import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import useAccountSetting from './useAccountSetting'
import useInstanceValue from './useInstanceValue'
import { cloneObj, dashToCamelCase } from '../utils/misc'

import setModuleSettingMutation from '../graphql/mutations/setModuleSetting'

const MAX_DEFAULT_VALUE_LENGTH = 200

const useGoSetModuleSetting = ({
  moduleSetting,
  projectId,
  undoRedoStack=`none`,
}) => {

  const [ setModuleSetting, setModuleSettingResult ] = useMutation(setModuleSettingMutation)
  const [ defaultModuleSettings, setDefaultModuleSettings ] = useAccountSetting(`def-module-settings`, {})
  const getDefaultModuleSettings = useInstanceValue(defaultModuleSettings)

  const onUpdate = useCallback(
    ({ newData }) => {

      const settingId = dashToCamelCase(newData.id.split(`:`)[1] || ``)

      if([ `legacy` ].includes(settingId)) {

        const { settings={} } = newData.value
        const moduleType = (
          (settings.cols && `DISCOURSE`)
          || (settings.numColsShown && `PHRASING`)
        )

        if(moduleType) {

          const settingsToSaveToDefault = {}

          for(let key in settings) {
            if(
              [
                `showmp`,
                `showinnerlines`,
                `colorarcs`,
                `shiftbrackets`,
                `showextended`,
                `numColsShown`,
                `linespacing`,
                `indenttogether`,
                `lWidPerc`,
              ].includes(key)
              || /^tabsize-/.test(key)
              || /^textsize-/.test(key)
            ) {
              settingsToSaveToDefault[key] = cloneObj(settings[key])
            }
          }

          setDefaultModuleSettings({
            ...getDefaultModuleSettings(),
            legacy: {
              ...(getDefaultModuleSettings().legacy || {}),
              [moduleType]: {
                ...((getDefaultModuleSettings().legacy || {})[moduleType] || {}),
                ...settingsToSaveToDefault,
              },
            },
          })

        }

      } else if(
        ![
          `lastScrollPosition`,
          `proceedingContext`,
          `followingContext`,
          `currentFrmtgKeyId`,
          `mainPointSummary`,
        ].includes(settingId)
        && JSON.stringify(newData.value).length < MAX_DEFAULT_VALUE_LENGTH
      ) {

        let settingValue = cloneObj(newData.value)
        if(settingId === `formattingKeyInfos`) {
          // which lenses are used gets its default from here, but specific labels for those lenses are not included
          settingValue.labelsByStyles = {}
        }

        setDefaultModuleSettings({
          ...getDefaultModuleSettings(),
          [settingId]: settingValue,
        })

      }
    },
    [ setDefaultModuleSettings, getDefaultModuleSettings ],
  )

  const goUpdate = useGoUpdateTable({
    currentData: moduleSetting,
    updateFunc: setModuleSetting,
    updateResult: setModuleSettingResult,
    mutationPrefix: `set`,
    projectId,
    moduleId: moduleSetting ? moduleSetting.id.split(':')[0] : null,
    undoRedoStack,
    onUpdate,
  })

  return goUpdate
}

export default useGoSetModuleSetting