import useShortcuts from './useShortcuts'

import keyDownSlash from '../components/common/shortcuts/keyDownSlash'

const keyDownFuncs = {
  keyDownSlash,
}

const useUniversalShortcuts = params => {

  useShortcuts({
    keyDownFuncs,
    ...params,
  })

}

export default useUniversalShortcuts