import { memo, useMemo } from 'react'
import styled from 'styled-components'

import { formattingTypes, formattingColors } from '../../../utils/formatting'
import useFormattingKey from '../../../hooks/useFormattingKey'

import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'

const Container = styled.div`
  max-width: min(30%, 170px);
  padding: 10px 15px;
  line-height: 1.7;
  font-size: 14px;
`

const Name = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  line-height: 1.3;
  font-weight: 300;
  font-size: 11px;
`

const Explanation = styled.div`
  font-size: 11px;
  line-height: 1.3;
  margin: ${({ $hideName }) => $hideName ? 0 : 7}px 0 4px;

  :empty {
    display: none;
  }
`

const Line = styled.div`
  display: block;
`

const Label = styled.span`  // must be span for sake of attaching the markup
`

const FormattingKey = ({
  id,
  hideName,
  hideExplanation,
  ...otherProps
}) => {

  const { name, explanation, labelsByStyles, hasLabels } = useFormattingKey(id)

  // const toggleShow = useCallback(
  //   ({ target }) => {
  //   },
  //   [],
  // )

  const { markup, content } = useMemo(
    () => {
      const markup = []
      const labelByColorForAnyArrowType = {}
      const content = (
        formattingTypes
          .map(type => (
            formattingColors.map(color => {

              const label = (labelsByStyles[type] || {})[color]
              if(!label) return null
              if(labelByColorForAnyArrowType[color] === label) return null
              labelByColorForAnyArrowType[color] = label

              const wordNumberInVerse = markup.length + 1

              const start = {
                loc: `01001001`,
                wordNumberInVerse,
              }

              markup.push({
                start,
                end: start,
                type,
                color,
              })

              return (
                <Line
                  key={wordNumberInVerse}
                >
                  <Label
                    data-word-loc={`01001001:${wordNumberInVerse}`}
                  >
                    {label}
                  </Label>
                </Line>
              )
            })
          ))
          .flat()
          .filter(Boolean)
      )
      return {
        markup,
        content,
      }
    },
    [ labelsByStyles ],
  )

  if(!hasLabels) return null

  return (
    <Container {...otherProps}>

      {!hideName &&
        <Name>
          {name}
        </Name>
      }

      {!hideExplanation &&
        <Explanation
          className="formatting-key-explanation"
          $hideName={!!hideName}
        >
          {explanation}
        </Explanation>
      }

      <TextContentMarkupContainer
        markup={markup}
      >
        {content}
      </TextContentMarkupContainer>

    </Container>
  )
}

export default memo(FormattingKey)