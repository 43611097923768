import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useLocation } from "react-router-dom"
import { getLocFromRef, getCorrespondingRefs } from "@bibletags/bibletags-versification"
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {
  KJV_VERSION,
} from '../../../utils/constants'
import { getOrigVersionInfo } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useSetChannelItemInfo from '../../../hooks/useSetChannelItemInfo'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from '../../common/Header'
import MessagePageNotFound from "./MessagePageNotFound"
import Loading from "../../common/Loading"
import StudyBibleItemSource from '../../study-bible/StudyBibleItemSource'
import MessagePagePassage from './MessagePagePassage'
import StudyBibleItemComments from '../../study-bible/StudyBibleItemComments'
import LinkButton from '../../common/LinkButton'

import channelItemQuery from '../../../graphql/queries/channelItem'

const REACTION_TYPES = [ `HEART`, `THUMBS-UP`, `THUMBS-DOWN`, `QUESTION-MARK` ]

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 150px);
  background-color: white;
`

const InnerContainer = styled.div`
  position: relative;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledStudyBibleItemSource = styled(StudyBibleItemSource)`
  margin: 40px 0;
  font-size: 13px;
  max-width: 600px;
  padding: 0;
`

const StyledStudyBibleItemComments = styled(StudyBibleItemComments)`
  width: 600px;
  max-width: calc(100vw - 12px);
`

const CommentsHeading = styled.div`
  font-weight: 500;
  margin: 25px 0 15px;
  font-size: 18px;

`

const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin: 5px -5px 20px;

  @media (max-width: 870px) {
    margin: 5px -5px;
  }
`

const HeaderText = styled.div`
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Title = styled.span`
  font-weight: 500;
`

const Message = ({
  // goPrintOrDownload,
  ...otherProps
}) => {

  const location = useLocation()
  const id = decodeURIComponent(location.pathname.split('/').at(-1))
  const channelId = (location.pathname.match(/^\/church\/([^/]+)/) || [])[1]

  const setChannelItemInfo = useSetChannelItemInfo()

  const { unhiddenSelectedVersionInfos } = useVersionInfos()
  const { version, safeVersionAbbr, id: versionId } = unhiddenSelectedVersionInfos[0] || {}  // i.e. the defaultVersionId

  const { channelItem, loading, refetch } = useDataQuery({
    channelItemQuery,
    variables: {
      id,
    },
  })

  const {
    // heading,
    title,
    type=`CHANNEL`,
    info,
    // details,
    // reactions,
    // tags,
    // createdAt,
  } = channelItem || {}

  const {
    locSets=[],
  } = info || {}

  const maxVersesPerPassage = Math.max(parseInt(10 / locSets.length, 10), 1)

  const defaultInputForNew = useMemo(
    () => ({
      type: `PUBLIC`,
      channelItemId: id,
    }),
    [ id ],
  )

  const versionLocSets = useMemo(
    () => {

      const getConvertedLoc = ({ loc, directionToTryIfSkipped=`previous`, atIdx=0 }) => (
        getLocFromRef(
          getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(loc),
              info: getOrigVersionInfo(),
            },
            lookupVersionInfo: version || KJV_VERSION,
            directionToTryIfSkipped,
          }).at(atIdx)
        ).split(':')[0]
      )

      return (
        locSets.map(
          locSet => locSet.map(
            (loc, idx) => (
              getConvertedLoc({
                loc,
                directionToTryIfSkipped: idx === 0 ? `previous` : `next`,
                atIdx: idx === 0 ? 0 : -1,
              })
            )
          )
        )
      )

    },
    [ version, locSets ]
  )

  useEffectAsync(
    () => {
      if(channelItem) {
        setChannelItemInfo({
          studyBibleItem: {
            ...channelItem,
            type: `CHANNEL`,
            studyBibleFollow: {
              id: `none`,
              channelId,
            },
          },
          versionId,
          anchorEl: true,
          autoplay: false,
        })
      }
    },
    [ !channelItem ],
  )

  if(!loading && !channelItem) {
    return (
      <MessagePageNotFound
        {...otherProps}
      />
    )
  }

  return (
    <>

      <Header
        showStudyBibleLogo={!channelId}
        showBiblearcLogo={!!channelId}
        {...otherProps}
      >
        <HeaderText>
          {i18nReact("Message: {{title}}", {
            title: (
              <Title>
                {title}
              </Title>
            ),
          })}
        </HeaderText>
      </Header>

      <Container key={id}>
        <InnerContainer>

          {loading && <Loading />}

          {!loading &&
            <>

              <StyledLinkButton
                to={channelId ? `/church/${channelId}` : `/`}
                startIcon={<ArrowBackIcon />}
                color="primary"
              >
                {i18n("Back to the Bible")}
              </StyledLinkButton>

              <Content>

                {versionLocSets.map((versionLocSet, idx) => (
                  <MessagePagePassage
                    key={idx}
                    versionLocSet={versionLocSet}
                    versionId={versionId}
                    safeVersionAbbr={safeVersionAbbr}
                    maxVerses={maxVersesPerPassage}
                  />
                ))}

                <StyledStudyBibleItemSource
                  type={type}
                  info={info}
                />

                <CommentsHeading>
                  {i18n("Discussion")}
                </CommentsHeading>

                <StyledStudyBibleItemComments
                  defaultInputForNew={defaultInputForNew}
                  query={`channelItemId:${id} type:PUBLIC`}
                  reactionTypes={REACTION_TYPES}
                  refetchItem={refetch}
                />

                {/* from The ___ Study Bible (with logo) */}
                {/* "also by" section? */}

              </Content>

            </>
          }

        </InnerContainer>
      </Container>

    </>
  )
}


export default memo(Message)