import { memo, useCallback, useRef } from 'react'
import styled from 'styled-components'
import Tab from '@material-ui/core/Tab'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useMeasure } from 'react-use'

import useGoUpdateModuleByProject from '../../../hooks/useGoUpdateModuleByProject'

import {
  MODULE_TYPE_COLOR_MAP,
} from '../../../utils/constants'
import useModalAnchor from '../../../hooks/useModalAnchor'
import { getNewOrdering } from '../../../utils/misc'

import NotesPopover from './NotesPopover'
import useInstanceValue from '../../../hooks/useInstanceValue'

const Container = styled.div`
  position: relative;
`

const Indicator = styled.div`
  position: absolute;
  width: ${({ $width }) => $width - 10}px;
  top: 5px;
  bottom: 5px;
  ${({ $indicatorSide }) => $indicatorSide}: 5px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 5px;
`

const StyledTab = styled(Tab)`
  width: auto;
  min-width: auto;
  text-transform: none;
  max-width: 200px;
  padding: 0 20px 0 12px;
  background-color: white;

  ${({ $reordering }) => !$reordering ? `` : `
    transition: transform .2s ease-in-out;
  `}

  &.hide-on-drag {
    opacity: 0;
  }

  ${({ $translateX }) => !$translateX ? `` : `
    transform: translateX(${$translateX}px);
  `}

  .MuiTab-wrapper {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SmallPeopleAltIcon = styled(PeopleAltIcon)`
  height: 14px !important;
  width: 14px !important;
  color: ${({ theme }) => theme.palette[MODULE_TYPE_COLOR_MAP.NOTES].dark};
  margin-left: 5px;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.grey[600]};
  height: 20px !important;
  width: 20px !important;
  position: absolute;
  right: 0;
  visibility: hidden;
`

const NotesTab = ({
  moduleByProject,
  onDeleteModuleByProject,
  selected,
  minimizedInDrawer,
  goPrintOrDownload,
  dragTab,
  setDragTab,
  orderingBefore,
  orderingAfter,
  ...otherProps
}) => {

  const { ordering, module } = moduleByProject
  const { label, shared } = module

  const [ goUpdateModuleByProject ] = useGoUpdateModuleByProject({ moduleByProject })

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const ref = useRef()
  const [ containerRef, { width } ] = useMeasure()

  const setThisAsDragTab = useCallback(
    ({ target }) => {
      setTimeout(() => target.classList.add('hide-on-drag'))
      setDragTab({
        ordering,
        width,
        move: ordering => goUpdateModuleByProject({ ordering }),
      })
    },
    [ setDragTab, ordering, width, goUpdateModuleByProject ],
  )

  const clearDragTab = useCallback(
    ({ target }) => {
      target.classList.remove('hide-on-drag')
      setDragTab(null)
    },
    [ setDragTab ],
  )

  const onDrop = useCallback(
    () => {
      dragTab.move(
        ordering < dragTab.ordering
          ? getNewOrdering(orderingBefore, ordering)
          : getNewOrdering(ordering, orderingAfter)
      )
    },
    [ dragTab, ordering, orderingBefore, orderingAfter ],
  )

  const getDragTab = useInstanceValue(dragTab)
  const onDragEnter = useCallback(
    () => {
      let { overOrdering, ...dragTab } = getDragTab() || {}
      overOrdering = overOrdering || dragTab.ordering
      if(overOrdering !== ordering) {
        setDragTab({
          ...getDragTab(),
          overOrdering: ordering,
        })
      }
    },
    [ ordering, getDragTab, setDragTab ],
  )
  const onDragLeave = useCallback(
    (e) => {
      if(!ref.current.contains(e.relatedTarget)) {
        let { overOrdering, ...dragTab } = getDragTab() || {}
        overOrdering = overOrdering || dragTab.ordering
        if(overOrdering === ordering) {
          setDragTab(dragTab)
        }
      }
    },
    [ ordering, getDragTab, setDragTab ],
  )

  const onDragOver = useCallback(event => event.preventDefault(), [])

  const doDropEvents = dragTab && dragTab.ordering !== ordering
  const translateX = (
    (dragTab && dragTab.overOrdering > dragTab.ordering && ordering <= dragTab.overOrdering && ordering > dragTab.ordering && -dragTab.width)
    || (dragTab && dragTab.overOrdering < dragTab.ordering && ordering >= dragTab.overOrdering && ordering < dragTab.ordering && dragTab.width)
    || 0
  )
  const indicatorSide = (
    dragTab
    && dragTab.overOrdering === ordering
    && (
      ordering > dragTab.ordering
        ? `right`
        : `left`
    )
  )

  if(minimizedInDrawer) {
    return (
      <StyledTab
        label={label}
        selected={selected}
        {...otherProps}
      />
    )
  }

  return (
    <div ref={ref}>
      <Container
        ref={containerRef}
        onDrop={doDropEvents ? onDrop : null}
        onDragOver={doDropEvents ? onDragOver : null}
        onDragEnter={doDropEvents ? onDragEnter : null}
        onDragLeave={doDropEvents ? onDragLeave : null}
      >

        <StyledTab
          label={
            <LabelContainer>

              <Label>
                {label}
              </Label>

              {shared && <SmallPeopleAltIcon />}

              <StyledExpandMoreIcon className="expand-more-icon" />

            </LabelContainer>
          }
          selected={selected}
          onClick={selected ? openModal : null}
          onDragStart={setThisAsDragTab}
          onDragEnd={clearDragTab} 
          $translateX={translateX}
          $reordering={!!dragTab}
          {...otherProps}
        />

        {!!indicatorSide &&
          <Indicator
            $width={dragTab.width}
            $indicatorSide={indicatorSide}
          />
        }

        <NotesPopover
          anchorEl={anchorEl}
          onClose={closeModal}
          moduleByProject={moduleByProject}
          onDeleteModuleByProject={onDeleteModuleByProject}
          goPrintOrDownload={goPrintOrDownload}
        />

      </Container>
    </div>
  )
}

export default memo(NotesTab)