import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import LinkButton from "../../common/LinkButton"

const StyledAppContent = styled(AppContent)`
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  font-size: 20px;
  margin-bottom: 30px;
  padding: 0 20px;
  text-align: center;
`

const Buttons = styled.div`
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

const StyledLinkButton = styled(LinkButton)`
`

const ChannelNotFound = ({
  id,
  message=i18n("We could not find the Study Bible you are looking for."),
  buttons,
  ...otherProps
}) => {

  return (
    <>

      <Header {...otherProps}>
        {` `}
      </Header>

      <StyledAppContent>

        <Message>
          {message}
        </Message>

        <Buttons>

          {buttons ||
            <>

              <StyledLinkButton
                to="/church-bibles"
                color="primary"
                variant="contained"
                disableElevation
              >
                {i18n("Browse Bibles")}
              </StyledLinkButton>

              <StyledLinkButton
                to="/"
                color="primary"
                variant="outlined"
              >
                {i18n("The Biblearc Bible")}
              </StyledLinkButton>

            </>
          }

        </Buttons>

      </StyledAppContent>

    </>
  )
}


export default memo(ChannelNotFound)