import { memo, useCallback } from 'react'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const Container = styled.div`
  padding: 0 0 0 6px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    margin-left: 2px;
    font-size: 14px;
  }
`

const CustomCheckbox = ({
  checked,
  onChange,
  color,
  className,
  onUpdate,
  onUpdateKey,
  onUpdateValue,
  ...otherProps
}) => {

  const goOnUpdate = useCallback(
    () => {
      onUpdate && onUpdate({ [onUpdateKey]: onUpdateValue })
    },
    [ onUpdate, onUpdateKey, onUpdateValue ],
  )

  return (
    <Container className={className} >

      <StyledFormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onUpdate ? goOnUpdate : onChange}
            size="small"
            color={color}
          />
        }
        {...otherProps}
      />

    </Container>
  )
}

export default memo(CustomCheckbox)