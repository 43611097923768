import styled from 'styled-components'

import {
  GET_STUDY_BIBLE_ITEM_TYPES,
} from '../../utils/constants'

const StudyBibleItemType = styled.span`
  border-radius: 100px;
  background: ${({ $type }) => GET_STUDY_BIBLE_ITEM_TYPES()[$type].color}33;
  padding: 3px 5px;
  font-size: 8px;
  line-height: 1;
  text-transform: uppercase;
  /* color: white; */
  display: inline-block;
  vertical-align: middle;

  :empty {
    display: none;
  }
`

export default StudyBibleItemType