import { memo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import SettingsIcon from '@material-ui/icons/Settings'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useModalAnchor from '../../../hooks/useModalAnchor'
import { embedSettingOnClick } from '../../../graphql/links/embedLink'

import OptionsPopover from '../../common/OptionsPopover'
import CustomSwitch from '../../common/CustomSwitch'

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -1px;
  right: -1px;
  background: white;
  z-index: 1400;

  ${({ $embedFullScreen }) => !$embedFullScreen ? `` : `
    top: 5px;
    right: 149px;
  `}

  ${({ $embedType }) => $embedType !== `search` ? `` : `
    top: 5px;
    right: 53px;
  `}

  :hover {
    background: ${({ theme }) => theme.palette.grey[200]};
  }
`

const Container = styled.div`
  padding: 20px;
  width: ${({ $embedType }) => $embedType !== `projects` ? `600px` : `auto`};
  max-width: calc(100vw - 10px);
`

const StyledTextField = styled(TextField)`
  margin: 5px 0 15px;
`

const EmbedSettingsIconButton = ({
  embedSettings,
  embedFullScreen,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  // const { embedType, instructions="", optional, includeNotes, allowStudentsToAddModules, viewOnly, disableOriginalLanguageHelps, saveToTools } = embedSettings || {}
  const { embedType, instructions="", optional, includeNotes, allowStudentsToAddModules, viewOnly, disableOriginalLanguageHelps } = embedSettings || {}

  return (
    <>
      <StyledIconButton
        onClick={openModal}
        $embedFullScreen={embedFullScreen}
        $embedType={embedSettings.embedType}
      >
        <SettingsIcon />
      </StyledIconButton>
      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        forceBelow
        hideArrow
      >

        <Container $embedType={embedType} >

          <StyledTextField
            variant="outlined"
            size="small"
            defaultValue={instructions}
            onChange={embedSettingOnClick}
            fullWidth
            multiline
            label={i18n("Instructions")}
            data-key="instructions"
            data-type="textbox"
          />

          <CustomSwitch
            checked={!!optional && !viewOnly}
            disabled={!!viewOnly}
            onChange={embedSettingOnClick}
            label={i18n("Optional (i.e. not required for lesson submission)")}
            data-key="optional"
            data-value={optional ? `` : `true`}
            data-type="boolean"
          />

          {embedType === `modules` &&
            <CustomSwitch
              checked={!!includeNotes}
              onChange={embedSettingOnClick}
              label={i18n("Include notes")}
              data-key="includeNotes"
              data-value={includeNotes ? `` : `true`}
              data-type="boolean"
            />
          }

          {embedType === `modules` &&
            <CustomSwitch
              checked={!!allowStudentsToAddModules && !viewOnly}
              disabled={!!viewOnly}
              onChange={embedSettingOnClick}
              label={i18n("Allow students to add modules")}
              data-key="allowStudentsToAddModules"
              data-value={allowStudentsToAddModules ? `` : `true`}
              data-type="boolean"
            />
          }

          <CustomSwitch
            checked={!!disableOriginalLanguageHelps}
            onChange={embedSettingOnClick}
            label={i18n("Disable original language helps")}
            data-key="disableOriginalLanguageHelps"
            data-value={disableOriginalLanguageHelps ? `` : `true`}
            data-type="boolean"
          />

          {/* I need to set this up to be GP client specific. And I need to get it to work. (See the saveLessonToTools function in GP's dataUtils) */}
          {/* {[ `modules`, `richtextbox` ].includes(embedType) &&
            <CustomSwitch
              checked={!!saveToTools && !viewOnly}
              disabled={!!viewOnly}
              onChange={embedSettingOnClick}
              label={i18n("Save as Biblearc TOOLS project in student’s account")}
              data-key="saveToTools"
              data-value={saveToTools ? `` : `true`}
              data-type="boolean"
            />
          } */}

          {[ `modules`, `search`, `passages` ].includes(embedType) &&
            <CustomSwitch
              checked={!!viewOnly}
              onChange={embedSettingOnClick}
              label={i18n("View only")}
              data-key="viewOnly"
              data-value={viewOnly ? `` : `true`}
              data-type="boolean"
            />
          }

        </Container>

      </OptionsPopover>
    </>
  )
}

export default memo(EmbedSettingsIconButton)