import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import LaunchIcon from '@material-ui/icons/Launch'

import LinkIconButton from '../common/LinkIconButton'

const Container = styled.div`
  display: flex;
  padding: 10px;
  margin: 0 10px;

  :hover {
    background: ${({ theme }) => theme.palette.grey[100]};
    cursor: pointer;
  }

  :hover .MuiButtonBase-root {
    visibility: visible;
  }
`

const Image = styled.img`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  object-fit: cover;
  border-radius: 5px;
`

const MainSection = styled.div`
  line-height: 1.2;
  padding-top: 7px;
  flex: 1;
`

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const NumSermons = styled.div`
  font-size: 11px;
  font-weight: 500;
`

const ButtonContainer = styled.div`
  align-self: center;
  margin: -5px -5px -5px -24px;
`

const StyledLinkIconButton = styled(LinkIconButton)`
  padding: 10px;
  background: white;
  visibility: hidden;

  :hover {
    background: ${({ theme }) => theme.palette.grey[300]};
  }

  .MuiSvgIcon-root {
    font-size: 14px;
  }
`

const StudyBibleFollowAddSpeaker = ({
  speaker,
  onSelect,
}) => {

  const {
    displayName,
    roundedThumbnailImageURL,
    speakerID,
    sermonCount,
  } = speaker

  const onClick = useCallback(
    () => {
      onSelect({
        ...speaker,
        channelUrl: `https://api.sermonaudio.com/v2/node/sermons?speakerID=${speakerID}`,
      })
    },
    [ onSelect, speaker, speakerID ],
  )

  return (
    <Container
      onClick={onClick}
    >

      <Image
        src={roundedThumbnailImageURL}
        className="dark-mode-exempt"
      />

      <MainSection>

        <Name>
          {displayName}
        </Name>

        <NumSermons>
          {i18n("{{num}} sermons", {
            num: sermonCount,
          })}
        </NumSermons>

      </MainSection>

      <ButtonContainer>
        <StyledLinkIconButton
          to={`/channel/sermonaudio/speaker/${speakerID}`}
        >
          <LaunchIcon />
        </StyledLinkIconButton>
      </ButtonContainer>

    </Container>
  )
}

export default memo(StudyBibleFollowAddSpeaker)