import { memo } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import { isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"

import { getOrigVersionInfo } from '../../utils/misc'

import BibleSearchPlusVersionsMenu from './BibleSearchPlusVersionsMenu'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 1;
  color: ${({ theme }) => theme.palette.text.primary};  // so that the grey text that accompanies the PassagePopper doesn't affect this container
`

const Heading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 15px;
  line-height: 18px;
  padding: 15px 15px 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
`

const Hits = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding: 0 15px 15px;
`

const BibleSearchHeader = ({
  searchText,
  includeVersionIds,
  inVersionAbbrs,
  inVersionIds,
  totalRows,
  hitsByBookId,
  disabled,
}) => {

  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)

  const unitLabels = {
    verse: [ i18n("verse"), i18n("verses") ],
    phrase: [ i18n("phrase"), i18n("phrases") ],
    sentence: [ i18n("sentence"), i18n("sentences") ],
    paragraph: [ i18n("paragraph"), i18n("paragraphs") ],
  }

  const [ x, same ] = searchText.match(/(?:^| )same:([a-z]+)(?: |$)/) || []  // eslint-disable-line no-unused-vars
  const unit = (unitLabels[same] || unitLabels.verse)[totalRows === 1 ? 0 : 1]
  const numberOfHits = hitsByBookId && hitsByBookId.reduce((total, hits) => total + hits, 0)

  return (
    <Container>

      {isOrigLanguageSearch &&
        <Heading>

          {i18nReact("Bible Search: {{versions}}", {
            versions: (
              (inVersionAbbrs || {}).length > 0
                ? inVersionAbbrs.join('+')
                : getOrigVersionInfo().abbr
            ),
          })}

          <BibleSearchPlusVersionsMenu
            searchText={searchText}
            includeVersionIds={includeVersionIds}
            disabled={disabled}
          />

        </Heading>
      }

      {!isOrigLanguageSearch &&
        <Heading>

          {i18n("Bible Search")}

          <BibleSearchPlusVersionsMenu
            searchText={searchText}
            inVersionIds={inVersionIds}
            disabled={disabled}
          />

        </Heading>
      }

      <Hits>
        {
          hitsByBookId
            ? (
              i18n("{{number_of_hits}} {{hits}} in {{number_of_units}} {{unit}}", {
                hits: numberOfHits > 1 ? i18n("hits") : i18n("hit"),
                number_of_hits: numberOfHits,
                number_of_units: totalRows,
                unit,
              })
            )
            : (
              i18n("{{number_of_units}} {{unit}}", {
                number_of_units: totalRows,
                unit,
              })
            )
        }
      </Hits>

    </Container>
  )
}

export default memo(BibleSearchHeader)