import { memo, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { i18n } from 'inline-i18n'
import { NavLink } from 'react-router-dom'

import useModalAnchor from "../../../hooks/useModalAnchor"
import useVersionInfos from '../../../hooks/useVersionInfos'

import MenuDivider from '../../common/MenuDivider'
import DangerMenuItem from '../../common/DangerMenuItem'

const Container = styled.div`
  padding: 0 5px;
  margin-right: -10px;
`

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.palette.text.primary} !important;
  text-decoration: none;
`

const MyVersionsListOptionsMenu = ({
  versionId,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const { selectedVersionInfos, updateSelectedVersionIds, toggleHideSelectedVersion, nonOrigSelectedVersionInfos } = useVersionInfos()

  const { hide=false, version: { doNotRemove=false }={} } = selectedVersionInfos.find(({ id }) => id === versionId) || {}
  const disallowRemove = nonOrigSelectedVersionInfos.length === 1

  const removeVersion = useCallback(
    () => updateSelectedVersionIds(selectedVersionInfos.map(({ id }) => id).filter(id => id !== versionId)),
    [ versionId, selectedVersionInfos, updateSelectedVersionIds ],
  )

  const toggleHideVersion = useCallback(
    () => {
      closeModal()
      toggleHideSelectedVersion(versionId)
    },
    [ versionId, toggleHideSelectedVersion, closeModal ],
  )

  return (
    <Container>

      <IconButton
        onClick={openModal}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >

        <StyledNavLink exact to={`/version/${versionId}`}>
          <MenuItem>
            {i18n("Version information")}
          </MenuItem>
        </StyledNavLink>

        <MenuDivider />

        {doNotRemove &&
          <MenuItem
            onClick={toggleHideVersion}
          >
            {
              hide
                ? i18n("Show in version chooser")
                : i18n("Hide from version chooser")
            }
          </MenuItem>
        }

        <Tooltip
          title={
            doNotRemove
              ? i18n("This version cannot be removed, though it can be hidden.")
              : (
                disallowRemove
                  ? i18n("You must keep at least one Bible version besides Heb+Grk.")
                  : ``
              )
          }
        >
          <div>
            <DangerMenuItem
              onClick={removeVersion}
              disabled={doNotRemove || disallowRemove}
            >
              {i18n("Remove")}
            </DangerMenuItem>
          </div>
        </Tooltip>

      </Menu>

    </Container>
  )
}

export default memo(MyVersionsListOptionsMenu)