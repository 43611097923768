import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"

import { getPassageTextSize, getPassageLineSpacing, getOrigVersionInfo } from '../../../utils/misc'
import useGoUndo from '../../../hooks/useGoUndo'
import useMarkupSettings from '../../../hooks/useMarkupSettings'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useEffectAsync from '../../../hooks/useEffectAsync'

import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
// import NewToMarkup from './NewToMarkup'
import ModuleHeaderBackground from '../shared/ModuleHeaderBackground'
import MarkupOptionsChangePassage from './MarkupOptionsChangePassage'
import MarkupSettings from './MarkupSettings'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import MarkupContent from './MarkupContent'
import MarkupKey from './MarkupKey'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

import useVersionInfo from '../../../hooks/useVersionInfo'

const noop = ()=>{}

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 0;

  ${({ $noneditableViewingMode }) => ![ `shared-page`, `sketch`].includes($noneditableViewingMode) ? `` : `
    padding: 0 15px;

    @media (max-width:600px) {
      padding: 0 10px;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    justify-content: flex-start;
  `}
`

const Markup = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  printOrDownloadInfo,
  goPrintOrDownload,
  // goPrintEncompassingNotesTab,  // not needed, since MarkupContent (which has the shortcut key items) is not rendered
  goSketch,
  onLoad,
  setHeight,
  embedFullScreen,
  ...otherProps
}) => {

  const { id, modulePassages } = module
  const { fromLoc, info={} } = modulePassages[0] || {}
  const { bookId } = getRefFromLoc(fromLoc || ``)
  const { versionId } = info

  const [ ref, { width: moduleWidth } ] = useMeasure()
  const minimized = moduleWidth < 600  // TODO: base this on formattingKeyView as well

  const inEditingMode = module.inEditingMode && !noneditableViewingMode

  const origVersionInfo = useEqualObjsMemo(getOrigVersionInfo())
  const { version=origVersionInfo } = useVersionInfo(versionId)

  const { goUndo, goRedo, undoComponent } = useGoUndo({
    moduleId: id,
    projectId,
  })

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module,
        projectId,
        ...params,
      })
    },
    [ goPrintOrDownload, module, projectId ],
  )

  useEffectAsync(
    () => {
      // TODO: not sure what this is for
      if(onLoad) {
        onLoad()
      }
    },
    [],
  )

  const markupSettings = useMarkupSettings({ moduleId: id })
  const {
    textSizesSetting,
    lineSpacingsSetting,
    formattingKeyInfosSetting,
    currentFrmtgKeyIdSetting,
    lensesSetting,
    // formattingKeyViewSetting,
  } = markupSettings

  const [ displayedTextSizes, setDisplayedTextSizes ] = useState(textSizesSetting.value)
  const [ displayedLineSpacings, setDisplayedLineSpacings ] = useState(lineSpacingsSetting.value)

  useEffectAsync(
    () => {
      setDisplayedTextSizes(textSizesSetting.value)
    },
    [ textSizesSetting, setDisplayedTextSizes ],
  )

  useEffectAsync(
    () => {
      setDisplayedLineSpacings(lineSpacingsSetting.value)
    },
    [ lineSpacingsSetting, setDisplayedLineSpacings ],
  )

  const adjustmentType = (
    (displayedTextSizes !== textSizesSetting.value && `textsizes`)
    || (displayedLineSpacings !== lineSpacingsSetting.value && `linespacings`)
  )
  const languageId = getTextLanguageId({ ...version, bookId })
  const textFontSize = getPassageTextSize({ textSizes: displayedTextSizes, languageId })
  const lineSpacing = getPassageLineSpacing({ lineSpacingSizes: displayedLineSpacings, languageId })
  const isRTL = isRTLText({ languageId, bookId })

  return (
    <ModuleContainer 
      ref={ref}
      noneditableViewingMode={noneditableViewingMode}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={i18n("New Markup", "", "markup")}
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={noop}
          moduleWidth={moduleWidth}
          // newOrLearning={<NewToMarkup />}
          maxChapterSpan={3}
        />
      }

      {modulePassages.length > 0 &&
        <StyledContainerWithPassagePopper
          // ref={setContainerRef}
          $noneditableViewingMode={noneditableViewingMode}
          onTopOfAll
          // selectText
          // markup
          // updateMarkup
          // formattingKeyId
          // mode?, noTextSelection?
          inEditingMode={inEditingMode}
          bookId={bookId}
          versionId={versionId}
        >

          {!noneditableViewingMode && !printOrDownloadInfo &&
            <>

              <ModuleHeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                showVersionAbbr
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                goSketch={goSketch}
                goUndo={goUndo}
                width={otherProps.width}
                // absolutePositionedSection={
                // }
                topSection={
                  <MarkupOptionsChangePassage
                    module={module}
                    projectId={projectId}
                    setUpPiecesAfterPassageChange={noop}
                  />
                }
                settingsSection={
                  <MarkupSettings
                    projectId={projectId}
                    versionId={versionId}
                    languageId={languageId}
                    displayedTextSizes={displayedTextSizes}
                    displayedLineSpacings={displayedLineSpacings}
                    setDisplayedTextSizes={setDisplayedTextSizes}
                    setDisplayedLineSpacings={setDisplayedLineSpacings}
                    {...markupSettings}
                  />
                }
                $adjustmentType={adjustmentType}
              />

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode === `embed` &&
            <ModuleTitleButton
              module={module}
              width={otherProps.width}
              disabled
              showVersionAbbr
            />
          }

          <MarkupContent
            module={module}
            projectId={projectId}
            inEditingMode={inEditingMode}
            noneditableViewingMode={noneditableViewingMode}
            isRTL={isRTL}
            textFontSize={textFontSize}
            lineSpacing={lineSpacing}
            goUndo={goUndo}
            goRedo={goRedo}
            goPrintOrDownload={goPrintOrDownloadWithBaseParams}
            embedFullScreen={embedFullScreen}
            {...markupSettings}
          />

          {lensesSetting.value === `ON` &&
            <MarkupKey
              currentFrmtgKeyIdSetting={currentFrmtgKeyIdSetting}
              formattingKeyInfosSetting={formattingKeyInfosSetting}
              inEditingMode={inEditingMode}
              noneditableViewingMode={noneditableViewingMode}
              minimized={minimized}
              printOrDownloadInfo={printOrDownloadInfo}
              projectId={projectId}
            />
          }

        </StyledContainerWithPassagePopper>
      }

      {undoComponent}

    </ModuleContainer>
  )
}

export default memo(Markup)


// const formattingKeys = [
//   {
//     id: "lkjsdf-sdfsdfsdf-sdfd",
//     name: "Commands+",
//     type: "MARKUP",
//     info: {
//       styles: [
//         {
//           markupType: "BASE-HIGHLIGHT",
//           label: "Commands",
//         },
//       ],
//       colors: [
//         {
//           color: "RED",
//           type: "Main Topic",
//           example: "Love one another",
//           value: "?",
//         },
//       ],
//       colorDefault: {
//         type: "Secondary Topic",
//       },
//     },
//     public: false,
//   }
// ]

// const moduleSettings = {
//   formattingKeyInfos: [
//     {
//       id: "sdf",
//       colors: [
//         {
//           color: "RED",
//           value: "Main Topic",
//         },
//       ],
//     },
//   ],
//   currentFrmtgKeyId,  // the page they are on
// }


/*

  - lens settings dialog
    Show when one is a system lens
    Show proper edit title: Create new, Copy from ___, Modify ___
    Option to delete
    snackbars to reverse stuff
    prevent need to refetch all plural queries
    Make pretty
    Force unique names?
    get rid of false lenses on load

  - default lens data worked?

  UI for "Create/edit/delete/reorder/remove a lens" (include public lenses)
    Default to the lenses I used last time I modified my lenses
      If never done so, default to the lenses for the markup course
    Button to change to the Markup course lenses (system default)
  System/public lenses
    Page between lenses
  Public lenses
    import publicFormattingKeysQuery from '../graphql/queries/publicFormattingKeys'
    Choosing a public lens creates it as your own lens
      const notMeAddOn = user ? ` not-userId="${user.id}"` : ``
      const { publicFormattingKeys, loading } = useDataQuery({
        publicFormattingKeysQuery,
        variables: {
          query: (
            `${query}${notMeAddOn}`
            || `id:"${possiblePublicFormattingKeyIds.join(` `).replace(/"/g, ``)}"${notMeAddOn}`
          ),
        },
        skip: possiblePublicFormattingKeyIds.length === 0,
      })
  Create/edit/delete/reorder/remove lenses
    create/edit fields
      Lens Name
      Markup types
        Colors
          Sublabels
            (e.g. for Markup L4: "Main Topic" for red, "Secondary Topic" for other colors)
          Example label
            (e.g. for Markup L5-7: "Love one another")
          Default label
      Public toggle
  Add color/labels to a lens (should not change what is being viewed if currently viewing all colors)
  tap color-label to only view that one (with view all option as well)
    also reveals pencil icon to edit the label
  Drag (long-tap first on touch) color-labels to reorder; drag (long-tap first on touch) out to delete (with undo snackbar)
  text-selection
    editing mode
      Lens-specific markup palette
    non-editing mode
      normal copy, search
  make easy way to delete an existing markup (considering touch as well) - double click/tap?
  Brand with "Markup Lens" terminology
  fix bug where it doesn't highlight a line if the markup is 3+ lines long
  Make arrow highlight and circle markups look better
  Clean up code
  Dot notes
  NewToMarkup
  put systemFormattingKeys in formatting.js

  Make entire content of project searchable from projects and search bar



  MARKUP PALETTE IN OTHER MODULES (and notes passage inserts)
    Data for markup key
      a ModuleSetting (possibly more than one)
      for Notes it is contained in the data for that insert
    UI
      text selection menu - no key
        top toggle
          "Basic Markup" / "All Markup"
          basic markup is just three colors
        bottom button (if no key)
          Create a Key
            options:
              from this project (default, if exists; otherwise "new" is default)
              from another project
              new
                will automatically fill with any existing markup in this module/notes passage
              system options
                Hebrew Grammar
                Greek Grammar
                Devotional Reading
                Who and What
                Key Points and Connectors
      text selection menu - with a key
        markup key is just the styles in the key
        ends with a + button to add a new one
          the + button shows all the markup options with a "back" option
      the key
        heading: "Markup Key"
        shows all markup ever used, whether labeled or not
        shows "unnamed" for label by default
        tap a label to rename it [if editable]
        drag (long-tap first on touch) labels to reorder [if editable]
        drag (long-tap first on touch) a label out to delete it (visually indicate this will happen + undo snackbar) [if editable]
        has button at the bottom for adding a new markup style (brings up all markup to choose from) [if editable]
        has an options button [if editable]
          minimize/expand
          share
          import (with way to search keys from my other work)
          delete
        (key is always shown if it exists, whether minimized or expanded)
  TODO:
    - change "formatting key" language everywhere to something else
    - 
      


*/