import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

import OptionsPopover from '../../common/OptionsPopover'
import ModuleActions from './ModuleActions'

const StyledOptionsPopover = styled(OptionsPopover)`
  max-height: calc(100% - 60px);
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: min(400px, calc(100vw - 10px));
`

const SplitContainer = styled.div`
  display: flex;

  @media (max-width: 400px) {
    flex-direction: column;

    .MuiDivider-vertical {
      height: 1px;
      width: auto;
    }
  }
`

const Section = styled.div`
  ${({ $flex }) => !$flex ? `` : `
    flex: 1;
    min-width: 0;
  `}
  display: flex;
  flex-direction: column;
`

const ModuleOptionsHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  padding: 10px 15px 0;
`

const ModuleOptionsPopover = ({
  module,
  moduleByProject,
  onDeleteModuleByProject,
  goPrintOrDownload,
  goSketch,
  disableDownload,
  giveDownloadChoices,
  goUndo,
  absolutePositionedSection=null,
  topSection=null,
  columnsHeadingText=i18n("Columns"),
  columnsSection=null,
  settingsSection=null,
  customActionsSection=null,
  onClose,
  ...otherProps
}) => {

  // const toggleShowStudyBible = useCallback(
  //   ({ target }) => {
  //     updatePassageBookmark({
  //       showStudyBible: target.checked,
  //     })
  //   },
  //   [ updatePassageBookmark ],
  // )

  // const updatePassage = useCallback(
  //   passage => {
  //     updatePassageBookmark({
  //       scrollYFraction: 0,
  //       ...passage,
  //     })
  //     passage.ref && onClose()
  //   },
  //   [ updatePassageBookmark, onClose ],
  // )

  return (
    <StyledOptionsPopover
      {...otherProps}
      onClose={onClose}
      forceBelow
      hideArrow
    >

      <MainContainer>

        {!!topSection &&
          <>
            <Section className="module-options-popover-top">
              {topSection}
            </Section>
            <Divider />
          </>
        }

        {!!columnsSection &&
          <>
            <Section className="module-options-popover-columns">
              <ModuleOptionsHeading>
                {columnsHeadingText}
              </ModuleOptionsHeading>
              {columnsSection}
            </Section>
            <Divider />
          </>
        }

        <SplitContainer>

          {!!settingsSection &&
            <>
              <Section $flex className="module-options-popover-settings">
                <ModuleOptionsHeading>
                  {i18n("Settings")}
                </ModuleOptionsHeading>
                {settingsSection}
              </Section>
              <Divider orientation="vertical" flexItem />
            </>
          }

          <Section className="module-options-popover-actions">
            <ModuleOptionsHeading>
              {i18n("Actions")}
            </ModuleOptionsHeading>
            {customActionsSection ||
              <ModuleActions
                module={module}
                moduleByProject={moduleByProject}
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={goPrintOrDownload}
                goSketch={goSketch}
                disableDownload={disableDownload}
                giveDownloadChoices={giveDownloadChoices}
                goUndo={goUndo}
                onClose={onClose}
              />
            }
          </Section>

        </SplitContainer>

        {absolutePositionedSection}

      </MainContainer>

    </StyledOptionsPopover>
  )
}

export default memo(ModuleOptionsPopover)