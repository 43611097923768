import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { i18n } from 'inline-i18n'
import { getBibleBookName } from "@bibletags/bibletags-ui-helper"

import { GET_STUDY_BIBLE_ITEM_TYPES, GET_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS } from '../../../utils/constants'
import { getLocalStorage } from '../../../utils/misc'
import useDataQuery from '../../../hooks/useDataQuery'
import useInitialValueUpdater from '../../../hooks/useInitialValueUpdater'

import StudyBibleItemRow from './StudyBibleItemRow'
import StudyBibleItemsListMore from './StudyBibleItemsListMore'
import FadedLoading from '../../common/FadedLoading'

import studyBibleItemsQuery from '../../../graphql/queries/studyBibleItems'

const PAGE_SIZE = 25

const Container = styled.div`
  position: relative;
  min-height: 500px;
`

const Options = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
`

const List = styled.div`
`

const StyledTextField = styled(TextField)`
`

const StudyBibleTab = () => {

  const [ order, setOrder ] = useState(() => getLocalStorage(`initialValue:StudyBibleTab-order`, `createdAt`))  // OR `fromLoc,adminRating DESC,createdAt`
  const [ status, setStatus ] = useState(() => getLocalStorage(`initialValue:StudyBibleTab-status`, `DRAFT-LOCKED DRAFT-UNLOCKED`))
  const [ type, setType ] = useState(() => getLocalStorage(`initialValue:StudyBibleTab-type`, `ALL`))
  const [ bookId, setBookId ] = useState(() => getLocalStorage(`initialValue:StudyBibleTab-bookId`, 0))
  const [ textSearch, setTextSearch ] = useState(() => getLocalStorage(`initialValue:StudyBibleTab-textSearch`, ``))

  useInitialValueUpdater({
    keyPrefix: `StudyBibleTab-`,
    order,
    status,
    type,
    bookId,
    textSearch,
  })

  const onChangeSort = useCallback(({ target }) => setOrder(target.value), [])
  const onChangeStatus = useCallback(({ target }) => setStatus(target.value), [])
  const onChangeBookId = useCallback(({ target }) => setBookId(target.value), [])
  const onChangeTextSearch = useCallback(({ target }) => setTextSearch(target.value), [])
  const onChangeType = useCallback(({ target }) => setType(target.value), [])

  const queryPieces = []

  const tags = (
    textSearch
      .split(/ /g)
      .filter(tagMaybe => /^#(?:INTERNAL:)?(?:\p{L}|[-_+&/%])+$/u.test(tagMaybe))
      .map(tagPlus => tagPlus.replace(/#/g, ``))
  )

  const userId = (textSearch.match(/(?:^| )user:([0-9]+)/) || [])[1]

  const textSearchWithoutTags = (
    textSearch
      .split(/ /g)
      .filter(piece => (
        !/^#(?:INTERNAL:)?(?:\p{L}|[-_+&/%])+$/u.test(piece)
        && !/^user:[0-9]+$/.test(piece)
      ))
      .join(` `)
      .trim()
  )

  tags.length > 0 && queryPieces.push(`studyBibleItemTagTag:"${tags.join(` `)}"`)
  type !== `ALL` && queryPieces.push(`type:"${type}"`)
  status !== `all` && queryPieces.push(`status:"${status}"`)
  userId && queryPieces.push(`userId:"${userId}"`)

  if(bookId) {
    queryPieces.push(`0${bookId}`.slice(-2))
  } else if(textSearchWithoutTags) {
    queryPieces.push(textSearchWithoutTags)
  }

  const {
    studyBibleItems: {
      studyBibleItems=[],
      count,
    }={},
    networkStatus,
    fetchMore,
  } = useDataQuery({
    studyBibleItemsQuery,
    variables: {
      order,
      query: queryPieces.join(` `),
      limit: PAGE_SIZE,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const goFetchMore = useCallback(
    () => {
      fetchMore({
        variables: {
          offset: studyBibleItems.length,
        },
        fetchPolicy: 'network-only',
      })
    },
    [ fetchMore, studyBibleItems ],
  )

  const commonTextFieldProps = {
    variant: `outlined`,
    size: `small`,
    InputLabelProps: {
      shrink: true,
    },
  }

  const orderOptionTextByValue = {
    "createdAt": i18n("Oldest first", "", "admin"),
    "fromLoc,adminRating DESC,createdAt DESC": i18n("Canonical ordering", "", "admin"),
    "updatedAt DESC": i18n("Last modified", "", "admin"),
    "literal:numOpenComments DESC": i18n("Number of comments", "", "admin"),
  }

  const statusOptionTextByValue = {
    "all": i18n("All", "", "admin"),
    "DRAFT-LOCKED DRAFT-UNLOCKED": i18n("Unpublished and Undeferred", "", "admin"),
    "DEFERRED": i18n("Deferred", "", "admin"),
    "DRAFT-UNLOCKED": i18n("Contributor Drafts", "", "admin"),
    "DRAFT-LOCKED": i18n("Submitted to Editor", "", "admin"),
    "PUBLISHED": i18n("Published", "", "admin"),
  }

  const studyBibleItemTypes = GET_STUDY_BIBLE_ITEM_TYPES()

  return (
    <Container>

      <Options>

        <StyledTextField
          {...commonTextFieldProps}
          select
          label={i18n("Sort By")}
          value={order}
          onChange={onChangeSort}
        >
          {Object.keys(orderOptionTextByValue).map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              {orderOptionTextByValue[value]}
            </MenuItem>
          ))}
        </StyledTextField>

        <StyledTextField
          {...commonTextFieldProps}
          select
          label={i18n("Status")}
          value={status}
          onChange={onChangeStatus}
        >
          {Object.keys(statusOptionTextByValue).map(value => (
            <MenuItem
              key={value}
              value={value}
            >
              {statusOptionTextByValue[value]}
            </MenuItem>
          ))}
        </StyledTextField>

        <StyledTextField
          {...commonTextFieldProps}
          select
          label={i18n("Book")}
          value={bookId}
          onChange={onChangeBookId}
        >
          {Array(67).fill().map((x, bookId) => (
            <MenuItem
              key={bookId}
              value={bookId}
            >
              {getBibleBookName(bookId) || i18n("All", "", "admin")}
            </MenuItem>
          ))}
        </StyledTextField>

        <StyledTextField
          {...commonTextFieldProps}
          label={i18n("Heading, Title, Tag, User")}
          value={textSearch}
          onChange={onChangeTextSearch}
        />

        <StyledTextField
          {...commonTextFieldProps}
          select
          label={i18n("Type")}
          value={type}
          onChange={onChangeType}
        >
          <MenuItem
            value={`ALL`}
          >
            {i18n("All", "", "admin")}
          </MenuItem>
          {GET_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS().map(type => (
            <MenuItem
              key={type}
              value={type}
            >
              {studyBibleItemTypes[type].label}
            </MenuItem>
          ))}
        </StyledTextField>
      </Options>

      <List>

        {networkStatus < 3 && <FadedLoading />}

        {studyBibleItems.map(studyBibleItem => (
          <StudyBibleItemRow
            key={studyBibleItem.id}
            studyBibleItem={studyBibleItem}
            setTextSearch={setTextSearch}
          />
        ))}

        {networkStatus >= 3 &&
          <StudyBibleItemsListMore
            showingCount={studyBibleItems.length}
            totalCount={count}
            goFetchMore={goFetchMore}
            loading={networkStatus < 7}
          />
        }

      </List>

    </Container>
  )
}


export default memo(StudyBibleTab)

// - View all entries (or just your own if you are a contributor)
// - Filter
//   - tag
// - Action
//   - edit
//   - publish
//   - unlock [ Editing by an editor "locks" it ]
//   - chat item
//   - email to contributor
//   - tag an entry
//   - tag an entry as INTERNAL:deferred (hides from inbox)
// - Manage contributors
// - Add
// - Remove
// - Email all contributors