import { memo, useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { useMeasure } from 'react-use'

import { getSecondsFromTimeString, isIOS, isPWA } from '../../utils/misc'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useStickyRefState from '../../hooks/useStickyRefState'
import useRefState from '../../hooks/useRefState'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useMouseOverOrTouch from '../../hooks/useMouseOverOrTouch'
import useEffectAsync from '../../hooks/useEffectAsync'
import useSetTimeout from '../../hooks/useSetTimeout'

import VideoPlayerCustomControls from './VideoPlayerCustomControls'
import AudioVisualizer from './AudioVisualizer'

// const vimeoRegex = /^https:\/\/(?:www\.)?vimeo\.com\/(?:.*?\/video\/|channels\/[^/]*\/|manage\/videos\/)?([0-9]+)(?:\/[^/]*)?\/?(?:\?.*)?$/
// const youtubeRegex = /^https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&]*).*$/
// const shortYoutubeRegex = /^https:\/\/youtu\.be\/([^?]+).*$/
// const sermonAudioRegex = /^https:\/\/beta\.sermonaudio\.com\/sermons\/([0-9]+)(\/a)?/
// const videoFileRegex = /^.*\.(mp4|ogg)(?:\?.*)?$/

const Container1 = styled.div`
  position: relative;
  width: 100%;
  max-width: min(100%, calc(100vw - 20px));

  .VideoPlayerCustomControls-fullscreenexiticon {
    display: none;
  }

  :fullscreen .VideoPlayerCustomControls-fullscreenicon {
    display: none;
  }

  :fullscreen .VideoPlayerCustomControls-fullscreenexiticon {
    display: block;
  }
`

const Container2 = styled.div`
  position: relative;
  padding-bottom: ${({ $isAudioUrl, $show }) => ($show ? ($isAudioUrl ? `210px` : `56.25%`) : `50px`)};
  transition: .2s ease-in-out padding-bottom;
  width: 100%;
`

const Container3 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0/.65);
`

const ReactPlayerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: ${({ $doAudio }) => $doAudio ? `hidden` : `visible`};
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const VideoPlayer = ({
  id,
  src,
  startTime,
  endTime,
  autoplay,
  rememberSpot,

  customControls,  // all items in this section only relevant if `customControls` is true
  onClose,
  sourceLink,
  studyBibleLink,
  reactions,
  addBookmark,
  removeBookmark,
  updateBookmark,
  numComments,
  updateMyBookmarks,
  title,
  duration: durationProp,
  initialCurrentSec,
  defaultSpeed=1,
  setDefaultSpeed,
  setDoAudio,
  hasAudio,
  sharingText,
  updateListeningHistory,
  doAudio,

  ...otherProps
}) => {

  const srcKey = id || `${src} ${startTime} ${endTime} ${autoplay}`
  const url = (src || ``).replace(/(?:www\.)?youtube\.com/, `youtube-nocookie.com`)
  const isAudioUrl = /^.*\.(mp3|aac)(?:\?.*)?$/i.test(url)
  const isAudio = typeof doAudio === `boolean` ? doAudio : isAudioUrl
  const isVimeoUrl = /^https:\/\/(?:www\.)?vimeo\.com\//.test(url)

  autoplay = !!(autoplay && isAudioUrl)

  const ref = useRef()
  const ready = useRef(false)
  const waitUntilPlayToSeek = useRef(!isAudioUrl && !!customControls && !isVimeoUrl)
  const container1Ref = useRef()
  const [ container3Ref, { width } ] = useMeasure()
  const [ setSeekTimeout, clearSeekTimeout, getIsActiveSeekTimeout ] = useSetTimeout()  // eslint-disable-line no-unused-vars

  const [ duration, setDuration ] = useState(durationProp)
  const [ buffering, setBuffering, getBuffering ] = useRefState(true)
  const [ pip, togglePip ] = useSimpleToggle()
  const [ rememberCurrentSecs, setRememberCurrentSecs, getRememberCurrentSecs ] = useStickyRefState({ id: `VideoPlayer:currentSecs:${srcKey}`, defaultValue: 0 })
  const [ noRememberCurrentSecs, setNoRememberCurrentSecs, getNoRememberCurrentSecs ] = useRefState(initialCurrentSec || getSecondsFromTimeString(startTime))

  const currentSecs = rememberSpot ? rememberCurrentSecs : noRememberCurrentSecs
  const setCurrentSecs = rememberSpot ? setRememberCurrentSecs : setNoRememberCurrentSecs
  const getCurrentSecs = rememberSpot ? getRememberCurrentSecs : getNoRememberCurrentSecs
  const endSecs = getSecondsFromTimeString(endTime)

  let [ speed, setSpeed ] = useStickyRefState({ id: `VideoPlayer:speed:${srcKey}` })  // cannot use `defaultValue` because the defaultSpeed is fetched async
  speed = speed || defaultSpeed

  const [ playing, setPlaying, getPlaying ] = useRefState(false)
  const [ showSharing, toggleShowSharing ] = useSimpleToggle()
  const [ showBookmarking, toggleShowBookmarking ] = useSimpleToggle()
  const showControlsProps = useMouseOverOrTouch({
    forceShow: !playing || showSharing || showBookmarking,
    timeoutMs: isAudio ? null : 3000,
    mouseOutTimeoutMs: isAudio ? 500 : null,
    initialTimeoutMs: isAudio ? 3000 : 1000,
  })

  // const [ muted, setMuted ] = useState(false)

  const pipEnabled = !!document.pictureInPictureEnabled && /\.(?:m3u8|mp4)(?:[?#].*)$/.test(url) && !(isIOS && isPWA)

  const goSetSpeed = useCallback(
    newSpeed => {
      if(newSpeed === speed) return
      setDefaultSpeed && setDefaultSpeed(newSpeed)
      setSpeed(newSpeed)
    },
    [ setDefaultSpeed, setSpeed, speed ],
  )

  const onProgressOrSeek = useCallback(
    info => {
      if(
        endSecs
        && getCurrentSecs() < endSecs
        && info.playedSeconds > endSecs
      ) {
        setPlaying(false)
      }
      const newCurrentSecs = parseFloat(info.playedSeconds || info) || 0
      if(
        !waitUntilPlayToSeek.current
        && !getIsActiveSeekTimeout()
        && newCurrentSecs.toFixed(2) !== getCurrentSecs().toFixed(2)
        && (
          // it should never progress to zero; it could seek to zero, but not if there are customControls
          newCurrentSecs
          || !customControls
        )
      ) {
        setCurrentSecs(parseFloat(info.playedSeconds || info) || 0)
      }
    },
    [ setCurrentSecs, getCurrentSecs, endSecs, setPlaying, getIsActiveSeekTimeout, customControls ],
  )

  const onPlay = useCallback(
    () => {
      if(!getPlaying()) {
        if(waitUntilPlayToSeek.current) {
          waitUntilPlayToSeek.current = false
          ref.current.seekTo(getCurrentSecs(), `seconds`)
        }
        setPlaying(true)
      }
    },
    [ getPlaying, setPlaying, getCurrentSecs ],
  )

  const onReady = useCallback(
    () => {
      if(ready.current) return
      ready.current = true
      setBuffering(false)
      if(!waitUntilPlayToSeek.current) {
        ref.current.seekTo(currentSecs, `seconds`)
      }
      if(autoplay) {
        onPlay()
      }
    },
    [ currentSecs, autoplay, onPlay, setBuffering ],
  )

  const onPause = useCallback(
    () => {
      if(getPlaying()) setPlaying(false)
      if(getBuffering()) setBuffering(false)
    },
    [ getPlaying, setPlaying, setBuffering, getBuffering ],
  )
  const enabledPip = useCallback(() => togglePip({ force: true }), [ togglePip ])
  const disablePip = useCallback(() => togglePip({ force: false }), [ togglePip ])

  const goSeek = useCallback(
    seconds => {
      setCurrentSecs(seconds)
      if(!waitUntilPlayToSeek.current) {
        setSeekTimeout(() => ref.current.seekTo(seconds, `seconds`), 100)
      }
    },
    [ setCurrentSecs, setSeekTimeout ],
  )

  const onBuffer = useCallback(() => setBuffering(true), [ setBuffering ])
  const onBufferEnd = useCallback(() => setBuffering(false), [ setBuffering ])

  const toggleFullscreen = useCallback(
    () => {
      if(document.fullscreenElement) {
        document.exitFullscreen()
      } else {
        container1Ref.current.requestFullscreen()
      }
    },
    [],
  )

  useLayoutEffectAsync(
    () => {
      ready.current = false
    },
    [ url ],
  )

  useEffectAsync(
    () => {
      if(updateListeningHistory) {
        updateListeningHistory({ playing, currentSecs, speed })
      }
    },
    [ playing, !currentSecs, parseInt(currentSecs / 30, 10), speed ],
  )

  return (
    <Container1
      ref={container1Ref}
      className="dark-mode-exempt"
      {...otherProps}
    >
      <Container2
        $isAudioUrl={isAudioUrl}
        $show={showControlsProps.$show || !isAudio}
      >
          <Container3 ref={container3Ref}>

            {isAudioUrl &&
              <AudioVisualizer
                currentSecs={currentSecs}
                duration={duration}
                playing={playing}
              />
            }

            {!!width &&
              <ReactPlayerContainer $doAudio={doAudio}>
                <StyledReactPlayer
                  key={srcKey}
                  ref={ref}
                  url={url}
                  width="100%"
                  height="100%"
                  playing={playing}
                  // muted={muted}
                  playbackRate={speed}
                  pip={pip}
                  onEnablePIP={enabledPip}
                  onDisablePIP={disablePip}
                  controls={!customControls}
                  playsinline
                  config={{
                    youtube: {
                      // onUnstarted: onPause,
                      playerVars: {
                        // modestbranding: 1,  // doesn't do anything
                        // showinfo: 0,  // doesn't do anything
                      },
                    },
                    vimeo: {
                      playerOptions: {
                        width,  // this is needed to avoid a shift
                        playsinline: true,
                        quality: isAudio ? `360p` : `auto`,
                        speed: true,
                      },
                    },
                  }}
                  progressInterval={100}
                  onProgress={onProgressOrSeek}
                  onReady={onReady}
                  onPlay={onPlay}
                  onPause={onPause}
                  onSeek={onProgressOrSeek}
                  // onStart={(...a) => console.log('onStart', ...a)}
                  onBuffer={onBuffer}
                  onBufferEnd={onBufferEnd}
                  onDuration={setDuration}
                  onPlaybackRateChange={goSetSpeed}
                />
              </ReactPlayerContainer>
            }

            {!!customControls &&
              <VideoPlayerCustomControls
                onClose={onClose}
                sourceLink={sourceLink}
                studyBibleLink={studyBibleLink}
                reactions={reactions}
                addBookmark={addBookmark}
                removeBookmark={removeBookmark}
                updateBookmark={updateBookmark}
                numComments={numComments}
                updateMyBookmarks={updateMyBookmarks}
                title={title}
                playing={playing}
                speed={speed}
                setSpeed={goSetSpeed}
                onPlay={onPlay}
                onPause={onPause}
                duration={duration}
                currentSecs={currentSecs}
                buffering={buffering}
                toggleFullscreen={toggleFullscreen}
                togglePip={pipEnabled && togglePip}
                goSeek={goSeek}
                isAudio={isAudio}
                extraDark={/youtube/.test(src || ``)}
                showControlsProps={showControlsProps}
                setDoAudio={setDoAudio}
                hasAudio={hasAudio}
                showSharing={showSharing}
                toggleShowSharing={toggleShowSharing}
                showBookmarking={showBookmarking}
                toggleShowBookmarking={toggleShowBookmarking}
                sharingText={sharingText}
              />
            }

        </Container3>
      </Container2>
    </Container1>
  )

}

export default memo(VideoPlayer)