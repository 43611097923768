import { memo, useState, useMemo, useCallback, useRef } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { useMeasure } from 'react-use'
import { getLocFromRef, getPreviousTranslationRef, getRefFromLoc,
         getStartAndEndVersesByChapter, getCorrespondingRefs } from '@bibletags/bibletags-versification'
import { getTextLanguageId } from "@bibletags/bibletags-ui-helper"
import { useApolloClient } from '@apollo/client'

import { getPassageTextSize, getPassageLineSpacing, getOrigVersionInfo } from '../../../utils/misc'
import useGoUndo from '../../../hooks/useGoUndo'
import useOutlineSettings from '../../../hooks/useOutlineSettings'
import useSetUpPiecesAfterPassageChangeOutlining from '../../../hooks/useSetUpPiecesAfterPassageChangeOutlining'
import useDataQuery from '../../../hooks/useDataQuery'
import useGoUpdateModulePiece from '../../../hooks/useGoUpdateModulePiece'
import usePreloadChapters from '../../../hooks/usePreloadChapters'
import useValueWithDelayedUpdate from '../../../hooks/useValueWithDelayedUpdate'
import useEqualObjsMemo from '../../../hooks/useEqualObjsMemo'
import useEffectAsync from '../../../hooks/useEffectAsync'

import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
// import NewToOutlining from './NewToOutlining'
import ModuleHeaderBackground from '../shared/ModuleHeaderBackground'
import OutlineOptionsChangePassage from './OutlineOptionsChangePassage'
import OutlineSettings from './OutlineSettings'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import OutlineContent from './OutlineContent'
import OutlineKeys from './OutlineKeys'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

import modulePiecesQuery from '../../../graphql/queries/modulePieces'
import Loading from '../../common/Loading'
import useVersionInfo from '../../../hooks/useVersionInfo'

const BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS = 30

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;

  ${({ $noneditableViewingMode }) => ![ `shared-page`, `sketch`].includes($noneditableViewingMode) ? `` : `
    padding: 0 15px;

    @media (max-width:600px) {
      padding: 0 10px;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    justify-content: flex-start;
  `}
`

const Outline = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  printOrDownloadInfo,
  goPrintOrDownload,
  // goPrintEncompassingNotesTab,  // not needed, since OutlineContent (which has the shortcut key items) is not rendered
  goSketch,
  onLoad,
  setHeight,
  embedFullScreen,
  ...otherProps
}) => {

  const { id, modulePassages } = module
  const { fromLoc: originalFromLoc=``, toLoc: originalToLoc=``, info={} } = modulePassages[0] || {}
  const { bookId } = getRefFromLoc(originalFromLoc)
  const { versionId, pastedInVerses } = info
  const virtuoso = useRef(null)

  const [ ref, { width: moduleWidth } ] = useMeasure()
  const outlineKeysMinimized = moduleWidth < 600

  const inEditingMode = module.inEditingMode && !noneditableViewingMode

  const origVersionInfo = useEqualObjsMemo(getOrigVersionInfo())
  const { version=origVersionInfo } = useVersionInfo(versionId)

  const [ fromLoc, toLoc ] = useMemo(
    () => {
      if(!originalFromLoc || !originalToLoc) return [ ``, `` ]
      return [ originalFromLoc, originalToLoc ].map((loc, idx) => (
        getLocFromRef(
          getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(loc),
              info: origVersionInfo,
            },
            lookupVersionInfo: version,
            directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
          }).at(idx === 0 ? 0 : -1)
        )
      ))
    },
    [ originalFromLoc, originalToLoc, version, origVersionInfo ]
  )

  // const [ containerRef, setContainerRef ] = useState(null)

  const client = useApolloClient()

  const onClick = useCallback(
    ({ target, currentTarget }) => {
      if(target && target.closest && target.closest(`[contenteditable]`)) return
      try {
        currentTarget.querySelector(`.OutlineContent-StyledVirtuoso`).focus()
      } catch(e) {}
    },
    [],
  )

  const onCreate = useCallback(
    ({ newData }) => {

      const queryAndVars = {
        query: modulePiecesQuery,
        variables: {
          moduleId: id,
        },
      }

      const { modulePieces } = client.readQuery(queryAndVars) || {}

      if(modulePieces.some(({ id }) => id === newData.id)) return

      client.writeQuery({
        ...queryAndVars,
        data: {
          modulePieces: [
            ...modulePieces,
            newData,
          ].sort((a,b) => a.ordering > b.ordering ? 1 : -1),
        },
        broadcast: true,
      })

    },
    [ id, client ],
  )

  const [ goCreateModulePiece ] = useGoUpdateModulePiece({
    moduleId: id,
    projectId,
    onUpdate: onCreate,
  })

  const { goUndo, goRedo, undoComponent } = useGoUndo({
    moduleId: id,
    projectId,
    onCreate,
  })

  const goPrintOrDownloadWithBaseParams = useCallback(
    params => {
      goPrintOrDownload({
        module,
        projectId,
        defaultImageWidth: 400,
        // defaultImageWidth: moduleWidth,  // use this when they want to print the content too?
        note: i18n("Available only for your actual outline.", "", "outline"),
        ...params,
      })
    },
    [ goPrintOrDownload, module, projectId ],
  )

  const { modulePieces=[], loading: loadingModulePieces } = useDataQuery({
    modulePiecesQuery,
    variables: {
      moduleId: id,
    },
  })

  useEffectAsync(
    () => {
      if(!loadingModulePieces && onLoad) {
        onLoad()
      }
    },
    [ loadingModulePieces ],
  )

  const chaptersReady = (
    useValueWithDelayedUpdate(
      usePreloadChapters({
        skip: !!(
          printOrDownloadInfo
          || noneditableViewingMode === `within-notes`
          || pastedInVerses
        ),
        preloadInfo: (
          versionId
            ? (
              [{
                fromLoc: originalFromLoc,
                toLoc: originalToLoc,
                versionId,
              }]
            )
            : []
        ),
      }),
      0,
      false,
    ) || !!pastedInVerses
  )

  const setUpPiecesAfterPassageChange = useSetUpPiecesAfterPassageChangeOutlining({
    moduleId: id,
    goCreateModulePiece,
    modulePieces,
  })

  const outlineSettings = useOutlineSettings({ moduleId: id })
  const {
    textSizesSetting,
    lineSpacingsSetting,
    formattingKeyIdSetting,
    outlineStyleSetting,
    outlineVisibilitySetting,
    formattingKeyViewSetting,
  } = outlineSettings

  const [ displayedTextSizes, setDisplayedTextSizes ] = useState(textSizesSetting.value)
  const [ displayedLineSpacings, setDisplayedLineSpacings ] = useState(lineSpacingsSetting.value)

  const { startAndEndVersesByChapter } = useMemo(() => getStartAndEndVersesByChapter({ versionInfo: version, bookId }), [ bookId, version ])

  const baseAutoBreakLocs = useMemo(
    () => {
      const baseAutoBreakLocs = []
      let currentVerseTally = 0
      for(let idx=0; idx<startAndEndVersesByChapter.length; idx++) {
        if(!startAndEndVersesByChapter[idx]) {
          continue
        } else if(currentVerseTally + startAndEndVersesByChapter[idx][1] >= BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS) {
          for(
            let verse = BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS - currentVerseTally;
            verse <= startAndEndVersesByChapter[idx][1];
            verse += BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS
          ) {
            const autoBreakLoc = getLocFromRef({
              bookId,
              chapter: idx + 1,
              verse,
            })
            if(autoBreakLoc < fromLoc) {
              // ignore this since it is before the passage start
            } else if(autoBreakLoc > toLoc) {
              // past the passage end
              break
            } else {
              baseAutoBreakLocs.push(autoBreakLoc)
            }
            currentVerseTally = startAndEndVersesByChapter[idx][1] - verse
          }
        } else {
          currentVerseTally += startAndEndVersesByChapter[idx][1]
        }
      }
      return baseAutoBreakLocs
    },
    [ bookId, fromLoc, toLoc, startAndEndVersesByChapter ],
  )

  const { rows } = useMemo(
    () => {

      const getNumVerseBetweenLocs = (loc1, loc2) => {
        const [ ref1, ref2 ] = [ loc1.split(':')[0], loc2.split(':')[0] ].sort().map(getRefFromLoc)
        if(ref1.chapter === ref2.chapter) {
          return ref2.verse - ref1.verse
        } else {
          let totalBetween = (startAndEndVersesByChapter[ref1.chapter - 1] || [0,0])[1] - ref1.verse
          for(let chapter=ref1.chapter+1; chapter<ref2.chapter; chapter++) {
            totalBetween += (startAndEndVersesByChapter[ref1.chapter - 1] || [0,0])[1]
          }
          totalBetween += ref2.verse
          return totalBetween
        }
      }

      const rows = [{
        fromLoc: `${fromLoc}:1`,
      }]

      let baseAutoBreakLocsIdx = 0
      const infoByOrdering = {}
      modulePieces.forEach(modulePiece => {

        const { position, ordering } = modulePiece
        const orderingStr = `${ordering}`
        const info = infoByOrdering[orderingStr] = infoByOrdering[orderingStr] || { modulePieces: {} }
        const locWithoutWord = `0${orderingStr.slice(0,-3)}`.slice(-8)
        info.fromLoc = `${locWithoutWord}:${parseInt(orderingStr.slice(-3))}`
        info.modulePieces[position] = modulePiece

        if(
          locWithoutWord < fromLoc
          || locWithoutWord > toLoc
        ) return

        if(position === `break`) {
          if(info.fromLoc === `${fromLoc}:1` && rows[0].fromLoc === info.fromLoc) {
            // this is a split before the first word in the text and so we want to get rid of the first block
            rows.shift()
          }

          const fromLocWithoutWordNum = info.fromLoc.split(':')[0]
          while(fromLocWithoutWordNum > baseAutoBreakLocs[baseAutoBreakLocsIdx]) {
            if(
              getNumVerseBetweenLocs(rows.at(-1).fromLoc, baseAutoBreakLocs[baseAutoBreakLocsIdx]) > BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS/2
              && getNumVerseBetweenLocs(baseAutoBreakLocs[baseAutoBreakLocsIdx], fromLocWithoutWordNum) > BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS/2
            ) {
              rows.push({
                fromLoc: `${baseAutoBreakLocs[baseAutoBreakLocsIdx]}:7`,  // making the break mid-verse to make it look less like a true break
              })
            }
            baseAutoBreakLocsIdx++
          }

          rows.push(info)
        }

        // from the db...
          // content (heading)
          // position (the type; namely /break|space|h[1-6]/; e.g. h2)
          // ordering: (based on loc, but with three extra digits for word number; e.g. 1001001001 for the first word in Gen 1:1)

        // example of what I want...
          // {
          //   modulePieces: {
          //     h1: [modulePiece],
          //     space: [modulePiece],
          //   },
          //   fromLoc,
          //   toLoc,
          //   toLocByPosition: {
          //     h1: [loc]
          //   },
          // }

      })

      const baseAutoBreakLocsIdxBeforeLoop = baseAutoBreakLocsIdx
      while(baseAutoBreakLocsIdx < baseAutoBreakLocs.length) {
        if(
          baseAutoBreakLocsIdx > baseAutoBreakLocsIdxBeforeLoop
          || getNumVerseBetweenLocs(rows.at(-1).fromLoc, baseAutoBreakLocs[baseAutoBreakLocsIdx]) > BASE_NUMBER_OF_VERSES_BETWEEN_AUTO_BREAKS/2
        ) {
          rows.push({
            fromLoc: `${baseAutoBreakLocs[baseAutoBreakLocsIdx]}:7`,  // making the break mid-verse to make it look less like a true break
          })
        }
        baseAutoBreakLocsIdx++
      }

      const getLocBeforeIdx = idx => {
        if(rows[idx]) {
          const [ nextFromLoc, nextFromLocWord=`1` ] = rows[idx].fromLoc.split(':')
          return (
            nextFromLocWord === `1`
              ? (
                getLocFromRef(
                  getPreviousTranslationRef({
                    loc: nextFromLoc,
                    info: version,
                  })
                )
              )
              : `${nextFromLoc}:${parseInt(nextFromLocWord, 10) - 1}`
          )
        } else {
          return toLoc
        }
      }

      rows.forEach((row, idx) => {
        if(/:1$/.test(row.fromLoc)) {
          row.fromLoc = row.fromLoc.split(':')[0]
        }
        row.toLoc = getLocBeforeIdx(idx+1)
        row.toLocByPosition = {}
        Object.keys(row.modulePieces || {}).forEach(position => {
          if(/^h[1-6]$/.test(position)) {
            const allHeadings = [ `h1`, `h2`, `h3`, `h4`, `h5`, `h6` ]
            const equalOrGreaterHeadings = allHeadings.slice(0, allHeadings.indexOf(position) + 1)
            let idxAddition = rows.slice(idx+1).findIndex(({ modulePieces={} }) => (
              equalOrGreaterHeadings.some(position => modulePieces[position])
            ))
            row.toLocByPosition[position] = (
              idxAddition === -1
                ? toLoc
                : getLocBeforeIdx(idx + 1 + idxAddition)
            )
          }
        })
      })

      return {
        rows,
      }

    },
    [ modulePieces, fromLoc, toLoc, version, startAndEndVersesByChapter, baseAutoBreakLocs ],
  )

  const roughNumberOfVerses = useMemo(
    () => {
      if(!fromLoc || !toLoc) return 0

      const { chapter: fromChapter, verse: fromVerse } = getRefFromLoc(fromLoc)
      const { chapter: toChapter, verse: toVerse } = getRefFromLoc(toLoc)
      let roughNumberOfVerses = (
        ((fromChapter === toChapter ? toVerse : startAndEndVersesByChapter[fromChapter - 1][1]) - fromVerse + 1)  // num verses in first chapter
        + (fromChapter !== toChapter ? toVerse : 0)  // num verses in last chapter
      )
      for(let chapter=fromChapter+1; chapter<=toChapter-1; chapter++) {
        roughNumberOfVerses += startAndEndVersesByChapter[chapter - 1][1]
      }

      return roughNumberOfVerses
    },
    [ fromLoc, toLoc, startAndEndVersesByChapter ],
  )

  useEffectAsync(
    () => {
      setDisplayedTextSizes(textSizesSetting.value)
    },
    [ textSizesSetting, setDisplayedTextSizes ],
  )

  useEffectAsync(
    () => {
      setDisplayedLineSpacings(lineSpacingsSetting.value)
    },
    [ lineSpacingsSetting, setDisplayedLineSpacings ],
  )

  const adjustmentType = (
    (displayedTextSizes !== textSizesSetting.value && `textsizes`)
    || (displayedLineSpacings !== lineSpacingsSetting.value && `linespacings`)
  )
  const languageId = getTextLanguageId({ ...version, bookId })
  const textFontSize = getPassageTextSize({ textSizes: displayedTextSizes, languageId })
  const lineSpacing = getPassageLineSpacing({ lineSpacingSizes: displayedLineSpacings, languageId })

  const loading = (
    loadingModulePieces
  )

  return (
    <ModuleContainer 
      ref={ref}
      noneditableViewingMode={noneditableViewingMode}
      onClick={onClick}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={i18n("New Outline", "", "outline")}
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={setUpPiecesAfterPassageChange}
          moduleWidth={moduleWidth}
          autoSelectEntireBook={true}
          // newOrLearning={<NewToOutlining />}
        />
      }

      {modulePassages.length > 0 &&
        <StyledContainerWithPassagePopper
          // ref={setContainerRef}
          $noneditableViewingMode={noneditableViewingMode}
          onTopOfAll
          // markup
          // updateMarkup
          // formattingKeyId
          inEditingMode={inEditingMode}
          bookId={bookId}
          versionId={versionId}
        >

          {!noneditableViewingMode && !printOrDownloadInfo &&
            <>

              <ModuleHeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                showVersionAbbr
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={goPrintOrDownloadWithBaseParams}
                goSketch={goSketch}
                goUndo={goUndo}
                width={otherProps.width}
                // absolutePositionedSection={
                // }
                topSection={
                  <OutlineOptionsChangePassage
                    module={module}
                    projectId={projectId}
                    setUpPiecesAfterPassageChange={setUpPiecesAfterPassageChange}
                  />
                }
                settingsSection={
                  <OutlineSettings
                    projectId={projectId}
                    versionId={versionId}
                    languageId={languageId}
                    displayedTextSizes={displayedTextSizes}
                    displayedLineSpacings={displayedLineSpacings}
                    setDisplayedTextSizes={setDisplayedTextSizes}
                    setDisplayedLineSpacings={setDisplayedLineSpacings}
                    {...outlineSettings}
                  />
                }
                $adjustmentType={adjustmentType}
              />

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode ===  `embed` &&
            <ModuleTitleButton
              module={module}
              width={otherProps.width}
              disabled
              showVersionAbbr
            />
          }

          {!printOrDownloadInfo && noneditableViewingMode !== `within-notes` &&
            <OutlineContent
              module={module}
              projectId={projectId}
              // containerRef={containerRef}
              roughNumberOfVerses={roughNumberOfVerses}
              rows={rows}
              pastedInVerses={pastedInVerses}
              setHeight={setHeight}
              chaptersReady={chaptersReady}
              goCreateModulePiece={goCreateModulePiece}
              inEditingMode={inEditingMode}
              noneditableViewingMode={noneditableViewingMode}
              textFontSize={textFontSize}
              lineSpacing={lineSpacing}
              goUndo={goUndo}
              goRedo={goRedo}
              goPrintOrDownload={goPrintOrDownloadWithBaseParams}
              virtuoso={virtuoso}
              embedFullScreen={embedFullScreen}
              {...outlineSettings}
            />
          }

          <OutlineKeys
            rows={rows}
            formattingKeyId={formattingKeyIdSetting.value}
            fromLoc={fromLoc}
            toLoc={toLoc}
            versionId={versionId}
            outlineStyle={outlineStyleSetting.value}
            outlineVisibility={outlineVisibilitySetting.value}
            formattingKeyView={formattingKeyViewSetting.value}
            inEditingMode={inEditingMode}
            noneditableViewingMode={noneditableViewingMode}
            outlineKeysMinimized={outlineKeysMinimized}
            printOrDownloadInfo={printOrDownloadInfo}
            virtuoso={virtuoso}
          />

          {loading && <Loading />}

        </StyledContainerWithPassagePopper>
      }

      {undoComponent}

    </ModuleContainer>
  )
}

export default memo(Outline)