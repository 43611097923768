import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import { cloneObj } from '../../../utils/misc'
import useFormattingKeys from '../../../hooks/useFormattingKeys'

import MarkupKeyLazyLoadPageViewer from './MarkupKeyLazyLoadPageViewer'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'

const None = styled.div`

`

const MarkupKeyPage = styled.div`
  padding: 0 15px;
`

const DividerLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  opacity: .5;
  margin: 10px 0;
`

const MarkupStyleLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MarkupStyle = styled.span`
  line-height: 1.8;
`

const MarkupColor = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const MarkupColorDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: ${({ theme, $color }) => theme.palette.markupColors[$color].arrow};
`

const StyledTextField = styled(TextField)`
  min-width: 0;
  flex: 1;

  .MuiInputBase-adornedStart {
    padding-left: 10px;
  }

  .MuiInputBase-input {
    padding: 5px 10px;
    font-size: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: -7px;
  cursor: default;
  user-select: none;

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 200;
    color: inherit;
  }
`

const MarkupKeyPages = ({
  currentFrmtgKeyIdSetting,
  formattingKeyInfosSetting,
  inEditingMode,
  pageKeyIndex,
}) => {

  const { formattingKeysById } = useFormattingKeys()

  const getPageByKey = useCallback(
    formattingKeyId => {
      const formattingKeyInfo = formattingKeyInfosSetting.value.find(({ id }) => id === formattingKeyId)
      if(!formattingKeyInfo) return null

      const {
        name=formattingKeyId,
        info,
      } = formattingKeysById[formattingKeyId] || {}

      const {
        styles=[],
        colorDefault={},
      } = info || {}

      let unusedColors = cloneObj((info || {}).colors || [])
      const colors = [
        ...formattingKeyInfo.colors.map(({ color, value }) => {
          let combinedColorObj = {
            color,
            value,
          }
          const formattingKeyColor = (info || {}).colors.find(colorObj => colorObj.color === color)
          if(formattingKeyColor) {
            unusedColors = unusedColors.filter(colorObj => colorObj.color !== color)
            return {
              ...formattingKeyColor,
              ...combinedColorObj,
            }
          } else {
            return combinedColorObj
          }
        }),
        ...unusedColors,
      ]

      const markup = []
      const stylesContent = (
        styles
          .map(({ markupType, label }, idx) => {

            const start = {
              loc: `01001001`,
              wordNumberInVerse: idx+1,
            }

            markup.push({
              start,
              end: start,
              type: markupType,
              color: `GREY`,
            })

            return (
              <MarkupStyleLine
                key={idx+1}
              >
                <MarkupStyle
                  data-word-loc={`01001001:${idx+1}`}
                >
                  {label}
                </MarkupStyle>
              </MarkupStyleLine>
            )
          })
      )

      return (
        <MarkupKeyPage>
 
          <TextContentMarkupContainer
            markup={markup}
          >
            {stylesContent}
          </TextContentMarkupContainer>

          <DividerLine />

          {colors.map(({ color, value, type, example }) => (
            <MarkupColor key={color}>
              <MarkupColorDot $color={color} />
              <StyledTextField
                variant="outlined"
                placeholder={example || i18n("Enter a label here")}
                value={value}
                onChange={null}
                InputProps={
                  type
                    ? {
                      startAdornment: (
                        <StyledInputAdornment position="start">
                          {i18n("{{category}}:", { category: type })}
                        </StyledInputAdornment>
                      ),
                    }
                    : null
                }
              />
            </MarkupColor>
          ))}

        </MarkupKeyPage>
      )
    },
    [ formattingKeyInfosSetting.value, formattingKeysById ],
  )

  if(formattingKeyInfosSetting.value.length === 0) {
    return (
      <None>
        {i18n("You do not have any lenses set up.")}
      </None>
    )
  }

  const getPageKey = idx => (formattingKeyInfosSetting.value[idx] || {}).id || null

  return (
    <MarkupKeyLazyLoadPageViewer
      pageKey={getPageKey(pageKeyIndex)}
      getPageByKey={getPageByKey}
      previousPageKey={getPageKey(pageKeyIndex - 1)}
      nextPageKey={getPageKey(pageKeyIndex + 1)}
      width={238}
    />
  )
}

export default memo(MarkupKeyPages)