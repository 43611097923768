import { memo, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuList from '@material-ui/core/MenuList'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PrintIcon from '@material-ui/icons/Print'
import GestureIcon from '@material-ui/icons/Gesture'
import GetAppIcon from '@material-ui/icons/GetApp'
import HistoryIcon from '@material-ui/icons/History'
import UndoIcon from '@material-ui/icons/Undo'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

import { IS_EMBED } from '../../../utils/constants'
import { isIOS, getModifierChar } from '../../../utils/misc'
import useGoUpdateModuleByProject from '../../../hooks/useGoUpdateModuleByProject'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useDuplicateModule from '../../../hooks/useDuplicateModule'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useMoveModuleTo from '../../../hooks/useMoveModuleTo'
import { getHasUndoItems } from '../../../hooks/useGoUndo'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getEmbedMode, getEmbedSettings } from '../../../graphql/links/embedLink'

import OptionsPopover from '../../common/OptionsPopover'
import SubtleDivider from '../../common/SubtleDivider'
import DangerMenuItem from '../../common/DangerMenuItem'
import TooltipNote from '../../common/TooltipNote'
import TextAndShortcutCombo from '../../common/TextAndShortcutCombo'
import SelectProjectAndModulePopover from './SelectProjectAndModulePopover'

const DownloadAsMessage = styled.div`
  padding: 3px 12px 7px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const ModuleActions = ({
  module,
  moduleByProject,
  onDeleteModuleByProject,
  goPrintOrDownload,
  goSketch,
  disableDownload,
  giveDownloadChoices,
  goUndo,
  onClose,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const { anchorEl: downloadAnchorEl, openModal: downloadOpenModal, closeModal: downloadCloseModal } = useModalAnchor()
  const { anchorEl: moveToAnchorlEl, openModal: moveToOpenModal, closeModal: moveToCloseModal } = useModalAnchor()

  const [ x, goDeleteModuleByProject ] = useGoUpdateModuleByProject({ moduleByProject })  // eslint-disable-line no-unused-vars
  const goDuplicateModule = useDuplicateModule({ module, moduleByProject })

  const goDuplicateModuleAndClose = useThrottleCallback(
    () => {
      goDuplicateModule()
      onClose()
    },
    500,
  )

  const goPrint = useThrottleCallback(
    () => {
      onClose()
      goPrintOrDownload({ action: `print` })
    },
    200,
  )

  const goDownload = useThrottleCallback(
    () => {
      onClose()
      goPrintOrDownload({ action: `download` })
    },
    200,
  )

  const goDownloadPDF = useThrottleCallback(
    () => {
      goPrintOrDownload({ action: `download`, type: `pdf` })
      downloadCloseModal()
    },
    200,
  )

  const goDownloadImage = useThrottleCallback(
    () => {
      goPrintOrDownload({ action: `download`, type: `image` })
      downloadCloseModal()
    },
    500,
  )

  const goSketchAndClose = useThrottleCallback(
    () => {
      onClose()
      goSketch({ module })
    },
    500,
  )

  const deleteModuleByProject = useThrottleCallback(
    () => {
      goDeleteModuleByProject()
      onDeleteModuleByProject({ moduleByProject })
    },
    500,
  )

  const goMoveModuleTo = useMoveModuleTo({
    module,
    onClose,
    onMoveComplete: ({ project }) => {
      goDeleteModuleByProject()
      onDeleteModuleByProject({ moduleByProject, project })
    },
  })

  const canAddAndDeleteModules = (
    hasToolsPlan
    || (
      IS_EMBED
      && (
        getEmbedMode() === `prep`
        || (
          getEmbedMode() === `edit`
          && (
            getEmbedSettings().allowStudentsToAddModules
            || module.type === `NOTES`
          )
        )
      )
    )
  )
  const canUndo = (
    hasToolsPlan
    || (
      IS_EMBED
      && getEmbedMode() !== `frozen`
    )
  )
  const hasUndoItems = getHasUndoItems(module.id)

  return (
    <>

      <List>

        <MenuItem
          onClick={goDuplicateModuleAndClose}
          disabled={!canAddAndDeleteModules}
        >
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Duplicate")}
        </MenuItem>

        {!IS_EMBED &&
          <MenuItem
            onClick={moveToOpenModal}
            disabled={!hasToolsPlan}
          >
            <ListItemIcon>
              <ArrowForwardIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Move to...")}
          </MenuItem>
        }

        <SelectProjectAndModulePopover
          anchorEl={moveToAnchorlEl}
          onClose={moveToCloseModal}
          onSelection={goMoveModuleTo}
          disabledModuleId={module.id}
          disabledProjectId={moduleByProject.projectId}
          chooseProjectOnly
        />

      </List>

      <SubtleDivider />

      <List>

        {!!goSketch && false &&
          <MenuItem
            onClick={goSketchAndClose}
          >
            <ListItemIcon>
              <GestureIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Sketch")}
          </MenuItem>
        }

        <MenuItem
          onClick={goPrint}
          disabled={giveDownloadChoices && isIOS}
        >
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <TextAndShortcutCombo
            text={i18n("Print")}
            shortcutCombo={`${getModifierChar()}P`}
            subtle
          />
        </MenuItem>

        {!disableDownload &&
          <MenuItem
            onClick={giveDownloadChoices ? downloadOpenModal : goDownload}
            disabled={giveDownloadChoices && isIOS}
          >
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            {giveDownloadChoices && i18n("Download")}
            {!giveDownloadChoices &&
              <TextAndShortcutCombo
                text={i18n("Download")}
                shortcutCombo={`${getModifierChar()}D`}
                subtle
              />
            }
          </MenuItem>
        }

        <OptionsPopover
          anchorEl={downloadAnchorEl}
          onClose={downloadCloseModal}
        >
          <MenuList>
            <DownloadAsMessage>
              {i18n("Download as...")}
            </DownloadAsMessage>
            <MenuItem
              onClick={goDownloadPDF}
            >
              {i18n("PDF")}
            </MenuItem>
            <MenuItem
              onClick={goDownloadImage}
            >
              {i18n("Image")}
            </MenuItem>
          </MenuList>
        </OptionsPopover>

      </List>

      <SubtleDivider />

      <List>

        {!!goUndo &&
          <Tooltip
            title={
              hasUndoItems
                ? ``
                : (
                  <TooltipNote
                    note={i18n("Cannot undo any further")}
                    secondaryNote={IS_EMBED ? `` : i18n("(use View History to see more)")}
                  />
                )
            }
          >
            <div>
              <MenuItem
                onClick={goUndo}
                disabled={!canUndo || !hasUndoItems}
              >
                <ListItemIcon>
                  <UndoIcon fontSize="small" />
                </ListItemIcon>
                <TextAndShortcutCombo
                  text={i18n("Undo")}
                  shortcutCombo={`${getModifierChar()}Z`}
                  subtle
                />
              </MenuItem>
            </div>
          </Tooltip>
        }

        {!IS_EMBED &&
          <Tooltip title="“View History” coming soon...">
            <div>
              <MenuItem
                // onClick={}
                disabled
              >
                <ListItemIcon>
                  <HistoryIcon fontSize="small" />
                </ListItemIcon>
                {i18n("View History")}
              </MenuItem>
            </div>
          </Tooltip>
        }

      </List>

      <SubtleDivider />

      <List>

        <DangerMenuItem
          onClick={deleteModuleByProject}
          disabled={!canAddAndDeleteModules}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          {i18n("Delete")}
        </DangerMenuItem>

      </List>

    </>
  )
}

export default memo(ModuleActions)