import { useCallback, useRef, memo } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useRefState from '../../../hooks/useRefState'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useThrottleCallback from '../../../hooks/useThrottleCallback'

const StyledTextField = styled(TextField)`
  background: ${({ theme }) => theme.palette.grey[100]};
  
  .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  .MuiInputBase-root {
    padding: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

const MarkupContentTextField = ({
  moduleSetting,
  projectId,
  ...otherProps
}) => {

  const [ currentValue, setCurrentValue, getCurrentValue ] = useRefState(``)
  const getModuleSettingValue = useInstanceValue(moduleSetting.value)
  const ref = useRef()

  const [ goSet ] = useGoSetModuleSetting({
    moduleSetting,
    projectId,
  })

  const goSave = useCallback(
    () => {
      if(getModuleSettingValue() !== getCurrentValue().trim()) {
        goSet({
          value: getCurrentValue().trim(),
        })
      }
    },
    [ getModuleSettingValue, getCurrentValue, goSet ],
  )

  const goThrottledSave = useThrottleCallback(goSave, 2000)

  const onChange = useCallback(
    ({ target }) => {
      setCurrentValue(target.value)
      goThrottledSave()
    },
    [ setCurrentValue, goThrottledSave ],
  )

  const onBlur = useCallback(
    () => {
      goSave()
      if(getCurrentValue().trim() !== getCurrentValue()) {
        setCurrentValue(getCurrentValue().trim())
      }
    },
    [ goSave, getCurrentValue ],
  )

  useEffectAsync(
    () => {
      if(
        ref.current !== document.activeElement  // i.e it is blurred
        && moduleSetting.value !== getCurrentValue()
      ) {
        setCurrentValue(moduleSetting.value)
      }
    },
    [ moduleSetting.value, getCurrentValue ],
  )

  return (
    <StyledTextField
      inputRef={ref}
      value={currentValue}
      multiline
      // minRows={3}
      onChange={onChange}
      onBlur={onBlur}
      variant="outlined"
      fullWidth
      {...otherProps}
    />
  )
}

export default memo(MarkupContentTextField)