import { useCallback, useState } from 'react'

const useModalAnchor = () => {

  const [ anchorEl, setAnchorEl ] = useState(null)
  const openModal = useCallback(({ currentTarget }) => setAnchorEl(currentTarget), [])
  const closeModal = useCallback(() => setAnchorEl(null), [])

  return {
    anchorEl,
    openModal,
    closeModal,
  }
}

export default useModalAnchor
