import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 28px;
  vertical-align: top;
  margin: 9px -3px 0 -3px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 400px;
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
`

const Welcome = styled.div`
  text-align: center;
  margin: 70px 0 40px;
  font-size: 22px;
  font-weight: 500;
`

const LogoImg = styled.img`
  height: 38px;
  vertical-align: top;
  margin: -5px -4px;
`

const UseFor = styled.div`
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.primary.faded};
  padding: 4px 15px;
  text-align: center;
`

const LogoImg2 = styled.img`
  height: 25px;
  vertical-align: top;
  margin: -2px -3px;
`

const UseForSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlternativelyUseFor = styled.div`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`

const FeaturesHeading = styled.div`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ::before, ::after {
    content: "";
    width: 30px;
    height: 1px;
    margin: 0 5px;
    background-image: linear-gradient(to right, rgb(0 0 0/.3) 0%, transparent 100%);
  }

  ::before {
    transform: rotate(180deg);
  }

`

const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
`

const Feature = styled.div`
  margin-top: 30px;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`

const Gif = styled.img`
  margin: 15px 0 30px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  width: 602px;
  max-width: calc(100vw - 20px);
  display: block;
`

const GoToBiblearcBibleButton = styled(LinkButton)`
  display: block;
  margin: 0 auto;
`

const Note = styled.div`
  margin: 15px 0;
  text-align: center;
  font-size: 15px;
`

const AboutTheBiblearcBible = ({ ...props }) => {

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("About The {{logo}} Bible", {
            logo: <HeaderLogoImg src="/logo.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("About")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          <Welcome>
            {i18nReact("Presenting The {{logo}} Bible", {
              logo: <LogoImg src="/logo.svg" />,
            })}
          </Welcome>

          <UseForSection>

            <UseFor>
              {i18nReact("Use The {{logo}} Bible for super-powered reading and searching.", {
                logo: <LogoImg2 src="/logo.svg" />,
              })}
            </UseFor>

            <AlternativelyUseFor>
              {i18nReact("(Use {{biblearc_tools}} for in-depth study of a passage or topic.)", {
                biblearc_tools: (
                  <NavLinkOrAWithDisable to="/about-tools" exact>
                    {i18n("Biblearc TOOLS")}
                  </NavLinkOrAWithDisable>
                ),
              })}
            </AlternativelyUseFor>
            <AlternativelyUseFor>
              {i18nReact("(Use {{biblearc_equip}} to learn deeper study of Scripture.)", {
                biblearc_equip: (
                  <NavLinkOrAWithDisable to="https://equip.biblearc.com">
                    {i18n("Biblearc EQUIP")}
                  </NavLinkOrAWithDisable>
                ),
              })}
            </AlternativelyUseFor>

          </UseForSection>

          <Features>

            <FeaturesHeading>
              {i18n("Features")}
            </FeaturesHeading>

            <Feature>
              {i18n("Read from 150+ versions")}
              <Gif src="/features_versions.gif" />
            </Feature>
            <Feature>
              {i18n("Utilize publisher cross-references and notes")}
              <Gif src="/features_cfs.gif" />
            </Feature>
            <Feature>
              {i18n("Compare versions and passages")}
              <Gif src="/features_compare.gif" />
            </Feature>
            <Feature>
              {i18n("Study with the original languages, including full Greek and Hebrew parsing")}
              <Gif src="/features_original.gif" />
            </Feature>
            <Feature>
              {i18n("Access our exhaustive ancient variant apparatus for the Greek NT")}
              <Gif src="/features_variants.gif" />
            </Feature>
            <Feature>
              {i18n("Search anything")}
              <Gif src="/features_search.gif" />
            </Feature>

          </Features>

          <Note>
            {i18nReact("Note: The Biblearc Bible does {{not}} require a subscription (or even an account).", {
              not: (
                <b>
                  {i18n("NOT")}
                </b>
              ),
            })}
          </Note>

          <GoToBiblearcBibleButton
            to="/"
            variant="contained"
            color="primary"
          >
            {i18n("Go to The Biblearc Bible")}
          </GoToBiblearcBibleButton>

        </Content>
      </Container>

    </>
  )
}


export default memo(AboutTheBiblearcBible)