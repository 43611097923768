import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import AddIcon from '@material-ui/icons/Add'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import { getNewOrdering } from '../../../utils/misc'
import useGoUpdateModulePassage from '../../../hooks/useGoUpdateModulePassage'
import useModalAnchor from '../../../hooks/useModalAnchor'

import VersionPopover from '../../common/VersionPopover'

const Container = styled.div`
  transition: background-color .15s ease-in-out;
  width: 75px;
  height: 75px;
  padding: 10px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  transition: background-color .15s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;

  :hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    cursor: pointer;
  }
`

const StyledAddIcon = styled(AddIcon)`
  display: block;
`

const LegacyDiscourseOptionsNewColumn = ({
  moduleId,
  projectId,
  modulePassages,
  setUpPiecesAfterPassageChange,
}) => {

  const [ goCreateModulePassage ] = useGoUpdateModulePassage({ moduleId, projectId })
  
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { fromLoc, toLoc } = modulePassages.at(-1)

  const getNewExtraWidthFactor = useCallback(
    () => parseInt(modulePassages.reduce((total, { info: { extraWidthFactor } }) => total + extraWidthFactor, 0) / modulePassages.length, 10),
    [ modulePassages ],
  )

  const onSelectVersion = useCallback(
    ({ versionId, pastedInVerses }) => {

      const { fromLoc, toLoc, ordering } = modulePassages.at(-1)

      const newModulePassage = goCreateModulePassage({
        fromLoc,
        toLoc,
        ordering: getNewOrdering(ordering),
        info: {
          versionId,
          visible: true,
          extraWidthFactor: getNewExtraWidthFactor(),
          ...(!pastedInVerses ? {} : {
            pastedInVerses,
          }),
        },
      })

      setUpPiecesAfterPassageChange({
        oldModulePassages: modulePassages,
        newModulePassage,
        savedAt: newModulePassage.savedAt,
      })

    },
    [ modulePassages, goCreateModulePassage, getNewExtraWidthFactor, setUpPiecesAfterPassageChange ],
  )

  const versionsAlreadyInUse = useMemo(
   () => modulePassages.map(({ info: { versionId } }) => versionId),
   [ modulePassages ],
  )

  return (
    <>

      <Container
        onClick={openModal}
      >
        <StyledAddIcon />
      </Container>

      <VersionPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        onSelectVersion={onSelectVersion}
        versionsAlreadyInUse={versionsAlreadyInUse}
        fromLoc={fromLoc}
        toLoc={toLoc}
        restrictToTestamentBookId={getRefFromLoc(fromLoc).bookId}
      />

    </>
  )
}

export default memo(LegacyDiscourseOptionsNewColumn)