import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useGoUpdateModulePiece from '../../../hooks/useGoUpdateModulePiece'
import useOutlineHeadings from '../../../hooks/useOutlineHeadings'

import OptionsPopover from '../../common/OptionsPopover'
import SubtleDivider from '../../common/SubtleDivider'
import DangerMenuItem from '../../common/DangerMenuItem'

const IconButtonContainer = styled.div`
  position: absolute;
  ${({ $isRTL }) => $isRTL ? `left: calc(-.2em - (max(.5em, 7px) * 2 + max(1em, 14px)))` : `right: -.2em`};
  top: 50%;
  display: flex;
  align-items: center;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  padding: max(.5em, 7px);
  font-size: inherit;

  .MuiSvgIcon-root {
    font-size: inherit;
    height: max(1em, 14px);
    width: max(1em, 14px);
  }
`

const CreateMessage = styled.div`
  padding: 3px 12px 7px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const OutlineHeadingOptionsIcon = ({
  modulePiece,
  projectId,
  headingTypesInUse,
  isRTL,
}) => {

  const [ goUpdateModulePiece, goDeleteModulePiece ] = useGoUpdateModulePiece({ modulePiece, projectId })

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const headings = useOutlineHeadings().filter(({ type }) => type !== `space`)

  const goChangeType = useThrottleCallback(
    ({ target }) => {
      closeModal()
      goUpdateModulePiece({
        position: target.closest(`[data-type]`).getAttribute(`data-type`),
      })
    },
    200,
  )

  const goDeleteHeading = useThrottleCallback(goDeleteModulePiece, 200)

  if(modulePiece.position === `space`) {
    return (
      <IconButtonContainer>
        <StyledIconButton
          onClick={goDeleteHeading}
          tabIndex={-1}
        >
          <DeleteIcon />
        </StyledIconButton>
      </IconButtonContainer>

    )
  }

  return (
    <>

      <IconButtonContainer
        $isRTL={isRTL}
      >
        <StyledIconButton
          onClick={openModal}
          tabIndex={-1}
        >
          <MoreVertIcon />
        </StyledIconButton>
      </IconButtonContainer>

      <OptionsPopover
        hideArrow
        anchorEl={anchorEl}
        onClose={closeModal}
      >

        <MenuList>

          <CreateMessage>
            {i18n("Change to...", "", "outline")}
          </CreateMessage>

          {headings.map(({ type, label }) => (
            <MenuItem
              key={type}
              onClick={goChangeType}
              disabled={headingTypesInUse.includes(type)}
              data-type={type}
            >
              <ListItemIcon>
                {type.toUpperCase()}
              </ListItemIcon>
              {label}
            </MenuItem>
          ))}

        </MenuList>

        <SubtleDivider />

        <MenuList>

          <DangerMenuItem
            onClick={goDeleteHeading}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            {i18n("Delete this heading", "", "outline")}
          </DangerMenuItem>

        </MenuList>

      </OptionsPopover>

    </>
  )
}

export default memo(OutlineHeadingOptionsIcon)