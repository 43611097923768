import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

import useMapEditorItem from '../../../hooks/useMapEditorItem'
import { GET_DEFAULT_EVENT } from './BibleMap'
import { preventDefaultEvent } from '../../../utils/misc'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'
import { placeTypeOptions } from './mapDataIsValid'

const EventsHeading = styled.div`
  font-size: 16px;
`

const EventRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  transition: padding-left .15s ease-in-out;

  :hover {
    padding-left: 10px;
    cursor: pointer;
  }
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 14px;
  margin: -1px 0 0 9px;
`

const None = styled.div`
  font-style: italic;
  margin: -10px 0;
  font-weight: 300;
`

const AddEventButton = styled(Button)`
  margin-bottom: 5px;
`

const BibleMapEditorPlace = ({
  mapLayerId,
  placeInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  setEventInEdit,
  ...otherProps
}) => {

  const inEdit = { ...placeInEdit }
  delete inEdit.events

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `place`,
    inEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    ...otherProps,
  })

  const { names, levelOfImportance, search, about, type } = edited

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  const createNewEvent = useCallback(
    event => {
      preventDefaultEvent(event)
      setEventInEdit(GET_DEFAULT_EVENT())
    },
    [ setEventInEdit ],
  )

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          {...commonProps}
        />

        <TextField
          value={`type ${type}`}
          label={i18n("Location Type")}
          select
          {...commonProps}
        >
          {placeTypeOptions.map(typeOption => (
            <MenuItem
              key={typeOption}
              value={`type ${typeOption}`}
            >
              {typeOption}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <TextField
          value={search || ``}
          data-keys={`search`}
          label={i18n("Search Text")}
          placeholder={i18n("E.g. {{example}}", { example: `jerusalem / salem` })}
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        <EventsHeading>
          {i18n("Events")}
        </EventsHeading>

        {placeInEdit.events.length === 0 &&
          <None>
            {i18n("None")}
          </None>
        }

        {placeInEdit.events.map(event => (
          <EventRow
            key={event.id}
            onClick={() => setEventInEdit(event)}
          >
            {event.names[0].name}
            {` `}
            ({event.dates[0].date})
            <StyledEditIcon />
          </EventRow>
        ))}

        <AddEventButton
          onClick={createNewEvent}
          {...commonProps}
          variant="contained"
          onChange={null}
          disableElevation
          {...(otherProps.placeId ? {} : {
            disabled: true,
          })}
        >
          {i18n("Add an Event")}
        </AddEventButton>

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorPlace)