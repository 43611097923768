import { memo, useCallback, useRef } from 'react'
import { i18nNumber } from "inline-i18n"
import styled from 'styled-components'

import useEffectAsync from '../../hooks/useEffectAsync'

const Container = styled.div`
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;

  ${({ $isSelected }) => !$isSelected ? `` : `
    background: black;
    color: white;
  `}

  ${({ $isSelected, theme }) => $isSelected ? `` : `
    @media (hover: hover) {
      :hover {
        cursor: pointer;
        background: ${theme.palette.grey[100]};
      }
    }
  `}
`

const ChapterChooserChapter = ({
  chapter,
  isSelected,
  setChapter,
}) => {

  const ref = useRef()

  useEffectAsync(
    () => {
      if(isSelected) {
        ref.current.scrollIntoView({ block: 'center' })
      }
    },
    [],
  )

  const onClick = useCallback(() => setChapter(chapter), [ setChapter, chapter ])

  return (
    <Container
      $isSelected={isSelected}
      onClick={onClick}
      ref={ref}
    >
      {i18nNumber({ num: chapter, type: 'formal' })}
    </Container>
  )
}

export default memo(ChapterChooserChapter)