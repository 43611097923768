import { useCallback, useState } from "react"
import { v4 as uuidv4 } from 'uuid'

import useGoUpdateModule from "./useGoUpdateModule"
import useDuplicateModuleSettings from './useDuplicateModuleSettings'
import useDuplicateModulePassages from './useDuplicateModulePassages'
import useDuplicateModulePieces from './useDuplicateModulePieces'
import useInstanceValue from "./useInstanceValue"
import { getNewOrdering, cloneObj } from '../utils/misc'

const useDuplicateModule = ({
  module,
  moduleByProject,
  newOrdering,
}) => {

  const getModule = useInstanceValue(module)

  const projectId = moduleByProject.id.split(':')[1]
  const oldOrdering = moduleByProject.ordering

  const [ newModuleId, setNewModuleId ] = useState(uuidv4())

  const [ goCreateModule ] = useGoUpdateModule({ projectId })

  const goDuplicateModulePassages = useDuplicateModulePassages({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModuleSettings = useDuplicateModuleSettings({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  const goDuplicateModulePieces = useDuplicateModulePieces({
    moduleToCopyFrom: module,
    newModuleId,
    projectId,
  })

  // TODO: Module dots need duplicating. They are mostly like module pieces
  // and I should thus create a useDuplicateModuleDots with bulkCreateModuleDots
  // that is similar to useDuplicateModulePieces. The difficulty is with the
  // modulePieceId key, as I will need to map those to match the new
  // module piece ids.

  const goDuplicateModule = useCallback(
    async () => {

      const ordering = newOrdering !== undefined ? newOrdering : getNewOrdering(oldOrdering)
      const newModuleData = cloneObj(getModule())
      delete newModuleData.modulePassages
      delete newModuleData.createdAt
      delete newModuleData.savedAt
      delete newModuleData.modifiedAt
      delete newModuleData.openedOrModifiedAt
      delete newModuleData.deletedAt
      delete newModuleData.userId
      delete newModuleData.__typename
      newModuleData.id = newModuleId
      newModuleData.shared = false
      newModuleData.public = false

      goCreateModule(
        newModuleData,
        { ordering },
      )

      goDuplicateModulePassages()
      await Promise.all([
        goDuplicateModuleSettings(),
        goDuplicateModulePieces(),
      ])

      setNewModuleId(uuidv4())

    },
    [ getModule, goCreateModule, goDuplicateModulePassages, goDuplicateModuleSettings, goDuplicateModulePieces, newModuleId, newOrdering, oldOrdering ],
  )

  return goDuplicateModule
}

export default useDuplicateModule
