import { memo, useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinkIcon from '@material-ui/icons/Link'
import CheckIcon from '@material-ui/icons/Check'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'

import useSetTimeout from '../../hooks/useSetTimeout'

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin: -20px 0 -20px 3px;
`

const StyledLinkIcon = styled(LinkIcon)`
  height: 15px;
  width: 15px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 13px;
  width: 13px;
  margin: -2px 2px -2px 0;
  color: ${({ theme }) => theme.palette.primary.light};
`

const CopySearchLinkIconButton = ({
  searchText,
  versionIds,
  includeOrIn,
  includeOrInRegex,
}) => {

  const [ copied, setCopied ] = useState(false)
  const [ setResetTimeout ] = useSetTimeout()
  
  const onClick = useCallback(
    () => {
      const includeAddOn = ` ${includeOrIn}:${versionIds.length > 0 ? versionIds.join(',').toUpperCase() : `none`}`
      const newSearchText = `${searchText.replace(includeOrInRegex, '').replace(/  +/g, ' ')}${includeAddOn}`
      copy(`${window.location.origin}/#search=${encodeURIComponent(newSearchText)}`)
      setCopied(true)
    },
    [ versionIds, includeOrIn, includeOrInRegex, searchText ],
  )

  const reset = useCallback(() => setResetTimeout(() => setCopied(false), 500), [ setResetTimeout ])

  return (
    <Tooltip
      title={
        copied
          ? (
            <>
              <StyledCheckIcon />
              {i18n("Copied")}
            </>
          )
          : i18n("Copy search link")
      }
    >
      <StyledIconButton
        onClick={onClick}
        onMouseLeave={reset}
      >
        <StyledLinkIcon />
      </StyledIconButton>
    </Tooltip>
  )
}

export default memo(CopySearchLinkIconButton)