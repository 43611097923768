import { useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'

import undoComponentsByAction from '../utils/undoComponentsByAction'
import CustomSnackbar from '../components/common/CustomSnackbar'

const undoSetsByModuleId = {}
const redoSetsByModuleId = {}
let skipClearingRedoStack = false

export const pushUndoRedoItem = ({
  type,
  action,
  data,
  ...otherParams
}) => {

  // console.log(`Setting undo item`, action, data, otherParams)
  const setsByModuleId = type === `undo` ? undoSetsByModuleId : redoSetsByModuleId
  const now = Date.now()
  const { moduleId } = data

  if(!moduleId) {
    console.error(`Bad call to pushUndoItem`, action, data, otherParams)
    return
  }

  if(type === `undo` && !skipClearingRedoStack) {
    delete redoSetsByModuleId[moduleId]
  }

  const undoRedoSets = setsByModuleId[moduleId] = setsByModuleId[moduleId] || []

  if(undoRedoSets.length > 0 && undoRedoSets.at(-1).timestamp > now - 100) {
    undoRedoSets.at(-1).items.push({
      action,
      data,
      ...otherParams,
    })
  } else {
    undoRedoSets.push({
      timestamp: now,
      items: [{
        action,
        data,
        ...otherParams,
      }],
    })
  }

}

export const getHasUndoItems = moduleId => (undoSetsByModuleId[moduleId] || []).length > 0
export const clearUndoItems = moduleId => delete undoSetsByModuleId[moduleId]

const NullComponent = () => null

const UndoComponent = ({
  undoSet={ items: [] },
  projectId,
  snackbarInfo,
  ...otherProps
 }) => (
  <>

    {undoSet.items.map((undoItem, idx) => {
      const Component = undoComponentsByAction[undoItem.action] || NullComponent

      return (
        <Component
          key={idx}
          projectId={projectId}
          undoRedoStack={undoSet.undoRedoStack}
          {...otherProps}
          {...undoItem}
        />
      )
    })}

    <CustomSnackbar {...snackbarInfo} />

  </>
)

const useGoUndo = ({
  moduleId,
  projectId,
  ...otherProps
 }) => {

  const [ undoSet, setUndoSet ] = useState()
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })

  const goUndoOrRedo = useCallback(
    ({ setsByModuleId, noneMsg, undoRedoStack }) => {
      if((setsByModuleId[moduleId] || []).length === 0) {
        const closeSnackbar = () => setSnackbarInfo({ ...newSnackbarInfo, open: false })
        const newSnackbarInfo = {
          open: true,
          message: noneMsg,
          onClose: closeSnackbar,
          hideButton: true,
        }
        setSnackbarInfo(newSnackbarInfo)
      } else {
        setUndoSet({ ...setsByModuleId[moduleId].pop(), undoRedoStack })
        setTimeout(() => setUndoSet())
      }
    },
    [ moduleId ],
  )

  const goUndo = useCallback(
    () => {
      goUndoOrRedo({
        setsByModuleId: undoSetsByModuleId,
        noneMsg: i18n("Cannot undo any further"),
        undoRedoStack: `redo`,  // this is the stack I should add on to
      })
    },
    [ goUndoOrRedo ],
  )

  const goRedo = useCallback(
    () => {
      skipClearingRedoStack = true
      goUndoOrRedo({
        setsByModuleId: redoSetsByModuleId,
        noneMsg: i18n("Nothing further to redo"),
        undoRedoStack: `undo`,  // this is the stack I should add on to
      })
      skipClearingRedoStack = false
    },
    [ goUndoOrRedo ],
  )

  return {
    goUndo,
    goRedo,
    undoComponent: (
      <UndoComponent
        undoSet={undoSet}
        projectId={projectId}
        snackbarInfo={snackbarInfo}
        {...otherProps}
      />
    ),
  }
}

export default useGoUndo



/*

STILL NEED TO DO THE FOLLOWING (when I actually have something to test it by):
  moduleDot
  moduleMarkup
  useGoBulkCreateModulePieces (used in new Phrasing)
  useGoUpdateModulePieces (used in new Phrasing)

  For the following, indicate that they will need to use module history
    deleteModulePieces
    deleteModuleDots
    deleteModuleMarkups

*/