import { memo, useCallback, useRef } from 'react'
import styled from 'styled-components'

import useOutlineHeadings from '../../../hooks/useOutlineHeadings'
import useGoUpdateModulePiece from '../../../hooks/useGoUpdateModulePiece'
import { isFirefox, selectTextOfEl } from '../../../utils/misc'

import OutlineHeadingOptionsIcon from './OutlineHeadingOptionsIcon'

const TextContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: calc(100% - ${({ $isCentered }) => $isCentered ? 2 : 1} * (max(.5em, 7px) * 2 + max(1em, 14px) + .2em));  // 100% - double (in case it is centered) the button (padding on each side + the icon)
`

const Text = styled.span`
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  box-sizing: content-box;
  padding: 0 .2em;
  margin: 0 -.2em;

  :focus {
    outline: none;
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
`

const OutlineHeading = ({
  modulePiece,
  projectId,
  headingTypesInUse,
  inEditingMode,
  isRTL,
}) => {

  const spanRef = useRef()

  const [ goUpdateModulePiece ] = useGoUpdateModulePiece({ modulePiece, projectId })

  const { Component: Heading, label, isCentered, type } = useOutlineHeadings().find(({ type }) => type === modulePiece.position)
  const placeholder = `[ ${label} ]`
  const contentEditable = (inEditingMode && type !== `space`) ? (isFirefox ? true : `plaintext-only`) : false
  const content = modulePiece.content || (inEditingMode ? placeholder : ` `)
  const isPlaceholder = content === placeholder

  const onFocus = useCallback(
    () => {
      if(isPlaceholder) {
        selectTextOfEl(spanRef.current)
      }
    },
    [ isPlaceholder ],
  )

  const onBlur = useCallback(
    () => {
      let content = spanRef.current.textContent.trim()
      if(content === placeholder) {
        content = ``
      }
      if(modulePiece.content !== content) {
        goUpdateModulePiece({
          content,
        })
      } else if((content || placeholder) !== spanRef.current.textContent) {
        spanRef.current.textContent = content || placeholder
      }
    },
    [ modulePiece, goUpdateModulePiece, placeholder ],
  )

  const onInput = useCallback(
    event => {
      if(event.target.firstElementChild) {
        // should only get here on FF due to contentEditable="plaintext-only" below 
        event.target.textContent = event.target.textContent  // eslint-disable-line no-self-assign
      }
    },
    [],
  )

  const onKeyDown = useCallback(
    event => {

      const doBlur = () => {
        try {
          event.target.closest(`.OutlineContent-StyledVirtuoso`).focus()
        } catch(e) {
          event.target.blur()
        }
        window.getSelection().empty()
      }

      // handle enter (without shift) and esc by blurring
      if(event.key === `Enter` && !event.shiftKey) {
        event.preventDefault()
        doBlur()
      } else if(event.key === `Escape`) {
        event.preventDefault()
        event.target.textContent = modulePiece.content || placeholder
        doBlur()
      }

    },
    [ modulePiece, placeholder ],
  )

  return (
    <Heading
      $isRTL={isRTL}
      className="options-popper-no-pointer-events"
    >
      <TextContainer $isCentered={isCentered}>

        {!contentEditable && <Text>{content}</Text>}

        {!!contentEditable &&
          <Text
            id={`OutlineHeading-${modulePiece.position}-${modulePiece.ordering}`}
            ref={spanRef}
            contentEditable={contentEditable}
            onInput={onInput}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            tabIndex={-1}
            dangerouslySetInnerHTML={{
              __html: (
                content
                  .replace(/&/g, '&amp;')
                  .replace(/</g, '&lt;')
                  .replace(/>/g, '&gt;')
                  .replace(/"/g, '&quot;')
              ),
            }}
          />
        }

        {inEditingMode &&
          <OutlineHeadingOptionsIcon
            modulePiece={modulePiece}
            projectId={projectId}
            headingTypesInUse={headingTypesInUse}
            isRTL={isRTL}
          />
        }

      </TextContainer>
    </Heading>
  )
}

export default memo(OutlineHeading)