import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
  background-color: ${({ theme, $color }) => theme.palette.markupColors[$color].highlight};
  margin: 0 -.15em;
  padding: 0 .15em;
  z-index: -8;
`

const NormalHighlight = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    $color={color}
  />
)


export default memo(NormalHighlight)