import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
  border-bottom: .08em solid ${({ theme, $color }) => theme.palette.markupColors[$color].line};
  transform: translateY(-.15em);
  z-index: -4;
`

const NormalUnderline = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    $color={color}
  />
)


export default memo(NormalUnderline)