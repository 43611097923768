import { memo, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import HeightIcon from '@material-ui/icons/Height'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useThrottleCallback from '../../../hooks/useThrottleCallback'
import useGoUpdateModulePiece from '../../../hooks/useGoUpdateModulePiece'
import useOutlineHeadings from '../../../hooks/useOutlineHeadings'
import { selectTextOfEl } from '../../../utils/misc'

import OptionsPopover from '../../common/OptionsPopover'
import SubtleDivider from '../../common/SubtleDivider'
import DangerMenuItem from '../../common/DangerMenuItem'

const StyledIconButton = styled(IconButton)`
  padding: max(.4em, 7px);
  font-size: inherit;
  margin: -10em min(-.15em, -3px);  // so it doesn't expand the height at all

  ${({ $inEditingMode }) => $inEditingMode ? `` : `
    visibility: hidden;
  `}

  .MuiSvgIcon-root {
    font-size: inherit;
    height: max(.8em, 14px);
    width: max(.8em, 14px);
  }
`

const OutlineBreakOptionsIcon = ({
  inEditingMode,
  modulePieces,
  projectId,
  goCreateModulePiece,
}) => {

  const { moduleId } = modulePieces.break
  const deleteModulePieceFuncs = [
    useGoUpdateModulePiece({ modulePiece: modulePieces.break, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h1, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h2, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h3, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h4, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h5, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.h6, moduleId, projectId })[1],
    useGoUpdateModulePiece({ modulePiece: modulePieces.space, moduleId, projectId })[1],
  ].filter(Boolean)

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const goAddHeading = useThrottleCallback(
    ({ target }) => {

      const position = target.closest(`[data-type]`).getAttribute(`data-type`)
      const { ordering } = modulePieces.break

      closeModal()
      goCreateModulePiece({
        position,
        ordering,
      })

      if(/^h/.test(position)) {
        setTimeout(() => selectTextOfEl(`#OutlineHeading-${position}-${ordering}`))
      }
    },
    200,
  )

  const goDeleteDivision = useThrottleCallback(() => deleteModulePieceFuncs.forEach(func => func()), 200)

  const headingStatusByType = useMemo(
    () => {
      const headingStatusByType = {}
      Object.keys(modulePieces || {}).forEach(type => {
        headingStatusByType[type] = true
      })
      return headingStatusByType
    },
    [ modulePieces ],
  )

  const headings = useOutlineHeadings().filter(({ type }) => type !== `space`)

  return (
    <>

      <StyledIconButton
        onClick={openModal}
        $inEditingMode={inEditingMode}
        tabIndex={-1}
      >
        <MoreVertIcon />
      </StyledIconButton>

      <OptionsPopover
        hideArrow
        anchorEl={anchorEl}
        onClose={closeModal}
      >
        <MenuList>

          {headings.map(({ type, label }) => (
            <MenuItem
              key={type}
              onClick={goAddHeading}
              disabled={!!headingStatusByType[type]}
              data-type={type}
            >
              <ListItemIcon>
                {type.toUpperCase()}
              </ListItemIcon>
              {label}
            </MenuItem>
          ))}

        </MenuList>

        <SubtleDivider />

        <MenuList>

          <MenuItem
            onClick={goAddHeading}
            disabled={!!headingStatusByType.space}
            data-type="space"
          >
            <ListItemIcon>
              <HeightIcon />
            </ListItemIcon>
            {i18n("Add extra space", "", "outline")}
          </MenuItem>

        </MenuList>

        <SubtleDivider />

        <MenuList>

          <DangerMenuItem
            onClick={goDeleteDivision}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            {i18n("Delete this division", "", "outline")}
          </DangerMenuItem>

        </MenuList>

      </OptionsPopover>

    </>
  )
}

export default memo(OutlineBreakOptionsIcon)