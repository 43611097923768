import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

import projectQuery from '../graphql/queries/project'
import updateModulePassageMutation from '../graphql/mutations/updateModulePassage'
import deleteModulePassageMutation from '../graphql/mutations/deleteModulePassage'

export const getDefaultModulePassage = () => ({
  __typename: `ModulePassage`,
  info: null,
})

const useGoUpdateModulePassage = ({
  modulePassage,
  projectId,
  moduleId,
  undoRedoStack,
  isUpdate,
}) => {

  moduleId = moduleId || modulePassage.moduleId

  const client = useApolloClient()

  const [ updateModulePassage, updateModulePassageResult ] = useMutation(updateModulePassageMutation)
  const [ deleteModulePassage, deleteModulePassageResult ] = useMutation(deleteModulePassageMutation)

  const goUpdate = useGoUpdateTable({
    currentData: modulePassage || getDefaultModulePassage(),
    updateFunc: updateModulePassage,
    updateResult: updateModulePassageResult,
    deleteFunc: deleteModulePassage,
    deleteResult: deleteModulePassageResult,
    projectId,
    moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => {
      if(!modulePassage && !isUpdate) {
        return [
          initData => {
            // Create a new modulePassage

            const newData = goUpdate[0]({
              ...initData,
              moduleId,
            })

            const { project } = client.readQuery({
              query: projectQuery,
              variables: {
                id: projectId,
              },
            })

            const data = {
              project: {
                ...project,
                moduleByProjects: project.moduleByProjects.map(moduleByProject => {
                  if(moduleByProject.module.id === moduleId) {
                    const { module } = moduleByProject
                    const { modulePassages } = module
                    return {
                      ...moduleByProject,
                      module: {
                        ...module,
                        modulePassages: [
                          ...modulePassages,
                          newData,
                        ],
                      },
                    }
                  }
                  return moduleByProject
                }),
              },
            }

            client.writeQuery({
              query: projectQuery,
              data,
              variables: {
                id: newData.projectId,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ modulePassage, isUpdate, projectId, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModulePassage