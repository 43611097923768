import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import useInstanceValue from './useInstanceValue'
import useAppSize from './useAppSize'
import useSetTimeout from './useSetTimeout'
import useEffectAsync from './useEffectAsync'
import { cloneObj } from '../utils/misc'

const ColumnChooserIconButton = styled(IconButton)`
  position: absolute;
  bottom: 30px;
  left: calc(50% - 50px);
  background: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgb(100 100 100);
  z-index: 15;
  transition: opacity .3s ease-in-out;
  opacity: ${({ $hide }) => $hide ? 0 : 1};

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ onClick, theme }) => onClick ? `` : `
    background: ${theme.palette.primary.light} !important;
    cursor: default;
  `}

  .MuiSvgIcon-root {
    height: 48px;
    width: 48px;
  }
`

const useOpenPassage = ({
  getPassageBookmarks,
  setPassageBookmarks,
  expandPassageArea,
  numColumns,
}={}) => {

  const location = useLocation()
  const history = useHistory()

  const getLocation = useInstanceValue(location)
  const { mobileSize } = useAppSize()

  const [ openPassageColumnChoosers, setOpenPassageColumnChoosers ] = useState([])

  const [ setFlashColumnIndicatorTimeout ] = useSetTimeout()

  useEffectAsync(
    () => {
      const { passageToOpen } = location.state || {}

      if(passageToOpen) {

        if(expandPassageArea) {

          expandPassageArea()

        } else if(getPassageBookmarks && setPassageBookmarks && numColumns) {

          const launchPassage = columnIdx => {
            const newPassageBookmarks = [ ...getPassageBookmarks() ]

            const passageBookmarkToReplace = cloneObj(newPassageBookmarks[columnIdx])

            if(passageBookmarkToReplace.ref) {
              delete passageBookmarkToReplace.width
              delete passageBookmarkToReplace.showStudyBible
              newPassageBookmarks.splice(numColumns, 0, passageBookmarkToReplace)
            }

            const { refs, versionId, specialMarkup } = passageToOpen
            const { bookId, chapter } = refs[0]
            newPassageBookmarks[columnIdx] = {
              ...newPassageBookmarks[columnIdx],
              ...(!versionId ? {} : { versionId }),
              ref: {
                bookId,
                chapter,
              },
              specialMarkup,
              scrollYFraction: 0,
              id: uuidv4(),
            }

            setPassageBookmarks(newPassageBookmarks)
          }

          if(numColumns === 1) {
            launchPassage(0)
            const setFlashComponent = hide => {
              setOpenPassageColumnChoosers([
                <ColumnChooserIconButton
                  key="loneColumn"
                  $hide={hide}
                >
                  <ArrowUpwardIcon />
                </ColumnChooserIconButton>
              ])
            }
            setFlashComponent(true)
            setFlashColumnIndicatorTimeout(() => {
              setFlashComponent(false)
              setFlashColumnIndicatorTimeout(() => {
                setFlashComponent(true)
                setFlashColumnIndicatorTimeout(() => {
                  setOpenPassageColumnChoosers([])
                }, 300)
              }, 600)
            }, 150)
          } else {
            setOpenPassageColumnChoosers(
              Array(numColumns).fill().map((x, idx) => (
                <ColumnChooserIconButton
                  key={idx}
                  onClick={() => {
                    setOpenPassageColumnChoosers([])
                    launchPassage(idx)
                  }}
                >
                  <ArrowUpwardIcon />
                </ColumnChooserIconButton>
              ))
            )
          }

          const newState = { ...location.state }
          delete newState.passageToOpen
          history.replace({
            ...location,
            state: newState,
          })

        }
      }
    },
    [ location, getPassageBookmarks, setPassageBookmarks, numColumns, history ],
  )

  const openPassage = useCallback(
    (passageToOpen, locationOverrides={}) => {
      if(passageToOpen.refs) {

        const location = getLocation()

        if(
          location.pathname !== `/`  // not home
          && !/^\/church\/[^/]+$/.test(location.pathname)
          && (
            !/^\/project\//.test(location.pathname)
            || mobileSize
          )
        ) {
          history.push({
            pathname: (
              /^\/church\/[^/]+/.test(location.pathname)
                ? `/church/${location.pathname.split(`/`)[2]}`
                : `/`
            ),
            state: {
              passageToOpen,
            },
          })

        } else {
          history.replace({
            ...location,
            ...locationOverrides,
            state: {
              ...location.state,
              passageToOpen,
            },
          })
        }

      }
    },
    [ getLocation, history, mobileSize ],
  )

  return { openPassage, openPassageColumnChoosers }
}

export default useOpenPassage