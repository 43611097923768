import { memo, useContext, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import Tooltip from '@material-ui/core/Tooltip'
import { useMeasure } from 'react-use'

import PassageClose from './PassageClose'
import PassageNextPreviousButtons from './PassageNextPreviousButtons'

import PassageRefButton from './PassageRefButton'

import { LoggedInUserContext } from '../../context/LoggedInUser'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 2;
  overflow: hidden;
`

const ContainerForAllButClose = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 48px;
  margin-right: 12px;
`

const Spacer = styled.div`
  flex: 1;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 4px 0;

  ${({ $showStudyBible, theme }) => !$showStudyBible ? `` : `
    color: ${theme.palette.primary.main};
  `}

  .MuiSvgIcon-root {
    transform: rotate(180deg);
  }
`

const PassageHeader = ({
  updatePassageBookmark,
  closeColumn,
  bookId,
  chapter,
  versionId,
  parallelVersionId,
  showStudyBible,
  setThisAsDragColumn,
  clearDragColumn,
  columnIdx,
  channelIdOrUrl,
}) => {

  const { adminLevel } = useContext(LoggedInUserContext) || {}
  const [ containerRef, { width } ] = useMeasure()

  const toggleShowStudyBible = useCallback(
    () => {
      updatePassageBookmark({
        showStudyBible: !showStudyBible,
      })
    },
    [ updatePassageBookmark, showStudyBible ],
  )

  return (
    <Container ref={containerRef}>

      <ContainerForAllButClose>

        <PassageRefButton
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          parallelVersionId={parallelVersionId}
          updatePassageBookmark={updatePassageBookmark}
          setThisAsDragColumn={setThisAsDragColumn}
          clearDragColumn={clearDragColumn}
          abbreviated={width < 430}
        />

        <Spacer />

        {!channelIdOrUrl && ([ `ADMIN`, `EDITOR`, `CONTRIBUTOR` ].includes(adminLevel) || process.env.REACT_APP_STAGE === `development`) &&  // when I remove this, also remove from SettingsLeftPanel, PassageArea (and elsewhere??)
          <Tooltip
            title={
              showStudyBible
                ? i18n("Hide Study Bible items")
                : i18n("Show Study Bible items")
            }
          >
            <StyledIconButton
              $showStudyBible={showStudyBible}
              onClick={toggleShowStudyBible}
              // onMouseDown={preventDefaultEvent}
              tabIndex={-1}
            >
              <VerticalSplitIcon />
            </StyledIconButton>
          </Tooltip>
        }

        <PassageNextPreviousButtons
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          updatePassageBookmark={updatePassageBookmark}
          columnIdx={columnIdx}
        />

      </ContainerForAllButClose>

      {!!closeColumn &&
        <PassageClose
          closeColumn={closeColumn}
        />
      }

    </Container>
  )
}

export default memo(PassageHeader)