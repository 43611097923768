import { memo, useCallback, useState, useRef, useMemo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getStroke } from 'perfect-freehand'

import getSvgPathFromStroke from '../../utils/getSvgPathFromStroke'
import useInstanceValue from '../../hooks/useInstanceValue'
import { sketchUtensilInfos, pressureUtensilInfos } from '../../utils/formatting'

const Container = styled.div`
  position: relative;
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
`

const Path = styled.path`
  fill: ${({ theme, $color, $utensil }) => (theme.palette.markupColors[$color] || {})[$utensil === `HIGHLIGHTER` ? `highlight` : `arrow`] || $color};
  opacity: ${({ $selected }) => $selected ? .5 : 1};
`

const getAdjustedStroke = (...params) => {

  const stroke = getStroke(...params)
    .map(point => (
      point.map(xOrY => (
        Math.round(xOrY * 100, 10) / 100
      ))
    ))

  return stroke
}

const FreehandContainer = ({
  children,
  svgStrokes=[],
  updateSvgStrokes,
  selectedStrokeIdx,
  options,
  ...otherProps
}) => {

  const svgRef = useRef()
  const svgBoundingClientRect = useRef({})

  const [ currentPoints, setCurrentPoints ] = useState()
  const getCurrentPoints = useInstanceValue(currentPoints)

  const [ simulatePressure, setSimulatePressure ] = useState(true)

  const utensilInfo = useMemo(
    () => {
      const info = sketchUtensilInfos[(options || {}).utensil] || sketchUtensilInfos.MARKER
      if(info.pressureOverrideOn && !simulatePressure) {
        return pressureUtensilInfos[(options || {}).utensil] || pressureUtensilInfos.MARKER
      }
      return info
    },
    [ options, simulatePressure ],
  )
  

  const handlePointerDown = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      svgBoundingClientRect.current = svgRef.current.getBoundingClientRect()
      event.target.setPointerCapture(event.pointerId)
      setCurrentPoints([[
        event.pageX - svgBoundingClientRect.current.x,
        event.pageY - svgBoundingClientRect.current.y,
        event.pressure,
      ]])
      setSimulatePressure(!utensilInfo.pressureOverrideOn || event.pressure === 0.5)  // 0.5 indicates that hardware does not support pressure
    }, 
    [ utensilInfo ],
  )

  const handlePointerMove = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      if(!getCurrentPoints()) return  // invalid if the sketch starts off the image
      if(event.buttons !== 1) return
      setCurrentPoints([
        ...getCurrentPoints(),
        [
          event.pageX - svgBoundingClientRect.current.x,
          event.pageY - svgBoundingClientRect.current.y,
          event.pressure,
        ],
      ])
    },
    [ getCurrentPoints ],
  )

  const handlePointerUp = useCallback(
    event => {
      if(event.buttons === undefined) return  // prevent ios "scribble" call
      if(!getCurrentPoints()) return  // invalid if the sketch starts off the image
      if(event.buttons !== 0) return
      updateSvgStrokes([
        ...svgStrokes,
        {
          stroke: (
            getAdjustedStroke(
              getCurrentPoints(),
              {
                ...utensilInfo,
                simulatePressure,
              },    
            )
          ),
          options,
        },
      ])
      setCurrentPoints()
    },
    [ svgStrokes, options, utensilInfo, simulatePressure, getCurrentPoints, updateSvgStrokes ],
  )

  return (
    <Container {...otherProps}>

      {children}

      <Svg
        ref={svgRef}
        onPointerDown={updateSvgStrokes ? handlePointerDown : null}
        onPointerMove={updateSvgStrokes ? handlePointerMove : null}
        onPointerUp={updateSvgStrokes ? handlePointerUp : null}
      >
        {svgStrokes.map(({ stroke, options }, idx) => (
          <Path
            key={idx}
            d={getSvgPathFromStroke(stroke)}
            $color={options.color}
            $utensil={options.utensil}
            $selected={idx === selectedStrokeIdx}
          />
        ))}
        {!!currentPoints &&
          <Path
            d={
              getSvgPathFromStroke(
                getAdjustedStroke(
                  currentPoints,
                  {
                    ...utensilInfo,
                    simulatePressure,
                  }
                )
              )
            }
            $color={options.color}
            $utensil={options.utensil}
          />
        }
      </Svg>

    </Container>
  )
}

export default memo(FreehandContainer)