import React, { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { normalizeSearchStr } from '@bibletags/bibletags-ui-helper'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'

import { cloneObj, getVersionIdForBibleTags, getLinkFromManuscript, getManuscriptAbbr } from '../../utils/misc'
import { getManuscriptInfoStr } from '../../utils/manuscriptInfo'
import useAccountSetting from '../../hooks/useAccountSetting'

import PassagePopperContainer from './PassagePopperContainer'
import PassagePopperHeading from './PassagePopperHeading'
import ApparatusPopperContentCaseToggle from './ApparatusPopperContentCaseToggle'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'

const StyledButton = styled(Button)`
  padding: 4px 8px;
  font-size: 10px;
  color: white;
  border-color: rgb(255 255 255/.5);

  :hover {
    background-color: rgb(255 255 255/.1);
  }
`

const Spacer = styled.div`
  height: 8px;
`

const StyledDivider = styled(Divider)`
  margin: 13px -16px;
  background-color: rgb(255 255 255/.15) !important;
`

const OrigVersionAbbr = styled(NavLinkOrAWithDisable)`
  color: ${({ theme }) => theme.palette.grey[300]};
  font-weight: 300;
  text-decoration: none;
  text-transform: none;
`

const Plus = styled.span`
  display: inline-block;
  margin: 0 5px;
`

const AllIdenticalNote = styled.div`
  margin: 5px 0 10px;
  font-size: 14px;
`

const Words = styled.div`
  margin-bottom: 10px;

  ${({ $allCaps }) => !$allCaps ? `` : `
    font-size: .8em;
    text-transform: uppercase;
  `}
`

const FooterItems = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
`

const outOfOrderColors = [
  '#e8e87f',
  '#a0dcfa',
  '#60e360',
  '#f16df1',
  '#ffc070',
  '#9eaaff',
  '#53e6e7',
  '#aff93b',
  '#ffc5c5',
]

const Word = styled.span`
  color: ${({ theme }) => theme.palette.grey[600]};

  &.missing {
    color: white;
  }

  ${outOfOrderColors.map((color, idx) => `
    &.out-of-order-${idx+1} {
      color: ${color};
    }
  `)}
`

const NominaSacra = styled.span`
  text-decoration: overline;
`

const NumericAbbreviation = styled.span`
  text-decoration: overline;
  text-decoration-color: rgb(255 255 255/.4);
`

const CharacterDamaged = styled.span`
  background-color: rgb(255 255 255/.2);
`

const CharacterMissing = styled.span`
  background-color: black;
  color: rgb(255 255 255/.4);
`

const MovableNuIndicator = styled.span`
  text-decoration: overline;
  text-decoration-color: white;
  color: rgb(255 255 255/.2);
`

const UnknownCharacter = styled.span`
`

const CorrectionOfOriginalScribe = styled.span`
  color: #faa9a9;
`

const CorrectionOfSecondScribe = styled.span`
  color: #f75151;
`

const CorrectionOfThirdScribe = styled.span`
  color: #e50000;
`

const CorrectedByOriginalScribe = styled.span`
  text-decoration: line-through;
  text-decoration-color: #faa9a9;
`

const CorrectedBySecond = styled.span`
  text-decoration: line-through;
  text-decoration-color: #f75151;
`

const CorrectionCorrectedBySecond = styled(CorrectionOfOriginalScribe)`
  text-decoration: line-through;
  text-decoration-color: #f75151;
`

const CorrectedByThird = styled.span`
  text-decoration: line-through;
  text-decoration-color: #e50000;
`

const CorrectionCorrectedByThird = styled(CorrectionOfOriginalScribe)`
  text-decoration: line-through;
  text-decoration-color: #e50000;
`


const getInfoFromWordKey = wordKey => {

  const [ x, isAltWord, startWordNum, endWordNum ] = wordKey.match(/^(\+?)([0-9]+)(?:-([0-9]+))?$/)  // eslint-disable-line no-unused-vars
  const startWordIdx = parseInt(startWordNum, 10) - 1
  const endWordIdx = endWordNum ? parseInt(endWordNum, 10) - 1 : startWordIdx

  return {
    isAltWord: !!isAltWord,
    startWordIdx,
    endWordIdx,
  }
}

const ApparatusPopperContent = ({
  apparatusJson,
  baseWords,
  contextRef,
  showAncientManuscripts=false,
  // goSetPopperInfo,
  updateProps,
  hasHeader,
}) => {

  const [ allCaps, x, toggleAllCaps ] = useAccountSetting(`manuscripts-all-caps`, true)  // eslint-disable-line no-unused-vars

  const { critical, words, ancient } = useMemo(
    () => {

      const critical = cloneObj(apparatusJson.critical)
      critical.sort((a,b) => b.includes(':') ? -1 : 1)
      const coreOrigVersionAbbr = getVersionIdForBibleTags({ versionId: 'original', ...contextRef }).toUpperCase()
      if(critical[0].includes(':')) {
        critical.unshift(coreOrigVersionAbbr)
      } else {
        critical[0] = `${coreOrigVersionAbbr},${critical[0]}`
      }

      return { ...apparatusJson, critical }
    },
    [ contextRef, apparatusJson ],
  )

  const allCriticalTextsIdentical = !critical.some(reading => reading.includes(':'))

  const missingOrOutOfOrderBaseWordIndexes = useMemo(
    () => {

      const altReadings = critical.slice(1)
      const wordAltTypeByIndex = {}
      let outOfOrderingNum = 1

      altReadings.forEach(reading => {
        let previousIdx = -1
        const wordKeys = reading.split(':')[1].split(',')
        wordKeys.forEach((wordKey, wkIdx) => {
          const { isAltWord, startWordIdx, endWordIdx } = getInfoFromWordKey(wordKey)
          if(isAltWord) return

          if(startWordIdx !== previousIdx + 1) {
            if(startWordIdx > previousIdx) {
              Array(startWordIdx - previousIdx - 1).fill().forEach((x, idx) => {
                if(!wordAltTypeByIndex[previousIdx + 1 + idx]) {
                  wordAltTypeByIndex[previousIdx + 1 + idx] = 'missing'
                }
              })
            } else {
              Array(previousIdx - endWordIdx).fill().forEach((x, idx) => {
                if([ undefined, 'missing' ].includes(wordAltTypeByIndex[endWordIdx + 1 + idx])) {
                  wordAltTypeByIndex[endWordIdx + 1 + idx] = `out-of-order-${outOfOrderingNum++}`
                }
              })
              Array(endWordIdx - startWordIdx + 1).fill().forEach((x, idx) => {
                if([ undefined, 'missing' ].includes(wordAltTypeByIndex[startWordIdx + idx])) {
                  wordAltTypeByIndex[startWordIdx + idx] = `out-of-order-${outOfOrderingNum++}`
                }
              })
            }
          }

          previousIdx = endWordIdx
        })

        if(previousIdx !== baseWords.length - 1) {
          Array(baseWords.length - previousIdx - 1).fill().forEach((x, idx) => {
            if(!wordAltTypeByIndex[previousIdx + 1 + idx]) {
              wordAltTypeByIndex[previousIdx + 1 + idx] = 'missing'
            }
          })
        }

      })

      return wordAltTypeByIndex
    },
    [ critical, baseWords ],
  )

  const readings = useMemo(
    () => (
      showAncientManuscripts
        ? [
          ...critical,
          ...ancient,
        ]
        : critical
    ),
    [ showAncientManuscripts, critical, ancient ],
  )

  let currentCorrectionPhraseType
  let lastWordWasCorrectedByOriginalScribe = false
  const getWordsFromWordKey = isAncientManuscript => (wordKey, wkIdx) => {
    const { isAltWord, startWordIdx, endWordIdx } = getInfoFromWordKey(wordKey)
    const wordObjOrTexts = (isAltWord ? words : baseWords).slice(startWordIdx, endWordIdx+1)
    return (
      wordObjOrTexts.map((wordObjOrText, idx) => {

        let wordText = (
          (wordObjOrText.children || []).map(({ text }) => text).join('')
          || wordObjOrText.text
          || wordObjOrText.w
          || wordObjOrText
          || ``
        )

        if(isAncientManuscript) {
          wordText = normalizeSearchStr({ str: wordText }).replace(/[’]/g, '')  // normalizeSearchStr will remove Greek accents
          currentCorrectionPhraseType = (wordText.match(/^[XAB]?{/i) || [])[0] || currentCorrectionPhraseType
          const currentCorrectionPhraseEndsAfterThisWord = /}$/.test(wordText)
          wordText = wordText.replace(/[XAB{}]/gi, '')
          const trimmedWordTextIsEmpty = !wordText
          const hideWordTooltips = !!currentCorrectionPhraseType
          const hideCharTooltips = hideWordTooltips || /[=$]/.test(wordText)
          const allUnknownCharsi18nSwaps = { unknown_chars: /^�+$/i.test(wordText) ? i18n(" (characters unreadable)") : `` }

          wordText = (
            wordText
              .split(/(.[%^]|[¯�])/)
              .map((chars, idx) => {
                switch(chars.slice(-1)) {
                  case `%`: {
                    return (
                      <Tooltip
                        key={idx}
                        title={hideCharTooltips ? `` : i18n("Character(s) damaged")}
                        placement="top"
                      >
                        <CharacterDamaged>
                          {chars[0]}
                        </CharacterDamaged>
                      </Tooltip>
                    )
                  }
                  case `^`: {
                    return (
                      <Tooltip
                        key={idx}
                        title={hideCharTooltips ? `` : i18n("Character(s) missing")}
                        placement="top"
                      >
                        <CharacterMissing>
                          {chars[0]}
                        </CharacterMissing>
                      </Tooltip>
                    )
                  }
                  case `¯`: {
                    return (
                      <Tooltip
                        key={idx}
                        title={hideCharTooltips ? `` : i18n("Top line indicating movable nu")}
                        placement="top"
                      >
                        {/* NOTE: this is a nu, not an English N */}
                        <MovableNuIndicator>ν</MovableNuIndicator>
                      </Tooltip>
                    )
                  }
                  case `�`: {
                    return (
                      <Tooltip
                        key={idx}
                        title={hideCharTooltips ? `` : i18n("Character(s) unreadable")}
                        placement="top"
                      >
                        <UnknownCharacter>□</UnknownCharacter>
                      </Tooltip>
                    )
                  }
                  default: {
                    return chars
                  }
                }
              })
          )

          if(wordText[0].includes('=')) {
            wordText = [
              <Tooltip
                key="nomina"
                title={hideWordTooltips ? `` : i18n("“Nomina Sacra” (abbreviation for a sacred name)")}
                placement="top"
              >
                <NominaSacra>
                  {[ wordText[0].replace('=', ''), ...wordText.slice(1) ]}
                </NominaSacra>
              </Tooltip>
            ]
          } else if(wordText[0].includes('$')) {
            wordText = [
              <Tooltip
                key="numeric"
                title={hideWordTooltips ? `` : i18n("Numeric abbreviation")}
                placement="top"
              >
                <NumericAbbreviation>
                  {[ wordText[0].replace('$', ''), ...wordText.slice(1) ]}
                </NumericAbbreviation>
              </Tooltip>
            ]
          }

          switch(currentCorrectionPhraseType) {  // eslint-disable-line default-case
            case `X{`: {
              wordText = (
                <Tooltip
                  title={i18n("First written by the original scribe before he made a correction{{unknown_chars}}", allUnknownCharsi18nSwaps)}
                  placement="top"
                >
                  <CorrectedByOriginalScribe>
                    {wordText}
                  </CorrectedByOriginalScribe>
                </Tooltip>
              )
              break
            }
            case `{`: {
              // const [ nextWordCorrectorLetter ] = (wordObjOrTexts[idx+1] || ``).match(/^[ab](?={)/i) || []
              let correctionPhraseEnded = currentCorrectionPhraseEndsAfterThisWord
              let Component = CorrectionOfOriginalScribe
              wordObjOrTexts.slice(idx+1).some(wObjOrText => {
                if(typeof wObjOrText !== 'string') return true  // done
                if(!correctionPhraseEnded) {
                  if(/}$/.test(wObjOrText)) {
                    correctionPhraseEnded = true
                  }
                  return false  // keep looking
                }
                if(/^a{/i.test(wObjOrText)) {
                  Component = lastWordWasCorrectedByOriginalScribe ? CorrectionCorrectedBySecond : CorrectedBySecond
                }
                if(/^b{/i.test(wObjOrText)) {
                  Component = lastWordWasCorrectedByOriginalScribe ? CorrectionCorrectedByThird : CorrectedByThird
                }
                return true  // done
              })
              const title = (
                lastWordWasCorrectedByOriginalScribe
                  ? (
                    lastWordWasCorrectedByOriginalScribe === `empty`
                      ? i18n("Insertion made by original scribe{{unknown_chars}}", allUnknownCharsi18nSwaps)
                      : i18n("Correction made by original scribe{{unknown_chars}}", allUnknownCharsi18nSwaps)
                  )
                  : i18n("Corrected by a different scribe{{unknown_chars}}", allUnknownCharsi18nSwaps)
              )
              wordText = (
                <Tooltip
                  title={title}
                  placement="top"
                >
                  <Component>
                    {wordText}
                  </Component>
                </Tooltip>
              )
              break
            }
            case `A{`: {
              wordText = (
                <Tooltip
                  title={i18n("Second scribe’s correction{{unknown_chars}}", allUnknownCharsi18nSwaps)}
                  placement="top"
                >
                  <CorrectionOfSecondScribe>
                    {wordText}
                  </CorrectionOfSecondScribe>
                </Tooltip>
              )
              break
            }
            case `B{`: {
              wordText = (
                <Tooltip
                  title={i18n("Third scribe’s correction{{unknown_chars}}", allUnknownCharsi18nSwaps)}
                  placement="top"
                >
                  <CorrectionOfThirdScribe>
                    {wordText}
                  </CorrectionOfThirdScribe>
                </Tooltip>
              )
              break
            }
          }

          if(currentCorrectionPhraseEndsAfterThisWord) {

            lastWordWasCorrectedByOriginalScribe = (
              currentCorrectionPhraseType === `X{`
              && (
                trimmedWordTextIsEmpty
                  ? `empty`
                  : true
              )
            )

            currentCorrectionPhraseType = undefined
          }

        }
    
        return [
          <Word
            key={`${wkIdx}:${wordKey}:${idx}`}
            className={
              isAltWord
                ? `missing`
                : missingOrOutOfOrderBaseWordIndexes[startWordIdx + idx]
            }
          >
            {wordText}
          </Word>,
          ` `,
        ]
      })
    )
  }

  const getWordFromBaseWords = (word, idx) => ([
    <Word
      key={word[`x-id`]}
      className={missingOrOutOfOrderBaseWordIndexes[idx] || ``}
      // $missingOrOutOfOrderBaseWordIndexes={missingOrOutOfOrderBaseWordIndexes[idx] === `missing`}
      // $outOfOrderNumber={parseInt((missingOrOutOfOrderBaseWordIndexes[idx] || ``).split('-').pop(), 10)}
      // missingOrOutOfOrderBaseWordIndexes
    >
      {word.text}
    </Word>,
    ` `,
  ])

  const toggleShowAncientManuscripts = useCallback(
    () => {
      updateProps({
        showAncientManuscripts: !showAncientManuscripts,
      })
    },
    [ updateProps, showAncientManuscripts ],
  )

  const origVersionNamesByAbbr = {
    UHB: i18n("unfoldingWord Hebrew Bible (default)"),
    UGNT: i18n("unfoldingWord Greek New Testament (default)"),
    WH: i18n("1885 Wescott and Hort Greek NT"),
    NA: i18n("The Nestle-Aland 28th edition Greek NT"),
    SBL: i18n("Society of Biblical Literature Greek NT"),
    RP: i18n("2018 Robinson-Pierpont Greek NT"),
    ST: i18n("1550 Stephanus Greek NT"),
    KJTR: i18n("King James Textus Receptus"),
    K: i18n("“written” – This is what was written in the consonantal text that was available to the Masoretes."),
    Q: i18n("“read” – This is what the Masoretes indicated should actually be read aloud. Motivations include an assumed corruption in the written text, an antiquated spelling that was more difficult to read, for the sake of propriety in public reading, etc."),
  }

  return (
    <PassagePopperContainer $hasHeader={hasHeader} >

      <PassagePopperHeading>
        {
          contextRef.bookId <= 39
            ? i18n("Compare Hebrew Readings")
            : i18n("Compare Greek Texts")
        }
      </PassagePopperHeading>
      <Spacer />

      {readings.map((reading, idx) => {

        const isFirstAncientManuscript = idx === critical.length
        const isAncientManuscript = idx >= critical.length
        const [ origVersionAbbrs, wordKeys ] = reading.split(':').map(val => val.split(','))

        return (
          <React.Fragment key={idx}>

            {isFirstAncientManuscript && <StyledDivider />}

            <PassagePopperHeading>
              {
              origVersionAbbrs
                .map(origVersionAbbr => {
                  const to = getLinkFromManuscript(origVersionAbbr, contextRef)
                  return [
                    <Tooltip
                      key={origVersionAbbr}
                      title={origVersionNamesByAbbr[origVersionAbbr] || getManuscriptInfoStr(origVersionAbbr) || ``}
                      placement="top"
                    >
                      <span>
                        <OrigVersionAbbr
                          to={to}
                          disabled={!to}
                        >
                          {getManuscriptAbbr(origVersionAbbr)}
                        </OrigVersionAbbr>
                      </span>
                    </Tooltip>,
                    <Plus key={`${origVersionAbbr}+`}>+</Plus>
                  ]
                })
                .flat()
                .slice(0, -1)
              }
            </PassagePopperHeading>

            <Words $allCaps={isAncientManuscript && allCaps}>
              {!wordKeys && baseWords.map(getWordFromBaseWords).flat().slice(0, -1)}
              {(wordKeys || []).map(getWordsFromWordKey(isAncientManuscript)).flat(2).slice(0, -1)}
            </Words>

          </React.Fragment>
        )
      })}

      {allCriticalTextsIdentical && !showAncientManuscripts &&
        <AllIdenticalNote>
          {i18n("All critical texts present the same reading.")}
        </AllIdenticalNote>
      }

      {ancient.length > 0 &&
        <FooterItems>

          <StyledButton
            onClick={toggleShowAncientManuscripts}
            variant="outlined"
            disableElevation
          >
            {
              showAncientManuscripts
                ? i18n("Hide ancient manuscripts")
                : i18n("Show ancient manuscripts")
            }
          </StyledButton>

          {showAncientManuscripts &&
            <ApparatusPopperContentCaseToggle
              allCaps={allCaps}
              toggleAllCaps={toggleAllCaps}
            />
          }

        </FooterItems>
      }

    </PassagePopperContainer>
  )
}

export default memo(ApparatusPopperContent)