import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'

import useFormattingKeys from '../../../hooks/useFormattingKeys'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  user-select: none;
`

const Name = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  user-select: text;
  padding: 0 2px;
  font-weight: 500;
  font-size: 16px;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const MarkupKeyHeader = ({
  formattingKeyInfos,
  pageKeyIndex,
  goSetCurrentFormattingKeyId,
}) => {

  const { formattingKeysById } = useFormattingKeys()

  const { id } = formattingKeyInfos[pageKeyIndex]
  const {
    name=id,
  } = formattingKeysById[id] || {}

  const onClick = useCallback(
    indexChange => {
      goSetCurrentFormattingKeyId({
        value: formattingKeyInfos[pageKeyIndex + indexChange].id,
      })
    },
    [ goSetCurrentFormattingKeyId, formattingKeyInfos, pageKeyIndex ],
  )

  const onLeftClick = useCallback(() => onClick(-1), [ onClick ])
  const onRightClick = useCallback(() => onClick(1), [ onClick ])

  return (
    <Container>

      <StyledIconButton
        onClick={onLeftClick}
        disabled={!formattingKeyInfos[pageKeyIndex - 1]}
      >
        <ArrowBackIcon />
      </StyledIconButton>

      <Name>
        {name}
      </Name>

      <StyledIconButton
        onClick={onRightClick}
        disabled={!formattingKeyInfos[pageKeyIndex + 1]}
      >
        <ArrowForwardIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(MarkupKeyHeader)