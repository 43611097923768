import { memo } from 'react'
import styled from 'styled-components'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { i18n } from 'inline-i18n'

import useFormattingKeys from '../../../hooks/useFormattingKeys'

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin: -1px -1px 0 -1px;
  padding: 3px 0;
  background-color: white;
`

const HandleContainer = styled.div`
  height: 40px;
  width: 40px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 10px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  :hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const Name = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 500;
`

const LensSettingsLensListLine = ({
  formattingKeyId,
  onEdit,
  onRemove,
  dragHandleProps,
}) => {

  const { formattingKeysById } = useFormattingKeys()

  return (
    <Container>

      <Tooltip
        title={i18n("Drag to reorder")}
      >
        <HandleContainer {...dragHandleProps}>
          <StyledDragHandleIcon />
        </HandleContainer>
      </Tooltip>

      <Name>
        {formattingKeysById[formattingKeyId].name || `?`}
      </Name>

      <Tooltip
        title={i18n("Edit or delete")}
      >
        <StyledIconButton
          onClick={onEdit}
        >
          <EditIcon />
        </StyledIconButton>
      </Tooltip>

      <Tooltip
        title={i18n("Remove from this module")}
      >
        <StyledIconButton
          onClick={onRemove}
        >
          <CloseIcon />
        </StyledIconButton>
      </Tooltip>

    </Container>
  )
}

export default memo(LensSettingsLensListLine)