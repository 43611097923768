import { memo } from 'react'
import styled from 'styled-components'
import Fab from '@material-ui/core/Fab'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

import OptionsPopover from '../../common/OptionsPopover'
import MarkupKeyHeader from './MarkupKeyHeader'
import MarkupKeyPages from './MarkupKeyPages'
import MarkupKeyFooter from './MarkupKeyFooter'

const StyledFab = styled(Fab)`
  position: absolute;
  bottom: 14px;
  left: 5px;
  box-shadow: 1px 8px 40px rgb(0 0 0 / 30%);
  color: white;
  background-color: ${({ theme }) => theme.palette.grey[700]};

  ${({ $noneditableViewingMode }) => !$noneditableViewingMode ? `` : `
    left: 15px;
  `}

  :hover {
    background-color: ${({ theme }) => theme.palette.grey[900]};
  }

  ${({ $open, theme }) => !$open ? `` : `
    background-color: ${theme.palette.grey[900]};
  `}

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`

const StyledOptionsPopover = styled(OptionsPopover)`
  .MuiPopover-paper {
    margin-top: -5px;
    margin-left: -5px;
    max-height: calc(100vh - 65px);
    max-width: calc(100vw - 20px);
    min-height: 150px;
    min-width: 130px;
  }
`

const Container = styled.div`
  width: 240px;
  margin-left: 15px;
  position: relative;

  ${({ $printOrDownloadInfo }) => !$printOrDownloadInfo ? `` : `
    width: 100%;
    margin: 0;
    align-self: start;

    & > div {
      position: static;
      height: auto;
      max-height: none;
      border-radius: 0;
      border: none;
    }

    & > div > div {
      background: none;
    }
  `}

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    margin: 0;
    width: auto;
    display: inline-block;
  `}

`

const getBoxTop = ({ $noneditableViewingMode }) => $noneditableViewingMode ? `10px` : `60px`;
const Box = styled.div`
  position: absolute;
  top: ${getBoxTop};
  width: 100%;
  max-height: calc(100% - ${getBoxTop} - 10px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  box-sizing: border-box;
  overflow: auto;

  ${({ $noneditableViewingMode }) => $noneditableViewingMode !== `within-notes` ? `` : `
    position: relative;
    top: 0;
    height: auto;
    max-height: none;
  `}
`

const MarkupKey = ({
  noneditableViewingMode,
  minimized,
  printOrDownloadInfo,
  formattingKeyInfosSetting,
  currentFrmtgKeyIdSetting,
  inEditingMode,
  projectId,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const [ goSetCurrentFormattingKeyId ] = useGoSetModuleSetting({
    moduleSetting: currentFrmtgKeyIdSetting,
    projectId,
  })

  const pageKeyIndex = Math.max(formattingKeyInfosSetting.value.findIndex(({ id }) => id === currentFrmtgKeyIdSetting.value), 0)

  const lenses = (
    <>
      <MarkupKeyHeader
        pageKeyIndex={pageKeyIndex}
        formattingKeyInfos={formattingKeyInfosSetting.value}
        goSetCurrentFormattingKeyId={goSetCurrentFormattingKeyId}
      />
      <MarkupKeyPages
        pageKeyIndex={pageKeyIndex}
        formattingKeyInfosSetting={formattingKeyInfosSetting}
        inEditingMode={inEditingMode}
      />
      <MarkupKeyFooter
        pageKeyIndex={pageKeyIndex}
        formattingKeyInfosSetting={formattingKeyInfosSetting}
        projectId={projectId}
        inEditingMode={inEditingMode}
        goSetCurrentFormattingKeyId={goSetCurrentFormattingKeyId}
      />
    </>
  )

  if(minimized && !printOrDownloadInfo) {
    return (
      <>

        <StyledFab
          onMouseDown={openModal}  // instead of onClick so that restored focus doesn't come back to this button
          $open={!!anchorEl}
          tabIndex={-1}
          size="small"
          $noneditableViewingMode={noneditableViewingMode}
        >
          <VpnKeyIcon />
        </StyledFab>

        <StyledOptionsPopover
          anchorEl={anchorEl}
          onClose={closeModal}
          forceAbove
          hideArrow
        >
          {lenses}
        </StyledOptionsPopover>

      </>
    )
  }

  return (
    <Container
      $printOrDownloadInfo={printOrDownloadInfo}
      $noneditableViewingMode={noneditableViewingMode}
    >
      <Box $noneditableViewingMode={noneditableViewingMode}>
        {lenses}
      </Box>
    </Container>
  )
}

export default memo(MarkupKey)