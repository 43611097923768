import { memo, useCallback } from 'react'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { useHistory } from 'react-router-dom'
import { i18n } from 'inline-i18n'

import useVersionInfos from '../../hooks/useVersionInfos'
import useModalAnchor from "../../hooks/useModalAnchor"

import CopySearchLinkIconButton from './CopySearchLinkIconButton'

const includeRegex = /((?:^| )include:[A-Z0-9]{2,9}(?:,[A-Z0-9]{2,9})*(?= |$))/gi
const inRegex = /((?:^| )in:[A-Z0-9]{2,9}(?:,[A-Z0-9]{2,9})*(?= |$))/gi

const StyledMenuItem = styled(MenuItem)`
  padding: 5px 15px 5px 3px;
  font-size: 14px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 5px;

  .MuiSvgIcon-root {
    height: 16px;
    height: 16px;
  }
`

const PlusVersions = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.palette.grey[600]};

  ${({ onClick }) => !onClick ? `` : `
    :hover {
      cursor: pointer;
      color: black;
    }
  `}
`

const PlusVersion = styled.span`
  display: inline-block;
  margin-left: 3px;
`

const Plus = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
`

const Only = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-left: 7px;

  :hover {
    text-decoration: underline;
  }
`

const BibleSearchPlusVersionsMenu = ({
  searchText,
  includeVersionIds,
  inVersionIds,
  disabled,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const versionIds = includeVersionIds || inVersionIds
  const includeOrIn = includeVersionIds ? `include` : `in`
  const includeOrInRegex = (
    includeOrIn === `include`
      ? includeRegex
      : inRegex
  )

  const { nonOrigSelectedVersionInfos, getVersionInfoByIdSync } = useVersionInfos({ versionIds, restrictToTestamentSearchText: searchText })
  const history = useHistory()

  const onClick = useCallback(
    ({ currentTarget, target }) => {
      
      closeModal()

      const clickedVersionId = currentTarget.getAttribute('data-version-id')
      const newIncludeOrInVersionIds = (
        target.classList.contains(`BibleSearchPlusVersionsMenu-only`)
          ? [ clickedVersionId ]
          : (
            versionIds.includes(clickedVersionId)
              ? versionIds.filter(versionId => versionId !== clickedVersionId)
              : [ ...versionIds, clickedVersionId ]
          )
      )

      const includeAddOn = ` ${includeOrIn}:${newIncludeOrInVersionIds.length > 0 ? newIncludeOrInVersionIds.join(',').toUpperCase() : `none`}`
      const newSearchText = `${searchText.replace(includeOrInRegex, '').replace(/  +/g, ' ')}${includeAddOn}`

      history.replace({
        hash: `#search=${encodeURIComponent(newSearchText)}`,
        state: {
          executeSearch: true,
        },
      })
    },
    [ searchText, versionIds, includeOrIn, closeModal, history, includeOrInRegex ],
  )

  return (
    <>

      <PlusVersions
        onClick={disabled ? null : openModal}
      >
        {versionIds.length === 0 &&
          <PlusVersion>
            <Plus>{i18n("+", "combination character")}</Plus>
            {i18n("Add translation")}
          </PlusVersion>
        }

        {versionIds.map(versionId => (
          <PlusVersion key={versionId}>
            {includeOrIn === `include` &&
              <Plus>{i18n("+", "combination character")}</Plus>
            }
            {(getVersionInfoByIdSync(versionId) || {}).safeVersionAbbr}
          </PlusVersion>
        ))}
      </PlusVersions>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
      >
        {nonOrigSelectedVersionInfos.map(({ id, version: { name }={}, safeVersionAbbr }) => (
          <StyledMenuItem
            key={id}
            data-version-id={id}
            onClick={onClick}
            disabled={includeOrIn === `in` && versionIds.length === 1 && versionIds.includes(id)}
          >
            <StyledCheckbox
              checked={versionIds.includes(id)}
            />
            {name
              ? (
                i18n("{{title}} ({{subtitle}})", {
                  title: name,
                  subtitle: safeVersionAbbr,
                })
              )
              : safeVersionAbbr
            }
            <Only className="BibleSearchPlusVersionsMenu-only">
              {i18n("only")}
            </Only>
          </StyledMenuItem>
        ))}
      </Menu>

      <CopySearchLinkIconButton
        searchText={searchText}
        versionIds={versionIds}
        includeOrIn={includeOrIn}
        includeOrInRegex={includeOrInRegex}
      />

    </>
  )
}

export default memo(BibleSearchPlusVersionsMenu)