import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 20px;
  margin: 15px 5px 0 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.div`
  text-align: center;
  font-size: 15px;
  margin-bottom: 12px;
`

const NewOrLearning = ({
  children,
}) => (
  <Box>

    <Label>
      {i18n("New or learning?")}
    </Label>

    {children}

  </Box>
)

export default memo(NewOrLearning)