import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import AppContent from '../../common/AppContent'

const StyledAppContent = styled(AppContent)`
  margin-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  font-size: 20px;
  margin-bottom: 30px;
`

const Buttons = styled.div`
`

const StyledLinkButton = styled(LinkButton)`
  margin: 0 5px;
`

const NotFound = ({ ...props }) => {

  return (
    <>
      <Header {...props} />

      <StyledAppContent>

        <Message>
          {i18n("Page not found.")}

        </Message>

        <Buttons>

          <StyledLinkButton
            to="/"
            color="primary"
            variant="outlined"
          >
            {i18n("Go to The Biblearc Bible")}
          </StyledLinkButton>

        </Buttons>

      </StyledAppContent>

    </>
  )
}


export default memo(NotFound)