import { memo } from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  && {
    margin: 5px 0 0;
  }

  &::before {
    content: none;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  margin-left: -8px;
  min-height: 26px;
  padding: 0;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 12px;
  justify-content: flex-end;
  flex-flow: row-reverse;

  &.Mui-expanded {
    background: transparent;
    min-height: 26px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.Mui-focused {
    background-color: transparent;
  }

  .MuiSvgIcon-root {
    height: 12px !important;
  }

  .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded {
    flex-grow: 0;
    margin: 6px 5px 6px 0;
  }

  .MuiAccordionSummary-expandIcon {
    transform: rotate(135deg);
    padding: 0;
    margin: -1px 1px 0;
  }
  .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    height: 15px;
    transition: color .15s ease-in-out, transform .15s ease-in-out;
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(180deg);
  }

  :hover .MuiAccordionSummary-expandIcon .MuiSvgIcon-root {
    color: black;
    transform: scale(1.2);
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  display: block;
`

const DetailAccordion = ({
  summary,
  details,
  ...otherProps
}) => (
  <StyledAccordion
    square
    {...otherProps}
  >
    <StyledAccordionSummary
      className="DetailAccordion-StyledAccordionSummary"
      expandIcon={<CloseIcon />}
    >
      {summary}
    </StyledAccordionSummary>
    <StyledAccordionDetails>
      {details}
    </StyledAccordionDetails>
  </StyledAccordion>
)

export default memo(DetailAccordion)