import { memo, useMemo, useRef } from 'react'
import styled from 'styled-components'
import Fade from '@material-ui/core/Fade'

import useAppSize from '../../hooks/useAppSize'

import BasicPopover from './BasicPopover'

const StyledPopover = styled(BasicPopover)`

  ${({ $hideArrow, $showAbove }) => $hideArrow ? `` : `

    .MuiPopover-paper {
      overflow: visible;
      transform: translateY(${$showAbove ? 5 : -5}px);
    }

    .MuiPopover-paper::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: white transparent transparent transparent;
      position: absolute;
      top: -10px;
      transform: rotate(180deg);
      left: calc(50% - 5.5px);

      ${!$showAbove ? `` : `
        bottom: -10px;
        top: auto;
        transform: none;
      `}
    }

  `}
`

const OptionsPopover = ({
  anchorEl,
  forceAbove=false,
  forceBelow=false,
  hideArrow=false,
  horizontal,
  vertical,
  tabs,
  ...otherProps
}) => {

  const { height: windowHeight } = useAppSize()

  const previousShowAboveRef = useRef(!!forceAbove)
  const showAbove = useMemo(
    () => {
      if(forceAbove || forceBelow || !anchorEl) return previousShowAboveRef.current

      const { top, height } = anchorEl.getBoundingClientRect()
      previousShowAboveRef.current = (
        top > 375
        || top > windowHeight - top - height
      )

      return previousShowAboveRef.current
    },
    [ anchorEl, forceAbove, forceBelow, windowHeight ],
  )

  horizontal = horizontal || (hideArrow ? 'left' : 'center')

  const origins = useMemo(
    () => (
      showAbove
        ? {
          anchorOrigin: {
            vertical: vertical || 'top',
            horizontal,
          },
          transformOrigin: {
            vertical: vertical || 'bottom',
            horizontal,
          },
        }
        : {
          anchorOrigin: {
            vertical: vertical || 'bottom',
            horizontal,
          },
          transformOrigin: {
            vertical: vertical || 'top',
            horizontal,
          },
        }
    ),
    [ showAbove, horizontal, vertical ],
  )

  return (
    <StyledPopover
      open={!!anchorEl}
      anchorEl={anchorEl}
      marginThreshold={5}
      TransitionComponent={Fade}
      $hideArrow={hideArrow}
      $showAbove={showAbove}
      {...origins}
      {...otherProps}
    />
  )
}

export default memo(OptionsPopover)