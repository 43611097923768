import { useCallback } from 'react'

import useShortcuts from './useShortcuts'
import keyDownArrowLeft from '../components/passage/shortcuts/keyDownArrowLeft'
import keyDownArrowRight from '../components/passage/shortcuts/keyDownArrowRight'

const keyDownFuncs = {
  keyDownArrowLeft,
  keyDownArrowRight,
}

const usePassageArrowShortcuts = params => {

  const { columnIdx } = params

  const inCorrectContext = useCallback(
    () => {

      // check that we are focused on this module
      if(!document.activeElement) return false
      if(parseInt(document.activeElement.getAttribute('data-passage-column-idx'), 10) !== columnIdx) return false

      return true

    },
    [ columnIdx ],
  )

  useShortcuts({
    keyDownFuncs,
    inCorrectContext,
    ...params,
  })

}

export default usePassageArrowShortcuts