import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const getSideFactor = $type => ({
  point: -0.25,
  tail: -0.45,
}[$type] || 0)

const getSideMargin = paramKey => ({ $height, height, ...otherParams }) => ($height || height) * getSideFactor(otherParams[paramKey])

const StyledMarkupLine = styled(MarkupLine)`
  z-index: -10;
  display: flex;
`

const Content = styled.div`
  flex: 1;
  position: relative;

  margin: ${({ $height=0 }) => $height * -0.165}px 0;
  ${({ $isRTL, ...otherParams }) => `
    margin-${$isRTL ? `right` : `left`}: ${getSideMargin($isRTL ? `$endType` : `$startType`)(otherParams)}px;
    margin-${$isRTL ? `left` : `right`}: ${getSideMargin($isRTL ? `$startType` : `$endType`)(otherParams)}px;
  `}

  path, rect {
    fill: ${({ theme, $color, $darkGreyInsteadOfGrey }) => (
      ($color === `GREY` && $darkGreyInsteadOfGrey)
        ? `#383838`
        : theme.palette.markupColors[$color].arrow
    )};
  }
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ArrowHighlight = ({
  startType,
  endType,
  includesStart,
  includesEnd,
  color,
  darkGreyInsteadOfGrey,
  isRTL=false,
  ...otherProps
}) => {

  if(isRTL) {
    [ includesEnd, includesStart ] = [ includesStart, includesEnd ]
  }

  const contentProps = {
    "$height": otherProps.height,
    "$startType": includesStart !== false && startType,
    "$endType": includesEnd !== false && endType,
    "$color": color,
    "$darkGreyInsteadOfGrey": darkGreyInsteadOfGrey,
    "$isRTL": isRTL,
  }

  const { width, height } = otherProps
  let adjustedHeight = height + (height * 0.165 * 2)
  let adjustedWidth = width - getSideMargin(isRTL ? `$endType` : `$startType`)(contentProps) - getSideMargin(isRTL ? `$startType` : `$endType`)(contentProps)
  let totalWidth = (adjustedWidth / adjustedHeight) * 500

  const pointLeftPath = `L 220 500 L 0 250 L 220 0`
  const pointRightPath = `L ${totalWidth-220} 0 L ${totalWidth} 250 L ${totalWidth-220} 500`
  const tailLeftPath = `L 70 420 L 220 250 L 70 80`
  const tailRightPath = `L ${totalWidth-70} 80 L ${totalWidth-220} 250 L ${totalWidth-70} 420`
  const squareLeftPath = `L 0 420 L 0 80`
  const squareRightPath = `L ${totalWidth} 80 L ${totalWidth} 420`

  const leftEndPath = (
    (includesStart !== false && startType === `point` && pointLeftPath)
    || (includesStart !== false && startType === `tail` && tailLeftPath)
    || squareLeftPath
  )

  const rightEndPath = (
    (includesEnd !== false && endType === `point` && pointRightPath)
    || (includesEnd !== false && endType === `tail` && tailRightPath)
    || squareRightPath
  )

  return (
    <StyledMarkupLine
      {...otherProps}
    >
      <Content
        {...contentProps}
      >
        <Svg
          viewBox={`0 0 ${totalWidth} 500`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M 220 420 ${leftEndPath} L 220 80 L ${totalWidth-220} 80 ${rightEndPath} L ${totalWidth-220} 420 Z`}
          />
        </Svg>
      </Content>
    </StyledMarkupLine>
  )
}

export default memo(ArrowHighlight)