import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const Pin = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background: ${({ $inEdit }) => $inEdit ? `red` : `black`};
  z-index 1;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 10px;
`

const BibleMapPlace = ({
  place,
  setPlaceInEdit,
  getStyle,
  ...otherProps
}) => {

  const goSetPlaceInEdit = useCallback(() => (setPlaceInEdit && setPlaceInEdit(place)), [ setPlaceInEdit, place ])

  return (
    <Pin
      onClick={goSetPlaceInEdit}
      style={getStyle(place.locations[0])}
      {...otherProps}
    />
  )
}

export default memo(BibleMapPlace)