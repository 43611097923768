import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import SnoozeIcon from '@material-ui/icons/Snooze'
import StarIcon from '@material-ui/icons/Star'
import Tooltip from '@material-ui/core/Tooltip'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { i18n, getLocale } from 'inline-i18n'

import {
  KJV_VERSION,
  GET_STUDY_BIBLE_ITEM_TYPES,
} from '../../../utils/constants'
import useSetStudyBibleItemInfo from '../../../hooks/useSetStudyBibleItemInfo'

import PassageRef from '../../common/PassageRef'
import RelativeTime from '../../common/RelativeTime'
import StudyBibleItemInsightInfoAndDetails from './StudyBibleItemInsightInfoAndDetails'
import StudyBibleItemSermonInfoAndDetails from './StudyBibleItemSermonInfoAndDetails'
import StudyBibleItemMapInfoAndDetails from './StudyBibleItemMapInfoAndDetails'
import StudyBibleItemGenealogyInfoAndDetails from './StudyBibleItemGenealogyInfoAndDetails'
import StudyBibleItemImageInfoAndDetails from './StudyBibleItemImageInfoAndDetails'
import StudyBibleItemIntroductionInfoAndDetails from './StudyBibleItemIntroductionInfoAndDetails'
import StudyBibleItemTimelineInfoAndDetails from './StudyBibleItemTimelineInfoAndDetails'
import StudyBibleItemHistoryTooltip from '../../study-bible/StudyBibleItemHistoryTooltip'
import Avatar from '../../common/Avatar'

const Row = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  min-height: 40px;
  padding: 7px 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgb(0 0 0/.05);
  position: relative;
  z-index: 1;

  & > * {
    flex-shrink: 0;
  }
`

const Status = styled.div`
  width: 40px;
`

const PassageRefContainer = styled.div`
  width: 90px;
  font-weight: 300;
`

const HeadingAndTitle = styled.div`
  width: 200px;
  line-height: 1.1;
  transition: opacity .15s ease-in-out;

  :hover {
    cursor: pointer;
    opacity: .5;
  }
`

const Heading = styled.div`
  font-style: italic;
  font-size: 12px;
`

const Title = styled.div`
  font-size: 16px;
`

const Type = styled.div`
  width: 80px;
  font-weight: 500;
  font-size: 12px;
`

const Tags = styled.div`
  width: 100px;
`

const Tag = styled.div`
  font-weight: 300;
  font-size: 12px;

  color: ${({ $isInternal, theme }) => $isInternal ? theme.palette.primary.main : `black`};

  :hover {
    opacity: .5;
    cursor: pointer;
  }
`

const Author = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;

  :hover {
    opacity: .5;
    cursor: pointer;
  }
`

const Rating = styled.div`
  width: 50px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  line-height: .9;
  margin-top: -5px;
  opacity: ${({ $adminRating }) => ($adminRating / 10) * .8 + .2};
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const Dates = styled.div`
  width: 110px;
  font-size: 11px;
  font-weight: 300;
  white-space: nowrap;
`

const CreatedAt = styled.div`
`

const UpdatedAt = styled.div`
`

const DeletedAt = styled.div`
`

const NumOpenComments = styled.div`
  font-weight: ${({ $numOpenComments}) => $numOpenComments ? `bold` : `normal`};
`

const StudyBibleItemRow = ({
  studyBibleItem,
  setTextSearch,
}) => {

  const {
    // id,
    heading,
    title,
    type,
    info,
    // thumbnailImageUrl,
    status,
    fromLoc,
    toLoc,
    editHistory,
    adminRating,
    userId,
    user,
    details,
    tags,
    createdAt,
    updatedAt,
    deletedAt,
    numOpenComments,
  } = studyBibleItem

  const publishedAt = useMemo(
    () => {
      if(status === `PUBLISHED` && editHistory) {
        for(let edit of editHistory) {
          if(edit.updates.status === `PUBLISHED`) {
            return edit.time
          }
        }
      }
    },
    [ status, editHistory ],
  )

  const setStudyBibleItemInfo = useSetStudyBibleItemInfo()
  const show = useCallback(
    () => {
      setStudyBibleItemInfo({
        studyBibleItem,
        versionId: KJV_VERSION,
        goStraightToEditor: true,
      })
    },
    [ studyBibleItem, setStudyBibleItemInfo ],
  )

  const doSearch = useCallback(
    ({ target }) => {
      setTextSearch(target.closest(`[data-textsearch]`).getAttribute(`data-textsearch`))
    },
    [ setTextSearch ],
  )

  const statusIcon = {
    "DRAFT-UNLOCKED": <LockOpenIcon />,
    "DRAFT-LOCKED": <LockIcon />,
    "PUBLISHED": <CloudDoneIcon />,
    "DEFERRED": <SnoozeIcon />,
  }[status]

  const InfoAndDetails = {
    INSIGHT: StudyBibleItemInsightInfoAndDetails,
    QUOTE: StudyBibleItemInsightInfoAndDetails,
    SERMON: StudyBibleItemSermonInfoAndDetails,
    LECTURE: StudyBibleItemSermonInfoAndDetails,
    PODCAST: StudyBibleItemSermonInfoAndDetails,
    COURSE: StudyBibleItemSermonInfoAndDetails,
    IMAGE: StudyBibleItemImageInfoAndDetails,
    MAP: StudyBibleItemMapInfoAndDetails,
    GENEALOGY: StudyBibleItemGenealogyInfoAndDetails,
    TIMELINE: StudyBibleItemTimelineInfoAndDetails,
    CREED: StudyBibleItemInsightInfoAndDetails,
    INTRODUCTION: StudyBibleItemIntroductionInfoAndDetails,
  }[type]

  return (
    <Row>

      <Status>
        {statusIcon}
      </Status>

      <PassageRefContainer>
        <PassageRef
          fromLoc={fromLoc}
          toLoc={toLoc}
          convertToVersionId={KJV_VERSION}
          abbreviated
        />
      </PassageRefContainer>

      <HeadingAndTitle
        onClick={show}
      >
        <Heading>
          {heading}
        </Heading>
        <Title>
          {title || (heading ? `` : i18n("[click to view]", "", ""))}
        </Title>
      </HeadingAndTitle>

      <Type>
        {GET_STUDY_BIBLE_ITEM_TYPES()[type].label}
      </Type>

      <Tags>
        {tags.map(({ tag }) => (
          <Tag
            key={tag}
            onClick={doSearch}
            data-textsearch={`#${tag.replace(/^INTERNAL:/, ``)}`}
            $isInternal={/^INTERNAL:/.test(tag)}
          >
            #{tag.replace(/^INTERNAL:/, ``)}
          </Tag>
        ))}
      </Tags>

      <InfoAndDetails
        info={info}
        details={details}
      />

      <Author
        onClick={doSearch}
        data-textsearch={`user:${userId}`}
      >
        <Tooltip title={`${(user || {}).name} [user:${userId}]`}>
          <span>
            <Avatar
              user={user || {}}
              size="small"
            />
          </span>
        </Tooltip>
      </Author>

      <Rating
        $adminRating={adminRating}
      >
        <StarIcon />
        <div>
          {(adminRating / 10 + 4).toFixed(1)}
        </div>
      </Rating>

      <StudyBibleItemHistoryTooltip
        editHistory={editHistory}
      >
        <Dates>
          <CreatedAt>
            {i18nReact("Created: {{date}}", {
              date: (
                <RelativeTime date={createdAt} />
              ),
            })}
          </CreatedAt>
          <UpdatedAt>
            {i18nReact("Updated: {{date}}", {
              date: (
                <RelativeTime date={updatedAt} />
              ),
            })}
          </UpdatedAt>
          {!!publishedAt &&
            <UpdatedAt>
              {i18nReact("Published: {{date}}", {
                date: new Date(publishedAt).toLocaleDateString(getLocale()),
              })}
            </UpdatedAt>
          }
          {!!deletedAt &&
            <DeletedAt>
              {i18nReact("Deleted: {{date}}", {
                date: (
                  <RelativeTime date={deletedAt} />
                ),
              })}
            </DeletedAt>
          }
          <NumOpenComments
            $numOpenComments={numOpenComments}
          >
            {i18nReact("Open Comments: {{num}}", {
              num: numOpenComments,
            })}
          </NumOpenComments>
        </Dates>
      </StudyBibleItemHistoryTooltip>

    </Row>
  )
}

export default memo(StudyBibleItemRow)