import styled from 'styled-components'

const ModuleHeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ $height }) => $height || `60px`};
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 1;
`

export default ModuleHeaderBackground