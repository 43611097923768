import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import TextureIcon from '@material-ui/icons/Texture'
// import { i18n } from 'inline-i18n'

import { cloneObj } from '../../../utils/misc'
import { formattingTypes, formattingComponentByType } from '../../../utils/formatting'
import useModalAnchor from '../../../hooks/useModalAnchor'

import OptionsPopover from '../../common/OptionsPopover'

const defaultBottomNavProps = {
  top: 0,
  left: 0,
  width: 17,
  height: 19,
  style: {
    position: `relative`,
    zIndex: 1,
    fontSize: 10,
  },
}

const bottomNavPropsByComponentType = {
  "ARROW-HIGHLIGHT-DOUBLE": {
    ...cloneObj(defaultBottomNavProps),
    width: 22,
  },
}

;[ ...Object.values(bottomNavPropsByComponentType), defaultBottomNavProps ].forEach(props => {
  props.style.width = props.width
  props.style.height = props.height
})

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 114px;
`

const T = styled.div`
  z-index: 2;
  position: relative;
  color: black;
  font-size: 17px;
`

const ComponentContainer = styled.div`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyleIcon = styled.div`
  position: relative;
  width: 38px;
  padding: 7px;
  text-align: center;
  overflow: hidden;
  transition: transform .15s ease-in-out;

  :hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const MarkupTypeChooser = ({
  skipTypes=[],
  onChange,
  keys,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  return (
    <>

      <StyledIconButton
        onClick={openModal}
      >
        <TextureIcon />
      </StyledIconButton>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
      >
        <Container>
          {formattingTypes.filter(type => !skipTypes.includes(type)).map((type, idx) => {
            const Component = formattingComponentByType[type]
          
            return (
              <StyleIcon
                key={type}
                onClick={() => onChange({ keys, newValue: type })}
              >
                <T>T</T>
                <ComponentContainer>
                  <Component
                    color="GREY"
                    {...(bottomNavPropsByComponentType[type] || defaultBottomNavProps)}
                  />
                </ComponentContainer>
              </StyleIcon>
            )
          })}
        </Container>
      </OptionsPopover>

    </>
  )
}


export default memo(MarkupTypeChooser)