import { useMemo, useContext } from "react"

import { FormattingKeysContext } from '../context/FormattingKeys'
import { getSystemFormattingKeys, getHasLabels } from '../utils/formatting'

const useFormattingKey = formattingKeyId => {

  const formattingKeys = useContext(FormattingKeysContext)

  formattingKeyId = formattingKeyId || `basic-highlighting`  // TODO: the project default should be second item here

  const allFormattingKeys = useMemo(
    () => ([
      ...getSystemFormattingKeys(),
      ...formattingKeys,
    ]),
    [ formattingKeys ],
  )

  const formattingKey = useMemo(
    () => {
      const formattingKeyBase = {
        ...(
          allFormattingKeys.find(({ id }) => id === formattingKeyId)
          || allFormattingKeys[0]
        ),
      }
      formattingKeyBase.hasLabels = getHasLabels(formattingKeyBase)
      return formattingKeyBase
    },
    [ allFormattingKeys, formattingKeyId ],
  )

  return formattingKey

}

export default useFormattingKey