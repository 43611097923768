import { memo, useState } from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'

import StudyBibleDots from './StudyBibleDots'
import StudyBiblePage from './StudyBiblePage'
import LazyLoadPageViewer from '../common/LazyLoadPageViewer'
import FreehandContainer from '../common/FreehandContainer'
import VerticalInsetShadow from '../common/VerticalInsetShadow'

const Image = styled.img`
  vertical-align: middle;
  min-height: 75px;
`

const StyledLazyLoadPageViewer = styled(LazyLoadPageViewer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: ${({ $height }) => $height || 0}px;

  .LazyLoadPageViewer-PageContainer {
    background: none;
    display: flex;
    flex-direction: column;
  }
`

const Pages = styled.div`
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  display: flex;
  width: ${({ $numPages }) => $numPages * 100}%;
`

const StyledStudyBiblePage = styled(StudyBiblePage)`
  ${({ $dotsShown }) => !$dotsShown ? `` : `
    padding-bottom: 0;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const StudyBibleItemInsightContent = ({
  type,
  info,
  details=[],
}) => {

  const { imageUrl, imageDimensions={} } = info || {}

  const [ pageKey, setPageKey ] = useState(0)
  const [ ref, { height } ] = useMeasure()

  const { svgStrokes } = (details[pageKey] || {}).info || {}

  return (
    <>

      <StyledLazyLoadPageViewer
        className="StudyBibleItemInsightContent-StyledLazyLoadPageViewer"
        pageKey={pageKey}
        page={
          <StyledStudyBiblePage
            detail={details[pageKey] || {}}
            addQuotes={[ `QUOTE`, `CREED` ].includes(type)}
            $dotsShown={details.length > 1}
          />
        }
        setPageKey={setPageKey}
        previousPageKey={pageKey - 1}
        nextPageKey={pageKey + 1}
        $height={height}
      />

      <Pages
        ref={ref}
        $numPages={details.length}
        className="StudyBibleItemInsightContent-Pages"
      >
        {details.map((detail, idx) => (
          <StyledStudyBiblePage
            key={idx}
            detail={detail}
            addQuotes={[ `QUOTE`, `CREED` ].includes(type)}
            $dotsShown={details.length > 1}
          />
        ))}
      </Pages>

      {details.length > 1 &&
        <StudyBibleDots
          numDots={details.length}
          pageKey={pageKey}
          setPageKey={setPageKey}
        />
      }

      <FreehandContainer
        svgStrokes={svgStrokes}
      >
        <ImageContainer>
          <Image
            src={imageUrl}
            style={imageDimensions}
          />
          <VerticalInsetShadow />
        </ImageContainer>
      </FreehandContainer>

    </>
  )
}

export default memo(StudyBibleItemInsightContent)