import { useCallback, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useApolloClient } from '@apollo/client'

import useInstanceValue from './useInstanceValue'
import useMutationContext from './useMutationContext'
import { pushUndoRedoItem } from './useGoUndo'
import { pascalToCamelCase, cloneObj, capitalize } from '../utils/misc'

import projectQuery from '../graphql/queries/project'
import userUpdateQuery from '../graphql/queries/userUpdate'
// import projectsQuery from '../graphql/queries/projects'
// import modulesQuery from '../graphql/queries/modules'
// import modulePiecesQuery from '../graphql/queries/modulePieces'
// import highlightsQuery from '../graphql/queries/highlights'

export const defaultExpectedUserUpdate = {
  __typename: "UserUpdate",
  moreToGet: null,
  newUpdatedSince: null,
  folder: null,
  project: null,
  tag: null,
  moduleByProject: null,
  module: null,
  modulePassage: null,
  moduleSetting: null,
  modulePiece: null,
  moduleDot: null,
  moduleMarkup: null,
  formattingKey: null,
  highlight: null,
}

export const recordExpectedResponseData = ({ client, expectedResponseData }) => {

  client.writeQuery({
    query: userUpdateQuery,
    data: {
      userUpdate: expectedResponseData,
    },
  })

  // evict individual deleted rows from cache
  for(let key in expectedResponseData) {

    const { deletedIds=[] } = expectedResponseData[key] || {}
    const tableName = capitalize(key)

    deletedIds.forEach(id => {

      // remove this id
      client.cache.evict({
        id: client.cache.identify({
          __typename: tableName,
          id,
        })
      })

    })

  }

}

export const goGetExtraExpectedResponse = ({ tableName, projectId, moduleId, client, now }) => {

  const extraExpectedResponse = {}

  if([ 'Module', 'ModuleByProject', 'ModulePassage', 'ModuleSetting', 'ModulePiece', 'ModuleDot', 'ModuleMarkup' ].includes(tableName)) {

    // modifiedAt and openedOrModifiedAt need to be set for project
    if(projectId) {

      const { moduleByProjects, ...project } = client.readQuery({
        query: projectQuery,
        variables: {
          id: projectId,
        },
      }).project

      extraExpectedResponse.project = {
        __typename: `ProjectUpdate`,
        deletedIds: [],
        rows: [
          {
            ...project,
            modifiedAt: now,
            openedOrModifiedAt: now,
          },
        ],
      }

      if(![ 'Module', 'ModuleByProject' ].includes(tableName) && moduleId) {

        moduleByProjects.some(({ module }) => {
          if(module.id === moduleId) {

            const { modulePassages, ...moduleWithoutModulePassages } = module

            // modifiedAt and openedOrModifiedAt need to be set for module
            extraExpectedResponse.module = {
              __typename: `ModuleUpdate`,
              deletedIds: [],
              rows: [
                {
                  ...moduleWithoutModulePassages,
                  modifiedAt: now,
                  openedOrModifiedAt: now,
                },
              ],
            }

            return true
          }
          return false
        })

      }

    }

  }

  return extraExpectedResponse

}

const useGoUpdateTable = ({
  currentData,
  updateFunc,
  updateResult,
  mutationPrefix=`update`,
  deleteFunc,
  deleteResult,
  onUpdate,
  onDelete,
  projectId,
  moduleId,
  undoRedoStack=`undo`,
 }) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const tableName = (currentData || {}).__typename || ""
  // tableName possibilities: 'Project', 'Folder', 'Tag', 'Module', 'ModuleByProject', 'ModulePassage', 'ModuleSetting', 'ModulePiece', 'ModuleDot', 'ModuleMarkup', 'FormattingKey', 'Highlight'

  const dataRef = useRef()
  dataRef.current = currentData || {}

  const getExtraExpectedResponse = useCallback(
    ({ now, moduleIdOverride }) => (
      goGetExtraExpectedResponse({
        tableName,
        projectId,
        moduleId: moduleIdOverride || moduleId,
        client,
        now,
      })
    ),
    [ tableName, projectId, moduleId, client ],
  )

  undoRedoStack = (
    (
      [ 'ModulePassage', 'ModulePiece', 'ModuleDot', 'ModuleMarkup', 'ModuleSetting' ].includes(tableName)
      && undoRedoStack
    ) || `none`
  )

  const goUpdateTable = useCallback(
    (updateObj={}, currentDataOverride, moduleIdOverride) => {
      // note: call with empty updatedObj to just indicate open (relevant for project, module)

      const dataRefToUse = currentDataOverride ? { current: currentDataOverride } : dataRef

      const queryName = pascalToCamelCase(tableName)
      const now = updateObj.savedAt || Date.now()
      const isNew = !dataRefToUse.current.savedAt

      if(
        [ 'Project', 'Tag', 'Module', 'FormattingKey', 'Highlight' ].includes(tableName)
        && !dataRefToUse.current.createdAt
      ) {
        updateObj.createdAt = now
      }

      if(![ 'Tag' ].includes(tableName)) {
        updateObj.savedAt = now
      }

      if([ 'Project', 'Module' ].includes(tableName)) {
        if(Object.keys(updateObj).length !== 0) {
          updateObj.modifiedAt = now
        }
        updateObj.openedOrModifiedAt = now
      }

      const previousData = cloneObj(dataRefToUse.current)

      dataRefToUse.current = {
        ...dataRefToUse.current,
        ...updateObj,
      }

      if(!dataRefToUse.current.id) {
        dataRefToUse.current.id = uuidv4()
      }

      if([ `undo`, `redo` ].includes(undoRedoStack)) {
        if(isNew) {
          pushUndoRedoItem({
            type: undoRedoStack,
            action: `delete${tableName}`,
            data: cloneObj(dataRefToUse.current),
          })
        } else {
          const undoUpdateObj = {}
          Object.keys(updateObj).forEach(key => {
            if(![ `savedAt` ].includes(key)) {
              undoUpdateObj[key] = previousData[key]
            }
          })
          pushUndoRedoItem({
            type: undoRedoStack,
            action: `update${tableName}`,
            data: cloneObj(dataRefToUse.current),
            updateObj: cloneObj(undoUpdateObj),
          })
        }
      }

      if(
        projectId
        && [ 'ModuleByProject' ].includes(tableName)
      ) {
        dataRefToUse.current.projectId = projectId
      }

      if(updateObj.id) {
        updateObj = { ...updateObj }
        delete updateObj.id
      }

      const variables = {
        id: dataRefToUse.current.id,
        input: cloneObj(updateObj),
      }

      if(
        projectId
        && [ 'Module', 'ModulePassage', 'ModuleSetting', 'ModulePiece', 'ModuleDot', 'ModuleMarkup' ].includes(tableName)
      ) {
        variables.projectId = projectId
      }

      const newData = cloneObj(dataRefToUse.current)
      const rows = [ cloneObj(newData) ]

      if(tableName === 'Project') {
        delete rows[0].moduleByProjects
      }

      if(tableName === 'Module') {
        delete rows[0].modulePassages
      }

      if(tableName === 'ModuleByProject') {
        delete rows[0].module
      }

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        [queryName]: {
          __typename: `${tableName}Update`,
          ...(tableName === 'ModuleSetting' ? {} : {
            deletedIds: [],
          }),
          rows,
        },
        ...getExtraExpectedResponse({
          now,
          moduleIdOverride,
        }),
      }

      updateFunc({
        variables,
        context: {
          ...getContext(),
          expectedResponse: {
            [`${mutationPrefix}${tableName}`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData })

      onUpdate && onUpdate({ newData })

      return cloneObj(newData)
    },
    [ tableName, projectId, updateFunc, getContext, getExtraExpectedResponse, client, onUpdate, mutationPrefix, undoRedoStack ],
  )

  const goDeleteTable = useCallback(
    () => {

      const queryName = pascalToCamelCase(tableName)
      const now = Date.now()

      const { id } = dataRef.current
      if(!id) {
        console.error('goDeleteTable called without currentData.id', tableName)
        return false
      }

      if([ `undo`, `redo` ].includes(undoRedoStack)) {
        const initData = cloneObj(dataRef.current)
        // must use the previous id
        delete initData.moduleId
        delete initData.savedAt
        delete initData.createdAt
        delete initData.updatedAt
        delete initData.__typename
        pushUndoRedoItem({
          type: undoRedoStack,
          action: `create${tableName}`,
          data: { moduleId },
          initData,
        })
      }

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        [queryName]: {
          __typename: `${tableName}Update`,
          deletedIds: [ id ],
          rows: [],
        },
        ...getExtraExpectedResponse({ now }),
      }

      deleteFunc({
        variables: {
          id,
          savedAt: now,
        },
        context: {
          ...getContext(),
          // optimistically delete just the main record for now
          // TODO: the UserUpdate which is returned should take care of deleting the subtables (when relevant)
          expectedResponse: {
            [`delete${tableName}`]: expectedResponseData,
          },
        },
      })

      recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

      onDelete && onDelete({ ids: [ id ] })

      return now
    },
    [ tableName, deleteFunc, getContext, getExtraExpectedResponse, onDelete, client, undoRedoStack, moduleId ],
  )

  if(updateResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('updateResult.error', tableName, updateResult.error)
  }

  if((deleteResult || {}).error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('deleteResult.error', tableName, deleteResult.error)
  }

  const toReturn = useMemo(
    () => ([
      goUpdateTable,
      goDeleteTable,  
    ]),
    [ goUpdateTable, goDeleteTable ]
  )

  return toReturn
}

export default useGoUpdateTable