import { memo, useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import { useMeasure } from 'react-use'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'

import ConfirmDialog from './ConfirmDialog'
import useInstanceValue from '../../hooks/useInstanceValue'

import CustomSlider from './CustomSlider'

import editStudyBibleImageMutation from '../../graphql/mutations/editStudyBibleImage'

const StyledConfirmDialog = styled(ConfirmDialog)`

  .MuiDialogTitle-root {
    padding-bottom: 0;
  }

  .MuiDialogContentText-root {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .MuiDialog-paper {
    align-self: flex-start;
    max-width: none;
    margin: 20px;
  }

  .MuiDialogContent-root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

`

const OuterContainer = styled.div`
  position: relative;
  margin: 10px 0;
`

const Container = styled.div`
  min-width: 100px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
`

const CropSquare = styled.div`
  border: 600px solid rgb(0 0 0/.5);
  position: absolute;
`

const CropHandle = styled.div`
  border-radius: 10px;
  width: 13px;
  height: 13px;
  margin: -11px;
  background-color: black;
  position: absolute;
`

const ImageToEdit = styled.img`
  max-height: 600px;
  max-width: min(600px, calc(100vw - 84px));
  height: auto;
  width: auto;
  vertical-align: middle;
`

const ImageSizePreviewContainer = styled.div`
  overflow: hidden;
  max-width: calc(min(600px, calc(100vw - 84px)) + 2);
  position: relative;
  z-index: 1;
  margin: -1px;
  padding: 1px 1px 10px 1px;
  flex: 1;

  :after {
    background: linear-gradient(-45deg, #ffffff 6px, transparent 0), linear-gradient(45deg, #ffffff 6px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 12px 12px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 12px;
  }
`

const ImageSizePreviewText = styled.div`
  margin-top: 5px;
  
`

const StyledCustomSlider = styled(CustomSlider)`
  margin-top: 10px;

  .CustomSlider-SliderHeading {
    font-size: 12px;
  }
`

const ImageSizePreview = styled.div`
  outline: 1px solid ${({ theme }) => theme.palette.grey[400]};
  background-image: url(${({ $url }) => $url});
  background-repeat: no-repeat;
`

const EditImageDialog = ({
  onClose,
  url,
  updateUrl,
  minWidth,
  minHeight,
  maxHeight,
  // to add in maxWidth, I probably just need to replace 600 everywhere
  ...otherProps
}) => {

  const [ naturalImageSize, setNaturalImageSize ] = useState({})
  const [ saving, setSaving ] = useState(false)
  const [ handle1Position, setHandle1Position ] = useState({ left: 0, top: 0 })
  const [ handle2Position, setHandle2Position ] = useState({ right: 0, bottom: 0 })
  const getHandle1Position = useInstanceValue(handle1Position)
  const getHandle2Position = useInstanceValue(handle2Position)
  const [ scaleDownValue, setScaleDownValue ] = useState(1)

  const [ containerRef, { width, height } ] = useMeasure()
  const imageRef = useRef()
  const imagePreviewRef = useRef()

  const [ editStudyBibleImage ] = useMutation(editStudyBibleImageMutation)

  const onLoadImage = useCallback(
    () => {
        const { naturalWidth, naturalHeight } = imageRef.current
        setNaturalImageSize({
          width: naturalWidth,
          height: naturalHeight,
        })
    },
    [],
  )

  const resize1 = useCallback(
    ({ clientX, clientY }) => {
        const { naturalWidth, naturalHeight } = imageRef.current
        const { bottom, right } = getHandle2Position()
        const { top, left, width, height } = imageRef.current.getBoundingClientRect()

        const maxLeft = width - right - (minWidth * (width / naturalWidth))
        const maxTop = height - bottom - (minHeight * (height / naturalHeight))

        setHandle1Position({
          left: Math.max(Math.min(clientX - left, maxLeft), 0),
          top: Math.max(Math.min(clientY - top, maxTop), 0),
        })
    },
    [ getHandle2Position, minWidth, minHeight ],
  )

  const resize2 = useCallback(
    ({ clientX, clientY }) => {
      const { naturalWidth, naturalHeight } = imageRef.current
      const handle1Position = getHandle1Position()
      const { top, left, width, height } = imageRef.current.getBoundingClientRect()

      const maxRight = width - handle1Position.left - (minWidth * (width / naturalWidth))
      const maxBottom = height - handle1Position.top - (minHeight * (height / naturalHeight))

      setHandle2Position({
        right: Math.max(Math.min(left + width - clientX, maxRight), 0),
        bottom: Math.max(Math.min(top + height - clientY, maxBottom), 0),
      })
    },
    [ getHandle1Position, minWidth, minHeight ],
  )

  const onTouchStart = useCallback(
    (resize, event) => {

      event.preventDefault()

      const onTouchEnd = () => {
        document.body.removeEventListener('touchmove', onTouchMove)
        document.body.removeEventListener('touchend', onTouchEnd)
        document.body.removeEventListener('touchcancel', onTouchEnd)
      }

      const onTouchMove = ({ touches }) => {
        if(touches.length !== 1) {
          onTouchEnd()
        } else {
          resize(touches[0])
        }
      }

      document.body.addEventListener('touchmove', onTouchMove)
      document.body.addEventListener('touchend', onTouchEnd)
      document.body.addEventListener('touchcancel', onTouchEnd)

    },
    [],
  )

  const onMouseDown = useCallback(
    (resize, event) => {

      event.preventDefault()

      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', resize)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseleave', onMouseUp)
      }

      document.body.addEventListener('mousemove', resize)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseleave', onMouseUp)

    },
    [],
  )

  const onTouchStart1 = useCallback(event => onTouchStart(resize1, event), [ onTouchStart, resize1 ])
  const onTouchStart2 = useCallback(event => onTouchStart(resize2, event), [ onTouchStart, resize2 ])
  const onMouseDown1 = useCallback(event => onMouseDown(resize1, event), [ onMouseDown, resize1 ])
  const onMouseDown2 = useCallback(event => onMouseDown(resize2, event), [ onMouseDown, resize2 ])

  const percentageOfWidthToShow = 1 - (handle1Position.left + handle2Position.right) / width
  const percentageOfHeightToShow = 1 - (handle1Position.top + handle2Position.bottom) / height
  const heightOverWidth = (height - handle1Position.top - handle2Position.bottom) / (width - handle1Position.left - handle2Position.right)
  const adjustedWidth = Math.min(width, (naturalImageSize.width / 2) * percentageOfWidthToShow, (maxHeight/2) / heightOverWidth)
  const adjustedHeight = adjustedWidth * heightOverWidth
  // const adjustedScaleDownValue = Math.max((200/2) / Math.min(adjustedWidth, adjustedHeight), scaleDownValue)
  const adjustedScaleDownValue = Math.min(Math.max((minWidth/2) / adjustedWidth, (minHeight/2) / adjustedHeight, scaleDownValue), 1)
  const scaledWidth = adjustedWidth * adjustedScaleDownValue
  const scaledHeight = adjustedHeight * adjustedScaleDownValue
  const heightExceedingMaxCausingWidthToBeBelowMin = scaledWidth * 2 < minWidth
  const backgroundWidth = scaledWidth / percentageOfWidthToShow
  const backgroundHeight = scaledHeight / percentageOfHeightToShow
  const backgroundPositionLeft = (handle1Position.left / width) * -backgroundWidth
  const backgroundPositionTop = (handle1Position.top / height) * -backgroundHeight
  const imageSizePreviewStyle = {
    width: scaledWidth || 0,
    height: scaledHeight || 0,
    backgroundPosition: `${backgroundPositionLeft}px ${backgroundPositionTop}px`,
    backgroundSize: `${backgroundWidth}px`,
  }

  const updateScaleDownValue = useCallback((event, value) => setScaleDownValue(value / 100), [])

  const onConfirm = useCallback(
    async () => {
      setSaving(true)

      let { backgroundSize, width, height, backgroundPosition } = window.getComputedStyle(imagePreviewRef.current)
      backgroundSize = parseFloat(backgroundSize)
      width = parseFloat(width)
      height = parseFloat(height)
      const [ x, left, top ] = backgroundPosition.match(/^([-0-9.]+)px ([-0-9.]+)px$/).map(parseFloat)  // eslint-disable-line no-unused-vars
      const scale = backgroundSize / naturalImageSize.width

      const editInfo = {
        crop: [
          -left / scale,
          -top / scale,
          width / scale,
          height / scale,
        ],
        resize: [
          width * 2,
          height * 2,
        ],
      }

      const { data: { editStudyBibleImage: newUrl } } = await editStudyBibleImage({
        variables: {
          url,
          editInfo,
        },
      })

      updateUrl(newUrl)

      onClose()
      setSaving(false)
    },
    [ onClose, naturalImageSize, editStudyBibleImage, updateUrl, url ],
  )

  return (
    <StyledConfirmDialog
      title={i18n("Crop & Resize Image")}
      confirmButtonLabel={i18n("Crop & Resize")}
      {...otherProps}
      onConfirm={onConfirm}
      onCancel={onClose}
      disabled={heightExceedingMaxCausingWidthToBeBelowMin}
      explanation={
        <>
          <OuterContainer>
            <Container
              ref={containerRef}
            >
              <ImageToEdit
                src={url}
                ref={imageRef}
                onLoad={onLoadImage}
              />
              <CropSquare
                style={{
                  left: handle1Position.left - 600,
                  top: handle1Position.top - 600,
                  right: handle2Position.right - 600,
                  bottom: handle2Position.bottom - 600,
                }}
              />
            </Container>
            <CropHandle
              style={handle1Position}
              onMouseDown={onMouseDown1}
              onTouchStart={onTouchStart1}
            />
            <CropHandle
              style={handle2Position}
              onMouseDown={onMouseDown2}
              onTouchStart={onTouchStart2}
            />
          </OuterContainer>
          <ImageSizePreviewText>
            {i18n("Size Preview")}
          </ImageSizePreviewText>
          <ImageSizePreviewContainer>
            <ImageSizePreview
              ref={imagePreviewRef}
              $url={url}
              style={imageSizePreviewStyle}
            />
          </ImageSizePreviewContainer>
          {adjustedWidth * 2 > minWidth && adjustedHeight * 2 > minHeight &&
            <StyledCustomSlider
              label={i18n("Size Reducer")}
              min={0}
              max={100}
              color="secondary"
              value={parseInt(scaleDownValue * 100)}
              onChange={updateScaleDownValue}
            />
          }
        </>
      }
      loading={saving}
    />
  )
}

export default memo(EditImageDialog)