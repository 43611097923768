import { useCallback } from "react"
import { useMutation, useApolloClient } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { cloneObj } from '../utils/misc'
import { defaultExpectedUserUpdate, goGetExtraExpectedResponse, recordExpectedResponseData } from './useGoUpdateTable'
import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'

import modulePiecesQuery from '../graphql/queries/modulePieces'
import bulkCreateModulePiecesMutation from '../graphql/mutations/bulkCreateModulePieces'

const useDuplicateModulePieces = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
  onUpdate,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const [ bulkCreateModulePieces, bulkCreateModulePiecesResult ] = useMutation(bulkCreateModulePiecesMutation)

  if(bulkCreateModulePiecesResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('bulkCreateModulePieces.error', bulkCreateModulePiecesResult.error)
  }

  const goDuplicateModulePieces = useCallback(
    async () => {

      // get all pieces
      const { data: { modulePieces } } = await client.query({
        query: modulePiecesQuery,
        variables: {
          moduleId: moduleToCopyFrom.id,
        },
        context: getContext(),
      })

      const now = Date.now()

      const data = modulePieces.map(row => ({
        ...row,
        id: uuidv4(),
        moduleId: newModuleId,
        savedAt: now,
      }))

      const input = cloneObj(data)
      input.forEach(row => {
        delete row.__typename
      })

      const variables = {
        input,
        projectId,
      }

      const rows = cloneObj(data)

      const expectedResponseData = {
        ...defaultExpectedUserUpdate,
        modulePiece: {
          __typename: `ModulePieceUpdate`,
          deletedIds: [],
          rows,
        },
        ...goGetExtraExpectedResponse({
          tableName: `ModulePiece`,
          projectId,
          moduleId: newModuleId,
          client,
          now,
        }),
      }

      const bulkCreateMutationPromise = (
        rows.length > 0
          ? (
            bulkCreateModulePieces({
              variables,
              context: {
                ...getContext(),
                expectedResponse: {
                  bulkCreateModulePieces: expectedResponseData,
                },
              },
            })
          )
          : Promise.resolve()
      )

      if([ `off`, `on-query-sync` ].includes(getContext().offlineSetupStatus)) {
        // wait for the successful mutation to the server
        await bulkCreateMutationPromise
      } else {
        // don't wait, since we will get the data from dexie anyway
        recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })
      }

      onUpdate && onUpdate({ newData: cloneObj(data) })

      return cloneObj(data)

    },
    [ moduleToCopyFrom, newModuleId, projectId, onUpdate, client, bulkCreateModulePieces, getContext ],
  )

  return goDuplicateModulePieces
}

export default useDuplicateModulePieces
