import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import HistoryIcon from '@material-ui/icons/History'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import { isOriginalLanguageSearch, getRefsFromPassageStr } from "@bibletags/bibletags-ui-helper"

import { IS_EMBED } from '../utils/constants'
import { LoggedInUserContext } from '../context/LoggedInUser'
import { getOriginalWordsForSearch, preventDefaultEvent } from '../utils/misc'
import { getEmbedSettings } from '../graphql/links/embedLink'
import useRecentSearchManager from './useRecentSearchManager'

const Placeholder = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
`

const QuotationMark = styled.span`
  color: rgb(40 174 39);
`

const parenthasesColors = [
  'rgb(15 106 186)',
  'rgb(202 12 46)',
  'rgb(230 151 5)',
  'rgb(168 48 255)',
  'rgb(40 174 39)',
]
const ParenthasesOrSlash = styled.span`
  ${({ $depth }) => `color: ${parenthasesColors[$depth % parenthasesColors.length]}`}
`

const Star = styled.span`
  color: rgb(230 151 5);
`

const DotOrPlaceholderStar = styled.span`
  color: rgb(40 174 39);
`

const Flag = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const Version = styled.span`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const Action = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 2px;
  margin: 0 12px;
  padding: 0 4px;
  height: 18px;
  align-self: center;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  vertical-align: top;
`

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  height: 12px;
  margin: 0 -6px -2px -1px;
`

const StyledArrowRightAltIcon = styled(ArrowRightAltIcon)`
  height: 20px;
  margin: -2px -2px;
`

const Container = styled.div`
  display: flex;
`

const Words = styled.div`
  min-width: 0;
  flex-shrink: 1;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
`

const Word = styled.span`
  position: relative;
  display: inline-block;

  ${({ $showUnderline, theme }) => !$showUnderline ? `` : `
    ::before {
      content: "";
      background-color: ${theme.palette.grey[400]};
      height: 1px;
      position: absolute;
      left: 1px;
      right: 0;
      bottom: 11px;
    }
  `}
`

const OrigWord = styled.div`
  position: absolute;
  top: -17px;
  left: 0;
  right: 0;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.grey[600]};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Gloss = styled(OrigWord)`
  top: auto;
  bottom: -16px;
  font-size: 11px;
`

const Detail = styled.span`
  position: relative;
  display: inline-block;
`

const HashOrSlash = styled.span`
  color: ${({ theme }) => theme.palette.grey[500]};
`

const TabAddition = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
`

const Spacer = styled.div`
  flex: 1;
`

const IconContainer = styled.div`
  height: 19px;
  width: 19px;
  align-self: center;
  margin-left: 12px;
  position: relative;

  .useFormattedSearchValue-close {
    opacity: 0;
  }

  :hover .useFormattedSearchValue-history {
    opacity: 0;
  }

  :hover .useFormattedSearchValue-close {
    opacity: 1;
  }

`

const iconStyles = `
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  transition: opacity .15s ease-in-out;
`
const StyledHistoryIcon = styled(HistoryIcon)`
  ${iconStyles}
  color: ${({ theme }) => theme.palette.grey[400]};
`

const StyledCloseIcon = styled(CloseIcon)`
  ${iconStyles}
`

const ResultCount = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-left: 12px;
  font-size: 12px;
  height: 52px;
`

const ForceVerticalAlignmentWithWords = styled.span`
  display: inline-block;
  color: transparent;
  margin-left: -4px;  // roughly the width of a period, such that this does not affect layout
  font-size: 16px;  // matches the font-size of the words, forcing the baseline on the ResultCount to match it
`

const useFormattedSearchValue = ({
  value,
  action,
  versionId,
  tabAddition,
  suggestedQuery,
  resultCount,
  from,
  isSuggestion,
  noUnderline,
  // logo,
}) => {

  let formattedValue = value || suggestedQuery || ``

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const { removeFromRecentSearches } = useRecentSearchManager()

  const removeFromHistory = useCallback(
    event => {
      preventDefaultEvent(event)
      removeFromRecentSearches(suggestedQuery)
    },
    [ removeFromRecentSearches, suggestedQuery ],
  )

  if(!formattedValue) {
    return (
      <Placeholder>
        {
          hasToolsPlan
            ? i18n("Search Bibles, projects, and more...")
            : (
              (IS_EMBED && getEmbedSettings().embedType === `search`)
                ? i18n("Search Bibles...")
                : i18n("Search Bibles, and more...")
            )
        }
      </Placeholder>
    )
  }

  const historyIndicator = (
    <>
      <Spacer />
      <Tooltip
        title={i18n("Remove from history")}
      >
        <IconContainer onClick={removeFromHistory}>
          <StyledHistoryIcon className="useFormattedSearchValue-history" />
          <StyledCloseIcon className="useFormattedSearchValue-close" />
        </IconContainer>
      </Tooltip>
    </>
  )

  if(action) {
    const actionText = {
      open: i18n("Open"),
      read: i18n("Read"),
      "open-new-tab": (
        <>
          {i18n("Open")}
          <StyledOpenInNewIcon />
        </>
      ),
    }[action] || <StyledArrowRightAltIcon />

    let versionText
    if(action === 'read' && versionId && /^(.*)( .+)$/.test(formattedValue)) {
      ;[ formattedValue, versionText ] = formattedValue.match(/^(.*)( .+)$/).slice(1)
    }

    formattedValue = (
      <Container>
        <Words>
          {formattedValue}
          {!!versionText && <Version>{versionText}</Version>}
        </Words>
        {!!actionText && <Action>{actionText}</Action>}
        {isSuggestion && [ `search-history`, `project-search-history` ].includes(from) && historyIndicator}
      </Container>
    )
  }

  if(!action) {
    let parenDepth = 0
    const words = formattedValue.split(/( +|[()"])/g).filter(Boolean)
    const originalWords = getOriginalWordsForSearch()

    const isOrigLanguageSearch = isOriginalLanguageSearch(formattedValue)
    const { refs=[] } = (!isOrigLanguageSearch && getRefsFromPassageStr(formattedValue)) || {}
    const foundPassageRef = refs.length !== 0

    const showResults = !!(
      resultCount
      || (
        resultCount === 0
        && !foundPassageRef
      )
    )
    formattedValue = (
      <Container>

        <Words>

          {words.map((word, idx) => {
            const isLastWord = idx === words.length - 1

            if(word === '"') {
              return (
                <QuotationMark key={idx}>"</QuotationMark>
              )
            } else if(word === '(') {
              return (
                <ParenthasesOrSlash key={idx} $depth={parenDepth++}>(</ParenthasesOrSlash>
              )
            } else if(word === ')') {
              return (
                <ParenthasesOrSlash key={idx} $depth={--parenDepth}>)</ParenthasesOrSlash>
              )
            } else if(/^[0-9]+\+|\/$/.test(word)) {
              return (
                <ParenthasesOrSlash key={idx} $depth={Math.max(parenDepth - 1, 0)}>{word}</ParenthasesOrSlash>
              )
            } else if(word === '...') {
              return (
                <DotOrPlaceholderStar key={idx}>...</DotOrPlaceholderStar>
              )
            } else if(word === '*') {
              return (
                <DotOrPlaceholderStar key={idx}>*</DotOrPlaceholderStar>
              )
            } else if(/.\*$/.test(word)) {
              return (
                <React.Fragment key={idx}>
                  {word.slice(0, -1)}
                  <Star>*</Star>
                </React.Fragment>
              )
            } else if(/^[a-z]+:[-a-zA-Z,/+]+(?::[0-9]+)?$/.test(word)) {
              return (
                <Flag key={idx}>{word}</Flag>
              )
            }

            if(!/^(?:#|(?:#[^=#]+)+|=[^=#]*)$/.test(word)) return word
            const details = word.split(/(#[^#]*)/g).filter(Boolean)
            return (
              <Word
                key={idx}
                $showUnderline={!noUnderline && !!value}
              >
                {details.map((detail, idx) => {
                  const isLastDetail = idx === details.length - 1
                  const tabAdditionToUse = (isLastWord && isLastDetail && tabAddition) || ``
                  const originalWord = originalWords[`${detail}${tabAdditionToUse}`.replace(/^#(?:not:)?/, '')]
                  return (
                    <Detail key={idx}>
                      {originalWord && <OrigWord>{originalWord.lex}</OrigWord>}
                      {
                        detail
                          .split(/([#/])/g)
                          .map((hashSlashOrOtherwise, idx) => (
                            [ '#', '/' ].includes(hashSlashOrOtherwise)
                              ? <HashOrSlash key={idx}>{hashSlashOrOtherwise}</HashOrSlash>
                              : hashSlashOrOtherwise
                          ))
                      }
                      {!!tabAdditionToUse &&
                        <TabAddition>{tabAdditionToUse}</TabAddition>
                      }
                      {!!suggestedQuery && originalWord && <Gloss>{originalWord.gloss}</Gloss>}
                    </Detail>
                  )
                })}
              </Word>
            )
          })}

        </Words>

        {showResults &&
          <ResultCount>
            {resultCount === -1
              ? i18n("error")
              : (
                i18n("{{count}}x", {
                  count: resultCount,
                })
              )
            }
            <ForceVerticalAlignmentWithWords>.</ForceVerticalAlignmentWithWords>
          </ResultCount>
        }

        {isSuggestion && [ `search-history`, `project-search-history` ].includes(from) && historyIndicator}

      </Container>
    )
  }

  return formattedValue

}

export default useFormattedSearchValue
