import { memo, useMemo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CallReceivedIcon from '@material-ui/icons/CallReceived'

import {
  MINIMUM_PASSAGE_COLUMN_WIDTH,
  MINIMUM_MODULE_COLUMN_WIDTH,
  MINIMUM_NOTES_COLUMN_WIDTH,
  HIDDEN_COLUMN_WIDTH,
  THUMBNAILS_WIDTH,
} from '../../../utils/constants'
import useInstanceValue from '../../../hooks/useInstanceValue'

import MinimizedPanel from '../../common/MinimizedPanel'

const MinimizedPanelIconButton = styled(IconButton)`
  padding: 8px;
  margin: 4px 1px 4px 0;

  .MuiSvgIcon-root {
    height: 18px;
  }
`

const NotesMinimizedPanel = ({
  notesModuleByProjects,
  toggleInDrawer,
  goUpdateProject,
  getProjectSizes,
  passageBookmarks,
}) => {

  const getPassageBookmarks = useInstanceValue(passageBookmarks)

  const buttonsInfo = useMemo(
    () => (
      notesModuleByProjects.map(({ module }, idx) => ({
        label: module.label,
        onClick: () => {
          const { module1Width, module2Width, showInDoubleStudyMode, passagesWidth } = getProjectSizes()
          const passageBookmarks = getPassageBookmarks()

          let modulesAndNotesWidth = module1Width + module2Width + THUMBNAILS_WIDTH + HIDDEN_COLUMN_WIDTH
          const minModulesAndNotesWidth = MINIMUM_MODULE_COLUMN_WIDTH * (showInDoubleStudyMode ? 2 : 1) + THUMBNAILS_WIDTH + MINIMUM_NOTES_COLUMN_WIDTH

          const getNewPassageBookmarks = width => [
            {
              ...(passageBookmarks[0] || {}),
              width,
            },
            ...passageBookmarks.slice(1),
          ]

          if(modulesAndNotesWidth >= minModulesAndNotesWidth) {

            // take space from modules
            goUpdateProject({
              notesWidthPercentage: (MINIMUM_NOTES_COLUMN_WIDTH / modulesAndNotesWidth) * 100,
              notesModuleId: module.id,
            })

          } else if(passagesWidth + HIDDEN_COLUMN_WIDTH >= MINIMUM_NOTES_COLUMN_WIDTH + MINIMUM_PASSAGE_COLUMN_WIDTH) {

            // take space from passage
            modulesAndNotesWidth += MINIMUM_NOTES_COLUMN_WIDTH - HIDDEN_COLUMN_WIDTH
            goUpdateProject({
              notesWidthPercentage: (MINIMUM_NOTES_COLUMN_WIDTH / modulesAndNotesWidth) * 100,
              passageBookmarks: getNewPassageBookmarks(passagesWidth - MINIMUM_NOTES_COLUMN_WIDTH + HIDDEN_COLUMN_WIDTH),
              notesModuleId: module.id,
            })

          } else {

            // minimize passage panel in order to expand notes
            modulesAndNotesWidth += passagesWidth - HIDDEN_COLUMN_WIDTH
            goUpdateProject({
              notesWidthPercentage: (passagesWidth / modulesAndNotesWidth) * 100,
              passageBookmarks: getNewPassageBookmarks(HIDDEN_COLUMN_WIDTH),
              notesModuleId: module.id,
            })
          }

        },
      }))
    ),
    [ notesModuleByProjects, goUpdateProject, getProjectSizes, getPassageBookmarks ],
  )

  return (
    <MinimizedPanel
      buttonsOnTop={
        <MinimizedPanelIconButton
          onClick={toggleInDrawer}
        >
          <CallReceivedIcon />
        </MinimizedPanelIconButton>
      }
      buttonsInfo={buttonsInfo}
    />
  )
}

export default memo(NotesMinimizedPanel)