import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation deleteModuleDot($id: ID!, $savedAt: Milliseconds!, $updatedSince: Milliseconds) {
    deleteModuleDot(id: $id, savedAt: $savedAt, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`