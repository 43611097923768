import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'

import NewOrLearning from '../shared/NewOrLearning'
import LinkButton from '../../common/LinkButton'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Spacer = styled.div`
  height: 10px;
`

const Links = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-top: 15px;
`

const GradCap = styled.span`
  display: inline-block;
  margin-right: 5px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: transform .15s ease-in-out;

  @media (hover: hover) {
    :hover {
      transform: scale(1.03);
    }
  }
`

const MiniCourseImg = styled.img`
  width: 300px;
  max-width: 100%;
  margin: 20px 0 2px;
  vertical-align: middle;
`

const NewToDiscourse = () => {

  const isLoggedIn = useIsLoggedIn()

  const autologin = isLoggedIn ? `?autologin` : ``

  return (
    <NewOrLearning>

      <LinkButton
        href={`https://equip.biblearc.com/course/arcing${autologin}`}
        variant="contained"
        color="primary"
        disableElevation
      >
        <GradCap className="dark-mode-exempt">🎓</GradCap>
        {i18n("Take the Arcing course", "", "discourse")}
      </LinkButton>

      <Spacer />

      <LinkButton
        href={`https://equip.biblearc.com/course/bracketing${autologin}`}
        variant="contained"
        color="primary"
        disableElevation
      >
        <GradCap className="dark-mode-exempt">🎓</GradCap>
        {i18n("Take the Bracketing course", "", "discourse")}
      </LinkButton>

      <StyledNavLinkOrAWithDisable
        to="https://equip.biblearc.com/course/bible-logic"
      >
        <MiniCourseImg
          src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723476957606-BibleLogicMiniCourse4xtiny.jpeg"
          className="dark-mode-exempt"
        />
      </StyledNavLinkOrAWithDisable>

      <Links>
        <NavLinkOrAWithDisable to="https://equip.biblearc.com/course/arcing/86117">
          {i18n("What is Arcing?")}
        </NavLinkOrAWithDisable>
        <NavLinkOrAWithDisable to="https://equip.biblearc.com/course/arcing/21207">
          {i18n("Demo")}
        </NavLinkOrAWithDisable>
      </Links>

    </NewOrLearning>
  )
}

  // What is Arcing? (animation) 
  // Arcing demonastration from Arcing course https://equip.biblearc.com/course/arcing/21207


export default memo(NewToDiscourse)