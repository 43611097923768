import { memo } from 'react'
import styled from 'styled-components'

const NotesContainerWithFocusIndicator = styled.div`

  :focus-within, .pseudo-focus {
    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

`

export default memo(NotesContainerWithFocusIndicator)