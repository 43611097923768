import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

import moduleDotQuery from '../graphql/queries/moduleDot'
import updateModuleDotMutation from '../graphql/mutations/updateModuleDot'
import deleteModuleDotMutation from '../graphql/mutations/deleteModuleDot'

export const getDefaultModuleDot = () => ({
  __typename: `ModuleDot`,
  content: "",
  positionInfo: null,
  modulePieceId: null,
})

const useGoUpdateModuleDot = ({
  moduleDot,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateModuleDot, updateModuleDotResult ] = useMutation(updateModuleDotMutation)
  const [ deleteModuleDot, deleteModuleDotResult ] = useMutation(deleteModuleDotMutation)

  const goUpdate = useGoUpdateTable({
    currentData: moduleDot || getDefaultModuleDot(),
    updateFunc: updateModuleDot,
    updateResult: updateModuleDotResult,
    deleteFunc: deleteModuleDot,
    deleteResult: deleteModuleDotResult,
    projectId,
    moduleId: moduleId || moduleDot.moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => {
      if(!moduleDot) {
        return [
          initData => {
            // Create a new moduleDot

            const newData = goUpdate[0]({
              ...initData,
              moduleId,
            })

            client.writeQuery({
              query: moduleDotQuery,
              data: {
                moduleDot: newData,
              },
              variables: {
                id: newData.id,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ moduleDot, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModuleDot