import { memo } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import PaletteIcon from '@material-ui/icons/Palette'
// import { i18n } from 'inline-i18n'

import { formattingColors } from '../../utils/formatting'
import useModalAnchor from '../../hooks/useModalAnchor'

import OptionsPopover from './OptionsPopover'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  padding: 5px;
`

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin: 5px;
  background-color: ${({ theme, $color }) => (theme.palette.markupColors[$color] || {}).arrow};
  transition: transform .15s ease-in-out;

  :hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const ColorChooser = ({
  skipColors=[],
  onChange,
  keys,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  return (
    <>

      <StyledIconButton
        onClick={openModal}
      >
        <PaletteIcon />
      </StyledIconButton>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
      >
        <Container>
          {formattingColors.filter(color => !skipColors.includes(color)).map((color, idx) => (
            <Dot
              key={color}
              onClick={() => {
                onChange({ keys, newValue: color })
                closeModal()
              }}
              $color={color}
            >
            </Dot>
          ))}
        </Container>
      </OptionsPopover>

    </>
  )
}


export default memo(ColorChooser)