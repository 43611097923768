export const operationsToBlock = [
  `userUpdate`,
  `user`,
  `highlights`,
  `stripeSubscriptionChangePreview`,
  `stripeSubscriptionNextInvoice`,
  `groupSubscriptions`,
  `stats`,
  `users`,
  `searchShared`,
  `moduleHistoryItems`,
  `moduleByProjectHistoryItems`,
  `projectPassageHistoryItems`,
  `accountSettings`,
  `accountSetting`,
  `alertsItems`,
  `projects`,
  `folders`,
  `folderAncestry`,
  `tags`,
  `updateHighlight`,
  `deleteHighlight`,
]

export const operationsToGetFromEmbed = [
  `formattingKeys`,
  `project`,
  `modules`,
  `module`,
  `moduleSetting`,
  `modulePieces`,
  `modulePiece`,
  `moduleDots`,
  `moduleDot`,
  `moduleMarkups`,
  `moduleMarkup`,
]

export const operationsToSaveToEmbed = [
  `updateProject`,
  `updateModule`,
  `deleteModule`,
  `updateModuleByProject`,
  `deleteModuleByProject`,
  `updateModulePassage`,
  `deleteModulePassage`,
  `setModuleSetting`,
  `bulkCreateModulePieces`,
  `updateModulePiece`,
  `updateModulePieces`,
  `deleteModulePiece`,
  `deleteModulePieces`,
  `updateModuleDot`,
  `deleteModuleDot`,
  `deleteModuleDots`,
  `updateModuleMarkup`,
  `deleteModuleMarkup`,
  `deleteModuleMarkups`,
  `updateFormattingKey`,
  `deleteFormattingKey`,
]