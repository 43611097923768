import { forwardRef, memo } from 'react'
import styled from 'styled-components'

import styledResizableDiv from '../../../utils/styledResizableDiv'

import DivWithFocusIndicator from '../../common/DivWithFocusIndicator'

const Container = styledResizableDiv`
  position: relative;
  display: flex;
  flex-direction: column;
`

const InnerContainer = styled(DivWithFocusIndicator)`
  padding: ${({ $padding }) => $padding};
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  ${({ $noneditableViewingMode }) => ![ `shared-page`, `within-notes`, `sketch` ].includes($noneditableViewingMode) ? `` : `
    :focus-within::after, &.pseudo-focus::after {
      display: none;
    }
  `}
`

const ModuleContainer = ({
  width,
  padding,
  children,
  noneditableViewingMode,
  ...otherProps
}, ref) => (
  <Container $width={width} ref={ref}>
    <InnerContainer
      $padding={padding}
      $noneditableViewingMode={noneditableViewingMode}
      {...otherProps}
    >
      {children}
    </InnerContainer>
  </Container>
)

export default memo(forwardRef(ModuleContainer))