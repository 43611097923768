import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation bulkCreateModulePieces($projectId: ID, $input: [BulkCreateModulePieceInput]!, $updatedSince: Milliseconds) {
    bulkCreateModulePieces(projectId: $projectId, input: $input, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`