import { memo, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

import {
  MINIMUM_MEDIUM_WIDTH,
} from '../../../utils/constants'

import PassageRef from '../../common/PassageRef'
import ModuleThumbnailContainer from './ModuleThumbnailContainer'

const Container = styled(ModuleThumbnailContainer)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  transition: border-color .2s ease-in-out;
  background-image: url('/${({ $type }) => $type.toLowerCase()}.svg');
  background-size: contain;
  position: relative;
  user-select: none;

  &::before {
    content: "";
    background-color: rgb(0 0 0/.03);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  ${({ isSelected, theme }) => !isSelected ? `` : `
    border-color: ${theme.palette.grey[800]};
    outline: 2px solid ${theme.palette.grey[800]};

    @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
      outline-width: 1px;
    }
  `}

  ${({ onClick, theme }) => !onClick ? `` : `
    :hover {
      cursor: pointer;
      border-color: ${theme.palette.grey[800]};
    }
  `}
`

const PassageLabel = styled.div`
  position: relative;
  white-space: pre;
  text-align: center;
  font-size: 10px;
  line-height: 1.1;
  overflow: hidden;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
    font-size: 6px;
  }

  ${({ $numRefs }) => $numRefs > 1 ? `` : `

    font-size: 11px;

    &::first-line {
      font-size: 15px;
    }

    @media (max-width: ${MINIMUM_MEDIUM_WIDTH}px) {
      font-size: 7px;

      &::first-line {
        font-size: 11px;
      }
    }

  `}
`

const StyledPeopleAltIcon = styled(PeopleAltIcon)`
  position: absolute;
  right: 3px;
  bottom: 2px;
  height: 12px;
  width: 12px;
`

const Arrows = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:hover > button {
    opacity: 1;
  }
`

const arrowHorizontalPosition = `-15px`

const LeftArrowIconButton = styled(IconButton)`
  position: absolute; 
  top: 17px;
  left: ${arrowHorizontalPosition};
  width: 30px;
  height: 30px;

  .MuiSvgIcon-root {
    height: 16px;
  }

  ${({ disabled, theme }) => disabled ? `` : `
    border: 1px solid ${theme.palette.divider};
    background-color: white;
    transition: background-color .2s ease-in-out, opacity .2s ease-in-out;
    opacity: 0;

    :hover {
      background-color: ${theme.palette.grey[100]};
    }
  `}

  ${({ disabled, theme }) => !disabled ? `` : `
    background-color: ${theme.palette.grey[800]} !important;
    color: white !important;
  `}

`

const RightArrowIconButton = styled(LeftArrowIconButton)`
  left: auto;
  right: ${arrowHorizontalPosition};
`

const ModuleThumbnail = ({
  project,
  moduleByProject,
  goUpdateProject,
  showInDoubleStudyMode,
}) => {

  const { id: moduleId, modulePassages=[], shared } = moduleByProject.module

  const { studyModule1Id, studyModule2Id } = project

  const isStudyModule1 = moduleByProject.module.id === studyModule1Id
  const isStudyModule2 = moduleByProject.module.id === studyModule2Id

  const open = useCallback(
    placement => {
      const update = {
        [`studyModule${placement}Id`]: moduleId,
      }

      if(placement === 1 && moduleId === studyModule2Id) {
        update.studyModule2Id = studyModule1Id
      } else if(placement === 2 && moduleId === studyModule1Id) {
        update.studyModule1Id = studyModule2Id
      }

      goUpdateProject(update)
    },
    [ goUpdateProject, moduleId, studyModule1Id, studyModule2Id ],
  )

  const openToTheLeft = useCallback(() => open(1), [ open ])
  const openToTheRight = useCallback(() => open(2), [ open ])

  let type = moduleByProject.module.type

  if(type === `DISCOURSE`) {
    // figure out if it is an arc, bracket, or both
    // type = `BRACKETING`
  }

  const fromLocToLocs = []
  const distinctModulePassages = modulePassages.filter(({ fromLoc, toLoc }) => {
    const fromLocToLoc = `${fromLoc}-${toLoc}`
    if(!fromLocToLocs.includes(fromLocToLoc)) {
      fromLocToLocs.push(fromLocToLoc)
      return true
    }
    return false
  })

  return (
    <Container
      $type={type}
      onClick={(showInDoubleStudyMode || isStudyModule1) ? null : openToTheLeft}
      isSelected={isStudyModule1 || (showInDoubleStudyMode && isStudyModule2)}
    >

      <PassageLabel $numRefs={distinctModulePassages.length}>
        {distinctModulePassages.slice(0,3).map(({ fromLoc, toLoc, info: { versionId } }, idx) => (
          <div key={idx}>
            <PassageRef
              fromLoc={fromLoc}
              toLoc={toLoc}
              convertToVersionId={versionId}
              abbreviated={true}
              twoLines={distinctModulePassages.length === 1}
            />
          </div>
        ))}
      </PassageLabel>

      {shared && <StyledPeopleAltIcon />}

      {showInDoubleStudyMode &&
        <Arrows>

          <LeftArrowIconButton
            disabled={isStudyModule1}
            onClick={openToTheLeft}
            tabIndex={-1}
          >
            <ArrowBackIcon />
          </LeftArrowIconButton>

          <RightArrowIconButton
            disabled={isStudyModule2}
            onClick={openToTheRight}
            tabIndex={-1}
          >
            <ArrowForwardIcon />
          </RightArrowIconButton>

        </Arrows>
      }

    </Container>
  )
}

export default memo(ModuleThumbnail)