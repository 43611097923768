import { memo, useContext } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useDataQuery from '../../../hooks/useDataQuery'
import userQuery from '../../../graphql/queries/user'
import useAppSize from '../../../hooks/useAppSize'
import usePassageRef from '../../../hooks/usePassageRef'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getModuleTypeLabel } from '../../../utils/misc'

import Header from "../../common/Header"
import SharedModuleOptionsPopover from "./SharedModuleOptionsPopover"
import TitleAndAuthorForHeader from './TitleAndAuthorForHeader'

const StyledButton = styled(Button)`
  height: ${({ $mobileSize }) => $mobileSize ? `48px` : `38px`};
  margin: ${({ $mobileSize }) => $mobileSize ? `0` : `5px 0`};
  text-transform: none;
  font-size: 18px;
  max-width: 100%;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const SharedHeader = ({
  module,
  goPrintOrDownload,
  loading,
  ...otherProps
}) => {

  const loggedInUser = useContext(LoggedInUserContext)
  const myWork = module.userId === (loggedInUser || {}).id

  let { user } = useDataQuery({
    userQuery,
    skip: !module.userId || myWork,
    variables: {
      id: module.userId,
    },
  })

  if(myWork) {
    user = loggedInUser
  }

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { mobileSize } = useAppSize()

  const { modulePassages=[] } = module
  const { fromLoc, toLoc, info={} } = modulePassages[0] || {}
  const { versionId } = info

  const passageRef = usePassageRef({
    fromLoc,
    toLoc,
    convertToVersionId: versionId,
  })

  let titleOverride

  if(module.type === `NOTES`) {
    titleOverride = module.label
  }

  return (
    <Header
      showBiblearcLogo
      title={
        (titleOverride || fromLoc)
          ? i18n("{{title}} by {{name}}", {
            title: (
              titleOverride
              || (
                i18n("{{module_type}} of {{passages}}", {
                  module_type: getModuleTypeLabel(module),
                  passages: passageRef,
                })
              )
            ),
            name: (user || {}).name,
          })
          : undefined
      }
      {...otherProps}
    >

      {!loading &&
        <StyledButton
          $mobileSize={mobileSize}
          onClick={openModal}
        >

          <TitleAndAuthorForHeader
            module={module}
          />

          <StyledExpandMoreIcon />

        </StyledButton>
      }

      <SharedModuleOptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        module={module}
        goPrintOrDownload={goPrintOrDownload}
        {...otherProps}
      />

    </Header>
  )
}


export default memo(SharedHeader)