import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'session-sync-auth-site/src/sessionSyncAuthFrontend'

import { IGNORE_ERRORS_REGEXES, IS_EMBED } from './utils/constants'
import { loginCallback, logoutCallback, setLocalStorage } from './utils/misc'
import useEffectAsync from './hooks/useEffectAsync'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: "https://57d39fe7c8694ea5b6a792f51dcbb936@o67690.ingest.sentry.io/5816643",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_STAGE,
  tracesSampleRate: process.env.REACT_APP_STAGE === 'production' ? .1 : 1,
  release: process.env.REACT_APP_RELEASE_DATE_AND_TIME,
  ignoreErrors: IGNORE_ERRORS_REGEXES,
})

window.sessionSyncAuth.init({
  defaultOrigin: process.env.REACT_APP_BIBLEARC_DATA_URI.replace(/^(https?:\/\/[^/]*).*$/, '$1'),
  callbacks: {
    successfulLogin: () => {
      setLocalStorage('inLoginProcess', true)
      loginCallback()
    },
    successfulLogout: logoutCallback,
    unnecessaryLogout: logoutCallback,
    error: ({ errorMessage }) => alert(`Auth Error: ${errorMessage}`),  // TODO: have this error reported elsewhere
  },
})

const reload = registration => {
  // Makes Workbox call skipWaiting()
  if(registration.waiting) {
    console.log(`postMessage: SKIP_WAITING`, registration)
    registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }
  // Once the service worker is unregistered, we can reload the page to let
  // the browser download a fresh copy of our app (invalidating the cache)
  setTimeout(() => {
    window.location.reload()
  }, 100)  // the timeout is a blind attempt to make sure the postMessage went through
}

const loadTime = Date.now()

const AppWrapper = () => {

  const [ goUpdate, setGoUpdate ] = useState()
  const [ updating, setUpdating ] = useState(false)
  const [ goResetServiceWorker, setGoResetServiceWorker ] = useState()

  const onRegistrationEvent = registration => {
    if(registration) {

      setGoResetServiceWorker(() => async () => {
        await registration.unregister()
        reload(registration)
      })

      if(registration.waiting) {
        if(IS_EMBED || Date.now() - loadTime < 2000) {
          // Auto update if the update is immediately ready, or if (on embed) it is soon ready
          reload(registration)
        } else {
          setGoUpdate(() => () => {
            setUpdating(true)
            reload(registration)
          })
        }
      }

    }
  }

  useEffectAsync(
    () => {
      // Learn more about service workers: https://cra.link/PWA
      serviceWorkerRegistration.register({
        onRegistrationReady: onRegistrationEvent,
        onUpdate: onRegistrationEvent,
      })
    },
    [],
  )

  return (
    <App
      goUpdate={goUpdate}
      updating={updating}
      goResetServiceWorker={goResetServiceWorker}
    />
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
