import { memo, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { useMeasure, useCounter } from 'react-use'

// import { DEFAULT_NUM_VERSES_PER_BLOCK_FOR_PHRASING } from '../../../utils/constants'
// import useSetUpPiecesAfterPassageChangePhrasing from '../../../hooks/useSetUpPiecesAfterPassageChangePhrasing'
// import { getModuleTextFontSize } from '../../../utils/misc'
// import usePhrasingSettings from '../../../hooks/usePhrasingSettings'
import useLegacySettings from '../../../hooks/useLegacySettings'
import useEffectiveVisibility from '../../../hooks/useEffectiveVisibility'
import useLegacyPhrasingShortcuts from '../../../hooks/useLegacyPhrasingShortcuts'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useFocusContainerSetRef from '../../../hooks/useFocusContainerSetRef'

import LegacyIFrame from '../shared/LegacyIFrame'
import DotNotesButton from '../shared/DotNotesButton'
import ModuleContainer from '../shared/ModuleContainer'
import NewModuleHeadingAndPassageChooser from '../shared/NewModuleHeadingAndPassageChooser'
import NewToPhrasing from './NewToPhrasing'
import LegacyPhrasingSettings from './LegacyPhrasingSettings'
import LegacyPhrasingOptionsColumns from './LegacyPhrasingOptionsColumns'
import PhrasingOptionsChangePassage from './PhrasingOptionsChangePassage'
import ModuleTitleButton from '../shared/ModuleTitleButton'
import EditingButton from '../shared/EditingButton'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'

const noop = ()=>{}

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  ${({ $moduleWidth }) => $moduleWidth > 700 ? `` : `
    flex-direction: column;
    justify-content: flex-start;
  `}
`

const PrintContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;
`

const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgb(255 255 255/.85);
  backdrop-filter: blur(3px);
  z-index: 1;
`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  flex: 1;
  display: flex;
`

const Phrasing = ({
  module,
  projectId,
  moduleByProject,
  onDeleteModuleByProject,
  noneditableViewingMode,
  sharedPrintDialogInfo,
  clearSharedPrintDialogInfo,
  goPrintEncompassingNotesTab,
  goSketch,
  setHeight,
  embedFullScreen,
  onLoad,
  ...otherProps
}) => {

  const { id, modulePassages } = module

  const [ printDialogInfo=sharedPrintDialogInfo, setPrintDialogInfo ] = useState()
  const [ dotNoteButtonClickCounter, { inc }] = useCounter(0)
  const incrementDotNoteButtonClickCounter = useCallback(() => inc(), [ inc ])

  const [ undoClickCounter, { inc: inc2 }] = useCounter(0)
  const incrementUndoClickCounter = useCallback(() => inc2(), [ inc2 ])

  const [ ref, { width: moduleWidth } ] = useMeasure()
  const containerSetRef = useFocusContainerSetRef({ focusElSelector: `[data-module-id="${module.id}"]` })

  const effectiveVisibilityById = useEffectiveVisibility({ modulePassages, moduleWidth: 9999 })  // this is just a placeholder for now

  const closePrint = useCallback(
    () => {
      setPrintDialogInfo()
      clearSharedPrintDialogInfo && clearSharedPrintDialogInfo()
    },
    [ clearSharedPrintDialogInfo ],
  )

  // const setUpPiecesAfterPassageChange = useSetUpPiecesAfterPassageChangePhrasing({
  //   projectId,
  //   moduleId: id,
  //   defaultBlockSize: DEFAULT_NUM_VERSES_PER_BLOCK_FOR_PHRASING,
  // })

  // const { textSizesSetting, ...otherPhrasingSettings } = usePhrasingSettings({ moduleId: id })
  const { legacySetting, moduleSettingsLoading } = useLegacySettings({ moduleId: id })

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)
  const cancelClickOrMoveRef = useRef()

  useLegacyPhrasingShortcuts({
    getModule,
    cancelClickOrMoveRef,  
    goUpdateModule,
    setPrintDialogInfo,
    noneditableViewingMode,
  })

  // const [ displayedTextSizes, setDisplayedTextSizes ] = useState(textSizesSetting.value)

  // useEffectAsync(
  //   () => {
  //     setDisplayedTextSizes(textSizesSetting.value)
  //   },
  //   [ textSizesSetting, setDisplayedTextSizes ],
  // )

  // const adjustmentType = (
  //   (displayedTextSizes !== textSizesSetting.value && `textsizes`)
  // )

  // const settings = {
  //   textFontSize: getModuleTextFontSize(displayedTextSizes.base),
  // }
  // for(let key in otherPhrasingSettings) {
  //   settings[key] = otherPhrasingSettings[key].value
  // }

  return (
    <ModuleContainer
      ref={ref}
      data-container-module-id={module.id}
      noneditableViewingMode={noneditableViewingMode}
      {...otherProps}
    >

      {!noneditableViewingMode && modulePassages.length === 0 &&
        <NewModuleHeadingAndPassageChooser
          heading={i18n("New Phrase", "", "phrasing")}
          module={module}
          moduleByProject={moduleByProject}
          setUpPiecesAfterPassageChange={noop}
          moduleWidth={moduleWidth}
          newOrLearning={<NewToPhrasing />}
          maxChapterSpan={3}
        />
      }

      {modulePassages.length > 0 &&
        <Container
          ref={containerSetRef}
        >

          {!noneditableViewingMode &&
            <>

              <HeaderBackground />

              <ModuleTitleButton
                module={module}
                moduleByProject={moduleByProject}
                onDeleteModuleByProject={onDeleteModuleByProject}
                goPrintOrDownload={setPrintDialogInfo}
                goSketch={goSketch}
                giveDownloadChoices
                goUndo={incrementUndoClickCounter}
                width={otherProps.width}
                topSection={
                  <PhrasingOptionsChangePassage
                    module={module}
                    projectId={projectId}
                    setUpPiecesAfterPassageChange={noop}
                  />
                }
                columnsHeadingText={i18n("Texts")}
                columnsSection={
                  <LegacyPhrasingOptionsColumns
                    modulePassages={modulePassages}
                    projectId={projectId}
                    moduleId={id}
                    setUpPiecesAfterPassageChange={noop}
                    visibilityById={effectiveVisibilityById}
                  />
                }
                settingsSection={
                  <LegacyPhrasingSettings
                    projectId={projectId}
                    legacySetting={legacySetting}
                  />
                }
                // $adjustmentType={adjustmentType}
              />

              {module.inEditingMode &&
                <DotNotesButton
                  incrementDotNoteButtonClickCounter={incrementDotNoteButtonClickCounter}
                />
              }

              <EditingButton
                module={module}
              />

            </>
          }

          {noneditableViewingMode ===  `embed` &&
            <ModuleTitleButton
              module={module}
              width={otherProps.width}
              disabled
            />
          }

          <StyledContainerWithPassagePopper
            onTopOfAll
            noTextSelection
          >
            <LegacyIFrame
              module={module}
              projectId={projectId}
              legacySetting={legacySetting}
              dotNoteButtonClickCounter={dotNoteButtonClickCounter}
              undoClickCounter={undoClickCounter}
              moduleSettingsLoading={moduleSettingsLoading}
              goPrintOrDownload={goPrintEncompassingNotesTab || setPrintDialogInfo}
              noneditableViewingMode={noneditableViewingMode}
              setHeight={setHeight}
              embedFullScreen={embedFullScreen}
              onLoad={onLoad}
            />
          </StyledContainerWithPassagePopper>

          {!!printDialogInfo &&
            <PrintContainer>
              <LegacyIFrame
                module={module}
                projectId={projectId}
                legacySetting={legacySetting}
                moduleSettingsLoading={moduleSettingsLoading}
                doImmediateAction={`${printDialogInfo.action}:${printDialogInfo.type}:${moduleWidth}`}
                onClosePrint={closePrint}
              />
            </PrintContainer>
          }

        </Container>
      }

    </ModuleContainer>
  )
}

export default memo(Phrasing)