import { memo, useCallback, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import AssignmentIcon from '@material-ui/icons/Assignment'
import IconButton from '@material-ui/core/IconButton'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import useVersionInfos from '../../hooks/useVersionInfos'

const Container = styled.div`
  padding: 10px;

  ${({ $isParallel }) => !$isParallel ? `` : `
    padding-top: 0;
  `}
`

const VersionName = styled.div`
  font-size: 13px;
  padding: 5px 0;
  text-align: center;
`

const VersionStatus = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`

const StyledCloudDownloadIcon = styled(CloudDownloadIcon)`
  height: 14px;
  width: 14px;
  margin-right: 6px;
`

const StyledCheckIcon = styled(CheckIcon)`
  height: 14px;  
  width: 14px;
  margin-right: 6px;
`

const StyledChip = styled(Chip)`
  margin: 2px;

  color: ${({ $selected }) => $selected ? `white` : ``};
  background: ${({ $selected }) => $selected ? `black` : `transparent`};
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin-left: 2px;

  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`

const OtherContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledAssignmentIcon = styled(AssignmentIcon)`
  width: 14px;
  height: 14px;
  margin: 0 2px 0 -3px;
`

const ToBePastedNote = styled.div`
  font-size: 10px;
  margin: -4px 0 2px;
  font-weight: 400;
`

const VersionChooser = ({
  selectedVersionId,
  setSelectedVersionId,
  isParallel,
  restrictToTestamentBookId,
  showOther,
}) => {

  const { unhiddenSelectedVersionInfos } = useVersionInfos({ restrictToTestamentBookId })

  const versionInfosToUse = useMemo(
    () => ([
      ...unhiddenSelectedVersionInfos,
      ...(!showOther ? [] : [{
        id: `paste-in`,
        version: {
          abbr: (
            <OtherContainer>
              <StyledAssignmentIcon />
              {i18n("Other")}
            </OtherContainer>
          ),
          name: i18n("Alternative Text"),
        },
      }]),
    ]),
    [ unhiddenSelectedVersionInfos, showOther ],
  )

  const selectVersion = useCallback(
    ({ currentTarget }) => {
      setSelectedVersionId(currentTarget.getAttribute(`data-id`))
    },
    [ setSelectedVersionId ],
  )

  return (
    <Container $isParallel={isParallel}>

      {versionInfosToUse.map(({ id, version, setupStatus }) => {
        const { abbr, name } = version || {}

        return (
          <Tooltip
            key={id}
            title={
              name
                ? <>

                  <VersionName>
                    {name}
                  </VersionName>

                  <VersionStatus>

                    {setupStatus === "downloading" && <StyledCloudDownloadIcon />}
                    {setupStatus === "offline-ready" && <StyledCheckIcon />}

                    {setupStatus === "downloading" && i18n("Downloading to make available offline...")}
                    {setupStatus === "offline-ready" && i18n("Available offline")}

                  </VersionStatus>

                  {id === "paste-in" &&
                    <ToBePastedNote>
                      {i18n("To be pasted in")}
                    </ToBePastedNote>
                  }
                </>
                : ``
            }
          >
            <StyledChip
              data-id={id}
              label={abbr || id.toUpperCase()}
              onClick={id === selectedVersionId ? null : selectVersion}
              $selected={id === selectedVersionId}
              tabIndex={-1}
            />
          </Tooltip>
        )

      })}

      {!isParallel &&
        <NavLinkOrAWithDisable exact to="/settings/versions">
          <Tooltip
            title={i18n("Add, remove, and reorder")}
          >
            <StyledIconButton
            >
              <SettingsIcon />
            </StyledIconButton>
          </Tooltip>
        </NavLinkOrAWithDisable>
      }

    </Container>
  )
}

export default memo(VersionChooser)