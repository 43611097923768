import { createTheme } from '@material-ui/core/styles'

import { IS_EMBED } from './constants'

const palette = {
  primary: {  // also DISCOURSE
    // ultraLight: '#fefcf6',
    faded: '#e4dfc1',
    veryLight: '#fff3bc',
    light: '#f1de88',
    main: '#bdac59',
    dark: '#8b7d2c',
    contrastText: '#FFF',
  },
  secondary: {  // also PHRASING
    // ultraLight: '#f6f9fb',
    light: '#8bb1cb',
    main: '#5c829a',
    dark: '#2e566c',
    contrastText: '#FFF',
  },
  tertiary: {  // also NOTES
    // ultraLight: '#fbefee',
    light: '#d3847e',
    main: '#C95047',
    dark: '#9f362e',
    contrastText: '#FFF',
  },
  fourthly: {  // also DIAGRAMMING
    // ultraLight: '#fbefee',
    light: '#b1e396',
    main: '#95bb80',
    dark: '#52783d',
    contrastText: '#FFF',
  },
  fifthly: {  // also WORD-STUDY
    // ultraLight: '#fbefee',
    light: '#f2b37c',
    main: '#d68945',
    dark: '#a05310',
    contrastText: '#FFF',
  },
  sixthly: {  // also OUTLINE
    // ultraLight: '#fbefee',
    light: '#d5d5d5',
    main: '#a7a7a7',
    dark: '#686767',
    contrastText: '#FFF',
  },
  seventhly: {  // also MARKUP (TODO)
    // ultraLight: '#fbefee',
    light: '#d5d5d5',
    main: '#a7a7a7',
    dark: '#686767',
    contrastText: '#FFF',
  },
  markupColors: {
    RED: {
      arrow: '#ec6b63',
      line: '#dd0000b5',
      highlight: '#dd000066',
    },
    BLUE: {
      arrow: '#a1afff',
      line: '#0000d699',
      highlight: '#0000d628',
    },
    YELLOW: {
      arrow: '#cccc03',
      line: '#cccc03ff',
      highlight: '#cccc0377',
    },
    ORANGE: {
      arrow: '#eca43b',
      line: '#e89600dd',
      highlight: '#e8960044',
    },
    GREEN: {
      arrow: '#5db939',
      line: '#3dae00dd',
      highlight: '#3dae0044',
    },
    PURPLE: {
      arrow: '#d09ccc',
      line: '#800080db',
      highlight: '#8000802f',
    },
    PINK: {
      arrow: '#ffa3b3',
      line: '#ff869cc2',
      highlight: '#ff869c44',
    },
    "LIGHT-BLUE": {
      arrow: '#46b9bb',
      line: '#00adafd9',
      highlight: '#00adaf33',
    },
    GREY: {
      arrow: '#acacac',
      line: '#222222d9',
      highlight: '#22222233',
    },
    WHITE: {  // this one simply for the page selector
      arrow: '#ccc',
      line: '#FFFFFF',
      highlight: '#FFFFFF88',
    },
    SYSTEM: {  // not available for users to choose
      highlight: '#bdac596b',
    },
  },
}

export const fontFamily = `"CustomFontOverride", "Roboto", "Helvetica", "Arial", sans-serif`

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          overflow: 'hidden',
          ...(!IS_EMBED ? {} : {
            backgroundColor:  'transparent',
          }),
          overscrollBehaviorY: 'none',
        },
        a: {
          color: palette.secondary.dark,
          '&:hover': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 'auto',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '34px',
        marginTop: '-1px',
      },
    },
  },
  typography: {
    fontFamily,
  },
  palette,
})

export default theme