import { memo, useRef, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'

import useAppSize from '../../hooks/useAppSize'
import useEffectAsync from '../../hooks/useEffectAsync'

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`

  z-index: 1300;

  .MuiTooltip-tooltip {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    max-width: 600px;
    height: ${({ $height }) => $height}px;
    padding: 0;
    background: white;
    color: black;
    border-radius: 0;
  }

`

const HeightTestContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  max-width: 600px;
  visibility: hidden;
`

const StudyBibleItemTooltip = ({
  open,
  title,
  resizeKey,
  ...otherProps
}) => {

  const { height: windowHeight } = useAppSize()
  const [ tooltipHeight, setTooltipHeight ] = useState()
  const [ tooltipOpen, setTooltipOpen ] = useState(false)
  const ref = useRef()

  const checkHeight = !!(!tooltipOpen && open)

  useEffectAsync(
    () => {
      if(checkHeight) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if(ref.current) {
              setTooltipHeight(ref.current.getBoundingClientRect().height)
            }
            setTooltipOpen(true)
          })
        })
      }
    },
    [ checkHeight ],
  )

  useEffectAsync(
    () => {
      if(!open) {
        setTooltipOpen(false)
      }
    },
    [ open ],
  )

  useEffectAsync(
    () => {
      setTooltipOpen(false)  // i.e. size the height afresh
    },
    [ windowHeight, resizeKey ],
  )

  return (
    <>

      <StyledTooltip
        {...otherProps}
        title={title}
        open={tooltipOpen}
        $height={tooltipHeight}
        interactive
        placement="right"
      />

      {checkHeight &&
        <HeightTestContainer
          className="MuiTooltip-tooltip"
          ref={ref}
        >
          {title}
        </HeightTestContainer>
      }

    </>
  )
}

export default memo(StudyBibleItemTooltip)