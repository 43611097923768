import { memo, useState, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from '../../hooks/useMutationContext'

import FadedLoading from './FadedLoading'
import MarkdownContainer from './MarkdownContainer'

import dismissAlertMutation from '../../graphql/mutations/dismissAlert'
import RelativeTime from './RelativeTime'

const Container = styled.div`
  padding: 15px;
  border: 1px solid;
  border-color: ${({ theme, $importance }) => ({
    ALERT: theme.palette.divider,
    EMPHASIZED: theme.palette.secondary.main,
    URGENT: theme.palette.tertiary.dark,
  }[$importance])};
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
`

const Heading = styled.div`
  display: flex;
  align-items: flex-start;
`

const CloseButton = styled(IconButton)`
  padding: 8px;
  margin: -10px -10px -10px 2px;

  .MuiSvgIcon-root {
    font-size: 14px;
  }

`

const Title = styled(MarkdownContainer)`
  flex: 1;
  color: ${({ theme, $importance }) => ({
    ALERT: theme.palette.grey[500],
    EMPHASIZED: theme.palette.secondary.main,
    URGENT: theme.palette.tertiary.dark,
  }[$importance])};
  margin: -1px 0 10px;
  font-size: 12px;
`

const Content = styled(MarkdownContainer)`
  font-size: 14px;
`

const Created = styled.div`
  text-align: right;
  font-weight: 300;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: 10px 0 -5px;
`

const StyledFadedLoading = styled(FadedLoading)`
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
`

const AlertsItem = ({
  id,
  title,
  content,
  importance,
  userId,
  createdAt,
  deletedAt,
  expiresAt,
  onClosePopover,
  __typename,
  ...otherProps
}) => {

  const [ loading, setLoading ] = useState(false)
  const [ dismissAlert ] = useMutation(dismissAlertMutation)
  const context = useMutationContext()
  const client = useApolloClient()

  const onClose = useCallback(
    async () => {
      if(!onClosePopover) return

      setLoading(true)

      await dismissAlert({
        variables: {
          id,
        },
        context,
      })

      await client.refetchQueries({
        include: [ 'alertsItems' ],
      })

      setLoading(false)

    },
    [ onClosePopover, dismissAlert, id, context, client ],
  )

  const onClickLink = useCallback(
    async () => {
      if(!onClosePopover) return
      onClosePopover()
      await onClose()
    },
    [ onClosePopover, onClose ],
  )

  return (
    <Container
      {...otherProps}
      $importance={importance}
    >

      <Heading>

        <Title
          $importance={importance}
        >
          {title}
        </Title>

        {!!onClosePopover &&
          <CloseButton
            onClick={onClose}
          >
            <CloseIcon />
          </CloseButton>
        }
      </Heading>

      <Content
        onClickLink={onClickLink}
      >
        {content}
      </Content>

      <Created>
        <RelativeTime date={createdAt} />
      </Created>

      {loading && <StyledFadedLoading size={20} />}

    </Container>
  )
}

export default memo(AlertsItem)