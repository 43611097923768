import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { useLocation, useHistory } from "react-router-dom"
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

import { isIOS } from '../../../utils/misc'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useDataQuery from '../../../hooks/useDataQuery'

import FadedLoading from '../../common/FadedLoading'
import LinkButton from '../../common/LinkButton'

import channelQuery from '../../../graphql/queries/channel'

const APP_ICON_SIZE = 80

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 30px;
`

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
  padding-bottom: 30vh;
  text-align: center;
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px;
`

const AndroidPreview = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
`

const MiniAndroidPreview = styled(AndroidPreview)`
  margin-bottom: 15px;
`

const AndroidPreviewImage = styled.img`
  width: ${APP_ICON_SIZE * 1.1}px;
  height: ${APP_ICON_SIZE * 1.1}px;
  margin: -${APP_ICON_SIZE * .1}px;
`

const AndroidPreviewImage2 = styled.img`
  width: ${APP_ICON_SIZE * .6 * 1.1}px;
  height: ${APP_ICON_SIZE * .6 * 1.1}px;
  margin: -${APP_ICON_SIZE * .6 * .1}px;
`

const IOSPreviewImage = styled.div`
  width: ${APP_ICON_SIZE}px;
  height: ${APP_ICON_SIZE}px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  user-select: none;
`

const PreviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${APP_ICON_SIZE * 1.2}px;
  margin-top: 5px;
  font-size: 13px;
`

const Message = styled.div`
  font-size: 18px;
  margin: 15px 0;
`

const Instructions = styled.div`
  margin: 30px auto;
  text-align: left;
  max-width: 220px;
`

const InstructionsIntro = styled.div`
  font-weight: bold;
`

const InstructionsSteps = styled.ol`
  margin-block-start: .7em;
  margin-block-end: .7em;
  padding-inline-start: 30px;
`

const InstructionsStep = styled.li`
  margin: 10px 0;
`

const Parenthases = styled.span`
  display: inline-block;
`

const SecondaryMessage = styled.div`
  margin: 20px 0;
  font-size: 14px;
`

const TertiaryMessage = styled.div`
  margin: 30px 0;
  font-size: 14px;
  font-weight: 300;
`

const AppName = styled.span`
  display: inline-block;
  text-transform: none;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-style: dotted;
`

const Name = styled.span`
  font-weight: 700;
`

const CancelLinkButton = styled(LinkButton)`
`

const DoneLinkButton = styled(LinkButton)`
  padding: 10px 30px;
`

const OpenLinkButton = styled(LinkButton)`
  padding: 10px 16px;
  font-size: 18px;
  .MuiButton-label {
    flex-direction: column;
  }
`

const Line = styled.div`
  text-transform: none;
  font-weight: normal;
`

const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`

const Spacer = styled.div`
  height: 10px;
`

const Previews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTrendingFlatIcon = styled(TrendingFlatIcon)`
  margin: 0 5px 15px 0;
`

const IOSShareIcon = styled.div`
  display: inline-block;
  width: 23px;
  height: 23px;
  margin: -8px -2px -5px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  user-select: none;
`

const Install = ({
  installOutcome,
}) => {

  const location = useLocation()
  const id = location.pathname.split('/')[2]
  const history = useHistory()

  const { channel, loading } = useDataQuery({
    channelQuery,
    variables: {
      id,
    },
    sticky: true,
  })
  const { updatedAt, shortName } = channel || {}

  const navigateToHomeAfterAMoment = useCallback(
    () => {
      setTimeout(() => history.replace(`/`), 1000)
    },
    [ history ],
  )

  useEffectAsync(
    () => {
      if(installOutcome === `dismissed`) {
        document.location = `/church/${id}`
      }
    },
    [ installOutcome, id ],
  )

  const androidAppIcon = (
    <Preview>
      <AndroidPreview>
        <AndroidPreviewImage
          src={`${process.env.REACT_APP_ASSETS_URI}${id}-app_icon_maskable_512-${updatedAt}.png`}
          className="dark-mode-exempt"
        />
      </AndroidPreview>
      <PreviewText>
        {shortName}
      </PreviewText>
    </Preview>
  )

  const loadingComponents = (
    <Container>
      <FadedLoading />
    </Container>
  )

  const cancelButton = (
    <CancelLinkButton
      {...{ [isIOS ? `href` : `to`]: `/church/${id}` }}
      variant="outlined"
      navInSameTab
    >
      {i18n("Cancel")}
    </CancelLinkButton>
  )

  const doneButton = (
    <DoneLinkButton
      {...{ [isIOS ? `href` : `to`]: `/` }}
      variant="contained"
      disableElevation
      color="default"
      navInSameTab
    >
      {i18n("Done")}
    </DoneLinkButton>
  )

  if(loading) return loadingComponents

  if(isIOS) {

    return (
      <Container>
        <Content>

          <Spacer />
          <Spacer />

          <Preview>
            <IOSPreviewImage
              $src={`${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png`}
              className="dark-mode-exempt"
            />
            <PreviewText>
              {shortName}
            </PreviewText>
          </Preview>

          <Instructions>
            <InstructionsIntro>
              {i18n("To install:")}
            </InstructionsIntro>
            <InstructionsSteps>
              <InstructionsStep>
                {i18n("Tap your browser’s share icon")}
                {` `}
                <Parenthases>
                  {i18nReact("({{parenthetical_text}})", {
                    parenthetical_text: <IOSShareIcon $src="/ios_share.svg" />,
                  })}
                </Parenthases>
              </InstructionsStep>
              <InstructionsStep>
                {i18n("Scroll down and select “Add to Home Screen”")}
              </InstructionsStep>
              <InstructionsStep>
                {i18n("Tap “Add”")}
              </InstructionsStep>
            </InstructionsSteps>
          </Instructions>

          <SecondaryMessage>
            {i18n("Once installed, find it in your app library.")}
          </SecondaryMessage>

          <Buttons>
            {doneButton}
            {cancelButton}
          </Buttons>

          <TertiaryMessage>
            {i18n("Note: If your browser does not give you a “Add to Home Screen” option, you will need to open this page in Safari and install from there.")}
          </TertiaryMessage>

        </Content>
      </Container>
    )

  } else if(installOutcome === `accepted`) {

    return (
      <Container>
        <Content>

          <Message>
            {i18n("Installing...")}
          </Message>
          {androidAppIcon}

          <SecondaryMessage>
            {i18n("You should receive a notification when it is installed, which you can tap to open the app.")}
          </SecondaryMessage>

          <Buttons>
            {doneButton}
          </Buttons>

        </Content>
      </Container>
    )

  } else if(installOutcome === `bsb-already-installed`) {

    return (
      <Container>
        <Content>

          <Message>
            {i18n("No need to install as the Biblearc app allows you to easily flip between Study Bibles in the main menu.")}
          </Message>

          <Previews>
            <Preview>
              <AndroidPreview>
                <AndroidPreviewImage
                  src="/app_icon_maskable_512.png"
                  className="dark-mode-exempt"
                />
              </AndroidPreview>
              <PreviewText>
                {i18n("Biblearc")}
              </PreviewText>
            </Preview>
            <StyledTrendingFlatIcon />
            <MiniAndroidPreview>
              <AndroidPreviewImage2
                src={`${process.env.REACT_APP_ASSETS_URI}${id}-app_icon_maskable_512-${updatedAt}.png`}
                className="dark-mode-exempt"
              />
            </MiniAndroidPreview>
          </Previews>

          <Buttons>

            <OpenLinkButton
              variant="contained"
              disableElevation
              color="secondary"
              href={`/church/${id}`}
              onClick={navigateToHomeAfterAMoment}
            >
              <Line>
                {i18nReact("Open {{app_name}}", {
                  app_name: (
                    <AppName>
                      {i18nReact("The {{short_name}} Study Bible", {
                        short_name: (
                          <Name>
                            {shortName}
                          </Name>
                        ),
                      })}
                    </AppName>
                  ),
                })}
              </Line>
              <Line>
                {i18n("within the Biblearc app")}
              </Line>
            </OpenLinkButton>

            {cancelButton}

          </Buttons>

        </Content>
      </Container>
    )

  } else if(installOutcome === `already-installed`) {

    return (
      <Container>
        <Content>

          <Message>
            {i18n("This app is already installed on your home screen.")}
          </Message>

          {androidAppIcon}

          <Buttons>

            <OpenLinkButton
              variant="contained"
              disableElevation
              color="secondary"
              href={`/church/${id}`}
              onClick={navigateToHomeAfterAMoment}
            >
              {i18n("Open the App")}
            </OpenLinkButton>

            {cancelButton}

          </Buttons>

        </Content>
      </Container>
    )

  } else {

    return loadingComponents

  }

}


export default memo(Install)