import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import CustomCheckbox from '../../common/CustomCheckbox'

const Cell = styled.div`
  width: 450px;

  && {
    flex-shrink: 1;
  }
`

const Pages = styled.div`
`

const PagesHeading = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  margin-top: 5px;
`

const Page = styled.div`
  display: flex;
`

const PageText = styled.div`
  word-wrap: break-word;
  display: inline-block;
  margin-right: 15px;
  font-weight: 300;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`

const CheckBoxes = styled.div`
  padding-left: 3px;
  line-height: .8;
`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  display: inline-flex;

  .MuiCheckbox-root {
    padding: 1px;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  .MuiCheckbox-root,
  .MuiFormControlLabel-label {
    color: black;
    font-size: 10px;
    font-weight: 300;
  }
`

const StudyBibleItemInsightInfoAndDetails = ({
  info,
  details=[],
}) => {

  const { imageUrl=``, sourceText=``, sourceUrl=``, sourceThumbnailImgUrl=`` } = info || {}

  return (
    <Cell>

      <CheckBoxes>

        <StyledCustomCheckbox
          checked={!!imageUrl.trim()}
          disabled
          label={i18n("Base Image", "", "admin")}
        />

        <StyledCustomCheckbox
          checked={!!sourceUrl.trim()}
          disabled
          label={i18n("Source URL", "", "admin")}
        />

        <StyledCustomCheckbox
          checked={!!sourceText.trim()}
          disabled
          label={i18n("Source Text", "", "admin")}
        />

        <StyledCustomCheckbox
          checked={!!sourceThumbnailImgUrl.trim()}
          disabled
          label={i18n("Source Thumbnail Image", "", "admin")}
        />

      </CheckBoxes>

      <Pages>

        <PagesHeading>
          {details.length === 0 && i18n("No Pages", "", "admin")}
          {details.length > 0 && i18n("Pages:", "", "admin")}
        </PagesHeading>

        {details.map(({ text=``, info: { svgStrokes=[] } }, idx) => (
          <Page key={idx}>
            <PageText>
              {text.slice(0, 50)}
              {text.length > 50 && `...`}
            </PageText>
            <StyledCustomCheckbox
              checked={svgStrokes.length > 1}
              label={i18n("Sketch", "", "admin")}
            />
          </Page>
        ))}
      </Pages>

    </Cell>
  )
}

export default memo(StudyBibleItemInsightInfoAndDetails)