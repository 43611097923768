import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import useEffectAsync from '../../hooks/useEffectAsync'

import FadedLoading from './FadedLoading'
import CustomCheckbox from './CustomCheckbox'
import LinkButton from './LinkButton'

const ConfirmDialog = ({
  onClose,
  onCancel,
  cancelHref,
  onConfirm,
  title,
  explanation,
  confirmButtonLabel=i18n("Confirm"),
  cancelButtonLabel=i18n("Cancel"),
  doubleConfirm=false,
  color="primary",
  loading,
  disabled,
  ...props
}) => {

  const [ doubleConfirmChecked, setDoubleConfirmChecked ] = useState(false)
  const onDoubleConfirmCheckboxChange = useCallback((event, checked) => setDoubleConfirmChecked(checked), [])

  const CancelButton = cancelHref ? LinkButton : Button

  useEffectAsync(
    () => {
      setDoubleConfirmChecked(false)
    },
    [ props.open ],
  )

  return (
    <Dialog
      onClose={onClose}
      {...props}
    >
      <DialogTitle>
        {title || confirmButtonLabel}
      </DialogTitle>
      <DialogContent>
        {typeof explanation === 'string'
          ?
            <DialogContentText>
              {explanation}
            </DialogContentText>
          : explanation
        }
        {!!doubleConfirm && 
          <DialogContentText>
            <CustomCheckbox
              color="secondary"
              checked={doubleConfirmChecked}
              label={i18n("Yes, I am sure.")}
              onChange={onDoubleConfirmCheckboxChange}
            />
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={onCancel || onClose}
          color={color}
          href={cancelHref}
        >
          {cancelButtonLabel}
        </CancelButton>
        <Button
          onClick={onConfirm}
          variant="contained"
          color={color}
          disabled={loading || (doubleConfirm && !doubleConfirmChecked) || disabled}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
      {loading && <FadedLoading />}
    </Dialog>
  )
}

export default memo(ConfirmDialog)