import { memo, useContext, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import LaunchIcon from '@material-ui/icons/Launch'
import { useMutation, useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import useRefState from '../../hooks/useRefState'
import useMutationContext from '../../hooks/useMutationContext'
import { STUDY_BIBLE_CHANNELS } from '../../utils/constants'

import ConfirmDialog from '../common/ConfirmDialog'
import CustomCheckbox from '../common/CustomCheckbox'
import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import FadedLoading from '../common/FadedLoading'
import StudyBibleFollowAddBroadcaster from './StudyBibleFollowAddBroadcaster'
import StudyBibleFollowAddSpeaker from './StudyBibleFollowAddSpeaker'
import LinkButton from '../common/LinkButton'

import createStudyBibleFollowMutation from '../../graphql/mutations/createStudyBibleFollow'
import sermonAudioChannelsQuery from '../../graphql/queries/sermonAudioChannels'

const StyledConfirmDialog = styled(ConfirmDialog)`
  ${({ $hasMyPlan }) => $hasMyPlan ? `` : `
    .MuiDialogActions-root .MuiButton-text {
      display: none;
    }
  `}
`

const Container = styled.div`

`

const Explanation = styled.div`
  margin-top: -8px;
  padding: 7px 12px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px;
`

const ReorderEtc = styled.div`
  margin-top: 10px;
`

const SelectionSection = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`

const Divider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.palette.divider};
`

const SermonAudio = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 500px;
`

const SermonAudioLogo = styled.img`
  height: 18px;
  margin-bottom: 10px;
  align-self: flex-start;
`

const Ministries = styled.div`
  min-width: 0;
  flex: 1;
`

const MinistriesHeading = styled.div`
  font-size: 16.5px;
  font-weight: bold;
  margin-bottom: 5px;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputBase-root {
    padding-right: 5px;
  }
`

const SermonAudioSearchResults = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  margin: 0 -20px;
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: center;
  padding: 30px;
`

const None = styled.div`
  font-style: italic;
  text-align: center;
  padding: 30px;
`

const ResultSection = styled.div`
`

const ResultHeading = styled.div`
  margin: 15px 20px 5px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  .MuiFormControlLabel-root {
    max-width: 100%;
  }
  
  .MuiFormControlLabel-label {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  display: inline-block;
`

const StyledLinkButton = styled(LinkButton)`
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 9px;
  margin: 2px -9px;

  .MuiSvgIcon-root {
    font-size: 12px;
    margin: 0 3px 0 -1px;
  }
`

const StudyBibleFollowAddDialog = ({
  onClose,
  ...otherProps
}) => {

  const { studyBibleFollows=[], hasMyPlan } = useContext(LoggedInUserContext) || {}

  const [ search, setSearch, getSearch ] = useRefState(``)
  const [ searching, setSearching ] = useState(false)
  const [ error, setError ] = useState()
  const [ broadcasters, setBroadcasters ] = useState()
  const [ speakers, setSpeakers ] = useState()
  const [ selectedBroadcastersOrSpeakers, setSelectedBroadcastersOrSpeakers ] = useRefState([])
  const [ selectedMinistries, setSelectedMinistries ] = useState([])
  const [ saving, setSaving ] = useState(false)

  const [ createStudyBibleFollow ] = useMutation(createStudyBibleFollowMutation)
  const client = useApolloClient()
  const context = useMutationContext()
  const history = useHistory()

  const onChangeSearch = useCallback(({ target }) => setSearch(target.value), [ setSearch ])

  const goSearch = useCallback(
    async () => {

      setError()

      let query = getSearch().replace(/  +/g, ` `).trim()

      if(query.length < 3) {
        setError(i18n("Enter at least 3 characters"))
        query = ``
      }

      if(!query) {
        setBroadcasters()
        setSpeakers()
        return
      }

      setSearching(true)

      try {

        const { data: { sermonAudioChannels } } = await client.query({
          query: sermonAudioChannelsQuery,
          variables: {
            query,
          },
          context,
        })

        setBroadcasters(sermonAudioChannels.broadcasters)
        setSpeakers(sermonAudioChannels.speakers)

      } catch(err) {
        console.error(err)
        setBroadcasters()
        setSpeakers()
        setError(`SermonAudio search error`)
        setSearching(false)
      }

      setSearching(false)

    },
    [ getSearch, client, context ],
  )

  const onKeyDownSearch = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goSearch()
      }

    },
    [ goSearch ],
  )

  const onSelect = useCallback(
    info => {
      if(!hasMyPlan) {
        const [ x, source, detail ] = info.channelUrl.match(/\?([a-z]+)ID=(.+)$/) || []  // eslint-disable-line no-unused-vars
        history.push(`/channel/sermonaudio/${source}/${detail}`)
      } else {
        setSelectedBroadcastersOrSpeakers([
          ...selectedBroadcastersOrSpeakers,
          info,
        ])
      }
    },
    [ setSelectedBroadcastersOrSpeakers, selectedBroadcastersOrSpeakers, hasMyPlan, history ],
  )

  const onConfirm = useCallback(
    async () => {

      setSaving(true)

      await Promise.all([ ...selectedBroadcastersOrSpeakers, ...selectedMinistries ].map(async item => { 
        const channelUrl = (
          typeof item === `string`
            ? item
            : item.channelUrl
        ) 
        await createStudyBibleFollow({
          variables: {
            input: {
              channelUrl,
            },
          },
          context,
        })
      }))

      onClose()
      setSaving(false)
      setSelectedBroadcastersOrSpeakers([])
      setSelectedMinistries([])

    },
    [ selectedBroadcastersOrSpeakers, selectedMinistries, createStudyBibleFollow, context, onClose, setSelectedBroadcastersOrSpeakers ],
  )

  const hasSelection = (
    selectedBroadcastersOrSpeakers.length > 0
    || selectedMinistries.length > 0
  )

  return (
    <StyledConfirmDialog
      {...otherProps}
      $hasMyPlan={hasMyPlan}
      title={hasMyPlan ? i18n("Add Channels") : i18n("Find Channels")}
      confirmButtonLabel={hasMyPlan ? i18n("Follow") : i18n("Done")}
      disabled={hasMyPlan && !hasSelection}
      onConfirm={hasMyPlan ? onConfirm : onClose}
      onCancel={onClose}
      explanation={
        <Container>

          <Explanation>
            {hasMyPlan
              ? i18n("Select one or more channels below to view sermons from your church (and popular ministries) right along side your Bible.")
              : i18n("View sermons from your church (and a popular ministry) right along side the Bible.")
            }
          </Explanation>

          {hasMyPlan &&
            <ReorderEtc>
              {i18nReact("Reorder channel priority and unfollow by going to {{settings_channels}}.", {
                settings_channels: (
                  <StyledNavLinkOrAWithDisable to="/settings/channels" >
                    {i18n("Settings > Channels")}
                  </StyledNavLinkOrAWithDisable>
                ),
              })}
            </ReorderEtc>
          }

          <SelectionSection>

            <SermonAudio>

              <SermonAudioLogo src="/sermonaudio.svg" />

              {selectedBroadcastersOrSpeakers.map(({ displayName }, idx) => (
                <StyledCustomCheckbox
                  key={idx}
                  checked
                  onChange={() => {
                    setSelectedBroadcastersOrSpeakers([
                      ...selectedBroadcastersOrSpeakers.slice(0, idx),
                      ...selectedBroadcastersOrSpeakers.slice(idx + 1),
                    ])
                  }}
                  label={displayName}
                />
              ))}

              <StyledTextField
                label={i18n("Search for a church or speaker")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={goSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                variant="outlined"
                value={search}
                onChange={onChangeSearch}
                onKeyDown={onKeyDownSearch}
              />

              <SermonAudioSearchResults>

                {!!error &&
                  <Error>
                    {error}
                  </Error>
                }

                {!!broadcasters && !!speakers && broadcasters.length + speakers.length === 0 &&
                  <None>
                    {i18n("None found.")}
                  </None>
                }

                {(broadcasters || []).length > 0 &&
                  <ResultSection>

                    <ResultHeading>
                      {i18n("Church or Ministry")}
                    </ResultHeading>

                    {broadcasters.map(broadcaster => (
                      selectedBroadcastersOrSpeakers.some(({ broadcasterID }) => broadcasterID === broadcaster.broadcasterID)
                        ? null
                        : (
                          <StudyBibleFollowAddBroadcaster
                            key={broadcaster.broadcasterID}
                            broadcaster={broadcaster}
                            onSelect={onSelect}
                          />
                        )
                    ))}

                  </ResultSection>
                }

                {(speakers || []).length > 0 &&
                  <ResultSection>

                    <ResultHeading>
                      {i18n("Speaker")}
                    </ResultHeading>

                    {speakers.map(speaker => (
                      selectedBroadcastersOrSpeakers.some(({ speakerID }) => speakerID === speaker.speakerID)
                        ? null
                        : (
                          <StudyBibleFollowAddSpeaker
                            key={speaker.speakerID}
                            speaker={speaker}
                            onSelect={onSelect}
                          />
                        )
                    ))}

                  </ResultSection>
                }

                {searching && <FadedLoading size={30} />}

              </SermonAudioSearchResults>

            </SermonAudio>

            <Divider />

            <Ministries>

              <MinistriesHeading>
                {i18n("Ministry Channels")}
              </MinistriesHeading>

              {STUDY_BIBLE_CHANNELS.map(({ label, channelUrl }) => {
                const alreadyFollowing = studyBibleFollows.some(studyBibleFollow => studyBibleFollow.channelUrl === channelUrl)

                if(hasMyPlan) {
                  return (
                    <StyledCustomCheckbox
                      key={label}
                      checked={alreadyFollowing || selectedMinistries.includes(channelUrl)}
                      disabled={alreadyFollowing}
                      onChange={() => {
                        const newSelectedMinistries = selectedMinistries.filter(sMChannelUrl => sMChannelUrl !== channelUrl)
                        if(newSelectedMinistries.length === selectedMinistries.length) {
                          newSelectedMinistries.push(channelUrl)
                        }
                        setSelectedMinistries(newSelectedMinistries)
                      }}
                      label={label}
                    />
                  )
                } else {
                  let to
                  if(/^https:\/\/api\.sermonaudio\.com\/v2\/node\/sermons\?/.test(channelUrl)) {
                    const [ x, source, detail ] = channelUrl.match(/\?([a-z]+)ID=(.+)$/) || []  // eslint-disable-line no-unused-vars
                    to = `/channel/sermonaudio/${source}/${detail}`
                  }
                  return (
                    <StyledLinkButton
                      key={label}
                      size="small"
                      disableElevation
                      to={to}
                      endIcon={<LaunchIcon />}
                    >
                      {label}
                    </StyledLinkButton>
                  )
                }

              })}

            </Ministries>

          </SelectionSection>

        </Container>
      }
      color="secondary"
      loading={saving}
    />
  )
}

export default memo(StudyBibleFollowAddDialog)