import PostAddIcon from '@material-ui/icons/PostAdd'
import BookIcon from '@material-ui/icons/Book'

import StudyModuleInNotes from './StudyModuleInNotes'
import PassageInNotes from './PassageInNotes'
import { IS_EMBED } from '../../../utils/constants'

const customComponentTypes = {
  // specialBlockSectionAdditions: [
  insertSection: [

    {
      type: 'passage',
      component: PassageInNotes,
      showOnBasicToolbarSide: true,
      Icon: BookIcon,
      lang: {
        en: 'Passage',
      },
    },

    ...(IS_EMBED ? [] : [
      {
        type: 'studyModule',
        component: StudyModuleInNotes,
        showOnBasicToolbarSide: true,
        Icon: PostAddIcon,
        lang: {
          en: 'Study Module',
        },
      },
    ]),

  ],
  // insertSection: [
  //   {
  //     type: 'image',
  //   },
  //   {
  //     type: 'imageList',
  //   },
  //   {
  //     type: 'video',
  //   },
  //   {
  //     type: 'audioPlayer',
  //   },
  //   {
  //     type: 'document',
  //   },
  //   {
  //     type: 'pullQuote',
  //   },
  //   {
  //     type: 'table',
  //   },
  //   // {
  //   //   type: 'framedSection',
  //   // },
  // ],
}

export default customComponentTypes