import { useCallback, useMemo } from "react"
import { getPassageInfoArrayFromText, getUsfmBibleBookAbbr } from "@bibletags/bibletags-ui-helper"
import { getLocFromRef } from "@bibletags/bibletags-versification"
import styled from 'styled-components'

import usePassagePopper from "../../../hooks/usePassagePopper"
import useVersionInfos from "../../../hooks/useVersionInfos"

import PassageRefPopperContent from "../../passage/PassageRefPopperContent"
import { preventDefaultEvent, normalizeVersionId } from "../../../utils/misc"

const StyledA = styled.a`
  ${({ href, theme }) => !/^\/version\/[^/]*\/[^/]+/.test(href) ? `` : `

    display: inline-block;
    border: 1px solid ${theme.palette.divider};
    margin: -1px 0;
    font-weight: 500;
    text-decoration: none;
    color: inherit;
    border-radius: 3px;
    padding: 0 4px;
    
    :hover {
      background-color: ${theme.palette.grey[100]};
      color: inherit;
      cursor: pointer;
    }

  `}
`

// const StyledA = styled.a`
//   ${({ href, theme }) => !/^\/version\/[^/]*\/[^/]+/.test(href) ? `` : `

//     color: ${theme.palette.primary.dark};

//     :hover {
//       color: ${theme.palette.primary.main};
//       cursor: pointer;
//     }

//   `}
// `

const ATag = ({
  contentState,
  entityKey,
  decoratedText,
  getTopOffset,
  getWidth,
  children,
}) => {

  const entity = entityKey && contentState.getEntity(entityKey)
  const isAutoDetectedRef = !entity

  const { defaultVersionId } = useVersionInfos()

  let { url, target, openInNewTab, passageInfo } = (entity && entity.getData()) || {}

  passageInfo = useMemo(
    () => {
      if(passageInfo) return passageInfo
      if(!isAutoDetectedRef) return null

      const passageInfoInFormation = (
        getPassageInfoArrayFromText({
          text: decoratedText,
          mustIncludeEntirety: true,
        }) || []
      )[0]

      if(passageInfoInFormation && passageInfoInFormation.versionId) {
        passageInfoInFormation.versionId = normalizeVersionId(passageInfoInFormation.versionId)
      }

      return passageInfoInFormation
    },
    [ passageInfo, isAutoDetectedRef, decoratedText ],
  )

  if(passageInfo) {
    const { versionId=defaultVersionId, refSets } = passageInfo
    if(refSets) {
      const refs = refSets[0]
      const { bookId, chapter, verse } = refs[0]
      url = `/version/${versionId}/${getUsfmBibleBookAbbr(bookId)}/${chapter}`
      if(verse !== undefined) {
        const searchParams = new URLSearchParams(``)
        searchParams.set(`range`, `${getLocFromRef(refs[0])}-${getLocFromRef(refs.at(-1))}`)
        url += `?${searchParams.toString()}`
        // if(refSets.length > 1) {
        //   url += `#refSets=${encodeURIComponent(JSON.stringify(refSets))}`
        // }
      }
    }
  }

  const { goSetPopperInfo, passagePopperComponents } = usePassagePopper({
    closePopperOnChangeText: decoratedText,
    verticalSpaceNeeded: 300,
    preferShowAbove: true,
    getTopOffset,
    getWidth,
  })

  const onClick = useCallback(
    event => {
      // if(passageInfo && document.getSelection().isCollapsed) {
      if(passageInfo) {

        event.preventDefault()
        const { versionId, refSets } = passageInfo

        goSetPopperInfo(event, {
          Component: PassageRefPopperContent,
          props: {
            versionId,
            refSets,
            goSetPopperInfo,
          },
        })

      }
    },
    [ passageInfo, goSetPopperInfo ],
  )

  return (
    <>

      <StyledA
        href={url || "about:blank"}
        target={openInNewTab ? "_blank" : target}
        {...(!passageInfo ? {} : {
          // onTouchStart={preventDefaultEvent}
          onMouseDown: preventDefaultEvent,
          onClick,
        })}
      >
        {children}
      </StyledA>

      {passagePopperComponents}

    </>
  )
}

export default ATag