import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PrintIcon from '@material-ui/icons/Print'
import GetAppIcon from '@material-ui/icons/GetApp'

import useThrottleCallback from '../../../hooks/useThrottleCallback'
import { isIOS, getModifierChar } from '../../../utils/misc'

import OptionsPopover from '../../common/OptionsPopover'
import TextAndShortcutCombo from '../../common/TextAndShortcutCombo'

const SharedModuleOptionsPopover = ({
  module,
  goPrintOrDownload,
  onClose,
  ...otherProps
}) => {

  const goPrintOrDownloadAndClose = useThrottleCallback(
    info => {
      goPrintOrDownload(info)
      onClose()
    },
    200,
  )

  const goPrint = useCallback(() => goPrintOrDownloadAndClose({ action: `print` }), [ goPrintOrDownloadAndClose ])
  const goDownload = useCallback(() => goPrintOrDownloadAndClose({ action: `download` }), [ goPrintOrDownloadAndClose ])
  const goDownloadPDF = useCallback(() => goPrintOrDownloadAndClose({ action: `download`, type: `pdf` }), [ goPrintOrDownloadAndClose ])
  const goDownloadImage = useCallback(() => goPrintOrDownloadAndClose({ action: `download`, type: `image` }), [ goPrintOrDownloadAndClose ])

  const hideDownload = [ `DIAGRAMMING` ].includes(module.type) || isIOS
  const giveDownloadChoices = [ `DISCOURSE`, `PHRASING` ].includes(module.type)

  return (
    <OptionsPopover
      {...otherProps}
      onClose={onClose}
      forceBelow
      hideArrow
    >

      <List>

        <MenuItem
          onClick={goPrint}
        >
          <ListItemIcon>
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <TextAndShortcutCombo
            text={i18n("Print")}
            shortcutCombo={`${getModifierChar()}P`}
            subtle
          />
        </MenuItem>

        {!hideDownload && !giveDownloadChoices &&
          <MenuItem
            onClick={goDownload}
            disabled={isIOS}
          >
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <TextAndShortcutCombo
              text={i18n("Download")}
              shortcutCombo={`${getModifierChar()}D`}
              subtle
            />
          </MenuItem>
        }

        {!hideDownload && giveDownloadChoices &&
          <MenuItem
            onClick={goDownloadPDF}
          >
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Download as PDF")}
          </MenuItem>
        }

        {!hideDownload && giveDownloadChoices &&
          <MenuItem
            onClick={goDownloadImage}
          >
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            {i18n("Download as Image")}
          </MenuItem>
        }

      </List>

    </OptionsPopover>
  )
}

export default memo(SharedModuleOptionsPopover)