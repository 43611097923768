import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'

export const TransitionUp = props => <Slide {...props} direction="up" />

const UndoButton = styled(Button)`
  :hover {
    background: rgb(255 255 255/.1);
  }
`

const CloseSnackbarIconButton = styled(IconButton)`
  padding: 5px;

  :hover {
    background: rgb(255 255 255/.1);
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  color: white;
  height: 16px;
  width: 16px;
`

const CustomSnackbar = ({
  onClickClose,
  onClose,
  onClick,
  buttonLabel,
  buttonProps={},
  hideButton,
  ...otherProps
}) => {

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={TransitionUp}
      autoHideDuration={15000}
      onClose={onClose}
      action={
        <>
          {!hideButton &&
            <UndoButton
              color="primary"
              size="small"
              disableElevation
              onClick={onClick}
              {...buttonProps}
            >
              {buttonLabel || i18n("Undo")}
            </UndoButton>
          }
          <CloseSnackbarIconButton
            onClick={onClickClose || onClose}
          >
            <StyledCloseIcon />
          </CloseSnackbarIconButton>
        </>
      }
      {...otherProps}
    />
  )
}

export default memo(CustomSnackbar)