import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.tertiary.dark};
  color: rgb(255 255 255/.6);
  padding: 6px 15px;
  font-size: 13px;
  text-align: center;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  color: white;
  display: inline-block;
  transition: color .15s ease-in-out;

  :hover {
    color: rgb(255 255 255/.4);
  }
`

const ProjectsNoTools = ({ ...props }) => {

  return (
    <Container>

      {i18n("Projects are view-only since you do not have an active Biblearc TOOLS subscription.")}

      {` `}

      <StyledNavLinkOrAWithDisable to="/subscribe">
        {i18n("Get TOOLS")}
      </StyledNavLinkOrAWithDisable>

    </Container>
  )
}

export default memo(ProjectsNoTools)