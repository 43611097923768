import { i18n } from 'inline-i18n'
import { v4 as uuidv4 } from 'uuid'

export const SELECTED_VERSION_LIST_LENGTH_LIMIT = 50  // this is the max limit on the server

export const PRICING = {
  MY: {
    ANNUALLY: 17.88,  // 1.49/month
    MONTHLY: 1.99,
  },
  TOOLS: {
    ANNUALLY: 95.88,  // 7.99/month
    MONTHLY: 9.99,
  },
  EQUIP: {
    ANNUALLY: 299.88,  // 24.99/month
    MONTHLY: 39.99,
  },
}

export const GROUP_PRICING_DISCOUNT = [
  {
    RANGE_START: 2,
    RANGE_END: 9,
    PERCENTAGE_DISCOUNT_ANNUAL: 40,  // based off of monthly amount
    PERCENTAGE_DISCOUNT_3MONTH: 25,  // based off of monthly amount
  },
  {
    RANGE_START: 10,
    RANGE_END: 39,
    PERCENTAGE_DISCOUNT_ANNUAL: 56,  // based off of monthly amount
    PERCENTAGE_DISCOUNT_3MONTH: 40,  // based off of monthly amount
  },
  {
    RANGE_START: 40,
    RANGE_END: 99,
    PERCENTAGE_DISCOUNT_ANNUAL: 65,  // based off of monthly amount
    PERCENTAGE_DISCOUNT_3MONTH: 50,  // based off of monthly amount
  },
  {
    RANGE_START: 100,
    PERCENTAGE_DISCOUNT_ANNUAL: 75,  // based off of monthly amount
    PERCENTAGE_DISCOUNT_3MONTH: 60,  // based off of monthly amount
  },
]

export const WHATEVER_YOU_CAN_AFFORD_PERCENTAGE_DISCOUNTS = [ 25, 50, 75 ]

export const TAB_ID = uuidv4()

export const MAX_NUM_VERSIONS_TO_SEARCH_AT_ONCE = 5

export const MAX_NOTES_DRAWER_WIDTH = 1000

export const HEADER_HEIGHT = 60
export const DRAWER_MINIMIZED_HEIGHT = 48

export const MINIMUM_MEDIUM_WIDTH = 800
export const MINIMUM_MEDIUM_LARGE_WIDTH = 1050
export const MINIMUM_LARGE_WIDTH = 1400

export const MINIMUM_PASSAGE_COLUMN_WIDTH = 300
export const MINIMUM_MODULE_COLUMN_WIDTH = 300
export const MINIMUM_NOTES_COLUMN_WIDTH = 300  // should not be smaller than MINIMUM_PASSAGE_COLUMN_WIDTH
export const HIDDEN_COLUMN_WIDTH = 36
export const THUMBNAILS_WIDTH = 100

export const DEFAULT_NUM_VERSES_PER_BLOCK_FOR_PHRASING = 10  // this cannot be changed!
// export const DEFAULT_NUM_VERSES_PER_BLOCK_FOR_DISCOURSE = 10  // this cannot be changed!

export const DEFAULT_PASSAGE_TEXT_SIZE_MULTIPLIER = 2.7
export const DEFAULT_PASSAGE_LINE_SPACING_SIZE_MULTIPLIER = 2.5
export const DEFAULT_MODULE_COLUMN_TEXT_SIZE_MULTIPLIER = 2.7
export const DEFAULT_PHRASING_COLUMN_TAB_MULTIPLIER = 7

export const MAX_PHRASES_IN_SELECTION = 150

export const MODULE_TYPE_COLOR_MAP = {
  OUTLINE: `sixthly`,
  DIAGRAMMING: `fourthly`,
  "WORD-STUDY": `fifthly`,
  PHRASING: `secondary`,
  DISCOURSE: `primary`,
  NOTES: `tertiary`,
  MARKUP: `seventhly`,
}

export const IGNORE_ERRORS_REGEXES = [
  /Failed to fetch/,  // They have a bad internet connection
  /ResizeObserver loop limit exceeded/,  // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  /ResizeObserver loop completed with undelivered notifications/,  // See https://github.com/juggle/resize-observer/issues/103
  /Can't find variable: [Bb]Arc|[Bb]Arc is not defined/,  // Old native apps cause this
  /Script error./,  // Safari throws this on passage chooser, followed by "ResizeObserver loop completed with undelivered notifications". This is seemingly due to virtuoso having rows with flex-wrap: wrap. I could not find a way to avoid this.
  /not logged in/,  // If they log out of EQUIP and then come back to this tab...
]

export const GET_SETTINGS_FOR_SEARCH = () => {
  
  const settingsForSearch = [
  ]

  settingsForSearch.forEach(option => {
    option.from = "settings"
    option.action = ""
  })

  return settingsForSearch

}

export const GET_HELP_FOR_SEARCH = () => {
  
  const settingsForSearch = [
  ]

  settingsForSearch.forEach(option => {
    option.from = "help"
    option.action = ""
  })

  return settingsForSearch

}

export const TODO_defaultLanguageId = 'eng'

export const PLANS = [ 'MY', 'TOOLS', 'EQUIP' ]

export const POPPER_TEXT_SIZES = { base: 2.6, grc: 2.9, heb: 2.9 }  // similar, but not the same as the default in LocalInfo
export const POPPER_LINE_SPACING_SIZES = { base: 2.3, grc: 2.5, heb: 2.7 }  // similar, but not the same as the default in LocalInfo

export const KJV_VERSION = {
  versificationModel: `kjv`,
  skipsUnlikelyOriginals: true,
}

export const IS_EMBED = /^\/embed\//.test(document.location.pathname)

export const MAX_ORIG_WORDS_FOR_SEARCH_ALL_SUGGESTION = 5

export const GET_STUDY_BIBLE_ITEM_TYPES = () => ({
  INSIGHT: {
    label: i18n("Insight"),
    color: `#2e566c`,
  },
  QUOTE: {
    label: i18n("Quote"),
    color: `#8b7d2c`,
  },
  SERMON: {
    label: i18n("Sermon Clip"),
    color: `#9f362e`,
  },
  LECTURE: {
    label: i18n("Lecture Clip"),
    color: `#9f362e`,
  },
  PODCAST: {
    label: i18n("Podcast Clip"),
    color: `#9f362e`,
  },
  COURSE: {
    label: i18n("Course Clip"),
    color: `#9f362e`,
  },
  CHANNEL: {
    color: `#9f362e`,
    hide: true,
  },
  // IMAGE: {  // We need to see if there are enough passages that would be exegetically helped by an image before using this category (at least 20?)
  //   label: i18n("Image"),
  //   color: `#52783d`,
  // },
  // MAP: {
  //   label: i18n("Map"),
  //   color: `#a05310`,
  // },
  // GENEALOGY: {
  //   label: i18n("Family Tree"),
  //   color: `#a05310`,
  // },
  // TIMELINE: {
  //   label: i18n("Timeline"),
  //   color: `#a05310`,
  // },
  CREED: {
    label: i18n("Creed"),
    color: `#686767`,
  },
  // INTRODUCTION: {
  //   label: i18n("Introduction"),
  //   color: `#686767`,
  // },
})

export const GET_UNHIDDEN_STUDY_BIBLE_ITEM_KEYS = () => Object.keys(GET_STUDY_BIBLE_ITEM_TYPES()).filter(key => !GET_STUDY_BIBLE_ITEM_TYPES()[key].hide)

export const STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE = 100

export const STUDY_BIBLE_CHANNELS = [
  {
    channelUrl: `https://www.desiringgod.org/scripture/labs`,
    label: `Look at the Book (John Piper)`,
    iconLogoSrc: `https://www.desiringgod.org/assets/2/badges/lab_logo_1800x1800-5c8b9d66b440c9c19369655edadba5eae6846f923a3e24ccd82ca45ec573cc0e.png`,
    // darkLogoSrc: `/sermonaudio.svg`,
  },
  {
    channelUrl: `https://www.desiringgod.org/scripture/messages`,
    label: `Desiring God (John Piper+)`,
    iconLogoSrc: `https://www.desiringgod.org/assets/2/social/dg_logo_facebook_fallback-1b7a5ed0b26ad75e6bffa6faeb1be2784b7d189a0b13c4d2c7287b4dda2d71ab.png`,
    // darkLogoSrc: `https://www.desiringgod.org/assets/2/social/dg_logo_facebook_fallback-1b7a5ed0b26ad75e6bffa6faeb1be2784b7d189a0b13c4d2c7287b4dda2d71ab.png`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons`,
    label: `Sermon Audio (most popular)`,
    logoSrc: `/sermonaudio.svg`,
    darkLogoSrc: `/sermonaudio-dark.svg`,
    iconLogoSrc: `/sa.png`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=johnmacarthur`,
    label: `Grace to You (John MacArthur)`,
    // logoSrc: `/sermonaudio.svg`,
    // darkLogoSrc: `/sermonaudio-dark.svg`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=heartcry`,
    label: `HeartCry (Paul Washer)`,
    // logoSrc: `/sermonaudio.svg`,
    // darkLogoSrc: `/sermonaudio-dark.svg`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=ligonier`,
    label: `Ligonier (R. C. Sproul)`,
    // logoSrc: `/sermonaudio.svg`,
    // darkLogoSrc: `/sermonaudio-dark.svg`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=pop`,
    label: `Sermons by C. H. Spurgeon`,
    // logoSrc: `/sermonaudio.svg`,
    // darkLogoSrc: `/sermonaudio-dark.svg`,
  },
  {
    channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=truthforlife`,
    label: `Truth for Life (Alistair Begg)`,
    // logoSrc: `/sermonaudio.svg`,
    // darkLogoSrc: `/sermonaudio-dark.svg`,
  },
  // Capital Hill Baptist
  // 9Marks ("Bible Talk" on apple podcasts)
  // Christ Church (Moscow, ID)
  // The Gospel Coalition
]