import { memo, useMemo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'

import StudyBiblePage from './StudyBiblePage'
import VideoPlayer from '../common/VideoPlayer'

const Container = styled.div`
  width: 100%;
`

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const StudyBibleItemSermonContent = ({
  info,
  fromThumbnail,
}) => {

  const { mediaUrl, mediaStartTime, mediaEndTime, quote=`` } = info || {}

  const detail = useMemo(() => ({ text: quote }), [ quote ])

  return (
    <Container>

      {quote.trim() &&
        <StudyBiblePage
          detail={detail}
          addQuotes
        />
      }

      <VideoContainer>
        <VideoPlayer
          src={mediaUrl}
          startTime={mediaStartTime}
          endTime={mediaEndTime}
          autoplay={!!fromThumbnail}
        />
      </VideoContainer>

    </Container>
  )
}

export default memo(StudyBibleItemSermonContent)