import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BrowserRouter } from "react-router-dom"
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import styled, { ThemeProvider } from 'styled-components'
import { passOverI18n, passOverI18nNumber } from "@bibletags/bibletags-ui-helper"
import { i18nSetup, i18n, i18nNumber } from "inline-i18n"
import { useToggle } from 'react-use'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import theme from './utils/theme'
import './utils/polyfills'
import useAnalytics from './hooks/useAnalytics'
import { IS_EMBED } from './utils/constants'

import ApolloSetup from './components/common/ApolloSetup'
import ErrorBoundary from './components/common/ErrorBoundary'
import ConfirmResetAppDialog from './components/common/ConfirmResetAppDialog'
import GlobalContextProviders from './components/common/GlobalContextProviders'
import Main from './Main'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

// When ready, I will need to create a translation file, setLocale appropriately, test the scripts, etc.
i18nSetup({
  localesUrl: '/translations'
})
passOverI18n(i18n)
passOverI18nNumber(i18nNumber)

// Keep track of whether mouse button is depressed
window.mouseIsDown = false
// let mouseDownCount = 0
// const updateMouseIsDown = () => { window.mouseIsDown = mouseDownCount > 0 }
// window.addEventListener('mousedown', () => updateMouseIsDown(++mouseDownCount), { capture: true })
// window.addEventListener('mouseup', () => updateMouseIsDown(--mouseDownCount), { capture: true })
window.addEventListener('mousedown', () => { window.mouseIsDown = true }, { capture: true })
window.addEventListener('mouseup', () => { window.mouseIsDown = false }, { capture: true })

// Keep track of whether screen is presently being touched
window.screenIsTouched = false
const touchEvent = ({ touches=[] }) => { window.screenIsTouched = touches.length > 0 }
window.addEventListener('touchstart', touchEvent, { capture: true })
window.addEventListener('touchend', touchEvent, { capture: true })
window.addEventListener('touchcancel', touchEvent, { capture: true })

window.getBoundingClientRectAdjustmentFactor = 1

// silence irrelevant errors
console.errorOrig = console.errorOrig || console.error
console.error = (...params) => (
  (
    false
    // Material UI error that cannot be silienced:
    || /^Warning: %s is deprecated in StrictMode/.test(params[0] || ``)
    // html-to-image errors that cannot be silienced:
    || /^Error inlining remote css file/.test(params[0] || ``)
    || /^Error loading remote stylesheet/.test(params[0] || ``)
    || /^Error while reading CSS rules from https:/.test(params[0] || ``)
    // react-use error that occurs when a ref is not yet set (a situation that doesn't hurt anything):
    || /^`useScroll` expects a single ref argument/.test(params[0] || ``)
  )
    ? undefined
    : console.errorOrig(...params)
)

const ForceFontLoad = styled.div`
  position: absolute;
  z-index: 9999;
  top: -10px;
  left: 0;
  font-size: 5px;
  line-height: 1;
  user-select: none;
`

const App = ({
  goUpdate,
  updating,
  goResetServiceWorker,
}) => {

  useAnalytics()

  const [ resetApp, setResetApp ] = useToggle(false)

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>

          <ErrorBoundary
            goUpdate={goUpdate}
            setResetApp={setResetApp}
          >
            <Elements stripe={stripePromise}>
              <ApolloSetup>
                <GlobalContextProviders>
                  <BrowserRouter>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Main
                        goUpdate={goUpdate}
                        updating={updating}
                        setResetApp={setResetApp}
                      />
                    </MuiPickersUtilsProvider>
                  </BrowserRouter>
                </GlobalContextProviders>
              </ApolloSetup>
            </Elements>
          </ErrorBoundary>

          <ConfirmResetAppDialog
            open={resetApp && !IS_EMBED}
            onClose={setResetApp}
            goResetServiceWorker={goResetServiceWorker}
          />

          <ForceFontLoad>
            זζz
            <span id="biblearc-bounding-client-rect-tester">A</span>
          </ForceFontLoad>

        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default App


{  // Run localStorage report

  // TODO: If too much space used, remove largest types?

  const t = Date.now()
  let report = {}
  let total = 0
  
  const getSize = key => (localStorage.getItem(key).length + key.length) * 2

  for(let key in localStorage) {
    if(/^initialValue:useStickyRefState:useDataQuery:/.test(key)) {
      const k = key.split(':').slice(2,4).join(':')
      report[k] = report[k] || 0
      const size = getSize(key)
      report[k] += size
      total += size
    } else if(/^initialValue:useStickyRefState:/.test(key)) {
      const k = key.split(':').slice(1,3).join(':')
      report[k] = report[k] || 0
      const size = getSize(key)
      report[k] += size
      total += size
    } else {
      try {
        report[`OTHER:${key}`] = getSize(key)
        total += report[`OTHER:${key}`]
      } catch(err) {}
    }
  }

  const reportOutput = (
    Object.keys(report)
      .sort((a,b) => report[b] - report[a])
      .map(key => `  ${(report[key]/1024).toFixed(1)}kb \t${key}`)
      .join(`\n`)
  )
  
  console.log(`localStorage REPORT (${Math.ceil((total/(5*1024*1024))*100)}% used – ${Math.ceil(total/1024)} KB out of 5000 KB)`)
  console.log(`${reportOutput}`)
  console.log(`[[ report took ${Date.now() - t}ms ]]`)

}