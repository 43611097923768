import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

import modulePieceQuery from '../graphql/queries/modulePiece'
import updateModulePieceMutation from '../graphql/mutations/updateModulePiece'
import deleteModulePieceMutation from '../graphql/mutations/deleteModulePiece'

export const getDefaultModulePiece = () => ({
  __typename: `ModulePiece`,
  content: null,
  info: null,
  position: null,
})

const useGoUpdateModulePiece = ({
  modulePiece,
  projectId,
  moduleId,
  undoRedoStack,
  onUpdate,
}) => {

  const client = useApolloClient()

  const [ updateModulePiece, updateModulePieceResult ] = useMutation(updateModulePieceMutation)
  const [ deleteModulePiece, deleteModulePieceResult ] = useMutation(deleteModulePieceMutation)

  const goUpdate = useGoUpdateTable({
    currentData: modulePiece || getDefaultModulePiece(),
    updateFunc: updateModulePiece,
    updateResult: updateModulePieceResult,
    deleteFunc: deleteModulePiece,
    deleteResult: deleteModulePieceResult,
    projectId,
    moduleId: moduleId || modulePiece.moduleId,
    undoRedoStack,
    onUpdate,
  })

  const toReturn = useMemo(
    () => {
      if(!modulePiece) {
        return [
          initData => {
            // Create a new modulePiece

            const newData = goUpdate[0]({
              ...initData,
              moduleId,
            })

            client.writeQuery({
              query: modulePieceQuery,
              data: {
                modulePiece: newData,
              },
              variables: {
                id: newData.id,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ modulePiece, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModulePiece