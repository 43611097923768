import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useCounter } from 'react-use'

import styledResizableDiv from '../../utils/styledResizableDiv'
import useFocusContainerSetRef from '../../hooks/useFocusContainerSetRef'
import useEffectAsync from '../../hooks/useEffectAsync'
import useAppSize from '../../hooks/useAppSize'

import PassageContentPager from './PassageContentPager'
import PassageHeader from './PassageHeader'
import Loading from '../common/Loading'
import DivWithFocusIndicator from '../common/DivWithFocusIndicator'

const Container = styledResizableDiv`
  overflow: hidden;
  position: relative;

  ${({ $indicateDropSpotOn, $indicateDropSpotToLeft, $indicateDropSpotToRight, theme }) => !$indicateDropSpotOn ? `` : `

    & > div {
      transition: transform .15s ease-in-out;
    }

    ::before, ::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 15px;
      width: 38px;
      height: 120px;
      border-radius: 5px;
      background: ${theme.palette.grey[200]};
      opacity: 0;
      transition: opacity .15s ease-in-out;
      z-index: 1;
    }

    ::after {
      left: auto;
      right: 5px;  
    }

    ${!$indicateDropSpotToLeft ? `` : `
      & > div {
        transform: translateX(45px);
      }

      ::before {
        opacity: 1;
      }
    `}

    ${!$indicateDropSpotToRight ? `` : `
      & > div {
        transform: translateX(-45px);
      }

      ::after {
        opacity: 1;
      }
    `}

  `}

`

const InnerContainer = styled(DivWithFocusIndicator)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;  
  display: flex;
  flex-direction: column;
`

const PassageColumn = ({
  initialScrollYFraction,
  width,
  updatePassageBookmark,
  closeColumn,
  moveColumn,
  dragColumnIdx,
  setDragColumnIdx,
  columnIdx,
  numColumns,
  versionId,
  openPassageColumnChooser,
  noMiniHeader,
  ...otherProps
    // Namely:
      // bookId
      // chapter
      // parallelVersionId
      // showStudyBible
}) => {
  const [ dragInCounter, { inc: incrementDragCounter, dec: decrementDragCounter, reset: resetDragCounter }] = useCounter(0)

  const { miniHeaderSize } = useAppSize()

  const containerSetRef = useFocusContainerSetRef({ focusElSelector: `[data-passage-column-idx="${columnIdx}"]` })

  const goUpdatePassageBookmark = useCallback(
    updates => updatePassageBookmark({ columnIdx, ...updates }),
    [ updatePassageBookmark, columnIdx ],
  )

  const goCloseColumn = useCallback(
    () => closeColumn({ columnIdx }),
    [ closeColumn, columnIdx ],
  )

  const setThisAsDragColumn = useCallback(
    () => setDragColumnIdx(columnIdx),
    [ setDragColumnIdx, columnIdx ],
  )

  const clearDragColumn = useCallback(
    () => {
      setDragColumnIdx(null)
      window.biblearcExecutePostponedFocus()
    },
    [ setDragColumnIdx ],
  )

  const onDrop = useCallback(
    () => moveColumn(columnIdx),
    [ moveColumn, columnIdx ],
  )

  const onDragEnter = useCallback(() => incrementDragCounter(), [ incrementDragCounter ])
  const onDragLeave = useCallback(() => decrementDragCounter(), [ decrementDragCounter ])

  const onDragOver = useCallback(event => event.preventDefault(), [])

  const doDropEvents = ![ columnIdx, null ].includes(dragColumnIdx)

  useEffectAsync(
    () => {
      resetDragCounter()
    },
    [ columnIdx, resetDragCounter ],
  )

  return (
    <Container
      ref={containerSetRef}
      $width={width}
      onDrop={doDropEvents ? onDrop : null}
      onDragEnter={doDropEvents ? onDragEnter : null}
      onDragLeave={doDropEvents ? onDragLeave : null}
      onDragOver={doDropEvents ? onDragOver : null}
      $indicateDropSpotOn={dragColumnIdx != null}
      $indicateDropSpotToLeft={dragInCounter > 0 && dragColumnIdx > columnIdx}
      $indicateDropSpotToRight={dragInCounter > 0 && dragColumnIdx < columnIdx}
    >

      {!versionId && <Loading />}

      {!!versionId &&
        <InnerContainer>

          {(!miniHeaderSize || noMiniHeader) &&
            <PassageHeader
              {...otherProps}
              versionId={versionId}
              updatePassageBookmark={goUpdatePassageBookmark}
              closeColumn={numColumns > 1 ? goCloseColumn : null}
              setThisAsDragColumn={setDragColumnIdx ? setThisAsDragColumn : null}
              clearDragColumn={setDragColumnIdx ? clearDragColumn : null}
              columnIdx={columnIdx}
            />
          }

          <PassageContentPager
            {...otherProps}
            versionId={versionId}
            initialScrollYFraction={initialScrollYFraction}
            updatePassageBookmark={goUpdatePassageBookmark}
            columnIdx={columnIdx}
            width={width}
          />

        </InnerContainer>
      }

      {openPassageColumnChooser}

    </Container>
  )
}

export default memo(PassageColumn)