// See this spreadsheet for data flow information: https://docs.google.com/spreadsheets/d/1HQTbo3YS0IShtKfuxyfFrklCnmju9V432iQmQH2wSzk/edit?usp=sharing

import { getLocalStorage } from '../../utils/misc'

const getQuerySettings = ({
  operationName,
  variables,
  offlineVersions,
  offlineSetupStatus,
  isBackupServerQuery,
  forceSaveServerResultToDexie,
}) => {

  const online = navigator.onLine

  let doDexieQuery = false
  let doBackupServerQuery = false
  let updatedSinceType = null
  let serverRequestUsesBibleTagsEndpoint = false
  let saveServerResultToDexie = false
  let alsoSaveToApolloAsSingularQueries = false

  if(isBackupServerQuery) {
    return {
      doDexieQuery,
      doBackupServerQuery,
      updatedSinceType,
      runThroughServer: true,
      saveServerResultToDexie: !!forceSaveServerResultToDexie,
    }
  }

  switch(operationName) {  // eslint-disable-line default-case

    case 'bookIdsWithUpdates': {
      updatedSinceType = `bookIdsWithUpdates`  // NOTE: cannot call with useDataQuery since it will constantly call it (given that updatedSince is added)
      break
    }

    case 'userUpdate': {
      updatedSinceType = `userUpdate`  // NOTE: cannot call with useDataQuery since it will constantly call it (given that updatedSince is added)
      saveServerResultToDexie = true
      break
    }

    case 'versions':
    case 'version': {
      doDexieQuery = !online
      saveServerResultToDexie = online
        // TODO: make saveServerResultToDexie more precise? 
        // Precise behavior: saveServerResultToDexie === true when selected to be an offline version
      break
    }

    case 'user': {
      if(variables.id == null) {
        const userId = getLocalStorage('user.id')
        if(userId !== "0") {  // userId === "0" when they just logged in
          doDexieQuery = true
          doBackupServerQuery = online
        }
        saveServerResultToDexie = online
      }
      break
    }

    case 'studyBibleItems':
    case 'customStudyBibleItems':
    case 'myBookmarkedChannelItems':
    case 'channelItems': {
        alsoSaveToApolloAsSingularQueries = true
      break
    }

    case 'stripeCurrentSale':
    case 'stripeSubscriptionChangePreview':
    case 'stripeSubscriptionNextInvoice':
    case 'groupSubscriptions':
    case 'stats':
    case 'users':
    case 'learningResourcesItems':
    case 'studyBibleItem':
    case 'customStudyBibleItem':
    case 'studyBibleItemComments':
    case 'studyBibleItemComment':
    case 'studyBibleItemTags':
    case 'channel':
    case 'channels':
    case 'popularChannelUrls':
    case 'sermonAudioChannels':
    case 'streamingServiceChannel':
    case 'mapLayer':
    case 'globalAlertsItems':
    case 'searchShared':
    case 'moduleHistoryItems':
    case 'moduleByProjectHistoryItems': {
      break
    }

    case 'projectPassageHistoryItems': {
      doDexieQuery = !online
      break
    }

    case 'accountSettings': {
      updatedSinceType = `accountSettings`  // NOTE: cannot call with useDataQuery since it will constantly call it (given that updatedSince is added)
      saveServerResultToDexie = online
      break
    }

    case 'accountSetting': {
      doDexieQuery = true
      // doBackupServerQuery = online
      // saveServerResultToDexie = online
      break
    }

    case 'chapter':
    case 'verse': {
      const offlineReadyVersionIds = offlineVersions.filter(({ setupStatus }) => setupStatus === `offline-ready`).map(({ id }) => id)
      doDexieQuery = offlineReadyVersionIds.includes(variables.versionId)
      serverRequestUsesBibleTagsEndpoint = [ `uhb`, `ugnt`, `lxx` ].includes(variables.versionId)
      break
    }

    case 'definition':
    case 'tagSets':
    case 'tagSet': {
      const definitionsOfflineReady = false  // NOTE: have not yet worked out how to do offline for this yet
      doDexieQuery = definitionsOfflineReady
      serverRequestUsesBibleTagsEndpoint = true
      break
    }

    case 'alertsItems': {
      // At this point, I am requiring a person to be online to see alerts; if this
      // turns out to be insufficient, use the following:
      // doDexieQuery = true
      // doBackupServerQuery = online
      // saveServerResultToDexie = online
      break
    }

    case 'formattingKeys':
    case 'highlights':
    case 'projects':
    case 'project':
    case 'folders':
    case 'folderAncestry':
    case 'tags':
    case 'modules':
    case 'module':
    case 'moduleSetting':
    case 'modulePieces':
    case 'modulePiece':
    case 'moduleDots':
    case 'moduleDot':
    case 'moduleMarkups':
    case 'moduleMarkup':
    case 'autoCompleteSuggestions':
    case 'bibleSearchResults': {
      doDexieQuery = (
        [ 'on-ready', 'on-mutation-sync' ].includes(offlineSetupStatus)
        && !/(\s|^)deleted:(?:"true"|true)(?=\s|$)/.test(variables.query || "")
        && !/(\s|^)userId:/.test(variables.query || "")
      )
      break
    }

  }

  const failDueToNoConnection = !online && !doDexieQuery
  const runThroughServer = !doDexieQuery

  return {
    failDueToNoConnection,
    doDexieQuery,
    doBackupServerQuery,
    updatedSinceType,
    runThroughServer,
    fetchingAt: runThroughServer ? Date.now() : 0,
    serverRequestUsesBibleTagsEndpoint,
    saveServerResultToDexie,
    alsoSaveToApolloAsSingularQueries,
  }
}

export default getQuerySettings