import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import { i18n } from 'inline-i18n'
import { getRefFromLoc, getLocFromRef, getCorrespondingRefs } from '@bibletags/bibletags-versification'

import useVersionInfo from '../../../hooks/useVersionInfo'
import useGoUpdateModulePassage from '../../../hooks/useGoUpdateModulePassage'
import useModalAnchor from '../../../hooks/useModalAnchor'
import { getOrigVersionInfo } from '../../../utils/misc'
import { KJV_VERSION } from '../../../utils/constants'

import PassagePopover from '../../passage/PassagePopover'
import ModuleOptionsSharing from '../shared/ModuleOptionsSharing'

const Container = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  @media (max-width: 319px) {
    min-width: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 15px;
    .MuiButton-label {
      justify-content: start;
      width: 12px;
      white-space: nowrap;
    }
  }
`

const SmallEditIcon = styled(EditIcon)`
  height: 16px;
`

const MarkupOptionsChangePassage = ({
  module,
  projectId,
  setUpPiecesAfterPassageChange,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const { modulePassages } = module
  const firstModulePassage = modulePassages[0]

  const { version=KJV_VERSION } = useVersionInfo(firstModulePassage.info.versionId)

  const [ goUpdateModulePassage ] = useGoUpdateModulePassage({ projectId, moduleId: modulePassages[0].moduleId, isUpdate: true })

  const initialPassageState = useMemo(
    () => {
      const { fromLoc, toLoc, info: { versionId } } = firstModulePassage

      const getConvertedRefs = ({ loc, directionToTryIfSkipped=`next` }) => (
        getCorrespondingRefs({
          baseVersion: {
            ref: getRefFromLoc(loc),
            info: getOrigVersionInfo(),
          },
          lookupVersionInfo: version,
          directionToTryIfSkipped,
        })
      )

      const refs = [ getConvertedRefs({ loc: fromLoc })[0] ]
      if(fromLoc !== toLoc) {
        refs.push(getConvertedRefs({ loc: toLoc, directionToTryIfSkipped: `previous` }).at(-1))
      }

      return {
        versionId,
        bookId: getRefFromLoc(fromLoc).bookId,
        refs,
      }
    },
    [ firstModulePassage, version ]
  )

  const updatePassageBookmark = useCallback(
    ({ versionId, refs }) => {

      const getConvertedLoc = ({ ref, directionToTryIfSkipped=`next`, atIdx=0 }) => (
        getLocFromRef(
          getCorrespondingRefs({
            baseVersion: {
              ref,
              info: version,
            },
            lookupVersionInfo: getOrigVersionInfo(),
            directionToTryIfSkipped,
          }).at(atIdx)
        ).split(':')[0]
      )

      const savedAt = Date.now()

      const newData = {
        savedAt,
      }

      if(versionId) {
        newData.info = {
          ...modulePassages[0].info,
          versionId,
        }
      }

      if(refs) {
        newData.fromLoc = getConvertedLoc({ ref: refs[0] })
        newData.toLoc = getConvertedLoc({ ref: refs[1] || refs[0], directionToTryIfSkipped: `previous`, atIdx: -1 })
      }

      const updatedModulePassage = goUpdateModulePassage(newData, modulePassages[0])
      setUpPiecesAfterPassageChange({
        oldModulePassage: modulePassages[0],
        updatedModulePassage,
        savedAt,
      })

      closeModal()

    },
    [ version, closeModal, goUpdateModulePassage, modulePassages, setUpPiecesAfterPassageChange ],
  )

  return (
    <Container>

      <StyledButton
        variant="contained"
        size="small"
        startIcon={<SmallEditIcon />}
        disableElevation
        onClick={openModal}
      >
        {i18n("Passage", "", "markup")}
      </StyledButton>

      <ModuleOptionsSharing
        module={module}
      />

      <PassagePopover
        anchorEl={anchorEl}
        onClose={closeModal}
        initialPassageState={initialPassageState}
        updatePassageBookmark={updatePassageBookmark}
        type="verses"
        maxChapterSpan={3}
      />

    </Container>
  )
}

export default memo(MarkupOptionsChangePassage)