import { memo, useCallback } from 'react'
import { i18n, i18nNumber } from "inline-i18n"
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'

const Container = styled.div`
  width: 34px;
  height: 34px;
  line-height: 34px;

  ${({ $isSelected, theme }) => !$isSelected ? `` : `
    background: black;
    color: white;
  `}
`

const Verse = styled.div`
  text-align: center;
  border-radius: 50%;

  ${({ $disabled }) => $disabled ? `` : `
    cursor: pointer;
  `}

  ${({ $disabled }) => !$disabled ? `` : `
    opacity: .4;
  `}

  ${({ $disabled, $isSelected, theme }) => $disabled ? `` : `
    @media (hover: hover) {
      :hover {
        background: ${
          $isSelected
            ? theme.palette.grey[800]
            : theme.palette.grey[100]
        };
      }
    }
  `}
`

const VersesChooserVerse = ({
  chapter,
  verse,
  isSelected,
  selectVerse,
  disabled,
}) => {

  const onClick = useCallback(({ shiftKey }) => selectVerse({ chapter, verse, shiftKey }), [ chapter, verse, selectVerse ])

  return (
    <Container
      $isSelected={isSelected}
    >
      <Tooltip
        title={
          verse === 0
            ? i18n("Psalm title")
            : ""
        }
      >
        <Verse
          onClick={disabled ? null : onClick}
          $disabled={disabled}
          $isSelected={isSelected}
        >
          {i18nNumber({ num: verse || i18n("t", "Single character to represent psalm titles in passage chooser.") })}
        </Verse>
      </Tooltip>
    </Container>
  )
}

export default memo(VersesChooserVerse)