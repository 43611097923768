import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import copy from 'copy-to-clipboard'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useMutationContext from '../../../hooks/useMutationContext'

import RelativeTime from '../../common/RelativeTime'
import AlertsItem from '../../common/AlertsItem'
import FadedLoading from '../../common/FadedLoading'
import ConfirmDialog from '../../common/ConfirmDialog'

import updateGlobalAlertsItemMutation from '../../../graphql/mutations/updateGlobalAlertsItem'
import deleteGlobalAlertsItemMutation from '../../../graphql/mutations/deleteGlobalAlertsItem'

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0;
  position: relative;
  z-index: 1;

  & > * {
    flex-shrink: 0;
  }
`

const AlertsItemContainer = styled.div`
  width: 270px;
  margin-bottom: -10px;
`

const Info = styled.div`
  margin-left: 10px;
  width: 180px;
  line-height: 1.2;
`

const RefCode = styled.div`
`

const RefCodeLabel = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const Expiration = styled.div`
  margin-top: 7px;
  font-size: 12px;
  font-weight: 500;
`

const Expired = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
`

const Deleted = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const ActiveVsDismissed = styled.div`
  margin-top: 7px;
  font-size: 12px;
  font-weight: 300;
`

const Active = styled.div`
`

const Dismissed = styled.div`
`

const Action = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin-left: 3px;
  color: ${({ theme }) => theme.palette.secondary.main};
  text-decoration: underline;
  transition: opacity .25s ease-in-out;
  display: inline-block;

  :hover {
    opacity: .5;
    cursor: pointer;
  }
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  display: none;
`


const GlobalAlertsItemRow = ({
  globalAlertsItem,
  setTextSearch,
}) => {

  const {
    id: referenceCode,
    title,
    content,
    expiresAt,
    deletedAt,
    numUndismissed,
    numDismissed,
  } = globalAlertsItem

  const expired = expiresAt < Date.now()

  const [ updating, setUpdating ] = useState()
  const [ editingExpiresAt, toggleEditingExpiresAt ] = useSimpleToggle()
  const [ confirmDelete, toggleConfirmDelete ] = useSimpleToggle()

  const [ updateGlobalAlertsItem ] = useMutation(updateGlobalAlertsItemMutation)
  const [ deleteGlobalAlertsItem ] = useMutation(deleteGlobalAlertsItemMutation)
  const context = useMutationContext()

  const copyContent = useCallback(
    () => {
      copy(content)
      alert(`Content copied to clipboard.`)
    },
    [ content ],
  )

  const handleExpiresAtChange = useCallback(
    async date => {

      setUpdating(true)

      await updateGlobalAlertsItem({
        variables: {
          id: referenceCode,
          input: {
            expiresAt: date.getTime(),
          },
        },
        context,
      })

      setUpdating(false)

    },
    [ referenceCode, updateGlobalAlertsItem, context ],
  )

  const goDelete = useCallback(
    async () => {

      toggleConfirmDelete()
      setUpdating(true)

      await deleteGlobalAlertsItem({
        variables: {
          id: referenceCode,
        },
        context,
      })

      setUpdating(false)

    },
    [ toggleConfirmDelete, deleteGlobalAlertsItem, context, referenceCode ],
  )

  const editExpirationAction = (
    <>
      {` `}
      <Action
        onClick={toggleEditingExpiresAt}
      >
        {i18n("edit", "", "admin")}
      </Action>
      <StyledKeyboardDatePicker
        open={editingExpiresAt}
        onClose={toggleEditingExpiresAt}
        views={['date']}
        minDate={new Date()}
        maxDate={new Date(Date.now() + 1000*60*60*24*365)}
        value={new Date(expiresAt)}
        onChange={handleExpiresAtChange}      
      />
    </>
  )

  return (
    <Row>

      <AlertsItemContainer>
        <AlertsItem
          {...globalAlertsItem}
        />
      </AlertsItemContainer>

      <Info>

        <RefCode>
          <RefCodeLabel>
            {i18n("Reference Code")}
          </RefCodeLabel>
          {referenceCode.split('\n')[1] || `??`}
          <Action
            onClick={copyContent}
          >
            {i18n("copy")}
          </Action>
          {!deletedAt && !expired &&
            <>
              {` `}
              <Action
                onClick={toggleConfirmDelete}
              >
                {i18n("delete")}
              </Action>
              <ConfirmDialog
                open={confirmDelete}
                onCancel={toggleConfirmDelete}    
                onConfirm={goDelete}
                confirmButtonLabel={i18n("Delete")}
                explanation={i18n("Are you sure you want to delete the alert entitled “{{title}}” for all users?", "", "admin", { title })}
                doubleConfirm
                loading={updating}
                color="secondary"
              />
            </>
          }
        </RefCode>

        <ActiveVsDismissed>
          {!expired && !deletedAt &&
            <Active>
              {i18n("{{percent}}% ({{num}}) active", "", "admin", {
                percent: Math.round((numUndismissed / (numUndismissed + numDismissed)) * 100),
                num: numUndismissed,
              })}
            </Active>
          }
          <Dismissed>
            {!expired && !deletedAt && i18n("{{percent}}% ({{num}}) dismissed", "", "admin", {
              percent: Math.round((numDismissed / (numUndismissed + numDismissed)) * 100),
              num: numDismissed,
            })}
            {expired && !deletedAt && i18n("{{percent}}% ({{num}}) were dismissed", "", "admin", {
              percent: Math.round((numDismissed / (numUndismissed + numDismissed)) * 100),
              num: numDismissed,
            })}
          </Dismissed>
        </ActiveVsDismissed>

        <Expiration>
          {!expired && !deletedAt &&
            <>
              {i18nReact("Expires: {{date}}", "", "admin", {
                date: (
                  <RelativeTime date={expiresAt} />
                ),
              })}
              {editExpirationAction}
            </>
          }
          <Expired>
            {expired && !deletedAt &&
              <>
                {i18nReact("Expired: {{date}}", "", "admin", {
                  date: (
                    <RelativeTime date={expiresAt} />
                  ),
                })}
                {editExpirationAction}
              </>
            }
          </Expired>
          <Deleted>
            {!!deletedAt && i18nReact("Deleted: {{date}}", "", "admin", {
              date: (
                <RelativeTime date={deletedAt} />
              ),
            })}
          </Deleted>
        </Expiration>

      </Info>

      {updating && <FadedLoading />}

    </Row>
  )
}

export default memo(GlobalAlertsItemRow)