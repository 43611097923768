import React, { memo, useCallback, useMemo, useRef } from 'react'
// import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import useChangeIndex from '../../hooks/useChangeIndex'
import useNextPrevChapters from '../../hooks/useNextPrevChapters'
import useThrottleCallback from '../../hooks/useThrottleCallback'
import useInstanceValue from '../../hooks/useInstanceValue'

import PassagePage from './PassagePage'
import LazyLoadPageViewer from '../common/LazyLoadPageViewer'
import { equalObjs } from '../../utils/misc'

const PassageContentPager = ({
  initialScrollYFraction,
  updatePassageBookmark,
  bookId,
  chapter,
  versionId,
  parallelVersionId,
  specialMarkup,
  showStudyBible,
  setSnackbarInfo,
  columnIdx,
  width,
  channelIdOrUrl,
}) => {

  const ref = useRef()
  const getPageId = useInstanceValue(`${bookId} ${chapter}`)

  const pageKey = useMemo(
    () => JSON.stringify({
      bookId,
      chapter,
    }),
    [ bookId, chapter ],
  )

  const { previousChapter, nextChapter } = useNextPrevChapters({
    bookId,
    chapter,
    versionId,
  })

  const previousPageKey = useMemo(() => JSON.stringify(previousChapter), [ previousChapter ])
  const nextPageKey = useMemo(() => JSON.stringify(nextChapter), [ nextChapter ])

  const keyIndex = useChangeIndex(
    {
      previousPageKey,
      nextPageKey,
      pageKey,
      showStudyBible,
    },
    (prev={}, current) => (
      !equalObjs(prev, current)
      && ![ prev.previousPageKey, prev.nextPageKey ].includes(current.pageKey)
    ),
  )

  const setPageKey = useCallback(
    pageKey => {
      updatePassageBookmark({
        ref: JSON.parse(pageKey),
        scrollYFraction: 0,  // this will need to preserve the scrollYFraction for the prev/next page like the arrows do
      })
    },
    [ updatePassageBookmark ],
  )

  const onAnimationComplete = useCallback(
    position => {
      if(position === `exit`) {
        const el = ref.current.querySelector(`[data-pageid="${getPageId()}"] .PassageContent-ScrollContainer`)
        el && el.focus()
      }
    },
    [ getPageId ],
  )

  const updateScrollYFraction = useThrottleCallback(
    scrollYFraction => {
      updatePassageBookmark({
        scrollYFraction,
      })
    },
    1000,
  )

  const getPageByKey = useCallback(
    pageKey => {
      const { bookId, chapter } = JSON.parse(pageKey)
      return (
        <PassagePage
          bookId={bookId}
          chapter={chapter}
          versionId={versionId}
          parallelVersionId={parallelVersionId}
          specialMarkup={specialMarkup}
          showStudyBible={showStudyBible}
          initialScrollYFraction={initialScrollYFraction}
          updateScrollYFraction={updateScrollYFraction}
          updatePassageBookmark={updatePassageBookmark}
          setSnackbarInfo={setSnackbarInfo}
          columnIdx={columnIdx}
          width={width}
          data-pageid={getPageId()}
          channelIdOrUrl={channelIdOrUrl}
        />
      )
    },
    [ versionId, parallelVersionId, showStudyBible, initialScrollYFraction, updateScrollYFraction, updatePassageBookmark, columnIdx, width, specialMarkup, setSnackbarInfo, getPageId, channelIdOrUrl ],
  )

  return (
    <LazyLoadPageViewer
      ref={ref}
      key={keyIndex}
      pageKey={pageKey}
      getPageByKey={getPageByKey}
      setPageKey={setPageKey}
      previousPageKey={previousPageKey}
      nextPageKey={nextPageKey}
      onAnimationComplete={onAnimationComplete}
    />
  )
}

export default memo(PassageContentPager)