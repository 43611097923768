import { memo, useCallback, useContext } from 'react'
import { NavLink } from "react-router-dom"
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import DeleteIcon from '@material-ui/icons/Delete'
import StarIcon from '@material-ui/icons/Star'
// import ShareIcon from '@material-ui/icons/Share'
import { useNetworkState } from 'react-use'

import useGoUpdateProject from "../../../hooks/useGoUpdateProject"
import { LoggedInUserContext } from '../../../context/LoggedInUser'

const Container = styled.div`
  width: 300px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    background-color: white;
    position: absolute;
    z-index: 3;
    top: 59px;
    bottom: 0;
    transition: left .2s ease-in-out;
    left: ${({ $expanded }) => $expanded ? 0 : `-300px`};
  }
`

const SectionNavLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
  }

  &.active .MuiListItem-button {
    /* background-color: ${({ theme }) => theme.palette.primary.faded}; */
    background-color: ${({ theme }) => theme.palette.grey[300]};
    cursor: default;
  }
`

const styleIcon = () => `
  margin-right: 10px;
`

const StyledLibraryBooksIcon = styled(LibraryBooksIcon)(styleIcon)
const StyledStarIcon = styled(StarIcon)(styleIcon)
// const StyledShareIcon = styled(ShareIcon)(styleIcon)
const StyledDeleteIcon = styled(DeleteIcon)(styleIcon)

const FillSpace = styled.div`
  flex: 1;
`

const NewProjectButton = styled(Button)`
  margin: 30px 20px;
`

const ProjectsLeftPanel = ({
  expanded=false,
  close,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  const [ goCreateProject ] = useGoUpdateProject()
  const createProject = useCallback(() => goCreateProject(), [ goCreateProject ])

  const { online } = useNetworkState()

  let trashItem = (
    <ListItem
      button
      onClick={close}
      disabled={!online}
    >
      <StyledDeleteIcon />
      <ListItemText primary={i18n("Trash")} />
    </ListItem>
  )

  if(online) {
    trashItem = (
      <SectionNavLink exact to="/projects/trash">
        {trashItem}
      </SectionNavLink>
    )
  }

  return (
    <Container $expanded={expanded}>

      <List>

        <SectionNavLink exact to="/projects">
          <ListItem
            button
            onClick={close}
          >
            <StyledLibraryBooksIcon />
            <ListItemText primary={i18n("All Projects")} />
          </ListItem>
        </SectionNavLink>

        <SectionNavLink exact to="/projects/starred">
          <ListItem
            button
            onClick={close}
          >
            <StyledStarIcon />
            <ListItemText primary={i18n("Starred")} />
          </ListItem>
        </SectionNavLink>

        {/* <SectionNavLink exact to="/projects/shared-with-me">
          <ListItem
            button
            onClick={close}
          >
            <StyledShareIcon />
            <ListItemText primary={i18n("Shared With Me")} />
          </ListItem>
        </SectionNavLink> */}

        {trashItem}

      </List>

      <Divider />
      <FillSpace />

      {hasToolsPlan &&
        <NewProjectButton
          variant="contained"
          color="secondary"
          onClick={createProject}
        >
          {i18n("Create a new project")}
        </NewProjectButton>
      }

    </Container>
  )
}

export default memo(ProjectsLeftPanel)