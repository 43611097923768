import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { getLocFromRef } from '@bibletags/bibletags-versification'
import { useMeasure } from 'react-use'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'

import { IS_EMBED } from '../../../utils/constants'
import { getEmbedSettings } from '../../../graphql/links/embedLink'
import useVersesPieces from '../../../hooks/useVersesPieces'
import useVersionInfos from '../../../hooks/useVersionInfos'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { preventDefaultEvent } from '../../../utils/misc'
import selectText from './selection-utils/selectText'

import PassagePopover from '../../passage/PassagePopover'
import PassageInNotesContent from './PassageInNotesContent'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'
import FormattingKey from '../shared/FormattingKey'
import FormattingKeyButton from '../shared/FormattingKeyButton'

const PassageChooserContainer = styled.div`
  margin: 5px auto 5px 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
`

const Container = styled(ContainerWithPassagePopper)`
  margin: 5px 0;
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  box-sizing: border-box;

  ${({ $showKeyBelow, theme }) => !$showKeyBelow ? `` : `
    flex-direction: column;
    .passage-in-notes-formatting-key {
      border-top: 1px solid ${theme.palette.divider};
      border-left: none;
      max-width: none;
    }
  `};
`

const StyledFormattingKey = styled(FormattingKey)`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
`

const overridesByPopperType = {
  options: {
    verticalSpaceNeeded: 378 + 10,  // 378 is the max content height (need not worry about the offset and padding in this number). The 10 is "just in case."
    preferShowAbove: true,
    bottomOffset: -500,  // needed to allow it always to be below
  },
}

const PassageInNotes = ({
  topOffset,
  onLoad,
  effectiveMode: mode,
  setPopoverComponent,
  flipEditorUpdateSettings,
  flipEditorDeleteThisBlock,
  flipEditorBlock,
  ...atomicComponentSettings
}) => {

  let {
    refs=[],
    versionId,
    pastedInVerses,
    markup,
    settings,
  } = atomicComponentSettings
  const getAtomicComponentSettings = useInstanceValue(atomicComponentSettings)
  const getFlipEditorUpdateSettings = useInstanceValue(flipEditorUpdateSettings)

  // const user = useContext(LoggedInUserContext)
  // const { hasToolsPlan } = user || {}

  const { bookId } = refs[0] || {}

  settings = useMemo(
    () => ({
      textSize: 15,
      lineSpacing: 1.43,
      showCfs: true,
      showNotes: true,
      showHebrewVowels: true,
      showCantillation: true,
      showGreekAccents: true,
      greekPunctuation: `ENGLISH`,
      formattingKeyView: `FULL`,
      ...(settings || {}),
    }),
    [ settings ]
  )

  const { unhiddenSelectedVersionInfos } = useVersionInfos()
  versionId = versionId || (unhiddenSelectedVersionInfos[0] || {}).id || `kjv`

  const [ ref, { width } ] = useMeasure()
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const initialPassageState = useMemo(
    () => ({
      refs,
      bookId,
      versionId,
    }),
    [ refs, bookId, versionId ],
  )

  const updateAtomicComponentSettings = useCallback(
    passageInfo => {
      getFlipEditorUpdateSettings()({
        ...getAtomicComponentSettings(),
        ...passageInfo,
      })
    },
    [ getFlipEditorUpdateSettings, getAtomicComponentSettings ],
  )

  const updateMarkup = useCallback(markup => updateAtomicComponentSettings({ markup }), [ updateAtomicComponentSettings ])

  const hasPassageAndVersion = !!(refs[0] && versionId)

  const [ pieces ] = useVersesPieces({
    fromLoc: getLocFromRef(refs[0] || {}),
    toLoc: getLocFromRef(refs.at(-1) || {}),
    versionId,
    skip: !hasPassageAndVersion,
    pastedInVerses,
  })

  useEffectAsync(
    () => {
      if(!hasPassageAndVersion) {
        onLoad && onLoad()
      }
    },
    []
  )

  useEffectAsync(
    () => {
      setPopoverComponent({
        blockKey: flipEditorBlock.key,
        popoverComponent: (
          hasPassageAndVersion
            ? null
            : (
              <PassagePopover
                key={flipEditorBlock.key}
                anchorEl={anchorEl}
                onClose={closeModal}
                initialPassageState={initialPassageState}
                updatePassageBookmark={updateAtomicComponentSettings}
                type="verses"
                horizontal="center"
                maxChapterSpan={2}
              />
            )
        ),
      })
    },
    [ hasPassageAndVersion, anchorEl, closeModal, initialPassageState, updateAtomicComponentSettings ],
  )

  if(!hasPassageAndVersion) {
    return (
      <PassageChooserContainer>
        <Button
          onClick={openModal}
          variant="contained"
          disableElevation
        >
          {i18n("Choose a passage")}
        </Button>
      </PassageChooserContainer>
    )
  }

  return (
    <Container
      className="passage-in-notes"
      ref={ref}
      onClick={preventDefaultEvent}  // not sure why I need this; it is possible that stopPropagationEvent would be better since it is onClick
      $showKeyBelow={
        width < 400
        && settings.formattingKeyView === `FULL`
      }
      width={width + 10}
      versionId={versionId}
      bookId={bookId}
      selectText={selectText}
      markup={markup}
      updateMarkup={updateMarkup}
      formattingKeyId={settings.formattingKeyId}
      mode={mode}
      topOffset={(IS_EMBED && [ `passages`, `richtextbox` ].includes(getEmbedSettings().embedType)) ? undefined : topOffset}
      overridesByPopperType={overridesByPopperType}
      // NOTE: the key to having the popper stay in place when scrolling out of view
      // is to have a one overflow:auto within another.
    >

      <PassageInNotesContent
        refs={refs}
        versionId={versionId}
        pieces={pieces}
        settings={settings}
        onLoad={onLoad}
        topOffset={topOffset}
        setPopoverComponent={setPopoverComponent}
        blockKey={flipEditorBlock.key}
        markup={markup}
        width={width}
        mode={mode}
        updateAtomicComponentSettings={updateAtomicComponentSettings}
        flipEditorDeleteThisBlock={flipEditorDeleteThisBlock}
      />

      {settings.formattingKeyView === `FULL` &&
        <StyledFormattingKey
          id={settings.formattingKeyId}
          className="passage-in-notes-formatting-key"
          hideExplanation
        />
      }

      {settings.formattingKeyView === `BUTTON` &&
        <FormattingKeyButton
          id={settings.formattingKeyId}
        />
      }

    </Container>
  )

}

export default memo(PassageInNotes)