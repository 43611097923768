import { memo, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import useModalAnchor from '../../../hooks/useModalAnchor'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'

import InfoDialog from '../../common/InfoDialog'
import LensSettingsLensList from './LensSettingsLensList'
import MenuDivider from '../../common/MenuDivider'
import EditLensDialog from './EditLensDialog'

const StyledInfoDialog = styled(InfoDialog)`
  .MuiDialog-paper {
    width: 450px;
    max-width: calc(100vw - 30px);
    margin: 15px;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`

const CreateMenuItem = styled(MenuItem)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.main};
`

const LensSettingsDialog = ({
  open,
  onDone,
  formattingKeyInfosSetting,
  projectId,
}) => {

  const { formattingKeys } = useFormattingKeys()
  const [ formattingKeyIdInEdit, setFormattingKeyIdInEdit ] = useState()
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const [ goSetFormattingKeyInfos ] = useGoSetModuleSetting({
    moduleSetting: formattingKeyInfosSetting,
    projectId,
  })

  const formattingKeyInfoIds = useMemo(() => formattingKeyInfosSetting.value.map(({ id }) => id), [ formattingKeyInfosSetting.value ])
  const unusedFormattingKeys = formattingKeys.filter(({ id }) => !formattingKeyInfoIds.includes(id))

  const addLens = useCallback(
    ({ target }) => {
      const id = target.closest(`[data-id]`).getAttribute('data-id')
      goSetFormattingKeyInfos({
        value: [
          ...formattingKeyInfosSetting.value,
          {
            id,
            colors: [],
          },
        ],
      })
    },
    [ formattingKeyInfosSetting.value, goSetFormattingKeyInfos ],
  )

  const onCancelEdit = useCallback(() => setFormattingKeyIdInEdit(), [ setFormattingKeyIdInEdit ])

  return (
    <>

      <StyledInfoDialog
        open={open}
        onOkay={onDone}
        okayButtonLabel={i18n("Done")}
        title={i18n("Manage Lenses for This Module", "", "markup")}
        explanation={
          <>

            <LensSettingsLensList
              formattingKeyInfos={formattingKeyInfosSetting.value}
              goSetFormattingKeyInfos={goSetFormattingKeyInfos}
              setFormattingKeyIdInEdit={setFormattingKeyIdInEdit}
            />

            <StyledButton
              color="primary"
              onClick={openModal}
            >
              {i18n("Add Lenses")}
            </StyledButton>
            <Menu
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={closeModal}
            >
              {unusedFormattingKeys.map(({ id, name }) => (
                <MenuItem
                  key={id}
                  data-id={id}
                  onClick={addLens}
                >
                  {name}
                </MenuItem>
              ))}
              {unusedFormattingKeys.length > 0 && <MenuDivider />}
              <CreateMenuItem
                onClick={addLens}
              >
                {i18n("Create a new lens")}
              </CreateMenuItem>
            </Menu>

          </>
        }
      />

      <EditLensDialog
        onCancel={onCancelEdit}
        formattingKeyInfos={formattingKeyInfosSetting.value}
        goSetFormattingKeyInfos={goSetFormattingKeyInfos}
        formattingKeyId={formattingKeyIdInEdit}
      />

    </>
  )
}

export default memo(LensSettingsDialog)