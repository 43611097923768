import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { useApolloClient } from '@apollo/client'

import useRefState from '../../../hooks/useRefState'

import CustomCheckbox from '../../common/CustomCheckbox'
import FadedLoading from '../../common/FadedLoading'
import StudyBibleFollowAddBroadcaster from '../../passage/StudyBibleFollowAddBroadcaster'

import sermonAudioChannelsQuery from '../../../graphql/queries/sermonAudioChannels'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputBase-root {
    padding-right: 5px;
  }
`

const SermonAudioSearchResults = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  margin: 20px -10px;
  min-height: 100px;
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: center;
  padding: 30px;
`

const None = styled.div`
  font-style: italic;
  text-align: center;
  padding: 30px;
`

const ResultSection = styled.div`
`

const LinkLike = styled.span`
  display: inline-block;
  text-decoration: underline;
  color: black;
  opacity: .5;
  transition: opacity .15s ease-in-out;

  @media (hover: hover) {
    :hover {
      opacity: .8;
      cursor: pointer;
    }
  }
`

const StyledCustomCheckbox = styled(CustomCheckbox)`
  .MuiFormControlLabel-root {
    max-width: 100%;
  }
  
  .MuiFormControlLabel-label {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const SermonAudioChannelSearch = ({
  onSelect,
}) => {

  const [ search, setSearch, getSearch ] = useRefState(``)
  const [ searching, setSearching ] = useState(false)
  const [ error, setError ] = useState()
  const [ broadcasters, setBroadcasters ] = useState()
  const [ selectedBroadcastersOrSpeakers, setSelectedBroadcastersOrSpeakers ] = useRefState([])

  const client = useApolloClient()

  const onChangeSearch = useCallback(({ target }) => setSearch(target.value), [ setSearch ])

  const goSearch = useCallback(
    async () => {

      setError()

      let query = getSearch().replace(/  +/g, ` `).trim()

      if(query.length < 3) {
        setError(i18n("Enter at least 3 characters"))
        query = ``
      }

      if(!query) {
        setBroadcasters()
        return
      }

      setSearching(true)
      setBroadcasters()

      try {

        const { data: { sermonAudioChannels } } = await client.query({
          query: sermonAudioChannelsQuery,
          variables: {
            query,
          },
        })

        setBroadcasters(sermonAudioChannels.broadcasters)

      } catch(err) {
        console.error(err)
        setBroadcasters()
        setError(`SermonAudio search error`)
        setSearching(false)
      }

      setSearching(false)

    },
    [ getSearch, client ],
  )

  const onKeyDownSearch = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goSearch()
      }

    },
    [ goSearch ],
  )

  return (
    <Container>

      {selectedBroadcastersOrSpeakers.map(({ displayName }, idx) => (
        <StyledCustomCheckbox
          key={idx}
          checked
          onChange={() => {
            setSelectedBroadcastersOrSpeakers([
              ...selectedBroadcastersOrSpeakers.slice(0, idx),
              ...selectedBroadcastersOrSpeakers.slice(idx + 1),
            ])
          }}
          label={displayName}
        />
      ))}

      <StyledTextField
        label={i18n("Search for a church")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={goSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        fullWidth
        variant="outlined"
        value={search}
        onChange={onChangeSearch}
        onKeyDown={onKeyDownSearch}
      />

      <SermonAudioSearchResults>

        {!!error &&
          <Error>
            {error}
            {` `}
            <LinkLike onClick={goSearch}>
              {i18n("try again")}
            </LinkLike>
          </Error>
        }

        {!!broadcasters && broadcasters.length === 0 &&
          <None>
            {i18n("None found.")}
          </None>
        }

        {(broadcasters || []).length > 0 &&
          <ResultSection>

            {broadcasters.map(broadcaster => (
              selectedBroadcastersOrSpeakers.some(({ broadcasterID }) => broadcasterID === broadcaster.broadcasterID)
                ? null
                : (
                  <StudyBibleFollowAddBroadcaster
                    key={broadcaster.broadcasterID}
                    broadcaster={broadcaster}
                    onSelect={onSelect}
                  />
                )
            ))}

          </ResultSection>
        }

        {searching && <FadedLoading size={30} />}

      </SermonAudioSearchResults>

    </Container>
  )
}

export default memo(SermonAudioChannelSearch)