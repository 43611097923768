import { ApolloLink, Observable } from '@apollo/client'

import { IS_EMBED } from '../../utils/constants'
import { operationsToBlock, operationsToGetFromEmbed, operationsToSaveToEmbed } from './embedLinkOperations'
import embedLinkQueries from './embedLinkQueries'
import embedLinkMutations from './embedLinkMutations'
import { capitalize } from '../../utils/misc'
import { getDefaultProject } from '../../hooks/useGoUpdateProject'
import { getDefaultModule } from '../../hooks/useGoUpdateModule'
import { getDefaultModuleByProject } from '../../hooks/useGoUpdateModuleByProject'
import { getDefaultModulePassage } from '../../hooks/useGoUpdateModulePassage'
import { getDefaultModulePiece } from '../../hooks/useGoUpdateModulePiece'
import { getDefaultModuleDot } from '../../hooks/useGoUpdateModuleDot'
import { getDefaultModuleMarkup } from '../../hooks/useGoUpdateModuleMarkup'
import { getDefaultFormattingKey } from '../../hooks/useGoUpdateFormattingKey'

const getDefaults = {
  getDefaultProject,
  getDefaultModule,
  getDefaultModuleByProject,
  getDefaultModulePassage,
  getDefaultModulePiece,
  getDefaultModuleDot,
  getDefaultModuleMarkup,
  getDefaultModuleSetting: () => ({ __typename: `ModuleSetting` }),
  getDefaultFormattingKey,
}

export const getEmbedId = () => document.location.pathname.split('/')[3]
export const getEmbedMode = () => document.location.pathname.split('/')[2]  // prep, frozen, edit

// TODO: detect a failed save
// TODO: disallow study module in notes

let embedDataFromParent, embedDataBeingSaved, updateEmbedData, embedSettingsFromParent, updateEmbedSettings, updateEmbedOverflowHeight, goToggleEmbedFullScreen

const goUpdateEmbedOverflowHeight = value => {
  updateEmbedOverflowHeight && updateEmbedOverflowHeight(document.body.clientHeight >= 500 ? 0 : value)
}

const onMessage = ({ data }) => {

  try { JSON.parse(data) } catch(e) { return }  // just to avoid an error
  const { id, action, keys, value } = JSON.parse(data)

  if(id !== getEmbedId()) return

  switch(action) {  // eslint-disable-line default-case

    case `setData`: {
      embedDataFromParent = keys

      if(!embedDataBeingSaved) {
        embedDataBeingSaved = embedDataFromParent
        updateEmbedData && updateEmbedData(embedDataBeingSaved)
      }
      break
    }

    case `setSettings`: {
      embedSettingsFromParent = keys
      updateEmbedSettings && updateEmbedSettings(embedSettingsFromParent)
      updateEmbedSettingsEventFuncs.forEach(func => func())
      break
    }

    case `setOverflowHeight`: {
      goUpdateEmbedOverflowHeight(value)
      break
    }

    case `exitFullScreen`: {
      goToggleEmbedFullScreen && goToggleEmbedFullScreen({ force: false })
      break
    }

  }

}

const onResize = () => {
  if(document.body.clientHeight >= 500) {
    updateEmbedOverflowHeight && updateEmbedOverflowHeight(0)
  }
}

window.addEventListener('message', onMessage)
window.addEventListener('resize', onResize)

export const getEmbedData = () => embedDataBeingSaved || {}
export const getEmbedProjectId = () => (Object.values(getEmbedData()).find(({ __typename }) => __typename === `Project`) || {}).id
export const getEmbedSettings = () => embedSettingsFromParent || {}

export const setUpdateEmbedData = newUpdateEmbedDataFunc => {
  updateEmbedData = newUpdateEmbedDataFunc
}

let updateEmbedSettingsEventFuncs = []
export const addUpdateEmbedSettingsEvent = func => updateEmbedSettingsEventFuncs.push(func)
export const removeUpdateEmbedSettingsEvent = func => {
  updateEmbedSettingsEventFuncs = updateEmbedSettingsEventFuncs.filter(f => f !== func)
}

export const setUpdateEmbedSettings = newUpdateEmbedSettingsFunc => {
  updateEmbedSettings = newUpdateEmbedSettingsFunc
}

export const setUpdateEmbedOverflowHeight = newUpdateEmbedOverflowHeight => {
  updateEmbedOverflowHeight = newUpdateEmbedOverflowHeight
}

export const setToggleEmbedFullScreen = toggleEmbedFullScreen => {
  goToggleEmbedFullScreen = toggleEmbedFullScreen
}

export const goSave = ({ key, newValues, value }) => {
  value = (
    newValues
      ? {
        ...(
          getEmbedData()[key]
          || (
            [ `searchInfo` ].includes(key)
              ? {}
              : {
                id: key.replace(/^[^:]+:/, ``),
                ...getDefaults[`getDefault${key.split(':')[0]}`](`embed`),
              }
          )
        ),
        ...newValues,
      }
      : value
  )
  window.parent.postMessage(
    JSON.stringify({
      action: 'save',
      id: getEmbedId(),
      key,
      value,
    }),
    `*`
  )
  if(value === undefined) {
    delete getEmbedData()[key]
  } else {
    getEmbedData()[key] = value
  }
  updateEmbedData && updateEmbedData(getEmbedData())
}

export const setEmbedOnTop = value => {
  if(!IS_EMBED) return
  window.parent.postMessage(
    JSON.stringify({
      action: 'setOnTop',
      id: getEmbedId(),
      value,
    }),
    `*`
  )
}

let embedHeight
export const setEmbedHeight = value => {
  if(!IS_EMBED) return
  embedHeight = value
  window.parent.postMessage(
    JSON.stringify({
      action: 'setHeight',
      id: getEmbedId(),
      value,
    }),
    `*`
  )
}
export const getEmbedHeight = () => embedHeight

let latestEmbedFullScreen = false
export const setEmbedFullScreen = value => {
  window.parent.postMessage(
    JSON.stringify({
      action: 'setFullScreen',
      id: getEmbedId(),
      value,
    }),
    `*`
  )
  latestEmbedFullScreen = value
}
export const getEmbedFullScreen = () => latestEmbedFullScreen

export const embedSettingOnClick = ({ target }) => {
  if(getEmbedMode() !== `prep`) throw new Error(`Invalid embed setup. Mode: ${getEmbedMode()}`)

  const el = target.closest(`[data-key]`)
  const key = el.getAttribute(`data-key`)
  if(!key) return

  let value = el.getAttribute(`data-value`)
  switch(el.getAttribute(`data-type`)) {  // eslint-disable-line default-case
    case `textbox`: {
      value = target.value
      break
    }
    case `int`: {
      value = parseInt(value, 10)
      break
    }
    case `boolean`: {
      value = !!value
      break
    }
  }

  window.parent.postMessage(
    JSON.stringify({
      action: 'saveSetting',
      id: getEmbedId(),
      key,
      value,
    }),
    `*`
  )

  if(key === `embedType` && [ `passages`, `search` ].includes(value)) {
    window.parent.postMessage(
      JSON.stringify({
        action: 'saveSetting',
        id: getEmbedId(),
        key: 'viewOnly',
        value: true,
      }),
      `*`
    )
  }

}

export const shouldGetFromEmbed = operation => {
  if(!IS_EMBED) return false

  const { operationType, operationName } = operation.getContext()

  return (
    operationType === `mutation`
    || operationsToBlock.includes(operationName)
    || operationsToGetFromEmbed.includes(operationName)
    || operationsToSaveToEmbed.includes(operationName)
  )
}

const embedLink = new ApolloLink((operation, forward) => (
  new Observable(async observer => {

    const { variables } = operation
    // let { id } = variables

    const context = operation.getContext()
    const { operationName, operationType, expectedResponse } = context

    let dbResult
    const commonParams = {
      operationName,
      variables,
      embedData: getEmbedData(),
      embedSettings: getEmbedSettings(),
      embedMode: getEmbedMode(),
    }

    if(operationsToGetFromEmbed.includes(operationName)) {

      dbResult = await embedLinkQueries(commonParams)

    } else if(operationsToSaveToEmbed.includes(operationName)) {

      dbResult = await embedLinkMutations({
        ...commonParams,
        goSave,
        expectedResponse,
      })

    } else if(operationType === `mutation`) {
      throw new Error(`Invalid mutation for embed`, operationName)
    } else {
      dbResult = /s$/.test(operationName) ? [] : null
    }

    const response = {
      data: {
        [operationName]: (
          (/s$/.test(operationName) && dbResult)
          || (
            dbResult
            && {
              __typename: capitalize(operationName),
              ...dbResult,
            }
          )
          || null
        ),
      },
    }

    observer.next(response)
    observer.complete()

  })
))

export default embedLink