import { useMemo, useContext } from "react"

import { FormattingKeysContext } from '../context/FormattingKeys'
import systemFormattingKeys from "../utils/systemFormattingKeys"

const useFormattingKeys = ({
  query,
}={}) => {

  const myFormattingKeys = useContext(FormattingKeysContext)

  const formattingKeys = useMemo(
    () => ([
      ...systemFormattingKeys,
      ...myFormattingKeys,
    ]),
    [ myFormattingKeys ],
  )

  const filteredFormattingKeys = useMemo(
    () => {
      if(!query) return formattingKeys
      const queryWords = query.replace(/  +/g, ` `).trim().split(` `)
      return formattingKeys.filter(formattingKey => (
        queryWords.every(word => (
          formattingKey.name.includes(word)
          || (formattingKeys.user || {}).name.includes(word)
        ))
      ))
    },
    [ formattingKeys, query ],
  )

  const formattingKeysById = useMemo(
    () => {
      const formattingKeysById = {}
      formattingKeys.forEach(formattingKey => {
        formattingKeysById[formattingKey.id] = formattingKey
      })
      return formattingKeysById
    },
    [ formattingKeys ],
  )

  return {
    formattingKeys,
    filteredFormattingKeys,
    formattingKeysById,
  }

}

export default useFormattingKeys