// The first five array items for each language from www.iso639-3.sil.org

const iso6393Info = [
  // [
  //   English name
  //   ISO-639-3
  //   ISO-639-2B (optional)
  //   ISO-639-2T (optional)
  //   ISO-639-1 (optional)
  //   Native name (optional; defaults to English name)
  //   definitionPreferencesForVerbs (optional; see language.js for default value above)
  //   standardWordDivider (optional; see language.js for default value above)
  //   phraseDividerRegex (optional; see language.js for default value above)
  //   sentenceDividerRegex (optional; see language.js for default value above)
  // ]
  [
    "Ghotuo",
    "aaa"
  ],
  [
    "Alumu-Tesu",
    "aab"
  ],
  [
    "Ari",
    "aac"
  ],
  [
    "Amal",
    "aad"
  ],
  [
    "Arbëreshë Albanian",
    "aae"
  ],
  [
    "Aranadan",
    "aaf"
  ],
  [
    "Ambrak",
    "aag"
  ],
  [
    "Abu' Arapesh",
    "aah"
  ],
  [
    "Arifama-Miniafia",
    "aai"
  ],
  [
    "Ankave",
    "aak"
  ],
  [
    "Afade",
    "aal"
  ],
  [
    "Anambé",
    "aan"
  ],
  [
    "Algerian Saharan Arabic",
    "aao"
  ],
  [
    "Pará Arára",
    "aap"
  ],
  [
    "Eastern Abnaki",
    "aaq"
  ],
  [
    "Afar",
    "aar",
    "aar",
    "aar",
    "aa",
    "Afar"
  ],
  [
    "Aasáx",
    "aas"
  ],
  [
    "Arvanitika Albanian",
    "aat"
  ],
  [
    "Abau",
    "aau"
  ],
  [
    "Solong",
    "aaw"
  ],
  [
    "Mandobo Atas",
    "aax"
  ],
  [
    "Amarasi",
    "aaz"
  ],
  [
    "Abé",
    "aba"
  ],
  [
    "Bankon",
    "abb"
  ],
  [
    "Ambala Ayta",
    "abc"
  ],
  [
    "Manide",
    "abd"
  ],
  [
    "Western Abnaki",
    "abe"
  ],
  [
    "Abai Sungai",
    "abf"
  ],
  [
    "Abaga",
    "abg"
  ],
  [
    "Tajiki Arabic",
    "abh"
  ],
  [
    "Abidji",
    "abi"
  ],
  [
    "Aka-Bea",
    "abj"
  ],
  [
    "Abkhazian",
    "abk",
    "abk",
    "abk",
    "ab",
    "Аҧсуа"
  ],
  [
    "Lampung Nyo",
    "abl"
  ],
  [
    "Abanyom",
    "abm"
  ],
  [
    "Abua",
    "abn"
  ],
  [
    "Abon",
    "abo"
  ],
  [
    "Abellen Ayta",
    "abp"
  ],
  [
    "Abaza",
    "abq"
  ],
  [
    "Abron",
    "abr"
  ],
  [
    "Ambonese Malay",
    "abs"
  ],
  [
    "Ambulas",
    "abt"
  ],
  [
    "Abure",
    "abu"
  ],
  [
    "Baharna Arabic",
    "abv"
  ],
  [
    "Pal",
    "abw"
  ],
  [
    "Inabaknon",
    "abx"
  ],
  [
    "Aneme Wake",
    "aby"
  ],
  [
    "Abui",
    "abz"
  ],
  [
    "Achagua",
    "aca"
  ],
  [
    "Áncá",
    "acb"
  ],
  [
    "Gikyode",
    "acd"
  ],
  [
    "Achinese",
    "ace",
    "ace",
    "ace"
  ],
  [
    "Saint Lucian Creole French",
    "acf"
  ],
  [
    "Acoli",
    "ach",
    "ach",
    "ach"
  ],
  [
    "Aka-Cari",
    "aci"
  ],
  [
    "Aka-Kora",
    "ack"
  ],
  [
    "Akar-Bale",
    "acl"
  ],
  [
    "Mesopotamian Arabic",
    "acm"
  ],
  [
    "Achang",
    "acn"
  ],
  [
    "Eastern Acipa",
    "acp"
  ],
  [
    "Ta'izzi-Adeni Arabic",
    "acq"
  ],
  [
    "Achi",
    "acr"
  ],
  [
    "Acroá",
    "acs"
  ],
  [
    "Achterhoeks",
    "act"
  ],
  [
    "Achuar-Shiwiar",
    "acu"
  ],
  [
    "Achumawi",
    "acv"
  ],
  [
    "Hijazi Arabic",
    "acw"
  ],
  [
    "Omani Arabic",
    "acx"
  ],
  [
    "Cypriot Arabic",
    "acy"
  ],
  [
    "Acheron",
    "acz"
  ],
  [
    "Adangme",
    "ada",
    "ada",
    "ada"
  ],
  [
    "Atauran",
    "adb"
  ],
  [
    "Lidzonka",
    "add"
  ],
  [
    "Adele",
    "ade"
  ],
  [
    "Dhofari Arabic",
    "adf"
  ],
  [
    "Andegerebinha",
    "adg"
  ],
  [
    "Adhola",
    "adh"
  ],
  [
    "Adi",
    "adi"
  ],
  [
    "Adioukrou",
    "adj"
  ],
  [
    "Galo",
    "adl"
  ],
  [
    "Adang",
    "adn"
  ],
  [
    "Abu",
    "ado"
  ],
  [
    "Adangbe",
    "adq"
  ],
  [
    "Adonara",
    "adr"
  ],
  [
    "Adamorobe Sign Language",
    "ads"
  ],
  [
    "Adnyamathanha",
    "adt"
  ],
  [
    "Aduge",
    "adu"
  ],
  [
    "Amundava",
    "adw"
  ],
  [
    "Amdo Tibetan",
    "adx"
  ],
  [
    "Adyghe",
    "ady",
    "ady",
    "ady"
  ],
  [
    "Adzera",
    "adz"
  ],
  [
    "Areba",
    "aea"
  ],
  [
    "Tunisian Arabic",
    "aeb"
  ],
  [
    "Saidi Arabic",
    "aec"
  ],
  [
    "Argentine Sign Language",
    "aed"
  ],
  [
    "Northeast Pashai",
    "aee"
  ],
  [
    "Haeke",
    "aek"
  ],
  [
    "Ambele",
    "ael"
  ],
  [
    "Arem",
    "aem"
  ],
  [
    "Armenian Sign Language",
    "aen"
  ],
  [
    "Aer",
    "aeq"
  ],
  [
    "Eastern Arrernte",
    "aer"
  ],
  [
    "Alsea",
    "aes"
  ],
  [
    "Akeu",
    "aeu"
  ],
  [
    "Ambakich",
    "aew"
  ],
  [
    "Amele",
    "aey"
  ],
  [
    "Aeka",
    "aez"
  ],
  [
    "Gulf Arabic",
    "afb"
  ],
  [
    "Andai",
    "afd"
  ],
  [
    "Putukwam",
    "afe"
  ],
  [
    "Afghan Sign Language",
    "afg"
  ],
  [
    "Afrihili",
    "afh",
    "afh",
    "afh"
  ],
  [
    "Akrukay",
    "afi"
  ],
  [
    "Nanubae",
    "afk"
  ],
  [
    "Defaka",
    "afn"
  ],
  [
    "Eloyi",
    "afo"
  ],
  [
    "Tapei",
    "afp"
  ],
  [
    "Afrikaans",
    "afr",
    "afr",
    "afr",
    "af",
    "Afrikaans"
  ],
  [
    "Afro-Seminole Creole",
    "afs"
  ],
  [
    "Afitti",
    "aft"
  ],
  [
    "Awutu",
    "afu"
  ],
  [
    "Obokuitai",
    "afz"
  ],
  [
    "Aguano",
    "aga"
  ],
  [
    "Legbo",
    "agb"
  ],
  [
    "Agatu",
    "agc"
  ],
  [
    "Agarabi",
    "agd"
  ],
  [
    "Angal",
    "age"
  ],
  [
    "Arguni",
    "agf"
  ],
  [
    "Angor",
    "agg"
  ],
  [
    "Ngelima",
    "agh"
  ],
  [
    "Agariya",
    "agi"
  ],
  [
    "Argobba",
    "agj"
  ],
  [
    "Isarog Agta",
    "agk"
  ],
  [
    "Fembe",
    "agl"
  ],
  [
    "Angaataha",
    "agm"
  ],
  [
    "Agutaynen",
    "agn"
  ],
  [
    "Tainae",
    "ago"
  ],
  [
    "Aghem",
    "agq"
  ],
  [
    "Aguaruna",
    "agr"
  ],
  [
    "Esimbi",
    "ags"
  ],
  [
    "Central Cagayan Agta",
    "agt"
  ],
  [
    "Aguacateco",
    "agu"
  ],
  [
    "Remontado Dumagat",
    "agv"
  ],
  [
    "Kahua",
    "agw"
  ],
  [
    "Aghul",
    "agx"
  ],
  [
    "Southern Alta",
    "agy"
  ],
  [
    "Mt. Iriga Agta",
    "agz"
  ],
  [
    "Ahanta",
    "aha"
  ],
  [
    "Axamb",
    "ahb"
  ],
  [
    "Qimant",
    "ahg"
  ],
  [
    "Aghu",
    "ahh"
  ],
  [
    "Tiagbamrin Aizi",
    "ahi"
  ],
  [
    "Akha",
    "ahk"
  ],
  [
    "Igo",
    "ahl"
  ],
  [
    "Mobumrin Aizi",
    "ahm"
  ],
  [
    "Àhàn",
    "ahn"
  ],
  [
    "Ahom",
    "aho"
  ],
  [
    "Aproumu Aizi",
    "ahp"
  ],
  [
    "Ahirani",
    "ahr"
  ],
  [
    "Ashe",
    "ahs"
  ],
  [
    "Ahtena",
    "aht"
  ],
  [
    "Arosi",
    "aia"
  ],
  [
    "Ainu (China)",
    "aib"
  ],
  [
    "Ainbai",
    "aic"
  ],
  [
    "Alngith",
    "aid"
  ],
  [
    "Amara",
    "aie"
  ],
  [
    "Agi",
    "aif"
  ],
  [
    "Antigua and Barbuda Creole English",
    "aig"
  ],
  [
    "Ai-Cham",
    "aih"
  ],
  [
    "Assyrian Neo-Aramaic",
    "aii"
  ],
  [
    "Lishanid Noshan",
    "aij"
  ],
  [
    "Ake",
    "aik"
  ],
  [
    "Aimele",
    "ail"
  ],
  [
    "Aimol",
    "aim"
  ],
  [
    "Ainu (Japan)",
    "ain",
    "ain",
    "ain"
  ],
  [
    "Aiton",
    "aio"
  ],
  [
    "Burumakok",
    "aip"
  ],
  [
    "Aimaq",
    "aiq"
  ],
  [
    "Airoran",
    "air"
  ],
  [
    "Arikem",
    "ait"
  ],
  [
    "Aari",
    "aiw"
  ],
  [
    "Aighon",
    "aix"
  ],
  [
    "Ali",
    "aiy"
  ],
  [
    "Aja (South Sudan)",
    "aja"
  ],
  [
    "Aja (Benin)",
    "ajg"
  ],
  [
    "Ajië",
    "aji"
  ],
  [
    "Andajin",
    "ajn"
  ],
  [
    "South Levantine Arabic",
    "ajp"
  ],
  [
    "Algerian Jewish Sign Language",
    "ajs"
  ],
  [
    "Judeo-Moroccan Arabic",
    "aju"
  ],
  [
    "Ajawa",
    "ajw"
  ],
  [
    "Amri Karbi",
    "ajz"
  ],
  [
    "Akan",
    "aka",
    "aka",
    "aka",
    "ak",
    "Akana"
  ],
  [
    "Batak Angkola",
    "akb"
  ],
  [
    "Mpur",
    "akc"
  ],
  [
    "Ukpet-Ehom",
    "akd"
  ],
  [
    "Akawaio",
    "ake"
  ],
  [
    "Akpa",
    "akf"
  ],
  [
    "Anakalangu",
    "akg"
  ],
  [
    "Angal Heneng",
    "akh"
  ],
  [
    "Aiome",
    "aki"
  ],
  [
    "Aka-Jeru",
    "akj"
  ],
  [
    "Akkadian",
    "akk",
    "akk",
    "akk"
  ],
  [
    "Aklanon",
    "akl"
  ],
  [
    "Aka-Bo",
    "akm"
  ],
  [
    "Akurio",
    "ako"
  ],
  [
    "Siwu",
    "akp"
  ],
  [
    "Ak",
    "akq"
  ],
  [
    "Araki",
    "akr"
  ],
  [
    "Akaselem",
    "aks"
  ],
  [
    "Akolet",
    "akt"
  ],
  [
    "Akum",
    "aku"
  ],
  [
    "Akhvakh",
    "akv"
  ],
  [
    "Akwa",
    "akw"
  ],
  [
    "Aka-Kede",
    "akx"
  ],
  [
    "Aka-Kol",
    "aky"
  ],
  [
    "Alabama",
    "akz"
  ],
  [
    "Alago",
    "ala"
  ],
  [
    "Qawasqar",
    "alc"
  ],
  [
    "Alladian",
    "ald"
  ],
  [
    "Aleut",
    "ale",
    "ale",
    "ale"
  ],
  [
    "Alege",
    "alf"
  ],
  [
    "Alawa",
    "alh"
  ],
  [
    "Amaimon",
    "ali"
  ],
  [
    "Alangan",
    "alj"
  ],
  [
    "Alak",
    "alk"
  ],
  [
    "Allar",
    "all"
  ],
  [
    "Amblong",
    "alm"
  ],
  [
    "Gheg Albanian",
    "aln"
  ],
  [
    "Larike-Wakasihu",
    "alo"
  ],
  [
    "Alune",
    "alp"
  ],
  [
    "Algonquin",
    "alq"
  ],
  [
    "Alutor",
    "alr"
  ],
  [
    "Tosk Albanian",
    "als"
  ],
  [
    "Southern Altai",
    "alt",
    "alt",
    "alt"
  ],
  [
    "'Are'are",
    "alu"
  ],
  [
    "Alaba-K’abeena",
    "alw"
  ],
  [
    "Amol",
    "alx"
  ],
  [
    "Alyawarr",
    "aly"
  ],
  [
    "Alur",
    "alz"
  ],
  [
    "Amanayé",
    "ama"
  ],
  [
    "Ambo",
    "amb"
  ],
  [
    "Amahuaca",
    "amc"
  ],
  [
    "Yanesha'",
    "ame"
  ],
  [
    "Hamer-Banna",
    "amf"
  ],
  [
    "Amurdak",
    "amg"
  ],
  [
    "Amharic",
    "amh",
    "amh",
    "amh",
    "am",
    "አማርኛ"
  ],
  [
    "Amis",
    "ami"
  ],
  [
    "Amdang",
    "amj"
  ],
  [
    "Ambai",
    "amk"
  ],
  [
    "War-Jaintia",
    "aml"
  ],
  [
    "Ama (Papua New Guinea)",
    "amm"
  ],
  [
    "Amanab",
    "amn"
  ],
  [
    "Amo",
    "amo"
  ],
  [
    "Alamblak",
    "amp"
  ],
  [
    "Amahai",
    "amq"
  ],
  [
    "Amarakaeri",
    "amr"
  ],
  [
    "Southern Amami-Oshima",
    "ams"
  ],
  [
    "Amto",
    "amt"
  ],
  [
    "Guerrero Amuzgo",
    "amu"
  ],
  [
    "Ambelau",
    "amv"
  ],
  [
    "Western Neo-Aramaic",
    "amw"
  ],
  [
    "Anmatyerre",
    "amx"
  ],
  [
    "Ami",
    "amy"
  ],
  [
    "Atampaya",
    "amz"
  ],
  [
    "Andaqui",
    "ana"
  ],
  [
    "Andoa",
    "anb"
  ],
  [
    "Ngas",
    "anc"
  ],
  [
    "Ansus",
    "and"
  ],
  [
    "Xârâcùù",
    "ane"
  ],
  [
    "Animere",
    "anf"
  ],
  [
    "Old English (ca. 450-1100)",
    "ang",
    "ang",
    "ang"
  ],
  [
    "Nend",
    "anh"
  ],
  [
    "Andi",
    "ani"
  ],
  [
    "Anor",
    "anj"
  ],
  [
    "Goemai",
    "ank"
  ],
  [
    "Anu-Hkongso Chin",
    "anl"
  ],
  [
    "Anal",
    "anm"
  ],
  [
    "Obolo",
    "ann"
  ],
  [
    "Andoque",
    "ano"
  ],
  [
    "Angika",
    "anp",
    "anp",
    "anp"
  ],
  [
    "Jarawa (India)",
    "anq"
  ],
  [
    "Andh",
    "anr"
  ],
  [
    "Anserma",
    "ans"
  ],
  [
    "Antakarinya",
    "ant"
  ],
  [
    "Anuak",
    "anu"
  ],
  [
    "Denya",
    "anv"
  ],
  [
    "Anaang",
    "anw"
  ],
  [
    "Andra-Hus",
    "anx"
  ],
  [
    "Anyin",
    "any"
  ],
  [
    "Anem",
    "anz"
  ],
  [
    "Angolar",
    "aoa"
  ],
  [
    "Abom",
    "aob"
  ],
  [
    "Pemon",
    "aoc"
  ],
  [
    "Andarum",
    "aod"
  ],
  [
    "Angal Enen",
    "aoe"
  ],
  [
    "Bragat",
    "aof"
  ],
  [
    "Angoram",
    "aog"
  ],
  [
    "Anindilyakwa",
    "aoi"
  ],
  [
    "Mufian",
    "aoj"
  ],
  [
    "Arhö",
    "aok"
  ],
  [
    "Alor",
    "aol"
  ],
  [
    "Ömie",
    "aom"
  ],
  [
    "Bumbita Arapesh",
    "aon"
  ],
  [
    "Aore",
    "aor"
  ],
  [
    "Taikat",
    "aos"
  ],
  [
    "Atong (India)",
    "aot"
  ],
  [
    "A'ou",
    "aou"
  ],
  [
    "Atorada",
    "aox"
  ],
  [
    "Uab Meto",
    "aoz"
  ],
  [
    "Sa'a",
    "apb"
  ],
  [
    "North Levantine Arabic",
    "apc"
  ],
  [
    "Sudanese Arabic",
    "apd"
  ],
  [
    "Bukiyip",
    "ape"
  ],
  [
    "Pahanan Agta",
    "apf"
  ],
  [
    "Ampanang",
    "apg"
  ],
  [
    "Athpariya",
    "aph"
  ],
  [
    "Apiaká",
    "api"
  ],
  [
    "Jicarilla Apache",
    "apj"
  ],
  [
    "Kiowa Apache",
    "apk"
  ],
  [
    "Lipan Apache",
    "apl"
  ],
  [
    "Mescalero-Chiricahua Apache",
    "apm"
  ],
  [
    "Apinayé",
    "apn"
  ],
  [
    "Ambul",
    "apo"
  ],
  [
    "Apma",
    "app"
  ],
  [
    "A-Pucikwar",
    "apq"
  ],
  [
    "Arop-Lokep",
    "apr"
  ],
  [
    "Arop-Sissano",
    "aps"
  ],
  [
    "Apatani",
    "apt"
  ],
  [
    "Apurinã",
    "apu"
  ],
  [
    "Alapmunte",
    "apv"
  ],
  [
    "Western Apache",
    "apw"
  ],
  [
    "Aputai",
    "apx"
  ],
  [
    "Apalaí",
    "apy"
  ],
  [
    "Safeyoka",
    "apz"
  ],
  [
    "Archi",
    "aqc"
  ],
  [
    "Ampari Dogon",
    "aqd"
  ],
  [
    "Arigidi",
    "aqg"
  ],
  [
    "Aninka",
    "aqk"
  ],
  [
    "Atohwaim",
    "aqm"
  ],
  [
    "Northern Alta",
    "aqn"
  ],
  [
    "Atakapa",
    "aqp"
  ],
  [
    "Arhâ",
    "aqr"
  ],
  [
    "Angaité",
    "aqt"
  ],
  [
    "Akuntsu",
    "aqz"
  ],
  [
    "Arabic",
    "ara",
    "ara",
    "ara",
    "ar",
    "العربية"
  ],
  [
    "Standard Arabic",
    "arb"
  ],
  [
    "Official Aramaic (700-300 BCE)",
    "arc",
    "arc",
    "arc"
  ],
  [
    "Arabana",
    "ard"
  ],
  [
    "Western Arrarnta",
    "are"
  ],
  [
    "Aragonese",
    "arg",
    "arg",
    "arg",
    "an",
    "Aragonés"
  ],
  [
    "Arhuaco",
    "arh"
  ],
  [
    "Arikara",
    "ari"
  ],
  [
    "Arapaso",
    "arj"
  ],
  [
    "Arikapú",
    "ark"
  ],
  [
    "Arabela",
    "arl"
  ],
  [
    "Mapudungun",
    "arn",
    "arn",
    "arn"
  ],
  [
    "Araona",
    "aro"
  ],
  [
    "Arapaho",
    "arp",
    "arp",
    "arp"
  ],
  [
    "Algerian Arabic",
    "arq"
  ],
  [
    "Karo (Brazil)",
    "arr"
  ],
  [
    "Najdi Arabic",
    "ars"
  ],
  [
    "Aruá (Amazonas State)",
    "aru"
  ],
  [
    "Arbore",
    "arv"
  ],
  [
    "Arawak",
    "arw",
    "arw",
    "arw"
  ],
  [
    "Aruá (Rodonia State)",
    "arx"
  ],
  [
    "Moroccan Arabic",
    "ary"
  ],
  [
    "Egyptian Arabic",
    "arz"
  ],
  [
    "Asu (Tanzania)",
    "asa"
  ],
  [
    "Assiniboine",
    "asb"
  ],
  [
    "Casuarina Coast Asmat",
    "asc"
  ],
  [
    "American Sign Language",
    "ase"
  ],
  [
    "Auslan",
    "asf"
  ],
  [
    "Cishingini",
    "asg"
  ],
  [
    "Abishira",
    "ash"
  ],
  [
    "Buruwai",
    "asi"
  ],
  [
    "Sari",
    "asj"
  ],
  [
    "Ashkun",
    "ask"
  ],
  [
    "Asilulu",
    "asl"
  ],
  [
    "Assamese",
    "asm",
    "asm",
    "asm",
    "as",
    "অসমীয়া"
  ],
  [
    "Xingú Asuriní",
    "asn"
  ],
  [
    "Dano",
    "aso"
  ],
  [
    "Algerian Sign Language",
    "asp"
  ],
  [
    "Austrian Sign Language",
    "asq"
  ],
  [
    "Asuri",
    "asr"
  ],
  [
    "Ipulo",
    "ass"
  ],
  [
    "Asturian",
    "ast",
    "ast",
    "ast"
  ],
  [
    "Tocantins Asurini",
    "asu"
  ],
  [
    "Asoa",
    "asv"
  ],
  [
    "Australian Aborigines Sign Language",
    "asw"
  ],
  [
    "Muratayak",
    "asx"
  ],
  [
    "Yaosakor Asmat",
    "asy"
  ],
  [
    "As",
    "asz"
  ],
  [
    "Pele-Ata",
    "ata"
  ],
  [
    "Zaiwa",
    "atb"
  ],
  [
    "Atsahuaca",
    "atc"
  ],
  [
    "Ata Manobo",
    "atd"
  ],
  [
    "Atemble",
    "ate"
  ],
  [
    "Ivbie North-Okpela-Arhe",
    "atg"
  ],
  [
    "Attié",
    "ati"
  ],
  [
    "Atikamekw",
    "atj"
  ],
  [
    "Ati",
    "atk"
  ],
  [
    "Mt. Iraya Agta",
    "atl"
  ],
  [
    "Ata",
    "atm"
  ],
  [
    "Ashtiani",
    "atn"
  ],
  [
    "Atong (Cameroon)",
    "ato"
  ],
  [
    "Pudtol Atta",
    "atp"
  ],
  [
    "Aralle-Tabulahan",
    "atq"
  ],
  [
    "Waimiri-Atroari",
    "atr"
  ],
  [
    "Gros Ventre",
    "ats"
  ],
  [
    "Pamplona Atta",
    "att"
  ],
  [
    "Reel",
    "atu"
  ],
  [
    "Northern Altai",
    "atv"
  ],
  [
    "Atsugewi",
    "atw"
  ],
  [
    "Arutani",
    "atx"
  ],
  [
    "Aneityum",
    "aty"
  ],
  [
    "Arta",
    "atz"
  ],
  [
    "Asumboa",
    "aua"
  ],
  [
    "Alugu",
    "aub"
  ],
  [
    "Waorani",
    "auc"
  ],
  [
    "Anuta",
    "aud"
  ],
  [
    "Aguna",
    "aug"
  ],
  [
    "Aushi",
    "auh"
  ],
  [
    "Anuki",
    "aui"
  ],
  [
    "Awjilah",
    "auj"
  ],
  [
    "Heyo",
    "auk"
  ],
  [
    "Aulua",
    "aul"
  ],
  [
    "Asu (Nigeria)",
    "aum"
  ],
  [
    "Molmo One",
    "aun"
  ],
  [
    "Auyokawa",
    "auo"
  ],
  [
    "Makayam",
    "aup"
  ],
  [
    "Anus",
    "auq"
  ],
  [
    "Aruek",
    "aur"
  ],
  [
    "Austral",
    "aut"
  ],
  [
    "Auye",
    "auu"
  ],
  [
    "Awyi",
    "auw"
  ],
  [
    "Aurá",
    "aux"
  ],
  [
    "Awiyaana",
    "auy"
  ],
  [
    "Uzbeki Arabic",
    "auz"
  ],
  [
    "Avaric",
    "ava",
    "ava",
    "ava",
    "av",
    "Авар"
  ],
  [
    "Avau",
    "avb"
  ],
  [
    "Alviri-Vidari",
    "avd"
  ],
  [
    "Avestan",
    "ave",
    "ave",
    "ave",
    "ae"
  ],
  [
    "Avikam",
    "avi"
  ],
  [
    "Kotava",
    "avk"
  ],
  [
    "Eastern Egyptian Bedawi Arabic",
    "avl"
  ],
  [
    "Angkamuthi",
    "avm"
  ],
  [
    "Avatime",
    "avn"
  ],
  [
    "Agavotaguerra",
    "avo"
  ],
  [
    "Aushiri",
    "avs"
  ],
  [
    "Au",
    "avt"
  ],
  [
    "Avokaya",
    "avu"
  ],
  [
    "Avá-Canoeiro",
    "avv"
  ],
  [
    "Awadhi",
    "awa",
    "awa",
    "awa"
  ],
  [
    "Awa (Papua New Guinea)",
    "awb"
  ],
  [
    "Cicipu",
    "awc"
  ],
  [
    "Awetí",
    "awe"
  ],
  [
    "Anguthimri",
    "awg"
  ],
  [
    "Awbono",
    "awh"
  ],
  [
    "Aekyom",
    "awi"
  ],
  [
    "Awabakal",
    "awk"
  ],
  [
    "Arawum",
    "awm"
  ],
  [
    "Awngi",
    "awn"
  ],
  [
    "Awak",
    "awo"
  ],
  [
    "Awera",
    "awr"
  ],
  [
    "South Awyu",
    "aws"
  ],
  [
    "Araweté",
    "awt"
  ],
  [
    "Central Awyu",
    "awu"
  ],
  [
    "Jair Awyu",
    "awv"
  ],
  [
    "Awun",
    "aww"
  ],
  [
    "Awara",
    "awx"
  ],
  [
    "Edera Awyu",
    "awy"
  ],
  [
    "Abipon",
    "axb"
  ],
  [
    "Ayerrerenge",
    "axe"
  ],
  [
    "Mato Grosso Arára",
    "axg"
  ],
  [
    "Yaka (Central African Republic)",
    "axk"
  ],
  [
    "Lower Southern Aranda",
    "axl"
  ],
  [
    "Middle Armenian",
    "axm"
  ],
  [
    "Xârâgurè",
    "axx"
  ],
  [
    "Awar",
    "aya"
  ],
  [
    "Ayizo Gbe",
    "ayb"
  ],
  [
    "Southern Aymara",
    "ayc"
  ],
  [
    "Ayabadhu",
    "ayd"
  ],
  [
    "Ayere",
    "aye"
  ],
  [
    "Ginyanga",
    "ayg"
  ],
  [
    "Hadrami Arabic",
    "ayh"
  ],
  [
    "Leyigha",
    "ayi"
  ],
  [
    "Akuku",
    "ayk"
  ],
  [
    "Libyan Arabic",
    "ayl"
  ],
  [
    "Aymara",
    "aym",
    "aym",
    "aym",
    "ay",
    "Aymar"
  ],
  [
    "Sanaani Arabic",
    "ayn"
  ],
  [
    "Ayoreo",
    "ayo"
  ],
  [
    "North Mesopotamian Arabic",
    "ayp"
  ],
  [
    "Ayi (Papua New Guinea)",
    "ayq"
  ],
  [
    "Central Aymara",
    "ayr"
  ],
  [
    "Sorsogon Ayta",
    "ays"
  ],
  [
    "Magbukun Ayta",
    "ayt"
  ],
  [
    "Ayu",
    "ayu"
  ],
  [
    "Mai Brat",
    "ayz"
  ],
  [
    "Azha",
    "aza"
  ],
  [
    "South Azerbaijani",
    "azb"
  ],
  [
    "Eastern Durango Nahuatl",
    "azd"
  ],
  [
    "Azerbaijani",
    "aze",
    "aze",
    "aze",
    "az",
    "Azərbaycanca / آذربايجان"
  ],
  [
    "San Pedro Amuzgos Amuzgo",
    "azg"
  ],
  [
    "North Azerbaijani",
    "azj"
  ],
  [
    "Ipalapa Amuzgo",
    "azm"
  ],
  [
    "Western Durango Nahuatl",
    "azn"
  ],
  [
    "Awing",
    "azo"
  ],
  [
    "Faire Atta",
    "azt"
  ],
  [
    "Highland Puebla Nahuatl",
    "azz"
  ],
  [
    "Babatana",
    "baa"
  ],
  [
    "Bainouk-Gunyuño",
    "bab"
  ],
  [
    "Badui",
    "bac"
  ],
  [
    "Baré",
    "bae"
  ],
  [
    "Nubaca",
    "baf"
  ],
  [
    "Tuki",
    "bag"
  ],
  [
    "Bahamas Creole English",
    "bah"
  ],
  [
    "Barakai",
    "baj"
  ],
  [
    "Bashkir",
    "bak",
    "bak",
    "bak",
    "ba",
    "Башҡорт"
  ],
  [
    "Baluchi",
    "bal",
    "bal",
    "bal"
  ],
  [
    "Bambara",
    "bam",
    "bam",
    "bam",
    "bm",
    "Bamanankan"
  ],
  [
    "Balinese",
    "ban",
    "ban",
    "ban"
  ],
  [
    "Waimaha",
    "bao"
  ],
  [
    "Bantawa",
    "bap"
  ],
  [
    "Bavarian",
    "bar"
  ],
  [
    "Basa (Cameroon)",
    "bas",
    "bas",
    "bas"
  ],
  [
    "Bada (Nigeria)",
    "bau"
  ],
  [
    "Vengo",
    "bav"
  ],
  [
    "Bambili-Bambui",
    "baw"
  ],
  [
    "Bamun",
    "bax"
  ],
  [
    "Batuley",
    "bay"
  ],
  [
    "Baatonum",
    "bba"
  ],
  [
    "Barai",
    "bbb"
  ],
  [
    "Batak Toba",
    "bbc"
  ],
  [
    "Bau",
    "bbd"
  ],
  [
    "Bangba",
    "bbe"
  ],
  [
    "Baibai",
    "bbf"
  ],
  [
    "Barama",
    "bbg"
  ],
  [
    "Bugan",
    "bbh"
  ],
  [
    "Barombi",
    "bbi"
  ],
  [
    "Ghomálá'",
    "bbj"
  ],
  [
    "Babanki",
    "bbk"
  ],
  [
    "Bats",
    "bbl"
  ],
  [
    "Babango",
    "bbm"
  ],
  [
    "Uneapa",
    "bbn"
  ],
  [
    "Northern Bobo Madaré",
    "bbo"
  ],
  [
    "West Central Banda",
    "bbp"
  ],
  [
    "Bamali",
    "bbq"
  ],
  [
    "Girawa",
    "bbr"
  ],
  [
    "Bakpinka",
    "bbs"
  ],
  [
    "Mburku",
    "bbt"
  ],
  [
    "Kulung (Nigeria)",
    "bbu"
  ],
  [
    "Karnai",
    "bbv"
  ],
  [
    "Baba",
    "bbw"
  ],
  [
    "Bubia",
    "bbx"
  ],
  [
    "Befang",
    "bby"
  ],
  [
    "Central Bai",
    "bca"
  ],
  [
    "Bainouk-Samik",
    "bcb"
  ],
  [
    "Southern Balochi",
    "bcc"
  ],
  [
    "North Babar",
    "bcd"
  ],
  [
    "Bamenyam",
    "bce"
  ],
  [
    "Bamu",
    "bcf"
  ],
  [
    "Baga Pokur",
    "bcg"
  ],
  [
    "Bariai",
    "bch"
  ],
  [
    "Baoulé",
    "bci"
  ],
  [
    "Bardi",
    "bcj"
  ],
  [
    "Bunuba",
    "bck"
  ],
  [
    "Central Bikol",
    "bcl"
  ],
  [
    "Bannoni",
    "bcm"
  ],
  [
    "Bali (Nigeria)",
    "bcn"
  ],
  [
    "Kaluli",
    "bco"
  ],
  [
    "Bali (Democratic Republic of Congo)",
    "bcp"
  ],
  [
    "Bench",
    "bcq"
  ],
  [
    "Babine",
    "bcr"
  ],
  [
    "Kohumono",
    "bcs"
  ],
  [
    "Bendi",
    "bct"
  ],
  [
    "Awad Bing",
    "bcu"
  ],
  [
    "Shoo-Minda-Nye",
    "bcv"
  ],
  [
    "Bana",
    "bcw"
  ],
  [
    "Bacama",
    "bcy"
  ],
  [
    "Bainouk-Gunyaamolo",
    "bcz"
  ],
  [
    "Bayot",
    "bda"
  ],
  [
    "Basap",
    "bdb"
  ],
  [
    "Emberá-Baudó",
    "bdc"
  ],
  [
    "Bunama",
    "bdd"
  ],
  [
    "Bade",
    "bde"
  ],
  [
    "Biage",
    "bdf"
  ],
  [
    "Bonggi",
    "bdg"
  ],
  [
    "Baka (South Sudan)",
    "bdh"
  ],
  [
    "Burun",
    "bdi"
  ],
  [
    "Bai (South Sudan)",
    "bdj"
  ],
  [
    "Budukh",
    "bdk"
  ],
  [
    "Indonesian Bajau",
    "bdl"
  ],
  [
    "Buduma",
    "bdm"
  ],
  [
    "Baldemu",
    "bdn"
  ],
  [
    "Morom",
    "bdo"
  ],
  [
    "Bende",
    "bdp"
  ],
  [
    "Bahnar",
    "bdq"
  ],
  [
    "West Coast Bajau",
    "bdr"
  ],
  [
    "Burunge",
    "bds"
  ],
  [
    "Bokoto",
    "bdt"
  ],
  [
    "Oroko",
    "bdu"
  ],
  [
    "Bodo Parja",
    "bdv"
  ],
  [
    "Baham",
    "bdw"
  ],
  [
    "Budong-Budong",
    "bdx"
  ],
  [
    "Bandjalang",
    "bdy"
  ],
  [
    "Badeshi",
    "bdz"
  ],
  [
    "Beaver",
    "bea"
  ],
  [
    "Bebele",
    "beb"
  ],
  [
    "Iceve-Maci",
    "bec"
  ],
  [
    "Bedoanas",
    "bed"
  ],
  [
    "Byangsi",
    "bee"
  ],
  [
    "Benabena",
    "bef"
  ],
  [
    "Belait",
    "beg"
  ],
  [
    "Biali",
    "beh"
  ],
  [
    "Bekati'",
    "bei"
  ],
  [
    "Beja",
    "bej",
    "bej",
    "bej"
  ],
  [
    "Bebeli",
    "bek"
  ],
  [
    "Belarusian",
    "bel",
    "bel",
    "bel",
    "be",
    "Беларуская"
  ],
  [
    "Bemba (Zambia)",
    "bem",
    "bem",
    "bem"
  ],
  [
    "Bengali",
    "ben",
    "ben",
    "ben",
    "bn",
    "বাংলা"
  ],
  [
    "Beami",
    "beo"
  ],
  [
    "Besoa",
    "bep"
  ],
  [
    "Beembe",
    "beq"
  ],
  [
    "Besme",
    "bes"
  ],
  [
    "Guiberoua Béte",
    "bet"
  ],
  [
    "Blagar",
    "beu"
  ],
  [
    "Daloa Bété",
    "bev"
  ],
  [
    "Betawi",
    "bew"
  ],
  [
    "Jur Modo",
    "bex"
  ],
  [
    "Beli (Papua New Guinea)",
    "bey"
  ],
  [
    "Bena (Tanzania)",
    "bez"
  ],
  [
    "Bari",
    "bfa"
  ],
  [
    "Pauri Bareli",
    "bfb"
  ],
  [
    "Panyi Bai",
    "bfc"
  ],
  [
    "Bafut",
    "bfd"
  ],
  [
    "Betaf",
    "bfe"
  ],
  [
    "Bofi",
    "bff"
  ],
  [
    "Busang Kayan",
    "bfg"
  ],
  [
    "Blafe",
    "bfh"
  ],
  [
    "British Sign Language",
    "bfi"
  ],
  [
    "Bafanji",
    "bfj"
  ],
  [
    "Ban Khor Sign Language",
    "bfk"
  ],
  [
    "Banda-Ndélé",
    "bfl"
  ],
  [
    "Mmen",
    "bfm"
  ],
  [
    "Bunak",
    "bfn"
  ],
  [
    "Malba Birifor",
    "bfo"
  ],
  [
    "Beba",
    "bfp"
  ],
  [
    "Badaga",
    "bfq"
  ],
  [
    "Bazigar",
    "bfr"
  ],
  [
    "Southern Bai",
    "bfs"
  ],
  [
    "Balti",
    "bft"
  ],
  [
    "Gahri",
    "bfu"
  ],
  [
    "Bondo",
    "bfw"
  ],
  [
    "Bantayanon",
    "bfx"
  ],
  [
    "Bagheli",
    "bfy"
  ],
  [
    "Mahasu Pahari",
    "bfz"
  ],
  [
    "Gwamhi-Wuri",
    "bga"
  ],
  [
    "Bobongko",
    "bgb"
  ],
  [
    "Haryanvi",
    "bgc"
  ],
  [
    "Rathwi Bareli",
    "bgd"
  ],
  [
    "Bauria",
    "bge"
  ],
  [
    "Bangandu",
    "bgf"
  ],
  [
    "Bugun",
    "bgg"
  ],
  [
    "Giangan",
    "bgi"
  ],
  [
    "Bangolan",
    "bgj"
  ],
  [
    "Bit",
    "bgk"
  ],
  [
    "Bo (Laos)",
    "bgl"
  ],
  [
    "Western Balochi",
    "bgn"
  ],
  [
    "Baga Koga",
    "bgo"
  ],
  [
    "Eastern Balochi",
    "bgp"
  ],
  [
    "Bagri",
    "bgq"
  ],
  [
    "Bawm Chin",
    "bgr"
  ],
  [
    "Tagabawa",
    "bgs"
  ],
  [
    "Bughotu",
    "bgt"
  ],
  [
    "Mbongno",
    "bgu"
  ],
  [
    "Warkay-Bipim",
    "bgv"
  ],
  [
    "Bhatri",
    "bgw"
  ],
  [
    "Balkan Gagauz Turkish",
    "bgx"
  ],
  [
    "Benggoi",
    "bgy"
  ],
  [
    "Banggai",
    "bgz"
  ],
  [
    "Bharia",
    "bha"
  ],
  [
    "Bhili",
    "bhb"
  ],
  [
    "Biga",
    "bhc"
  ],
  [
    "Bhadrawahi",
    "bhd"
  ],
  [
    "Bhaya",
    "bhe"
  ],
  [
    "Odiai",
    "bhf"
  ],
  [
    "Binandere",
    "bhg"
  ],
  [
    "Bukharic",
    "bhh"
  ],
  [
    "Bhilali",
    "bhi"
  ],
  [
    "Bahing",
    "bhj"
  ],
  [
    "Bimin",
    "bhl"
  ],
  [
    "Bathari",
    "bhm"
  ],
  [
    "Bohtan Neo-Aramaic",
    "bhn"
  ],
  [
    "Bhojpuri",
    "bho",
    "bho",
    "bho"
  ],
  [
    "Bima",
    "bhp"
  ],
  [
    "Tukang Besi South",
    "bhq"
  ],
  [
    "Bara Malagasy",
    "bhr"
  ],
  [
    "Buwal",
    "bhs"
  ],
  [
    "Bhattiyali",
    "bht"
  ],
  [
    "Bhunjia",
    "bhu"
  ],
  [
    "Bahau",
    "bhv"
  ],
  [
    "Biak",
    "bhw"
  ],
  [
    "Bhalay",
    "bhx"
  ],
  [
    "Bhele",
    "bhy"
  ],
  [
    "Bada (Indonesia)",
    "bhz"
  ],
  [
    "Badimaya",
    "bia"
  ],
  [
    "Bissa",
    "bib"
  ],
  [
    "Bidiyo",
    "bid"
  ],
  [
    "Bepour",
    "bie"
  ],
  [
    "Biafada",
    "bif"
  ],
  [
    "Biangai",
    "big"
  ],
  [
    "Bikol",
    "bik",
    "bik",
    "bik"
  ],
  [
    "Bile",
    "bil"
  ],
  [
    "Bimoba",
    "bim"
  ],
  [
    "Bini",
    "bin",
    "bin",
    "bin"
  ],
  [
    "Nai",
    "bio"
  ],
  [
    "Bila",
    "bip"
  ],
  [
    "Bipi",
    "biq"
  ],
  [
    "Bisorio",
    "bir"
  ],
  [
    "Bislama",
    "bis",
    "bis",
    "bis",
    "bi",
    "Bislama"
  ],
  [
    "Berinomo",
    "bit"
  ],
  [
    "Biete",
    "biu"
  ],
  [
    "Southern Birifor",
    "biv"
  ],
  [
    "Kol (Cameroon)",
    "biw"
  ],
  [
    "Bijori",
    "bix"
  ],
  [
    "Birhor",
    "biy"
  ],
  [
    "Baloi",
    "biz"
  ],
  [
    "Budza",
    "bja"
  ],
  [
    "Banggarla",
    "bjb"
  ],
  [
    "Bariji",
    "bjc"
  ],
  [
    "Biao-Jiao Mien",
    "bje"
  ],
  [
    "Barzani Jewish Neo-Aramaic",
    "bjf"
  ],
  [
    "Bidyogo",
    "bjg"
  ],
  [
    "Bahinemo",
    "bjh"
  ],
  [
    "Burji",
    "bji"
  ],
  [
    "Kanauji",
    "bjj"
  ],
  [
    "Barok",
    "bjk"
  ],
  [
    "Bulu (Papua New Guinea)",
    "bjl"
  ],
  [
    "Bajelani",
    "bjm"
  ],
  [
    "Banjar",
    "bjn"
  ],
  [
    "Mid-Southern Banda",
    "bjo"
  ],
  [
    "Fanamaket",
    "bjp"
  ],
  [
    "Binumarien",
    "bjr"
  ],
  [
    "Bajan",
    "bjs"
  ],
  [
    "Balanta-Ganja",
    "bjt"
  ],
  [
    "Busuu",
    "bju"
  ],
  [
    "Bedjond",
    "bjv"
  ],
  [
    "Bakwé",
    "bjw"
  ],
  [
    "Banao Itneg",
    "bjx"
  ],
  [
    "Bayali",
    "bjy"
  ],
  [
    "Baruga",
    "bjz"
  ],
  [
    "Kyak",
    "bka"
  ],
  [
    "Baka (Cameroon)",
    "bkc"
  ],
  [
    "Binukid",
    "bkd"
  ],
  [
    "Beeke",
    "bkf"
  ],
  [
    "Buraka",
    "bkg"
  ],
  [
    "Bakoko",
    "bkh"
  ],
  [
    "Baki",
    "bki"
  ],
  [
    "Pande",
    "bkj"
  ],
  [
    "Brokskat",
    "bkk"
  ],
  [
    "Berik",
    "bkl"
  ],
  [
    "Kom (Cameroon)",
    "bkm"
  ],
  [
    "Bukitan",
    "bkn"
  ],
  [
    "Kwa'",
    "bko"
  ],
  [
    "Boko (Democratic Republic of Congo)",
    "bkp"
  ],
  [
    "Bakairí",
    "bkq"
  ],
  [
    "Bakumpai",
    "bkr"
  ],
  [
    "Northern Sorsoganon",
    "bks"
  ],
  [
    "Boloki",
    "bkt"
  ],
  [
    "Buhid",
    "bku"
  ],
  [
    "Bekwarra",
    "bkv"
  ],
  [
    "Bekwel",
    "bkw"
  ],
  [
    "Baikeno",
    "bkx"
  ],
  [
    "Bokyi",
    "bky"
  ],
  [
    "Bungku",
    "bkz"
  ],
  [
    "Siksika",
    "bla",
    "bla",
    "bla"
  ],
  [
    "Bilua",
    "blb"
  ],
  [
    "Bella Coola",
    "blc"
  ],
  [
    "Bolango",
    "bld"
  ],
  [
    "Balanta-Kentohe",
    "ble"
  ],
  [
    "Buol",
    "blf"
  ],
  [
    "Kuwaa",
    "blh"
  ],
  [
    "Bolia",
    "bli"
  ],
  [
    "Bolongan",
    "blj"
  ],
  [
    "Pa'o Karen",
    "blk"
  ],
  [
    "Biloxi",
    "bll"
  ],
  [
    "Beli (South Sudan)",
    "blm"
  ],
  [
    "Southern Catanduanes Bikol",
    "bln"
  ],
  [
    "Anii",
    "blo"
  ],
  [
    "Blablanga",
    "blp"
  ],
  [
    "Baluan-Pam",
    "blq"
  ],
  [
    "Blang",
    "blr"
  ],
  [
    "Balaesang",
    "bls"
  ],
  [
    "Tai Dam",
    "blt"
  ],
  [
    "Kibala",
    "blv"
  ],
  [
    "Balangao",
    "blw"
  ],
  [
    "Mag-Indi Ayta",
    "blx"
  ],
  [
    "Notre",
    "bly"
  ],
  [
    "Balantak",
    "blz"
  ],
  [
    "Lame",
    "bma"
  ],
  [
    "Bembe",
    "bmb"
  ],
  [
    "Biem",
    "bmc"
  ],
  [
    "Baga Manduri",
    "bmd"
  ],
  [
    "Limassa",
    "bme"
  ],
  [
    "Bom-Kim",
    "bmf"
  ],
  [
    "Bamwe",
    "bmg"
  ],
  [
    "Kein",
    "bmh"
  ],
  [
    "Bagirmi",
    "bmi"
  ],
  [
    "Bote-Majhi",
    "bmj"
  ],
  [
    "Ghayavi",
    "bmk"
  ],
  [
    "Bomboli",
    "bml"
  ],
  [
    "Northern Betsimisaraka Malagasy",
    "bmm"
  ],
  [
    "Bina (Papua New Guinea)",
    "bmn"
  ],
  [
    "Bambalang",
    "bmo"
  ],
  [
    "Bulgebi",
    "bmp"
  ],
  [
    "Bomu",
    "bmq"
  ],
  [
    "Muinane",
    "bmr"
  ],
  [
    "Bilma Kanuri",
    "bms"
  ],
  [
    "Biao Mon",
    "bmt"
  ],
  [
    "Somba-Siawari",
    "bmu"
  ],
  [
    "Bum",
    "bmv"
  ],
  [
    "Bomwali",
    "bmw"
  ],
  [
    "Baimak",
    "bmx"
  ],
  [
    "Baramu",
    "bmz"
  ],
  [
    "Bonerate",
    "bna"
  ],
  [
    "Bookan",
    "bnb"
  ],
  [
    "Bontok",
    "bnc"
  ],
  [
    "Banda (Indonesia)",
    "bnd"
  ],
  [
    "Bintauna",
    "bne"
  ],
  [
    "Masiwang",
    "bnf"
  ],
  [
    "Benga",
    "bng"
  ],
  [
    "Bangi",
    "bni"
  ],
  [
    "Eastern Tawbuid",
    "bnj"
  ],
  [
    "Bierebo",
    "bnk"
  ],
  [
    "Boon",
    "bnl"
  ],
  [
    "Batanga",
    "bnm"
  ],
  [
    "Bunun",
    "bnn"
  ],
  [
    "Bantoanon",
    "bno"
  ],
  [
    "Bola",
    "bnp"
  ],
  [
    "Bantik",
    "bnq"
  ],
  [
    "Butmas-Tur",
    "bnr"
  ],
  [
    "Bundeli",
    "bns"
  ],
  [
    "Bentong",
    "bnu"
  ],
  [
    "Bonerif",
    "bnv"
  ],
  [
    "Bisis",
    "bnw"
  ],
  [
    "Bangubangu",
    "bnx"
  ],
  [
    "Bintulu",
    "bny"
  ],
  [
    "Beezen",
    "bnz"
  ],
  [
    "Bora",
    "boa"
  ],
  [
    "Aweer",
    "bob"
  ],
  [
    "Tibetan",
    "bod",
    "tib",
    "bod",
    "bo",
    "བོད་ཡིག / Bod skad"
  ],
  [
    "Mundabli",
    "boe"
  ],
  [
    "Bolon",
    "bof"
  ],
  [
    "Bamako Sign Language",
    "bog"
  ],
  [
    "Boma",
    "boh"
  ],
  [
    "Barbareño",
    "boi"
  ],
  [
    "Anjam",
    "boj"
  ],
  [
    "Bonjo",
    "bok"
  ],
  [
    "Bole",
    "bol"
  ],
  [
    "Berom",
    "bom"
  ],
  [
    "Bine",
    "bon"
  ],
  [
    "Tiemacèwè Bozo",
    "boo"
  ],
  [
    "Bonkiman",
    "bop"
  ],
  [
    "Bogaya",
    "boq"
  ],
  [
    "Borôro",
    "bor"
  ],
  [
    "Bosnian",
    "bos",
    "bos",
    "bos",
    "bs",
    "Bosanski"
  ],
  [
    "Bongo",
    "bot"
  ],
  [
    "Bondei",
    "bou"
  ],
  [
    "Tuwuli",
    "bov"
  ],
  [
    "Rema",
    "bow"
  ],
  [
    "Buamu",
    "box"
  ],
  [
    "Bodo (Central African Republic)",
    "boy"
  ],
  [
    "Tiéyaxo Bozo",
    "boz"
  ],
  [
    "Daakaka",
    "bpa"
  ],
  [
    "Mbuk",
    "bpc"
  ],
  [
    "Banda-Banda",
    "bpd"
  ],
  [
    "Bauni",
    "bpe"
  ],
  [
    "Bonggo",
    "bpg"
  ],
  [
    "Botlikh",
    "bph"
  ],
  [
    "Bagupi",
    "bpi"
  ],
  [
    "Binji",
    "bpj"
  ],
  [
    "Orowe",
    "bpk"
  ],
  [
    "Broome Pearling Lugger Pidgin",
    "bpl"
  ],
  [
    "Biyom",
    "bpm"
  ],
  [
    "Dzao Min",
    "bpn"
  ],
  [
    "Anasi",
    "bpo"
  ],
  [
    "Kaure",
    "bpp"
  ],
  [
    "Banda Malay",
    "bpq"
  ],
  [
    "Koronadal Blaan",
    "bpr"
  ],
  [
    "Sarangani Blaan",
    "bps"
  ],
  [
    "Barrow Point",
    "bpt"
  ],
  [
    "Bongu",
    "bpu"
  ],
  [
    "Bian Marind",
    "bpv"
  ],
  [
    "Bo (Papua New Guinea)",
    "bpw"
  ],
  [
    "Palya Bareli",
    "bpx"
  ],
  [
    "Bishnupriya",
    "bpy"
  ],
  [
    "Bilba",
    "bpz"
  ],
  [
    "Tchumbuli",
    "bqa"
  ],
  [
    "Bagusa",
    "bqb"
  ],
  [
    "Boko (Benin)",
    "bqc"
  ],
  [
    "Bung",
    "bqd"
  ],
  [
    "Baga Kaloum",
    "bqf"
  ],
  [
    "Bago-Kusuntu",
    "bqg"
  ],
  [
    "Baima",
    "bqh"
  ],
  [
    "Bakhtiari",
    "bqi"
  ],
  [
    "Bandial",
    "bqj"
  ],
  [
    "Banda-Mbrès",
    "bqk"
  ],
  [
    "Bilakura",
    "bql"
  ],
  [
    "Wumboko",
    "bqm"
  ],
  [
    "Bulgarian Sign Language",
    "bqn"
  ],
  [
    "Balo",
    "bqo"
  ],
  [
    "Busa",
    "bqp"
  ],
  [
    "Biritai",
    "bqq"
  ],
  [
    "Burusu",
    "bqr"
  ],
  [
    "Bosngun",
    "bqs"
  ],
  [
    "Bamukumbit",
    "bqt"
  ],
  [
    "Boguru",
    "bqu"
  ],
  [
    "Koro Wachi",
    "bqv"
  ],
  [
    "Buru (Nigeria)",
    "bqw"
  ],
  [
    "Baangi",
    "bqx"
  ],
  [
    "Bengkala Sign Language",
    "bqy"
  ],
  [
    "Bakaka",
    "bqz"
  ],
  [
    "Braj",
    "bra",
    "bra",
    "bra"
  ],
  [
    "Brao",
    "brb"
  ],
  [
    "Berbice Creole Dutch",
    "brc"
  ],
  [
    "Baraamu",
    "brd"
  ],
  [
    "Breton",
    "bre",
    "bre",
    "bre",
    "br",
    "Brezhoneg"
  ],
  [
    "Bira",
    "brf"
  ],
  [
    "Baure",
    "brg"
  ],
  [
    "Brahui",
    "brh"
  ],
  [
    "Mokpwe",
    "bri"
  ],
  [
    "Bieria",
    "brj"
  ],
  [
    "Birked",
    "brk"
  ],
  [
    "Birwa",
    "brl"
  ],
  [
    "Barambu",
    "brm"
  ],
  [
    "Boruca",
    "brn"
  ],
  [
    "Brokkat",
    "bro"
  ],
  [
    "Barapasi",
    "brp"
  ],
  [
    "Breri",
    "brq"
  ],
  [
    "Birao",
    "brr"
  ],
  [
    "Baras",
    "brs"
  ],
  [
    "Bitare",
    "brt"
  ],
  [
    "Eastern Bru",
    "bru"
  ],
  [
    "Western Bru",
    "brv"
  ],
  [
    "Bellari",
    "brw"
  ],
  [
    "Bodo (India)",
    "brx"
  ],
  [
    "Burui",
    "bry"
  ],
  [
    "Bilbil",
    "brz"
  ],
  [
    "Abinomn",
    "bsa"
  ],
  [
    "Brunei Bisaya",
    "bsb"
  ],
  [
    "Bassari",
    "bsc"
  ],
  [
    "Wushi",
    "bse"
  ],
  [
    "Bauchi",
    "bsf"
  ],
  [
    "Bashkardi",
    "bsg"
  ],
  [
    "Kati",
    "bsh"
  ],
  [
    "Bassossi",
    "bsi"
  ],
  [
    "Bangwinji",
    "bsj"
  ],
  [
    "Burushaski",
    "bsk"
  ],
  [
    "Basa-Gumna",
    "bsl"
  ],
  [
    "Busami",
    "bsm"
  ],
  [
    "Barasana-Eduria",
    "bsn"
  ],
  [
    "Buso",
    "bso"
  ],
  [
    "Baga Sitemu",
    "bsp"
  ],
  [
    "Bassa",
    "bsq"
  ],
  [
    "Bassa-Kontagora",
    "bsr"
  ],
  [
    "Akoose",
    "bss"
  ],
  [
    "Basketo",
    "bst"
  ],
  [
    "Bahonsuai",
    "bsu"
  ],
  [
    "Baga Sobané",
    "bsv"
  ],
  [
    "Baiso",
    "bsw"
  ],
  [
    "Yangkam",
    "bsx"
  ],
  [
    "Sabah Bisaya",
    "bsy"
  ],
  [
    "Bata",
    "bta"
  ],
  [
    "Bati (Cameroon)",
    "btc"
  ],
  [
    "Batak Dairi",
    "btd"
  ],
  [
    "Gamo-Ningi",
    "bte"
  ],
  [
    "Birgit",
    "btf"
  ],
  [
    "Gagnoa Bété",
    "btg"
  ],
  [
    "Biatah Bidayuh",
    "bth"
  ],
  [
    "Burate",
    "bti"
  ],
  [
    "Bacanese Malay",
    "btj"
  ],
  [
    "Batak Mandailing",
    "btm"
  ],
  [
    "Ratagnon",
    "btn"
  ],
  [
    "Rinconada Bikol",
    "bto"
  ],
  [
    "Budibud",
    "btp"
  ],
  [
    "Batek",
    "btq"
  ],
  [
    "Baetora",
    "btr"
  ],
  [
    "Batak Simalungun",
    "bts"
  ],
  [
    "Bete-Bendi",
    "btt"
  ],
  [
    "Batu",
    "btu"
  ],
  [
    "Bateri",
    "btv"
  ],
  [
    "Butuanon",
    "btw"
  ],
  [
    "Batak Karo",
    "btx"
  ],
  [
    "Bobot",
    "bty"
  ],
  [
    "Batak Alas-Kluet",
    "btz"
  ],
  [
    "Buriat",
    "bua",
    "bua",
    "bua"
  ],
  [
    "Bua",
    "bub"
  ],
  [
    "Bushi",
    "buc"
  ],
  [
    "Ntcham",
    "bud"
  ],
  [
    "Beothuk",
    "bue"
  ],
  [
    "Bushoong",
    "buf"
  ],
  [
    "Buginese",
    "bug",
    "bug",
    "bug"
  ],
  [
    "Younuo Bunu",
    "buh"
  ],
  [
    "Bongili",
    "bui"
  ],
  [
    "Basa-Gurmana",
    "buj"
  ],
  [
    "Bugawac",
    "buk"
  ],
  [
    "Bulgarian",
    "bul",
    "bul",
    "bul",
    "bg",
    "Български"
  ],
  [
    "Bulu (Cameroon)",
    "bum"
  ],
  [
    "Sherbro",
    "bun"
  ],
  [
    "Terei",
    "buo"
  ],
  [
    "Busoa",
    "bup"
  ],
  [
    "Brem",
    "buq"
  ],
  [
    "Bokobaru",
    "bus"
  ],
  [
    "Bungain",
    "but"
  ],
  [
    "Budu",
    "buu"
  ],
  [
    "Bun",
    "buv"
  ],
  [
    "Bubi",
    "buw"
  ],
  [
    "Boghom",
    "bux"
  ],
  [
    "Bullom So",
    "buy"
  ],
  [
    "Bukwen",
    "buz"
  ],
  [
    "Barein",
    "bva"
  ],
  [
    "Bube",
    "bvb"
  ],
  [
    "Baelelea",
    "bvc"
  ],
  [
    "Baeggu",
    "bvd"
  ],
  [
    "Berau Malay",
    "bve"
  ],
  [
    "Boor",
    "bvf"
  ],
  [
    "Bonkeng",
    "bvg"
  ],
  [
    "Bure",
    "bvh"
  ],
  [
    "Belanda Viri",
    "bvi"
  ],
  [
    "Baan",
    "bvj"
  ],
  [
    "Bukat",
    "bvk"
  ],
  [
    "Bolivian Sign Language",
    "bvl"
  ],
  [
    "Bamunka",
    "bvm"
  ],
  [
    "Buna",
    "bvn"
  ],
  [
    "Bolgo",
    "bvo"
  ],
  [
    "Bumang",
    "bvp"
  ],
  [
    "Birri",
    "bvq"
  ],
  [
    "Burarra",
    "bvr"
  ],
  [
    "Bati (Indonesia)",
    "bvt"
  ],
  [
    "Bukit Malay",
    "bvu"
  ],
  [
    "Baniva",
    "bvv"
  ],
  [
    "Boga",
    "bvw"
  ],
  [
    "Dibole",
    "bvx"
  ],
  [
    "Baybayanon",
    "bvy"
  ],
  [
    "Bauzi",
    "bvz"
  ],
  [
    "Bwatoo",
    "bwa"
  ],
  [
    "Namosi-Naitasiri-Serua",
    "bwb"
  ],
  [
    "Bwile",
    "bwc"
  ],
  [
    "Bwaidoka",
    "bwd"
  ],
  [
    "Bwe Karen",
    "bwe"
  ],
  [
    "Boselewa",
    "bwf"
  ],
  [
    "Barwe",
    "bwg"
  ],
  [
    "Bishuo",
    "bwh"
  ],
  [
    "Baniwa",
    "bwi"
  ],
  [
    "Láá Láá Bwamu",
    "bwj"
  ],
  [
    "Bauwaki",
    "bwk"
  ],
  [
    "Bwela",
    "bwl"
  ],
  [
    "Biwat",
    "bwm"
  ],
  [
    "Wunai Bunu",
    "bwn"
  ],
  [
    "Boro (Ethiopia)",
    "bwo"
  ],
  [
    "Mandobo Bawah",
    "bwp"
  ],
  [
    "Southern Bobo Madaré",
    "bwq"
  ],
  [
    "Bura-Pabir",
    "bwr"
  ],
  [
    "Bomboma",
    "bws"
  ],
  [
    "Bafaw-Balong",
    "bwt"
  ],
  [
    "Buli (Ghana)",
    "bwu"
  ],
  [
    "Bwa",
    "bww"
  ],
  [
    "Bu-Nao Bunu",
    "bwx"
  ],
  [
    "Cwi Bwamu",
    "bwy"
  ],
  [
    "Bwisi",
    "bwz"
  ],
  [
    "Tairaha",
    "bxa"
  ],
  [
    "Belanda Bor",
    "bxb"
  ],
  [
    "Molengue",
    "bxc"
  ],
  [
    "Pela",
    "bxd"
  ],
  [
    "Birale",
    "bxe"
  ],
  [
    "Bilur",
    "bxf"
  ],
  [
    "Bangala",
    "bxg"
  ],
  [
    "Buhutu",
    "bxh"
  ],
  [
    "Pirlatapa",
    "bxi"
  ],
  [
    "Bayungu",
    "bxj"
  ],
  [
    "Bukusu",
    "bxk"
  ],
  [
    "Jalkunan",
    "bxl"
  ],
  [
    "Mongolia Buriat",
    "bxm"
  ],
  [
    "Burduna",
    "bxn"
  ],
  [
    "Barikanchi",
    "bxo"
  ],
  [
    "Bebil",
    "bxp"
  ],
  [
    "Beele",
    "bxq"
  ],
  [
    "Russia Buriat",
    "bxr"
  ],
  [
    "Busam",
    "bxs"
  ],
  [
    "China Buriat",
    "bxu"
  ],
  [
    "Berakou",
    "bxv"
  ],
  [
    "Bankagooma",
    "bxw"
  ],
  [
    "Binahari",
    "bxz"
  ],
  [
    "Batak",
    "bya"
  ],
  [
    "Bikya",
    "byb"
  ],
  [
    "Ubaghara",
    "byc"
  ],
  [
    "Benyadu'",
    "byd"
  ],
  [
    "Pouye",
    "bye"
  ],
  [
    "Bete",
    "byf"
  ],
  [
    "Baygo",
    "byg"
  ],
  [
    "Bhujel",
    "byh"
  ],
  [
    "Buyu",
    "byi"
  ],
  [
    "Bina (Nigeria)",
    "byj"
  ],
  [
    "Biao",
    "byk"
  ],
  [
    "Bayono",
    "byl"
  ],
  [
    "Bidjara",
    "bym"
  ],
  [
    "Bilin",
    "byn",
    "byn",
    "byn"
  ],
  [
    "Biyo",
    "byo"
  ],
  [
    "Bumaji",
    "byp"
  ],
  [
    "Basay",
    "byq"
  ],
  [
    "Baruya",
    "byr"
  ],
  [
    "Burak",
    "bys"
  ],
  [
    "Berti",
    "byt"
  ],
  [
    "Medumba",
    "byv"
  ],
  [
    "Belhariya",
    "byw"
  ],
  [
    "Qaqet",
    "byx"
  ],
  [
    "Banaro",
    "byz"
  ],
  [
    "Bandi",
    "bza"
  ],
  [
    "Andio",
    "bzb"
  ],
  [
    "Southern Betsimisaraka Malagasy",
    "bzc"
  ],
  [
    "Bribri",
    "bzd"
  ],
  [
    "Jenaama Bozo",
    "bze"
  ],
  [
    "Boikin",
    "bzf"
  ],
  [
    "Babuza",
    "bzg"
  ],
  [
    "Mapos Buang",
    "bzh"
  ],
  [
    "Bisu",
    "bzi"
  ],
  [
    "Belize Kriol English",
    "bzj"
  ],
  [
    "Nicaragua Creole English",
    "bzk"
  ],
  [
    "Boano (Sulawesi)",
    "bzl"
  ],
  [
    "Bolondo",
    "bzm"
  ],
  [
    "Boano (Maluku)",
    "bzn"
  ],
  [
    "Bozaba",
    "bzo"
  ],
  [
    "Kemberano",
    "bzp"
  ],
  [
    "Buli (Indonesia)",
    "bzq"
  ],
  [
    "Biri",
    "bzr"
  ],
  [
    "Brazilian Sign Language",
    "bzs"
  ],
  [
    "Brithenig",
    "bzt"
  ],
  [
    "Burmeso",
    "bzu"
  ],
  [
    "Naami",
    "bzv"
  ],
  [
    "Basa (Nigeria)",
    "bzw"
  ],
  [
    "Kɛlɛngaxo Bozo",
    "bzx"
  ],
  [
    "Obanliku",
    "bzy"
  ],
  [
    "Evant",
    "bzz"
  ],
  [
    "Chortí",
    "caa"
  ],
  [
    "Garifuna",
    "cab"
  ],
  [
    "Chuj",
    "cac"
  ],
  [
    "Caddo",
    "cad",
    "cad",
    "cad"
  ],
  [
    "Lehar",
    "cae"
  ],
  [
    "Southern Carrier",
    "caf"
  ],
  [
    "Nivaclé",
    "cag"
  ],
  [
    "Cahuarano",
    "cah"
  ],
  [
    "Chané",
    "caj"
  ],
  [
    "Kaqchikel",
    "cak"
  ],
  [
    "Carolinian",
    "cal"
  ],
  [
    "Cemuhî",
    "cam"
  ],
  [
    "Chambri",
    "can"
  ],
  [
    "Chácobo",
    "cao"
  ],
  [
    "Chipaya",
    "cap"
  ],
  [
    "Car Nicobarese",
    "caq"
  ],
  [
    "Galibi Carib",
    "car",
    "car",
    "car"
  ],
  [
    "Tsimané",
    "cas"
  ],
  [
    "Catalan",
    "cat",
    "cat",
    "cat",
    "ca",
    "Català"
  ],
  [
    "Cavineña",
    "cav"
  ],
  [
    "Callawalla",
    "caw"
  ],
  [
    "Chiquitano",
    "cax"
  ],
  [
    "Cayuga",
    "cay"
  ],
  [
    "Canichana",
    "caz"
  ],
  [
    "Cabiyarí",
    "cbb"
  ],
  [
    "Carapana",
    "cbc"
  ],
  [
    "Carijona",
    "cbd"
  ],
  [
    "Chimila",
    "cbg"
  ],
  [
    "Chachi",
    "cbi"
  ],
  [
    "Ede Cabe",
    "cbj"
  ],
  [
    "Chavacano",
    "cbk"
  ],
  [
    "Bualkhaw Chin",
    "cbl"
  ],
  [
    "Nyahkur",
    "cbn"
  ],
  [
    "Izora",
    "cbo"
  ],
  [
    "Tsucuba",
    "cbq"
  ],
  [
    "Cashibo-Cacataibo",
    "cbr"
  ],
  [
    "Cashinahua",
    "cbs"
  ],
  [
    "Chayahuita",
    "cbt"
  ],
  [
    "Candoshi-Shapra",
    "cbu"
  ],
  [
    "Cacua",
    "cbv"
  ],
  [
    "Kinabalian",
    "cbw"
  ],
  [
    "Carabayo",
    "cby"
  ],
  [
    "Chamicuro",
    "ccc"
  ],
  [
    "Cafundo Creole",
    "ccd"
  ],
  [
    "Chopi",
    "cce"
  ],
  [
    "Samba Daka",
    "ccg"
  ],
  [
    "Atsam",
    "cch"
  ],
  [
    "Kasanga",
    "ccj"
  ],
  [
    "Cutchi-Swahili",
    "ccl"
  ],
  [
    "Malaccan Creole Malay",
    "ccm"
  ],
  [
    "Comaltepec Chinantec",
    "cco"
  ],
  [
    "Chakma",
    "ccp"
  ],
  [
    "Cacaopera",
    "ccr"
  ],
  [
    "Choni",
    "cda"
  ],
  [
    "Chenchu",
    "cde"
  ],
  [
    "Chiru",
    "cdf"
  ],
  [
    "Chambeali",
    "cdh"
  ],
  [
    "Chodri",
    "cdi"
  ],
  [
    "Churahi",
    "cdj"
  ],
  [
    "Chepang",
    "cdm"
  ],
  [
    "Chaudangsi",
    "cdn"
  ],
  [
    "Min Dong Chinese",
    "cdo"
  ],
  [
    "Cinda-Regi-Tiyal",
    "cdr"
  ],
  [
    "Chadian Sign Language",
    "cds"
  ],
  [
    "Chadong",
    "cdy"
  ],
  [
    "Koda",
    "cdz"
  ],
  [
    "Lower Chehalis",
    "cea"
  ],
  [
    "Cebuano",
    "ceb",
    "ceb",
    "ceb"
  ],
  [
    "Chamacoco",
    "ceg"
  ],
  [
    "Eastern Khumi Chin",
    "cek"
  ],
  [
    "Cen",
    "cen"
  ],
  [
    "Czech",
    "ces",
    "cze",
    "ces",
    "cs",
    "Česky"
  ],
  [
    "Centúúm",
    "cet"
  ],
  [
    "Ekai Chin",
    "cey"
  ],
  [
    "Dijim-Bwilim",
    "cfa"
  ],
  [
    "Cara",
    "cfd"
  ],
  [
    "Como Karim",
    "cfg"
  ],
  [
    "Falam Chin",
    "cfm"
  ],
  [
    "Changriwa",
    "cga"
  ],
  [
    "Kagayanen",
    "cgc"
  ],
  [
    "Chiga",
    "cgg"
  ],
  [
    "Chocangacakha",
    "cgk"
  ],
  [
    "Chamorro",
    "cha",
    "cha",
    "cha",
    "ch",
    "Chamoru"
  ],
  [
    "Chibcha",
    "chb",
    "chb",
    "chb"
  ],
  [
    "Catawba",
    "chc"
  ],
  [
    "Highland Oaxaca Chontal",
    "chd"
  ],
  [
    "Chechen",
    "che",
    "che",
    "che",
    "ce",
    "Нохчийн"
  ],
  [
    "Tabasco Chontal",
    "chf"
  ],
  [
    "Chagatai",
    "chg",
    "chg",
    "chg"
  ],
  [
    "Chinook",
    "chh"
  ],
  [
    "Ojitlán Chinantec",
    "chj"
  ],
  [
    "Chuukese",
    "chk",
    "chk",
    "chk"
  ],
  [
    "Cahuilla",
    "chl"
  ],
  [
    "Mari (Russia)",
    "chm",
    "chm",
    "chm"
  ],
  [
    "Chinook jargon",
    "chn",
    "chn",
    "chn"
  ],
  [
    "Choctaw",
    "cho",
    "cho",
    "cho"
  ],
  [
    "Chipewyan",
    "chp",
    "chp",
    "chp"
  ],
  [
    "Quiotepec Chinantec",
    "chq"
  ],
  [
    "Cherokee",
    "chr",
    "chr",
    "chr"
  ],
  [
    "Cholón",
    "cht"
  ],
  [
    "Church Slavic",
    "chu",
    "chu",
    "chu",
    "cu",
    "словѣньскъ / slověnĭskŭ"
  ],
  [
    "Chuvash",
    "chv",
    "chv",
    "chv",
    "cv",
    "Чăваш"
  ],
  [
    "Chuwabu",
    "chw"
  ],
  [
    "Chantyal",
    "chx"
  ],
  [
    "Cheyenne",
    "chy",
    "chy",
    "chy"
  ],
  [
    "Ozumacín Chinantec",
    "chz"
  ],
  [
    "Cia-Cia",
    "cia"
  ],
  [
    "Ci Gbe",
    "cib"
  ],
  [
    "Chickasaw",
    "cic"
  ],
  [
    "Chimariko",
    "cid"
  ],
  [
    "Cineni",
    "cie"
  ],
  [
    "Chinali",
    "cih"
  ],
  [
    "Chitkuli Kinnauri",
    "cik"
  ],
  [
    "Cimbrian",
    "cim"
  ],
  [
    "Cinta Larga",
    "cin"
  ],
  [
    "Chiapanec",
    "cip"
  ],
  [
    "Tiri",
    "cir"
  ],
  [
    "Chippewa",
    "ciw"
  ],
  [
    "Chaima",
    "ciy"
  ],
  [
    "Western Cham",
    "cja"
  ],
  [
    "Chru",
    "cje"
  ],
  [
    "Upper Chehalis",
    "cjh"
  ],
  [
    "Chamalal",
    "cji"
  ],
  [
    "Chokwe",
    "cjk"
  ],
  [
    "Eastern Cham",
    "cjm"
  ],
  [
    "Chenapian",
    "cjn"
  ],
  [
    "Ashéninka Pajonal",
    "cjo"
  ],
  [
    "Cabécar",
    "cjp"
  ],
  [
    "Shor",
    "cjs"
  ],
  [
    "Chuave",
    "cjv"
  ],
  [
    "Jinyu Chinese",
    "cjy"
  ],
  [
    "Central Kurdish",
    "ckb"
  ],
  [
    "Chak",
    "ckh"
  ],
  [
    "Cibak",
    "ckl"
  ],
  [
    "Chakavian",
    "ckm"
  ],
  [
    "Kaang Chin",
    "ckn"
  ],
  [
    "Anufo",
    "cko"
  ],
  [
    "Kajakse",
    "ckq"
  ],
  [
    "Kairak",
    "ckr"
  ],
  [
    "Tayo",
    "cks"
  ],
  [
    "Chukot",
    "ckt"
  ],
  [
    "Koasati",
    "cku"
  ],
  [
    "Kavalan",
    "ckv"
  ],
  [
    "Caka",
    "ckx"
  ],
  [
    "Cakfem-Mushere",
    "cky"
  ],
  [
    "Cakchiquel-Quiché Mixed Language",
    "ckz"
  ],
  [
    "Ron",
    "cla"
  ],
  [
    "Chilcotin",
    "clc"
  ],
  [
    "Chaldean Neo-Aramaic",
    "cld"
  ],
  [
    "Lealao Chinantec",
    "cle"
  ],
  [
    "Chilisso",
    "clh"
  ],
  [
    "Chakali",
    "cli"
  ],
  [
    "Laitu Chin",
    "clj"
  ],
  [
    "Idu-Mishmi",
    "clk"
  ],
  [
    "Chala",
    "cll"
  ],
  [
    "Clallam",
    "clm"
  ],
  [
    "Lowland Oaxaca Chontal",
    "clo"
  ],
  [
    "Lautu Chin",
    "clt"
  ],
  [
    "Caluyanun",
    "clu"
  ],
  [
    "Chulym",
    "clw"
  ],
  [
    "Eastern Highland Chatino",
    "cly"
  ],
  [
    "Maa",
    "cma"
  ],
  [
    "Cerma",
    "cme"
  ],
  [
    "Classical Mongolian",
    "cmg"
  ],
  [
    "Emberá-Chamí",
    "cmi"
  ],
  [
    "Campalagian",
    "cml"
  ],
  [
    "Michigamea",
    "cmm"
  ],
  [
    "Mandarin Chinese",
    "cmn"
  ],
  [
    "Central Mnong",
    "cmo"
  ],
  [
    "Mro-Khimi Chin",
    "cmr"
  ],
  [
    "Messapic",
    "cms"
  ],
  [
    "Camtho",
    "cmt"
  ],
  [
    "Changthang",
    "cna"
  ],
  [
    "Chinbon Chin",
    "cnb"
  ],
  [
    "Côông",
    "cnc"
  ],
  [
    "Northern Qiang",
    "cng"
  ],
  [
    "Hakha Chin",
    "cnh"
  ],
  [
    "Asháninka",
    "cni"
  ],
  [
    "Khumi Chin",
    "cnk"
  ],
  [
    "Lalana Chinantec",
    "cnl"
  ],
  [
    "Con",
    "cno"
  ],
  [
    "Northern Ping Chinese",
    "cnp"
  ],
  [
    "Chung",
    "cnq"
  ],
  [
    "Montenegrin",
    "cnr",
    "cnr",
    "cnr"
  ],
  [
    "Central Asmat",
    "cns"
  ],
  [
    "Tepetotutla Chinantec",
    "cnt"
  ],
  [
    "Chenoua",
    "cnu"
  ],
  [
    "Ngawn Chin",
    "cnw"
  ],
  [
    "Middle Cornish",
    "cnx"
  ],
  [
    "Cocos Islands Malay",
    "coa"
  ],
  [
    "Chicomuceltec",
    "cob"
  ],
  [
    "Cocopa",
    "coc"
  ],
  [
    "Cocama-Cocamilla",
    "cod"
  ],
  [
    "Koreguaje",
    "coe"
  ],
  [
    "Colorado",
    "cof"
  ],
  [
    "Chong",
    "cog"
  ],
  [
    "Chonyi-Dzihana-Kauma",
    "coh"
  ],
  [
    "Cochimi",
    "coj"
  ],
  [
    "Santa Teresa Cora",
    "cok"
  ],
  [
    "Columbia-Wenatchi",
    "col"
  ],
  [
    "Comanche",
    "com"
  ],
  [
    "Cofán",
    "con"
  ],
  [
    "Comox",
    "coo"
  ],
  [
    "Coptic",
    "cop",
    "cop",
    "cop"
  ],
  [
    "Coquille",
    "coq"
  ],
  [
    "Cornish",
    "cor",
    "cor",
    "cor",
    "kw",
    "Kernewek"
  ],
  [
    "Corsican",
    "cos",
    "cos",
    "cos",
    "co",
    "Corsu"
  ],
  [
    "Caquinte",
    "cot"
  ],
  [
    "Wamey",
    "cou"
  ],
  [
    "Cao Miao",
    "cov"
  ],
  [
    "Cowlitz",
    "cow"
  ],
  [
    "Nanti",
    "cox"
  ],
  [
    "Chochotec",
    "coz"
  ],
  [
    "Palantla Chinantec",
    "cpa"
  ],
  [
    "Ucayali-Yurúa Ashéninka",
    "cpb"
  ],
  [
    "Ajyíninka Apurucayali",
    "cpc"
  ],
  [
    "Cappadocian Greek",
    "cpg"
  ],
  [
    "Chinese Pidgin English",
    "cpi"
  ],
  [
    "Cherepon",
    "cpn"
  ],
  [
    "Kpeego",
    "cpo"
  ],
  [
    "Capiznon",
    "cps"
  ],
  [
    "Pichis Ashéninka",
    "cpu"
  ],
  [
    "Pu-Xian Chinese",
    "cpx"
  ],
  [
    "South Ucayali Ashéninka",
    "cpy"
  ],
  [
    "Chuanqiandian Cluster Miao",
    "cqd"
  ],
  [
    "Chara",
    "cra"
  ],
  [
    "Island Carib",
    "crb"
  ],
  [
    "Lonwolwol",
    "crc"
  ],
  [
    "Coeur d'Alene",
    "crd"
  ],
  [
    "Cree",
    "cre",
    "cre",
    "cre",
    "cr",
    "Nehiyaw"
  ],
  [
    "Caramanta",
    "crf"
  ],
  [
    "Michif",
    "crg"
  ],
  [
    "Crimean Tatar",
    "crh",
    "crh",
    "crh"
  ],
  [
    "Sãotomense",
    "cri"
  ],
  [
    "Southern East Cree",
    "crj"
  ],
  [
    "Plains Cree",
    "crk"
  ],
  [
    "Northern East Cree",
    "crl"
  ],
  [
    "Moose Cree",
    "crm"
  ],
  [
    "El Nayar Cora",
    "crn"
  ],
  [
    "Crow",
    "cro"
  ],
  [
    "Iyo'wujwa Chorote",
    "crq"
  ],
  [
    "Carolina Algonquian",
    "crr"
  ],
  [
    "Seselwa Creole French",
    "crs"
  ],
  [
    "Iyojwa'ja Chorote",
    "crt"
  ],
  [
    "Chaura",
    "crv"
  ],
  [
    "Chrau",
    "crw"
  ],
  [
    "Carrier",
    "crx"
  ],
  [
    "Cori",
    "cry"
  ],
  [
    "Cruzeño",
    "crz"
  ],
  [
    "Chiltepec Chinantec",
    "csa"
  ],
  [
    "Kashubian",
    "csb",
    "csb",
    "csb"
  ],
  [
    "Catalan Sign Language",
    "csc"
  ],
  [
    "Chiangmai Sign Language",
    "csd"
  ],
  [
    "Czech Sign Language",
    "cse"
  ],
  [
    "Cuba Sign Language",
    "csf"
  ],
  [
    "Chilean Sign Language",
    "csg"
  ],
  [
    "Asho Chin",
    "csh"
  ],
  [
    "Coast Miwok",
    "csi"
  ],
  [
    "Songlai Chin",
    "csj"
  ],
  [
    "Jola-Kasa",
    "csk"
  ],
  [
    "Chinese Sign Language",
    "csl"
  ],
  [
    "Central Sierra Miwok",
    "csm"
  ],
  [
    "Colombian Sign Language",
    "csn"
  ],
  [
    "Sochiapam Chinantec",
    "cso"
  ],
  [
    "Southern Ping Chinese",
    "csp"
  ],
  [
    "Croatia Sign Language",
    "csq"
  ],
  [
    "Costa Rican Sign Language",
    "csr"
  ],
  [
    "Southern Ohlone",
    "css"
  ],
  [
    "Northern Ohlone",
    "cst"
  ],
  [
    "Sumtu Chin",
    "csv"
  ],
  [
    "Swampy Cree",
    "csw"
  ],
  [
    "Cambodian Sign Language",
    "csx"
  ],
  [
    "Siyin Chin",
    "csy"
  ],
  [
    "Coos",
    "csz"
  ],
  [
    "Tataltepec Chatino",
    "cta"
  ],
  [
    "Chetco",
    "ctc"
  ],
  [
    "Tedim Chin",
    "ctd"
  ],
  [
    "Tepinapa Chinantec",
    "cte"
  ],
  [
    "Chittagonian",
    "ctg"
  ],
  [
    "Thaiphum Chin",
    "cth"
  ],
  [
    "Tlacoatzintepec Chinantec",
    "ctl"
  ],
  [
    "Chitimacha",
    "ctm"
  ],
  [
    "Chhintange",
    "ctn"
  ],
  [
    "Emberá-Catío",
    "cto"
  ],
  [
    "Western Highland Chatino",
    "ctp"
  ],
  [
    "Northern Catanduanes Bikol",
    "cts"
  ],
  [
    "Wayanad Chetti",
    "ctt"
  ],
  [
    "Chol",
    "ctu"
  ],
  [
    "Moundadan Chetty",
    "cty"
  ],
  [
    "Zacatepec Chatino",
    "ctz"
  ],
  [
    "Cua",
    "cua"
  ],
  [
    "Cubeo",
    "cub"
  ],
  [
    "Usila Chinantec",
    "cuc"
  ],
  [
    "Chuka",
    "cuh"
  ],
  [
    "Cuiba",
    "cui"
  ],
  [
    "Mashco Piro",
    "cuj"
  ],
  [
    "San Blas Kuna",
    "cuk"
  ],
  [
    "Culina",
    "cul"
  ],
  [
    "Cumanagoto",
    "cuo"
  ],
  [
    "Cupeño",
    "cup"
  ],
  [
    "Cun",
    "cuq"
  ],
  [
    "Chhulung",
    "cur"
  ],
  [
    "Teutila Cuicatec",
    "cut"
  ],
  [
    "Tai Ya",
    "cuu"
  ],
  [
    "Cuvok",
    "cuv"
  ],
  [
    "Chukwa",
    "cuw"
  ],
  [
    "Tepeuxila Cuicatec",
    "cux"
  ],
  [
    "Cuitlatec",
    "cuy"
  ],
  [
    "Chug",
    "cvg"
  ],
  [
    "Valle Nacional Chinantec",
    "cvn"
  ],
  [
    "Kabwa",
    "cwa"
  ],
  [
    "Maindo",
    "cwb"
  ],
  [
    "Woods Cree",
    "cwd"
  ],
  [
    "Kwere",
    "cwe"
  ],
  [
    "Chewong",
    "cwg"
  ],
  [
    "Kuwaataay",
    "cwt"
  ],
  [
    "Nopala Chatino",
    "cya"
  ],
  [
    "Cayubaba",
    "cyb"
  ],
  [
    "Welsh",
    "cym",
    "wel",
    "cym",
    "cy",
    "Cymraeg"
  ],
  [
    "Cuyonon",
    "cyo"
  ],
  [
    "Huizhou Chinese",
    "czh"
  ],
  [
    "Knaanic",
    "czk"
  ],
  [
    "Zenzontepec Chatino",
    "czn"
  ],
  [
    "Min Zhong Chinese",
    "czo"
  ],
  [
    "Zotung Chin",
    "czt"
  ],
  [
    "Dangaléat",
    "daa"
  ],
  [
    "Dambi",
    "dac"
  ],
  [
    "Marik",
    "dad"
  ],
  [
    "Duupa",
    "dae"
  ],
  [
    "Dagbani",
    "dag"
  ],
  [
    "Gwahatike",
    "dah"
  ],
  [
    "Day",
    "dai"
  ],
  [
    "Dar Fur Daju",
    "daj"
  ],
  [
    "Dakota",
    "dak",
    "dak",
    "dak"
  ],
  [
    "Dahalo",
    "dal"
  ],
  [
    "Damakawa",
    "dam"
  ],
  [
    "Danish",
    "dan",
    "dan",
    "dan",
    "da",
    "Dansk"
  ],
  [
    "Daai Chin",
    "dao"
  ],
  [
    "Dandami Maria",
    "daq"
  ],
  [
    "Dargwa",
    "dar",
    "dar",
    "dar"
  ],
  [
    "Daho-Doo",
    "das"
  ],
  [
    "Dar Sila Daju",
    "dau"
  ],
  [
    "Taita",
    "dav"
  ],
  [
    "Davawenyo",
    "daw"
  ],
  [
    "Dayi",
    "dax"
  ],
  [
    "Dao",
    "daz"
  ],
  [
    "Bangime",
    "dba"
  ],
  [
    "Deno",
    "dbb"
  ],
  [
    "Dadiya",
    "dbd"
  ],
  [
    "Dabe",
    "dbe"
  ],
  [
    "Edopi",
    "dbf"
  ],
  [
    "Dogul Dom Dogon",
    "dbg"
  ],
  [
    "Doka",
    "dbi"
  ],
  [
    "Ida'an",
    "dbj"
  ],
  [
    "Dyirbal",
    "dbl"
  ],
  [
    "Duguri",
    "dbm"
  ],
  [
    "Duriankere",
    "dbn"
  ],
  [
    "Dulbu",
    "dbo"
  ],
  [
    "Duwai",
    "dbp"
  ],
  [
    "Daba",
    "dbq"
  ],
  [
    "Dabarre",
    "dbr"
  ],
  [
    "Ben Tey Dogon",
    "dbt"
  ],
  [
    "Bondum Dom Dogon",
    "dbu"
  ],
  [
    "Dungu",
    "dbv"
  ],
  [
    "Bankan Tey Dogon",
    "dbw"
  ],
  [
    "Dibiyaso",
    "dby"
  ],
  [
    "Deccan",
    "dcc"
  ],
  [
    "Negerhollands",
    "dcr"
  ],
  [
    "Dadi Dadi",
    "dda"
  ],
  [
    "Dongotono",
    "ddd"
  ],
  [
    "Doondo",
    "dde"
  ],
  [
    "Fataluku",
    "ddg"
  ],
  [
    "West Goodenough",
    "ddi"
  ],
  [
    "Jaru",
    "ddj"
  ],
  [
    "Dendi (Benin)",
    "ddn"
  ],
  [
    "Dido",
    "ddo"
  ],
  [
    "Dhudhuroa",
    "ddr"
  ],
  [
    "Donno So Dogon",
    "dds"
  ],
  [
    "Dawera-Daweloor",
    "ddw"
  ],
  [
    "Dagik",
    "dec"
  ],
  [
    "Dedua",
    "ded"
  ],
  [
    "Dewoin",
    "dee"
  ],
  [
    "Dezfuli",
    "def"
  ],
  [
    "Degema",
    "deg"
  ],
  [
    "Dehwari",
    "deh"
  ],
  [
    "Demisa",
    "dei"
  ],
  [
    "Dek",
    "dek"
  ],
  [
    "Delaware",
    "del",
    "del",
    "del"
  ],
  [
    "Dem",
    "dem"
  ],
  [
    "Slave (Athapascan)",
    "den",
    "den",
    "den"
  ],
  [
    "Pidgin Delaware",
    "dep"
  ],
  [
    "Dendi (Central African Republic)",
    "deq"
  ],
  [
    "Deori",
    "der"
  ],
  [
    "Desano",
    "des"
  ],
  [
    "German",
    "deu",
    "ger",
    "deu",
    "de",
    "Deutsch",
    ["#infinitive-construct", "#infinitive", "#participle#3rd#singular", "#present#3rd#singular"]
  ],
  [
    "Domung",
    "dev"
  ],
  [
    "Dengese",
    "dez"
  ],
  [
    "Southern Dagaare",
    "dga"
  ],
  [
    "Bunoge Dogon",
    "dgb"
  ],
  [
    "Casiguran Dumagat Agta",
    "dgc"
  ],
  [
    "Dagaari Dioula",
    "dgd"
  ],
  [
    "Degenan",
    "dge"
  ],
  [
    "Doga",
    "dgg"
  ],
  [
    "Dghwede",
    "dgh"
  ],
  [
    "Northern Dagara",
    "dgi"
  ],
  [
    "Dagba",
    "dgk"
  ],
  [
    "Andaandi",
    "dgl"
  ],
  [
    "Dagoman",
    "dgn"
  ],
  [
    "Dogri (individual language)",
    "dgo"
  ],
  [
    "Dogrib",
    "dgr",
    "dgr",
    "dgr"
  ],
  [
    "Dogoso",
    "dgs"
  ],
  [
    "Ndra'ngith",
    "dgt"
  ],
  [
    "Daungwurrung",
    "dgw"
  ],
  [
    "Doghoro",
    "dgx"
  ],
  [
    "Daga",
    "dgz"
  ],
  [
    "Dhundari",
    "dhd"
  ],
  [
    "Dhangu-Djangu",
    "dhg"
  ],
  [
    "Dhimal",
    "dhi"
  ],
  [
    "Dhalandji",
    "dhl"
  ],
  [
    "Zemba",
    "dhm"
  ],
  [
    "Dhanki",
    "dhn"
  ],
  [
    "Dhodia",
    "dho"
  ],
  [
    "Dhargari",
    "dhr"
  ],
  [
    "Dhaiso",
    "dhs"
  ],
  [
    "Dhurga",
    "dhu"
  ],
  [
    "Dehu",
    "dhv"
  ],
  [
    "Dhanwar (Nepal)",
    "dhw"
  ],
  [
    "Dhungaloo",
    "dhx"
  ],
  [
    "Dia",
    "dia"
  ],
  [
    "South Central Dinka",
    "dib"
  ],
  [
    "Lakota Dida",
    "dic"
  ],
  [
    "Didinga",
    "did"
  ],
  [
    "Dieri",
    "dif"
  ],
  [
    "Digo",
    "dig"
  ],
  [
    "Kumiai",
    "dih"
  ],
  [
    "Dimbong",
    "dii"
  ],
  [
    "Dai",
    "dij"
  ],
  [
    "Southwestern Dinka",
    "dik"
  ],
  [
    "Dilling",
    "dil"
  ],
  [
    "Dime",
    "dim"
  ],
  [
    "Dinka",
    "din",
    "din",
    "din"
  ],
  [
    "Dibo",
    "dio"
  ],
  [
    "Northeastern Dinka",
    "dip"
  ],
  [
    "Dimli (individual language)",
    "diq"
  ],
  [
    "Dirim",
    "dir"
  ],
  [
    "Dimasa",
    "dis"
  ],
  [
    "Diriku",
    "diu"
  ],
  [
    "Dhivehi",
    "div",
    "div",
    "div",
    "dv",
    "ދިވެހިބަސް"
  ],
  [
    "Northwestern Dinka",
    "diw"
  ],
  [
    "Dixon Reef",
    "dix"
  ],
  [
    "Diuwe",
    "diy"
  ],
  [
    "Ding",
    "diz"
  ],
  [
    "Djadjawurrung",
    "dja"
  ],
  [
    "Djinba",
    "djb"
  ],
  [
    "Dar Daju Daju",
    "djc"
  ],
  [
    "Djamindjung",
    "djd"
  ],
  [
    "Zarma",
    "dje"
  ],
  [
    "Djangun",
    "djf"
  ],
  [
    "Djinang",
    "dji"
  ],
  [
    "Djeebbana",
    "djj"
  ],
  [
    "Eastern Maroon Creole",
    "djk"
  ],
  [
    "Jamsay Dogon",
    "djm"
  ],
  [
    "Jawoyn",
    "djn"
  ],
  [
    "Jangkang",
    "djo"
  ],
  [
    "Djambarrpuyngu",
    "djr"
  ],
  [
    "Kapriman",
    "dju"
  ],
  [
    "Djawi",
    "djw"
  ],
  [
    "Dakpakha",
    "dka"
  ],
  [
    "Kadung",
    "dkg"
  ],
  [
    "Dakka",
    "dkk"
  ],
  [
    "Kuijau",
    "dkr"
  ],
  [
    "Southeastern Dinka",
    "dks"
  ],
  [
    "Mazagway",
    "dkx"
  ],
  [
    "Dolgan",
    "dlg"
  ],
  [
    "Dahalik",
    "dlk"
  ],
  [
    "Dalmatian",
    "dlm"
  ],
  [
    "Darlong",
    "dln"
  ],
  [
    "Duma",
    "dma"
  ],
  [
    "Mombo Dogon",
    "dmb"
  ],
  [
    "Gavak",
    "dmc"
  ],
  [
    "Madhi Madhi",
    "dmd"
  ],
  [
    "Dugwor",
    "dme"
  ],
  [
    "Medefaidrin",
    "dmf"
  ],
  [
    "Upper Kinabatangan",
    "dmg"
  ],
  [
    "Domaaki",
    "dmk"
  ],
  [
    "Dameli",
    "dml"
  ],
  [
    "Dama",
    "dmm"
  ],
  [
    "Kemedzung",
    "dmo"
  ],
  [
    "East Damar",
    "dmr"
  ],
  [
    "Dampelas",
    "dms"
  ],
  [
    "Dubu",
    "dmu"
  ],
  [
    "Dumpas",
    "dmv"
  ],
  [
    "Mudburra",
    "dmw"
  ],
  [
    "Dema",
    "dmx"
  ],
  [
    "Demta",
    "dmy"
  ],
  [
    "Upper Grand Valley Dani",
    "dna"
  ],
  [
    "Daonda",
    "dnd"
  ],
  [
    "Ndendeule",
    "dne"
  ],
  [
    "Dungan",
    "dng"
  ],
  [
    "Lower Grand Valley Dani",
    "dni"
  ],
  [
    "Dan",
    "dnj"
  ],
  [
    "Dengka",
    "dnk"
  ],
  [
    "Dzùùngoo",
    "dnn"
  ],
  [
    "Ndrulo",
    "dno"
  ],
  [
    "Danaru",
    "dnr"
  ],
  [
    "Mid Grand Valley Dani",
    "dnt"
  ],
  [
    "Danau",
    "dnu"
  ],
  [
    "Danu",
    "dnv"
  ],
  [
    "Western Dani",
    "dnw"
  ],
  [
    "Dení",
    "dny"
  ],
  [
    "Dom",
    "doa"
  ],
  [
    "Dobu",
    "dob"
  ],
  [
    "Northern Dong",
    "doc"
  ],
  [
    "Doe",
    "doe"
  ],
  [
    "Domu",
    "dof"
  ],
  [
    "Dong",
    "doh"
  ],
  [
    "Dogri (macrolanguage)",
    "doi",
    "doi",
    "doi"
  ],
  [
    "Dondo",
    "dok"
  ],
  [
    "Doso",
    "dol"
  ],
  [
    "Toura (Papua New Guinea)",
    "don"
  ],
  [
    "Dongo",
    "doo"
  ],
  [
    "Lukpa",
    "dop"
  ],
  [
    "Dominican Sign Language",
    "doq"
  ],
  [
    "Dori'o",
    "dor"
  ],
  [
    "Dogosé",
    "dos"
  ],
  [
    "Dass",
    "dot"
  ],
  [
    "Dombe",
    "dov"
  ],
  [
    "Doyayo",
    "dow"
  ],
  [
    "Bussa",
    "dox"
  ],
  [
    "Dompo",
    "doy"
  ],
  [
    "Dorze",
    "doz"
  ],
  [
    "Papar",
    "dpp"
  ],
  [
    "Dair",
    "drb"
  ],
  [
    "Minderico",
    "drc"
  ],
  [
    "Darmiya",
    "drd"
  ],
  [
    "Dolpo",
    "dre"
  ],
  [
    "Rungus",
    "drg"
  ],
  [
    "C'Lela",
    "dri"
  ],
  [
    "Paakantyi",
    "drl"
  ],
  [
    "West Damar",
    "drn"
  ],
  [
    "Daro-Matu Melanau",
    "dro"
  ],
  [
    "Dura",
    "drq"
  ],
  [
    "Gedeo",
    "drs"
  ],
  [
    "Drents",
    "drt"
  ],
  [
    "Rukai",
    "dru"
  ],
  [
    "Darai",
    "dry"
  ],
  [
    "Lower Sorbian",
    "dsb",
    "dsb",
    "dsb"
  ],
  [
    "Dutch Sign Language",
    "dse"
  ],
  [
    "Daasanach",
    "dsh"
  ],
  [
    "Disa",
    "dsi"
  ],
  [
    "Danish Sign Language",
    "dsl"
  ],
  [
    "Dusner",
    "dsn"
  ],
  [
    "Desiya",
    "dso"
  ],
  [
    "Tadaksahak",
    "dsq"
  ],
  [
    "Mardin Sign Language",
    "dsz"
  ],
  [
    "Daur",
    "dta"
  ],
  [
    "Labuk-Kinabatangan Kadazan",
    "dtb"
  ],
  [
    "Ditidaht",
    "dtd"
  ],
  [
    "Adithinngithigh",
    "dth"
  ],
  [
    "Ana Tinga Dogon",
    "dti"
  ],
  [
    "Tene Kan Dogon",
    "dtk"
  ],
  [
    "Tomo Kan Dogon",
    "dtm"
  ],
  [
    "Daatsʼíin",
    "dtn"
  ],
  [
    "Tommo So Dogon",
    "dto"
  ],
  [
    "Kadazan Dusun",
    "dtp"
  ],
  [
    "Lotud",
    "dtr"
  ],
  [
    "Toro So Dogon",
    "dts"
  ],
  [
    "Toro Tegu Dogon",
    "dtt"
  ],
  [
    "Tebul Ure Dogon",
    "dtu"
  ],
  [
    "Dotyali",
    "dty"
  ],
  [
    "Duala",
    "dua",
    "dua",
    "dua"
  ],
  [
    "Dubli",
    "dub"
  ],
  [
    "Duna",
    "duc"
  ],
  [
    "Umiray Dumaget Agta",
    "due"
  ],
  [
    "Dumbea",
    "duf"
  ],
  [
    "Duruma",
    "dug"
  ],
  [
    "Dungra Bhil",
    "duh"
  ],
  [
    "Dumun",
    "dui"
  ],
  [
    "Uyajitaya",
    "duk"
  ],
  [
    "Alabat Island Agta",
    "dul"
  ],
  [
    "Middle Dutch (ca. 1050-1350)",
    "dum",
    "dum",
    "dum"
  ],
  [
    "Dusun Deyah",
    "dun"
  ],
  [
    "Dupaninan Agta",
    "duo"
  ],
  [
    "Duano",
    "dup"
  ],
  [
    "Dusun Malang",
    "duq"
  ],
  [
    "Dii",
    "dur"
  ],
  [
    "Dumi",
    "dus"
  ],
  [
    "Drung",
    "duu"
  ],
  [
    "Duvle",
    "duv"
  ],
  [
    "Dusun Witu",
    "duw"
  ],
  [
    "Duungooma",
    "dux"
  ],
  [
    "Dicamay Agta",
    "duy"
  ],
  [
    "Duli-Gey",
    "duz"
  ],
  [
    "Duau",
    "dva"
  ],
  [
    "Diri",
    "dwa"
  ],
  [
    "Dawik Kui",
    "dwk"
  ],
  [
    "Dawro",
    "dwr"
  ],
  [
    "Dutton World Speedwords",
    "dws"
  ],
  [
    "Dhuwal",
    "dwu"
  ],
  [
    "Dawawa",
    "dww"
  ],
  [
    "Dhuwaya",
    "dwy"
  ],
  [
    "Dewas Rai",
    "dwz"
  ],
  [
    "Dyan",
    "dya"
  ],
  [
    "Dyaberdyaber",
    "dyb"
  ],
  [
    "Dyugun",
    "dyd"
  ],
  [
    "Villa Viciosa Agta",
    "dyg"
  ],
  [
    "Djimini Senoufo",
    "dyi"
  ],
  [
    "Yanda Dom Dogon",
    "dym"
  ],
  [
    "Dyangadi",
    "dyn"
  ],
  [
    "Jola-Fonyi",
    "dyo"
  ],
  [
    "Dyula",
    "dyu",
    "dyu",
    "dyu"
  ],
  [
    "Djabugay",
    "dyy"
  ],
  [
    "Tunzu",
    "dza"
  ],
  [
    "Djiwarli",
    "dze"
  ],
  [
    "Dazaga",
    "dzg"
  ],
  [
    "Dzalakha",
    "dzl"
  ],
  [
    "Dzando",
    "dzn"
  ],
  [
    "Dzongkha",
    "dzo",
    "dzo",
    "dzo",
    "dz",
    "ཇོང་ཁ"
  ],
  [
    "Karenggapa",
    "eaa"
  ],
  [
    "Beginci",
    "ebc"
  ],
  [
    "Ebughu",
    "ebg"
  ],
  [
    "Eastern Bontok",
    "ebk"
  ],
  [
    "Teke-Ebo",
    "ebo"
  ],
  [
    "Ebrié",
    "ebr"
  ],
  [
    "Embu",
    "ebu"
  ],
  [
    "Eteocretan",
    "ecr"
  ],
  [
    "Ecuadorian Sign Language",
    "ecs"
  ],
  [
    "Eteocypriot",
    "ecy"
  ],
  [
    "E",
    "eee"
  ],
  [
    "Efai",
    "efa"
  ],
  [
    "Efe",
    "efe"
  ],
  [
    "Efik",
    "efi",
    "efi",
    "efi"
  ],
  [
    "Ega",
    "ega"
  ],
  [
    "Emilian",
    "egl"
  ],
  [
    "Benamanga",
    "egm"
  ],
  [
    "Eggon",
    "ego"
  ],
  [
    "Egyptian (Ancient)",
    "egy",
    "egy",
    "egy"
  ],
  [
    "Miyakubo Sign Language",
    "ehs"
  ],
  [
    "Ehueun",
    "ehu"
  ],
  [
    "Eipomek",
    "eip"
  ],
  [
    "Eitiep",
    "eit"
  ],
  [
    "Askopan",
    "eiv"
  ],
  [
    "Ejamat",
    "eja"
  ],
  [
    "Ekajuk",
    "eka",
    "eka",
    "eka"
  ],
  [
    "Ekit",
    "eke"
  ],
  [
    "Ekari",
    "ekg"
  ],
  [
    "Eki",
    "eki"
  ],
  [
    "Standard Estonian",
    "ekk"
  ],
  [
    "Kol (Bangladesh)",
    "ekl"
  ],
  [
    "Elip",
    "ekm"
  ],
  [
    "Koti",
    "eko"
  ],
  [
    "Ekpeye",
    "ekp"
  ],
  [
    "Yace",
    "ekr"
  ],
  [
    "Eastern Kayah",
    "eky"
  ],
  [
    "Elepi",
    "ele"
  ],
  [
    "El Hugeirat",
    "elh"
  ],
  [
    "Nding",
    "eli"
  ],
  [
    "Elkei",
    "elk"
  ],
  [
    "Modern Greek (1453-)",
    "ell",
    "gre",
    "ell",
    "el",
    "Ελληνικά"
  ],
  [
    "Eleme",
    "elm"
  ],
  [
    "El Molo",
    "elo"
  ],
  [
    "Elu",
    "elu"
  ],
  [
    "Elamite",
    "elx",
    "elx",
    "elx"
  ],
  [
    "Emai-Iuleha-Ora",
    "ema"
  ],
  [
    "Embaloh",
    "emb"
  ],
  [
    "Emerillon",
    "eme"
  ],
  [
    "Eastern Meohang",
    "emg"
  ],
  [
    "Mussau-Emira",
    "emi"
  ],
  [
    "Eastern Maninkakan",
    "emk"
  ],
  [
    "Mamulique",
    "emm"
  ],
  [
    "Eman",
    "emn"
  ],
  [
    "Northern Emberá",
    "emp"
  ],
  [
    "Eastern Minyag",
    "emq"
  ],
  [
    "Pacific Gulf Yupik",
    "ems"
  ],
  [
    "Eastern Muria",
    "emu"
  ],
  [
    "Emplawas",
    "emw"
  ],
  [
    "Erromintxela",
    "emx"
  ],
  [
    "Epigraphic Mayan",
    "emy"
  ],
  [
    "Mbessa",
    "emz"
  ],
  [
    "Apali",
    "ena"
  ],
  [
    "Markweeta",
    "enb"
  ],
  [
    "En",
    "enc"
  ],
  [
    "Ende",
    "end"
  ],
  [
    "Forest Enets",
    "enf"
  ],
  [
    "English",
    "eng",
    "eng",
    "eng",
    "en",
    "English"
  ],
  [
    "Tundra Enets",
    "enh"
  ],
  [
    "Enlhet",
    "enl"
  ],
  [
    "Middle English (1100-1500)",
    "enm",
    "enm",
    "enm"
  ],
  [
    "Engenni",
    "enn"
  ],
  [
    "Enggano",
    "eno"
  ],
  [
    "Enga",
    "enq"
  ],
  [
    "Emumu",
    "enr"
  ],
  [
    "Enu",
    "enu"
  ],
  [
    "Enwan (Edo State)",
    "env"
  ],
  [
    "Enwan (Akwa Ibom State)",
    "enw"
  ],
  [
    "Enxet",
    "enx"
  ],
  [
    "Beti (Côte d'Ivoire)",
    "eot"
  ],
  [
    "Epie",
    "epi"
  ],
  [
    "Esperanto",
    "epo",
    "epo",
    "epo",
    "eo",
    "Esperanto"
  ],
  [
    "Eravallan",
    "era"
  ],
  [
    "Sie",
    "erg"
  ],
  [
    "Eruwa",
    "erh"
  ],
  [
    "Ogea",
    "eri"
  ],
  [
    "South Efate",
    "erk"
  ],
  [
    "Horpa",
    "ero"
  ],
  [
    "Erre",
    "err"
  ],
  [
    "Ersu",
    "ers"
  ],
  [
    "Eritai",
    "ert"
  ],
  [
    "Erokwanas",
    "erw"
  ],
  [
    "Ese Ejja",
    "ese"
  ],
  [
    "Aheri Gondi",
    "esg"
  ],
  [
    "Eshtehardi",
    "esh"
  ],
  [
    "North Alaskan Inupiatun",
    "esi"
  ],
  [
    "Northwest Alaska Inupiatun",
    "esk"
  ],
  [
    "Egypt Sign Language",
    "esl"
  ],
  [
    "Esuma",
    "esm"
  ],
  [
    "Salvadoran Sign Language",
    "esn"
  ],
  [
    "Estonian Sign Language",
    "eso"
  ],
  [
    "Esselen",
    "esq"
  ],
  [
    "Central Siberian Yupik",
    "ess"
  ],
  [
    "Estonian",
    "est",
    "est",
    "est",
    "et",
    "Eesti"
  ],
  [
    "Central Yupik",
    "esu"
  ],
  [
    "Eskayan",
    "esy"
  ],
  [
    "Etebi",
    "etb"
  ],
  [
    "Etchemin",
    "etc"
  ],
  [
    "Ethiopian Sign Language",
    "eth"
  ],
  [
    "Eton (Vanuatu)",
    "etn"
  ],
  [
    "Eton (Cameroon)",
    "eto"
  ],
  [
    "Edolo",
    "etr"
  ],
  [
    "Yekhee",
    "ets"
  ],
  [
    "Etruscan",
    "ett"
  ],
  [
    "Ejagham",
    "etu"
  ],
  [
    "Eten",
    "etx"
  ],
  [
    "Semimi",
    "etz"
  ],
  [
    "Basque",
    "eus",
    "baq",
    "eus",
    "eu",
    "Euskara"
  ],
  [
    "Even",
    "eve"
  ],
  [
    "Uvbie",
    "evh"
  ],
  [
    "Evenki",
    "evn"
  ],
  [
    "Ewe",
    "ewe",
    "ewe",
    "ewe",
    "ee",
    "Ɛʋɛ"
  ],
  [
    "Ewondo",
    "ewo",
    "ewo",
    "ewo"
  ],
  [
    "Extremaduran",
    "ext"
  ],
  [
    "Eyak",
    "eya"
  ],
  [
    "Keiyo",
    "eyo"
  ],
  [
    "Ezaa",
    "eza"
  ],
  [
    "Uzekwe",
    "eze"
  ],
  [
    "Fasu",
    "faa"
  ],
  [
    "Fa d'Ambu",
    "fab"
  ],
  [
    "Wagi",
    "fad"
  ],
  [
    "Fagani",
    "faf"
  ],
  [
    "Finongan",
    "fag"
  ],
  [
    "Baissa Fali",
    "fah"
  ],
  [
    "Faiwol",
    "fai"
  ],
  [
    "Faita",
    "faj"
  ],
  [
    "Fang (Cameroon)",
    "fak"
  ],
  [
    "South Fali",
    "fal"
  ],
  [
    "Fam",
    "fam"
  ],
  [
    "Fang (Equatorial Guinea)",
    "fan",
    "fan",
    "fan"
  ],
  [
    "Faroese",
    "fao",
    "fao",
    "fao",
    "fo",
    "Føroyskt"
  ],
  [
    "Paloor",
    "fap"
  ],
  [
    "Fataleka",
    "far"
  ],
  [
    "Persian",
    "fas",
    "per",
    "fas",
    "fa",
    "فارسی"
  ],
  [
    "Fanti",
    "fat",
    "fat",
    "fat"
  ],
  [
    "Fayu",
    "fau"
  ],
  [
    "Fala",
    "fax"
  ],
  [
    "Southwestern Fars",
    "fay"
  ],
  [
    "Northwestern Fars",
    "faz"
  ],
  [
    "West Albay Bikol",
    "fbl"
  ],
  [
    "Quebec Sign Language",
    "fcs"
  ],
  [
    "Feroge",
    "fer"
  ],
  [
    "Foia Foia",
    "ffi"
  ],
  [
    "Maasina Fulfulde",
    "ffm"
  ],
  [
    "Fongoro",
    "fgr"
  ],
  [
    "Nobiin",
    "fia"
  ],
  [
    "Fyer",
    "fie"
  ],
  [
    "Faifi",
    "fif"
  ],
  [
    "Fijian",
    "fij",
    "fij",
    "fij",
    "fj",
    "Na Vosa Vakaviti"
  ],
  [
    "Filipino",
    "fil",
    "fil",
    "fil"
  ],
  [
    "Finnish",
    "fin",
    "fin",
    "fin",
    "fi",
    "Suomi"
  ],
  [
    "Fipa",
    "fip"
  ],
  [
    "Firan",
    "fir"
  ],
  [
    "Tornedalen Finnish",
    "fit"
  ],
  [
    "Fiwaga",
    "fiw"
  ],
  [
    "Kirya-Konzəl",
    "fkk"
  ],
  [
    "Kven Finnish",
    "fkv"
  ],
  [
    "Kalispel-Pend d'Oreille",
    "fla"
  ],
  [
    "Foau",
    "flh"
  ],
  [
    "Fali",
    "fli"
  ],
  [
    "North Fali",
    "fll"
  ],
  [
    "Flinders Island",
    "fln"
  ],
  [
    "Fuliiru",
    "flr"
  ],
  [
    "Flaaitaal",
    "fly"
  ],
  [
    "Fe'fe'",
    "fmp"
  ],
  [
    "Far Western Muria",
    "fmu"
  ],
  [
    "Fanbak",
    "fnb"
  ],
  [
    "Fanagalo",
    "fng"
  ],
  [
    "Fania",
    "fni"
  ],
  [
    "Foodo",
    "fod"
  ],
  [
    "Foi",
    "foi"
  ],
  [
    "Foma",
    "fom"
  ],
  [
    "Fon",
    "fon",
    "fon",
    "fon"
  ],
  [
    "Fore",
    "for"
  ],
  [
    "Siraya",
    "fos"
  ],
  [
    "Fernando Po Creole English",
    "fpe"
  ],
  [
    "Fas",
    "fqs"
  ],
  [
    "French",
    "fra",
    "fre",
    "fra",
    "fr",
    "Français",
    ["#infinitive-construct", "#infinitive", "#participle#3rd#singular", "#present#3rd#singular"]
  ],
  [
    "Cajun French",
    "frc"
  ],
  [
    "Fordata",
    "frd"
  ],
  [
    "Frankish",
    "frk"
  ],
  [
    "Middle French (ca. 1400-1600)",
    "frm",
    "frm",
    "frm"
  ],
  [
    "Old French (842-ca. 1400)",
    "fro",
    "fro",
    "fro"
  ],
  [
    "Arpitan",
    "frp"
  ],
  [
    "Forak",
    "frq"
  ],
  [
    "Northern Frisian",
    "frr",
    "frr",
    "frr"
  ],
  [
    "Eastern Frisian",
    "frs",
    "frs",
    "frs"
  ],
  [
    "Fortsenal",
    "frt"
  ],
  [
    "Western Frisian",
    "fry",
    "fry",
    "fry",
    "fy",
    "Frysk"
  ],
  [
    "Finnish Sign Language",
    "fse"
  ],
  [
    "French Sign Language",
    "fsl"
  ],
  [
    "Finland-Swedish Sign Language",
    "fss"
  ],
  [
    "Adamawa Fulfulde",
    "fub"
  ],
  [
    "Pulaar",
    "fuc"
  ],
  [
    "East Futuna",
    "fud"
  ],
  [
    "Borgu Fulfulde",
    "fue"
  ],
  [
    "Pular",
    "fuf"
  ],
  [
    "Western Niger Fulfulde",
    "fuh"
  ],
  [
    "Bagirmi Fulfulde",
    "fui"
  ],
  [
    "Ko",
    "fuj"
  ],
  [
    "Fulah",
    "ful",
    "ful",
    "ful",
    "ff",
    "Fulfulde"
  ],
  [
    "Fum",
    "fum"
  ],
  [
    "Fulniô",
    "fun"
  ],
  [
    "Central-Eastern Niger Fulfulde",
    "fuq"
  ],
  [
    "Friulian",
    "fur",
    "fur",
    "fur"
  ],
  [
    "Futuna-Aniwa",
    "fut"
  ],
  [
    "Furu",
    "fuu"
  ],
  [
    "Nigerian Fulfulde",
    "fuv"
  ],
  [
    "Fuyug",
    "fuy"
  ],
  [
    "Fur",
    "fvr"
  ],
  [
    "Fwâi",
    "fwa"
  ],
  [
    "Fwe",
    "fwe"
  ],
  [
    "Ga",
    "gaa",
    "gaa",
    "gaa"
  ],
  [
    "Gabri",
    "gab"
  ],
  [
    "Mixed Great Andamanese",
    "gac"
  ],
  [
    "Gaddang",
    "gad"
  ],
  [
    "Guarequena",
    "gae"
  ],
  [
    "Gende",
    "gaf"
  ],
  [
    "Gagauz",
    "gag"
  ],
  [
    "Alekano",
    "gah"
  ],
  [
    "Borei",
    "gai"
  ],
  [
    "Gadsup",
    "gaj"
  ],
  [
    "Gamkonora",
    "gak"
  ],
  [
    "Galolen",
    "gal"
  ],
  [
    "Kandawo",
    "gam"
  ],
  [
    "Gan Chinese",
    "gan"
  ],
  [
    "Gants",
    "gao"
  ],
  [
    "Gal",
    "gap"
  ],
  [
    "Gata'",
    "gaq"
  ],
  [
    "Galeya",
    "gar"
  ],
  [
    "Adiwasi Garasia",
    "gas"
  ],
  [
    "Kenati",
    "gat"
  ],
  [
    "Mudhili Gadaba",
    "gau"
  ],
  [
    "Nobonob",
    "gaw"
  ],
  [
    "Borana-Arsi-Guji Oromo",
    "gax"
  ],
  [
    "Gayo",
    "gay",
    "gay",
    "gay"
  ],
  [
    "West Central Oromo",
    "gaz"
  ],
  [
    "Gbaya (Central African Republic)",
    "gba",
    "gba",
    "gba"
  ],
  [
    "Kaytetye",
    "gbb"
  ],
  [
    "Karajarri",
    "gbd"
  ],
  [
    "Niksek",
    "gbe"
  ],
  [
    "Gaikundi",
    "gbf"
  ],
  [
    "Gbanziri",
    "gbg"
  ],
  [
    "Defi Gbe",
    "gbh"
  ],
  [
    "Galela",
    "gbi"
  ],
  [
    "Bodo Gadaba",
    "gbj"
  ],
  [
    "Gaddi",
    "gbk"
  ],
  [
    "Gamit",
    "gbl"
  ],
  [
    "Garhwali",
    "gbm"
  ],
  [
    "Mo'da",
    "gbn"
  ],
  [
    "Northern Grebo",
    "gbo"
  ],
  [
    "Gbaya-Bossangoa",
    "gbp"
  ],
  [
    "Gbaya-Bozoum",
    "gbq"
  ],
  [
    "Gbagyi",
    "gbr"
  ],
  [
    "Gbesi Gbe",
    "gbs"
  ],
  [
    "Gagadu",
    "gbu"
  ],
  [
    "Gbanu",
    "gbv"
  ],
  [
    "Gabi-Gabi",
    "gbw"
  ],
  [
    "Eastern Xwla Gbe",
    "gbx"
  ],
  [
    "Gbari",
    "gby"
  ],
  [
    "Zoroastrian Dari",
    "gbz"
  ],
  [
    "Mali",
    "gcc"
  ],
  [
    "Ganggalida",
    "gcd"
  ],
  [
    "Galice",
    "gce"
  ],
  [
    "Guadeloupean Creole French",
    "gcf"
  ],
  [
    "Grenadian Creole English",
    "gcl"
  ],
  [
    "Gaina",
    "gcn"
  ],
  [
    "Guianese Creole French",
    "gcr"
  ],
  [
    "Colonia Tovar German",
    "gct"
  ],
  [
    "Gade Lohar",
    "gda"
  ],
  [
    "Pottangi Ollar Gadaba",
    "gdb"
  ],
  [
    "Gugu Badhun",
    "gdc"
  ],
  [
    "Gedaged",
    "gdd"
  ],
  [
    "Gude",
    "gde"
  ],
  [
    "Guduf-Gava",
    "gdf"
  ],
  [
    "Ga'dang",
    "gdg"
  ],
  [
    "Gadjerawang",
    "gdh"
  ],
  [
    "Gundi",
    "gdi"
  ],
  [
    "Gurdjar",
    "gdj"
  ],
  [
    "Gadang",
    "gdk"
  ],
  [
    "Dirasha",
    "gdl"
  ],
  [
    "Laal",
    "gdm"
  ],
  [
    "Umanakaina",
    "gdn"
  ],
  [
    "Ghodoberi",
    "gdo"
  ],
  [
    "Mehri",
    "gdq"
  ],
  [
    "Wipi",
    "gdr"
  ],
  [
    "Ghandruk Sign Language",
    "gds"
  ],
  [
    "Kungardutyi",
    "gdt"
  ],
  [
    "Gudu",
    "gdu"
  ],
  [
    "Godwari",
    "gdx"
  ],
  [
    "Geruma",
    "gea"
  ],
  [
    "Kire",
    "geb"
  ],
  [
    "Gboloo Grebo",
    "gec"
  ],
  [
    "Gade",
    "ged"
  ],
  [
    "Gerai",
    "gef"
  ],
  [
    "Gengle",
    "geg"
  ],
  [
    "Hutterite German",
    "geh"
  ],
  [
    "Gebe",
    "gei"
  ],
  [
    "Gen",
    "gej"
  ],
  [
    "Ywom",
    "gek"
  ],
  [
    "ut-Ma'in",
    "gel"
  ],
  [
    "Geme",
    "geq"
  ],
  [
    "Geser-Gorom",
    "ges"
  ],
  [
    "Eviya",
    "gev"
  ],
  [
    "Gera",
    "gew"
  ],
  [
    "Garre",
    "gex"
  ],
  [
    "Enya",
    "gey"
  ],
  [
    "Geez",
    "gez",
    "gez",
    "gez"
  ],
  [
    "Patpatar",
    "gfk"
  ],
  [
    "Gafat",
    "gft"
  ],
  [
    "Gao",
    "gga"
  ],
  [
    "Gbii",
    "ggb"
  ],
  [
    "Gugadj",
    "ggd"
  ],
  [
    "Gurr-goni",
    "gge"
  ],
  [
    "Gurgula",
    "ggg"
  ],
  [
    "Kungarakany",
    "ggk"
  ],
  [
    "Ganglau",
    "ggl"
  ],
  [
    "Gitua",
    "ggt"
  ],
  [
    "Gagu",
    "ggu"
  ],
  [
    "Gogodala",
    "ggw"
  ],
  [
    "Ghadamès",
    "gha"
  ],
  [
    "Hiberno-Scottish Gaelic",
    "ghc"
  ],
  [
    "Southern Ghale",
    "ghe"
  ],
  [
    "Northern Ghale",
    "ghh"
  ],
  [
    "Geko Karen",
    "ghk"
  ],
  [
    "Ghulfan",
    "ghl"
  ],
  [
    "Ghanongga",
    "ghn"
  ],
  [
    "Ghomara",
    "gho"
  ],
  [
    "Ghera",
    "ghr"
  ],
  [
    "Guhu-Samane",
    "ghs"
  ],
  [
    "Kuke",
    "ght"
  ],
  [
    "Kija",
    "gia"
  ],
  [
    "Gibanawa",
    "gib"
  ],
  [
    "Gail",
    "gic"
  ],
  [
    "Gidar",
    "gid"
  ],
  [
    "Gaɓogbo",
    "gie"
  ],
  [
    "Goaria",
    "gig"
  ],
  [
    "Githabul",
    "gih"
  ],
  [
    "Girirra",
    "gii"
  ],
  [
    "Gilbertese",
    "gil",
    "gil",
    "gil"
  ],
  [
    "Gimi (Eastern Highlands)",
    "gim"
  ],
  [
    "Hinukh",
    "gin"
  ],
  [
    "Gimi (West New Britain)",
    "gip"
  ],
  [
    "Green Gelao",
    "giq"
  ],
  [
    "Red Gelao",
    "gir"
  ],
  [
    "North Giziga",
    "gis"
  ],
  [
    "Gitxsan",
    "git"
  ],
  [
    "Mulao",
    "giu"
  ],
  [
    "White Gelao",
    "giw"
  ],
  [
    "Gilima",
    "gix"
  ],
  [
    "Giyug",
    "giy"
  ],
  [
    "South Giziga",
    "giz"
  ],
  [
    "Kachi Koli",
    "gjk"
  ],
  [
    "Gunditjmara",
    "gjm"
  ],
  [
    "Gonja",
    "gjn"
  ],
  [
    "Gurindji Kriol",
    "gjr"
  ],
  [
    "Gujari",
    "gju"
  ],
  [
    "Guya",
    "gka"
  ],
  [
    "Magɨ (Madang Province)",
    "gkd"
  ],
  [
    "Ndai",
    "gke"
  ],
  [
    "Gokana",
    "gkn"
  ],
  [
    "Kok-Nar",
    "gko"
  ],
  [
    "Guinea Kpelle",
    "gkp"
  ],
  [
    "ǂUngkue",
    "gku"
  ],
  [
    "Scottish Gaelic",
    "gla",
    "gla",
    "gla",
    "gd",
    "Gàidhlig"
  ],
  [
    "Belning",
    "glb"
  ],
  [
    "Bon Gula",
    "glc"
  ],
  [
    "Nanai",
    "gld"
  ],
  [
    "Irish",
    "gle",
    "gle",
    "gle",
    "ga",
    "Gaeilge"
  ],
  [
    "Galician",
    "glg",
    "glg",
    "glg",
    "gl",
    "Galego"
  ],
  [
    "Northwest Pashai",
    "glh"
  ],
  [
    "Gula Iro",
    "glj"
  ],
  [
    "Gilaki",
    "glk"
  ],
  [
    "Garlali",
    "gll"
  ],
  [
    "Galambu",
    "glo"
  ],
  [
    "Glaro-Twabo",
    "glr"
  ],
  [
    "Gula (Chad)",
    "glu"
  ],
  [
    "Manx",
    "glv",
    "glv",
    "glv",
    "gv",
    "Gaelg"
  ],
  [
    "Glavda",
    "glw"
  ],
  [
    "Gule",
    "gly"
  ],
  [
    "Gambera",
    "gma"
  ],
  [
    "Gula'alaa",
    "gmb"
  ],
  [
    "Mághdì",
    "gmd"
  ],
  [
    "Magɨyi",
    "gmg"
  ],
  [
    "Middle High German (ca. 1050-1500)",
    "gmh",
    "gmh",
    "gmh"
  ],
  [
    "Middle Low German",
    "gml"
  ],
  [
    "Gbaya-Mbodomo",
    "gmm"
  ],
  [
    "Gimnime",
    "gmn"
  ],
  [
    "Mirning",
    "gmr"
  ],
  [
    "Gumalu",
    "gmu"
  ],
  [
    "Gamo",
    "gmv"
  ],
  [
    "Magoma",
    "gmx"
  ],
  [
    "Mycenaean Greek",
    "gmy"
  ],
  [
    "Mgbolizhia",
    "gmz"
  ],
  [
    "Kaansa",
    "gna"
  ],
  [
    "Gangte",
    "gnb"
  ],
  [
    "Guanche",
    "gnc"
  ],
  [
    "Zulgo-Gemzek",
    "gnd"
  ],
  [
    "Ganang",
    "gne"
  ],
  [
    "Ngangam",
    "gng"
  ],
  [
    "Lere",
    "gnh"
  ],
  [
    "Gooniyandi",
    "gni"
  ],
  [
    "Ngen",
    "gnj"
  ],
  [
    "ǁGana",
    "gnk"
  ],
  [
    "Gangulu",
    "gnl"
  ],
  [
    "Ginuman",
    "gnm"
  ],
  [
    "Gumatj",
    "gnn"
  ],
  [
    "Northern Gondi",
    "gno"
  ],
  [
    "Gana",
    "gnq"
  ],
  [
    "Gureng Gureng",
    "gnr"
  ],
  [
    "Guntai",
    "gnt"
  ],
  [
    "Gnau",
    "gnu"
  ],
  [
    "Western Bolivian Guaraní",
    "gnw"
  ],
  [
    "Ganzi",
    "gnz"
  ],
  [
    "Guro",
    "goa"
  ],
  [
    "Playero",
    "gob"
  ],
  [
    "Gorakor",
    "goc"
  ],
  [
    "Godié",
    "god"
  ],
  [
    "Gongduk",
    "goe"
  ],
  [
    "Gofa",
    "gof"
  ],
  [
    "Gogo",
    "gog"
  ],
  [
    "Old High German (ca. 750-1050)",
    "goh",
    "goh",
    "goh"
  ],
  [
    "Gobasi",
    "goi"
  ],
  [
    "Gowlan",
    "goj"
  ],
  [
    "Gowli",
    "gok"
  ],
  [
    "Gola",
    "gol"
  ],
  [
    "Goan Konkani",
    "gom"
  ],
  [
    "Gondi",
    "gon",
    "gon",
    "gon"
  ],
  [
    "Gone Dau",
    "goo"
  ],
  [
    "Yeretuar",
    "gop"
  ],
  [
    "Gorap",
    "goq"
  ],
  [
    "Gorontalo",
    "gor",
    "gor",
    "gor"
  ],
  [
    "Gronings",
    "gos"
  ],
  [
    "Gothic",
    "got",
    "got",
    "got"
  ],
  [
    "Gavar",
    "gou"
  ],
  [
    "Goo",
    "gov"
  ],
  [
    "Gorowa",
    "gow"
  ],
  [
    "Gobu",
    "gox"
  ],
  [
    "Goundo",
    "goy"
  ],
  [
    "Gozarkhani",
    "goz"
  ],
  [
    "Gupa-Abawa",
    "gpa"
  ],
  [
    "Ghanaian Pidgin English",
    "gpe"
  ],
  [
    "Taiap",
    "gpn"
  ],
  [
    "Ga'anda",
    "gqa"
  ],
  [
    "Guiqiong",
    "gqi"
  ],
  [
    "Guana (Brazil)",
    "gqn"
  ],
  [
    "Gor",
    "gqr"
  ],
  [
    "Qau",
    "gqu"
  ],
  [
    "Rajput Garasia",
    "gra"
  ],
  [
    "Grebo",
    "grb",
    "grb",
    "grb"
  ],
  [
    "Greek",
    "grc",
    "grc",
    "grc",
    undefined,
    "Ελληνικά",
  ],
  [
    "Guruntum-Mbaaru",
    "grd"
  ],
  [
    "Madi",
    "grg"
  ],
  [
    "Gbiri-Niragu",
    "grh"
  ],
  [
    "Ghari",
    "gri"
  ],
  [
    "Southern Grebo",
    "grj"
  ],
  [
    "Kota Marudu Talantang",
    "grm"
  ],
  [
    "Guarani",
    "grn",
    "grn",
    "grn",
    "gn",
    "Avañe'ẽ"
  ],
  [
    "Groma",
    "gro"
  ],
  [
    "Gorovu",
    "grq"
  ],
  [
    "Taznatit",
    "grr"
  ],
  [
    "Gresi",
    "grs"
  ],
  [
    "Garo",
    "grt"
  ],
  [
    "Kistane",
    "gru"
  ],
  [
    "Central Grebo",
    "grv"
  ],
  [
    "Gweda",
    "grw"
  ],
  [
    "Guriaso",
    "grx"
  ],
  [
    "Barclayville Grebo",
    "gry"
  ],
  [
    "Guramalum",
    "grz"
  ],
  [
    "Ghanaian Sign Language",
    "gse"
  ],
  [
    "German Sign Language",
    "gsg"
  ],
  [
    "Gusilay",
    "gsl"
  ],
  [
    "Guatemalan Sign Language",
    "gsm"
  ],
  [
    "Nema",
    "gsn"
  ],
  [
    "Southwest Gbaya",
    "gso"
  ],
  [
    "Wasembo",
    "gsp"
  ],
  [
    "Greek Sign Language",
    "gss"
  ],
  [
    "Swiss German",
    "gsw",
    "gsw",
    "gsw"
  ],
  [
    "Guató",
    "gta"
  ],
  [
    "Aghu-Tharnggala",
    "gtu"
  ],
  [
    "Shiki",
    "gua"
  ],
  [
    "Guajajára",
    "gub"
  ],
  [
    "Wayuu",
    "guc"
  ],
  [
    "Yocoboué Dida",
    "gud"
  ],
  [
    "Gurindji",
    "gue"
  ],
  [
    "Gupapuyngu",
    "guf"
  ],
  [
    "Paraguayan Guaraní",
    "gug"
  ],
  [
    "Guahibo",
    "guh"
  ],
  [
    "Eastern Bolivian Guaraní",
    "gui"
  ],
  [
    "Gujarati",
    "guj",
    "guj",
    "guj",
    "gu",
    "ગુજરાતી"
  ],
  [
    "Gumuz",
    "guk"
  ],
  [
    "Sea Island Creole English",
    "gul"
  ],
  [
    "Guambiano",
    "gum"
  ],
  [
    "Mbyá Guaraní",
    "gun"
  ],
  [
    "Guayabero",
    "guo"
  ],
  [
    "Gunwinggu",
    "gup"
  ],
  [
    "Aché",
    "guq"
  ],
  [
    "Farefare",
    "gur"
  ],
  [
    "Guinean Sign Language",
    "gus"
  ],
  [
    "Maléku Jaíka",
    "gut"
  ],
  [
    "Yanomamö",
    "guu"
  ],
  [
    "Gun",
    "guw"
  ],
  [
    "Gourmanchéma",
    "gux"
  ],
  [
    "Gusii",
    "guz"
  ],
  [
    "Guana (Paraguay)",
    "gva"
  ],
  [
    "Guanano",
    "gvc"
  ],
  [
    "Duwet",
    "gve"
  ],
  [
    "Golin",
    "gvf"
  ],
  [
    "Guajá",
    "gvj"
  ],
  [
    "Gulay",
    "gvl"
  ],
  [
    "Gurmana",
    "gvm"
  ],
  [
    "Kuku-Yalanji",
    "gvn"
  ],
  [
    "Gavião Do Jiparaná",
    "gvo"
  ],
  [
    "Pará Gavião",
    "gvp"
  ],
  [
    "Gurung",
    "gvr"
  ],
  [
    "Gumawana",
    "gvs"
  ],
  [
    "Guyani",
    "gvy"
  ],
  [
    "Mbato",
    "gwa"
  ],
  [
    "Gwa",
    "gwb"
  ],
  [
    "Gawri",
    "gwc"
  ],
  [
    "Gawwada",
    "gwd"
  ],
  [
    "Gweno",
    "gwe"
  ],
  [
    "Gowro",
    "gwf"
  ],
  [
    "Moo",
    "gwg"
  ],
  [
    "Gwichʼin",
    "gwi",
    "gwi",
    "gwi"
  ],
  [
    "ǀGwi",
    "gwj"
  ],
  [
    "Awngthim",
    "gwm"
  ],
  [
    "Gwandara",
    "gwn"
  ],
  [
    "Gwere",
    "gwr"
  ],
  [
    "Gawar-Bati",
    "gwt"
  ],
  [
    "Guwamu",
    "gwu"
  ],
  [
    "Kwini",
    "gww"
  ],
  [
    "Gua",
    "gwx"
  ],
  [
    "Wè Southern",
    "gxx"
  ],
  [
    "Northwest Gbaya",
    "gya"
  ],
  [
    "Garus",
    "gyb"
  ],
  [
    "Kayardild",
    "gyd"
  ],
  [
    "Gyem",
    "gye"
  ],
  [
    "Gungabula",
    "gyf"
  ],
  [
    "Gbayi",
    "gyg"
  ],
  [
    "Gyele",
    "gyi"
  ],
  [
    "Gayil",
    "gyl"
  ],
  [
    "Ngäbere",
    "gym"
  ],
  [
    "Guyanese Creole English",
    "gyn"
  ],
  [
    "Gyalsumdo",
    "gyo"
  ],
  [
    "Guarayu",
    "gyr"
  ],
  [
    "Gunya",
    "gyy"
  ],
  [
    "Geji",
    "gyz"
  ],
  [
    "Ganza",
    "gza"
  ],
  [
    "Gazi",
    "gzi"
  ],
  [
    "Gane",
    "gzn"
  ],
  [
    "Han",
    "haa"
  ],
  [
    "Hanoi Sign Language",
    "hab"
  ],
  [
    "Gurani",
    "hac"
  ],
  [
    "Hatam",
    "had"
  ],
  [
    "Eastern Oromo",
    "hae"
  ],
  [
    "Haiphong Sign Language",
    "haf"
  ],
  [
    "Hanga",
    "hag"
  ],
  [
    "Hahon",
    "hah"
  ],
  [
    "Haida",
    "hai",
    "hai",
    "hai"
  ],
  [
    "Hajong",
    "haj"
  ],
  [
    "Hakka Chinese",
    "hak"
  ],
  [
    "Halang",
    "hal"
  ],
  [
    "Hewa",
    "ham"
  ],
  [
    "Hangaza",
    "han"
  ],
  [
    "Hakö",
    "hao"
  ],
  [
    "Hupla",
    "hap"
  ],
  [
    "Ha",
    "haq"
  ],
  [
    "Harari",
    "har"
  ],
  [
    "Haisla",
    "has"
  ],
  [
    "Haitian",
    "hat",
    "hat",
    "hat",
    "ht",
    "Krèyol ayisyen"
  ],
  [
    "Hausa",
    "hau",
    "hau",
    "hau",
    "ha",
    "هَوُسَ"
  ],
  [
    "Havu",
    "hav"
  ],
  [
    "Hawaiian",
    "haw",
    "haw",
    "haw"
  ],
  [
    "Southern Haida",
    "hax"
  ],
  [
    "Haya",
    "hay"
  ],
  [
    "Hazaragi",
    "haz"
  ],
  [
    "Hamba",
    "hba"
  ],
  [
    "Huba",
    "hbb"
  ],
  [
    "Heiban",
    "hbn"
  ],
  [
    "Ancient Hebrew",
    "hbo"
  ],
  [
    "Serbo-Croatian",
    "hbs",
    "",
    "",
    "sh",
    "Srpskohrvatski / Српскохрватски"
  ],
  [
    "Habu",
    "hbu"
  ],
  [
    "Andaman Creole Hindi",
    "hca"
  ],
  [
    "Huichol",
    "hch"
  ],
  [
    "Northern Haida",
    "hdn"
  ],
  [
    "Honduras Sign Language",
    "hds"
  ],
  [
    "Hadiyya",
    "hdy"
  ],
  [
    "Northern Qiandong Miao",
    "hea"
  ],
  [
    "Hebrew",
    "heb",
    "heb",
    "heb",
    "he",
    "עברית",
    ["#perfect#3rd#masculine#singular", "#indicative#aorist#active#3rd#singular", "#participle#1st#singular", "#present#1st#singular", "#infinitive-construct", "#infinitive"]
  ],
  [
    "Herdé",
    "hed"
  ],
  [
    "Helong",
    "heg"
  ],
  [
    "Hehe",
    "heh"
  ],
  [
    "Heiltsuk",
    "hei"
  ],
  [
    "Hemba",
    "hem"
  ],
  [
    "Herero",
    "her",
    "her",
    "her",
    "hz",
    "Otsiherero"
  ],
  [
    "Haiǁom",
    "hgm"
  ],
  [
    "Haigwai",
    "hgw"
  ],
  [
    "Hoia Hoia",
    "hhi"
  ],
  [
    "Kerak",
    "hhr"
  ],
  [
    "Hoyahoya",
    "hhy"
  ],
  [
    "Lamang",
    "hia"
  ],
  [
    "Hibito",
    "hib"
  ],
  [
    "Hidatsa",
    "hid"
  ],
  [
    "Fiji Hindi",
    "hif"
  ],
  [
    "Kamwe",
    "hig"
  ],
  [
    "Pamosu",
    "hih"
  ],
  [
    "Hinduri",
    "hii"
  ],
  [
    "Hijuk",
    "hij"
  ],
  [
    "Seit-Kaitetu",
    "hik"
  ],
  [
    "Hiligaynon",
    "hil",
    "hil",
    "hil"
  ],
  [
    "Hindi",
    "hin",
    "hin",
    "hin",
    "hi",
    "हिन्दी"
  ],
  [
    "Tsoa",
    "hio"
  ],
  [
    "Himarimã",
    "hir"
  ],
  [
    "Hittite",
    "hit",
    "hit",
    "hit"
  ],
  [
    "Hiw",
    "hiw"
  ],
  [
    "Hixkaryána",
    "hix"
  ],
  [
    "Haji",
    "hji"
  ],
  [
    "Kahe",
    "hka"
  ],
  [
    "Hunde",
    "hke"
  ],
  [
    "Khah",
    "hkh"
  ],
  [
    "Hunjara-Kaina Ke",
    "hkk"
  ],
  [
    "Mel-Khaonh",
    "hkn"
  ],
  [
    "Hong Kong Sign Language",
    "hks"
  ],
  [
    "Halia",
    "hla"
  ],
  [
    "Halbi",
    "hlb"
  ],
  [
    "Halang Doan",
    "hld"
  ],
  [
    "Hlersu",
    "hle"
  ],
  [
    "Matu Chin",
    "hlt"
  ],
  [
    "Hieroglyphic Luwian",
    "hlu"
  ],
  [
    "Southern Mashan Hmong",
    "hma"
  ],
  [
    "Humburi Senni Songhay",
    "hmb"
  ],
  [
    "Central Huishui Hmong",
    "hmc"
  ],
  [
    "Large Flowery Miao",
    "hmd"
  ],
  [
    "Eastern Huishui Hmong",
    "hme"
  ],
  [
    "Hmong Don",
    "hmf"
  ],
  [
    "Southwestern Guiyang Hmong",
    "hmg"
  ],
  [
    "Southwestern Huishui Hmong",
    "hmh"
  ],
  [
    "Northern Huishui Hmong",
    "hmi"
  ],
  [
    "Ge",
    "hmj"
  ],
  [
    "Maek",
    "hmk"
  ],
  [
    "Luopohe Hmong",
    "hml"
  ],
  [
    "Central Mashan Hmong",
    "hmm"
  ],
  [
    "Hmong",
    "hmn",
    "hmn",
    "hmn"
  ],
  [
    "Hiri Motu",
    "hmo",
    "hmo",
    "hmo",
    "ho",
    "Hiri Motu"
  ],
  [
    "Northern Mashan Hmong",
    "hmp"
  ],
  [
    "Eastern Qiandong Miao",
    "hmq"
  ],
  [
    "Hmar",
    "hmr"
  ],
  [
    "Southern Qiandong Miao",
    "hms"
  ],
  [
    "Hamtai",
    "hmt"
  ],
  [
    "Hamap",
    "hmu"
  ],
  [
    "Hmong Dô",
    "hmv"
  ],
  [
    "Western Mashan Hmong",
    "hmw"
  ],
  [
    "Southern Guiyang Hmong",
    "hmy"
  ],
  [
    "Hmong Shua",
    "hmz"
  ],
  [
    "Mina (Cameroon)",
    "hna"
  ],
  [
    "Southern Hindko",
    "hnd"
  ],
  [
    "Chhattisgarhi",
    "hne"
  ],
  [
    "Hungu",
    "hng"
  ],
  [
    "ǁAni",
    "hnh"
  ],
  [
    "Hani",
    "hni"
  ],
  [
    "Hmong Njua",
    "hnj"
  ],
  [
    "Hanunoo",
    "hnn"
  ],
  [
    "Northern Hindko",
    "hno"
  ],
  [
    "Caribbean Hindustani",
    "hns"
  ],
  [
    "Hung",
    "hnu"
  ],
  [
    "Hoava",
    "hoa"
  ],
  [
    "Mari (Madang Province)",
    "hob"
  ],
  [
    "Ho",
    "hoc"
  ],
  [
    "Holma",
    "hod"
  ],
  [
    "Horom",
    "hoe"
  ],
  [
    "Hobyót",
    "hoh"
  ],
  [
    "Holikachuk",
    "hoi"
  ],
  [
    "Hadothi",
    "hoj"
  ],
  [
    "Holu",
    "hol"
  ],
  [
    "Homa",
    "hom"
  ],
  [
    "Holoholo",
    "hoo"
  ],
  [
    "Hopi",
    "hop"
  ],
  [
    "Horo",
    "hor"
  ],
  [
    "Ho Chi Minh City Sign Language",
    "hos"
  ],
  [
    "Hote",
    "hot"
  ],
  [
    "Hovongan",
    "hov"
  ],
  [
    "Honi",
    "how"
  ],
  [
    "Holiya",
    "hoy"
  ],
  [
    "Hozo",
    "hoz"
  ],
  [
    "Hpon",
    "hpo"
  ],
  [
    "Hawai'i Sign Language (HSL)",
    "hps"
  ],
  [
    "Hrangkhol",
    "hra"
  ],
  [
    "Niwer Mil",
    "hrc"
  ],
  [
    "Hre",
    "hre"
  ],
  [
    "Haruku",
    "hrk"
  ],
  [
    "Horned Miao",
    "hrm"
  ],
  [
    "Haroi",
    "hro"
  ],
  [
    "Nhirrpi",
    "hrp"
  ],
  [
    "Hértevin",
    "hrt"
  ],
  [
    "Hruso",
    "hru"
  ],
  [
    "Croatian",
    "hrv",
    "hrv",
    "hrv",
    "hr",
    "Hrvatski"
  ],
  [
    "Warwar Feni",
    "hrw"
  ],
  [
    "Hunsrik",
    "hrx"
  ],
  [
    "Harzani",
    "hrz"
  ],
  [
    "Upper Sorbian",
    "hsb",
    "hsb",
    "hsb"
  ],
  [
    "Hungarian Sign Language",
    "hsh"
  ],
  [
    "Hausa Sign Language",
    "hsl"
  ],
  [
    "Xiang Chinese",
    "hsn"
  ],
  [
    "Harsusi",
    "hss"
  ],
  [
    "Hoti",
    "hti"
  ],
  [
    "Minica Huitoto",
    "hto"
  ],
  [
    "Hadza",
    "hts"
  ],
  [
    "Hitu",
    "htu"
  ],
  [
    "Middle Hittite",
    "htx"
  ],
  [
    "Huambisa",
    "hub"
  ],
  [
    "ǂHua",
    "huc"
  ],
  [
    "Huaulu",
    "hud"
  ],
  [
    "San Francisco Del Mar Huave",
    "hue"
  ],
  [
    "Humene",
    "huf"
  ],
  [
    "Huachipaeri",
    "hug"
  ],
  [
    "Huilliche",
    "huh"
  ],
  [
    "Huli",
    "hui"
  ],
  [
    "Northern Guiyang Hmong",
    "huj"
  ],
  [
    "Hulung",
    "huk"
  ],
  [
    "Hula",
    "hul"
  ],
  [
    "Hungana",
    "hum"
  ],
  [
    "Hungarian",
    "hun",
    "hun",
    "hun",
    "hu",
    "Magyar"
  ],
  [
    "Hu",
    "huo"
  ],
  [
    "Hupa",
    "hup",
    "hup",
    "hup"
  ],
  [
    "Tsat",
    "huq"
  ],
  [
    "Halkomelem",
    "hur"
  ],
  [
    "Huastec",
    "hus"
  ],
  [
    "Humla",
    "hut"
  ],
  [
    "Murui Huitoto",
    "huu"
  ],
  [
    "San Mateo Del Mar Huave",
    "huv"
  ],
  [
    "Hukumina",
    "huw"
  ],
  [
    "Nüpode Huitoto",
    "hux"
  ],
  [
    "Hulaulá",
    "huy"
  ],
  [
    "Hunzib",
    "huz"
  ],
  [
    "Haitian Vodoun Culture Language",
    "hvc"
  ],
  [
    "San Dionisio Del Mar Huave",
    "hve"
  ],
  [
    "Haveke",
    "hvk"
  ],
  [
    "Sabu",
    "hvn"
  ],
  [
    "Santa María Del Mar Huave",
    "hvv"
  ],
  [
    "Wané",
    "hwa"
  ],
  [
    "Hawai'i Creole English",
    "hwc"
  ],
  [
    "Hwana",
    "hwo"
  ],
  [
    "Hya",
    "hya"
  ],
  [
    "Armenian",
    "hye",
    "arm",
    "hye",
    "hy",
    "Հայերեն"
  ],
  [
    "Western Armenian",
    "hyw"
  ],
  [
    "Iaai",
    "iai"
  ],
  [
    "Iatmul",
    "ian"
  ],
  [
    "Purari",
    "iar"
  ],
  [
    "Iban",
    "iba",
    "iba",
    "iba"
  ],
  [
    "Ibibio",
    "ibb"
  ],
  [
    "Iwaidja",
    "ibd"
  ],
  [
    "Akpes",
    "ibe"
  ],
  [
    "Ibanag",
    "ibg"
  ],
  [
    "Bih",
    "ibh"
  ],
  [
    "Ibaloi",
    "ibl"
  ],
  [
    "Agoi",
    "ibm"
  ],
  [
    "Ibino",
    "ibn"
  ],
  [
    "Igbo",
    "ibo",
    "ibo",
    "ibo",
    "ig",
    "Igbo"
  ],
  [
    "Ibuoro",
    "ibr"
  ],
  [
    "Ibu",
    "ibu"
  ],
  [
    "Ibani",
    "iby"
  ],
  [
    "Ede Ica",
    "ica"
  ],
  [
    "Etkywan",
    "ich"
  ],
  [
    "Icelandic Sign Language",
    "icl"
  ],
  [
    "Islander Creole English",
    "icr"
  ],
  [
    "Idakho-Isukha-Tiriki",
    "ida"
  ],
  [
    "Indo-Portuguese",
    "idb"
  ],
  [
    "Idon",
    "idc"
  ],
  [
    "Ede Idaca",
    "idd"
  ],
  [
    "Idere",
    "ide"
  ],
  [
    "Idi",
    "idi"
  ],
  [
    "Ido",
    "ido",
    "ido",
    "ido",
    "io",
    "Ido"
  ],
  [
    "Indri",
    "idr"
  ],
  [
    "Idesa",
    "ids"
  ],
  [
    "Idaté",
    "idt"
  ],
  [
    "Idoma",
    "idu"
  ],
  [
    "Amganad Ifugao",
    "ifa"
  ],
  [
    "Batad Ifugao",
    "ifb"
  ],
  [
    "Ifè",
    "ife"
  ],
  [
    "Ifo",
    "iff"
  ],
  [
    "Tuwali Ifugao",
    "ifk"
  ],
  [
    "Teke-Fuumu",
    "ifm"
  ],
  [
    "Mayoyao Ifugao",
    "ifu"
  ],
  [
    "Keley-I Kallahan",
    "ify"
  ],
  [
    "Ebira",
    "igb"
  ],
  [
    "Igede",
    "ige"
  ],
  [
    "Igana",
    "igg"
  ],
  [
    "Igala",
    "igl"
  ],
  [
    "Kanggape",
    "igm"
  ],
  [
    "Ignaciano",
    "ign"
  ],
  [
    "Isebe",
    "igo"
  ],
  [
    "Interglossa",
    "igs"
  ],
  [
    "Igwe",
    "igw"
  ],
  [
    "Iha Based Pidgin",
    "ihb"
  ],
  [
    "Ihievbe",
    "ihi"
  ],
  [
    "Iha",
    "ihp"
  ],
  [
    "Bidhawal",
    "ihw"
  ],
  [
    "Sichuan Yi",
    "iii",
    "iii",
    "iii",
    "ii",
    "ꆇꉙ / 四川彝语"
  ],
  [
    "Thiin",
    "iin"
  ],
  [
    "Izon",
    "ijc"
  ],
  [
    "Biseni",
    "ije"
  ],
  [
    "Ede Ije",
    "ijj"
  ],
  [
    "Kalabari",
    "ijn"
  ],
  [
    "Southeast Ijo",
    "ijs"
  ],
  [
    "Eastern Canadian Inuktitut",
    "ike"
  ],
  [
    "Iko",
    "iki"
  ],
  [
    "Ika",
    "ikk"
  ],
  [
    "Ikulu",
    "ikl"
  ],
  [
    "Olulumo-Ikom",
    "iko"
  ],
  [
    "Ikpeshi",
    "ikp"
  ],
  [
    "Ikaranggal",
    "ikr"
  ],
  [
    "Inuit Sign Language",
    "iks"
  ],
  [
    "Inuinnaqtun",
    "ikt"
  ],
  [
    "Inuktitut",
    "iku",
    "iku",
    "iku",
    "iu",
    "ᐃᓄᒃᑎᑐᑦ"
  ],
  [
    "Iku-Gora-Ankwa",
    "ikv"
  ],
  [
    "Ikwere",
    "ikw"
  ],
  [
    "Ik",
    "ikx"
  ],
  [
    "Ikizu",
    "ikz"
  ],
  [
    "Ile Ape",
    "ila"
  ],
  [
    "Ila",
    "ilb"
  ],
  [
    "Interlingue",
    "ile",
    "ile",
    "ile",
    "ie",
    "Interlingue"
  ],
  [
    "Garig-Ilgar",
    "ilg"
  ],
  [
    "Ili Turki",
    "ili"
  ],
  [
    "Ilongot",
    "ilk"
  ],
  [
    "Iranun (Malaysia)",
    "ilm"
  ],
  [
    "Iloko",
    "ilo",
    "ilo",
    "ilo"
  ],
  [
    "Iranun (Philippines)",
    "ilp"
  ],
  [
    "International Sign",
    "ils"
  ],
  [
    "Ili'uun",
    "ilu"
  ],
  [
    "Ilue",
    "ilv"
  ],
  [
    "Mala Malasar",
    "ima"
  ],
  [
    "Anamgura",
    "imi"
  ],
  [
    "Miluk",
    "iml"
  ],
  [
    "Imonda",
    "imn"
  ],
  [
    "Imbongu",
    "imo"
  ],
  [
    "Imroing",
    "imr"
  ],
  [
    "Marsian",
    "ims"
  ],
  [
    "Imotong",
    "imt"
  ],
  [
    "Milyan",
    "imy"
  ],
  [
    "Interlingua (International Auxiliary Language Association)",
    "ina",
    "ina",
    "ina",
    "ia",
    "Interlingua"
  ],
  [
    "Inga",
    "inb"
  ],
  [
    "Indonesian",
    "ind",
    "ind",
    "ind",
    "id",
    "Bahasa Indonesia"
  ],
  [
    "Degexit'an",
    "ing"
  ],
  [
    "Ingush",
    "inh",
    "inh",
    "inh"
  ],
  [
    "Jungle Inga",
    "inj"
  ],
  [
    "Indonesian Sign Language",
    "inl"
  ],
  [
    "Minaean",
    "inm"
  ],
  [
    "Isinai",
    "inn"
  ],
  [
    "Inoke-Yate",
    "ino"
  ],
  [
    "Iñapari",
    "inp"
  ],
  [
    "Indian Sign Language",
    "ins"
  ],
  [
    "Intha",
    "int"
  ],
  [
    "Ineseño",
    "inz"
  ],
  [
    "Inor",
    "ior"
  ],
  [
    "Tuma-Irumu",
    "iou"
  ],
  [
    "Iowa-Oto",
    "iow"
  ],
  [
    "Ipili",
    "ipi"
  ],
  [
    "Inupiaq",
    "ipk",
    "ipk",
    "ipk",
    "ik",
    "Iñupiak"
  ],
  [
    "Ipiko",
    "ipo"
  ],
  [
    "Iquito",
    "iqu"
  ],
  [
    "Ikwo",
    "iqw"
  ],
  [
    "Iresim",
    "ire"
  ],
  [
    "Irarutu",
    "irh"
  ],
  [
    "Rigwe",
    "iri"
  ],
  [
    "Iraqw",
    "irk"
  ],
  [
    "Irántxe",
    "irn"
  ],
  [
    "Ir",
    "irr"
  ],
  [
    "Irula",
    "iru"
  ],
  [
    "Kamberau",
    "irx"
  ],
  [
    "Iraya",
    "iry"
  ],
  [
    "Isabi",
    "isa"
  ],
  [
    "Isconahua",
    "isc"
  ],
  [
    "Isnag",
    "isd"
  ],
  [
    "Italian Sign Language",
    "ise"
  ],
  [
    "Irish Sign Language",
    "isg"
  ],
  [
    "Esan",
    "ish"
  ],
  [
    "Nkem-Nkum",
    "isi"
  ],
  [
    "Ishkashimi",
    "isk"
  ],
  [
    "Icelandic",
    "isl",
    "ice",
    "isl",
    "is",
    "Íslenska"
  ],
  [
    "Masimasi",
    "ism"
  ],
  [
    "Isanzu",
    "isn"
  ],
  [
    "Isoko",
    "iso"
  ],
  [
    "Israeli Sign Language",
    "isr"
  ],
  [
    "Istriot",
    "ist"
  ],
  [
    "Isu (Menchum Division)",
    "isu"
  ],
  [
    "Italian",
    "ita",
    "ita",
    "ita",
    "it",
    "Italiano"
  ],
  [
    "Binongan Itneg",
    "itb"
  ],
  [
    "Southern Tidung",
    "itd"
  ],
  [
    "Itene",
    "ite"
  ],
  [
    "Inlaod Itneg",
    "iti"
  ],
  [
    "Judeo-Italian",
    "itk"
  ],
  [
    "Itelmen",
    "itl"
  ],
  [
    "Itu Mbon Uzo",
    "itm"
  ],
  [
    "Itonama",
    "ito"
  ],
  [
    "Iteri",
    "itr"
  ],
  [
    "Isekiri",
    "its"
  ],
  [
    "Maeng Itneg",
    "itt"
  ],
  [
    "Itawit",
    "itv"
  ],
  [
    "Ito",
    "itw"
  ],
  [
    "Itik",
    "itx"
  ],
  [
    "Moyadan Itneg",
    "ity"
  ],
  [
    "Itzá",
    "itz"
  ],
  [
    "Iu Mien",
    "ium"
  ],
  [
    "Ibatan",
    "ivb"
  ],
  [
    "Ivatan",
    "ivv"
  ],
  [
    "I-Wak",
    "iwk"
  ],
  [
    "Iwam",
    "iwm"
  ],
  [
    "Iwur",
    "iwo"
  ],
  [
    "Sepik Iwam",
    "iws"
  ],
  [
    "Ixcatec",
    "ixc"
  ],
  [
    "Ixil",
    "ixl"
  ],
  [
    "Iyayu",
    "iya"
  ],
  [
    "Mesaka",
    "iyo"
  ],
  [
    "Yaka (Congo)",
    "iyx"
  ],
  [
    "Ingrian",
    "izh"
  ],
  [
    "Izere",
    "izr"
  ],
  [
    "Izii",
    "izz"
  ],
  [
    "Jamamadí",
    "jaa"
  ],
  [
    "Hyam",
    "jab"
  ],
  [
    "Popti'",
    "jac"
  ],
  [
    "Jahanka",
    "jad"
  ],
  [
    "Yabem",
    "jae"
  ],
  [
    "Jara",
    "jaf"
  ],
  [
    "Jah Hut",
    "jah"
  ],
  [
    "Zazao",
    "jaj"
  ],
  [
    "Jakun",
    "jak"
  ],
  [
    "Yalahatan",
    "jal"
  ],
  [
    "Jamaican Creole English",
    "jam"
  ],
  [
    "Jandai",
    "jan"
  ],
  [
    "Yanyuwa",
    "jao"
  ],
  [
    "Yaqay",
    "jaq"
  ],
  [
    "New Caledonian Javanese",
    "jas"
  ],
  [
    "Jakati",
    "jat"
  ],
  [
    "Yaur",
    "jau"
  ],
  [
    "Javanese",
    "jav",
    "jav",
    "jav",
    "jv",
    "Basa Jawa"
  ],
  [
    "Jambi Malay",
    "jax"
  ],
  [
    "Yan-nhangu",
    "jay"
  ],
  [
    "Jawe",
    "jaz"
  ],
  [
    "Judeo-Berber",
    "jbe"
  ],
  [
    "Badjiri",
    "jbi"
  ],
  [
    "Arandai",
    "jbj"
  ],
  [
    "Barikewa",
    "jbk"
  ],
  [
    "Bijim",
    "jbm"
  ],
  [
    "Nafusi",
    "jbn"
  ],
  [
    "Lojban",
    "jbo",
    "jbo",
    "jbo"
  ],
  [
    "Jofotek-Bromnya",
    "jbr"
  ],
  [
    "Jabutí",
    "jbt"
  ],
  [
    "Jukun Takum",
    "jbu"
  ],
  [
    "Yawijibaya",
    "jbw"
  ],
  [
    "Jamaican Country Sign Language",
    "jcs"
  ],
  [
    "Krymchak",
    "jct"
  ],
  [
    "Jad",
    "jda"
  ],
  [
    "Jadgali",
    "jdg"
  ],
  [
    "Judeo-Tat",
    "jdt"
  ],
  [
    "Jebero",
    "jeb"
  ],
  [
    "Jerung",
    "jee"
  ],
  [
    "Jeh",
    "jeh"
  ],
  [
    "Yei",
    "jei"
  ],
  [
    "Jeri Kuo",
    "jek"
  ],
  [
    "Yelmek",
    "jel"
  ],
  [
    "Dza",
    "jen"
  ],
  [
    "Jere",
    "jer"
  ],
  [
    "Manem",
    "jet"
  ],
  [
    "Jonkor Bourmataguil",
    "jeu"
  ],
  [
    "Ngbee",
    "jgb"
  ],
  [
    "Judeo-Georgian",
    "jge"
  ],
  [
    "Gwak",
    "jgk"
  ],
  [
    "Ngomba",
    "jgo"
  ],
  [
    "Jehai",
    "jhi"
  ],
  [
    "Jhankot Sign Language",
    "jhs"
  ],
  [
    "Jina",
    "jia"
  ],
  [
    "Jibu",
    "jib"
  ],
  [
    "Tol",
    "jic"
  ],
  [
    "Bu (Kaduna State)",
    "jid"
  ],
  [
    "Jilbe",
    "jie"
  ],
  [
    "Jingulu",
    "jig"
  ],
  [
    "sTodsde",
    "jih"
  ],
  [
    "Jiiddu",
    "jii"
  ],
  [
    "Jilim",
    "jil"
  ],
  [
    "Jimi (Cameroon)",
    "jim"
  ],
  [
    "Jiamao",
    "jio"
  ],
  [
    "Guanyinqiao",
    "jiq"
  ],
  [
    "Jita",
    "jit"
  ],
  [
    "Youle Jinuo",
    "jiu"
  ],
  [
    "Shuar",
    "jiv"
  ],
  [
    "Buyuan Jinuo",
    "jiy"
  ],
  [
    "Jejueo",
    "jje"
  ],
  [
    "Bankal",
    "jjr"
  ],
  [
    "Kaera",
    "jka"
  ],
  [
    "Mobwa Karen",
    "jkm"
  ],
  [
    "Kubo",
    "jko"
  ],
  [
    "Paku Karen",
    "jkp"
  ],
  [
    "Koro (India)",
    "jkr"
  ],
  [
    "Amami Koniya Sign Language",
    "jks"
  ],
  [
    "Labir",
    "jku"
  ],
  [
    "Ngile",
    "jle"
  ],
  [
    "Jamaican Sign Language",
    "jls"
  ],
  [
    "Dima",
    "jma"
  ],
  [
    "Zumbun",
    "jmb"
  ],
  [
    "Machame",
    "jmc"
  ],
  [
    "Yamdena",
    "jmd"
  ],
  [
    "Jimi (Nigeria)",
    "jmi"
  ],
  [
    "Jumli",
    "jml"
  ],
  [
    "Makuri Naga",
    "jmn"
  ],
  [
    "Kamara",
    "jmr"
  ],
  [
    "Mashi (Nigeria)",
    "jms"
  ],
  [
    "Mouwase",
    "jmw"
  ],
  [
    "Western Juxtlahuaca Mixtec",
    "jmx"
  ],
  [
    "Jangshung",
    "jna"
  ],
  [
    "Jandavra",
    "jnd"
  ],
  [
    "Yangman",
    "jng"
  ],
  [
    "Janji",
    "jni"
  ],
  [
    "Yemsa",
    "jnj"
  ],
  [
    "Rawat",
    "jnl"
  ],
  [
    "Jaunsari",
    "jns"
  ],
  [
    "Joba",
    "job"
  ],
  [
    "Wojenaka",
    "jod"
  ],
  [
    "Jogi",
    "jog"
  ],
  [
    "Jorá",
    "jor"
  ],
  [
    "Jordanian Sign Language",
    "jos"
  ],
  [
    "Jowulu",
    "jow"
  ],
  [
    "Jewish Palestinian Aramaic",
    "jpa"
  ],
  [
    "Japanese",
    "jpn",
    "jpn",
    "jpn",
    "ja",
    "日本語"
  ],
  [
    "Judeo-Persian",
    "jpr",
    "jpr",
    "jpr"
  ],
  [
    "Jaqaru",
    "jqr"
  ],
  [
    "Jarai",
    "jra"
  ],
  [
    "Judeo-Arabic",
    "jrb",
    "jrb",
    "jrb"
  ],
  [
    "Jiru",
    "jrr"
  ],
  [
    "Jakattoe",
    "jrt"
  ],
  [
    "Japrería",
    "jru"
  ],
  [
    "Japanese Sign Language",
    "jsl"
  ],
  [
    "Júma",
    "jua"
  ],
  [
    "Wannu",
    "jub"
  ],
  [
    "Jurchen",
    "juc"
  ],
  [
    "Worodougou",
    "jud"
  ],
  [
    "Hõne",
    "juh"
  ],
  [
    "Ngadjuri",
    "jui"
  ],
  [
    "Wapan",
    "juk"
  ],
  [
    "Jirel",
    "jul"
  ],
  [
    "Jumjum",
    "jum"
  ],
  [
    "Juang",
    "jun"
  ],
  [
    "Jiba",
    "juo"
  ],
  [
    "Hupdë",
    "jup"
  ],
  [
    "Jurúna",
    "jur"
  ],
  [
    "Jumla Sign Language",
    "jus"
  ],
  [
    "Jutish",
    "jut"
  ],
  [
    "Ju",
    "juu"
  ],
  [
    "Wãpha",
    "juw"
  ],
  [
    "Juray",
    "juy"
  ],
  [
    "Javindo",
    "jvd"
  ],
  [
    "Caribbean Javanese",
    "jvn"
  ],
  [
    "Jwira-Pepesa",
    "jwi"
  ],
  [
    "Jiarong",
    "jya"
  ],
  [
    "Judeo-Yemeni Arabic",
    "jye"
  ],
  [
    "Jaya",
    "jyy"
  ],
  [
    "Kara-Kalpak",
    "kaa",
    "kaa",
    "kaa"
  ],
  [
    "Kabyle",
    "kab",
    "kab",
    "kab"
  ],
  [
    "Kachin",
    "kac",
    "kac",
    "kac"
  ],
  [
    "Adara",
    "kad"
  ],
  [
    "Ketangalan",
    "kae"
  ],
  [
    "Katso",
    "kaf"
  ],
  [
    "Kajaman",
    "kag"
  ],
  [
    "Kara (Central African Republic)",
    "kah"
  ],
  [
    "Karekare",
    "kai"
  ],
  [
    "Jju",
    "kaj"
  ],
  [
    "Kalanguya",
    "kak"
  ],
  [
    "Kalaallisut",
    "kal",
    "kal",
    "kal",
    "kl",
    "Kalaallisut"
  ],
  [
    "Kamba (Kenya)",
    "kam",
    "kam",
    "kam"
  ],
  [
    "Kannada",
    "kan",
    "kan",
    "kan",
    "kn",
    "ಕನ್ನಡ"
  ],
  [
    "Xaasongaxango",
    "kao"
  ],
  [
    "Bezhta",
    "kap"
  ],
  [
    "Capanahua",
    "kaq"
  ],
  [
    "Kashmiri",
    "kas",
    "kas",
    "kas",
    "ks",
    "कश्मीरी / كشميري"
  ],
  [
    "Georgian",
    "kat",
    "geo",
    "kat",
    "ka",
    "ქართული"
  ],
  [
    "Kanuri",
    "kau",
    "kau",
    "kau",
    "kr",
    "Kanuri"
  ],
  [
    "Katukína",
    "kav"
  ],
  [
    "Kawi",
    "kaw",
    "kaw",
    "kaw"
  ],
  [
    "Kao",
    "kax"
  ],
  [
    "Kamayurá",
    "kay"
  ],
  [
    "Kazakh",
    "kaz",
    "kaz",
    "kaz",
    "kk",
    "Қазақша"
  ],
  [
    "Kalarko",
    "kba"
  ],
  [
    "Kaxuiâna",
    "kbb"
  ],
  [
    "Kadiwéu",
    "kbc"
  ],
  [
    "Kabardian",
    "kbd",
    "kbd",
    "kbd"
  ],
  [
    "Kanju",
    "kbe"
  ],
  [
    "Khamba",
    "kbg"
  ],
  [
    "Camsá",
    "kbh"
  ],
  [
    "Kaptiau",
    "kbi"
  ],
  [
    "Kari",
    "kbj"
  ],
  [
    "Grass Koiari",
    "kbk"
  ],
  [
    "Kanembu",
    "kbl"
  ],
  [
    "Iwal",
    "kbm"
  ],
  [
    "Kare (Central African Republic)",
    "kbn"
  ],
  [
    "Keliko",
    "kbo"
  ],
  [
    "Kabiyè",
    "kbp"
  ],
  [
    "Kamano",
    "kbq"
  ],
  [
    "Kafa",
    "kbr"
  ],
  [
    "Kande",
    "kbs"
  ],
  [
    "Abadi",
    "kbt"
  ],
  [
    "Kabutra",
    "kbu"
  ],
  [
    "Dera (Indonesia)",
    "kbv"
  ],
  [
    "Kaiep",
    "kbw"
  ],
  [
    "Ap Ma",
    "kbx"
  ],
  [
    "Manga Kanuri",
    "kby"
  ],
  [
    "Duhwa",
    "kbz"
  ],
  [
    "Khanty",
    "kca"
  ],
  [
    "Kawacha",
    "kcb"
  ],
  [
    "Lubila",
    "kcc"
  ],
  [
    "Ngkâlmpw Kanum",
    "kcd"
  ],
  [
    "Kaivi",
    "kce"
  ],
  [
    "Ukaan",
    "kcf"
  ],
  [
    "Tyap",
    "kcg"
  ],
  [
    "Vono",
    "kch"
  ],
  [
    "Kamantan",
    "kci"
  ],
  [
    "Kobiana",
    "kcj"
  ],
  [
    "Kalanga",
    "kck"
  ],
  [
    "Kela (Papua New Guinea)",
    "kcl"
  ],
  [
    "Gula (Central African Republic)",
    "kcm"
  ],
  [
    "Nubi",
    "kcn"
  ],
  [
    "Kinalakna",
    "kco"
  ],
  [
    "Kanga",
    "kcp"
  ],
  [
    "Kamo",
    "kcq"
  ],
  [
    "Katla",
    "kcr"
  ],
  [
    "Koenoem",
    "kcs"
  ],
  [
    "Kaian",
    "kct"
  ],
  [
    "Kami (Tanzania)",
    "kcu"
  ],
  [
    "Kete",
    "kcv"
  ],
  [
    "Kabwari",
    "kcw"
  ],
  [
    "Kachama-Ganjule",
    "kcx"
  ],
  [
    "Korandje",
    "kcy"
  ],
  [
    "Konongo",
    "kcz"
  ],
  [
    "Worimi",
    "kda"
  ],
  [
    "Kutu",
    "kdc"
  ],
  [
    "Yankunytjatjara",
    "kdd"
  ],
  [
    "Makonde",
    "kde"
  ],
  [
    "Mamusi",
    "kdf"
  ],
  [
    "Seba",
    "kdg"
  ],
  [
    "Tem",
    "kdh"
  ],
  [
    "Kumam",
    "kdi"
  ],
  [
    "Karamojong",
    "kdj"
  ],
  [
    "Numèè",
    "kdk"
  ],
  [
    "Tsikimba",
    "kdl"
  ],
  [
    "Kagoma",
    "kdm"
  ],
  [
    "Kunda",
    "kdn"
  ],
  [
    "Kaningdon-Nindem",
    "kdp"
  ],
  [
    "Koch",
    "kdq"
  ],
  [
    "Karaim",
    "kdr"
  ],
  [
    "Kuy",
    "kdt"
  ],
  [
    "Kadaru",
    "kdu"
  ],
  [
    "Koneraw",
    "kdw"
  ],
  [
    "Kam",
    "kdx"
  ],
  [
    "Keder",
    "kdy"
  ],
  [
    "Kwaja",
    "kdz"
  ],
  [
    "Kabuverdianu",
    "kea"
  ],
  [
    "Kélé",
    "keb"
  ],
  [
    "Keiga",
    "kec"
  ],
  [
    "Kerewe",
    "ked"
  ],
  [
    "Eastern Keres",
    "kee"
  ],
  [
    "Kpessi",
    "kef"
  ],
  [
    "Tese",
    "keg"
  ],
  [
    "Keak",
    "keh"
  ],
  [
    "Kei",
    "kei"
  ],
  [
    "Kadar",
    "kej"
  ],
  [
    "Kekchí",
    "kek"
  ],
  [
    "Kela (Democratic Republic of Congo)",
    "kel"
  ],
  [
    "Kemak",
    "kem"
  ],
  [
    "Kenyang",
    "ken"
  ],
  [
    "Kakwa",
    "keo"
  ],
  [
    "Kaikadi",
    "kep"
  ],
  [
    "Kamar",
    "keq"
  ],
  [
    "Kera",
    "ker"
  ],
  [
    "Kugbo",
    "kes"
  ],
  [
    "Ket",
    "ket"
  ],
  [
    "Akebu",
    "keu"
  ],
  [
    "Kanikkaran",
    "kev"
  ],
  [
    "West Kewa",
    "kew"
  ],
  [
    "Kukna",
    "kex"
  ],
  [
    "Kupia",
    "key"
  ],
  [
    "Kukele",
    "kez"
  ],
  [
    "Kodava",
    "kfa"
  ],
  [
    "Northwestern Kolami",
    "kfb"
  ],
  [
    "Konda-Dora",
    "kfc"
  ],
  [
    "Korra Koraga",
    "kfd"
  ],
  [
    "Kota (India)",
    "kfe"
  ],
  [
    "Koya",
    "kff"
  ],
  [
    "Kudiya",
    "kfg"
  ],
  [
    "Kurichiya",
    "kfh"
  ],
  [
    "Kannada Kurumba",
    "kfi"
  ],
  [
    "Kemiehua",
    "kfj"
  ],
  [
    "Kinnauri",
    "kfk"
  ],
  [
    "Kung",
    "kfl"
  ],
  [
    "Khunsari",
    "kfm"
  ],
  [
    "Kuk",
    "kfn"
  ],
  [
    "Koro (Côte d'Ivoire)",
    "kfo"
  ],
  [
    "Korwa",
    "kfp"
  ],
  [
    "Korku",
    "kfq"
  ],
  [
    "Kachhi",
    "kfr"
  ],
  [
    "Bilaspuri",
    "kfs"
  ],
  [
    "Kanjari",
    "kft"
  ],
  [
    "Katkari",
    "kfu"
  ],
  [
    "Kurmukar",
    "kfv"
  ],
  [
    "Kharam Naga",
    "kfw"
  ],
  [
    "Kullu Pahari",
    "kfx"
  ],
  [
    "Kumaoni",
    "kfy"
  ],
  [
    "Koromfé",
    "kfz"
  ],
  [
    "Koyaga",
    "kga"
  ],
  [
    "Kawe",
    "kgb"
  ],
  [
    "Komering",
    "kge"
  ],
  [
    "Kube",
    "kgf"
  ],
  [
    "Kusunda",
    "kgg"
  ],
  [
    "Selangor Sign Language",
    "kgi"
  ],
  [
    "Gamale Kham",
    "kgj"
  ],
  [
    "Kaiwá",
    "kgk"
  ],
  [
    "Kunggari",
    "kgl"
  ],
  [
    "Karipúna",
    "kgm"
  ],
  [
    "Karingani",
    "kgn"
  ],
  [
    "Krongo",
    "kgo"
  ],
  [
    "Kaingang",
    "kgp"
  ],
  [
    "Kamoro",
    "kgq"
  ],
  [
    "Abun",
    "kgr"
  ],
  [
    "Kumbainggar",
    "kgs"
  ],
  [
    "Somyev",
    "kgt"
  ],
  [
    "Kobol",
    "kgu"
  ],
  [
    "Karas",
    "kgv"
  ],
  [
    "Karon Dori",
    "kgw"
  ],
  [
    "Kamaru",
    "kgx"
  ],
  [
    "Kyerung",
    "kgy"
  ],
  [
    "Khasi",
    "kha",
    "kha",
    "kha"
  ],
  [
    "Lü",
    "khb"
  ],
  [
    "Tukang Besi North",
    "khc"
  ],
  [
    "Bädi Kanum",
    "khd"
  ],
  [
    "Korowai",
    "khe"
  ],
  [
    "Khuen",
    "khf"
  ],
  [
    "Khams Tibetan",
    "khg"
  ],
  [
    "Kehu",
    "khh"
  ],
  [
    "Kuturmi",
    "khj"
  ],
  [
    "Halh Mongolian",
    "khk"
  ],
  [
    "Lusi",
    "khl"
  ],
  [
    "Khmer",
    "khm",
    "khm",
    "khm",
    "km",
    "ភាសាខ្មែរ"
  ],
  [
    "Khandesi",
    "khn"
  ],
  [
    "Khotanese",
    "kho",
    "kho",
    "kho"
  ],
  [
    "Kapori",
    "khp"
  ],
  [
    "Koyra Chiini Songhay",
    "khq"
  ],
  [
    "Kharia",
    "khr"
  ],
  [
    "Kasua",
    "khs"
  ],
  [
    "Khamti",
    "kht"
  ],
  [
    "Nkhumbi",
    "khu"
  ],
  [
    "Khvarshi",
    "khv"
  ],
  [
    "Khowar",
    "khw"
  ],
  [
    "Kanu",
    "khx"
  ],
  [
    "Kele (Democratic Republic of Congo)",
    "khy"
  ],
  [
    "Keapara",
    "khz"
  ],
  [
    "Kim",
    "kia"
  ],
  [
    "Koalib",
    "kib"
  ],
  [
    "Kickapoo",
    "kic"
  ],
  [
    "Koshin",
    "kid"
  ],
  [
    "Kibet",
    "kie"
  ],
  [
    "Eastern Parbate Kham",
    "kif"
  ],
  [
    "Kimaama",
    "kig"
  ],
  [
    "Kilmeri",
    "kih"
  ],
  [
    "Kitsai",
    "kii"
  ],
  [
    "Kilivila",
    "kij"
  ],
  [
    "Kikuyu",
    "kik",
    "kik",
    "kik",
    "ki",
    "Gĩkũyũ"
  ],
  [
    "Kariya",
    "kil"
  ],
  [
    "Karagas",
    "kim"
  ],
  [
    "Kinyarwanda",
    "kin",
    "kin",
    "kin",
    "rw",
    "Kinyarwandi"
  ],
  [
    "Kiowa",
    "kio"
  ],
  [
    "Sheshi Kham",
    "kip"
  ],
  [
    "Kosadle",
    "kiq"
  ],
  [
    "Kirghiz",
    "kir",
    "kir",
    "kir",
    "ky",
    "Kırgızca / Кыргызча"
  ],
  [
    "Kis",
    "kis"
  ],
  [
    "Agob",
    "kit"
  ],
  [
    "Kirmanjki (individual language)",
    "kiu"
  ],
  [
    "Kimbu",
    "kiv"
  ],
  [
    "Northeast Kiwai",
    "kiw"
  ],
  [
    "Khiamniungan Naga",
    "kix"
  ],
  [
    "Kirikiri",
    "kiy"
  ],
  [
    "Kisi",
    "kiz"
  ],
  [
    "Mlap",
    "kja"
  ],
  [
    "Q'anjob'al",
    "kjb"
  ],
  [
    "Coastal Konjo",
    "kjc"
  ],
  [
    "Southern Kiwai",
    "kjd"
  ],
  [
    "Kisar",
    "kje"
  ],
  [
    "Khmu",
    "kjg"
  ],
  [
    "Khakas",
    "kjh"
  ],
  [
    "Zabana",
    "kji"
  ],
  [
    "Khinalugh",
    "kjj"
  ],
  [
    "Highland Konjo",
    "kjk"
  ],
  [
    "Western Parbate Kham",
    "kjl"
  ],
  [
    "Kháng",
    "kjm"
  ],
  [
    "Kunjen",
    "kjn"
  ],
  [
    "Harijan Kinnauri",
    "kjo"
  ],
  [
    "Pwo Eastern Karen",
    "kjp"
  ],
  [
    "Western Keres",
    "kjq"
  ],
  [
    "Kurudu",
    "kjr"
  ],
  [
    "East Kewa",
    "kjs"
  ],
  [
    "Phrae Pwo Karen",
    "kjt"
  ],
  [
    "Kashaya",
    "kju"
  ],
  [
    "Kaikavian Literary Language",
    "kjv"
  ],
  [
    "Ramopa",
    "kjx"
  ],
  [
    "Erave",
    "kjy"
  ],
  [
    "Bumthangkha",
    "kjz"
  ],
  [
    "Kakanda",
    "kka"
  ],
  [
    "Kwerisa",
    "kkb"
  ],
  [
    "Odoodee",
    "kkc"
  ],
  [
    "Kinuku",
    "kkd"
  ],
  [
    "Kakabe",
    "kke"
  ],
  [
    "Kalaktang Monpa",
    "kkf"
  ],
  [
    "Mabaka Valley Kalinga",
    "kkg"
  ],
  [
    "Khün",
    "kkh"
  ],
  [
    "Kagulu",
    "kki"
  ],
  [
    "Kako",
    "kkj"
  ],
  [
    "Kokota",
    "kkk"
  ],
  [
    "Kosarek Yale",
    "kkl"
  ],
  [
    "Kiong",
    "kkm"
  ],
  [
    "Kon Keu",
    "kkn"
  ],
  [
    "Karko",
    "kko"
  ],
  [
    "Gugubera",
    "kkp"
  ],
  [
    "Kaeku",
    "kkq"
  ],
  [
    "Kir-Balar",
    "kkr"
  ],
  [
    "Giiwo",
    "kks"
  ],
  [
    "Koi",
    "kkt"
  ],
  [
    "Tumi",
    "kku"
  ],
  [
    "Kangean",
    "kkv"
  ],
  [
    "Teke-Kukuya",
    "kkw"
  ],
  [
    "Kohin",
    "kkx"
  ],
  [
    "Guugu Yimidhirr",
    "kky"
  ],
  [
    "Kaska",
    "kkz"
  ],
  [
    "Klamath-Modoc",
    "kla"
  ],
  [
    "Kiliwa",
    "klb"
  ],
  [
    "Kolbila",
    "klc"
  ],
  [
    "Gamilaraay",
    "kld"
  ],
  [
    "Kulung (Nepal)",
    "kle"
  ],
  [
    "Kendeje",
    "klf"
  ],
  [
    "Tagakaulo",
    "klg"
  ],
  [
    "Weliki",
    "klh"
  ],
  [
    "Kalumpang",
    "kli"
  ],
  [
    "Khalaj",
    "klj"
  ],
  [
    "Kono (Nigeria)",
    "klk"
  ],
  [
    "Kagan Kalagan",
    "kll"
  ],
  [
    "Migum",
    "klm"
  ],
  [
    "Kalenjin",
    "kln"
  ],
  [
    "Kapya",
    "klo"
  ],
  [
    "Kamasa",
    "klp"
  ],
  [
    "Rumu",
    "klq"
  ],
  [
    "Khaling",
    "klr"
  ],
  [
    "Kalasha",
    "kls"
  ],
  [
    "Nukna",
    "klt"
  ],
  [
    "Klao",
    "klu"
  ],
  [
    "Maskelynes",
    "klv"
  ],
  [
    "Tado",
    "klw"
  ],
  [
    "Koluwawa",
    "klx"
  ],
  [
    "Kalao",
    "kly"
  ],
  [
    "Kabola",
    "klz"
  ],
  [
    "Konni",
    "kma"
  ],
  [
    "Kimbundu",
    "kmb",
    "kmb",
    "kmb"
  ],
  [
    "Southern Dong",
    "kmc"
  ],
  [
    "Majukayang Kalinga",
    "kmd"
  ],
  [
    "Bakole",
    "kme"
  ],
  [
    "Kare (Papua New Guinea)",
    "kmf"
  ],
  [
    "Kâte",
    "kmg"
  ],
  [
    "Kalam",
    "kmh"
  ],
  [
    "Kami (Nigeria)",
    "kmi"
  ],
  [
    "Kumarbhag Paharia",
    "kmj"
  ],
  [
    "Limos Kalinga",
    "kmk"
  ],
  [
    "Tanudan Kalinga",
    "kml"
  ],
  [
    "Kom (India)",
    "kmm"
  ],
  [
    "Awtuw",
    "kmn"
  ],
  [
    "Kwoma",
    "kmo"
  ],
  [
    "Gimme",
    "kmp"
  ],
  [
    "Kwama",
    "kmq"
  ],
  [
    "Northern Kurdish",
    "kmr"
  ],
  [
    "Kamasau",
    "kms"
  ],
  [
    "Kemtuik",
    "kmt"
  ],
  [
    "Kanite",
    "kmu"
  ],
  [
    "Karipúna Creole French",
    "kmv"
  ],
  [
    "Komo (Democratic Republic of Congo)",
    "kmw"
  ],
  [
    "Waboda",
    "kmx"
  ],
  [
    "Koma",
    "kmy"
  ],
  [
    "Khorasani Turkish",
    "kmz"
  ],
  [
    "Dera (Nigeria)",
    "kna"
  ],
  [
    "Lubuagan Kalinga",
    "knb"
  ],
  [
    "Central Kanuri",
    "knc"
  ],
  [
    "Konda",
    "knd"
  ],
  [
    "Kankanaey",
    "kne"
  ],
  [
    "Mankanya",
    "knf"
  ],
  [
    "Koongo",
    "kng"
  ],
  [
    "Kanufi",
    "kni"
  ],
  [
    "Western Kanjobal",
    "knj"
  ],
  [
    "Kuranko",
    "knk"
  ],
  [
    "Keninjal",
    "knl"
  ],
  [
    "Kanamarí",
    "knm"
  ],
  [
    "Konkani (individual language)",
    "knn"
  ],
  [
    "Kono (Sierra Leone)",
    "kno"
  ],
  [
    "Kwanja",
    "knp"
  ],
  [
    "Kintaq",
    "knq"
  ],
  [
    "Kaningra",
    "knr"
  ],
  [
    "Kensiu",
    "kns"
  ],
  [
    "Panoan Katukína",
    "knt"
  ],
  [
    "Kono (Guinea)",
    "knu"
  ],
  [
    "Tabo",
    "knv"
  ],
  [
    "Kung-Ekoka",
    "knw"
  ],
  [
    "Kendayan",
    "knx"
  ],
  [
    "Kanyok",
    "kny"
  ],
  [
    "Kalamsé",
    "knz"
  ],
  [
    "Konomala",
    "koa"
  ],
  [
    "Kpati",
    "koc"
  ],
  [
    "Kodi",
    "kod"
  ],
  [
    "Kacipo-Bale Suri",
    "koe"
  ],
  [
    "Kubi",
    "kof"
  ],
  [
    "Cogui",
    "kog"
  ],
  [
    "Koyo",
    "koh"
  ],
  [
    "Komi-Permyak",
    "koi"
  ],
  [
    "Konkani (macrolanguage)",
    "kok",
    "kok",
    "kok"
  ],
  [
    "Kol (Papua New Guinea)",
    "kol"
  ],
  [
    "Komi",
    "kom",
    "kom",
    "kom",
    "kv",
    "Коми"
  ],
  [
    "Kongo",
    "kon",
    "kon",
    "kon",
    "kg",
    "KiKongo"
  ],
  [
    "Konzo",
    "koo"
  ],
  [
    "Waube",
    "kop"
  ],
  [
    "Kota (Gabon)",
    "koq"
  ],
  [
    "Korean",
    "kor",
    "kor",
    "kor",
    "ko",
    "한국어"
  ],
  [
    "Kosraean",
    "kos",
    "kos",
    "kos"
  ],
  [
    "Lagwan",
    "kot"
  ],
  [
    "Koke",
    "kou"
  ],
  [
    "Kudu-Camo",
    "kov"
  ],
  [
    "Kugama",
    "kow"
  ],
  [
    "Koyukon",
    "koy"
  ],
  [
    "Korak",
    "koz"
  ],
  [
    "Kutto",
    "kpa"
  ],
  [
    "Mullu Kurumba",
    "kpb"
  ],
  [
    "Curripaco",
    "kpc"
  ],
  [
    "Koba",
    "kpd"
  ],
  [
    "Kpelle",
    "kpe",
    "kpe",
    "kpe"
  ],
  [
    "Komba",
    "kpf"
  ],
  [
    "Kapingamarangi",
    "kpg"
  ],
  [
    "Kplang",
    "kph"
  ],
  [
    "Kofei",
    "kpi"
  ],
  [
    "Karajá",
    "kpj"
  ],
  [
    "Kpan",
    "kpk"
  ],
  [
    "Kpala",
    "kpl"
  ],
  [
    "Koho",
    "kpm"
  ],
  [
    "Kepkiriwát",
    "kpn"
  ],
  [
    "Ikposo",
    "kpo"
  ],
  [
    "Korupun-Sela",
    "kpq"
  ],
  [
    "Korafe-Yegha",
    "kpr"
  ],
  [
    "Tehit",
    "kps"
  ],
  [
    "Karata",
    "kpt"
  ],
  [
    "Kafoa",
    "kpu"
  ],
  [
    "Komi-Zyrian",
    "kpv"
  ],
  [
    "Kobon",
    "kpw"
  ],
  [
    "Mountain Koiali",
    "kpx"
  ],
  [
    "Koryak",
    "kpy"
  ],
  [
    "Kupsabiny",
    "kpz"
  ],
  [
    "Mum",
    "kqa"
  ],
  [
    "Kovai",
    "kqb"
  ],
  [
    "Doromu-Koki",
    "kqc"
  ],
  [
    "Koy Sanjaq Surat",
    "kqd"
  ],
  [
    "Kalagan",
    "kqe"
  ],
  [
    "Kakabai",
    "kqf"
  ],
  [
    "Khe",
    "kqg"
  ],
  [
    "Kisankasa",
    "kqh"
  ],
  [
    "Koitabu",
    "kqi"
  ],
  [
    "Koromira",
    "kqj"
  ],
  [
    "Kotafon Gbe",
    "kqk"
  ],
  [
    "Kyenele",
    "kql"
  ],
  [
    "Khisa",
    "kqm"
  ],
  [
    "Kaonde",
    "kqn"
  ],
  [
    "Eastern Krahn",
    "kqo"
  ],
  [
    "Kimré",
    "kqp"
  ],
  [
    "Krenak",
    "kqq"
  ],
  [
    "Kimaragang",
    "kqr"
  ],
  [
    "Northern Kissi",
    "kqs"
  ],
  [
    "Klias River Kadazan",
    "kqt"
  ],
  [
    "Seroa",
    "kqu"
  ],
  [
    "Okolod",
    "kqv"
  ],
  [
    "Kandas",
    "kqw"
  ],
  [
    "Mser",
    "kqx"
  ],
  [
    "Koorete",
    "kqy"
  ],
  [
    "Korana",
    "kqz"
  ],
  [
    "Kumhali",
    "kra"
  ],
  [
    "Karkin",
    "krb"
  ],
  [
    "Karachay-Balkar",
    "krc",
    "krc",
    "krc"
  ],
  [
    "Kairui-Midiki",
    "krd"
  ],
  [
    "Panará",
    "kre"
  ],
  [
    "Koro (Vanuatu)",
    "krf"
  ],
  [
    "Kurama",
    "krh"
  ],
  [
    "Krio",
    "kri"
  ],
  [
    "Kinaray-A",
    "krj"
  ],
  [
    "Kerek",
    "krk"
  ],
  [
    "Karelian",
    "krl",
    "krl",
    "krl"
  ],
  [
    "Sapo",
    "krn"
  ],
  [
    "Korop",
    "krp"
  ],
  [
    "Krung",
    "krr"
  ],
  [
    "Gbaya (Sudan)",
    "krs"
  ],
  [
    "Tumari Kanuri",
    "krt"
  ],
  [
    "Kurukh",
    "kru",
    "kru",
    "kru"
  ],
  [
    "Kavet",
    "krv"
  ],
  [
    "Western Krahn",
    "krw"
  ],
  [
    "Karon",
    "krx"
  ],
  [
    "Kryts",
    "kry"
  ],
  [
    "Sota Kanum",
    "krz"
  ],
  [
    "Shuwa-Zamani",
    "ksa"
  ],
  [
    "Shambala",
    "ksb"
  ],
  [
    "Southern Kalinga",
    "ksc"
  ],
  [
    "Kuanua",
    "ksd"
  ],
  [
    "Kuni",
    "kse"
  ],
  [
    "Bafia",
    "ksf"
  ],
  [
    "Kusaghe",
    "ksg"
  ],
  [
    "Kölsch",
    "ksh"
  ],
  [
    "Krisa",
    "ksi"
  ],
  [
    "Uare",
    "ksj"
  ],
  [
    "Kansa",
    "ksk"
  ],
  [
    "Kumalu",
    "ksl"
  ],
  [
    "Kumba",
    "ksm"
  ],
  [
    "Kasiguranin",
    "ksn"
  ],
  [
    "Kofa",
    "kso"
  ],
  [
    "Kaba",
    "ksp"
  ],
  [
    "Kwaami",
    "ksq"
  ],
  [
    "Borong",
    "ksr"
  ],
  [
    "Southern Kisi",
    "kss"
  ],
  [
    "Winyé",
    "kst"
  ],
  [
    "Khamyang",
    "ksu"
  ],
  [
    "Kusu",
    "ksv"
  ],
  [
    "S'gaw Karen",
    "ksw"
  ],
  [
    "Kedang",
    "ksx"
  ],
  [
    "Kharia Thar",
    "ksy"
  ],
  [
    "Kodaku",
    "ksz"
  ],
  [
    "Katua",
    "kta"
  ],
  [
    "Kambaata",
    "ktb"
  ],
  [
    "Kholok",
    "ktc"
  ],
  [
    "Kokata",
    "ktd"
  ],
  [
    "Nubri",
    "kte"
  ],
  [
    "Kwami",
    "ktf"
  ],
  [
    "Kalkutung",
    "ktg"
  ],
  [
    "Karanga",
    "kth"
  ],
  [
    "North Muyu",
    "kti"
  ],
  [
    "Plapo Krumen",
    "ktj"
  ],
  [
    "Kaniet",
    "ktk"
  ],
  [
    "Koroshi",
    "ktl"
  ],
  [
    "Kurti",
    "ktm"
  ],
  [
    "Karitiâna",
    "ktn"
  ],
  [
    "Kuot",
    "kto"
  ],
  [
    "Kaduo",
    "ktp"
  ],
  [
    "Katabaga",
    "ktq"
  ],
  [
    "South Muyu",
    "kts"
  ],
  [
    "Ketum",
    "ktt"
  ],
  [
    "Kituba (Democratic Republic of Congo)",
    "ktu"
  ],
  [
    "Eastern Katu",
    "ktv"
  ],
  [
    "Kato",
    "ktw"
  ],
  [
    "Kaxararí",
    "ktx"
  ],
  [
    "Kango (Bas-Uélé District)",
    "kty"
  ],
  [
    "Juǀʼhoan",
    "ktz"
  ],
  [
    "Kuanyama",
    "kua",
    "kua",
    "kua",
    "kj",
    "Kuanyama"
  ],
  [
    "Kutep",
    "kub"
  ],
  [
    "Kwinsu",
    "kuc"
  ],
  [
    "'Auhelawa",
    "kud"
  ],
  [
    "Kuman (Papua New Guinea)",
    "kue"
  ],
  [
    "Western Katu",
    "kuf"
  ],
  [
    "Kupa",
    "kug"
  ],
  [
    "Kushi",
    "kuh"
  ],
  [
    "Kuikúro-Kalapálo",
    "kui"
  ],
  [
    "Kuria",
    "kuj"
  ],
  [
    "Kepo'",
    "kuk"
  ],
  [
    "Kulere",
    "kul"
  ],
  [
    "Kumyk",
    "kum",
    "kum",
    "kum"
  ],
  [
    "Kunama",
    "kun"
  ],
  [
    "Kumukio",
    "kuo"
  ],
  [
    "Kunimaipa",
    "kup"
  ],
  [
    "Karipuna",
    "kuq"
  ],
  [
    "Kurdish",
    "kur",
    "kur",
    "kur",
    "ku",
    "Kurdî / كوردی"
  ],
  [
    "Kusaal",
    "kus"
  ],
  [
    "Kutenai",
    "kut",
    "kut",
    "kut"
  ],
  [
    "Upper Kuskokwim",
    "kuu"
  ],
  [
    "Kur",
    "kuv"
  ],
  [
    "Kpagua",
    "kuw"
  ],
  [
    "Kukatja",
    "kux"
  ],
  [
    "Kuuku-Ya'u",
    "kuy"
  ],
  [
    "Kunza",
    "kuz"
  ],
  [
    "Bagvalal",
    "kva"
  ],
  [
    "Kubu",
    "kvb"
  ],
  [
    "Kove",
    "kvc"
  ],
  [
    "Kui (Indonesia)",
    "kvd"
  ],
  [
    "Kalabakan",
    "kve"
  ],
  [
    "Kabalai",
    "kvf"
  ],
  [
    "Kuni-Boazi",
    "kvg"
  ],
  [
    "Komodo",
    "kvh"
  ],
  [
    "Kwang",
    "kvi"
  ],
  [
    "Psikye",
    "kvj"
  ],
  [
    "Korean Sign Language",
    "kvk"
  ],
  [
    "Kayaw",
    "kvl"
  ],
  [
    "Kendem",
    "kvm"
  ],
  [
    "Border Kuna",
    "kvn"
  ],
  [
    "Dobel",
    "kvo"
  ],
  [
    "Kompane",
    "kvp"
  ],
  [
    "Geba Karen",
    "kvq"
  ],
  [
    "Kerinci",
    "kvr"
  ],
  [
    "Lahta Karen",
    "kvt"
  ],
  [
    "Yinbaw Karen",
    "kvu"
  ],
  [
    "Kola",
    "kvv"
  ],
  [
    "Wersing",
    "kvw"
  ],
  [
    "Parkari Koli",
    "kvx"
  ],
  [
    "Yintale Karen",
    "kvy"
  ],
  [
    "Tsakwambo",
    "kvz"
  ],
  [
    "Dâw",
    "kwa"
  ],
  [
    "Kwa",
    "kwb"
  ],
  [
    "Likwala",
    "kwc"
  ],
  [
    "Kwaio",
    "kwd"
  ],
  [
    "Kwerba",
    "kwe"
  ],
  [
    "Kwara'ae",
    "kwf"
  ],
  [
    "Sara Kaba Deme",
    "kwg"
  ],
  [
    "Kowiai",
    "kwh"
  ],
  [
    "Awa-Cuaiquer",
    "kwi"
  ],
  [
    "Kwanga",
    "kwj"
  ],
  [
    "Kwakiutl",
    "kwk"
  ],
  [
    "Kofyar",
    "kwl"
  ],
  [
    "Kwambi",
    "kwm"
  ],
  [
    "Kwangali",
    "kwn"
  ],
  [
    "Kwomtari",
    "kwo"
  ],
  [
    "Kodia",
    "kwp"
  ],
  [
    "Kwer",
    "kwr"
  ],
  [
    "Kwese",
    "kws"
  ],
  [
    "Kwesten",
    "kwt"
  ],
  [
    "Kwakum",
    "kwu"
  ],
  [
    "Sara Kaba Náà",
    "kwv"
  ],
  [
    "Kwinti",
    "kww"
  ],
  [
    "Khirwar",
    "kwx"
  ],
  [
    "San Salvador Kongo",
    "kwy"
  ],
  [
    "Kwadi",
    "kwz"
  ],
  [
    "Kairiru",
    "kxa"
  ],
  [
    "Krobu",
    "kxb"
  ],
  [
    "Konso",
    "kxc"
  ],
  [
    "Brunei",
    "kxd"
  ],
  [
    "Manumanaw Karen",
    "kxf"
  ],
  [
    "Karo (Ethiopia)",
    "kxh"
  ],
  [
    "Keningau Murut",
    "kxi"
  ],
  [
    "Kulfa",
    "kxj"
  ],
  [
    "Zayein Karen",
    "kxk"
  ],
  [
    "Northern Khmer",
    "kxm"
  ],
  [
    "Kanowit-Tanjong Melanau",
    "kxn"
  ],
  [
    "Kanoé",
    "kxo"
  ],
  [
    "Wadiyara Koli",
    "kxp"
  ],
  [
    "Smärky Kanum",
    "kxq"
  ],
  [
    "Koro (Papua New Guinea)",
    "kxr"
  ],
  [
    "Kangjia",
    "kxs"
  ],
  [
    "Koiwat",
    "kxt"
  ],
  [
    "Kuvi",
    "kxv"
  ],
  [
    "Konai",
    "kxw"
  ],
  [
    "Likuba",
    "kxx"
  ],
  [
    "Kayong",
    "kxy"
  ],
  [
    "Kerewo",
    "kxz"
  ],
  [
    "Kwaya",
    "kya"
  ],
  [
    "Butbut Kalinga",
    "kyb"
  ],
  [
    "Kyaka",
    "kyc"
  ],
  [
    "Karey",
    "kyd"
  ],
  [
    "Krache",
    "kye"
  ],
  [
    "Kouya",
    "kyf"
  ],
  [
    "Keyagana",
    "kyg"
  ],
  [
    "Karok",
    "kyh"
  ],
  [
    "Kiput",
    "kyi"
  ],
  [
    "Karao",
    "kyj"
  ],
  [
    "Kamayo",
    "kyk"
  ],
  [
    "Kalapuya",
    "kyl"
  ],
  [
    "Kpatili",
    "kym"
  ],
  [
    "Northern Binukidnon",
    "kyn"
  ],
  [
    "Kelon",
    "kyo"
  ],
  [
    "Kang",
    "kyp"
  ],
  [
    "Kenga",
    "kyq"
  ],
  [
    "Kuruáya",
    "kyr"
  ],
  [
    "Baram Kayan",
    "kys"
  ],
  [
    "Kayagar",
    "kyt"
  ],
  [
    "Western Kayah",
    "kyu"
  ],
  [
    "Kayort",
    "kyv"
  ],
  [
    "Kudmali",
    "kyw"
  ],
  [
    "Rapoisi",
    "kyx"
  ],
  [
    "Kambaira",
    "kyy"
  ],
  [
    "Kayabí",
    "kyz"
  ],
  [
    "Western Karaboro",
    "kza"
  ],
  [
    "Kaibobo",
    "kzb"
  ],
  [
    "Bondoukou Kulango",
    "kzc"
  ],
  [
    "Kadai",
    "kzd"
  ],
  [
    "Kosena",
    "kze"
  ],
  [
    "Da'a Kaili",
    "kzf"
  ],
  [
    "Kikai",
    "kzg"
  ],
  [
    "Kelabit",
    "kzi"
  ],
  [
    "Kazukuru",
    "kzk"
  ],
  [
    "Kayeli",
    "kzl"
  ],
  [
    "Kais",
    "kzm"
  ],
  [
    "Kokola",
    "kzn"
  ],
  [
    "Kaningi",
    "kzo"
  ],
  [
    "Kaidipang",
    "kzp"
  ],
  [
    "Kaike",
    "kzq"
  ],
  [
    "Karang",
    "kzr"
  ],
  [
    "Sugut Dusun",
    "kzs"
  ],
  [
    "Kayupulau",
    "kzu"
  ],
  [
    "Komyandaret",
    "kzv"
  ],
  [
    "Karirí-Xocó",
    "kzw"
  ],
  [
    "Kamarian",
    "kzx"
  ],
  [
    "Kango (Tshopo District)",
    "kzy"
  ],
  [
    "Kalabra",
    "kzz"
  ],
  [
    "Southern Subanen",
    "laa"
  ],
  [
    "Linear A",
    "lab"
  ],
  [
    "Lacandon",
    "lac"
  ],
  [
    "Ladino",
    "lad",
    "lad",
    "lad"
  ],
  [
    "Pattani",
    "lae"
  ],
  [
    "Lafofa",
    "laf"
  ],
  [
    "Langi",
    "lag"
  ],
  [
    "Lahnda",
    "lah",
    "lah",
    "lah"
  ],
  [
    "Lambya",
    "lai"
  ],
  [
    "Lango (Uganda)",
    "laj"
  ],
  [
    "Lalia",
    "lal"
  ],
  [
    "Lamba",
    "lam",
    "lam",
    "lam"
  ],
  [
    "Laru",
    "lan"
  ],
  [
    "Lao",
    "lao",
    "lao",
    "lao",
    "lo",
    "ລາວ / Pha xa lao"
  ],
  [
    "Laka (Chad)",
    "lap"
  ],
  [
    "Qabiao",
    "laq"
  ],
  [
    "Larteh",
    "lar"
  ],
  [
    "Lama (Togo)",
    "las"
  ],
  [
    "Latin",
    "lat",
    "lat",
    "lat",
    "la",
    "Latina"
  ],
  [
    "Laba",
    "lau"
  ],
  [
    "Latvian",
    "lav",
    "lav",
    "lav",
    "lv",
    "Latviešu"
  ],
  [
    "Lauje",
    "law"
  ],
  [
    "Tiwa",
    "lax"
  ],
  [
    "Lama Bai",
    "lay"
  ],
  [
    "Aribwatsa",
    "laz"
  ],
  [
    "Label",
    "lbb"
  ],
  [
    "Lakkia",
    "lbc"
  ],
  [
    "Lak",
    "lbe"
  ],
  [
    "Tinani",
    "lbf"
  ],
  [
    "Laopang",
    "lbg"
  ],
  [
    "La'bi",
    "lbi"
  ],
  [
    "Ladakhi",
    "lbj"
  ],
  [
    "Central Bontok",
    "lbk"
  ],
  [
    "Libon Bikol",
    "lbl"
  ],
  [
    "Lodhi",
    "lbm"
  ],
  [
    "Rmeet",
    "lbn"
  ],
  [
    "Laven",
    "lbo"
  ],
  [
    "Wampar",
    "lbq"
  ],
  [
    "Lohorung",
    "lbr"
  ],
  [
    "Libyan Sign Language",
    "lbs"
  ],
  [
    "Lachi",
    "lbt"
  ],
  [
    "Labu",
    "lbu"
  ],
  [
    "Lavatbura-Lamusong",
    "lbv"
  ],
  [
    "Tolaki",
    "lbw"
  ],
  [
    "Lawangan",
    "lbx"
  ],
  [
    "Lamalama",
    "lby"
  ],
  [
    "Lardil",
    "lbz"
  ],
  [
    "Legenyem",
    "lcc"
  ],
  [
    "Lola",
    "lcd"
  ],
  [
    "Loncong",
    "lce"
  ],
  [
    "Lubu",
    "lcf"
  ],
  [
    "Luchazi",
    "lch"
  ],
  [
    "Lisela",
    "lcl"
  ],
  [
    "Tungag",
    "lcm"
  ],
  [
    "Western Lawa",
    "lcp"
  ],
  [
    "Luhu",
    "lcq"
  ],
  [
    "Lisabata-Nuniali",
    "lcs"
  ],
  [
    "Kla-Dan",
    "lda"
  ],
  [
    "Dũya",
    "ldb"
  ],
  [
    "Luri",
    "ldd"
  ],
  [
    "Lenyima",
    "ldg"
  ],
  [
    "Lamja-Dengsa-Tola",
    "ldh"
  ],
  [
    "Laari",
    "ldi"
  ],
  [
    "Lemoro",
    "ldj"
  ],
  [
    "Leelau",
    "ldk"
  ],
  [
    "Kaan",
    "ldl"
  ],
  [
    "Landoma",
    "ldm"
  ],
  [
    "Láadan",
    "ldn"
  ],
  [
    "Loo",
    "ldo"
  ],
  [
    "Tso",
    "ldp"
  ],
  [
    "Lufu",
    "ldq"
  ],
  [
    "Lega-Shabunda",
    "lea"
  ],
  [
    "Lala-Bisa",
    "leb"
  ],
  [
    "Leco",
    "lec"
  ],
  [
    "Lendu",
    "led"
  ],
  [
    "Lyélé",
    "lee"
  ],
  [
    "Lelemi",
    "lef"
  ],
  [
    "Lenje",
    "leh"
  ],
  [
    "Lemio",
    "lei"
  ],
  [
    "Lengola",
    "lej"
  ],
  [
    "Leipon",
    "lek"
  ],
  [
    "Lele (Democratic Republic of Congo)",
    "lel"
  ],
  [
    "Nomaande",
    "lem"
  ],
  [
    "Lenca",
    "len"
  ],
  [
    "Leti (Cameroon)",
    "leo"
  ],
  [
    "Lepcha",
    "lep"
  ],
  [
    "Lembena",
    "leq"
  ],
  [
    "Lenkau",
    "ler"
  ],
  [
    "Lese",
    "les"
  ],
  [
    "Lesing-Gelimi",
    "let"
  ],
  [
    "Kara (Papua New Guinea)",
    "leu"
  ],
  [
    "Lamma",
    "lev"
  ],
  [
    "Ledo Kaili",
    "lew"
  ],
  [
    "Luang",
    "lex"
  ],
  [
    "Lemolang",
    "ley"
  ],
  [
    "Lezghian",
    "lez",
    "lez",
    "lez"
  ],
  [
    "Lefa",
    "lfa"
  ],
  [
    "Lingua Franca Nova",
    "lfn"
  ],
  [
    "Lungga",
    "lga"
  ],
  [
    "Laghu",
    "lgb"
  ],
  [
    "Lugbara",
    "lgg"
  ],
  [
    "Laghuu",
    "lgh"
  ],
  [
    "Lengilu",
    "lgi"
  ],
  [
    "Lingarak",
    "lgk"
  ],
  [
    "Wala",
    "lgl"
  ],
  [
    "Lega-Mwenga",
    "lgm"
  ],
  [
    "T'apo",
    "lgn"
  ],
  [
    "Lango (South Sudan)",
    "lgo"
  ],
  [
    "Logba",
    "lgq"
  ],
  [
    "Lengo",
    "lgr"
  ],
  [
    "Pahi",
    "lgt"
  ],
  [
    "Longgu",
    "lgu"
  ],
  [
    "Ligenza",
    "lgz"
  ],
  [
    "Laha (Viet Nam)",
    "lha"
  ],
  [
    "Laha (Indonesia)",
    "lhh"
  ],
  [
    "Lahu Shi",
    "lhi"
  ],
  [
    "Lahul Lohar",
    "lhl"
  ],
  [
    "Lhomi",
    "lhm"
  ],
  [
    "Lahanan",
    "lhn"
  ],
  [
    "Lhokpu",
    "lhp"
  ],
  [
    "Mlahsö",
    "lhs"
  ],
  [
    "Lo-Toga",
    "lht"
  ],
  [
    "Lahu",
    "lhu"
  ],
  [
    "West-Central Limba",
    "lia"
  ],
  [
    "Likum",
    "lib"
  ],
  [
    "Hlai",
    "lic"
  ],
  [
    "Nyindrou",
    "lid"
  ],
  [
    "Likila",
    "lie"
  ],
  [
    "Limbu",
    "lif"
  ],
  [
    "Ligbi",
    "lig"
  ],
  [
    "Lihir",
    "lih"
  ],
  [
    "Ligurian",
    "lij"
  ],
  [
    "Lika",
    "lik"
  ],
  [
    "Lillooet",
    "lil"
  ],
  [
    "Limburgan",
    "lim",
    "lim",
    "lim",
    "li",
    "Limburgs"
  ],
  [
    "Lingala",
    "lin",
    "lin",
    "lin",
    "ln",
    "Lingála"
  ],
  [
    "Liki",
    "lio"
  ],
  [
    "Sekpele",
    "lip"
  ],
  [
    "Libido",
    "liq"
  ],
  [
    "Liberian English",
    "lir"
  ],
  [
    "Lisu",
    "lis"
  ],
  [
    "Lithuanian",
    "lit",
    "lit",
    "lit",
    "lt",
    "Lietuvių"
  ],
  [
    "Logorik",
    "liu"
  ],
  [
    "Liv",
    "liv"
  ],
  [
    "Col",
    "liw"
  ],
  [
    "Liabuku",
    "lix"
  ],
  [
    "Banda-Bambari",
    "liy"
  ],
  [
    "Libinza",
    "liz"
  ],
  [
    "Golpa",
    "lja"
  ],
  [
    "Rampi",
    "lje"
  ],
  [
    "Laiyolo",
    "lji"
  ],
  [
    "Li'o",
    "ljl"
  ],
  [
    "Lampung Api",
    "ljp"
  ],
  [
    "Yirandali",
    "ljw"
  ],
  [
    "Yuru",
    "ljx"
  ],
  [
    "Lakalei",
    "lka"
  ],
  [
    "Kabras",
    "lkb"
  ],
  [
    "Kucong",
    "lkc"
  ],
  [
    "Lakondê",
    "lkd"
  ],
  [
    "Kenyi",
    "lke"
  ],
  [
    "Lakha",
    "lkh"
  ],
  [
    "Laki",
    "lki"
  ],
  [
    "Remun",
    "lkj"
  ],
  [
    "Laeko-Libuat",
    "lkl"
  ],
  [
    "Kalaamaya",
    "lkm"
  ],
  [
    "Lakon",
    "lkn"
  ],
  [
    "Khayo",
    "lko"
  ],
  [
    "Päri",
    "lkr"
  ],
  [
    "Kisa",
    "lks"
  ],
  [
    "Lakota",
    "lkt"
  ],
  [
    "Kungkari",
    "lku"
  ],
  [
    "Lokoya",
    "lky"
  ],
  [
    "Lala-Roba",
    "lla"
  ],
  [
    "Lolo",
    "llb"
  ],
  [
    "Lele (Guinea)",
    "llc"
  ],
  [
    "Ladin",
    "lld"
  ],
  [
    "Lele (Papua New Guinea)",
    "lle"
  ],
  [
    "Hermit",
    "llf"
  ],
  [
    "Lole",
    "llg"
  ],
  [
    "Lamu",
    "llh"
  ],
  [
    "Teke-Laali",
    "lli"
  ],
  [
    "Ladji Ladji",
    "llj"
  ],
  [
    "Lelak",
    "llk"
  ],
  [
    "Lilau",
    "lll"
  ],
  [
    "Lasalimu",
    "llm"
  ],
  [
    "Lele (Chad)",
    "lln"
  ],
  [
    "North Efate",
    "llp"
  ],
  [
    "Lolak",
    "llq"
  ],
  [
    "Lithuanian Sign Language",
    "lls"
  ],
  [
    "Lau",
    "llu"
  ],
  [
    "Lauan",
    "llx"
  ],
  [
    "East Limba",
    "lma"
  ],
  [
    "Merei",
    "lmb"
  ],
  [
    "Limilngan",
    "lmc"
  ],
  [
    "Lumun",
    "lmd"
  ],
  [
    "Pévé",
    "lme"
  ],
  [
    "South Lembata",
    "lmf"
  ],
  [
    "Lamogai",
    "lmg"
  ],
  [
    "Lambichhong",
    "lmh"
  ],
  [
    "Lombi",
    "lmi"
  ],
  [
    "West Lembata",
    "lmj"
  ],
  [
    "Lamkang",
    "lmk"
  ],
  [
    "Hano",
    "lml"
  ],
  [
    "Lambadi",
    "lmn"
  ],
  [
    "Lombard",
    "lmo"
  ],
  [
    "Limbum",
    "lmp"
  ],
  [
    "Lamatuka",
    "lmq"
  ],
  [
    "Lamalera",
    "lmr"
  ],
  [
    "Lamenu",
    "lmu"
  ],
  [
    "Lomaiviti",
    "lmv"
  ],
  [
    "Lake Miwok",
    "lmw"
  ],
  [
    "Laimbue",
    "lmx"
  ],
  [
    "Lamboya",
    "lmy"
  ],
  [
    "Langbashe",
    "lna"
  ],
  [
    "Mbalanhu",
    "lnb"
  ],
  [
    "Lundayeh",
    "lnd"
  ],
  [
    "Langobardic",
    "lng"
  ],
  [
    "Lanoh",
    "lnh"
  ],
  [
    "Daantanai'",
    "lni"
  ],
  [
    "Leningitij",
    "lnj"
  ],
  [
    "South Central Banda",
    "lnl"
  ],
  [
    "Langam",
    "lnm"
  ],
  [
    "Lorediakarkar",
    "lnn"
  ],
  [
    "Lamnso'",
    "lns"
  ],
  [
    "Longuda",
    "lnu"
  ],
  [
    "Lanima",
    "lnw"
  ],
  [
    "Lonzo",
    "lnz"
  ],
  [
    "Loloda",
    "loa"
  ],
  [
    "Lobi",
    "lob"
  ],
  [
    "Inonhan",
    "loc"
  ],
  [
    "Saluan",
    "loe"
  ],
  [
    "Logol",
    "lof"
  ],
  [
    "Logo",
    "log"
  ],
  [
    "Narim",
    "loh"
  ],
  [
    "Loma (Côte d'Ivoire)",
    "loi"
  ],
  [
    "Lou",
    "loj"
  ],
  [
    "Loko",
    "lok"
  ],
  [
    "Mongo",
    "lol",
    "lol",
    "lol"
  ],
  [
    "Loma (Liberia)",
    "lom"
  ],
  [
    "Malawi Lomwe",
    "lon"
  ],
  [
    "Lombo",
    "loo"
  ],
  [
    "Lopa",
    "lop"
  ],
  [
    "Lobala",
    "loq"
  ],
  [
    "Téén",
    "lor"
  ],
  [
    "Loniu",
    "los"
  ],
  [
    "Otuho",
    "lot"
  ],
  [
    "Louisiana Creole",
    "lou"
  ],
  [
    "Lopi",
    "lov"
  ],
  [
    "Tampias Lobu",
    "low"
  ],
  [
    "Loun",
    "lox"
  ],
  [
    "Loke",
    "loy"
  ],
  [
    "Lozi",
    "loz",
    "loz",
    "loz"
  ],
  [
    "Lelepa",
    "lpa"
  ],
  [
    "Lepki",
    "lpe"
  ],
  [
    "Long Phuri Naga",
    "lpn"
  ],
  [
    "Lipo",
    "lpo"
  ],
  [
    "Lopit",
    "lpx"
  ],
  [
    "Logir",
    "lqr"
  ],
  [
    "Rara Bakati'",
    "lra"
  ],
  [
    "Northern Luri",
    "lrc"
  ],
  [
    "Laurentian",
    "lre"
  ],
  [
    "Laragia",
    "lrg"
  ],
  [
    "Marachi",
    "lri"
  ],
  [
    "Loarki",
    "lrk"
  ],
  [
    "Lari",
    "lrl"
  ],
  [
    "Marama",
    "lrm"
  ],
  [
    "Lorang",
    "lrn"
  ],
  [
    "Laro",
    "lro"
  ],
  [
    "Southern Yamphu",
    "lrr"
  ],
  [
    "Larantuka Malay",
    "lrt"
  ],
  [
    "Larevat",
    "lrv"
  ],
  [
    "Lemerig",
    "lrz"
  ],
  [
    "Lasgerdi",
    "lsa"
  ],
  [
    "Burundian Sign Language",
    "lsb"
  ],
  [
    "Albarradas Sign Language",
    "lsc"
  ],
  [
    "Lishana Deni",
    "lsd"
  ],
  [
    "Lusengo",
    "lse"
  ],
  [
    "Lish",
    "lsh"
  ],
  [
    "Lashi",
    "lsi"
  ],
  [
    "Latvian Sign Language",
    "lsl"
  ],
  [
    "Saamia",
    "lsm"
  ],
  [
    "Tibetan Sign Language",
    "lsn"
  ],
  [
    "Laos Sign Language",
    "lso"
  ],
  [
    "Panamanian Sign Language",
    "lsp"
  ],
  [
    "Aruop",
    "lsr"
  ],
  [
    "Lasi",
    "lss"
  ],
  [
    "Trinidad and Tobago Sign Language",
    "lst"
  ],
  [
    "Sivia Sign Language",
    "lsv"
  ],
  [
    "Seychelles Sign Language",
    "lsw"
  ],
  [
    "Mauritian Sign Language",
    "lsy"
  ],
  [
    "Late Middle Chinese",
    "ltc"
  ],
  [
    "Latgalian",
    "ltg"
  ],
  [
    "Thur",
    "lth"
  ],
  [
    "Leti (Indonesia)",
    "lti"
  ],
  [
    "Latundê",
    "ltn"
  ],
  [
    "Tsotso",
    "lto"
  ],
  [
    "Tachoni",
    "lts"
  ],
  [
    "Latu",
    "ltu"
  ],
  [
    "Luxembourgish",
    "ltz",
    "ltz",
    "ltz",
    "lb",
    "Lëtzebuergesch"
  ],
  [
    "Luba-Lulua",
    "lua",
    "lua",
    "lua"
  ],
  [
    "Luba-Katanga",
    "lub",
    "lub",
    "lub",
    "lu"
  ],
  [
    "Aringa",
    "luc"
  ],
  [
    "Ludian",
    "lud"
  ],
  [
    "Luvale",
    "lue"
  ],
  [
    "Laua",
    "luf"
  ],
  [
    "Ganda",
    "lug",
    "lug",
    "lug",
    "lg",
    "Luganda"
  ],
  [
    "Luiseno",
    "lui",
    "lui",
    "lui"
  ],
  [
    "Luna",
    "luj"
  ],
  [
    "Lunanakha",
    "luk"
  ],
  [
    "Olu'bo",
    "lul"
  ],
  [
    "Luimbi",
    "lum"
  ],
  [
    "Lunda",
    "lun",
    "lun",
    "lun"
  ],
  [
    "Luo (Kenya and Tanzania)",
    "luo",
    "luo",
    "luo"
  ],
  [
    "Lumbu",
    "lup"
  ],
  [
    "Lucumi",
    "luq"
  ],
  [
    "Laura",
    "lur"
  ],
  [
    "Lushai",
    "lus",
    "lus",
    "lus"
  ],
  [
    "Lushootseed",
    "lut"
  ],
  [
    "Lumba-Yakkha",
    "luu"
  ],
  [
    "Luwati",
    "luv"
  ],
  [
    "Luo (Cameroon)",
    "luw"
  ],
  [
    "Luyia",
    "luy"
  ],
  [
    "Southern Luri",
    "luz"
  ],
  [
    "Maku'a",
    "lva"
  ],
  [
    "Lavi",
    "lvi"
  ],
  [
    "Lavukaleve",
    "lvk"
  ],
  [
    "Standard Latvian",
    "lvs"
  ],
  [
    "Levuka",
    "lvu"
  ],
  [
    "Lwalu",
    "lwa"
  ],
  [
    "Lewo Eleng",
    "lwe"
  ],
  [
    "Wanga",
    "lwg"
  ],
  [
    "White Lachi",
    "lwh"
  ],
  [
    "Eastern Lawa",
    "lwl"
  ],
  [
    "Laomian",
    "lwm"
  ],
  [
    "Luwo",
    "lwo"
  ],
  [
    "Malawian Sign Language",
    "lws"
  ],
  [
    "Lewotobi",
    "lwt"
  ],
  [
    "Lawu",
    "lwu"
  ],
  [
    "Lewo",
    "lww"
  ],
  [
    "Lakurumau",
    "lxm"
  ],
  [
    "Layakha",
    "lya"
  ],
  [
    "Lyngngam",
    "lyg"
  ],
  [
    "Luyana",
    "lyn"
  ],
  [
    "Literary Chinese",
    "lzh"
  ],
  [
    "Litzlitz",
    "lzl"
  ],
  [
    "Leinong Naga",
    "lzn"
  ],
  [
    "Laz",
    "lzz"
  ],
  [
    "San Jerónimo Tecóatl Mazatec",
    "maa"
  ],
  [
    "Yutanduchi Mixtec",
    "mab"
  ],
  [
    "Madurese",
    "mad",
    "mad",
    "mad"
  ],
  [
    "Bo-Rukul",
    "mae"
  ],
  [
    "Mafa",
    "maf"
  ],
  [
    "Magahi",
    "mag",
    "mag",
    "mag"
  ],
  [
    "Marshallese",
    "mah",
    "mah",
    "mah",
    "mh",
    "Kajin Majel / Ebon"
  ],
  [
    "Maithili",
    "mai",
    "mai",
    "mai"
  ],
  [
    "Jalapa De Díaz Mazatec",
    "maj"
  ],
  [
    "Makasar",
    "mak",
    "mak",
    "mak"
  ],
  [
    "Malayalam",
    "mal",
    "mal",
    "mal",
    "ml",
    "മലയാളം"
  ],
  [
    "Mam",
    "mam"
  ],
  [
    "Mandingo",
    "man",
    "man",
    "man"
  ],
  [
    "Chiquihuitlán Mazatec",
    "maq"
  ],
  [
    "Marathi",
    "mar",
    "mar",
    "mar",
    "mr",
    "मराठी"
  ],
  [
    "Masai",
    "mas",
    "mas",
    "mas"
  ],
  [
    "San Francisco Matlatzinca",
    "mat"
  ],
  [
    "Huautla Mazatec",
    "mau"
  ],
  [
    "Sateré-Mawé",
    "mav"
  ],
  [
    "Mampruli",
    "maw"
  ],
  [
    "North Moluccan Malay",
    "max"
  ],
  [
    "Central Mazahua",
    "maz"
  ],
  [
    "Higaonon",
    "mba"
  ],
  [
    "Western Bukidnon Manobo",
    "mbb"
  ],
  [
    "Macushi",
    "mbc"
  ],
  [
    "Dibabawon Manobo",
    "mbd"
  ],
  [
    "Molale",
    "mbe"
  ],
  [
    "Baba Malay",
    "mbf"
  ],
  [
    "Mangseng",
    "mbh"
  ],
  [
    "Ilianen Manobo",
    "mbi"
  ],
  [
    "Nadëb",
    "mbj"
  ],
  [
    "Malol",
    "mbk"
  ],
  [
    "Maxakalí",
    "mbl"
  ],
  [
    "Ombamba",
    "mbm"
  ],
  [
    "Macaguán",
    "mbn"
  ],
  [
    "Mbo (Cameroon)",
    "mbo"
  ],
  [
    "Malayo",
    "mbp"
  ],
  [
    "Maisin",
    "mbq"
  ],
  [
    "Nukak Makú",
    "mbr"
  ],
  [
    "Sarangani Manobo",
    "mbs"
  ],
  [
    "Matigsalug Manobo",
    "mbt"
  ],
  [
    "Mbula-Bwazza",
    "mbu"
  ],
  [
    "Mbulungish",
    "mbv"
  ],
  [
    "Maring",
    "mbw"
  ],
  [
    "Mari (East Sepik Province)",
    "mbx"
  ],
  [
    "Memoni",
    "mby"
  ],
  [
    "Amoltepec Mixtec",
    "mbz"
  ],
  [
    "Maca",
    "mca"
  ],
  [
    "Machiguenga",
    "mcb"
  ],
  [
    "Bitur",
    "mcc"
  ],
  [
    "Sharanahua",
    "mcd"
  ],
  [
    "Itundujia Mixtec",
    "mce"
  ],
  [
    "Matsés",
    "mcf"
  ],
  [
    "Mapoyo",
    "mcg"
  ],
  [
    "Maquiritari",
    "mch"
  ],
  [
    "Mese",
    "mci"
  ],
  [
    "Mvanip",
    "mcj"
  ],
  [
    "Mbunda",
    "mck"
  ],
  [
    "Macaguaje",
    "mcl"
  ],
  [
    "Malaccan Creole Portuguese",
    "mcm"
  ],
  [
    "Masana",
    "mcn"
  ],
  [
    "Coatlán Mixe",
    "mco"
  ],
  [
    "Makaa",
    "mcp"
  ],
  [
    "Ese",
    "mcq"
  ],
  [
    "Menya",
    "mcr"
  ],
  [
    "Mambai",
    "mcs"
  ],
  [
    "Mengisa",
    "mct"
  ],
  [
    "Cameroon Mambila",
    "mcu"
  ],
  [
    "Minanibai",
    "mcv"
  ],
  [
    "Mawa (Chad)",
    "mcw"
  ],
  [
    "Mpiemo",
    "mcx"
  ],
  [
    "South Watut",
    "mcy"
  ],
  [
    "Mawan",
    "mcz"
  ],
  [
    "Mada (Nigeria)",
    "mda"
  ],
  [
    "Morigi",
    "mdb"
  ],
  [
    "Male (Papua New Guinea)",
    "mdc"
  ],
  [
    "Mbum",
    "mdd"
  ],
  [
    "Maba (Chad)",
    "mde"
  ],
  [
    "Moksha",
    "mdf",
    "mdf",
    "mdf"
  ],
  [
    "Massalat",
    "mdg"
  ],
  [
    "Maguindanaon",
    "mdh"
  ],
  [
    "Mamvu",
    "mdi"
  ],
  [
    "Mangbetu",
    "mdj"
  ],
  [
    "Mangbutu",
    "mdk"
  ],
  [
    "Maltese Sign Language",
    "mdl"
  ],
  [
    "Mayogo",
    "mdm"
  ],
  [
    "Mbati",
    "mdn"
  ],
  [
    "Mbala",
    "mdp"
  ],
  [
    "Mbole",
    "mdq"
  ],
  [
    "Mandar",
    "mdr",
    "mdr",
    "mdr"
  ],
  [
    "Maria (Papua New Guinea)",
    "mds"
  ],
  [
    "Mbere",
    "mdt"
  ],
  [
    "Mboko",
    "mdu"
  ],
  [
    "Santa Lucía Monteverde Mixtec",
    "mdv"
  ],
  [
    "Mbosi",
    "mdw"
  ],
  [
    "Dizin",
    "mdx"
  ],
  [
    "Male (Ethiopia)",
    "mdy"
  ],
  [
    "Suruí Do Pará",
    "mdz"
  ],
  [
    "Menka",
    "mea"
  ],
  [
    "Ikobi",
    "meb"
  ],
  [
    "Marra",
    "mec"
  ],
  [
    "Melpa",
    "med"
  ],
  [
    "Mengen",
    "mee"
  ],
  [
    "Megam",
    "mef"
  ],
  [
    "Southwestern Tlaxiaco Mixtec",
    "meh"
  ],
  [
    "Midob",
    "mei"
  ],
  [
    "Meyah",
    "mej"
  ],
  [
    "Mekeo",
    "mek"
  ],
  [
    "Central Melanau",
    "mel"
  ],
  [
    "Mangala",
    "mem"
  ],
  [
    "Mende (Sierra Leone)",
    "men",
    "men",
    "men"
  ],
  [
    "Kedah Malay",
    "meo"
  ],
  [
    "Miriwoong",
    "mep"
  ],
  [
    "Merey",
    "meq"
  ],
  [
    "Meru",
    "mer"
  ],
  [
    "Masmaje",
    "mes"
  ],
  [
    "Mato",
    "met"
  ],
  [
    "Motu",
    "meu"
  ],
  [
    "Mano",
    "mev"
  ],
  [
    "Maaka",
    "mew"
  ],
  [
    "Hassaniyya",
    "mey"
  ],
  [
    "Menominee",
    "mez"
  ],
  [
    "Pattani Malay",
    "mfa"
  ],
  [
    "Bangka",
    "mfb"
  ],
  [
    "Mba",
    "mfc"
  ],
  [
    "Mendankwe-Nkwen",
    "mfd"
  ],
  [
    "Morisyen",
    "mfe"
  ],
  [
    "Naki",
    "mff"
  ],
  [
    "Mogofin",
    "mfg"
  ],
  [
    "Matal",
    "mfh"
  ],
  [
    "Wandala",
    "mfi"
  ],
  [
    "Mefele",
    "mfj"
  ],
  [
    "North Mofu",
    "mfk"
  ],
  [
    "Putai",
    "mfl"
  ],
  [
    "Marghi South",
    "mfm"
  ],
  [
    "Cross River Mbembe",
    "mfn"
  ],
  [
    "Mbe",
    "mfo"
  ],
  [
    "Makassar Malay",
    "mfp"
  ],
  [
    "Moba",
    "mfq"
  ],
  [
    "Marrithiyel",
    "mfr"
  ],
  [
    "Mexican Sign Language",
    "mfs"
  ],
  [
    "Mokerang",
    "mft"
  ],
  [
    "Mbwela",
    "mfu"
  ],
  [
    "Mandjak",
    "mfv"
  ],
  [
    "Mulaha",
    "mfw"
  ],
  [
    "Melo",
    "mfx"
  ],
  [
    "Mayo",
    "mfy"
  ],
  [
    "Mabaan",
    "mfz"
  ],
  [
    "Middle Irish (900-1200)",
    "mga",
    "mga",
    "mga"
  ],
  [
    "Mararit",
    "mgb"
  ],
  [
    "Morokodo",
    "mgc"
  ],
  [
    "Moru",
    "mgd"
  ],
  [
    "Mango",
    "mge"
  ],
  [
    "Maklew",
    "mgf"
  ],
  [
    "Mpumpong",
    "mgg"
  ],
  [
    "Makhuwa-Meetto",
    "mgh"
  ],
  [
    "Lijili",
    "mgi"
  ],
  [
    "Abureni",
    "mgj"
  ],
  [
    "Mawes",
    "mgk"
  ],
  [
    "Maleu-Kilenge",
    "mgl"
  ],
  [
    "Mambae",
    "mgm"
  ],
  [
    "Mbangi",
    "mgn"
  ],
  [
    "Meta'",
    "mgo"
  ],
  [
    "Eastern Magar",
    "mgp"
  ],
  [
    "Malila",
    "mgq"
  ],
  [
    "Mambwe-Lungu",
    "mgr"
  ],
  [
    "Manda (Tanzania)",
    "mgs"
  ],
  [
    "Mongol",
    "mgt"
  ],
  [
    "Mailu",
    "mgu"
  ],
  [
    "Matengo",
    "mgv"
  ],
  [
    "Matumbi",
    "mgw"
  ],
  [
    "Mbunga",
    "mgy"
  ],
  [
    "Mbugwe",
    "mgz"
  ],
  [
    "Manda (India)",
    "mha"
  ],
  [
    "Mahongwe",
    "mhb"
  ],
  [
    "Mocho",
    "mhc"
  ],
  [
    "Mbugu",
    "mhd"
  ],
  [
    "Besisi",
    "mhe"
  ],
  [
    "Mamaa",
    "mhf"
  ],
  [
    "Margu",
    "mhg"
  ],
  [
    "Ma'di",
    "mhi"
  ],
  [
    "Mogholi",
    "mhj"
  ],
  [
    "Mungaka",
    "mhk"
  ],
  [
    "Mauwake",
    "mhl"
  ],
  [
    "Makhuwa-Moniga",
    "mhm"
  ],
  [
    "Mócheno",
    "mhn"
  ],
  [
    "Mashi (Zambia)",
    "mho"
  ],
  [
    "Balinese Malay",
    "mhp"
  ],
  [
    "Mandan",
    "mhq"
  ],
  [
    "Eastern Mari",
    "mhr"
  ],
  [
    "Buru (Indonesia)",
    "mhs"
  ],
  [
    "Mandahuaca",
    "mht"
  ],
  [
    "Digaro-Mishmi",
    "mhu"
  ],
  [
    "Mbukushu",
    "mhw"
  ],
  [
    "Maru",
    "mhx"
  ],
  [
    "Ma'anyan",
    "mhy"
  ],
  [
    "Mor (Mor Islands)",
    "mhz"
  ],
  [
    "Miami",
    "mia"
  ],
  [
    "Atatláhuca Mixtec",
    "mib"
  ],
  [
    "Mi'kmaq",
    "mic",
    "mic",
    "mic"
  ],
  [
    "Mandaic",
    "mid"
  ],
  [
    "Ocotepec Mixtec",
    "mie"
  ],
  [
    "Mofu-Gudur",
    "mif"
  ],
  [
    "San Miguel El Grande Mixtec",
    "mig"
  ],
  [
    "Chayuco Mixtec",
    "mih"
  ],
  [
    "Chigmecatitlán Mixtec",
    "mii"
  ],
  [
    "Abar",
    "mij"
  ],
  [
    "Mikasuki",
    "mik"
  ],
  [
    "Peñoles Mixtec",
    "mil"
  ],
  [
    "Alacatlatzala Mixtec",
    "mim"
  ],
  [
    "Minangkabau",
    "min",
    "min",
    "min"
  ],
  [
    "Pinotepa Nacional Mixtec",
    "mio"
  ],
  [
    "Apasco-Apoala Mixtec",
    "mip"
  ],
  [
    "Mískito",
    "miq"
  ],
  [
    "Isthmus Mixe",
    "mir"
  ],
  [
    "Uncoded languages",
    "mis",
    "mis",
    "mis"
  ],
  [
    "Southern Puebla Mixtec",
    "mit"
  ],
  [
    "Cacaloxtepec Mixtec",
    "miu"
  ],
  [
    "Akoye",
    "miw"
  ],
  [
    "Mixtepec Mixtec",
    "mix"
  ],
  [
    "Ayutla Mixtec",
    "miy"
  ],
  [
    "Coatzospan Mixtec",
    "miz"
  ],
  [
    "Makalero",
    "mjb"
  ],
  [
    "San Juan Colorado Mixtec",
    "mjc"
  ],
  [
    "Northwest Maidu",
    "mjd"
  ],
  [
    "Muskum",
    "mje"
  ],
  [
    "Tu",
    "mjg"
  ],
  [
    "Mwera (Nyasa)",
    "mjh"
  ],
  [
    "Kim Mun",
    "mji"
  ],
  [
    "Mawak",
    "mjj"
  ],
  [
    "Matukar",
    "mjk"
  ],
  [
    "Mandeali",
    "mjl"
  ],
  [
    "Medebur",
    "mjm"
  ],
  [
    "Ma (Papua New Guinea)",
    "mjn"
  ],
  [
    "Malankuravan",
    "mjo"
  ],
  [
    "Malapandaram",
    "mjp"
  ],
  [
    "Malaryan",
    "mjq"
  ],
  [
    "Malavedan",
    "mjr"
  ],
  [
    "Miship",
    "mjs"
  ],
  [
    "Sauria Paharia",
    "mjt"
  ],
  [
    "Manna-Dora",
    "mju"
  ],
  [
    "Mannan",
    "mjv"
  ],
  [
    "Karbi",
    "mjw"
  ],
  [
    "Mahali",
    "mjx"
  ],
  [
    "Mahican",
    "mjy"
  ],
  [
    "Majhi",
    "mjz"
  ],
  [
    "Mbre",
    "mka"
  ],
  [
    "Mal Paharia",
    "mkb"
  ],
  [
    "Siliput",
    "mkc"
  ],
  [
    "Macedonian",
    "mkd",
    "mac",
    "mkd",
    "mk",
    "Македонски"
  ],
  [
    "Mawchi",
    "mke"
  ],
  [
    "Miya",
    "mkf"
  ],
  [
    "Mak (China)",
    "mkg"
  ],
  [
    "Dhatki",
    "mki"
  ],
  [
    "Mokilese",
    "mkj"
  ],
  [
    "Byep",
    "mkk"
  ],
  [
    "Mokole",
    "mkl"
  ],
  [
    "Moklen",
    "mkm"
  ],
  [
    "Kupang Malay",
    "mkn"
  ],
  [
    "Mingang Doso",
    "mko"
  ],
  [
    "Moikodi",
    "mkp"
  ],
  [
    "Bay Miwok",
    "mkq"
  ],
  [
    "Malas",
    "mkr"
  ],
  [
    "Silacayoapan Mixtec",
    "mks"
  ],
  [
    "Vamale",
    "mkt"
  ],
  [
    "Konyanka Maninka",
    "mku"
  ],
  [
    "Mafea",
    "mkv"
  ],
  [
    "Kituba (Congo)",
    "mkw"
  ],
  [
    "Kinamiging Manobo",
    "mkx"
  ],
  [
    "East Makian",
    "mky"
  ],
  [
    "Makasae",
    "mkz"
  ],
  [
    "Malo",
    "mla"
  ],
  [
    "Mbule",
    "mlb"
  ],
  [
    "Cao Lan",
    "mlc"
  ],
  [
    "Manambu",
    "mle"
  ],
  [
    "Mal",
    "mlf"
  ],
  [
    "Malagasy",
    "mlg",
    "mlg",
    "mlg",
    "mg",
    "Malagasy"
  ],
  [
    "Mape",
    "mlh"
  ],
  [
    "Malimpung",
    "mli"
  ],
  [
    "Miltu",
    "mlj"
  ],
  [
    "Ilwana",
    "mlk"
  ],
  [
    "Malua Bay",
    "mll"
  ],
  [
    "Mulam",
    "mlm"
  ],
  [
    "Malango",
    "mln"
  ],
  [
    "Mlomp",
    "mlo"
  ],
  [
    "Bargam",
    "mlp"
  ],
  [
    "Western Maninkakan",
    "mlq"
  ],
  [
    "Vame",
    "mlr"
  ],
  [
    "Masalit",
    "mls"
  ],
  [
    "Maltese",
    "mlt",
    "mlt",
    "mlt",
    "mt",
    "bil-Malti"
  ],
  [
    "To'abaita",
    "mlu"
  ],
  [
    "Motlav",
    "mlv"
  ],
  [
    "Moloko",
    "mlw"
  ],
  [
    "Malfaxal",
    "mlx"
  ],
  [
    "Malaynon",
    "mlz"
  ],
  [
    "Mama",
    "mma"
  ],
  [
    "Momina",
    "mmb"
  ],
  [
    "Michoacán Mazahua",
    "mmc"
  ],
  [
    "Maonan",
    "mmd"
  ],
  [
    "Mae",
    "mme"
  ],
  [
    "Mundat",
    "mmf"
  ],
  [
    "North Ambrym",
    "mmg"
  ],
  [
    "Mehináku",
    "mmh"
  ],
  [
    "Musar",
    "mmi"
  ],
  [
    "Majhwar",
    "mmj"
  ],
  [
    "Mukha-Dora",
    "mmk"
  ],
  [
    "Man Met",
    "mml"
  ],
  [
    "Maii",
    "mmm"
  ],
  [
    "Mamanwa",
    "mmn"
  ],
  [
    "Mangga Buang",
    "mmo"
  ],
  [
    "Siawi",
    "mmp"
  ],
  [
    "Musak",
    "mmq"
  ],
  [
    "Western Xiangxi Miao",
    "mmr"
  ],
  [
    "Malalamai",
    "mmt"
  ],
  [
    "Mmaala",
    "mmu"
  ],
  [
    "Miriti",
    "mmv"
  ],
  [
    "Emae",
    "mmw"
  ],
  [
    "Madak",
    "mmx"
  ],
  [
    "Migaama",
    "mmy"
  ],
  [
    "Mabaale",
    "mmz"
  ],
  [
    "Mbula",
    "mna"
  ],
  [
    "Muna",
    "mnb"
  ],
  [
    "Manchu",
    "mnc",
    "mnc",
    "mnc"
  ],
  [
    "Mondé",
    "mnd"
  ],
  [
    "Naba",
    "mne"
  ],
  [
    "Mundani",
    "mnf"
  ],
  [
    "Eastern Mnong",
    "mng"
  ],
  [
    "Mono (Democratic Republic of Congo)",
    "mnh"
  ],
  [
    "Manipuri",
    "mni",
    "mni",
    "mni"
  ],
  [
    "Munji",
    "mnj"
  ],
  [
    "Mandinka",
    "mnk"
  ],
  [
    "Tiale",
    "mnl"
  ],
  [
    "Mapena",
    "mnm"
  ],
  [
    "Southern Mnong",
    "mnn"
  ],
  [
    "Min Bei Chinese",
    "mnp"
  ],
  [
    "Minriq",
    "mnq"
  ],
  [
    "Mono (USA)",
    "mnr"
  ],
  [
    "Mansi",
    "mns"
  ],
  [
    "Mer",
    "mnu"
  ],
  [
    "Rennell-Bellona",
    "mnv"
  ],
  [
    "Mon",
    "mnw"
  ],
  [
    "Manikion",
    "mnx"
  ],
  [
    "Manyawa",
    "mny"
  ],
  [
    "Moni",
    "mnz"
  ],
  [
    "Mwan",
    "moa"
  ],
  [
    "Mocoví",
    "moc"
  ],
  [
    "Mobilian",
    "mod"
  ],
  [
    "Innu",
    "moe"
  ],
  [
    "Mongondow",
    "mog"
  ],
  [
    "Mohawk",
    "moh",
    "moh",
    "moh"
  ],
  [
    "Mboi",
    "moi"
  ],
  [
    "Monzombo",
    "moj"
  ],
  [
    "Morori",
    "mok"
  ],
  [
    "Mangue",
    "mom"
  ],
  [
    "Mongolian",
    "mon",
    "mon",
    "mon",
    "mn",
    "Монгол"
  ],
  [
    "Monom",
    "moo"
  ],
  [
    "Mopán Maya",
    "mop"
  ],
  [
    "Mor (Bomberai Peninsula)",
    "moq"
  ],
  [
    "Moro",
    "mor"
  ],
  [
    "Mossi",
    "mos",
    "mos",
    "mos"
  ],
  [
    "Barí",
    "mot"
  ],
  [
    "Mogum",
    "mou"
  ],
  [
    "Mohave",
    "mov"
  ],
  [
    "Moi (Congo)",
    "mow"
  ],
  [
    "Molima",
    "mox"
  ],
  [
    "Shekkacho",
    "moy"
  ],
  [
    "Mukulu",
    "moz"
  ],
  [
    "Mpoto",
    "mpa"
  ],
  [
    "Malak Malak",
    "mpb"
  ],
  [
    "Mangarrayi",
    "mpc"
  ],
  [
    "Machinere",
    "mpd"
  ],
  [
    "Majang",
    "mpe"
  ],
  [
    "Marba",
    "mpg"
  ],
  [
    "Maung",
    "mph"
  ],
  [
    "Mpade",
    "mpi"
  ],
  [
    "Martu Wangka",
    "mpj"
  ],
  [
    "Mbara (Chad)",
    "mpk"
  ],
  [
    "Middle Watut",
    "mpl"
  ],
  [
    "Yosondúa Mixtec",
    "mpm"
  ],
  [
    "Mindiri",
    "mpn"
  ],
  [
    "Miu",
    "mpo"
  ],
  [
    "Migabac",
    "mpp"
  ],
  [
    "Matís",
    "mpq"
  ],
  [
    "Vangunu",
    "mpr"
  ],
  [
    "Dadibi",
    "mps"
  ],
  [
    "Mian",
    "mpt"
  ],
  [
    "Makuráp",
    "mpu"
  ],
  [
    "Mungkip",
    "mpv"
  ],
  [
    "Mapidian",
    "mpw"
  ],
  [
    "Misima-Panaeati",
    "mpx"
  ],
  [
    "Mapia",
    "mpy"
  ],
  [
    "Mpi",
    "mpz"
  ],
  [
    "Maba (Indonesia)",
    "mqa"
  ],
  [
    "Mbuko",
    "mqb"
  ],
  [
    "Mangole",
    "mqc"
  ],
  [
    "Matepi",
    "mqe"
  ],
  [
    "Momuna",
    "mqf"
  ],
  [
    "Kota Bangun Kutai Malay",
    "mqg"
  ],
  [
    "Tlazoyaltepec Mixtec",
    "mqh"
  ],
  [
    "Mariri",
    "mqi"
  ],
  [
    "Mamasa",
    "mqj"
  ],
  [
    "Rajah Kabunsuwan Manobo",
    "mqk"
  ],
  [
    "Mbelime",
    "mql"
  ],
  [
    "South Marquesan",
    "mqm"
  ],
  [
    "Moronene",
    "mqn"
  ],
  [
    "Modole",
    "mqo"
  ],
  [
    "Manipa",
    "mqp"
  ],
  [
    "Minokok",
    "mqq"
  ],
  [
    "Mander",
    "mqr"
  ],
  [
    "West Makian",
    "mqs"
  ],
  [
    "Mok",
    "mqt"
  ],
  [
    "Mandari",
    "mqu"
  ],
  [
    "Mosimo",
    "mqv"
  ],
  [
    "Murupi",
    "mqw"
  ],
  [
    "Mamuju",
    "mqx"
  ],
  [
    "Manggarai",
    "mqy"
  ],
  [
    "Pano",
    "mqz"
  ],
  [
    "Mlabri",
    "mra"
  ],
  [
    "Marino",
    "mrb"
  ],
  [
    "Maricopa",
    "mrc"
  ],
  [
    "Western Magar",
    "mrd"
  ],
  [
    "Martha's Vineyard Sign Language",
    "mre"
  ],
  [
    "Elseng",
    "mrf"
  ],
  [
    "Mising",
    "mrg"
  ],
  [
    "Mara Chin",
    "mrh"
  ],
  [
    "Maori",
    "mri",
    "mao",
    "mri",
    "mi",
    "Māori"
  ],
  [
    "Western Mari",
    "mrj"
  ],
  [
    "Hmwaveke",
    "mrk"
  ],
  [
    "Mortlockese",
    "mrl"
  ],
  [
    "Merlav",
    "mrm"
  ],
  [
    "Cheke Holo",
    "mrn"
  ],
  [
    "Mru",
    "mro"
  ],
  [
    "Morouas",
    "mrp"
  ],
  [
    "North Marquesan",
    "mrq"
  ],
  [
    "Maria (India)",
    "mrr"
  ],
  [
    "Maragus",
    "mrs"
  ],
  [
    "Marghi Central",
    "mrt"
  ],
  [
    "Mono (Cameroon)",
    "mru"
  ],
  [
    "Mangareva",
    "mrv"
  ],
  [
    "Maranao",
    "mrw"
  ],
  [
    "Maremgi",
    "mrx"
  ],
  [
    "Mandaya",
    "mry"
  ],
  [
    "Marind",
    "mrz"
  ],
  [
    "Malay (macrolanguage)",
    "msa",
    "may",
    "msa",
    "ms",
    "Bahasa Melayu"
  ],
  [
    "Masbatenyo",
    "msb"
  ],
  [
    "Sankaran Maninka",
    "msc"
  ],
  [
    "Yucatec Maya Sign Language",
    "msd"
  ],
  [
    "Musey",
    "mse"
  ],
  [
    "Mekwei",
    "msf"
  ],
  [
    "Moraid",
    "msg"
  ],
  [
    "Masikoro Malagasy",
    "msh"
  ],
  [
    "Sabah Malay",
    "msi"
  ],
  [
    "Ma (Democratic Republic of Congo)",
    "msj"
  ],
  [
    "Mansaka",
    "msk"
  ],
  [
    "Molof",
    "msl"
  ],
  [
    "Agusan Manobo",
    "msm"
  ],
  [
    "Vurës",
    "msn"
  ],
  [
    "Mombum",
    "mso"
  ],
  [
    "Maritsauá",
    "msp"
  ],
  [
    "Caac",
    "msq"
  ],
  [
    "Mongolian Sign Language",
    "msr"
  ],
  [
    "West Masela",
    "mss"
  ],
  [
    "Musom",
    "msu"
  ],
  [
    "Maslam",
    "msv"
  ],
  [
    "Mansoanka",
    "msw"
  ],
  [
    "Moresada",
    "msx"
  ],
  [
    "Aruamu",
    "msy"
  ],
  [
    "Momare",
    "msz"
  ],
  [
    "Cotabato Manobo",
    "mta"
  ],
  [
    "Anyin Morofo",
    "mtb"
  ],
  [
    "Munit",
    "mtc"
  ],
  [
    "Mualang",
    "mtd"
  ],
  [
    "Mono (Solomon Islands)",
    "mte"
  ],
  [
    "Murik (Papua New Guinea)",
    "mtf"
  ],
  [
    "Una",
    "mtg"
  ],
  [
    "Munggui",
    "mth"
  ],
  [
    "Maiwa (Papua New Guinea)",
    "mti"
  ],
  [
    "Moskona",
    "mtj"
  ],
  [
    "Mbe'",
    "mtk"
  ],
  [
    "Montol",
    "mtl"
  ],
  [
    "Mator",
    "mtm"
  ],
  [
    "Matagalpa",
    "mtn"
  ],
  [
    "Totontepec Mixe",
    "mto"
  ],
  [
    "Wichí Lhamtés Nocten",
    "mtp"
  ],
  [
    "Muong",
    "mtq"
  ],
  [
    "Mewari",
    "mtr"
  ],
  [
    "Yora",
    "mts"
  ],
  [
    "Mota",
    "mtt"
  ],
  [
    "Tututepec Mixtec",
    "mtu"
  ],
  [
    "Asaro'o",
    "mtv"
  ],
  [
    "Southern Binukidnon",
    "mtw"
  ],
  [
    "Tidaá Mixtec",
    "mtx"
  ],
  [
    "Nabi",
    "mty"
  ],
  [
    "Mundang",
    "mua"
  ],
  [
    "Mubi",
    "mub"
  ],
  [
    "Ajumbu",
    "muc"
  ],
  [
    "Mednyj Aleut",
    "mud"
  ],
  [
    "Media Lengua",
    "mue"
  ],
  [
    "Musgu",
    "mug"
  ],
  [
    "Mündü",
    "muh"
  ],
  [
    "Musi",
    "mui"
  ],
  [
    "Mabire",
    "muj"
  ],
  [
    "Mugom",
    "muk"
  ],
  [
    "Multiple languages",
    "mul",
    "mul",
    "mul"
  ],
  [
    "Maiwala",
    "mum"
  ],
  [
    "Nyong",
    "muo"
  ],
  [
    "Malvi",
    "mup"
  ],
  [
    "Eastern Xiangxi Miao",
    "muq"
  ],
  [
    "Murle",
    "mur"
  ],
  [
    "Creek",
    "mus",
    "mus",
    "mus"
  ],
  [
    "Western Muria",
    "mut"
  ],
  [
    "Yaaku",
    "muu"
  ],
  [
    "Muthuvan",
    "muv"
  ],
  [
    "Bo-Ung",
    "mux"
  ],
  [
    "Muyang",
    "muy"
  ],
  [
    "Mursi",
    "muz"
  ],
  [
    "Manam",
    "mva"
  ],
  [
    "Mattole",
    "mvb"
  ],
  [
    "Mamboru",
    "mvd"
  ],
  [
    "Marwari (Pakistan)",
    "mve"
  ],
  [
    "Peripheral Mongolian",
    "mvf"
  ],
  [
    "Yucuañe Mixtec",
    "mvg"
  ],
  [
    "Mulgi",
    "mvh"
  ],
  [
    "Miyako",
    "mvi"
  ],
  [
    "Mekmek",
    "mvk"
  ],
  [
    "Mbara (Australia)",
    "mvl"
  ],
  [
    "Minaveha",
    "mvn"
  ],
  [
    "Marovo",
    "mvo"
  ],
  [
    "Duri",
    "mvp"
  ],
  [
    "Moere",
    "mvq"
  ],
  [
    "Marau",
    "mvr"
  ],
  [
    "Massep",
    "mvs"
  ],
  [
    "Mpotovoro",
    "mvt"
  ],
  [
    "Marfa",
    "mvu"
  ],
  [
    "Tagal Murut",
    "mvv"
  ],
  [
    "Machinga",
    "mvw"
  ],
  [
    "Meoswar",
    "mvx"
  ],
  [
    "Indus Kohistani",
    "mvy"
  ],
  [
    "Mesqan",
    "mvz"
  ],
  [
    "Mwatebu",
    "mwa"
  ],
  [
    "Juwal",
    "mwb"
  ],
  [
    "Are",
    "mwc"
  ],
  [
    "Mwera (Chimwera)",
    "mwe"
  ],
  [
    "Murrinh-Patha",
    "mwf"
  ],
  [
    "Aiklep",
    "mwg"
  ],
  [
    "Mouk-Aria",
    "mwh"
  ],
  [
    "Labo",
    "mwi"
  ],
  [
    "Kita Maninkakan",
    "mwk"
  ],
  [
    "Mirandese",
    "mwl",
    "mwl",
    "mwl"
  ],
  [
    "Sar",
    "mwm"
  ],
  [
    "Nyamwanga",
    "mwn"
  ],
  [
    "Central Maewo",
    "mwo"
  ],
  [
    "Kala Lagaw Ya",
    "mwp"
  ],
  [
    "Mün Chin",
    "mwq"
  ],
  [
    "Marwari",
    "mwr",
    "mwr",
    "mwr"
  ],
  [
    "Mwimbi-Muthambi",
    "mws"
  ],
  [
    "Moken",
    "mwt"
  ],
  [
    "Mittu",
    "mwu"
  ],
  [
    "Mentawai",
    "mwv"
  ],
  [
    "Hmong Daw",
    "mww"
  ],
  [
    "Moingi",
    "mwz"
  ],
  [
    "Northwest Oaxaca Mixtec",
    "mxa"
  ],
  [
    "Tezoatlán Mixtec",
    "mxb"
  ],
  [
    "Manyika",
    "mxc"
  ],
  [
    "Modang",
    "mxd"
  ],
  [
    "Mele-Fila",
    "mxe"
  ],
  [
    "Malgbe",
    "mxf"
  ],
  [
    "Mbangala",
    "mxg"
  ],
  [
    "Mvuba",
    "mxh"
  ],
  [
    "Mozarabic",
    "mxi"
  ],
  [
    "Miju-Mishmi",
    "mxj"
  ],
  [
    "Monumbo",
    "mxk"
  ],
  [
    "Maxi Gbe",
    "mxl"
  ],
  [
    "Meramera",
    "mxm"
  ],
  [
    "Moi (Indonesia)",
    "mxn"
  ],
  [
    "Mbowe",
    "mxo"
  ],
  [
    "Tlahuitoltepec Mixe",
    "mxp"
  ],
  [
    "Juquila Mixe",
    "mxq"
  ],
  [
    "Murik (Malaysia)",
    "mxr"
  ],
  [
    "Huitepec Mixtec",
    "mxs"
  ],
  [
    "Jamiltepec Mixtec",
    "mxt"
  ],
  [
    "Mada (Cameroon)",
    "mxu"
  ],
  [
    "Metlatónoc Mixtec",
    "mxv"
  ],
  [
    "Namo",
    "mxw"
  ],
  [
    "Mahou",
    "mxx"
  ],
  [
    "Southeastern Nochixtlán Mixtec",
    "mxy"
  ],
  [
    "Central Masela",
    "mxz"
  ],
  [
    "Burmese",
    "mya",
    "bur",
    "mya",
    "my",
    "Myanmasa"
  ],
  [
    "Mbay",
    "myb"
  ],
  [
    "Mayeka",
    "myc"
  ],
  [
    "Myene",
    "mye"
  ],
  [
    "Bambassi",
    "myf"
  ],
  [
    "Manta",
    "myg"
  ],
  [
    "Makah",
    "myh"
  ],
  [
    "Mangayat",
    "myj"
  ],
  [
    "Mamara Senoufo",
    "myk"
  ],
  [
    "Moma",
    "myl"
  ],
  [
    "Me'en",
    "mym"
  ],
  [
    "Anfillo",
    "myo"
  ],
  [
    "Pirahã",
    "myp"
  ],
  [
    "Muniche",
    "myr"
  ],
  [
    "Mesmes",
    "mys"
  ],
  [
    "Mundurukú",
    "myu"
  ],
  [
    "Erzya",
    "myv",
    "myv",
    "myv"
  ],
  [
    "Muyuw",
    "myw"
  ],
  [
    "Masaaba",
    "myx"
  ],
  [
    "Macuna",
    "myy"
  ],
  [
    "Classical Mandaic",
    "myz"
  ],
  [
    "Santa María Zacatepec Mixtec",
    "mza"
  ],
  [
    "Tumzabt",
    "mzb"
  ],
  [
    "Madagascar Sign Language",
    "mzc"
  ],
  [
    "Malimba",
    "mzd"
  ],
  [
    "Morawa",
    "mze"
  ],
  [
    "Monastic Sign Language",
    "mzg"
  ],
  [
    "Wichí Lhamtés Güisnay",
    "mzh"
  ],
  [
    "Ixcatlán Mazatec",
    "mzi"
  ],
  [
    "Manya",
    "mzj"
  ],
  [
    "Nigeria Mambila",
    "mzk"
  ],
  [
    "Mazatlán Mixe",
    "mzl"
  ],
  [
    "Mumuye",
    "mzm"
  ],
  [
    "Mazanderani",
    "mzn"
  ],
  [
    "Matipuhy",
    "mzo"
  ],
  [
    "Movima",
    "mzp"
  ],
  [
    "Mori Atas",
    "mzq"
  ],
  [
    "Marúbo",
    "mzr"
  ],
  [
    "Macanese",
    "mzs"
  ],
  [
    "Mintil",
    "mzt"
  ],
  [
    "Inapang",
    "mzu"
  ],
  [
    "Manza",
    "mzv"
  ],
  [
    "Deg",
    "mzw"
  ],
  [
    "Mawayana",
    "mzx"
  ],
  [
    "Mozambican Sign Language",
    "mzy"
  ],
  [
    "Maiadomu",
    "mzz"
  ],
  [
    "Namla",
    "naa"
  ],
  [
    "Southern Nambikuára",
    "nab"
  ],
  [
    "Narak",
    "nac"
  ],
  [
    "Naka'ela",
    "nae"
  ],
  [
    "Nabak",
    "naf"
  ],
  [
    "Naga Pidgin",
    "nag"
  ],
  [
    "Nalu",
    "naj"
  ],
  [
    "Nakanai",
    "nak"
  ],
  [
    "Nalik",
    "nal"
  ],
  [
    "Ngan'gityemerri",
    "nam"
  ],
  [
    "Min Nan Chinese",
    "nan"
  ],
  [
    "Naaba",
    "nao"
  ],
  [
    "Neapolitan",
    "nap",
    "nap",
    "nap"
  ],
  [
    "Khoekhoe",
    "naq"
  ],
  [
    "Iguta",
    "nar"
  ],
  [
    "Naasioi",
    "nas"
  ],
  [
    "Ca̱hungwa̱rya̱",
    "nat"
  ],
  [
    "Nauru",
    "nau",
    "nau",
    "nau",
    "na",
    "Dorerin Naoero"
  ],
  [
    "Navajo",
    "nav",
    "nav",
    "nav",
    "nv",
    "Diné bizaad"
  ],
  [
    "Nawuri",
    "naw"
  ],
  [
    "Nakwi",
    "nax"
  ],
  [
    "Ngarrindjeri",
    "nay"
  ],
  [
    "Coatepec Nahuatl",
    "naz"
  ],
  [
    "Nyemba",
    "nba"
  ],
  [
    "Ndoe",
    "nbb"
  ],
  [
    "Chang Naga",
    "nbc"
  ],
  [
    "Ngbinda",
    "nbd"
  ],
  [
    "Konyak Naga",
    "nbe"
  ],
  [
    "Nagarchal",
    "nbg"
  ],
  [
    "Ngamo",
    "nbh"
  ],
  [
    "Mao Naga",
    "nbi"
  ],
  [
    "Ngarinyman",
    "nbj"
  ],
  [
    "Nake",
    "nbk"
  ],
  [
    "South Ndebele",
    "nbl",
    "nbl",
    "nbl",
    "nr",
    "isiNdebele"
  ],
  [
    "Ngbaka Ma'bo",
    "nbm"
  ],
  [
    "Kuri",
    "nbn"
  ],
  [
    "Nkukoli",
    "nbo"
  ],
  [
    "Nnam",
    "nbp"
  ],
  [
    "Nggem",
    "nbq"
  ],
  [
    "Numana",
    "nbr"
  ],
  [
    "Namibian Sign Language",
    "nbs"
  ],
  [
    "Na",
    "nbt"
  ],
  [
    "Rongmei Naga",
    "nbu"
  ],
  [
    "Ngamambo",
    "nbv"
  ],
  [
    "Southern Ngbandi",
    "nbw"
  ],
  [
    "Ningera",
    "nby"
  ],
  [
    "Iyo",
    "nca"
  ],
  [
    "Central Nicobarese",
    "ncb"
  ],
  [
    "Ponam",
    "ncc"
  ],
  [
    "Nachering",
    "ncd"
  ],
  [
    "Yale",
    "nce"
  ],
  [
    "Notsi",
    "ncf"
  ],
  [
    "Nisga'a",
    "ncg"
  ],
  [
    "Central Huasteca Nahuatl",
    "nch"
  ],
  [
    "Classical Nahuatl",
    "nci"
  ],
  [
    "Northern Puebla Nahuatl",
    "ncj"
  ],
  [
    "Na-kara",
    "nck"
  ],
  [
    "Michoacán Nahuatl",
    "ncl"
  ],
  [
    "Nambo",
    "ncm"
  ],
  [
    "Nauna",
    "ncn"
  ],
  [
    "Sibe",
    "nco"
  ],
  [
    "Northern Katang",
    "ncq"
  ],
  [
    "Ncane",
    "ncr"
  ],
  [
    "Nicaraguan Sign Language",
    "ncs"
  ],
  [
    "Chothe Naga",
    "nct"
  ],
  [
    "Chumburung",
    "ncu"
  ],
  [
    "Central Puebla Nahuatl",
    "ncx"
  ],
  [
    "Natchez",
    "ncz"
  ],
  [
    "Ndasa",
    "nda"
  ],
  [
    "Kenswei Nsei",
    "ndb"
  ],
  [
    "Ndau",
    "ndc"
  ],
  [
    "Nde-Nsele-Nta",
    "ndd"
  ],
  [
    "North Ndebele",
    "nde",
    "nde",
    "nde",
    "nd",
    "Sindebele"
  ],
  [
    "Nadruvian",
    "ndf"
  ],
  [
    "Ndengereko",
    "ndg"
  ],
  [
    "Ndali",
    "ndh"
  ],
  [
    "Samba Leko",
    "ndi"
  ],
  [
    "Ndamba",
    "ndj"
  ],
  [
    "Ndaka",
    "ndk"
  ],
  [
    "Ndolo",
    "ndl"
  ],
  [
    "Ndam",
    "ndm"
  ],
  [
    "Ngundi",
    "ndn"
  ],
  [
    "Ndonga",
    "ndo",
    "ndo",
    "ndo",
    "ng",
    "Oshiwambo"
  ],
  [
    "Ndo",
    "ndp"
  ],
  [
    "Ndombe",
    "ndq"
  ],
  [
    "Ndoola",
    "ndr"
  ],
  [
    "Low German",
    "nds",
    "nds",
    "nds"
  ],
  [
    "Ndunga",
    "ndt"
  ],
  [
    "Dugun",
    "ndu"
  ],
  [
    "Ndut",
    "ndv"
  ],
  [
    "Ndobo",
    "ndw"
  ],
  [
    "Nduga",
    "ndx"
  ],
  [
    "Lutos",
    "ndy"
  ],
  [
    "Ndogo",
    "ndz"
  ],
  [
    "Eastern Ngad'a",
    "nea"
  ],
  [
    "Toura (Côte d'Ivoire)",
    "neb"
  ],
  [
    "Nedebang",
    "nec"
  ],
  [
    "Nde-Gbite",
    "ned"
  ],
  [
    "Nêlêmwa-Nixumwak",
    "nee"
  ],
  [
    "Nefamese",
    "nef"
  ],
  [
    "Negidal",
    "neg"
  ],
  [
    "Nyenkha",
    "neh"
  ],
  [
    "Neo-Hittite",
    "nei"
  ],
  [
    "Neko",
    "nej"
  ],
  [
    "Neku",
    "nek"
  ],
  [
    "Nemi",
    "nem"
  ],
  [
    "Nengone",
    "nen"
  ],
  [
    "Ná-Meo",
    "neo"
  ],
  [
    "Nepali (macrolanguage)",
    "nep",
    "nep",
    "nep",
    "ne",
    "नेपाली"
  ],
  [
    "North Central Mixe",
    "neq"
  ],
  [
    "Yahadian",
    "ner"
  ],
  [
    "Bhoti Kinnauri",
    "nes"
  ],
  [
    "Nete",
    "net"
  ],
  [
    "Neo",
    "neu"
  ],
  [
    "Nyaheun",
    "nev"
  ],
  [
    "Newari",
    "new",
    "new",
    "new"
  ],
  [
    "Neme",
    "nex"
  ],
  [
    "Neyo",
    "ney"
  ],
  [
    "Nez Perce",
    "nez"
  ],
  [
    "Dhao",
    "nfa"
  ],
  [
    "Ahwai",
    "nfd"
  ],
  [
    "Ayiwo",
    "nfl"
  ],
  [
    "Nafaanra",
    "nfr"
  ],
  [
    "Mfumte",
    "nfu"
  ],
  [
    "Ngbaka",
    "nga"
  ],
  [
    "Northern Ngbandi",
    "ngb"
  ],
  [
    "Ngombe (Democratic Republic of Congo)",
    "ngc"
  ],
  [
    "Ngando (Central African Republic)",
    "ngd"
  ],
  [
    "Ngemba",
    "nge"
  ],
  [
    "Ngbaka Manza",
    "ngg"
  ],
  [
    "Nǁng",
    "ngh"
  ],
  [
    "Ngizim",
    "ngi"
  ],
  [
    "Ngie",
    "ngj"
  ],
  [
    "Dalabon",
    "ngk"
  ],
  [
    "Lomwe",
    "ngl"
  ],
  [
    "Ngatik Men's Creole",
    "ngm"
  ],
  [
    "Ngwo",
    "ngn"
  ],
  [
    "Ngulu",
    "ngp"
  ],
  [
    "Ngurimi",
    "ngq"
  ],
  [
    "Engdewu",
    "ngr"
  ],
  [
    "Gvoko",
    "ngs"
  ],
  [
    "Kriang",
    "ngt"
  ],
  [
    "Guerrero Nahuatl",
    "ngu"
  ],
  [
    "Nagumi",
    "ngv"
  ],
  [
    "Ngwaba",
    "ngw"
  ],
  [
    "Nggwahyi",
    "ngx"
  ],
  [
    "Tibea",
    "ngy"
  ],
  [
    "Ngungwel",
    "ngz"
  ],
  [
    "Nhanda",
    "nha"
  ],
  [
    "Beng",
    "nhb"
  ],
  [
    "Tabasco Nahuatl",
    "nhc"
  ],
  [
    "Chiripá",
    "nhd"
  ],
  [
    "Eastern Huasteca Nahuatl",
    "nhe"
  ],
  [
    "Nhuwala",
    "nhf"
  ],
  [
    "Tetelcingo Nahuatl",
    "nhg"
  ],
  [
    "Nahari",
    "nhh"
  ],
  [
    "Zacatlán-Ahuacatlán-Tepetzintla Nahuatl",
    "nhi"
  ],
  [
    "Isthmus-Cosoleacaque Nahuatl",
    "nhk"
  ],
  [
    "Morelos Nahuatl",
    "nhm"
  ],
  [
    "Central Nahuatl",
    "nhn"
  ],
  [
    "Takuu",
    "nho"
  ],
  [
    "Isthmus-Pajapan Nahuatl",
    "nhp"
  ],
  [
    "Huaxcaleca Nahuatl",
    "nhq"
  ],
  [
    "Naro",
    "nhr"
  ],
  [
    "Ometepec Nahuatl",
    "nht"
  ],
  [
    "Noone",
    "nhu"
  ],
  [
    "Temascaltepec Nahuatl",
    "nhv"
  ],
  [
    "Western Huasteca Nahuatl",
    "nhw"
  ],
  [
    "Isthmus-Mecayapan Nahuatl",
    "nhx"
  ],
  [
    "Northern Oaxaca Nahuatl",
    "nhy"
  ],
  [
    "Santa María La Alta Nahuatl",
    "nhz"
  ],
  [
    "Nias",
    "nia",
    "nia",
    "nia"
  ],
  [
    "Nakame",
    "nib"
  ],
  [
    "Ngandi",
    "nid"
  ],
  [
    "Niellim",
    "nie"
  ],
  [
    "Nek",
    "nif"
  ],
  [
    "Ngalakgan",
    "nig"
  ],
  [
    "Nyiha (Tanzania)",
    "nih"
  ],
  [
    "Nii",
    "nii"
  ],
  [
    "Ngaju",
    "nij"
  ],
  [
    "Southern Nicobarese",
    "nik"
  ],
  [
    "Nila",
    "nil"
  ],
  [
    "Nilamba",
    "nim"
  ],
  [
    "Ninzo",
    "nin"
  ],
  [
    "Nganasan",
    "nio"
  ],
  [
    "Nandi",
    "niq"
  ],
  [
    "Nimboran",
    "nir"
  ],
  [
    "Nimi",
    "nis"
  ],
  [
    "Southeastern Kolami",
    "nit"
  ],
  [
    "Niuean",
    "niu",
    "niu",
    "niu"
  ],
  [
    "Gilyak",
    "niv"
  ],
  [
    "Nimo",
    "niw"
  ],
  [
    "Hema",
    "nix"
  ],
  [
    "Ngiti",
    "niy"
  ],
  [
    "Ningil",
    "niz"
  ],
  [
    "Nzanyi",
    "nja"
  ],
  [
    "Nocte Naga",
    "njb"
  ],
  [
    "Ndonde Hamba",
    "njd"
  ],
  [
    "Lotha Naga",
    "njh"
  ],
  [
    "Gudanji",
    "nji"
  ],
  [
    "Njen",
    "njj"
  ],
  [
    "Njalgulgule",
    "njl"
  ],
  [
    "Angami Naga",
    "njm"
  ],
  [
    "Liangmai Naga",
    "njn"
  ],
  [
    "Ao Naga",
    "njo"
  ],
  [
    "Njerep",
    "njr"
  ],
  [
    "Nisa",
    "njs"
  ],
  [
    "Ndyuka-Trio Pidgin",
    "njt"
  ],
  [
    "Ngadjunmaya",
    "nju"
  ],
  [
    "Kunyi",
    "njx"
  ],
  [
    "Njyem",
    "njy"
  ],
  [
    "Nyishi",
    "njz"
  ],
  [
    "Nkoya",
    "nka"
  ],
  [
    "Khoibu Naga",
    "nkb"
  ],
  [
    "Nkongho",
    "nkc"
  ],
  [
    "Koireng",
    "nkd"
  ],
  [
    "Duke",
    "nke"
  ],
  [
    "Inpui Naga",
    "nkf"
  ],
  [
    "Nekgini",
    "nkg"
  ],
  [
    "Khezha Naga",
    "nkh"
  ],
  [
    "Thangal Naga",
    "nki"
  ],
  [
    "Nakai",
    "nkj"
  ],
  [
    "Nokuku",
    "nkk"
  ],
  [
    "Namat",
    "nkm"
  ],
  [
    "Nkangala",
    "nkn"
  ],
  [
    "Nkonya",
    "nko"
  ],
  [
    "Niuatoputapu",
    "nkp"
  ],
  [
    "Nkami",
    "nkq"
  ],
  [
    "Nukuoro",
    "nkr"
  ],
  [
    "North Asmat",
    "nks"
  ],
  [
    "Nyika (Tanzania)",
    "nkt"
  ],
  [
    "Bouna Kulango",
    "nku"
  ],
  [
    "Nyika (Malawi and Zambia)",
    "nkv"
  ],
  [
    "Nkutu",
    "nkw"
  ],
  [
    "Nkoroo",
    "nkx"
  ],
  [
    "Nkari",
    "nkz"
  ],
  [
    "Ngombale",
    "nla"
  ],
  [
    "Nalca",
    "nlc"
  ],
  [
    "Dutch",
    "nld",
    "dut",
    "nld",
    "nl",
    "Nederlands"
  ],
  [
    "East Nyala",
    "nle"
  ],
  [
    "Gela",
    "nlg"
  ],
  [
    "Grangali",
    "nli"
  ],
  [
    "Nyali",
    "nlj"
  ],
  [
    "Ninia Yali",
    "nlk"
  ],
  [
    "Nihali",
    "nll"
  ],
  [
    "Mankiyali",
    "nlm"
  ],
  [
    "Ngul",
    "nlo"
  ],
  [
    "Lao Naga",
    "nlq"
  ],
  [
    "Nchumbulu",
    "nlu"
  ],
  [
    "Orizaba Nahuatl",
    "nlv"
  ],
  [
    "Walangama",
    "nlw"
  ],
  [
    "Nahali",
    "nlx"
  ],
  [
    "Nyamal",
    "nly"
  ],
  [
    "Nalögo",
    "nlz"
  ],
  [
    "Maram Naga",
    "nma"
  ],
  [
    "Big Nambas",
    "nmb"
  ],
  [
    "Ngam",
    "nmc"
  ],
  [
    "Ndumu",
    "nmd"
  ],
  [
    "Mzieme Naga",
    "nme"
  ],
  [
    "Tangkhul Naga (India)",
    "nmf"
  ],
  [
    "Kwasio",
    "nmg"
  ],
  [
    "Monsang Naga",
    "nmh"
  ],
  [
    "Nyam",
    "nmi"
  ],
  [
    "Ngombe (Central African Republic)",
    "nmj"
  ],
  [
    "Namakura",
    "nmk"
  ],
  [
    "Ndemli",
    "nml"
  ],
  [
    "Manangba",
    "nmm"
  ],
  [
    "ǃXóõ",
    "nmn"
  ],
  [
    "Moyon Naga",
    "nmo"
  ],
  [
    "Nimanbur",
    "nmp"
  ],
  [
    "Nambya",
    "nmq"
  ],
  [
    "Nimbari",
    "nmr"
  ],
  [
    "Letemboi",
    "nms"
  ],
  [
    "Namonuito",
    "nmt"
  ],
  [
    "Northeast Maidu",
    "nmu"
  ],
  [
    "Ngamini",
    "nmv"
  ],
  [
    "Nimoa",
    "nmw"
  ],
  [
    "Nama (Papua New Guinea)",
    "nmx"
  ],
  [
    "Namuyi",
    "nmy"
  ],
  [
    "Nawdm",
    "nmz"
  ],
  [
    "Nyangumarta",
    "nna"
  ],
  [
    "Nande",
    "nnb"
  ],
  [
    "Nancere",
    "nnc"
  ],
  [
    "West Ambae",
    "nnd"
  ],
  [
    "Ngandyera",
    "nne"
  ],
  [
    "Ngaing",
    "nnf"
  ],
  [
    "Maring Naga",
    "nng"
  ],
  [
    "Ngiemboon",
    "nnh"
  ],
  [
    "North Nuaulu",
    "nni"
  ],
  [
    "Nyangatom",
    "nnj"
  ],
  [
    "Nankina",
    "nnk"
  ],
  [
    "Northern Rengma Naga",
    "nnl"
  ],
  [
    "Namia",
    "nnm"
  ],
  [
    "Ngete",
    "nnn"
  ],
  [
    "Norwegian Nynorsk",
    "nno",
    "nno",
    "nno",
    "nn",
    "Norsk (nynorsk)"
  ],
  [
    "Wancho Naga",
    "nnp"
  ],
  [
    "Ngindo",
    "nnq"
  ],
  [
    "Narungga",
    "nnr"
  ],
  [
    "Nanticoke",
    "nnt"
  ],
  [
    "Dwang",
    "nnu"
  ],
  [
    "Nugunu (Australia)",
    "nnv"
  ],
  [
    "Southern Nuni",
    "nnw"
  ],
  [
    "Nyangga",
    "nny"
  ],
  [
    "Nda'nda'",
    "nnz"
  ],
  [
    "Woun Meu",
    "noa"
  ],
  [
    "Norwegian Bokmål",
    "nob",
    "nob",
    "nob",
    "nb",
    "Norsk (bokmål)"
  ],
  [
    "Nuk",
    "noc"
  ],
  [
    "Northern Thai",
    "nod"
  ],
  [
    "Nimadi",
    "noe"
  ],
  [
    "Nomane",
    "nof"
  ],
  [
    "Nogai",
    "nog",
    "nog",
    "nog"
  ],
  [
    "Nomu",
    "noh"
  ],
  [
    "Noiri",
    "noi"
  ],
  [
    "Nonuya",
    "noj"
  ],
  [
    "Nooksack",
    "nok"
  ],
  [
    "Nomlaki",
    "nol"
  ],
  [
    "Nocamán",
    "nom"
  ],
  [
    "Old Norse",
    "non",
    "non",
    "non"
  ],
  [
    "Numanggang",
    "nop"
  ],
  [
    "Ngongo",
    "noq"
  ],
  [
    "Norwegian",
    "nor",
    "nor",
    "nor",
    "no",
    "Norsk (bokmål / riksmål)"
  ],
  [
    "Eastern Nisu",
    "nos"
  ],
  [
    "Nomatsiguenga",
    "not"
  ],
  [
    "Ewage-Notu",
    "nou"
  ],
  [
    "Novial",
    "nov"
  ],
  [
    "Nyambo",
    "now"
  ],
  [
    "Noy",
    "noy"
  ],
  [
    "Nayi",
    "noz"
  ],
  [
    "Nar Phu",
    "npa"
  ],
  [
    "Nupbikha",
    "npb"
  ],
  [
    "Ponyo-Gongwang Naga",
    "npg"
  ],
  [
    "Phom Naga",
    "nph"
  ],
  [
    "Nepali (individual language)",
    "npi"
  ],
  [
    "Southeastern Puebla Nahuatl",
    "npl"
  ],
  [
    "Mondropolon",
    "npn"
  ],
  [
    "Pochuri Naga",
    "npo"
  ],
  [
    "Nipsan",
    "nps"
  ],
  [
    "Puimei Naga",
    "npu"
  ],
  [
    "Noipx",
    "npx"
  ],
  [
    "Napu",
    "npy"
  ],
  [
    "Southern Nago",
    "nqg"
  ],
  [
    "Kura Ede Nago",
    "nqk"
  ],
  [
    "Ngendelengo",
    "nql"
  ],
  [
    "Ndom",
    "nqm"
  ],
  [
    "Nen",
    "nqn"
  ],
  [
    "N'Ko",
    "nqo",
    "nqo",
    "nqo"
  ],
  [
    "Kyan-Karyaw Naga",
    "nqq"
  ],
  [
    "Nteng",
    "nqt"
  ],
  [
    "Akyaung Ari Naga",
    "nqy"
  ],
  [
    "Ngom",
    "nra"
  ],
  [
    "Nara",
    "nrb"
  ],
  [
    "Noric",
    "nrc"
  ],
  [
    "Southern Rengma Naga",
    "nre"
  ],
  [
    "Jèrriais",
    "nrf"
  ],
  [
    "Narango",
    "nrg"
  ],
  [
    "Chokri Naga",
    "nri"
  ],
  [
    "Ngarla",
    "nrk"
  ],
  [
    "Ngarluma",
    "nrl"
  ],
  [
    "Narom",
    "nrm"
  ],
  [
    "Norn",
    "nrn"
  ],
  [
    "North Picene",
    "nrp"
  ],
  [
    "Norra",
    "nrr"
  ],
  [
    "Northern Kalapuya",
    "nrt"
  ],
  [
    "Narua",
    "nru"
  ],
  [
    "Ngurmbur",
    "nrx"
  ],
  [
    "Lala",
    "nrz"
  ],
  [
    "Sangtam Naga",
    "nsa"
  ],
  [
    "Lower Nossob",
    "nsb"
  ],
  [
    "Nshi",
    "nsc"
  ],
  [
    "Southern Nisu",
    "nsd"
  ],
  [
    "Nsenga",
    "nse"
  ],
  [
    "Northwestern Nisu",
    "nsf"
  ],
  [
    "Ngasa",
    "nsg"
  ],
  [
    "Ngoshie",
    "nsh"
  ],
  [
    "Nigerian Sign Language",
    "nsi"
  ],
  [
    "Naskapi",
    "nsk"
  ],
  [
    "Norwegian Sign Language",
    "nsl"
  ],
  [
    "Sumi Naga",
    "nsm"
  ],
  [
    "Nehan",
    "nsn"
  ],
  [
    "Pedi",
    "nso",
    "nso",
    "nso"
  ],
  [
    "Nepalese Sign Language",
    "nsp"
  ],
  [
    "Northern Sierra Miwok",
    "nsq"
  ],
  [
    "Maritime Sign Language",
    "nsr"
  ],
  [
    "Nali",
    "nss"
  ],
  [
    "Tase Naga",
    "nst"
  ],
  [
    "Sierra Negra Nahuatl",
    "nsu"
  ],
  [
    "Southwestern Nisu",
    "nsv"
  ],
  [
    "Navut",
    "nsw"
  ],
  [
    "Nsongo",
    "nsx"
  ],
  [
    "Nasal",
    "nsy"
  ],
  [
    "Nisenan",
    "nsz"
  ],
  [
    "Northern Tidung",
    "ntd"
  ],
  [
    "Nathembo",
    "nte"
  ],
  [
    "Ngantangarra",
    "ntg"
  ],
  [
    "Natioro",
    "nti"
  ],
  [
    "Ngaanyatjarra",
    "ntj"
  ],
  [
    "Ikoma-Nata-Isenye",
    "ntk"
  ],
  [
    "Nateni",
    "ntm"
  ],
  [
    "Ntomba",
    "nto"
  ],
  [
    "Northern Tepehuan",
    "ntp"
  ],
  [
    "Delo",
    "ntr"
  ],
  [
    "Natügu",
    "ntu"
  ],
  [
    "Nottoway",
    "ntw"
  ],
  [
    "Tangkhul Naga (Myanmar)",
    "ntx"
  ],
  [
    "Mantsi",
    "nty"
  ],
  [
    "Natanzi",
    "ntz"
  ],
  [
    "Yuanga",
    "nua"
  ],
  [
    "Nukuini",
    "nuc"
  ],
  [
    "Ngala",
    "nud"
  ],
  [
    "Ngundu",
    "nue"
  ],
  [
    "Nusu",
    "nuf"
  ],
  [
    "Nungali",
    "nug"
  ],
  [
    "Ndunda",
    "nuh"
  ],
  [
    "Ngumbi",
    "nui"
  ],
  [
    "Nyole",
    "nuj"
  ],
  [
    "Nuu-chah-nulth",
    "nuk"
  ],
  [
    "Nusa Laut",
    "nul"
  ],
  [
    "Niuafo'ou",
    "num"
  ],
  [
    "Anong",
    "nun"
  ],
  [
    "Nguôn",
    "nuo"
  ],
  [
    "Nupe-Nupe-Tako",
    "nup"
  ],
  [
    "Nukumanu",
    "nuq"
  ],
  [
    "Nukuria",
    "nur"
  ],
  [
    "Nuer",
    "nus"
  ],
  [
    "Nung (Viet Nam)",
    "nut"
  ],
  [
    "Ngbundu",
    "nuu"
  ],
  [
    "Northern Nuni",
    "nuv"
  ],
  [
    "Nguluwan",
    "nuw"
  ],
  [
    "Mehek",
    "nux"
  ],
  [
    "Nunggubuyu",
    "nuy"
  ],
  [
    "Tlamacazapa Nahuatl",
    "nuz"
  ],
  [
    "Nasarian",
    "nvh"
  ],
  [
    "Namiae",
    "nvm"
  ],
  [
    "Nyokon",
    "nvo"
  ],
  [
    "Nawathinehena",
    "nwa"
  ],
  [
    "Nyabwa",
    "nwb"
  ],
  [
    "Classical Newari",
    "nwc",
    "nwc",
    "nwc"
  ],
  [
    "Ngwe",
    "nwe"
  ],
  [
    "Ngayawung",
    "nwg"
  ],
  [
    "Southwest Tanna",
    "nwi"
  ],
  [
    "Nyamusa-Molo",
    "nwm"
  ],
  [
    "Nauo",
    "nwo"
  ],
  [
    "Nawaru",
    "nwr"
  ],
  [
    "Ndwewe",
    "nww"
  ],
  [
    "Middle Newar",
    "nwx"
  ],
  [
    "Nottoway-Meherrin",
    "nwy"
  ],
  [
    "Nauete",
    "nxa"
  ],
  [
    "Ngando (Democratic Republic of Congo)",
    "nxd"
  ],
  [
    "Nage",
    "nxe"
  ],
  [
    "Ngad'a",
    "nxg"
  ],
  [
    "Nindi",
    "nxi"
  ],
  [
    "Koki Naga",
    "nxk"
  ],
  [
    "South Nuaulu",
    "nxl"
  ],
  [
    "Numidian",
    "nxm"
  ],
  [
    "Ngawun",
    "nxn"
  ],
  [
    "Ndambomo",
    "nxo"
  ],
  [
    "Naxi",
    "nxq"
  ],
  [
    "Ninggerum",
    "nxr"
  ],
  [
    "Nafri",
    "nxx"
  ],
  [
    "Nyanja",
    "nya",
    "nya",
    "nya",
    "ny",
    "Chi-Chewa"
  ],
  [
    "Nyangbo",
    "nyb"
  ],
  [
    "Nyanga-li",
    "nyc"
  ],
  [
    "Nyore",
    "nyd"
  ],
  [
    "Nyengo",
    "nye"
  ],
  [
    "Giryama",
    "nyf"
  ],
  [
    "Nyindu",
    "nyg"
  ],
  [
    "Nyikina",
    "nyh"
  ],
  [
    "Ama (Sudan)",
    "nyi"
  ],
  [
    "Nyanga",
    "nyj"
  ],
  [
    "Nyaneka",
    "nyk"
  ],
  [
    "Nyeu",
    "nyl"
  ],
  [
    "Nyamwezi",
    "nym",
    "nym",
    "nym"
  ],
  [
    "Nyankole",
    "nyn",
    "nyn",
    "nyn"
  ],
  [
    "Nyoro",
    "nyo",
    "nyo",
    "nyo"
  ],
  [
    "Nyang'i",
    "nyp"
  ],
  [
    "Nayini",
    "nyq"
  ],
  [
    "Nyiha (Malawi)",
    "nyr"
  ],
  [
    "Nyungar",
    "nys"
  ],
  [
    "Nyawaygi",
    "nyt"
  ],
  [
    "Nyungwe",
    "nyu"
  ],
  [
    "Nyulnyul",
    "nyv"
  ],
  [
    "Nyaw",
    "nyw"
  ],
  [
    "Nganyaywana",
    "nyx"
  ],
  [
    "Nyakyusa-Ngonde",
    "nyy"
  ],
  [
    "Tigon Mbembe",
    "nza"
  ],
  [
    "Njebi",
    "nzb"
  ],
  [
    "Nzadi",
    "nzd"
  ],
  [
    "Nzima",
    "nzi",
    "nzi",
    "nzi"
  ],
  [
    "Nzakara",
    "nzk"
  ],
  [
    "Zeme Naga",
    "nzm"
  ],
  [
    "New Zealand Sign Language",
    "nzs"
  ],
  [
    "Teke-Nzikou",
    "nzu"
  ],
  [
    "Nzakambay",
    "nzy"
  ],
  [
    "Nanga Dama Dogon",
    "nzz"
  ],
  [
    "Orok",
    "oaa"
  ],
  [
    "Oroch",
    "oac"
  ],
  [
    "Old Aramaic (up to 700 BCE)",
    "oar"
  ],
  [
    "Old Avar",
    "oav"
  ],
  [
    "Obispeño",
    "obi"
  ],
  [
    "Southern Bontok",
    "obk"
  ],
  [
    "Oblo",
    "obl"
  ],
  [
    "Moabite",
    "obm"
  ],
  [
    "Obo Manobo",
    "obo"
  ],
  [
    "Old Burmese",
    "obr"
  ],
  [
    "Old Breton",
    "obt"
  ],
  [
    "Obulom",
    "obu"
  ],
  [
    "Ocaina",
    "oca"
  ],
  [
    "Old Chinese",
    "och"
  ],
  [
    "Occitan (post 1500)",
    "oci",
    "oci",
    "oci",
    "oc",
    "Occitan"
  ],
  [
    "Old Cham",
    "ocm"
  ],
  [
    "Old Cornish",
    "oco"
  ],
  [
    "Atzingo Matlatzinca",
    "ocu"
  ],
  [
    "Odut",
    "oda"
  ],
  [
    "Od",
    "odk"
  ],
  [
    "Old Dutch",
    "odt"
  ],
  [
    "Odual",
    "odu"
  ],
  [
    "Ofo",
    "ofo"
  ],
  [
    "Old Frisian",
    "ofs"
  ],
  [
    "Efutop",
    "ofu"
  ],
  [
    "Ogbia",
    "ogb"
  ],
  [
    "Ogbah",
    "ogc"
  ],
  [
    "Old Georgian",
    "oge"
  ],
  [
    "Ogbogolo",
    "ogg"
  ],
  [
    "Khana",
    "ogo"
  ],
  [
    "Ogbronuagum",
    "ogu"
  ],
  [
    "Old Hittite",
    "oht"
  ],
  [
    "Old Hungarian",
    "ohu"
  ],
  [
    "Oirata",
    "oia"
  ],
  [
    "Okolie",
    "oie"
  ],
  [
    "Inebu One",
    "oin"
  ],
  [
    "Northwestern Ojibwa",
    "ojb"
  ],
  [
    "Central Ojibwa",
    "ojc"
  ],
  [
    "Eastern Ojibwa",
    "ojg"
  ],
  [
    "Ojibwa",
    "oji",
    "oji",
    "oji",
    "oj",
    "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin"
  ],
  [
    "Old Japanese",
    "ojp"
  ],
  [
    "Severn Ojibwa",
    "ojs"
  ],
  [
    "Ontong Java",
    "ojv"
  ],
  [
    "Western Ojibwa",
    "ojw"
  ],
  [
    "Okanagan",
    "oka"
  ],
  [
    "Okobo",
    "okb"
  ],
  [
    "Kobo",
    "okc"
  ],
  [
    "Okodia",
    "okd"
  ],
  [
    "Okpe (Southwestern Edo)",
    "oke"
  ],
  [
    "Koko Babangk",
    "okg"
  ],
  [
    "Koresh-e Rostam",
    "okh"
  ],
  [
    "Okiek",
    "oki"
  ],
  [
    "Oko-Juwoi",
    "okj"
  ],
  [
    "Kwamtim One",
    "okk"
  ],
  [
    "Old Kentish Sign Language",
    "okl"
  ],
  [
    "Middle Korean (10th-16th cent.)",
    "okm"
  ],
  [
    "Oki-No-Erabu",
    "okn"
  ],
  [
    "Old Korean (3rd-9th cent.)",
    "oko"
  ],
  [
    "Kirike",
    "okr"
  ],
  [
    "Oko-Eni-Osayen",
    "oks"
  ],
  [
    "Oku",
    "oku"
  ],
  [
    "Orokaiva",
    "okv"
  ],
  [
    "Okpe (Northwestern Edo)",
    "okx"
  ],
  [
    "Old Khmer",
    "okz"
  ],
  [
    "Walungge",
    "ola"
  ],
  [
    "Mochi",
    "old"
  ],
  [
    "Olekha",
    "ole"
  ],
  [
    "Olkol",
    "olk"
  ],
  [
    "Oloma",
    "olm"
  ],
  [
    "Livvi",
    "olo"
  ],
  [
    "Olrat",
    "olr"
  ],
  [
    "Old Lithuanian",
    "olt"
  ],
  [
    "Kuvale",
    "olu"
  ],
  [
    "Omaha-Ponca",
    "oma"
  ],
  [
    "East Ambae",
    "omb"
  ],
  [
    "Mochica",
    "omc"
  ],
  [
    "Omagua",
    "omg"
  ],
  [
    "Omi",
    "omi"
  ],
  [
    "Omok",
    "omk"
  ],
  [
    "Ombo",
    "oml"
  ],
  [
    "Minoan",
    "omn"
  ],
  [
    "Utarmbung",
    "omo"
  ],
  [
    "Old Manipuri",
    "omp"
  ],
  [
    "Old Marathi",
    "omr"
  ],
  [
    "Omotik",
    "omt"
  ],
  [
    "Omurano",
    "omu"
  ],
  [
    "South Tairora",
    "omw"
  ],
  [
    "Old Mon",
    "omx"
  ],
  [
    "Old Malay",
    "omy"
  ],
  [
    "Ona",
    "ona"
  ],
  [
    "Lingao",
    "onb"
  ],
  [
    "Oneida",
    "one"
  ],
  [
    "Olo",
    "ong"
  ],
  [
    "Onin",
    "oni"
  ],
  [
    "Onjob",
    "onj"
  ],
  [
    "Kabore One",
    "onk"
  ],
  [
    "Onobasulu",
    "onn"
  ],
  [
    "Onondaga",
    "ono"
  ],
  [
    "Sartang",
    "onp"
  ],
  [
    "Northern One",
    "onr"
  ],
  [
    "Ono",
    "ons"
  ],
  [
    "Ontenu",
    "ont"
  ],
  [
    "Unua",
    "onu"
  ],
  [
    "Old Nubian",
    "onw"
  ],
  [
    "Onin Based Pidgin",
    "onx"
  ],
  [
    "Tohono O'odham",
    "ood"
  ],
  [
    "Ong",
    "oog"
  ],
  [
    "Önge",
    "oon"
  ],
  [
    "Oorlams",
    "oor"
  ],
  [
    "Old Ossetic",
    "oos"
  ],
  [
    "Okpamheri",
    "opa"
  ],
  [
    "Kopkaka",
    "opk"
  ],
  [
    "Oksapmin",
    "opm"
  ],
  [
    "Opao",
    "opo"
  ],
  [
    "Opata",
    "opt"
  ],
  [
    "Ofayé",
    "opy"
  ],
  [
    "Oroha",
    "ora"
  ],
  [
    "Orma",
    "orc"
  ],
  [
    "Orejón",
    "ore"
  ],
  [
    "Oring",
    "org"
  ],
  [
    "Oroqen",
    "orh"
  ],
  [
    "Oriya (macrolanguage)",
    "ori",
    "ori",
    "ori",
    "or",
    "ଓଡ଼ିଆ"
  ],
  [
    "Oromo",
    "orm",
    "orm",
    "orm",
    "om",
    "Oromoo"
  ],
  [
    "Orang Kanaq",
    "orn"
  ],
  [
    "Orokolo",
    "oro"
  ],
  [
    "Oruma",
    "orr"
  ],
  [
    "Orang Seletar",
    "ors"
  ],
  [
    "Adivasi Oriya",
    "ort"
  ],
  [
    "Ormuri",
    "oru"
  ],
  [
    "Old Russian",
    "orv"
  ],
  [
    "Oro Win",
    "orw"
  ],
  [
    "Oro",
    "orx"
  ],
  [
    "Odia",
    "ory"
  ],
  [
    "Ormu",
    "orz"
  ],
  [
    "Osage",
    "osa",
    "osa",
    "osa"
  ],
  [
    "Oscan",
    "osc"
  ],
  [
    "Osing",
    "osi"
  ],
  [
    "Old Sundanese",
    "osn"
  ],
  [
    "Ososo",
    "oso"
  ],
  [
    "Old Spanish",
    "osp"
  ],
  [
    "Ossetian",
    "oss",
    "oss",
    "oss",
    "os",
    "Иронау"
  ],
  [
    "Osatu",
    "ost"
  ],
  [
    "Southern One",
    "osu"
  ],
  [
    "Old Saxon",
    "osx"
  ],
  [
    "Ottoman Turkish (1500-1928)",
    "ota",
    "ota",
    "ota"
  ],
  [
    "Old Tibetan",
    "otb"
  ],
  [
    "Ot Danum",
    "otd"
  ],
  [
    "Mezquital Otomi",
    "ote"
  ],
  [
    "Oti",
    "oti"
  ],
  [
    "Old Turkish",
    "otk"
  ],
  [
    "Tilapa Otomi",
    "otl"
  ],
  [
    "Eastern Highland Otomi",
    "otm"
  ],
  [
    "Tenango Otomi",
    "otn"
  ],
  [
    "Querétaro Otomi",
    "otq"
  ],
  [
    "Otoro",
    "otr"
  ],
  [
    "Estado de México Otomi",
    "ots"
  ],
  [
    "Temoaya Otomi",
    "ott"
  ],
  [
    "Otuke",
    "otu"
  ],
  [
    "Ottawa",
    "otw"
  ],
  [
    "Texcatepec Otomi",
    "otx"
  ],
  [
    "Old Tamil",
    "oty"
  ],
  [
    "Ixtenco Otomi",
    "otz"
  ],
  [
    "Tagargrent",
    "oua"
  ],
  [
    "Glio-Oubi",
    "oub"
  ],
  [
    "Oune",
    "oue"
  ],
  [
    "Old Uighur",
    "oui"
  ],
  [
    "Ouma",
    "oum"
  ],
  [
    "Elfdalian",
    "ovd"
  ],
  [
    "Owiniga",
    "owi"
  ],
  [
    "Old Welsh",
    "owl"
  ],
  [
    "Oy",
    "oyb"
  ],
  [
    "Oyda",
    "oyd"
  ],
  [
    "Wayampi",
    "oym"
  ],
  [
    "Oya'oya",
    "oyy"
  ],
  [
    "Koonzime",
    "ozm"
  ],
  [
    "Parecís",
    "pab"
  ],
  [
    "Pacoh",
    "pac"
  ],
  [
    "Paumarí",
    "pad"
  ],
  [
    "Pagibete",
    "pae"
  ],
  [
    "Paranawát",
    "paf"
  ],
  [
    "Pangasinan",
    "pag",
    "pag",
    "pag"
  ],
  [
    "Tenharim",
    "pah"
  ],
  [
    "Pe",
    "pai"
  ],
  [
    "Parakanã",
    "pak"
  ],
  [
    "Pahlavi",
    "pal",
    "pal",
    "pal"
  ],
  [
    "Pampanga",
    "pam",
    "pam",
    "pam"
  ],
  [
    "Panjabi",
    "pan",
    "pan",
    "pan",
    "pa",
    "ਪੰਜਾਬੀ / पंजाबी / پنجابي"
  ],
  [
    "Northern Paiute",
    "pao"
  ],
  [
    "Papiamento",
    "pap",
    "pap",
    "pap"
  ],
  [
    "Parya",
    "paq"
  ],
  [
    "Panamint",
    "par"
  ],
  [
    "Papasena",
    "pas"
  ],
  [
    "Palauan",
    "pau",
    "pau",
    "pau"
  ],
  [
    "Pakaásnovos",
    "pav"
  ],
  [
    "Pawnee",
    "paw"
  ],
  [
    "Pankararé",
    "pax"
  ],
  [
    "Pech",
    "pay"
  ],
  [
    "Pankararú",
    "paz"
  ],
  [
    "Páez",
    "pbb"
  ],
  [
    "Patamona",
    "pbc"
  ],
  [
    "Mezontla Popoloca",
    "pbe"
  ],
  [
    "Coyotepec Popoloca",
    "pbf"
  ],
  [
    "Paraujano",
    "pbg"
  ],
  [
    "E'ñapa Woromaipu",
    "pbh"
  ],
  [
    "Parkwa",
    "pbi"
  ],
  [
    "Mak (Nigeria)",
    "pbl"
  ],
  [
    "Puebla Mazatec",
    "pbm"
  ],
  [
    "Kpasam",
    "pbn"
  ],
  [
    "Papel",
    "pbo"
  ],
  [
    "Badyara",
    "pbp"
  ],
  [
    "Pangwa",
    "pbr"
  ],
  [
    "Central Pame",
    "pbs"
  ],
  [
    "Southern Pashto",
    "pbt"
  ],
  [
    "Northern Pashto",
    "pbu"
  ],
  [
    "Pnar",
    "pbv"
  ],
  [
    "Pyu (Papua New Guinea)",
    "pby"
  ],
  [
    "Santa Inés Ahuatempan Popoloca",
    "pca"
  ],
  [
    "Pear",
    "pcb"
  ],
  [
    "Bouyei",
    "pcc"
  ],
  [
    "Picard",
    "pcd"
  ],
  [
    "Ruching Palaung",
    "pce"
  ],
  [
    "Paliyan",
    "pcf"
  ],
  [
    "Paniya",
    "pcg"
  ],
  [
    "Pardhan",
    "pch"
  ],
  [
    "Duruwa",
    "pci"
  ],
  [
    "Parenga",
    "pcj"
  ],
  [
    "Paite Chin",
    "pck"
  ],
  [
    "Pardhi",
    "pcl"
  ],
  [
    "Nigerian Pidgin",
    "pcm"
  ],
  [
    "Piti",
    "pcn"
  ],
  [
    "Pacahuara",
    "pcp"
  ],
  [
    "Pyapun",
    "pcw"
  ],
  [
    "Anam",
    "pda"
  ],
  [
    "Pennsylvania German",
    "pdc"
  ],
  [
    "Pa Di",
    "pdi"
  ],
  [
    "Podena",
    "pdn"
  ],
  [
    "Padoe",
    "pdo"
  ],
  [
    "Plautdietsch",
    "pdt"
  ],
  [
    "Kayan",
    "pdu"
  ],
  [
    "Peranakan Indonesian",
    "pea"
  ],
  [
    "Eastern Pomo",
    "peb"
  ],
  [
    "Mala (Papua New Guinea)",
    "ped"
  ],
  [
    "Taje",
    "pee"
  ],
  [
    "Northeastern Pomo",
    "pef"
  ],
  [
    "Pengo",
    "peg"
  ],
  [
    "Bonan",
    "peh"
  ],
  [
    "Chichimeca-Jonaz",
    "pei"
  ],
  [
    "Northern Pomo",
    "pej"
  ],
  [
    "Penchal",
    "pek"
  ],
  [
    "Pekal",
    "pel"
  ],
  [
    "Phende",
    "pem"
  ],
  [
    "Old Persian (ca. 600-400 B.C.)",
    "peo",
    "peo",
    "peo"
  ],
  [
    "Kunja",
    "pep"
  ],
  [
    "Southern Pomo",
    "peq"
  ],
  [
    "Iranian Persian",
    "pes"
  ],
  [
    "Pémono",
    "pev"
  ],
  [
    "Petats",
    "pex"
  ],
  [
    "Petjo",
    "pey"
  ],
  [
    "Eastern Penan",
    "pez"
  ],
  [
    "Pááfang",
    "pfa"
  ],
  [
    "Pere",
    "pfe"
  ],
  [
    "Pfaelzisch",
    "pfl"
  ],
  [
    "Sudanese Creole Arabic",
    "pga"
  ],
  [
    "Gāndhārī",
    "pgd"
  ],
  [
    "Pangwali",
    "pgg"
  ],
  [
    "Pagi",
    "pgi"
  ],
  [
    "Rerep",
    "pgk"
  ],
  [
    "Primitive Irish",
    "pgl"
  ],
  [
    "Paelignian",
    "pgn"
  ],
  [
    "Pangseng",
    "pgs"
  ],
  [
    "Pagu",
    "pgu"
  ],
  [
    "Papua New Guinean Sign Language",
    "pgz"
  ],
  [
    "Pa-Hng",
    "pha"
  ],
  [
    "Phudagi",
    "phd"
  ],
  [
    "Phuong",
    "phg"
  ],
  [
    "Phukha",
    "phh"
  ],
  [
    "Pahari",
    "phj"
  ],
  [
    "Phake",
    "phk"
  ],
  [
    "Phalura",
    "phl"
  ],
  [
    "Phimbi",
    "phm"
  ],
  [
    "Phoenician",
    "phn",
    "phn",
    "phn"
  ],
  [
    "Phunoi",
    "pho"
  ],
  [
    "Phana'",
    "phq"
  ],
  [
    "Pahari-Potwari",
    "phr"
  ],
  [
    "Phu Thai",
    "pht"
  ],
  [
    "Phuan",
    "phu"
  ],
  [
    "Pahlavani",
    "phv"
  ],
  [
    "Phangduwali",
    "phw"
  ],
  [
    "Pima Bajo",
    "pia"
  ],
  [
    "Yine",
    "pib"
  ],
  [
    "Pinji",
    "pic"
  ],
  [
    "Piaroa",
    "pid"
  ],
  [
    "Piro",
    "pie"
  ],
  [
    "Pingelapese",
    "pif"
  ],
  [
    "Pisabo",
    "pig"
  ],
  [
    "Pitcairn-Norfolk",
    "pih"
  ],
  [
    "Pijao",
    "pij"
  ],
  [
    "Yom",
    "pil"
  ],
  [
    "Powhatan",
    "pim"
  ],
  [
    "Piame",
    "pin"
  ],
  [
    "Piapoco",
    "pio"
  ],
  [
    "Pero",
    "pip"
  ],
  [
    "Piratapuyo",
    "pir"
  ],
  [
    "Pijin",
    "pis"
  ],
  [
    "Pitta Pitta",
    "pit"
  ],
  [
    "Pintupi-Luritja",
    "piu"
  ],
  [
    "Pileni",
    "piv"
  ],
  [
    "Pimbwe",
    "piw"
  ],
  [
    "Piu",
    "pix"
  ],
  [
    "Piya-Kwonci",
    "piy"
  ],
  [
    "Pije",
    "piz"
  ],
  [
    "Pitjantjatjara",
    "pjt"
  ],
  [
    "Ardhamāgadhī Prākrit",
    "pka"
  ],
  [
    "Pokomo",
    "pkb"
  ],
  [
    "Paekche",
    "pkc"
  ],
  [
    "Pak-Tong",
    "pkg"
  ],
  [
    "Pankhu",
    "pkh"
  ],
  [
    "Pakanha",
    "pkn"
  ],
  [
    "Pökoot",
    "pko"
  ],
  [
    "Pukapuka",
    "pkp"
  ],
  [
    "Attapady Kurumba",
    "pkr"
  ],
  [
    "Pakistan Sign Language",
    "pks"
  ],
  [
    "Maleng",
    "pkt"
  ],
  [
    "Paku",
    "pku"
  ],
  [
    "Miani",
    "pla"
  ],
  [
    "Polonombauk",
    "plb"
  ],
  [
    "Central Palawano",
    "plc"
  ],
  [
    "Polari",
    "pld"
  ],
  [
    "Palu'e",
    "ple"
  ],
  [
    "Pilagá",
    "plg"
  ],
  [
    "Paulohi",
    "plh"
  ],
  [
    "Pali",
    "pli",
    "pli",
    "pli",
    "pi",
    "Pāli / पाऴि"
  ],
  [
    "Polci",
    "plj"
  ],
  [
    "Kohistani Shina",
    "plk"
  ],
  [
    "Shwe Palaung",
    "pll"
  ],
  [
    "Palenquero",
    "pln"
  ],
  [
    "Oluta Popoluca",
    "plo"
  ],
  [
    "Palaic",
    "plq"
  ],
  [
    "Palaka Senoufo",
    "plr"
  ],
  [
    "San Marcos Tlacoyalco Popoloca",
    "pls"
  ],
  [
    "Plateau Malagasy",
    "plt"
  ],
  [
    "Palikúr",
    "plu"
  ],
  [
    "Southwest Palawano",
    "plv"
  ],
  [
    "Brooke's Point Palawano",
    "plw"
  ],
  [
    "Bolyu",
    "ply"
  ],
  [
    "Paluan",
    "plz"
  ],
  [
    "Paama",
    "pma"
  ],
  [
    "Pambia",
    "pmb"
  ],
  [
    "Pallanganmiddang",
    "pmd"
  ],
  [
    "Pwaamei",
    "pme"
  ],
  [
    "Pamona",
    "pmf"
  ],
  [
    "Māhārāṣṭri Prākrit",
    "pmh"
  ],
  [
    "Northern Pumi",
    "pmi"
  ],
  [
    "Southern Pumi",
    "pmj"
  ],
  [
    "Pamlico",
    "pmk"
  ],
  [
    "Lingua Franca",
    "pml"
  ],
  [
    "Pomo",
    "pmm"
  ],
  [
    "Pam",
    "pmn"
  ],
  [
    "Pom",
    "pmo"
  ],
  [
    "Northern Pame",
    "pmq"
  ],
  [
    "Paynamar",
    "pmr"
  ],
  [
    "Piemontese",
    "pms"
  ],
  [
    "Tuamotuan",
    "pmt"
  ],
  [
    "Plains Miwok",
    "pmw"
  ],
  [
    "Poumei Naga",
    "pmx"
  ],
  [
    "Papuan Malay",
    "pmy"
  ],
  [
    "Southern Pame",
    "pmz"
  ],
  [
    "Punan Bah-Biau",
    "pna"
  ],
  [
    "Western Panjabi",
    "pnb"
  ],
  [
    "Pannei",
    "pnc"
  ],
  [
    "Mpinda",
    "pnd"
  ],
  [
    "Western Penan",
    "pne"
  ],
  [
    "Pangu",
    "png"
  ],
  [
    "Penrhyn",
    "pnh"
  ],
  [
    "Aoheng",
    "pni"
  ],
  [
    "Pinjarup",
    "pnj"
  ],
  [
    "Paunaka",
    "pnk"
  ],
  [
    "Paleni",
    "pnl"
  ],
  [
    "Punan Batu 1",
    "pnm"
  ],
  [
    "Pinai-Hagahai",
    "pnn"
  ],
  [
    "Panobo",
    "pno"
  ],
  [
    "Pancana",
    "pnp"
  ],
  [
    "Pana (Burkina Faso)",
    "pnq"
  ],
  [
    "Panim",
    "pnr"
  ],
  [
    "Ponosakan",
    "pns"
  ],
  [
    "Pontic",
    "pnt"
  ],
  [
    "Jiongnai Bunu",
    "pnu"
  ],
  [
    "Pinigura",
    "pnv"
  ],
  [
    "Banyjima",
    "pnw"
  ],
  [
    "Phong-Kniang",
    "pnx"
  ],
  [
    "Pinyin",
    "pny"
  ],
  [
    "Pana (Central African Republic)",
    "pnz"
  ],
  [
    "Poqomam",
    "poc"
  ],
  [
    "San Juan Atzingo Popoloca",
    "poe"
  ],
  [
    "Poke",
    "pof"
  ],
  [
    "Potiguára",
    "pog"
  ],
  [
    "Poqomchi'",
    "poh"
  ],
  [
    "Highland Popoluca",
    "poi"
  ],
  [
    "Pokangá",
    "pok"
  ],
  [
    "Polish",
    "pol",
    "pol",
    "pol",
    "pl",
    "Polski"
  ],
  [
    "Southeastern Pomo",
    "pom"
  ],
  [
    "Pohnpeian",
    "pon",
    "pon",
    "pon"
  ],
  [
    "Central Pomo",
    "poo"
  ],
  [
    "Pwapwâ",
    "pop"
  ],
  [
    "Texistepec Popoluca",
    "poq"
  ],
  [
    "Portuguese",
    "por",
    "por",
    "por",
    "pt",
    "Português"
  ],
  [
    "Sayula Popoluca",
    "pos"
  ],
  [
    "Potawatomi",
    "pot"
  ],
  [
    "Upper Guinea Crioulo",
    "pov"
  ],
  [
    "San Felipe Otlaltepec Popoloca",
    "pow"
  ],
  [
    "Polabian",
    "pox"
  ],
  [
    "Pogolo",
    "poy"
  ],
  [
    "Papi",
    "ppe"
  ],
  [
    "Paipai",
    "ppi"
  ],
  [
    "Uma",
    "ppk"
  ],
  [
    "Pipil",
    "ppl"
  ],
  [
    "Papuma",
    "ppm"
  ],
  [
    "Papapana",
    "ppn"
  ],
  [
    "Folopa",
    "ppo"
  ],
  [
    "Pelende",
    "ppp"
  ],
  [
    "Pei",
    "ppq"
  ],
  [
    "San Luís Temalacayuca Popoloca",
    "pps"
  ],
  [
    "Pare",
    "ppt"
  ],
  [
    "Papora",
    "ppu"
  ],
  [
    "Pa'a",
    "pqa"
  ],
  [
    "Malecite-Passamaquoddy",
    "pqm"
  ],
  [
    "Parachi",
    "prc"
  ],
  [
    "Parsi-Dari",
    "prd"
  ],
  [
    "Principense",
    "pre"
  ],
  [
    "Paranan",
    "prf"
  ],
  [
    "Prussian",
    "prg"
  ],
  [
    "Porohanon",
    "prh"
  ],
  [
    "Paicî",
    "pri"
  ],
  [
    "Parauk",
    "prk"
  ],
  [
    "Peruvian Sign Language",
    "prl"
  ],
  [
    "Kibiri",
    "prm"
  ],
  [
    "Prasuni",
    "prn"
  ],
  [
    "Old Provençal (to 1500)",
    "pro",
    "pro",
    "pro"
  ],
  [
    "Parsi",
    "prp"
  ],
  [
    "Ashéninka Perené",
    "prq"
  ],
  [
    "Puri",
    "prr"
  ],
  [
    "Dari",
    "prs"
  ],
  [
    "Phai",
    "prt"
  ],
  [
    "Puragi",
    "pru"
  ],
  [
    "Parawen",
    "prw"
  ],
  [
    "Purik",
    "prx"
  ],
  [
    "Providencia Sign Language",
    "prz"
  ],
  [
    "Asue Awyu",
    "psa"
  ],
  [
    "Iranian Sign Language",
    "psc"
  ],
  [
    "Plains Indian Sign Language",
    "psd"
  ],
  [
    "Central Malay",
    "pse"
  ],
  [
    "Penang Sign Language",
    "psg"
  ],
  [
    "Southwest Pashai",
    "psh"
  ],
  [
    "Southeast Pashai",
    "psi"
  ],
  [
    "Puerto Rican Sign Language",
    "psl"
  ],
  [
    "Pauserna",
    "psm"
  ],
  [
    "Panasuan",
    "psn"
  ],
  [
    "Polish Sign Language",
    "pso"
  ],
  [
    "Philippine Sign Language",
    "psp"
  ],
  [
    "Pasi",
    "psq"
  ],
  [
    "Portuguese Sign Language",
    "psr"
  ],
  [
    "Kaulong",
    "pss"
  ],
  [
    "Central Pashto",
    "pst"
  ],
  [
    "Sauraseni Prākrit",
    "psu"
  ],
  [
    "Port Sandwich",
    "psw"
  ],
  [
    "Piscataway",
    "psy"
  ],
  [
    "Pai Tavytera",
    "pta"
  ],
  [
    "Pataxó Hã-Ha-Hãe",
    "pth"
  ],
  [
    "Pindiini",
    "pti"
  ],
  [
    "Patani",
    "ptn"
  ],
  [
    "Zo'é",
    "pto"
  ],
  [
    "Patep",
    "ptp"
  ],
  [
    "Pattapu",
    "ptq"
  ],
  [
    "Piamatsina",
    "ptr"
  ],
  [
    "Enrekang",
    "ptt"
  ],
  [
    "Bambam",
    "ptu"
  ],
  [
    "Port Vato",
    "ptv"
  ],
  [
    "Pentlatch",
    "ptw"
  ],
  [
    "Pathiya",
    "pty"
  ],
  [
    "Western Highland Purepecha",
    "pua"
  ],
  [
    "Purum",
    "pub"
  ],
  [
    "Punan Merap",
    "puc"
  ],
  [
    "Punan Aput",
    "pud"
  ],
  [
    "Puelche",
    "pue"
  ],
  [
    "Punan Merah",
    "puf"
  ],
  [
    "Phuie",
    "pug"
  ],
  [
    "Puinave",
    "pui"
  ],
  [
    "Punan Tubu",
    "puj"
  ],
  [
    "Puma",
    "pum"
  ],
  [
    "Puoc",
    "puo"
  ],
  [
    "Pulabu",
    "pup"
  ],
  [
    "Puquina",
    "puq"
  ],
  [
    "Puruborá",
    "pur"
  ],
  [
    "Pushto",
    "pus",
    "pus",
    "pus",
    "ps",
    "پښتو"
  ],
  [
    "Putoh",
    "put"
  ],
  [
    "Punu",
    "puu"
  ],
  [
    "Puluwatese",
    "puw"
  ],
  [
    "Puare",
    "pux"
  ],
  [
    "Purisimeño",
    "puy"
  ],
  [
    "Pawaia",
    "pwa"
  ],
  [
    "Panawa",
    "pwb"
  ],
  [
    "Gapapaiwa",
    "pwg"
  ],
  [
    "Patwin",
    "pwi"
  ],
  [
    "Molbog",
    "pwm"
  ],
  [
    "Paiwan",
    "pwn"
  ],
  [
    "Pwo Western Karen",
    "pwo"
  ],
  [
    "Powari",
    "pwr"
  ],
  [
    "Pwo Northern Karen",
    "pww"
  ],
  [
    "Quetzaltepec Mixe",
    "pxm"
  ],
  [
    "Pye Krumen",
    "pye"
  ],
  [
    "Fyam",
    "pym"
  ],
  [
    "Poyanáwa",
    "pyn"
  ],
  [
    "Paraguayan Sign Language",
    "pys"
  ],
  [
    "Puyuma",
    "pyu"
  ],
  [
    "Pyu (Myanmar)",
    "pyx"
  ],
  [
    "Pyen",
    "pyy"
  ],
  [
    "Pazeh",
    "pzh"
  ],
  [
    "Jejara Naga",
    "pzn"
  ],
  [
    "Quapaw",
    "qua"
  ],
  [
    "Huallaga Huánuco Quechua",
    "qub"
  ],
  [
    "K'iche'",
    "quc"
  ],
  [
    "Calderón Highland Quichua",
    "qud"
  ],
  [
    "Quechua",
    "que",
    "que",
    "que",
    "qu",
    "Runa Simi"
  ],
  [
    "Lambayeque Quechua",
    "quf"
  ],
  [
    "Chimborazo Highland Quichua",
    "qug"
  ],
  [
    "South Bolivian Quechua",
    "quh"
  ],
  [
    "Quileute",
    "qui"
  ],
  [
    "Chachapoyas Quechua",
    "quk"
  ],
  [
    "North Bolivian Quechua",
    "qul"
  ],
  [
    "Sipacapense",
    "qum"
  ],
  [
    "Quinault",
    "qun"
  ],
  [
    "Southern Pastaza Quechua",
    "qup"
  ],
  [
    "Quinqui",
    "quq"
  ],
  [
    "Yanahuanca Pasco Quechua",
    "qur"
  ],
  [
    "Santiago del Estero Quichua",
    "qus"
  ],
  [
    "Sacapulteco",
    "quv"
  ],
  [
    "Tena Lowland Quichua",
    "quw"
  ],
  [
    "Yauyos Quechua",
    "qux"
  ],
  [
    "Ayacucho Quechua",
    "quy"
  ],
  [
    "Cusco Quechua",
    "quz"
  ],
  [
    "Ambo-Pasco Quechua",
    "qva"
  ],
  [
    "Cajamarca Quechua",
    "qvc"
  ],
  [
    "Eastern Apurímac Quechua",
    "qve"
  ],
  [
    "Huamalíes-Dos de Mayo Huánuco Quechua",
    "qvh"
  ],
  [
    "Imbabura Highland Quichua",
    "qvi"
  ],
  [
    "Loja Highland Quichua",
    "qvj"
  ],
  [
    "Cajatambo North Lima Quechua",
    "qvl"
  ],
  [
    "Margos-Yarowilca-Lauricocha Quechua",
    "qvm"
  ],
  [
    "North Junín Quechua",
    "qvn"
  ],
  [
    "Napo Lowland Quechua",
    "qvo"
  ],
  [
    "Pacaraos Quechua",
    "qvp"
  ],
  [
    "San Martín Quechua",
    "qvs"
  ],
  [
    "Huaylla Wanca Quechua",
    "qvw"
  ],
  [
    "Queyu",
    "qvy"
  ],
  [
    "Northern Pastaza Quichua",
    "qvz"
  ],
  [
    "Corongo Ancash Quechua",
    "qwa"
  ],
  [
    "Classical Quechua",
    "qwc"
  ],
  [
    "Huaylas Ancash Quechua",
    "qwh"
  ],
  [
    "Kuman (Russia)",
    "qwm"
  ],
  [
    "Sihuas Ancash Quechua",
    "qws"
  ],
  [
    "Kwalhioqua-Tlatskanai",
    "qwt"
  ],
  [
    "Chiquián Ancash Quechua",
    "qxa"
  ],
  [
    "Chincha Quechua",
    "qxc"
  ],
  [
    "Panao Huánuco Quechua",
    "qxh"
  ],
  [
    "Salasaca Highland Quichua",
    "qxl"
  ],
  [
    "Northern Conchucos Ancash Quechua",
    "qxn"
  ],
  [
    "Southern Conchucos Ancash Quechua",
    "qxo"
  ],
  [
    "Puno Quechua",
    "qxp"
  ],
  [
    "Qashqa'i",
    "qxq"
  ],
  [
    "Cañar Highland Quichua",
    "qxr"
  ],
  [
    "Southern Qiang",
    "qxs"
  ],
  [
    "Santa Ana de Tusi Pasco Quechua",
    "qxt"
  ],
  [
    "Arequipa-La Unión Quechua",
    "qxu"
  ],
  [
    "Jauja Wanca Quechua",
    "qxw"
  ],
  [
    "Quenya",
    "qya"
  ],
  [
    "Quiripi",
    "qyp"
  ],
  [
    "Dungmali",
    "raa"
  ],
  [
    "Camling",
    "rab"
  ],
  [
    "Rasawa",
    "rac"
  ],
  [
    "Rade",
    "rad"
  ],
  [
    "Western Meohang",
    "raf"
  ],
  [
    "Logooli",
    "rag"
  ],
  [
    "Rabha",
    "rah"
  ],
  [
    "Ramoaaina",
    "rai"
  ],
  [
    "Rajasthani",
    "raj",
    "raj",
    "raj"
  ],
  [
    "Tulu-Bohuai",
    "rak"
  ],
  [
    "Ralte",
    "ral"
  ],
  [
    "Canela",
    "ram"
  ],
  [
    "Riantana",
    "ran"
  ],
  [
    "Rao",
    "rao"
  ],
  [
    "Rapanui",
    "rap",
    "rap",
    "rap"
  ],
  [
    "Saam",
    "raq"
  ],
  [
    "Rarotongan",
    "rar",
    "rar",
    "rar"
  ],
  [
    "Tegali",
    "ras"
  ],
  [
    "Razajerdi",
    "rat"
  ],
  [
    "Raute",
    "rau"
  ],
  [
    "Sampang",
    "rav"
  ],
  [
    "Rawang",
    "raw"
  ],
  [
    "Rang",
    "rax"
  ],
  [
    "Rapa",
    "ray"
  ],
  [
    "Rahambuu",
    "raz"
  ],
  [
    "Rumai Palaung",
    "rbb"
  ],
  [
    "Northern Bontok",
    "rbk"
  ],
  [
    "Miraya Bikol",
    "rbl"
  ],
  [
    "Barababaraba",
    "rbp"
  ],
  [
    "Réunion Creole French",
    "rcf"
  ],
  [
    "Rudbari",
    "rdb"
  ],
  [
    "Rerau",
    "rea"
  ],
  [
    "Rembong",
    "reb"
  ],
  [
    "Rejang Kayan",
    "ree"
  ],
  [
    "Kara (Tanzania)",
    "reg"
  ],
  [
    "Reli",
    "rei"
  ],
  [
    "Rejang",
    "rej"
  ],
  [
    "Rendille",
    "rel"
  ],
  [
    "Remo",
    "rem"
  ],
  [
    "Rengao",
    "ren"
  ],
  [
    "Rer Bare",
    "rer"
  ],
  [
    "Reshe",
    "res"
  ],
  [
    "Retta",
    "ret"
  ],
  [
    "Reyesano",
    "rey"
  ],
  [
    "Roria",
    "rga"
  ],
  [
    "Romano-Greek",
    "rge"
  ],
  [
    "Rangkas",
    "rgk"
  ],
  [
    "Romagnol",
    "rgn"
  ],
  [
    "Resígaro",
    "rgr"
  ],
  [
    "Southern Roglai",
    "rgs"
  ],
  [
    "Ringgou",
    "rgu"
  ],
  [
    "Rohingya",
    "rhg"
  ],
  [
    "Yahang",
    "rhp"
  ],
  [
    "Riang (India)",
    "ria"
  ],
  [
    "Bribri Sign Language",
    "rib"
  ],
  [
    "Tarifit",
    "rif"
  ],
  [
    "Riang Lang",
    "ril"
  ],
  [
    "Nyaturu",
    "rim"
  ],
  [
    "Nungu",
    "rin"
  ],
  [
    "Ribun",
    "rir"
  ],
  [
    "Ritharrngu",
    "rit"
  ],
  [
    "Riung",
    "riu"
  ],
  [
    "Rajong",
    "rjg"
  ],
  [
    "Raji",
    "rji"
  ],
  [
    "Rajbanshi",
    "rjs"
  ],
  [
    "Kraol",
    "rka"
  ],
  [
    "Rikbaktsa",
    "rkb"
  ],
  [
    "Rakahanga-Manihiki",
    "rkh"
  ],
  [
    "Rakhine",
    "rki"
  ],
  [
    "Marka",
    "rkm"
  ],
  [
    "Rangpuri",
    "rkt"
  ],
  [
    "Arakwal",
    "rkw"
  ],
  [
    "Rama",
    "rma"
  ],
  [
    "Rembarrnga",
    "rmb"
  ],
  [
    "Carpathian Romani",
    "rmc"
  ],
  [
    "Traveller Danish",
    "rmd"
  ],
  [
    "Angloromani",
    "rme"
  ],
  [
    "Kalo Finnish Romani",
    "rmf"
  ],
  [
    "Traveller Norwegian",
    "rmg"
  ],
  [
    "Murkim",
    "rmh"
  ],
  [
    "Lomavren",
    "rmi"
  ],
  [
    "Romkun",
    "rmk"
  ],
  [
    "Baltic Romani",
    "rml"
  ],
  [
    "Roma",
    "rmm"
  ],
  [
    "Balkan Romani",
    "rmn"
  ],
  [
    "Sinte Romani",
    "rmo"
  ],
  [
    "Rempi",
    "rmp"
  ],
  [
    "Caló",
    "rmq"
  ],
  [
    "Romanian Sign Language",
    "rms"
  ],
  [
    "Domari",
    "rmt"
  ],
  [
    "Tavringer Romani",
    "rmu"
  ],
  [
    "Romanova",
    "rmv"
  ],
  [
    "Welsh Romani",
    "rmw"
  ],
  [
    "Romam",
    "rmx"
  ],
  [
    "Vlax Romani",
    "rmy"
  ],
  [
    "Marma",
    "rmz"
  ],
  [
    "Brunca Sign Language",
    "rnb"
  ],
  [
    "Ruund",
    "rnd"
  ],
  [
    "Ronga",
    "rng"
  ],
  [
    "Ranglong",
    "rnl"
  ],
  [
    "Roon",
    "rnn"
  ],
  [
    "Rongpo",
    "rnp"
  ],
  [
    "Nari Nari",
    "rnr"
  ],
  [
    "Rungwa",
    "rnw"
  ],
  [
    "Tae'",
    "rob"
  ],
  [
    "Cacgia Roglai",
    "roc"
  ],
  [
    "Rogo",
    "rod"
  ],
  [
    "Ronji",
    "roe"
  ],
  [
    "Rombo",
    "rof"
  ],
  [
    "Northern Roglai",
    "rog"
  ],
  [
    "Romansh",
    "roh",
    "roh",
    "roh",
    "rm",
    "Rumantsch"
  ],
  [
    "Romblomanon",
    "rol"
  ],
  [
    "Romany",
    "rom",
    "rom",
    "rom"
  ],
  [
    "Romanian",
    "ron",
    "rum",
    "ron",
    "ro",
    "Română"
  ],
  [
    "Rotokas",
    "roo"
  ],
  [
    "Kriol",
    "rop"
  ],
  [
    "Rongga",
    "ror"
  ],
  [
    "Runga",
    "rou"
  ],
  [
    "Dela-Oenale",
    "row"
  ],
  [
    "Repanbitip",
    "rpn"
  ],
  [
    "Rapting",
    "rpt"
  ],
  [
    "Ririo",
    "rri"
  ],
  [
    "Waima",
    "rro"
  ],
  [
    "Arritinngithigh",
    "rrt"
  ],
  [
    "Romano-Serbian",
    "rsb"
  ],
  [
    "Ruthenian",
    "rsk"
  ],
  [
    "Russian Sign Language",
    "rsl"
  ],
  [
    "Miriwoong Sign Language",
    "rsm"
  ],
  [
    "Rwandan Sign Language",
    "rsn"
  ],
  [
    "Rungtu Chin",
    "rtc"
  ],
  [
    "Ratahan",
    "rth"
  ],
  [
    "Rotuman",
    "rtm"
  ],
  [
    "Yurats",
    "rts"
  ],
  [
    "Rathawi",
    "rtw"
  ],
  [
    "Gungu",
    "rub"
  ],
  [
    "Ruuli",
    "ruc"
  ],
  [
    "Rusyn",
    "rue"
  ],
  [
    "Luguru",
    "ruf"
  ],
  [
    "Roviana",
    "rug"
  ],
  [
    "Ruga",
    "ruh"
  ],
  [
    "Rufiji",
    "rui"
  ],
  [
    "Che",
    "ruk"
  ],
  [
    "Rundi",
    "run",
    "run",
    "run",
    "rn",
    "Kirundi"
  ],
  [
    "Istro Romanian",
    "ruo"
  ],
  [
    "Macedo-Romanian",
    "rup",
    "rup",
    "rup"
  ],
  [
    "Megleno Romanian",
    "ruq"
  ],
  [
    "Russian",
    "rus",
    "rus",
    "rus",
    "ru",
    "Русский"
  ],
  [
    "Rutul",
    "rut"
  ],
  [
    "Lanas Lobu",
    "ruu"
  ],
  [
    "Mala (Nigeria)",
    "ruy"
  ],
  [
    "Ruma",
    "ruz"
  ],
  [
    "Rawo",
    "rwa"
  ],
  [
    "Rwa",
    "rwk"
  ],
  [
    "Ruwila",
    "rwl"
  ],
  [
    "Amba (Uganda)",
    "rwm"
  ],
  [
    "Rawa",
    "rwo"
  ],
  [
    "Marwari (India)",
    "rwr"
  ],
  [
    "Ngardi",
    "rxd"
  ],
  [
    "Karuwali",
    "rxw"
  ],
  [
    "Northern Amami-Oshima",
    "ryn"
  ],
  [
    "Yaeyama",
    "rys"
  ],
  [
    "Central Okinawan",
    "ryu"
  ],
  [
    "Rāziḥī",
    "rzh"
  ],
  [
    "Saba",
    "saa"
  ],
  [
    "Buglere",
    "sab"
  ],
  [
    "Meskwaki",
    "sac"
  ],
  [
    "Sandawe",
    "sad",
    "sad",
    "sad"
  ],
  [
    "Sabanê",
    "sae"
  ],
  [
    "Safaliba",
    "saf"
  ],
  [
    "Sango",
    "sag",
    "sag",
    "sag",
    "sg",
    "Sängö"
  ],
  [
    "Yakut",
    "sah",
    "sah",
    "sah"
  ],
  [
    "Sahu",
    "saj"
  ],
  [
    "Sake",
    "sak"
  ],
  [
    "Samaritan Aramaic",
    "sam",
    "sam",
    "sam"
  ],
  [
    "Sanskrit",
    "san",
    "san",
    "san",
    "sa",
    "संस्कृतम्"
  ],
  [
    "Sause",
    "sao"
  ],
  [
    "Samburu",
    "saq"
  ],
  [
    "Saraveca",
    "sar"
  ],
  [
    "Sasak",
    "sas",
    "sas",
    "sas"
  ],
  [
    "Santali",
    "sat",
    "sat",
    "sat"
  ],
  [
    "Saleman",
    "sau"
  ],
  [
    "Saafi-Saafi",
    "sav"
  ],
  [
    "Sawi",
    "saw"
  ],
  [
    "Sa",
    "sax"
  ],
  [
    "Saya",
    "say"
  ],
  [
    "Saurashtra",
    "saz"
  ],
  [
    "Ngambay",
    "sba"
  ],
  [
    "Simbo",
    "sbb"
  ],
  [
    "Kele (Papua New Guinea)",
    "sbc"
  ],
  [
    "Southern Samo",
    "sbd"
  ],
  [
    "Saliba",
    "sbe"
  ],
  [
    "Chabu",
    "sbf"
  ],
  [
    "Seget",
    "sbg"
  ],
  [
    "Sori-Harengan",
    "sbh"
  ],
  [
    "Seti",
    "sbi"
  ],
  [
    "Surbakhal",
    "sbj"
  ],
  [
    "Safwa",
    "sbk"
  ],
  [
    "Botolan Sambal",
    "sbl"
  ],
  [
    "Sagala",
    "sbm"
  ],
  [
    "Sindhi Bhil",
    "sbn"
  ],
  [
    "Sabüm",
    "sbo"
  ],
  [
    "Sangu (Tanzania)",
    "sbp"
  ],
  [
    "Sileibi",
    "sbq"
  ],
  [
    "Sembakung Murut",
    "sbr"
  ],
  [
    "Subiya",
    "sbs"
  ],
  [
    "Kimki",
    "sbt"
  ],
  [
    "Stod Bhoti",
    "sbu"
  ],
  [
    "Sabine",
    "sbv"
  ],
  [
    "Simba",
    "sbw"
  ],
  [
    "Seberuang",
    "sbx"
  ],
  [
    "Soli",
    "sby"
  ],
  [
    "Sara Kaba",
    "sbz"
  ],
  [
    "Chut",
    "scb"
  ],
  [
    "Dongxiang",
    "sce"
  ],
  [
    "San Miguel Creole French",
    "scf"
  ],
  [
    "Sanggau",
    "scg"
  ],
  [
    "Sakachep",
    "sch"
  ],
  [
    "Sri Lankan Creole Malay",
    "sci"
  ],
  [
    "Sadri",
    "sck"
  ],
  [
    "Shina",
    "scl"
  ],
  [
    "Sicilian",
    "scn",
    "scn",
    "scn"
  ],
  [
    "Scots",
    "sco",
    "sco",
    "sco"
  ],
  [
    "Hyolmo",
    "scp"
  ],
  [
    "Sa'och",
    "scq"
  ],
  [
    "North Slavey",
    "scs"
  ],
  [
    "Southern Katang",
    "sct"
  ],
  [
    "Shumcho",
    "scu"
  ],
  [
    "Sheni",
    "scv"
  ],
  [
    "Sha",
    "scw"
  ],
  [
    "Sicel",
    "scx"
  ],
  [
    "Toraja-Sa'dan",
    "sda"
  ],
  [
    "Shabak",
    "sdb"
  ],
  [
    "Sassarese Sardinian",
    "sdc"
  ],
  [
    "Surubu",
    "sde"
  ],
  [
    "Sarli",
    "sdf"
  ],
  [
    "Savi",
    "sdg"
  ],
  [
    "Southern Kurdish",
    "sdh"
  ],
  [
    "Suundi",
    "sdj"
  ],
  [
    "Sos Kundi",
    "sdk"
  ],
  [
    "Saudi Arabian Sign Language",
    "sdl"
  ],
  [
    "Gallurese Sardinian",
    "sdn"
  ],
  [
    "Bukar-Sadung Bidayuh",
    "sdo"
  ],
  [
    "Sherdukpen",
    "sdp"
  ],
  [
    "Semandang",
    "sdq"
  ],
  [
    "Oraon Sadri",
    "sdr"
  ],
  [
    "Sened",
    "sds"
  ],
  [
    "Shuadit",
    "sdt"
  ],
  [
    "Sarudu",
    "sdu"
  ],
  [
    "Sibu Melanau",
    "sdx"
  ],
  [
    "Sallands",
    "sdz"
  ],
  [
    "Semai",
    "sea"
  ],
  [
    "Shempire Senoufo",
    "seb"
  ],
  [
    "Sechelt",
    "sec"
  ],
  [
    "Sedang",
    "sed"
  ],
  [
    "Seneca",
    "see"
  ],
  [
    "Cebaara Senoufo",
    "sef"
  ],
  [
    "Segeju",
    "seg"
  ],
  [
    "Sena",
    "seh"
  ],
  [
    "Seri",
    "sei"
  ],
  [
    "Sene",
    "sej"
  ],
  [
    "Sekani",
    "sek"
  ],
  [
    "Selkup",
    "sel",
    "sel",
    "sel"
  ],
  [
    "Nanerigé Sénoufo",
    "sen"
  ],
  [
    "Suarmin",
    "seo"
  ],
  [
    "Sìcìté Sénoufo",
    "sep"
  ],
  [
    "Senara Sénoufo",
    "seq"
  ],
  [
    "Serrano",
    "ser"
  ],
  [
    "Koyraboro Senni Songhai",
    "ses"
  ],
  [
    "Sentani",
    "set"
  ],
  [
    "Serui-Laut",
    "seu"
  ],
  [
    "Nyarafolo Senoufo",
    "sev"
  ],
  [
    "Sewa Bay",
    "sew"
  ],
  [
    "Secoya",
    "sey"
  ],
  [
    "Senthang Chin",
    "sez"
  ],
  [
    "Langue des signes de Belgique Francophone",
    "sfb"
  ],
  [
    "Eastern Subanen",
    "sfe"
  ],
  [
    "Small Flowery Miao",
    "sfm"
  ],
  [
    "South African Sign Language",
    "sfs"
  ],
  [
    "Sehwi",
    "sfw"
  ],
  [
    "Old Irish (to 900)",
    "sga",
    "sga",
    "sga"
  ],
  [
    "Mag-antsi Ayta",
    "sgb"
  ],
  [
    "Kipsigis",
    "sgc"
  ],
  [
    "Surigaonon",
    "sgd"
  ],
  [
    "Segai",
    "sge"
  ],
  [
    "Swiss-German Sign Language",
    "sgg"
  ],
  [
    "Shughni",
    "sgh"
  ],
  [
    "Suga",
    "sgi"
  ],
  [
    "Surgujia",
    "sgj"
  ],
  [
    "Sangkong",
    "sgk"
  ],
  [
    "Singa",
    "sgm"
  ],
  [
    "Singpho",
    "sgp"
  ],
  [
    "Sangisari",
    "sgr"
  ],
  [
    "Samogitian",
    "sgs"
  ],
  [
    "Brokpake",
    "sgt"
  ],
  [
    "Salas",
    "sgu"
  ],
  [
    "Sebat Bet Gurage",
    "sgw"
  ],
  [
    "Sierra Leone Sign Language",
    "sgx"
  ],
  [
    "Sanglechi",
    "sgy"
  ],
  [
    "Sursurunga",
    "sgz"
  ],
  [
    "Shall-Zwall",
    "sha"
  ],
  [
    "Ninam",
    "shb"
  ],
  [
    "Sonde",
    "shc"
  ],
  [
    "Kundal Shahi",
    "shd"
  ],
  [
    "Sheko",
    "she"
  ],
  [
    "Shua",
    "shg"
  ],
  [
    "Shoshoni",
    "shh"
  ],
  [
    "Tachelhit",
    "shi"
  ],
  [
    "Shatt",
    "shj"
  ],
  [
    "Shilluk",
    "shk"
  ],
  [
    "Shendu",
    "shl"
  ],
  [
    "Shahrudi",
    "shm"
  ],
  [
    "Shan",
    "shn",
    "shn",
    "shn"
  ],
  [
    "Shanga",
    "sho"
  ],
  [
    "Shipibo-Conibo",
    "shp"
  ],
  [
    "Sala",
    "shq"
  ],
  [
    "Shi",
    "shr"
  ],
  [
    "Shuswap",
    "shs"
  ],
  [
    "Shasta",
    "sht"
  ],
  [
    "Chadian Arabic",
    "shu"
  ],
  [
    "Shehri",
    "shv"
  ],
  [
    "Shwai",
    "shw"
  ],
  [
    "She",
    "shx"
  ],
  [
    "Tachawit",
    "shy"
  ],
  [
    "Syenara Senoufo",
    "shz"
  ],
  [
    "Akkala Sami",
    "sia"
  ],
  [
    "Sebop",
    "sib"
  ],
  [
    "Sidamo",
    "sid",
    "sid",
    "sid"
  ],
  [
    "Simaa",
    "sie"
  ],
  [
    "Siamou",
    "sif"
  ],
  [
    "Paasaal",
    "sig"
  ],
  [
    "Zire",
    "sih"
  ],
  [
    "Shom Peng",
    "sii"
  ],
  [
    "Numbami",
    "sij"
  ],
  [
    "Sikiana",
    "sik"
  ],
  [
    "Tumulung Sisaala",
    "sil"
  ],
  [
    "Mende (Papua New Guinea)",
    "sim"
  ],
  [
    "Sinhala",
    "sin",
    "sin",
    "sin",
    "si",
    "සිංහල"
  ],
  [
    "Sikkimese",
    "sip"
  ],
  [
    "Sonia",
    "siq"
  ],
  [
    "Siri",
    "sir"
  ],
  [
    "Siuslaw",
    "sis"
  ],
  [
    "Sinagen",
    "siu"
  ],
  [
    "Sumariup",
    "siv"
  ],
  [
    "Siwai",
    "siw"
  ],
  [
    "Sumau",
    "six"
  ],
  [
    "Sivandi",
    "siy"
  ],
  [
    "Siwi",
    "siz"
  ],
  [
    "Epena",
    "sja"
  ],
  [
    "Sajau Basap",
    "sjb"
  ],
  [
    "Kildin Sami",
    "sjd"
  ],
  [
    "Pite Sami",
    "sje"
  ],
  [
    "Assangori",
    "sjg"
  ],
  [
    "Kemi Sami",
    "sjk"
  ],
  [
    "Sajalong",
    "sjl"
  ],
  [
    "Mapun",
    "sjm"
  ],
  [
    "Sindarin",
    "sjn"
  ],
  [
    "Xibe",
    "sjo"
  ],
  [
    "Surjapuri",
    "sjp"
  ],
  [
    "Siar-Lak",
    "sjr"
  ],
  [
    "Senhaja De Srair",
    "sjs"
  ],
  [
    "Ter Sami",
    "sjt"
  ],
  [
    "Ume Sami",
    "sju"
  ],
  [
    "Shawnee",
    "sjw"
  ],
  [
    "Skagit",
    "ska"
  ],
  [
    "Saek",
    "skb"
  ],
  [
    "Ma Manda",
    "skc"
  ],
  [
    "Southern Sierra Miwok",
    "skd"
  ],
  [
    "Seke (Vanuatu)",
    "ske"
  ],
  [
    "Sakirabiá",
    "skf"
  ],
  [
    "Sakalava Malagasy",
    "skg"
  ],
  [
    "Sikule",
    "skh"
  ],
  [
    "Sika",
    "ski"
  ],
  [
    "Seke (Nepal)",
    "skj"
  ],
  [
    "Kutong",
    "skm"
  ],
  [
    "Kolibugan Subanon",
    "skn"
  ],
  [
    "Seko Tengah",
    "sko"
  ],
  [
    "Sekapan",
    "skp"
  ],
  [
    "Sininkere",
    "skq"
  ],
  [
    "Saraiki",
    "skr"
  ],
  [
    "Maia",
    "sks"
  ],
  [
    "Sakata",
    "skt"
  ],
  [
    "Sakao",
    "sku"
  ],
  [
    "Skou",
    "skv"
  ],
  [
    "Skepi Creole Dutch",
    "skw"
  ],
  [
    "Seko Padang",
    "skx"
  ],
  [
    "Sikaiana",
    "sky"
  ],
  [
    "Sekar",
    "skz"
  ],
  [
    "Sáliba",
    "slc"
  ],
  [
    "Sissala",
    "sld"
  ],
  [
    "Sholaga",
    "sle"
  ],
  [
    "Swiss-Italian Sign Language",
    "slf"
  ],
  [
    "Selungai Murut",
    "slg"
  ],
  [
    "Southern Puget Sound Salish",
    "slh"
  ],
  [
    "Lower Silesian",
    "sli"
  ],
  [
    "Salumá",
    "slj"
  ],
  [
    "Slovak",
    "slk",
    "slo",
    "slk",
    "sk",
    "Slovenčina"
  ],
  [
    "Salt-Yui",
    "sll"
  ],
  [
    "Pangutaran Sama",
    "slm"
  ],
  [
    "Salinan",
    "sln"
  ],
  [
    "Lamaholot",
    "slp"
  ],
  [
    "Salchuq",
    "slq"
  ],
  [
    "Salar",
    "slr"
  ],
  [
    "Singapore Sign Language",
    "sls"
  ],
  [
    "Sila",
    "slt"
  ],
  [
    "Selaru",
    "slu"
  ],
  [
    "Slovenian",
    "slv",
    "slv",
    "slv",
    "sl",
    "Slovenščina"
  ],
  [
    "Sialum",
    "slw"
  ],
  [
    "Salampasu",
    "slx"
  ],
  [
    "Selayar",
    "sly"
  ],
  [
    "Ma'ya",
    "slz"
  ],
  [
    "Southern Sami",
    "sma",
    "sma",
    "sma"
  ],
  [
    "Simbari",
    "smb"
  ],
  [
    "Som",
    "smc"
  ],
  [
    "Northern Sami",
    "sme",
    "sme",
    "sme",
    "se",
    "Davvisámegiella"
  ],
  [
    "Auwe",
    "smf"
  ],
  [
    "Simbali",
    "smg"
  ],
  [
    "Samei",
    "smh"
  ],
  [
    "Lule Sami",
    "smj",
    "smj",
    "smj"
  ],
  [
    "Bolinao",
    "smk"
  ],
  [
    "Central Sama",
    "sml"
  ],
  [
    "Musasa",
    "smm"
  ],
  [
    "Inari Sami",
    "smn",
    "smn",
    "smn"
  ],
  [
    "Samoan",
    "smo",
    "smo",
    "smo",
    "sm",
    "Gagana Samoa"
  ],
  [
    "Samaritan",
    "smp"
  ],
  [
    "Samo",
    "smq"
  ],
  [
    "Simeulue",
    "smr"
  ],
  [
    "Skolt Sami",
    "sms",
    "sms",
    "sms"
  ],
  [
    "Simte",
    "smt"
  ],
  [
    "Somray",
    "smu"
  ],
  [
    "Samvedi",
    "smv"
  ],
  [
    "Sumbawa",
    "smw"
  ],
  [
    "Samba",
    "smx"
  ],
  [
    "Semnani",
    "smy"
  ],
  [
    "Simeku",
    "smz"
  ],
  [
    "Shona",
    "sna",
    "sna",
    "sna",
    "sn",
    "chiShona"
  ],
  [
    "Sinaugoro",
    "snc"
  ],
  [
    "Sindhi",
    "snd",
    "snd",
    "snd",
    "sd",
    "सिनधि"
  ],
  [
    "Bau Bidayuh",
    "sne"
  ],
  [
    "Noon",
    "snf"
  ],
  [
    "Sanga (Democratic Republic of Congo)",
    "sng"
  ],
  [
    "Sensi",
    "sni"
  ],
  [
    "Riverain Sango",
    "snj"
  ],
  [
    "Soninke",
    "snk",
    "snk",
    "snk"
  ],
  [
    "Sangil",
    "snl"
  ],
  [
    "Southern Ma'di",
    "snm"
  ],
  [
    "Siona",
    "snn"
  ],
  [
    "Snohomish",
    "sno"
  ],
  [
    "Siane",
    "snp"
  ],
  [
    "Sangu (Gabon)",
    "snq"
  ],
  [
    "Sihan",
    "snr"
  ],
  [
    "South West Bay",
    "sns"
  ],
  [
    "Senggi",
    "snu"
  ],
  [
    "Sa'ban",
    "snv"
  ],
  [
    "Selee",
    "snw"
  ],
  [
    "Sam",
    "snx"
  ],
  [
    "Saniyo-Hiyewe",
    "sny"
  ],
  [
    "Kou",
    "snz"
  ],
  [
    "Thai Song",
    "soa"
  ],
  [
    "Sobei",
    "sob"
  ],
  [
    "So (Democratic Republic of Congo)",
    "soc"
  ],
  [
    "Songoora",
    "sod"
  ],
  [
    "Songomeno",
    "soe"
  ],
  [
    "Sogdian",
    "sog",
    "sog",
    "sog"
  ],
  [
    "Aka",
    "soh"
  ],
  [
    "Sonha",
    "soi"
  ],
  [
    "Soi",
    "soj"
  ],
  [
    "Sokoro",
    "sok"
  ],
  [
    "Solos",
    "sol"
  ],
  [
    "Somali",
    "som",
    "som",
    "som",
    "so",
    "Soomaaliga"
  ],
  [
    "Songo",
    "soo"
  ],
  [
    "Songe",
    "sop"
  ],
  [
    "Kanasi",
    "soq"
  ],
  [
    "Somrai",
    "sor"
  ],
  [
    "Seeku",
    "sos"
  ],
  [
    "Southern Sotho",
    "sot",
    "sot",
    "sot",
    "st",
    "Sesotho"
  ],
  [
    "Southern Thai",
    "sou"
  ],
  [
    "Sonsorol",
    "sov"
  ],
  [
    "Sowanda",
    "sow"
  ],
  [
    "Swo",
    "sox"
  ],
  [
    "Miyobe",
    "soy"
  ],
  [
    "Temi",
    "soz"
  ],
  [
    "Spanish",
    "spa",
    "spa",
    "spa",
    "es",
    "Español",
    ["#infinitive-construct", "#infinitive", "#participle#3rd#singular", "#present#3rd#singular"]
  ],
  [
    "Sepa (Indonesia)",
    "spb"
  ],
  [
    "Sapé",
    "spc"
  ],
  [
    "Saep",
    "spd"
  ],
  [
    "Sepa (Papua New Guinea)",
    "spe"
  ],
  [
    "Sian",
    "spg"
  ],
  [
    "Saponi",
    "spi"
  ],
  [
    "Sengo",
    "spk"
  ],
  [
    "Selepet",
    "spl"
  ],
  [
    "Akukem",
    "spm"
  ],
  [
    "Sanapaná",
    "spn"
  ],
  [
    "Spokane",
    "spo"
  ],
  [
    "Supyire Senoufo",
    "spp"
  ],
  [
    "Loreto-Ucayali Spanish",
    "spq"
  ],
  [
    "Saparua",
    "spr"
  ],
  [
    "Saposa",
    "sps"
  ],
  [
    "Spiti Bhoti",
    "spt"
  ],
  [
    "Sapuan",
    "spu"
  ],
  [
    "Sambalpuri",
    "spv"
  ],
  [
    "South Picene",
    "spx"
  ],
  [
    "Sabaot",
    "spy"
  ],
  [
    "Shama-Sambuga",
    "sqa"
  ],
  [
    "Shau",
    "sqh"
  ],
  [
    "Albanian",
    "sqi",
    "alb",
    "sqi",
    "sq",
    "Shqip"
  ],
  [
    "Albanian Sign Language",
    "sqk"
  ],
  [
    "Suma",
    "sqm"
  ],
  [
    "Susquehannock",
    "sqn"
  ],
  [
    "Sorkhei",
    "sqo"
  ],
  [
    "Sou",
    "sqq"
  ],
  [
    "Siculo Arabic",
    "sqr"
  ],
  [
    "Sri Lankan Sign Language",
    "sqs"
  ],
  [
    "Soqotri",
    "sqt"
  ],
  [
    "Squamish",
    "squ"
  ],
  [
    "Kufr Qassem Sign Language (KQSL)",
    "sqx"
  ],
  [
    "Saruga",
    "sra"
  ],
  [
    "Sora",
    "srb"
  ],
  [
    "Logudorese Sardinian",
    "src"
  ],
  [
    "Sardinian",
    "srd",
    "srd",
    "srd",
    "sc",
    "Sardu"
  ],
  [
    "Sara",
    "sre"
  ],
  [
    "Nafi",
    "srf"
  ],
  [
    "Sulod",
    "srg"
  ],
  [
    "Sarikoli",
    "srh"
  ],
  [
    "Siriano",
    "sri"
  ],
  [
    "Serudung Murut",
    "srk"
  ],
  [
    "Isirawa",
    "srl"
  ],
  [
    "Saramaccan",
    "srm"
  ],
  [
    "Sranan Tongo",
    "srn",
    "srn",
    "srn"
  ],
  [
    "Campidanese Sardinian",
    "sro"
  ],
  [
    "Serbian",
    "srp",
    "srp",
    "srp",
    "sr",
    "Српски"
  ],
  [
    "Sirionó",
    "srq"
  ],
  [
    "Serer",
    "srr",
    "srr",
    "srr"
  ],
  [
    "Sarsi",
    "srs"
  ],
  [
    "Sauri",
    "srt"
  ],
  [
    "Suruí",
    "sru"
  ],
  [
    "Southern Sorsoganon",
    "srv"
  ],
  [
    "Serua",
    "srw"
  ],
  [
    "Sirmauri",
    "srx"
  ],
  [
    "Sera",
    "sry"
  ],
  [
    "Shahmirzadi",
    "srz"
  ],
  [
    "Southern Sama",
    "ssb"
  ],
  [
    "Suba-Simbiti",
    "ssc"
  ],
  [
    "Siroi",
    "ssd"
  ],
  [
    "Balangingi",
    "sse"
  ],
  [
    "Thao",
    "ssf"
  ],
  [
    "Seimat",
    "ssg"
  ],
  [
    "Shihhi Arabic",
    "ssh"
  ],
  [
    "Sansi",
    "ssi"
  ],
  [
    "Sausi",
    "ssj"
  ],
  [
    "Sunam",
    "ssk"
  ],
  [
    "Western Sisaala",
    "ssl"
  ],
  [
    "Semnam",
    "ssm"
  ],
  [
    "Waata",
    "ssn"
  ],
  [
    "Sissano",
    "sso"
  ],
  [
    "Spanish Sign Language",
    "ssp"
  ],
  [
    "So'a",
    "ssq"
  ],
  [
    "Swiss-French Sign Language",
    "ssr"
  ],
  [
    "Sô",
    "sss"
  ],
  [
    "Sinasina",
    "sst"
  ],
  [
    "Susuami",
    "ssu"
  ],
  [
    "Shark Bay",
    "ssv"
  ],
  [
    "Swati",
    "ssw",
    "ssw",
    "ssw",
    "ss",
    "SiSwati"
  ],
  [
    "Samberigi",
    "ssx"
  ],
  [
    "Saho",
    "ssy"
  ],
  [
    "Sengseng",
    "ssz"
  ],
  [
    "Settla",
    "sta"
  ],
  [
    "Northern Subanen",
    "stb"
  ],
  [
    "Sentinel",
    "std"
  ],
  [
    "Liana-Seti",
    "ste"
  ],
  [
    "Seta",
    "stf"
  ],
  [
    "Trieng",
    "stg"
  ],
  [
    "Shelta",
    "sth"
  ],
  [
    "Bulo Stieng",
    "sti"
  ],
  [
    "Matya Samo",
    "stj"
  ],
  [
    "Arammba",
    "stk"
  ],
  [
    "Stellingwerfs",
    "stl"
  ],
  [
    "Setaman",
    "stm"
  ],
  [
    "Owa",
    "stn"
  ],
  [
    "Stoney",
    "sto"
  ],
  [
    "Southeastern Tepehuan",
    "stp"
  ],
  [
    "Saterfriesisch",
    "stq"
  ],
  [
    "Straits Salish",
    "str"
  ],
  [
    "Shumashti",
    "sts"
  ],
  [
    "Budeh Stieng",
    "stt"
  ],
  [
    "Samtao",
    "stu"
  ],
  [
    "Silt'e",
    "stv"
  ],
  [
    "Satawalese",
    "stw"
  ],
  [
    "Siberian Tatar",
    "sty"
  ],
  [
    "Sulka",
    "sua"
  ],
  [
    "Suku",
    "sub"
  ],
  [
    "Western Subanon",
    "suc"
  ],
  [
    "Suena",
    "sue"
  ],
  [
    "Suganga",
    "sug"
  ],
  [
    "Suki",
    "sui"
  ],
  [
    "Shubi",
    "suj"
  ],
  [
    "Sukuma",
    "suk",
    "suk",
    "suk"
  ],
  [
    "Sundanese",
    "sun",
    "sun",
    "sun",
    "su",
    "Basa Sunda"
  ],
  [
    "Bouni",
    "suo"
  ],
  [
    "Tirmaga-Chai Suri",
    "suq"
  ],
  [
    "Mwaghavul",
    "sur"
  ],
  [
    "Susu",
    "sus",
    "sus",
    "sus"
  ],
  [
    "Subtiaba",
    "sut"
  ],
  [
    "Puroik",
    "suv"
  ],
  [
    "Sumbwa",
    "suw"
  ],
  [
    "Sumerian",
    "sux",
    "sux",
    "sux"
  ],
  [
    "Suyá",
    "suy"
  ],
  [
    "Sunwar",
    "suz"
  ],
  [
    "Svan",
    "sva"
  ],
  [
    "Ulau-Suain",
    "svb"
  ],
  [
    "Vincentian Creole English",
    "svc"
  ],
  [
    "Serili",
    "sve"
  ],
  [
    "Slovakian Sign Language",
    "svk"
  ],
  [
    "Slavomolisano",
    "svm"
  ],
  [
    "Savosavo",
    "svs"
  ],
  [
    "Skalvian",
    "svx"
  ],
  [
    "Swahili (macrolanguage)",
    "swa",
    "swa",
    "swa",
    "sw",
    "Kiswahili"
  ],
  [
    "Maore Comorian",
    "swb"
  ],
  [
    "Congo Swahili",
    "swc"
  ],
  [
    "Swedish",
    "swe",
    "swe",
    "swe",
    "sv",
    "Svenska"
  ],
  [
    "Sere",
    "swf"
  ],
  [
    "Swabian",
    "swg"
  ],
  [
    "Swahili (individual language)",
    "swh"
  ],
  [
    "Sui",
    "swi"
  ],
  [
    "Sira",
    "swj"
  ],
  [
    "Malawi Sena",
    "swk"
  ],
  [
    "Swedish Sign Language",
    "swl"
  ],
  [
    "Samosa",
    "swm"
  ],
  [
    "Sawknah",
    "swn"
  ],
  [
    "Shanenawa",
    "swo"
  ],
  [
    "Suau",
    "swp"
  ],
  [
    "Sharwa",
    "swq"
  ],
  [
    "Saweru",
    "swr"
  ],
  [
    "Seluwasan",
    "sws"
  ],
  [
    "Sawila",
    "swt"
  ],
  [
    "Suwawa",
    "swu"
  ],
  [
    "Shekhawati",
    "swv"
  ],
  [
    "Sowa",
    "sww"
  ],
  [
    "Suruahá",
    "swx"
  ],
  [
    "Sarua",
    "swy"
  ],
  [
    "Suba",
    "sxb"
  ],
  [
    "Sicanian",
    "sxc"
  ],
  [
    "Sighu",
    "sxe"
  ],
  [
    "Shuhi",
    "sxg"
  ],
  [
    "Southern Kalapuya",
    "sxk"
  ],
  [
    "Selian",
    "sxl"
  ],
  [
    "Samre",
    "sxm"
  ],
  [
    "Sangir",
    "sxn"
  ],
  [
    "Sorothaptic",
    "sxo"
  ],
  [
    "Saaroa",
    "sxr"
  ],
  [
    "Sasaru",
    "sxs"
  ],
  [
    "Upper Saxon",
    "sxu"
  ],
  [
    "Saxwe Gbe",
    "sxw"
  ],
  [
    "Siang",
    "sya"
  ],
  [
    "Central Subanen",
    "syb"
  ],
  [
    "Classical Syriac",
    "syc",
    "syc",
    "syc"
  ],
  [
    "Seki",
    "syi"
  ],
  [
    "Sukur",
    "syk"
  ],
  [
    "Sylheti",
    "syl"
  ],
  [
    "Maya Samo",
    "sym"
  ],
  [
    "Senaya",
    "syn"
  ],
  [
    "Suoy",
    "syo"
  ],
  [
    "Syriac",
    "syr",
    "syr",
    "syr"
  ],
  [
    "Sinyar",
    "sys"
  ],
  [
    "Kagate",
    "syw"
  ],
  [
    "Samay",
    "syx"
  ],
  [
    "Al-Sayyid Bedouin Sign Language",
    "syy"
  ],
  [
    "Semelai",
    "sza"
  ],
  [
    "Ngalum",
    "szb"
  ],
  [
    "Semaq Beri",
    "szc"
  ],
  [
    "Seru",
    "szd"
  ],
  [
    "Seze",
    "sze"
  ],
  [
    "Sengele",
    "szg"
  ],
  [
    "Silesian",
    "szl"
  ],
  [
    "Sula",
    "szn"
  ],
  [
    "Suabo",
    "szp"
  ],
  [
    "Solomon Islands Sign Language",
    "szs"
  ],
  [
    "Isu (Fako Division)",
    "szv"
  ],
  [
    "Sawai",
    "szw"
  ],
  [
    "Sakizaya",
    "szy"
  ],
  [
    "Lower Tanana",
    "taa"
  ],
  [
    "Tabassaran",
    "tab"
  ],
  [
    "Lowland Tarahumara",
    "tac"
  ],
  [
    "Tause",
    "tad"
  ],
  [
    "Tariana",
    "tae"
  ],
  [
    "Tapirapé",
    "taf"
  ],
  [
    "Tagoi",
    "tag"
  ],
  [
    "Tahitian",
    "tah",
    "tah",
    "tah",
    "ty",
    "Reo Mā`ohi"
  ],
  [
    "Eastern Tamang",
    "taj"
  ],
  [
    "Tala",
    "tak"
  ],
  [
    "Tal",
    "tal"
  ],
  [
    "Tamil",
    "tam",
    "tam",
    "tam",
    "ta",
    "தமிழ்"
  ],
  [
    "Tangale",
    "tan"
  ],
  [
    "Yami",
    "tao"
  ],
  [
    "Taabwa",
    "tap"
  ],
  [
    "Tamasheq",
    "taq"
  ],
  [
    "Central Tarahumara",
    "tar"
  ],
  [
    "Tay Boi",
    "tas"
  ],
  [
    "Tatar",
    "tat",
    "tat",
    "tat",
    "tt",
    "Tatarça"
  ],
  [
    "Upper Tanana",
    "tau"
  ],
  [
    "Tatuyo",
    "tav"
  ],
  [
    "Tai",
    "taw"
  ],
  [
    "Tamki",
    "tax"
  ],
  [
    "Atayal",
    "tay"
  ],
  [
    "Tocho",
    "taz"
  ],
  [
    "Aikanã",
    "tba"
  ],
  [
    "Takia",
    "tbc"
  ],
  [
    "Kaki Ae",
    "tbd"
  ],
  [
    "Tanimbili",
    "tbe"
  ],
  [
    "Mandara",
    "tbf"
  ],
  [
    "North Tairora",
    "tbg"
  ],
  [
    "Dharawal",
    "tbh"
  ],
  [
    "Gaam",
    "tbi"
  ],
  [
    "Tiang",
    "tbj"
  ],
  [
    "Calamian Tagbanwa",
    "tbk"
  ],
  [
    "Tboli",
    "tbl"
  ],
  [
    "Tagbu",
    "tbm"
  ],
  [
    "Barro Negro Tunebo",
    "tbn"
  ],
  [
    "Tawala",
    "tbo"
  ],
  [
    "Taworta",
    "tbp"
  ],
  [
    "Tumtum",
    "tbr"
  ],
  [
    "Tanguat",
    "tbs"
  ],
  [
    "Tembo (Kitembo)",
    "tbt"
  ],
  [
    "Tubar",
    "tbu"
  ],
  [
    "Tobo",
    "tbv"
  ],
  [
    "Tagbanwa",
    "tbw"
  ],
  [
    "Kapin",
    "tbx"
  ],
  [
    "Tabaru",
    "tby"
  ],
  [
    "Ditammari",
    "tbz"
  ],
  [
    "Ticuna",
    "tca"
  ],
  [
    "Tanacross",
    "tcb"
  ],
  [
    "Datooga",
    "tcc"
  ],
  [
    "Tafi",
    "tcd"
  ],
  [
    "Southern Tutchone",
    "tce"
  ],
  [
    "Malinaltepec Me'phaa",
    "tcf"
  ],
  [
    "Tamagario",
    "tcg"
  ],
  [
    "Turks And Caicos Creole English",
    "tch"
  ],
  [
    "Wára",
    "tci"
  ],
  [
    "Tchitchege",
    "tck"
  ],
  [
    "Taman (Myanmar)",
    "tcl"
  ],
  [
    "Tanahmerah",
    "tcm"
  ],
  [
    "Tichurong",
    "tcn"
  ],
  [
    "Taungyo",
    "tco"
  ],
  [
    "Tawr Chin",
    "tcp"
  ],
  [
    "Kaiy",
    "tcq"
  ],
  [
    "Torres Strait Creole",
    "tcs"
  ],
  [
    "T'en",
    "tct"
  ],
  [
    "Southeastern Tarahumara",
    "tcu"
  ],
  [
    "Tecpatlán Totonac",
    "tcw"
  ],
  [
    "Toda",
    "tcx"
  ],
  [
    "Tulu",
    "tcy"
  ],
  [
    "Thado Chin",
    "tcz"
  ],
  [
    "Tagdal",
    "tda"
  ],
  [
    "Panchpargania",
    "tdb"
  ],
  [
    "Emberá-Tadó",
    "tdc"
  ],
  [
    "Tai Nüa",
    "tdd"
  ],
  [
    "Tiranige Diga Dogon",
    "tde"
  ],
  [
    "Talieng",
    "tdf"
  ],
  [
    "Western Tamang",
    "tdg"
  ],
  [
    "Thulung",
    "tdh"
  ],
  [
    "Tomadino",
    "tdi"
  ],
  [
    "Tajio",
    "tdj"
  ],
  [
    "Tambas",
    "tdk"
  ],
  [
    "Sur",
    "tdl"
  ],
  [
    "Taruma",
    "tdm"
  ],
  [
    "Tondano",
    "tdn"
  ],
  [
    "Teme",
    "tdo"
  ],
  [
    "Tita",
    "tdq"
  ],
  [
    "Todrah",
    "tdr"
  ],
  [
    "Doutai",
    "tds"
  ],
  [
    "Tetun Dili",
    "tdt"
  ],
  [
    "Toro",
    "tdv"
  ],
  [
    "Tandroy-Mahafaly Malagasy",
    "tdx"
  ],
  [
    "Tadyawan",
    "tdy"
  ],
  [
    "Temiar",
    "tea"
  ],
  [
    "Tetete",
    "teb"
  ],
  [
    "Terik",
    "tec"
  ],
  [
    "Tepo Krumen",
    "ted"
  ],
  [
    "Huehuetla Tepehua",
    "tee"
  ],
  [
    "Teressa",
    "tef"
  ],
  [
    "Teke-Tege",
    "teg"
  ],
  [
    "Tehuelche",
    "teh"
  ],
  [
    "Torricelli",
    "tei"
  ],
  [
    "Ibali Teke",
    "tek"
  ],
  [
    "Telugu",
    "tel",
    "tel",
    "tel",
    "te",
    "తెలుగు"
  ],
  [
    "Timne",
    "tem",
    "tem",
    "tem"
  ],
  [
    "Tama (Colombia)",
    "ten"
  ],
  [
    "Teso",
    "teo"
  ],
  [
    "Tepecano",
    "tep"
  ],
  [
    "Temein",
    "teq"
  ],
  [
    "Tereno",
    "ter",
    "ter",
    "ter"
  ],
  [
    "Tengger",
    "tes"
  ],
  [
    "Tetum",
    "tet",
    "tet",
    "tet"
  ],
  [
    "Soo",
    "teu"
  ],
  [
    "Teor",
    "tev"
  ],
  [
    "Tewa (USA)",
    "tew"
  ],
  [
    "Tennet",
    "tex"
  ],
  [
    "Tulishi",
    "tey"
  ],
  [
    "Tetserret",
    "tez"
  ],
  [
    "Tofin Gbe",
    "tfi"
  ],
  [
    "Tanaina",
    "tfn"
  ],
  [
    "Tefaro",
    "tfo"
  ],
  [
    "Teribe",
    "tfr"
  ],
  [
    "Ternate",
    "tft"
  ],
  [
    "Sagalla",
    "tga"
  ],
  [
    "Tobilung",
    "tgb"
  ],
  [
    "Tigak",
    "tgc"
  ],
  [
    "Ciwogai",
    "tgd"
  ],
  [
    "Eastern Gorkha Tamang",
    "tge"
  ],
  [
    "Chalikha",
    "tgf"
  ],
  [
    "Tobagonian Creole English",
    "tgh"
  ],
  [
    "Lawunuia",
    "tgi"
  ],
  [
    "Tagin",
    "tgj"
  ],
  [
    "Tajik",
    "tgk",
    "tgk",
    "tgk",
    "tg",
    "Тоҷикӣ"
  ],
  [
    "Tagalog",
    "tgl",
    "tgl",
    "tgl",
    "tl",
    "Tagalog"
  ],
  [
    "Tandaganon",
    "tgn"
  ],
  [
    "Sudest",
    "tgo"
  ],
  [
    "Tangoa",
    "tgp"
  ],
  [
    "Tring",
    "tgq"
  ],
  [
    "Tareng",
    "tgr"
  ],
  [
    "Nume",
    "tgs"
  ],
  [
    "Central Tagbanwa",
    "tgt"
  ],
  [
    "Tanggu",
    "tgu"
  ],
  [
    "Tingui-Boto",
    "tgv"
  ],
  [
    "Tagwana Senoufo",
    "tgw"
  ],
  [
    "Tagish",
    "tgx"
  ],
  [
    "Togoyo",
    "tgy"
  ],
  [
    "Tagalaka",
    "tgz"
  ],
  [
    "Thai",
    "tha",
    "tha",
    "tha",
    "th",
    "ไทย / Phasa Thai"
  ],
  [
    "Kuuk Thaayorre",
    "thd"
  ],
  [
    "Chitwania Tharu",
    "the"
  ],
  [
    "Thangmi",
    "thf"
  ],
  [
    "Northern Tarahumara",
    "thh"
  ],
  [
    "Tai Long",
    "thi"
  ],
  [
    "Tharaka",
    "thk"
  ],
  [
    "Dangaura Tharu",
    "thl"
  ],
  [
    "Aheu",
    "thm"
  ],
  [
    "Thachanadan",
    "thn"
  ],
  [
    "Thompson",
    "thp"
  ],
  [
    "Kochila Tharu",
    "thq"
  ],
  [
    "Rana Tharu",
    "thr"
  ],
  [
    "Thakali",
    "ths"
  ],
  [
    "Tahltan",
    "tht"
  ],
  [
    "Thuri",
    "thu"
  ],
  [
    "Tahaggart Tamahaq",
    "thv"
  ],
  [
    "Tha",
    "thy"
  ],
  [
    "Tayart Tamajeq",
    "thz"
  ],
  [
    "Tidikelt Tamazight",
    "tia"
  ],
  [
    "Tira",
    "tic"
  ],
  [
    "Tifal",
    "tif"
  ],
  [
    "Tigre",
    "tig",
    "tig",
    "tig"
  ],
  [
    "Timugon Murut",
    "tih"
  ],
  [
    "Tiene",
    "tii"
  ],
  [
    "Tilung",
    "tij"
  ],
  [
    "Tikar",
    "tik"
  ],
  [
    "Tillamook",
    "til"
  ],
  [
    "Timbe",
    "tim"
  ],
  [
    "Tindi",
    "tin"
  ],
  [
    "Teop",
    "tio"
  ],
  [
    "Trimuris",
    "tip"
  ],
  [
    "Tiéfo",
    "tiq"
  ],
  [
    "Tigrinya",
    "tir",
    "tir",
    "tir",
    "ti",
    "ትግርኛ"
  ],
  [
    "Masadiit Itneg",
    "tis"
  ],
  [
    "Tinigua",
    "tit"
  ],
  [
    "Adasen",
    "tiu"
  ],
  [
    "Tiv",
    "tiv",
    "tiv",
    "tiv"
  ],
  [
    "Tiwi",
    "tiw"
  ],
  [
    "Southern Tiwa",
    "tix"
  ],
  [
    "Tiruray",
    "tiy"
  ],
  [
    "Tai Hongjin",
    "tiz"
  ],
  [
    "Tajuasohn",
    "tja"
  ],
  [
    "Tunjung",
    "tjg"
  ],
  [
    "Northern Tujia",
    "tji"
  ],
  [
    "Tjungundji",
    "tjj"
  ],
  [
    "Tai Laing",
    "tjl"
  ],
  [
    "Timucua",
    "tjm"
  ],
  [
    "Tonjon",
    "tjn"
  ],
  [
    "Temacine Tamazight",
    "tjo"
  ],
  [
    "Tjupany",
    "tjp"
  ],
  [
    "Southern Tujia",
    "tjs"
  ],
  [
    "Tjurruru",
    "tju"
  ],
  [
    "Djabwurrung",
    "tjw"
  ],
  [
    "Truká",
    "tka"
  ],
  [
    "Buksa",
    "tkb"
  ],
  [
    "Tukudede",
    "tkd"
  ],
  [
    "Takwane",
    "tke"
  ],
  [
    "Tukumanféd",
    "tkf"
  ],
  [
    "Tesaka Malagasy",
    "tkg"
  ],
  [
    "Tokelau",
    "tkl",
    "tkl",
    "tkl"
  ],
  [
    "Takelma",
    "tkm"
  ],
  [
    "Toku-No-Shima",
    "tkn"
  ],
  [
    "Tikopia",
    "tkp"
  ],
  [
    "Tee",
    "tkq"
  ],
  [
    "Tsakhur",
    "tkr"
  ],
  [
    "Takestani",
    "tks"
  ],
  [
    "Kathoriya Tharu",
    "tkt"
  ],
  [
    "Upper Necaxa Totonac",
    "tku"
  ],
  [
    "Mur Pano",
    "tkv"
  ],
  [
    "Teanu",
    "tkw"
  ],
  [
    "Tangko",
    "tkx"
  ],
  [
    "Takua",
    "tkz"
  ],
  [
    "Southwestern Tepehuan",
    "tla"
  ],
  [
    "Tobelo",
    "tlb"
  ],
  [
    "Yecuatla Totonac",
    "tlc"
  ],
  [
    "Talaud",
    "tld"
  ],
  [
    "Telefol",
    "tlf"
  ],
  [
    "Tofanma",
    "tlg"
  ],
  [
    "Klingon",
    "tlh",
    "tlh",
    "tlh"
  ],
  [
    "Tlingit",
    "tli",
    "tli",
    "tli"
  ],
  [
    "Talinga-Bwisi",
    "tlj"
  ],
  [
    "Taloki",
    "tlk"
  ],
  [
    "Tetela",
    "tll"
  ],
  [
    "Tolomako",
    "tlm"
  ],
  [
    "Talondo'",
    "tln"
  ],
  [
    "Talodi",
    "tlo"
  ],
  [
    "Filomena Mata-Coahuitlán Totonac",
    "tlp"
  ],
  [
    "Tai Loi",
    "tlq"
  ],
  [
    "Talise",
    "tlr"
  ],
  [
    "Tambotalo",
    "tls"
  ],
  [
    "Sou Nama",
    "tlt"
  ],
  [
    "Tulehu",
    "tlu"
  ],
  [
    "Taliabu",
    "tlv"
  ],
  [
    "Khehek",
    "tlx"
  ],
  [
    "Talysh",
    "tly"
  ],
  [
    "Tama (Chad)",
    "tma"
  ],
  [
    "Katbol",
    "tmb"
  ],
  [
    "Tumak",
    "tmc"
  ],
  [
    "Haruai",
    "tmd"
  ],
  [
    "Tremembé",
    "tme"
  ],
  [
    "Toba-Maskoy",
    "tmf"
  ],
  [
    "Ternateño",
    "tmg"
  ],
  [
    "Tamashek",
    "tmh",
    "tmh",
    "tmh"
  ],
  [
    "Tutuba",
    "tmi"
  ],
  [
    "Samarokena",
    "tmj"
  ],
  [
    "Northwestern Tamang",
    "tmk"
  ],
  [
    "Tamnim Citak",
    "tml"
  ],
  [
    "Tai Thanh",
    "tmm"
  ],
  [
    "Taman (Indonesia)",
    "tmn"
  ],
  [
    "Temoq",
    "tmo"
  ],
  [
    "Tumleo",
    "tmq"
  ],
  [
    "Jewish Babylonian Aramaic (ca. 200-1200 CE)",
    "tmr"
  ],
  [
    "Tima",
    "tms"
  ],
  [
    "Tasmate",
    "tmt"
  ],
  [
    "Iau",
    "tmu"
  ],
  [
    "Tembo (Motembo)",
    "tmv"
  ],
  [
    "Temuan",
    "tmw"
  ],
  [
    "Tami",
    "tmy"
  ],
  [
    "Tamanaku",
    "tmz"
  ],
  [
    "Tacana",
    "tna"
  ],
  [
    "Western Tunebo",
    "tnb"
  ],
  [
    "Tanimuca-Retuarã",
    "tnc"
  ],
  [
    "Angosturas Tunebo",
    "tnd"
  ],
  [
    "Tobanga",
    "tng"
  ],
  [
    "Maiani",
    "tnh"
  ],
  [
    "Tandia",
    "tni"
  ],
  [
    "Kwamera",
    "tnk"
  ],
  [
    "Lenakel",
    "tnl"
  ],
  [
    "Tabla",
    "tnm"
  ],
  [
    "North Tanna",
    "tnn"
  ],
  [
    "Toromono",
    "tno"
  ],
  [
    "Whitesands",
    "tnp"
  ],
  [
    "Taino",
    "tnq"
  ],
  [
    "Ménik",
    "tnr"
  ],
  [
    "Tenis",
    "tns"
  ],
  [
    "Tontemboan",
    "tnt"
  ],
  [
    "Tay Khang",
    "tnu"
  ],
  [
    "Tangchangya",
    "tnv"
  ],
  [
    "Tonsawang",
    "tnw"
  ],
  [
    "Tanema",
    "tnx"
  ],
  [
    "Tongwe",
    "tny"
  ],
  [
    "Ten'edn",
    "tnz"
  ],
  [
    "Toba",
    "tob"
  ],
  [
    "Coyutla Totonac",
    "toc"
  ],
  [
    "Toma",
    "tod"
  ],
  [
    "Gizrra",
    "tof"
  ],
  [
    "Tonga (Nyasa)",
    "tog",
    "tog",
    "tog"
  ],
  [
    "Gitonga",
    "toh"
  ],
  [
    "Tonga (Zambia)",
    "toi"
  ],
  [
    "Tojolabal",
    "toj"
  ],
  [
    "Toki Pona",
    "tok"
  ],
  [
    "Tolowa",
    "tol"
  ],
  [
    "Tombulu",
    "tom"
  ],
  [
    "Tonga (Tonga Islands)",
    "ton",
    "ton",
    "ton",
    "to",
    "Lea Faka-Tonga"
  ],
  [
    "Xicotepec De Juárez Totonac",
    "too"
  ],
  [
    "Papantla Totonac",
    "top"
  ],
  [
    "Toposa",
    "toq"
  ],
  [
    "Togbo-Vara Banda",
    "tor"
  ],
  [
    "Highland Totonac",
    "tos"
  ],
  [
    "Tho",
    "tou"
  ],
  [
    "Upper Taromi",
    "tov"
  ],
  [
    "Jemez",
    "tow"
  ],
  [
    "Tobian",
    "tox"
  ],
  [
    "Topoiyo",
    "toy"
  ],
  [
    "To",
    "toz"
  ],
  [
    "Taupota",
    "tpa"
  ],
  [
    "Azoyú Me'phaa",
    "tpc"
  ],
  [
    "Tippera",
    "tpe"
  ],
  [
    "Tarpia",
    "tpf"
  ],
  [
    "Kula",
    "tpg"
  ],
  [
    "Tok Pisin",
    "tpi",
    "tpi",
    "tpi"
  ],
  [
    "Tapieté",
    "tpj"
  ],
  [
    "Tupinikin",
    "tpk"
  ],
  [
    "Tlacoapa Me'phaa",
    "tpl"
  ],
  [
    "Tampulma",
    "tpm"
  ],
  [
    "Tupinambá",
    "tpn"
  ],
  [
    "Tai Pao",
    "tpo"
  ],
  [
    "Pisaflores Tepehua",
    "tpp"
  ],
  [
    "Tukpa",
    "tpq"
  ],
  [
    "Tuparí",
    "tpr"
  ],
  [
    "Tlachichilco Tepehua",
    "tpt"
  ],
  [
    "Tampuan",
    "tpu"
  ],
  [
    "Tanapag",
    "tpv"
  ],
  [
    "Tupí",
    "tpw"
  ],
  [
    "Acatepec Me'phaa",
    "tpx"
  ],
  [
    "Trumai",
    "tpy"
  ],
  [
    "Tinputz",
    "tpz"
  ],
  [
    "Tembé",
    "tqb"
  ],
  [
    "Lehali",
    "tql"
  ],
  [
    "Turumsa",
    "tqm"
  ],
  [
    "Tenino",
    "tqn"
  ],
  [
    "Toaripi",
    "tqo"
  ],
  [
    "Tomoip",
    "tqp"
  ],
  [
    "Tunni",
    "tqq"
  ],
  [
    "Torona",
    "tqr"
  ],
  [
    "Western Totonac",
    "tqt"
  ],
  [
    "Touo",
    "tqu"
  ],
  [
    "Tonkawa",
    "tqw"
  ],
  [
    "Tirahi",
    "tra"
  ],
  [
    "Terebu",
    "trb"
  ],
  [
    "Copala Triqui",
    "trc"
  ],
  [
    "Turi",
    "trd"
  ],
  [
    "East Tarangan",
    "tre"
  ],
  [
    "Trinidadian Creole English",
    "trf"
  ],
  [
    "Lishán Didán",
    "trg"
  ],
  [
    "Turaka",
    "trh"
  ],
  [
    "Trió",
    "tri"
  ],
  [
    "Toram",
    "trj"
  ],
  [
    "Traveller Scottish",
    "trl"
  ],
  [
    "Tregami",
    "trm"
  ],
  [
    "Trinitario",
    "trn"
  ],
  [
    "Tarao Naga",
    "tro"
  ],
  [
    "Kok Borok",
    "trp"
  ],
  [
    "San Martín Itunyoso Triqui",
    "trq"
  ],
  [
    "Taushiro",
    "trr"
  ],
  [
    "Chicahuaxtla Triqui",
    "trs"
  ],
  [
    "Tunggare",
    "trt"
  ],
  [
    "Turoyo",
    "tru"
  ],
  [
    "Sediq",
    "trv"
  ],
  [
    "Torwali",
    "trw"
  ],
  [
    "Tringgus-Sembaan Bidayuh",
    "trx"
  ],
  [
    "Turung",
    "try"
  ],
  [
    "Torá",
    "trz"
  ],
  [
    "Tsaangi",
    "tsa"
  ],
  [
    "Tsamai",
    "tsb"
  ],
  [
    "Tswa",
    "tsc"
  ],
  [
    "Tsakonian",
    "tsd"
  ],
  [
    "Tunisian Sign Language",
    "tse"
  ],
  [
    "Tausug",
    "tsg"
  ],
  [
    "Tsuvan",
    "tsh"
  ],
  [
    "Tsimshian",
    "tsi",
    "tsi",
    "tsi"
  ],
  [
    "Tshangla",
    "tsj"
  ],
  [
    "Tseku",
    "tsk"
  ],
  [
    "Ts'ün-Lao",
    "tsl"
  ],
  [
    "Turkish Sign Language",
    "tsm"
  ],
  [
    "Tswana",
    "tsn",
    "tsn",
    "tsn",
    "tn",
    "Setswana"
  ],
  [
    "Tsonga",
    "tso",
    "tso",
    "tso",
    "ts",
    "Xitsonga"
  ],
  [
    "Northern Toussian",
    "tsp"
  ],
  [
    "Thai Sign Language",
    "tsq"
  ],
  [
    "Akei",
    "tsr"
  ],
  [
    "Taiwan Sign Language",
    "tss"
  ],
  [
    "Tondi Songway Kiini",
    "tst"
  ],
  [
    "Tsou",
    "tsu"
  ],
  [
    "Tsogo",
    "tsv"
  ],
  [
    "Tsishingini",
    "tsw"
  ],
  [
    "Mubami",
    "tsx"
  ],
  [
    "Tebul Sign Language",
    "tsy"
  ],
  [
    "Purepecha",
    "tsz"
  ],
  [
    "Tutelo",
    "tta"
  ],
  [
    "Gaa",
    "ttb"
  ],
  [
    "Tektiteko",
    "ttc"
  ],
  [
    "Tauade",
    "ttd"
  ],
  [
    "Bwanabwana",
    "tte"
  ],
  [
    "Tuotomb",
    "ttf"
  ],
  [
    "Tutong",
    "ttg"
  ],
  [
    "Upper Ta'oih",
    "tth"
  ],
  [
    "Tobati",
    "tti"
  ],
  [
    "Tooro",
    "ttj"
  ],
  [
    "Totoro",
    "ttk"
  ],
  [
    "Totela",
    "ttl"
  ],
  [
    "Northern Tutchone",
    "ttm"
  ],
  [
    "Towei",
    "ttn"
  ],
  [
    "Lower Ta'oih",
    "tto"
  ],
  [
    "Tombelala",
    "ttp"
  ],
  [
    "Tawallammat Tamajaq",
    "ttq"
  ],
  [
    "Tera",
    "ttr"
  ],
  [
    "Northeastern Thai",
    "tts"
  ],
  [
    "Muslim Tat",
    "ttt"
  ],
  [
    "Torau",
    "ttu"
  ],
  [
    "Titan",
    "ttv"
  ],
  [
    "Long Wat",
    "ttw"
  ],
  [
    "Sikaritai",
    "tty"
  ],
  [
    "Tsum",
    "ttz"
  ],
  [
    "Wiarumus",
    "tua"
  ],
  [
    "Tübatulabal",
    "tub"
  ],
  [
    "Mutu",
    "tuc"
  ],
  [
    "Tuxá",
    "tud"
  ],
  [
    "Tuyuca",
    "tue"
  ],
  [
    "Central Tunebo",
    "tuf"
  ],
  [
    "Tunia",
    "tug"
  ],
  [
    "Taulil",
    "tuh"
  ],
  [
    "Tupuri",
    "tui"
  ],
  [
    "Tugutil",
    "tuj"
  ],
  [
    "Turkmen",
    "tuk",
    "tuk",
    "tuk",
    "tk",
    "Туркмен / تركمن"
  ],
  [
    "Tula",
    "tul"
  ],
  [
    "Tumbuka",
    "tum",
    "tum",
    "tum"
  ],
  [
    "Tunica",
    "tun"
  ],
  [
    "Tucano",
    "tuo"
  ],
  [
    "Tedaga",
    "tuq"
  ],
  [
    "Turkish",
    "tur",
    "tur",
    "tur",
    "tr",
    "Türkçe"
  ],
  [
    "Tuscarora",
    "tus"
  ],
  [
    "Tututni",
    "tuu"
  ],
  [
    "Turkana",
    "tuv"
  ],
  [
    "Tuxináwa",
    "tux"
  ],
  [
    "Tugen",
    "tuy"
  ],
  [
    "Turka",
    "tuz"
  ],
  [
    "Vaghua",
    "tva"
  ],
  [
    "Tsuvadi",
    "tvd"
  ],
  [
    "Te'un",
    "tve"
  ],
  [
    "Southeast Ambrym",
    "tvk"
  ],
  [
    "Tuvalu",
    "tvl",
    "tvl",
    "tvl"
  ],
  [
    "Tela-Masbuar",
    "tvm"
  ],
  [
    "Tavoyan",
    "tvn"
  ],
  [
    "Tidore",
    "tvo"
  ],
  [
    "Taveta",
    "tvs"
  ],
  [
    "Tutsa Naga",
    "tvt"
  ],
  [
    "Tunen",
    "tvu"
  ],
  [
    "Sedoa",
    "tvw"
  ],
  [
    "Taivoan",
    "tvx"
  ],
  [
    "Timor Pidgin",
    "tvy"
  ],
  [
    "Twana",
    "twa"
  ],
  [
    "Western Tawbuid",
    "twb"
  ],
  [
    "Teshenawa",
    "twc"
  ],
  [
    "Twents",
    "twd"
  ],
  [
    "Tewa (Indonesia)",
    "twe"
  ],
  [
    "Northern Tiwa",
    "twf"
  ],
  [
    "Tereweng",
    "twg"
  ],
  [
    "Tai Dón",
    "twh"
  ],
  [
    "Twi",
    "twi",
    "twi",
    "twi",
    "tw",
    "Twi"
  ],
  [
    "Tawara",
    "twl"
  ],
  [
    "Tawang Monpa",
    "twm"
  ],
  [
    "Twendi",
    "twn"
  ],
  [
    "Tswapong",
    "two"
  ],
  [
    "Ere",
    "twp"
  ],
  [
    "Tasawaq",
    "twq"
  ],
  [
    "Southwestern Tarahumara",
    "twr"
  ],
  [
    "Turiwára",
    "twt"
  ],
  [
    "Termanu",
    "twu"
  ],
  [
    "Tuwari",
    "tww"
  ],
  [
    "Tewe",
    "twx"
  ],
  [
    "Tawoyan",
    "twy"
  ],
  [
    "Tombonuo",
    "txa"
  ],
  [
    "Tokharian B",
    "txb"
  ],
  [
    "Tsetsaut",
    "txc"
  ],
  [
    "Totoli",
    "txe"
  ],
  [
    "Tangut",
    "txg"
  ],
  [
    "Thracian",
    "txh"
  ],
  [
    "Ikpeng",
    "txi"
  ],
  [
    "Tarjumo",
    "txj"
  ],
  [
    "Tomini",
    "txm"
  ],
  [
    "West Tarangan",
    "txn"
  ],
  [
    "Toto",
    "txo"
  ],
  [
    "Tii",
    "txq"
  ],
  [
    "Tartessian",
    "txr"
  ],
  [
    "Tonsea",
    "txs"
  ],
  [
    "Citak",
    "txt"
  ],
  [
    "Kayapó",
    "txu"
  ],
  [
    "Tatana",
    "txx"
  ],
  [
    "Tanosy Malagasy",
    "txy"
  ],
  [
    "Tauya",
    "tya"
  ],
  [
    "Kyanga",
    "tye"
  ],
  [
    "O'du",
    "tyh"
  ],
  [
    "Teke-Tsaayi",
    "tyi"
  ],
  [
    "Tai Do",
    "tyj"
  ],
  [
    "Thu Lao",
    "tyl"
  ],
  [
    "Kombai",
    "tyn"
  ],
  [
    "Thaypan",
    "typ"
  ],
  [
    "Tai Daeng",
    "tyr"
  ],
  [
    "Tày Sa Pa",
    "tys"
  ],
  [
    "Tày Tac",
    "tyt"
  ],
  [
    "Kua",
    "tyu"
  ],
  [
    "Tuvinian",
    "tyv",
    "tyv",
    "tyv"
  ],
  [
    "Teke-Tyee",
    "tyx"
  ],
  [
    "Tiyaa",
    "tyy"
  ],
  [
    "Tày",
    "tyz"
  ],
  [
    "Tanzanian Sign Language",
    "tza"
  ],
  [
    "Tzeltal",
    "tzh"
  ],
  [
    "Tz'utujil",
    "tzj"
  ],
  [
    "Talossan",
    "tzl"
  ],
  [
    "Central Atlas Tamazight",
    "tzm"
  ],
  [
    "Tugun",
    "tzn"
  ],
  [
    "Tzotzil",
    "tzo"
  ],
  [
    "Tabriak",
    "tzx"
  ],
  [
    "Uamué",
    "uam"
  ],
  [
    "Kuan",
    "uan"
  ],
  [
    "Tairuma",
    "uar"
  ],
  [
    "Ubang",
    "uba"
  ],
  [
    "Ubi",
    "ubi"
  ],
  [
    "Buhi'non Bikol",
    "ubl"
  ],
  [
    "Ubir",
    "ubr"
  ],
  [
    "Umbu-Ungu",
    "ubu"
  ],
  [
    "Ubykh",
    "uby"
  ],
  [
    "Uda",
    "uda"
  ],
  [
    "Udihe",
    "ude"
  ],
  [
    "Muduga",
    "udg"
  ],
  [
    "Udi",
    "udi"
  ],
  [
    "Ujir",
    "udj"
  ],
  [
    "Wuzlam",
    "udl"
  ],
  [
    "Udmurt",
    "udm",
    "udm",
    "udm"
  ],
  [
    "Uduk",
    "udu"
  ],
  [
    "Kioko",
    "ues"
  ],
  [
    "Ufim",
    "ufi"
  ],
  [
    "Ugaritic",
    "uga",
    "uga",
    "uga"
  ],
  [
    "Kuku-Ugbanh",
    "ugb"
  ],
  [
    "Ughele",
    "uge"
  ],
  [
    "Kubachi",
    "ugh"
  ],
  [
    "Ugandan Sign Language",
    "ugn"
  ],
  [
    "Ugong",
    "ugo"
  ],
  [
    "Uruguayan Sign Language",
    "ugy"
  ],
  [
    "Uhami",
    "uha"
  ],
  [
    "Damal",
    "uhn"
  ],
  [
    "Uighur",
    "uig",
    "uig",
    "uig",
    "ug",
    "Uyƣurqə / ئۇيغۇرچە"
  ],
  [
    "Uisai",
    "uis"
  ],
  [
    "Iyive",
    "uiv"
  ],
  [
    "Tanjijili",
    "uji"
  ],
  [
    "Kaburi",
    "uka"
  ],
  [
    "Ukuriguma",
    "ukg"
  ],
  [
    "Ukhwejo",
    "ukh"
  ],
  [
    "Kui (India)",
    "uki"
  ],
  [
    "Muak Sa-aak",
    "ukk"
  ],
  [
    "Ukrainian Sign Language",
    "ukl"
  ],
  [
    "Ukpe-Bayobiri",
    "ukp"
  ],
  [
    "Ukwa",
    "ukq"
  ],
  [
    "Ukrainian",
    "ukr",
    "ukr",
    "ukr",
    "uk",
    "Українська"
  ],
  [
    "Urubú-Kaapor Sign Language",
    "uks"
  ],
  [
    "Ukue",
    "uku"
  ],
  [
    "Kuku",
    "ukv"
  ],
  [
    "Ukwuani-Aboh-Ndoni",
    "ukw"
  ],
  [
    "Kuuk-Yak",
    "uky"
  ],
  [
    "Fungwa",
    "ula"
  ],
  [
    "Ulukwumi",
    "ulb"
  ],
  [
    "Ulch",
    "ulc"
  ],
  [
    "Lule",
    "ule"
  ],
  [
    "Usku",
    "ulf"
  ],
  [
    "Ulithian",
    "uli"
  ],
  [
    "Meriam Mir",
    "ulk"
  ],
  [
    "Ullatan",
    "ull"
  ],
  [
    "Ulumanda'",
    "ulm"
  ],
  [
    "Unserdeutsch",
    "uln"
  ],
  [
    "Uma' Lung",
    "ulu"
  ],
  [
    "Ulwa",
    "ulw"
  ],
  [
    "Umatilla",
    "uma"
  ],
  [
    "Umbundu",
    "umb",
    "umb",
    "umb"
  ],
  [
    "Marrucinian",
    "umc"
  ],
  [
    "Umbindhamu",
    "umd"
  ],
  [
    "Morrobalama",
    "umg"
  ],
  [
    "Ukit",
    "umi"
  ],
  [
    "Umon",
    "umm"
  ],
  [
    "Makyan Naga",
    "umn"
  ],
  [
    "Umotína",
    "umo"
  ],
  [
    "Umpila",
    "ump"
  ],
  [
    "Umbugarla",
    "umr"
  ],
  [
    "Pendau",
    "ums"
  ],
  [
    "Munsee",
    "umu"
  ],
  [
    "North Watut",
    "una"
  ],
  [
    "Undetermined",
    "und",
    "und",
    "und"
  ],
  [
    "Uneme",
    "une"
  ],
  [
    "Ngarinyin",
    "ung"
  ],
  [
    "Uni",
    "uni"
  ],
  [
    "Enawené-Nawé",
    "unk"
  ],
  [
    "Unami",
    "unm"
  ],
  [
    "Kurnai",
    "unn"
  ],
  [
    "Mundari",
    "unr"
  ],
  [
    "Unubahe",
    "unu"
  ],
  [
    "Munda",
    "unx"
  ],
  [
    "Unde Kaili",
    "unz"
  ],
  [
    "Kulon",
    "uon"
  ],
  [
    "Umeda",
    "upi"
  ],
  [
    "Uripiv-Wala-Rano-Atchin",
    "upv"
  ],
  [
    "Urarina",
    "ura"
  ],
  [
    "Urubú-Kaapor",
    "urb"
  ],
  [
    "Urningangg",
    "urc"
  ],
  [
    "Urdu",
    "urd",
    "urd",
    "urd",
    "ur",
    "اردو"
  ],
  [
    "Uru",
    "ure"
  ],
  [
    "Uradhi",
    "urf"
  ],
  [
    "Urigina",
    "urg"
  ],
  [
    "Urhobo",
    "urh"
  ],
  [
    "Urim",
    "uri"
  ],
  [
    "Urak Lawoi'",
    "urk"
  ],
  [
    "Urali",
    "url"
  ],
  [
    "Urapmin",
    "urm"
  ],
  [
    "Uruangnirin",
    "urn"
  ],
  [
    "Ura (Papua New Guinea)",
    "uro"
  ],
  [
    "Uru-Pa-In",
    "urp"
  ],
  [
    "Lehalurup",
    "urr"
  ],
  [
    "Urat",
    "urt"
  ],
  [
    "Urumi",
    "uru"
  ],
  [
    "Uruava",
    "urv"
  ],
  [
    "Sop",
    "urw"
  ],
  [
    "Urimo",
    "urx"
  ],
  [
    "Orya",
    "ury"
  ],
  [
    "Uru-Eu-Wau-Wau",
    "urz"
  ],
  [
    "Usarufa",
    "usa"
  ],
  [
    "Ushojo",
    "ush"
  ],
  [
    "Usui",
    "usi"
  ],
  [
    "Usaghade",
    "usk"
  ],
  [
    "Uspanteco",
    "usp"
  ],
  [
    "us-Saare",
    "uss"
  ],
  [
    "Uya",
    "usu"
  ],
  [
    "Otank",
    "uta"
  ],
  [
    "Ute-Southern Paiute",
    "ute"
  ],
  [
    "ut-Hun",
    "uth"
  ],
  [
    "Amba (Solomon Islands)",
    "utp"
  ],
  [
    "Etulo",
    "utr"
  ],
  [
    "Utu",
    "utu"
  ],
  [
    "Urum",
    "uum"
  ],
  [
    "Ura (Vanuatu)",
    "uur"
  ],
  [
    "U",
    "uuu"
  ],
  [
    "West Uvean",
    "uve"
  ],
  [
    "Uri",
    "uvh"
  ],
  [
    "Lote",
    "uvl"
  ],
  [
    "Kuku-Uwanh",
    "uwa"
  ],
  [
    "Doko-Uyanga",
    "uya"
  ],
  [
    "Uzbek",
    "uzb",
    "uzb",
    "uzb",
    "uz",
    "Ўзбек"
  ],
  [
    "Northern Uzbek",
    "uzn"
  ],
  [
    "Southern Uzbek",
    "uzs"
  ],
  [
    "Vaagri Booli",
    "vaa"
  ],
  [
    "Vale",
    "vae"
  ],
  [
    "Vafsi",
    "vaf"
  ],
  [
    "Vagla",
    "vag"
  ],
  [
    "Varhadi-Nagpuri",
    "vah"
  ],
  [
    "Vai",
    "vai",
    "vai",
    "vai"
  ],
  [
    "Sekele",
    "vaj"
  ],
  [
    "Vehes",
    "val"
  ],
  [
    "Vanimo",
    "vam"
  ],
  [
    "Valman",
    "van"
  ],
  [
    "Vao",
    "vao"
  ],
  [
    "Vaiphei",
    "vap"
  ],
  [
    "Huarijio",
    "var"
  ],
  [
    "Vasavi",
    "vas"
  ],
  [
    "Vanuma",
    "vau"
  ],
  [
    "Varli",
    "vav"
  ],
  [
    "Wayu",
    "vay"
  ],
  [
    "Southeast Babar",
    "vbb"
  ],
  [
    "Southwestern Bontok",
    "vbk"
  ],
  [
    "Venetian",
    "vec"
  ],
  [
    "Veddah",
    "ved"
  ],
  [
    "Veluws",
    "vel"
  ],
  [
    "Vemgo-Mabas",
    "vem"
  ],
  [
    "Venda",
    "ven",
    "ven",
    "ven",
    "ve",
    "Tshivenḓa"
  ],
  [
    "Ventureño",
    "veo"
  ],
  [
    "Veps",
    "vep"
  ],
  [
    "Mom Jango",
    "ver"
  ],
  [
    "Vaghri",
    "vgr"
  ],
  [
    "Vlaamse Gebarentaal",
    "vgt"
  ],
  [
    "Virgin Islands Creole English",
    "vic"
  ],
  [
    "Vidunda",
    "vid"
  ],
  [
    "Vietnamese",
    "vie",
    "vie",
    "vie",
    "vi",
    "Việtnam"
  ],
  [
    "Vili",
    "vif"
  ],
  [
    "Viemo",
    "vig"
  ],
  [
    "Vilela",
    "vil"
  ],
  [
    "Vinza",
    "vin"
  ],
  [
    "Vishavan",
    "vis"
  ],
  [
    "Viti",
    "vit"
  ],
  [
    "Iduna",
    "viv"
  ],
  [
    "Kariyarra",
    "vka"
  ],
  [
    "Kujarge",
    "vkj"
  ],
  [
    "Kaur",
    "vkk"
  ],
  [
    "Kulisusu",
    "vkl"
  ],
  [
    "Kamakan",
    "vkm"
  ],
  [
    "Koro Nulu",
    "vkn"
  ],
  [
    "Kodeoha",
    "vko"
  ],
  [
    "Korlai Creole Portuguese",
    "vkp"
  ],
  [
    "Tenggarong Kutai Malay",
    "vkt"
  ],
  [
    "Kurrama",
    "vku"
  ],
  [
    "Koro Zuba",
    "vkz"
  ],
  [
    "Valpei",
    "vlp"
  ],
  [
    "Vlaams",
    "vls"
  ],
  [
    "Martuyhunira",
    "vma"
  ],
  [
    "Barbaram",
    "vmb"
  ],
  [
    "Juxtlahuaca Mixtec",
    "vmc"
  ],
  [
    "Mudu Koraga",
    "vmd"
  ],
  [
    "East Masela",
    "vme"
  ],
  [
    "Mainfränkisch",
    "vmf"
  ],
  [
    "Lungalunga",
    "vmg"
  ],
  [
    "Maraghei",
    "vmh"
  ],
  [
    "Miwa",
    "vmi"
  ],
  [
    "Ixtayutla Mixtec",
    "vmj"
  ],
  [
    "Makhuwa-Shirima",
    "vmk"
  ],
  [
    "Malgana",
    "vml"
  ],
  [
    "Mitlatongo Mixtec",
    "vmm"
  ],
  [
    "Soyaltepec Mazatec",
    "vmp"
  ],
  [
    "Soyaltepec Mixtec",
    "vmq"
  ],
  [
    "Marenje",
    "vmr"
  ],
  [
    "Moksela",
    "vms"
  ],
  [
    "Muluridyi",
    "vmu"
  ],
  [
    "Valley Maidu",
    "vmv"
  ],
  [
    "Makhuwa",
    "vmw"
  ],
  [
    "Tamazola Mixtec",
    "vmx"
  ],
  [
    "Ayautla Mazatec",
    "vmy"
  ],
  [
    "Mazatlán Mazatec",
    "vmz"
  ],
  [
    "Vano",
    "vnk"
  ],
  [
    "Vinmavis",
    "vnm"
  ],
  [
    "Vunapu",
    "vnp"
  ],
  [
    "Volapük",
    "vol",
    "vol",
    "vol",
    "vo",
    "Volapük"
  ],
  [
    "Voro",
    "vor"
  ],
  [
    "Votic",
    "vot",
    "vot",
    "vot"
  ],
  [
    "Vera'a",
    "vra"
  ],
  [
    "Võro",
    "vro"
  ],
  [
    "Varisi",
    "vrs"
  ],
  [
    "Burmbar",
    "vrt"
  ],
  [
    "Moldova Sign Language",
    "vsi"
  ],
  [
    "Venezuelan Sign Language",
    "vsl"
  ],
  [
    "Valencian Sign Language",
    "vsv"
  ],
  [
    "Vitou",
    "vto"
  ],
  [
    "Vumbu",
    "vum"
  ],
  [
    "Vunjo",
    "vun"
  ],
  [
    "Vute",
    "vut"
  ],
  [
    "Awa (China)",
    "vwa"
  ],
  [
    "Walla Walla",
    "waa"
  ],
  [
    "Wab",
    "wab"
  ],
  [
    "Wasco-Wishram",
    "wac"
  ],
  [
    "Wamesa",
    "wad"
  ],
  [
    "Walser",
    "wae"
  ],
  [
    "Wakoná",
    "waf"
  ],
  [
    "Wa'ema",
    "wag"
  ],
  [
    "Watubela",
    "wah"
  ],
  [
    "Wares",
    "wai"
  ],
  [
    "Waffa",
    "waj"
  ],
  [
    "Wolaytta",
    "wal",
    "wal",
    "wal"
  ],
  [
    "Wampanoag",
    "wam"
  ],
  [
    "Wan",
    "wan"
  ],
  [
    "Wappo",
    "wao"
  ],
  [
    "Wapishana",
    "wap"
  ],
  [
    "Wagiman",
    "waq"
  ],
  [
    "Waray (Philippines)",
    "war",
    "war",
    "war"
  ],
  [
    "Washo",
    "was",
    "was",
    "was"
  ],
  [
    "Kaninuwa",
    "wat"
  ],
  [
    "Waurá",
    "wau"
  ],
  [
    "Waka",
    "wav"
  ],
  [
    "Waiwai",
    "waw"
  ],
  [
    "Watam",
    "wax"
  ],
  [
    "Wayana",
    "way"
  ],
  [
    "Wampur",
    "waz"
  ],
  [
    "Warao",
    "wba"
  ],
  [
    "Wabo",
    "wbb"
  ],
  [
    "Waritai",
    "wbe"
  ],
  [
    "Wara",
    "wbf"
  ],
  [
    "Wanda",
    "wbh"
  ],
  [
    "Vwanji",
    "wbi"
  ],
  [
    "Alagwa",
    "wbj"
  ],
  [
    "Waigali",
    "wbk"
  ],
  [
    "Wakhi",
    "wbl"
  ],
  [
    "Wa",
    "wbm"
  ],
  [
    "Warlpiri",
    "wbp"
  ],
  [
    "Waddar",
    "wbq"
  ],
  [
    "Wagdi",
    "wbr"
  ],
  [
    "West Bengal Sign Language",
    "wbs"
  ],
  [
    "Warnman",
    "wbt"
  ],
  [
    "Wajarri",
    "wbv"
  ],
  [
    "Woi",
    "wbw"
  ],
  [
    "Yanomámi",
    "wca"
  ],
  [
    "Waci Gbe",
    "wci"
  ],
  [
    "Wandji",
    "wdd"
  ],
  [
    "Wadaginam",
    "wdg"
  ],
  [
    "Wadjiginy",
    "wdj"
  ],
  [
    "Wadikali",
    "wdk"
  ],
  [
    "Wendat",
    "wdt"
  ],
  [
    "Wadjigu",
    "wdu"
  ],
  [
    "Wadjabangayi",
    "wdy"
  ],
  [
    "Wewaw",
    "wea"
  ],
  [
    "Wè Western",
    "wec"
  ],
  [
    "Wedau",
    "wed"
  ],
  [
    "Wergaia",
    "weg"
  ],
  [
    "Weh",
    "weh"
  ],
  [
    "Kiunum",
    "wei"
  ],
  [
    "Weme Gbe",
    "wem"
  ],
  [
    "Wemale",
    "weo"
  ],
  [
    "Westphalien",
    "wep"
  ],
  [
    "Weri",
    "wer"
  ],
  [
    "Cameroon Pidgin",
    "wes"
  ],
  [
    "Perai",
    "wet"
  ],
  [
    "Rawngtu Chin",
    "weu"
  ],
  [
    "Wejewa",
    "wew"
  ],
  [
    "Yafi",
    "wfg"
  ],
  [
    "Wagaya",
    "wga"
  ],
  [
    "Wagawaga",
    "wgb"
  ],
  [
    "Wangkangurru",
    "wgg"
  ],
  [
    "Wahgi",
    "wgi"
  ],
  [
    "Waigeo",
    "wgo"
  ],
  [
    "Wirangu",
    "wgu"
  ],
  [
    "Warrgamay",
    "wgy"
  ],
  [
    "Sou Upaa",
    "wha"
  ],
  [
    "North Wahgi",
    "whg"
  ],
  [
    "Wahau Kenyah",
    "whk"
  ],
  [
    "Wahau Kayan",
    "whu"
  ],
  [
    "Southern Toussian",
    "wib"
  ],
  [
    "Wichita",
    "wic"
  ],
  [
    "Wik-Epa",
    "wie"
  ],
  [
    "Wik-Keyangan",
    "wif"
  ],
  [
    "Wik Ngathan",
    "wig"
  ],
  [
    "Wik-Me'anha",
    "wih"
  ],
  [
    "Minidien",
    "wii"
  ],
  [
    "Wik-Iiyanh",
    "wij"
  ],
  [
    "Wikalkan",
    "wik"
  ],
  [
    "Wilawila",
    "wil"
  ],
  [
    "Wik-Mungkan",
    "wim"
  ],
  [
    "Ho-Chunk",
    "win"
  ],
  [
    "Wiraféd",
    "wir"
  ],
  [
    "Wiru",
    "wiu"
  ],
  [
    "Vitu",
    "wiv"
  ],
  [
    "Wiyot",
    "wiy"
  ],
  [
    "Waja",
    "wja"
  ],
  [
    "Warji",
    "wji"
  ],
  [
    "Kw'adza",
    "wka"
  ],
  [
    "Kumbaran",
    "wkb"
  ],
  [
    "Wakde",
    "wkd"
  ],
  [
    "Kalanadi",
    "wkl"
  ],
  [
    "Keerray-Woorroong",
    "wkr"
  ],
  [
    "Kunduvadi",
    "wku"
  ],
  [
    "Wakawaka",
    "wkw"
  ],
  [
    "Wangkayutyuru",
    "wky"
  ],
  [
    "Walio",
    "wla"
  ],
  [
    "Mwali Comorian",
    "wlc"
  ],
  [
    "Wolane",
    "wle"
  ],
  [
    "Kunbarlang",
    "wlg"
  ],
  [
    "Welaun",
    "wlh"
  ],
  [
    "Waioli",
    "wli"
  ],
  [
    "Wailaki",
    "wlk"
  ],
  [
    "Wali (Sudan)",
    "wll"
  ],
  [
    "Middle Welsh",
    "wlm"
  ],
  [
    "Walloon",
    "wln",
    "wln",
    "wln",
    "wa",
    "Walon"
  ],
  [
    "Wolio",
    "wlo"
  ],
  [
    "Wailapa",
    "wlr"
  ],
  [
    "Wallisian",
    "wls"
  ],
  [
    "Wuliwuli",
    "wlu"
  ],
  [
    "Wichí Lhamtés Vejoz",
    "wlv"
  ],
  [
    "Walak",
    "wlw"
  ],
  [
    "Wali (Ghana)",
    "wlx"
  ],
  [
    "Waling",
    "wly"
  ],
  [
    "Mawa (Nigeria)",
    "wma"
  ],
  [
    "Wambaya",
    "wmb"
  ],
  [
    "Wamas",
    "wmc"
  ],
  [
    "Mamaindé",
    "wmd"
  ],
  [
    "Wambule",
    "wme"
  ],
  [
    "Western Minyag",
    "wmg"
  ],
  [
    "Waima'a",
    "wmh"
  ],
  [
    "Wamin",
    "wmi"
  ],
  [
    "Maiwa (Indonesia)",
    "wmm"
  ],
  [
    "Waamwang",
    "wmn"
  ],
  [
    "Wom (Papua New Guinea)",
    "wmo"
  ],
  [
    "Wambon",
    "wms"
  ],
  [
    "Walmajarri",
    "wmt"
  ],
  [
    "Mwani",
    "wmw"
  ],
  [
    "Womo",
    "wmx"
  ],
  [
    "Wanambre",
    "wnb"
  ],
  [
    "Wantoat",
    "wnc"
  ],
  [
    "Wandarang",
    "wnd"
  ],
  [
    "Waneci",
    "wne"
  ],
  [
    "Wanggom",
    "wng"
  ],
  [
    "Ndzwani Comorian",
    "wni"
  ],
  [
    "Wanukaka",
    "wnk"
  ],
  [
    "Wanggamala",
    "wnm"
  ],
  [
    "Wunumara",
    "wnn"
  ],
  [
    "Wano",
    "wno"
  ],
  [
    "Wanap",
    "wnp"
  ],
  [
    "Usan",
    "wnu"
  ],
  [
    "Wintu",
    "wnw"
  ],
  [
    "Wanyi",
    "wny"
  ],
  [
    "Kuwema",
    "woa"
  ],
  [
    "Wè Northern",
    "wob"
  ],
  [
    "Wogeo",
    "woc"
  ],
  [
    "Wolani",
    "wod"
  ],
  [
    "Woleaian",
    "woe"
  ],
  [
    "Gambian Wolof",
    "wof"
  ],
  [
    "Wogamusin",
    "wog"
  ],
  [
    "Kamang",
    "woi"
  ],
  [
    "Longto",
    "wok"
  ],
  [
    "Wolof",
    "wol",
    "wol",
    "wol",
    "wo",
    "Wollof"
  ],
  [
    "Wom (Nigeria)",
    "wom"
  ],
  [
    "Wongo",
    "won"
  ],
  [
    "Manombai",
    "woo"
  ],
  [
    "Woria",
    "wor"
  ],
  [
    "Hanga Hundi",
    "wos"
  ],
  [
    "Wawonii",
    "wow"
  ],
  [
    "Weyto",
    "woy"
  ],
  [
    "Maco",
    "wpc"
  ],
  [
    "Waluwarra",
    "wrb"
  ],
  [
    "Warungu",
    "wrg"
  ],
  [
    "Wiradjuri",
    "wrh"
  ],
  [
    "Wariyangga",
    "wri"
  ],
  [
    "Garrwa",
    "wrk"
  ],
  [
    "Warlmanpa",
    "wrl"
  ],
  [
    "Warumungu",
    "wrm"
  ],
  [
    "Warnang",
    "wrn"
  ],
  [
    "Worrorra",
    "wro"
  ],
  [
    "Waropen",
    "wrp"
  ],
  [
    "Wardaman",
    "wrr"
  ],
  [
    "Waris",
    "wrs"
  ],
  [
    "Waru",
    "wru"
  ],
  [
    "Waruna",
    "wrv"
  ],
  [
    "Gugu Warra",
    "wrw"
  ],
  [
    "Wae Rana",
    "wrx"
  ],
  [
    "Merwari",
    "wry"
  ],
  [
    "Waray (Australia)",
    "wrz"
  ],
  [
    "Warembori",
    "wsa"
  ],
  [
    "Adilabad Gondi",
    "wsg"
  ],
  [
    "Wusi",
    "wsi"
  ],
  [
    "Waskia",
    "wsk"
  ],
  [
    "Owenia",
    "wsr"
  ],
  [
    "Wasa",
    "wss"
  ],
  [
    "Wasu",
    "wsu"
  ],
  [
    "Wotapuri-Katarqalai",
    "wsv"
  ],
  [
    "Watiwa",
    "wtf"
  ],
  [
    "Wathawurrung",
    "wth"
  ],
  [
    "Berta",
    "wti"
  ],
  [
    "Watakataui",
    "wtk"
  ],
  [
    "Mewati",
    "wtm"
  ],
  [
    "Wotu",
    "wtw"
  ],
  [
    "Wikngenchera",
    "wua"
  ],
  [
    "Wunambal",
    "wub"
  ],
  [
    "Wudu",
    "wud"
  ],
  [
    "Wutunhua",
    "wuh"
  ],
  [
    "Silimo",
    "wul"
  ],
  [
    "Wumbvu",
    "wum"
  ],
  [
    "Bungu",
    "wun"
  ],
  [
    "Wurrugu",
    "wur"
  ],
  [
    "Wutung",
    "wut"
  ],
  [
    "Wu Chinese",
    "wuu"
  ],
  [
    "Wuvulu-Aua",
    "wuv"
  ],
  [
    "Wulna",
    "wux"
  ],
  [
    "Wauyai",
    "wuy"
  ],
  [
    "Waama",
    "wwa"
  ],
  [
    "Wakabunga",
    "wwb"
  ],
  [
    "Wetamut",
    "wwo"
  ],
  [
    "Warrwa",
    "wwr"
  ],
  [
    "Wawa",
    "www"
  ],
  [
    "Waxianghua",
    "wxa"
  ],
  [
    "Wardandi",
    "wxw"
  ],
  [
    "Wangaaybuwan-Ngiyambaa",
    "wyb"
  ],
  [
    "Woiwurrung",
    "wyi"
  ],
  [
    "Wymysorys",
    "wym"
  ],
  [
    "Wyandot",
    "wyn"
  ],
  [
    "Wayoró",
    "wyr"
  ],
  [
    "Western Fijian",
    "wyy"
  ],
  [
    "Andalusian Arabic",
    "xaa"
  ],
  [
    "Sambe",
    "xab"
  ],
  [
    "Kachari",
    "xac"
  ],
  [
    "Adai",
    "xad"
  ],
  [
    "Aequian",
    "xae"
  ],
  [
    "Aghwan",
    "xag"
  ],
  [
    "Kaimbé",
    "xai"
  ],
  [
    "Ararandewára",
    "xaj"
  ],
  [
    "Máku",
    "xak"
  ],
  [
    "Kalmyk",
    "xal",
    "xal",
    "xal"
  ],
  [
    "ǀXam",
    "xam"
  ],
  [
    "Xamtanga",
    "xan"
  ],
  [
    "Khao",
    "xao"
  ],
  [
    "Apalachee",
    "xap"
  ],
  [
    "Aquitanian",
    "xaq"
  ],
  [
    "Karami",
    "xar"
  ],
  [
    "Kamas",
    "xas"
  ],
  [
    "Katawixi",
    "xat"
  ],
  [
    "Kauwera",
    "xau"
  ],
  [
    "Xavánte",
    "xav"
  ],
  [
    "Kawaiisu",
    "xaw"
  ],
  [
    "Kayan Mahakam",
    "xay"
  ],
  [
    "Lower Burdekin",
    "xbb"
  ],
  [
    "Bactrian",
    "xbc"
  ],
  [
    "Bindal",
    "xbd"
  ],
  [
    "Bigambal",
    "xbe"
  ],
  [
    "Bunganditj",
    "xbg"
  ],
  [
    "Kombio",
    "xbi"
  ],
  [
    "Birrpayi",
    "xbj"
  ],
  [
    "Middle Breton",
    "xbm"
  ],
  [
    "Kenaboi",
    "xbn"
  ],
  [
    "Bolgarian",
    "xbo"
  ],
  [
    "Bibbulman",
    "xbp"
  ],
  [
    "Kambera",
    "xbr"
  ],
  [
    "Kambiwá",
    "xbw"
  ],
  [
    "Batjala",
    "xby"
  ],
  [
    "Cumbric",
    "xcb"
  ],
  [
    "Camunic",
    "xcc"
  ],
  [
    "Celtiberian",
    "xce"
  ],
  [
    "Cisalpine Gaulish",
    "xcg"
  ],
  [
    "Chemakum",
    "xch"
  ],
  [
    "Classical Armenian",
    "xcl"
  ],
  [
    "Comecrudo",
    "xcm"
  ],
  [
    "Cotoname",
    "xcn"
  ],
  [
    "Chorasmian",
    "xco"
  ],
  [
    "Carian",
    "xcr"
  ],
  [
    "Classical Tibetan",
    "xct"
  ],
  [
    "Curonian",
    "xcu"
  ],
  [
    "Chuvantsy",
    "xcv"
  ],
  [
    "Coahuilteco",
    "xcw"
  ],
  [
    "Cayuse",
    "xcy"
  ],
  [
    "Darkinyung",
    "xda"
  ],
  [
    "Dacian",
    "xdc"
  ],
  [
    "Dharuk",
    "xdk"
  ],
  [
    "Edomite",
    "xdm"
  ],
  [
    "Kwandu",
    "xdo"
  ],
  [
    "Kaitag",
    "xdq"
  ],
  [
    "Malayic Dayak",
    "xdy"
  ],
  [
    "Eblan",
    "xeb"
  ],
  [
    "Hdi",
    "xed"
  ],
  [
    "ǁXegwi",
    "xeg"
  ],
  [
    "Kelo",
    "xel"
  ],
  [
    "Kembayan",
    "xem"
  ],
  [
    "Epi-Olmec",
    "xep"
  ],
  [
    "Xerénte",
    "xer"
  ],
  [
    "Kesawai",
    "xes"
  ],
  [
    "Xetá",
    "xet"
  ],
  [
    "Keoru-Ahia",
    "xeu"
  ],
  [
    "Faliscan",
    "xfa"
  ],
  [
    "Galatian",
    "xga"
  ],
  [
    "Gbin",
    "xgb"
  ],
  [
    "Gudang",
    "xgd"
  ],
  [
    "Gabrielino-Fernandeño",
    "xgf"
  ],
  [
    "Goreng",
    "xgg"
  ],
  [
    "Garingbal",
    "xgi"
  ],
  [
    "Galindan",
    "xgl"
  ],
  [
    "Dharumbal",
    "xgm"
  ],
  [
    "Garza",
    "xgr"
  ],
  [
    "Unggumi",
    "xgu"
  ],
  [
    "Guwa",
    "xgw"
  ],
  [
    "Harami",
    "xha"
  ],
  [
    "Hunnic",
    "xhc"
  ],
  [
    "Hadrami",
    "xhd"
  ],
  [
    "Khetrani",
    "xhe"
  ],
  [
    "Middle Khmer (1400 to 1850 CE)",
    "xhm"
  ],
  [
    "Xhosa",
    "xho",
    "xho",
    "xho",
    "xh",
    "isiXhosa"
  ],
  [
    "Hernican",
    "xhr"
  ],
  [
    "Hattic",
    "xht"
  ],
  [
    "Hurrian",
    "xhu"
  ],
  [
    "Khua",
    "xhv"
  ],
  [
    "Iberian",
    "xib"
  ],
  [
    "Xiri",
    "xii"
  ],
  [
    "Illyrian",
    "xil"
  ],
  [
    "Xinca",
    "xin"
  ],
  [
    "Xiriâna",
    "xir"
  ],
  [
    "Kisan",
    "xis"
  ],
  [
    "Indus Valley Language",
    "xiv"
  ],
  [
    "Xipaya",
    "xiy"
  ],
  [
    "Minjungbal",
    "xjb"
  ],
  [
    "Jaitmatang",
    "xjt"
  ],
  [
    "Kalkoti",
    "xka"
  ],
  [
    "Northern Nago",
    "xkb"
  ],
  [
    "Kho'ini",
    "xkc"
  ],
  [
    "Mendalam Kayan",
    "xkd"
  ],
  [
    "Kereho",
    "xke"
  ],
  [
    "Khengkha",
    "xkf"
  ],
  [
    "Kagoro",
    "xkg"
  ],
  [
    "Kenyan Sign Language",
    "xki"
  ],
  [
    "Kajali",
    "xkj"
  ],
  [
    "Kachok",
    "xkk"
  ],
  [
    "Mainstream Kenyah",
    "xkl"
  ],
  [
    "Kayan River Kayan",
    "xkn"
  ],
  [
    "Kiorr",
    "xko"
  ],
  [
    "Kabatei",
    "xkp"
  ],
  [
    "Koroni",
    "xkq"
  ],
  [
    "Xakriabá",
    "xkr"
  ],
  [
    "Kumbewaha",
    "xks"
  ],
  [
    "Kantosi",
    "xkt"
  ],
  [
    "Kaamba",
    "xku"
  ],
  [
    "Kgalagadi",
    "xkv"
  ],
  [
    "Kembra",
    "xkw"
  ],
  [
    "Karore",
    "xkx"
  ],
  [
    "Uma' Lasan",
    "xky"
  ],
  [
    "Kurtokha",
    "xkz"
  ],
  [
    "Kamula",
    "xla"
  ],
  [
    "Loup B",
    "xlb"
  ],
  [
    "Lycian",
    "xlc"
  ],
  [
    "Lydian",
    "xld"
  ],
  [
    "Lemnian",
    "xle"
  ],
  [
    "Ligurian (Ancient)",
    "xlg"
  ],
  [
    "Liburnian",
    "xli"
  ],
  [
    "Alanic",
    "xln"
  ],
  [
    "Loup A",
    "xlo"
  ],
  [
    "Lepontic",
    "xlp"
  ],
  [
    "Lusitanian",
    "xls"
  ],
  [
    "Cuneiform Luwian",
    "xlu"
  ],
  [
    "Elymian",
    "xly"
  ],
  [
    "Mushungulu",
    "xma"
  ],
  [
    "Mbonga",
    "xmb"
  ],
  [
    "Makhuwa-Marrevone",
    "xmc"
  ],
  [
    "Mbudum",
    "xmd"
  ],
  [
    "Median",
    "xme"
  ],
  [
    "Mingrelian",
    "xmf"
  ],
  [
    "Mengaka",
    "xmg"
  ],
  [
    "Kugu-Muminh",
    "xmh"
  ],
  [
    "Majera",
    "xmj"
  ],
  [
    "Ancient Macedonian",
    "xmk"
  ],
  [
    "Malaysian Sign Language",
    "xml"
  ],
  [
    "Manado Malay",
    "xmm"
  ],
  [
    "Manichaean Middle Persian",
    "xmn"
  ],
  [
    "Morerebi",
    "xmo"
  ],
  [
    "Kuku-Mu'inh",
    "xmp"
  ],
  [
    "Kuku-Mangk",
    "xmq"
  ],
  [
    "Meroitic",
    "xmr"
  ],
  [
    "Moroccan Sign Language",
    "xms"
  ],
  [
    "Matbat",
    "xmt"
  ],
  [
    "Kamu",
    "xmu"
  ],
  [
    "Antankarana Malagasy",
    "xmv"
  ],
  [
    "Tsimihety Malagasy",
    "xmw"
  ],
  [
    "Salawati",
    "xmx"
  ],
  [
    "Mayaguduna",
    "xmy"
  ],
  [
    "Mori Bawah",
    "xmz"
  ],
  [
    "Ancient North Arabian",
    "xna"
  ],
  [
    "Kanakanabu",
    "xnb"
  ],
  [
    "Middle Mongolian",
    "xng"
  ],
  [
    "Kuanhua",
    "xnh"
  ],
  [
    "Ngarigu",
    "xni"
  ],
  [
    "Ngoni (Tanzania)",
    "xnj"
  ],
  [
    "Nganakarti",
    "xnk"
  ],
  [
    "Ngumbarl",
    "xnm"
  ],
  [
    "Northern Kankanay",
    "xnn"
  ],
  [
    "Anglo-Norman",
    "xno"
  ],
  [
    "Ngoni (Mozambique)",
    "xnq"
  ],
  [
    "Kangri",
    "xnr"
  ],
  [
    "Kanashi",
    "xns"
  ],
  [
    "Narragansett",
    "xnt"
  ],
  [
    "Nukunul",
    "xnu"
  ],
  [
    "Nyiyaparli",
    "xny"
  ],
  [
    "Kenzi",
    "xnz"
  ],
  [
    "O'chi'chi'",
    "xoc"
  ],
  [
    "Kokoda",
    "xod"
  ],
  [
    "Soga",
    "xog"
  ],
  [
    "Kominimung",
    "xoi"
  ],
  [
    "Xokleng",
    "xok"
  ],
  [
    "Komo (Sudan)",
    "xom"
  ],
  [
    "Konkomba",
    "xon"
  ],
  [
    "Xukurú",
    "xoo"
  ],
  [
    "Kopar",
    "xop"
  ],
  [
    "Korubo",
    "xor"
  ],
  [
    "Kowaki",
    "xow"
  ],
  [
    "Pirriya",
    "xpa"
  ],
  [
    "Northeastern Tasmanian",
    "xpb"
  ],
  [
    "Pecheneg",
    "xpc"
  ],
  [
    "Oyster Bay Tasmanian",
    "xpd"
  ],
  [
    "Liberia Kpelle",
    "xpe"
  ],
  [
    "Southeast Tasmanian",
    "xpf"
  ],
  [
    "Phrygian",
    "xpg"
  ],
  [
    "North Midlands Tasmanian",
    "xph"
  ],
  [
    "Pictish",
    "xpi"
  ],
  [
    "Mpalitjanh",
    "xpj"
  ],
  [
    "Kulina Pano",
    "xpk"
  ],
  [
    "Port Sorell Tasmanian",
    "xpl"
  ],
  [
    "Pumpokol",
    "xpm"
  ],
  [
    "Kapinawá",
    "xpn"
  ],
  [
    "Pochutec",
    "xpo"
  ],
  [
    "Puyo-Paekche",
    "xpp"
  ],
  [
    "Mohegan-Pequot",
    "xpq"
  ],
  [
    "Parthian",
    "xpr"
  ],
  [
    "Pisidian",
    "xps"
  ],
  [
    "Punthamara",
    "xpt"
  ],
  [
    "Punic",
    "xpu"
  ],
  [
    "Northern Tasmanian",
    "xpv"
  ],
  [
    "Northwestern Tasmanian",
    "xpw"
  ],
  [
    "Southwestern Tasmanian",
    "xpx"
  ],
  [
    "Puyo",
    "xpy"
  ],
  [
    "Bruny Island Tasmanian",
    "xpz"
  ],
  [
    "Karakhanid",
    "xqa"
  ],
  [
    "Qatabanian",
    "xqt"
  ],
  [
    "Krahô",
    "xra"
  ],
  [
    "Eastern Karaboro",
    "xrb"
  ],
  [
    "Gundungurra",
    "xrd"
  ],
  [
    "Kreye",
    "xre"
  ],
  [
    "Minang",
    "xrg"
  ],
  [
    "Krikati-Timbira",
    "xri"
  ],
  [
    "Armazic",
    "xrm"
  ],
  [
    "Arin",
    "xrn"
  ],
  [
    "Raetic",
    "xrr"
  ],
  [
    "Aranama-Tamique",
    "xrt"
  ],
  [
    "Marriammu",
    "xru"
  ],
  [
    "Karawa",
    "xrw"
  ],
  [
    "Sabaean",
    "xsa"
  ],
  [
    "Sambal",
    "xsb"
  ],
  [
    "Scythian",
    "xsc"
  ],
  [
    "Sidetic",
    "xsd"
  ],
  [
    "Sempan",
    "xse"
  ],
  [
    "Shamang",
    "xsh"
  ],
  [
    "Sio",
    "xsi"
  ],
  [
    "Subi",
    "xsj"
  ],
  [
    "South Slavey",
    "xsl"
  ],
  [
    "Kasem",
    "xsm"
  ],
  [
    "Sanga (Nigeria)",
    "xsn"
  ],
  [
    "Solano",
    "xso"
  ],
  [
    "Silopi",
    "xsp"
  ],
  [
    "Makhuwa-Saka",
    "xsq"
  ],
  [
    "Sherpa",
    "xsr"
  ],
  [
    "Assan",
    "xss"
  ],
  [
    "Sanumá",
    "xsu"
  ],
  [
    "Sudovian",
    "xsv"
  ],
  [
    "Saisiyat",
    "xsy"
  ],
  [
    "Alcozauca Mixtec",
    "xta"
  ],
  [
    "Chazumba Mixtec",
    "xtb"
  ],
  [
    "Katcha-Kadugli-Miri",
    "xtc"
  ],
  [
    "Diuxi-Tilantongo Mixtec",
    "xtd"
  ],
  [
    "Ketengban",
    "xte"
  ],
  [
    "Transalpine Gaulish",
    "xtg"
  ],
  [
    "Yitha Yitha",
    "xth"
  ],
  [
    "Sinicahua Mixtec",
    "xti"
  ],
  [
    "San Juan Teita Mixtec",
    "xtj"
  ],
  [
    "Tijaltepec Mixtec",
    "xtl"
  ],
  [
    "Magdalena Peñasco Mixtec",
    "xtm"
  ],
  [
    "Northern Tlaxiaco Mixtec",
    "xtn"
  ],
  [
    "Tokharian A",
    "xto"
  ],
  [
    "San Miguel Piedras Mixtec",
    "xtp"
  ],
  [
    "Tumshuqese",
    "xtq"
  ],
  [
    "Early Tripuri",
    "xtr"
  ],
  [
    "Sindihui Mixtec",
    "xts"
  ],
  [
    "Tacahua Mixtec",
    "xtt"
  ],
  [
    "Cuyamecalco Mixtec",
    "xtu"
  ],
  [
    "Thawa",
    "xtv"
  ],
  [
    "Tawandê",
    "xtw"
  ],
  [
    "Yoloxochitl Mixtec",
    "xty"
  ],
  [
    "Alu Kurumba",
    "xua"
  ],
  [
    "Betta Kurumba",
    "xub"
  ],
  [
    "Umiida",
    "xud"
  ],
  [
    "Kunigami",
    "xug"
  ],
  [
    "Jennu Kurumba",
    "xuj"
  ],
  [
    "Ngunawal",
    "xul"
  ],
  [
    "Umbrian",
    "xum"
  ],
  [
    "Unggaranggu",
    "xun"
  ],
  [
    "Kuo",
    "xuo"
  ],
  [
    "Upper Umpqua",
    "xup"
  ],
  [
    "Urartian",
    "xur"
  ],
  [
    "Kuthant",
    "xut"
  ],
  [
    "Kxoe",
    "xuu"
  ],
  [
    "Venetic",
    "xve"
  ],
  [
    "Kamviri",
    "xvi"
  ],
  [
    "Vandalic",
    "xvn"
  ],
  [
    "Volscian",
    "xvo"
  ],
  [
    "Vestinian",
    "xvs"
  ],
  [
    "Kwaza",
    "xwa"
  ],
  [
    "Woccon",
    "xwc"
  ],
  [
    "Wadi Wadi",
    "xwd"
  ],
  [
    "Xwela Gbe",
    "xwe"
  ],
  [
    "Kwegu",
    "xwg"
  ],
  [
    "Wajuk",
    "xwj"
  ],
  [
    "Wangkumara",
    "xwk"
  ],
  [
    "Western Xwla Gbe",
    "xwl"
  ],
  [
    "Written Oirat",
    "xwo"
  ],
  [
    "Kwerba Mamberamo",
    "xwr"
  ],
  [
    "Wotjobaluk",
    "xwt"
  ],
  [
    "Wemba Wemba",
    "xww"
  ],
  [
    "Boro (Ghana)",
    "xxb"
  ],
  [
    "Ke'o",
    "xxk"
  ],
  [
    "Minkin",
    "xxm"
  ],
  [
    "Koropó",
    "xxr"
  ],
  [
    "Tambora",
    "xxt"
  ],
  [
    "Yaygir",
    "xya"
  ],
  [
    "Yandjibara",
    "xyb"
  ],
  [
    "Mayi-Yapi",
    "xyj"
  ],
  [
    "Mayi-Kulan",
    "xyk"
  ],
  [
    "Yalakalore",
    "xyl"
  ],
  [
    "Mayi-Thakurti",
    "xyt"
  ],
  [
    "Yorta Yorta",
    "xyy"
  ],
  [
    "Zhang-Zhung",
    "xzh"
  ],
  [
    "Zemgalian",
    "xzm"
  ],
  [
    "Ancient Zapotec",
    "xzp"
  ],
  [
    "Yaminahua",
    "yaa"
  ],
  [
    "Yuhup",
    "yab"
  ],
  [
    "Pass Valley Yali",
    "yac"
  ],
  [
    "Yagua",
    "yad"
  ],
  [
    "Pumé",
    "yae"
  ],
  [
    "Yaka (Democratic Republic of Congo)",
    "yaf"
  ],
  [
    "Yámana",
    "yag"
  ],
  [
    "Yazgulyam",
    "yah"
  ],
  [
    "Yagnobi",
    "yai"
  ],
  [
    "Banda-Yangere",
    "yaj"
  ],
  [
    "Yakama",
    "yak"
  ],
  [
    "Yalunka",
    "yal"
  ],
  [
    "Yamba",
    "yam"
  ],
  [
    "Mayangna",
    "yan"
  ],
  [
    "Yao",
    "yao",
    "yao",
    "yao"
  ],
  [
    "Yapese",
    "yap",
    "yap",
    "yap"
  ],
  [
    "Yaqui",
    "yaq"
  ],
  [
    "Yabarana",
    "yar"
  ],
  [
    "Nugunu (Cameroon)",
    "yas"
  ],
  [
    "Yambeta",
    "yat"
  ],
  [
    "Yuwana",
    "yau"
  ],
  [
    "Yangben",
    "yav"
  ],
  [
    "Yawalapití",
    "yaw"
  ],
  [
    "Yauma",
    "yax"
  ],
  [
    "Agwagwune",
    "yay"
  ],
  [
    "Lokaa",
    "yaz"
  ],
  [
    "Yala",
    "yba"
  ],
  [
    "Yemba",
    "ybb"
  ],
  [
    "West Yugur",
    "ybe"
  ],
  [
    "Yakha",
    "ybh"
  ],
  [
    "Yamphu",
    "ybi"
  ],
  [
    "Hasha",
    "ybj"
  ],
  [
    "Bokha",
    "ybk"
  ],
  [
    "Yukuben",
    "ybl"
  ],
  [
    "Yaben",
    "ybm"
  ],
  [
    "Yabaâna",
    "ybn"
  ],
  [
    "Yabong",
    "ybo"
  ],
  [
    "Yawiyo",
    "ybx"
  ],
  [
    "Yaweyuha",
    "yby"
  ],
  [
    "Chesu",
    "ych"
  ],
  [
    "Lolopo",
    "ycl"
  ],
  [
    "Yucuna",
    "ycn"
  ],
  [
    "Chepya",
    "ycp"
  ],
  [
    "Yanda",
    "yda"
  ],
  [
    "Eastern Yiddish",
    "ydd"
  ],
  [
    "Yangum Dey",
    "yde"
  ],
  [
    "Yidgha",
    "ydg"
  ],
  [
    "Yoidik",
    "ydk"
  ],
  [
    "Ravula",
    "yea"
  ],
  [
    "Yeniche",
    "yec"
  ],
  [
    "Yimas",
    "yee"
  ],
  [
    "Yeni",
    "yei"
  ],
  [
    "Yevanic",
    "yej"
  ],
  [
    "Yela",
    "yel"
  ],
  [
    "Tarok",
    "yer"
  ],
  [
    "Nyankpa",
    "yes"
  ],
  [
    "Yetfa",
    "yet"
  ],
  [
    "Yerukula",
    "yeu"
  ],
  [
    "Yapunda",
    "yev"
  ],
  [
    "Yeyi",
    "yey"
  ],
  [
    "Malyangapa",
    "yga"
  ],
  [
    "Yiningayi",
    "ygi"
  ],
  [
    "Yangum Gel",
    "ygl"
  ],
  [
    "Yagomi",
    "ygm"
  ],
  [
    "Gepo",
    "ygp"
  ],
  [
    "Yagaria",
    "ygr"
  ],
  [
    "Yolŋu Sign Language",
    "ygs"
  ],
  [
    "Yugul",
    "ygu"
  ],
  [
    "Yagwoia",
    "ygw"
  ],
  [
    "Baha Buyang",
    "yha"
  ],
  [
    "Judeo-Iraqi Arabic",
    "yhd"
  ],
  [
    "Hlepho Phowa",
    "yhl"
  ],
  [
    "Yan-nhaŋu Sign Language",
    "yhs"
  ],
  [
    "Yinggarda",
    "yia"
  ],
  [
    "Yiddish",
    "yid",
    "yid",
    "yid",
    "yi",
    "ייִדיש"
  ],
  [
    "Ache",
    "yif"
  ],
  [
    "Wusa Nasu",
    "yig"
  ],
  [
    "Western Yiddish",
    "yih"
  ],
  [
    "Yidiny",
    "yii"
  ],
  [
    "Yindjibarndi",
    "yij"
  ],
  [
    "Dongshanba Lalo",
    "yik"
  ],
  [
    "Yindjilandji",
    "yil"
  ],
  [
    "Yimchungru Naga",
    "yim"
  ],
  [
    "Riang Lai",
    "yin"
  ],
  [
    "Pholo",
    "yip"
  ],
  [
    "Miqie",
    "yiq"
  ],
  [
    "North Awyu",
    "yir"
  ],
  [
    "Yis",
    "yis"
  ],
  [
    "Eastern Lalu",
    "yit"
  ],
  [
    "Awu",
    "yiu"
  ],
  [
    "Northern Nisu",
    "yiv"
  ],
  [
    "Axi Yi",
    "yix"
  ],
  [
    "Azhe",
    "yiz"
  ],
  [
    "Yakan",
    "yka"
  ],
  [
    "Northern Yukaghir",
    "ykg"
  ],
  [
    "Yoke",
    "yki"
  ],
  [
    "Yakaikeke",
    "ykk"
  ],
  [
    "Khlula",
    "ykl"
  ],
  [
    "Kap",
    "ykm"
  ],
  [
    "Kua-nsi",
    "ykn"
  ],
  [
    "Yasa",
    "yko"
  ],
  [
    "Yekora",
    "ykr"
  ],
  [
    "Kathu",
    "ykt"
  ],
  [
    "Kuamasi",
    "yku"
  ],
  [
    "Yakoma",
    "yky"
  ],
  [
    "Yaul",
    "yla"
  ],
  [
    "Yaleba",
    "ylb"
  ],
  [
    "Yele",
    "yle"
  ],
  [
    "Yelogu",
    "ylg"
  ],
  [
    "Angguruk Yali",
    "yli"
  ],
  [
    "Yil",
    "yll"
  ],
  [
    "Limi",
    "ylm"
  ],
  [
    "Langnian Buyang",
    "yln"
  ],
  [
    "Naluo Yi",
    "ylo"
  ],
  [
    "Yalarnnga",
    "ylr"
  ],
  [
    "Aribwaung",
    "ylu"
  ],
  [
    "Nyâlayu",
    "yly"
  ],
  [
    "Yambes",
    "ymb"
  ],
  [
    "Southern Muji",
    "ymc"
  ],
  [
    "Muda",
    "ymd"
  ],
  [
    "Yameo",
    "yme"
  ],
  [
    "Yamongeri",
    "ymg"
  ],
  [
    "Mili",
    "ymh"
  ],
  [
    "Moji",
    "ymi"
  ],
  [
    "Makwe",
    "ymk"
  ],
  [
    "Iamalele",
    "yml"
  ],
  [
    "Maay",
    "ymm"
  ],
  [
    "Yamna",
    "ymn"
  ],
  [
    "Yangum Mon",
    "ymo"
  ],
  [
    "Yamap",
    "ymp"
  ],
  [
    "Qila Muji",
    "ymq"
  ],
  [
    "Malasar",
    "ymr"
  ],
  [
    "Mysian",
    "yms"
  ],
  [
    "Northern Muji",
    "ymx"
  ],
  [
    "Muzi",
    "ymz"
  ],
  [
    "Aluo",
    "yna"
  ],
  [
    "Yandruwandha",
    "ynd"
  ],
  [
    "Lang'e",
    "yne"
  ],
  [
    "Yango",
    "yng"
  ],
  [
    "Naukan Yupik",
    "ynk"
  ],
  [
    "Yangulam",
    "ynl"
  ],
  [
    "Yana",
    "ynn"
  ],
  [
    "Yong",
    "yno"
  ],
  [
    "Yendang",
    "ynq"
  ],
  [
    "Yansi",
    "yns"
  ],
  [
    "Yahuna",
    "ynu"
  ],
  [
    "Yoba",
    "yob"
  ],
  [
    "Yogad",
    "yog"
  ],
  [
    "Yonaguni",
    "yoi"
  ],
  [
    "Yokuts",
    "yok"
  ],
  [
    "Yola",
    "yol"
  ],
  [
    "Yombe",
    "yom"
  ],
  [
    "Yongkom",
    "yon"
  ],
  [
    "Yoruba",
    "yor",
    "yor",
    "yor",
    "yo",
    "Yorùbá"
  ],
  [
    "Yotti",
    "yot"
  ],
  [
    "Yoron",
    "yox"
  ],
  [
    "Yoy",
    "yoy"
  ],
  [
    "Phala",
    "ypa"
  ],
  [
    "Labo Phowa",
    "ypb"
  ],
  [
    "Phola",
    "ypg"
  ],
  [
    "Phupha",
    "yph"
  ],
  [
    "Phuma",
    "ypm"
  ],
  [
    "Ani Phowa",
    "ypn"
  ],
  [
    "Alo Phola",
    "ypo"
  ],
  [
    "Phupa",
    "ypp"
  ],
  [
    "Phuza",
    "ypz"
  ],
  [
    "Yerakai",
    "yra"
  ],
  [
    "Yareba",
    "yrb"
  ],
  [
    "Yaouré",
    "yre"
  ],
  [
    "Nenets",
    "yrk"
  ],
  [
    "Nhengatu",
    "yrl"
  ],
  [
    "Yirrk-Mel",
    "yrm"
  ],
  [
    "Yerong",
    "yrn"
  ],
  [
    "Yaroamë",
    "yro"
  ],
  [
    "Yarsun",
    "yrs"
  ],
  [
    "Yarawata",
    "yrw"
  ],
  [
    "Yarluyandi",
    "yry"
  ],
  [
    "Yassic",
    "ysc"
  ],
  [
    "Samatao",
    "ysd"
  ],
  [
    "Sonaga",
    "ysg"
  ],
  [
    "Yugoslavian Sign Language",
    "ysl"
  ],
  [
    "Myanmar Sign Language",
    "ysm"
  ],
  [
    "Sani",
    "ysn"
  ],
  [
    "Nisi (China)",
    "yso"
  ],
  [
    "Southern Lolopo",
    "ysp"
  ],
  [
    "Sirenik Yupik",
    "ysr"
  ],
  [
    "Yessan-Mayo",
    "yss"
  ],
  [
    "Sanie",
    "ysy"
  ],
  [
    "Talu",
    "yta"
  ],
  [
    "Tanglang",
    "ytl"
  ],
  [
    "Thopho",
    "ytp"
  ],
  [
    "Yout Wam",
    "ytw"
  ],
  [
    "Yatay",
    "yty"
  ],
  [
    "Yucateco",
    "yua"
  ],
  [
    "Yugambal",
    "yub"
  ],
  [
    "Yuchi",
    "yuc"
  ],
  [
    "Judeo-Tripolitanian Arabic",
    "yud"
  ],
  [
    "Yue Chinese",
    "yue"
  ],
  [
    "Havasupai-Walapai-Yavapai",
    "yuf"
  ],
  [
    "Yug",
    "yug"
  ],
  [
    "Yurutí",
    "yui"
  ],
  [
    "Karkar-Yuri",
    "yuj"
  ],
  [
    "Yuki",
    "yuk"
  ],
  [
    "Yulu",
    "yul"
  ],
  [
    "Quechan",
    "yum"
  ],
  [
    "Bena (Nigeria)",
    "yun"
  ],
  [
    "Yukpa",
    "yup"
  ],
  [
    "Yuqui",
    "yuq"
  ],
  [
    "Yurok",
    "yur"
  ],
  [
    "Yopno",
    "yut"
  ],
  [
    "Yau (Morobe Province)",
    "yuw"
  ],
  [
    "Southern Yukaghir",
    "yux"
  ],
  [
    "East Yugur",
    "yuy"
  ],
  [
    "Yuracare",
    "yuz"
  ],
  [
    "Yawa",
    "yva"
  ],
  [
    "Yavitero",
    "yvt"
  ],
  [
    "Kalou",
    "ywa"
  ],
  [
    "Yinhawangka",
    "ywg"
  ],
  [
    "Western Lalu",
    "ywl"
  ],
  [
    "Yawanawa",
    "ywn"
  ],
  [
    "Wuding-Luquan Yi",
    "ywq"
  ],
  [
    "Yawuru",
    "ywr"
  ],
  [
    "Xishanba Lalo",
    "ywt"
  ],
  [
    "Wumeng Nasu",
    "ywu"
  ],
  [
    "Yawarawarga",
    "yww"
  ],
  [
    "Mayawali",
    "yxa"
  ],
  [
    "Yagara",
    "yxg"
  ],
  [
    "Yardliyawarra",
    "yxl"
  ],
  [
    "Yinwum",
    "yxm"
  ],
  [
    "Yuyu",
    "yxu"
  ],
  [
    "Yabula Yabula",
    "yxy"
  ],
  [
    "Yir Yoront",
    "yyr"
  ],
  [
    "Yau (Sandaun Province)",
    "yyu"
  ],
  [
    "Ayizi",
    "yyz"
  ],
  [
    "E'ma Buyang",
    "yzg"
  ],
  [
    "Zokhuo",
    "yzk"
  ],
  [
    "Sierra de Juárez Zapotec",
    "zaa"
  ],
  [
    "Western Tlacolula Valley Zapotec",
    "zab"
  ],
  [
    "Ocotlán Zapotec",
    "zac"
  ],
  [
    "Cajonos Zapotec",
    "zad"
  ],
  [
    "Yareni Zapotec",
    "zae"
  ],
  [
    "Ayoquesco Zapotec",
    "zaf"
  ],
  [
    "Zaghawa",
    "zag"
  ],
  [
    "Zangwal",
    "zah"
  ],
  [
    "Isthmus Zapotec",
    "zai"
  ],
  [
    "Zaramo",
    "zaj"
  ],
  [
    "Zanaki",
    "zak"
  ],
  [
    "Zauzou",
    "zal"
  ],
  [
    "Miahuatlán Zapotec",
    "zam"
  ],
  [
    "Ozolotepec Zapotec",
    "zao"
  ],
  [
    "Zapotec",
    "zap",
    "zap",
    "zap"
  ],
  [
    "Aloápam Zapotec",
    "zaq"
  ],
  [
    "Rincón Zapotec",
    "zar"
  ],
  [
    "Santo Domingo Albarradas Zapotec",
    "zas"
  ],
  [
    "Tabaa Zapotec",
    "zat"
  ],
  [
    "Zangskari",
    "zau"
  ],
  [
    "Yatzachi Zapotec",
    "zav"
  ],
  [
    "Mitla Zapotec",
    "zaw"
  ],
  [
    "Xadani Zapotec",
    "zax"
  ],
  [
    "Zayse-Zergulla",
    "zay"
  ],
  [
    "Zari",
    "zaz"
  ],
  [
    "Balaibalan",
    "zba"
  ],
  [
    "Central Berawan",
    "zbc"
  ],
  [
    "East Berawan",
    "zbe"
  ],
  [
    "Blissymbols",
    "zbl",
    "zbl",
    "zbl"
  ],
  [
    "Batui",
    "zbt"
  ],
  [
    "Bu (Bauchi State)",
    "zbu"
  ],
  [
    "West Berawan",
    "zbw"
  ],
  [
    "Coatecas Altas Zapotec",
    "zca"
  ],
  [
    "Las Delicias Zapotec",
    "zcd",
    "zcd",
    "zcd"
  ],
  [
    "Central Hongshuihe Zhuang",
    "zch"
  ],
  [
    "Ngazidja Comorian",
    "zdj"
  ],
  [
    "Zeeuws",
    "zea"
  ],
  [
    "Zenag",
    "zeg"
  ],
  [
    "Eastern Hongshuihe Zhuang",
    "zeh"
  ],
  [
    "Zenaga",
    "zen",
    "zen",
    "zen"
  ],
  [
    "Kinga",
    "zga"
  ],
  [
    "Guibei Zhuang",
    "zgb"
  ],
  [
    "Standard Moroccan Tamazight",
    "zgh",
    "zgh",
    "zgh"
  ],
  [
    "Minz Zhuang",
    "zgm"
  ],
  [
    "Guibian Zhuang",
    "zgn"
  ],
  [
    "Magori",
    "zgr"
  ],
  [
    "Zhuang",
    "zha",
    "zha",
    "zha",
    "za",
    "Cuengh / Tôô / 壮语"
  ],
  [
    "Zhaba",
    "zhb"
  ],
  [
    "Dai Zhuang",
    "zhd"
  ],
  [
    "Zhire",
    "zhi"
  ],
  [
    "Nong Zhuang",
    "zhn"
  ],
  [
    "Chinese",
    "zho",
    "chi",
    "zho",
    "zh",
    "中文"
  ],
  [
    "Zhoa",
    "zhw"
  ],
  [
    "Zia",
    "zia"
  ],
  [
    "Zimbabwe Sign Language",
    "zib"
  ],
  [
    "Zimakani",
    "zik"
  ],
  [
    "Zialo",
    "zil"
  ],
  [
    "Mesme",
    "zim"
  ],
  [
    "Zinza",
    "zin"
  ],
  [
    "Zigula",
    "ziw"
  ],
  [
    "Zizilivakan",
    "ziz"
  ],
  [
    "Kaimbulawa",
    "zka"
  ],
  [
    "Koibal",
    "zkb"
  ],
  [
    "Kadu",
    "zkd"
  ],
  [
    "Koguryo",
    "zkg"
  ],
  [
    "Khorezmian",
    "zkh"
  ],
  [
    "Karankawa",
    "zkk"
  ],
  [
    "Kanan",
    "zkn"
  ],
  [
    "Kott",
    "zko"
  ],
  [
    "São Paulo Kaingáng",
    "zkp"
  ],
  [
    "Zakhring",
    "zkr"
  ],
  [
    "Kitan",
    "zkt"
  ],
  [
    "Kaurna",
    "zku"
  ],
  [
    "Krevinian",
    "zkv"
  ],
  [
    "Khazar",
    "zkz"
  ],
  [
    "Zula",
    "zla"
  ],
  [
    "Liujiang Zhuang",
    "zlj"
  ],
  [
    "Malay (individual language)",
    "zlm"
  ],
  [
    "Lianshan Zhuang",
    "zln"
  ],
  [
    "Liuqian Zhuang",
    "zlq"
  ],
  [
    "Manda (Australia)",
    "zma"
  ],
  [
    "Zimba",
    "zmb"
  ],
  [
    "Margany",
    "zmc"
  ],
  [
    "Maridan",
    "zmd"
  ],
  [
    "Mangerr",
    "zme"
  ],
  [
    "Mfinu",
    "zmf"
  ],
  [
    "Marti Ke",
    "zmg"
  ],
  [
    "Makolkol",
    "zmh"
  ],
  [
    "Negeri Sembilan Malay",
    "zmi"
  ],
  [
    "Maridjabin",
    "zmj"
  ],
  [
    "Mandandanyi",
    "zmk"
  ],
  [
    "Matngala",
    "zml"
  ],
  [
    "Marimanindji",
    "zmm"
  ],
  [
    "Mbangwe",
    "zmn"
  ],
  [
    "Molo",
    "zmo"
  ],
  [
    "Mpuono",
    "zmp"
  ],
  [
    "Mituku",
    "zmq"
  ],
  [
    "Maranunggu",
    "zmr"
  ],
  [
    "Mbesa",
    "zms"
  ],
  [
    "Maringarr",
    "zmt"
  ],
  [
    "Muruwari",
    "zmu"
  ],
  [
    "Mbariman-Gudhinma",
    "zmv"
  ],
  [
    "Mbo (Democratic Republic of Congo)",
    "zmw"
  ],
  [
    "Bomitaba",
    "zmx"
  ],
  [
    "Mariyedi",
    "zmy"
  ],
  [
    "Mbandja",
    "zmz"
  ],
  [
    "Zan Gula",
    "zna"
  ],
  [
    "Zande (individual language)",
    "zne"
  ],
  [
    "Mang",
    "zng"
  ],
  [
    "Manangkari",
    "znk"
  ],
  [
    "Mangas",
    "zns"
  ],
  [
    "Copainalá Zoque",
    "zoc"
  ],
  [
    "Chimalapa Zoque",
    "zoh"
  ],
  [
    "Zou",
    "zom"
  ],
  [
    "Asunción Mixtepec Zapotec",
    "zoo"
  ],
  [
    "Tabasco Zoque",
    "zoq"
  ],
  [
    "Rayón Zoque",
    "zor"
  ],
  [
    "Francisco León Zoque",
    "zos"
  ],
  [
    "Lachiguiri Zapotec",
    "zpa"
  ],
  [
    "Yautepec Zapotec",
    "zpb"
  ],
  [
    "Choapan Zapotec",
    "zpc"
  ],
  [
    "Southeastern Ixtlán Zapotec",
    "zpd"
  ],
  [
    "Petapa Zapotec",
    "zpe"
  ],
  [
    "San Pedro Quiatoni Zapotec",
    "zpf"
  ],
  [
    "Guevea De Humboldt Zapotec",
    "zpg"
  ],
  [
    "Totomachapan Zapotec",
    "zph"
  ],
  [
    "Santa María Quiegolani Zapotec",
    "zpi"
  ],
  [
    "Quiavicuzas Zapotec",
    "zpj"
  ],
  [
    "Tlacolulita Zapotec",
    "zpk"
  ],
  [
    "Lachixío Zapotec",
    "zpl"
  ],
  [
    "Mixtepec Zapotec",
    "zpm"
  ],
  [
    "Santa Inés Yatzechi Zapotec",
    "zpn"
  ],
  [
    "Amatlán Zapotec",
    "zpo"
  ],
  [
    "El Alto Zapotec",
    "zpp"
  ],
  [
    "Zoogocho Zapotec",
    "zpq"
  ],
  [
    "Santiago Xanica Zapotec",
    "zpr"
  ],
  [
    "Coatlán Zapotec",
    "zps"
  ],
  [
    "San Vicente Coatlán Zapotec",
    "zpt"
  ],
  [
    "Yalálag Zapotec",
    "zpu"
  ],
  [
    "Chichicapan Zapotec",
    "zpv"
  ],
  [
    "Zaniza Zapotec",
    "zpw"
  ],
  [
    "San Baltazar Loxicha Zapotec",
    "zpx"
  ],
  [
    "Mazaltepec Zapotec",
    "zpy"
  ],
  [
    "Texmelucan Zapotec",
    "zpz"
  ],
  [
    "Qiubei Zhuang",
    "zqe"
  ],
  [
    "Kara (Korea)",
    "zra"
  ],
  [
    "Mirgan",
    "zrg"
  ],
  [
    "Zerenkel",
    "zrn"
  ],
  [
    "Záparo",
    "zro"
  ],
  [
    "Zarphatic",
    "zrp"
  ],
  [
    "Mairasi",
    "zrs"
  ],
  [
    "Sarasira",
    "zsa"
  ],
  [
    "Kaskean",
    "zsk"
  ],
  [
    "Zambian Sign Language",
    "zsl"
  ],
  [
    "Standard Malay",
    "zsm"
  ],
  [
    "Southern Rincon Zapotec",
    "zsr"
  ],
  [
    "Sukurum",
    "zsu"
  ],
  [
    "Elotepec Zapotec",
    "zte"
  ],
  [
    "Xanaguía Zapotec",
    "ztg"
  ],
  [
    "Lapaguía-Guivini Zapotec",
    "ztl"
  ],
  [
    "San Agustín Mixtepec Zapotec",
    "ztm"
  ],
  [
    "Santa Catarina Albarradas Zapotec",
    "ztn"
  ],
  [
    "Loxicha Zapotec",
    "ztp"
  ],
  [
    "Quioquitani-Quierí Zapotec",
    "ztq"
  ],
  [
    "Tilquiapan Zapotec",
    "zts"
  ],
  [
    "Tejalapan Zapotec",
    "ztt"
  ],
  [
    "Güilá Zapotec",
    "ztu"
  ],
  [
    "Zaachila Zapotec",
    "ztx"
  ],
  [
    "Yatee Zapotec",
    "zty"
  ],
  [
    "Zeem",
    "zua"
  ],
  [
    "Tokano",
    "zuh"
  ],
  [
    "Zulu",
    "zul",
    "zul",
    "zul",
    "zu",
    "isiZulu"
  ],
  [
    "Kumzari",
    "zum"
  ],
  [
    "Zuni",
    "zun",
    "zun",
    "zun"
  ],
  [
    "Zumaya",
    "zuy"
  ],
  [
    "Zay",
    "zwa"
  ],
  [
    "No linguistic content",
    "zxx",
    "zxx",
    "zxx"
  ],
  [
    "Yongbei Zhuang",
    "zyb"
  ],
  [
    "Yang Zhuang",
    "zyg"
  ],
  [
    "Youjiang Zhuang",
    "zyj"
  ],
  [
    "Yongnan Zhuang",
    "zyn"
  ],
  [
    "Zyphe Chin",
    "zyp"
  ],
  [
    "Zaza",
    "zza",
    "zza",
    "zza"
  ],
  [
    "Zuojiang Zhuang",
    "zzj"
  ]
]

export default iso6393Info