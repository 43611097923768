import { memo, useCallback } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import HeadsetIcon from '@material-ui/icons/Headset'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
// import { i18n } from 'inline-i18n'

const Container = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 10px;
  display: flex;

  border: 1px solid rgb(255 255 255/.1);
  background: rgb(255 255 255/.05);
  border-radius: 100px;
  padding: 3px;
`

const Marker = styled.div`
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  width: 32px;
  transition: transform .15s ease-in-out;
  transform: translateX(${({ $isAudio }) => $isAudio ? 0 : 32}px);
  background: rgb(255 255 255/.2);
  border-radius: 100px;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: 0;

  .MuiSvgIcon-root {
    color: white;
    font-size: 16px;
  }

  :hover {
    background: rgb(255 255 255/.05);
  }
`

const VideoAudioSwitch = ({
  isAudio,
  setDoAudio,
  hasAudio,
  ...otherProps
}) => {

  const toggleDoAudio = useCallback(() => setDoAudio(!isAudio), [ setDoAudio, isAudio ])

  return (
    <Container
      {...otherProps}
    >

      <Marker $isAudio={isAudio} />

      <StyledIconButton
        tabIndex={-1}
        onClick={toggleDoAudio}
        disabled={isAudio}
      >
        {hasAudio && <HeadsetIcon />}
        {!hasAudio && <VisibilityOffIcon />}
      </StyledIconButton>

      <StyledIconButton
        tabIndex={-1}
        onClick={toggleDoAudio}
        disabled={!isAudio}
      >
        <OndemandVideoIcon />
      </StyledIconButton>

    </Container>
  )

}

export default memo(VideoAudioSwitch)