import { useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

import moduleMarkupQuery from '../graphql/queries/moduleMarkup'
import updateModuleMarkupMutation from '../graphql/mutations/updateModuleMarkup'
import deleteModuleMarkupMutation from '../graphql/mutations/deleteModuleMarkup'

export const getDefaultModuleMarkup = () => ({
  __typename: `ModuleMarkup`,
  container: `none`,
  positionInfo: null,
  type: 'BASE-HIGHLIGHT',
  color: 'YELLOW',
})

const useGoUpdateModuleMarkup = ({
  moduleMarkup,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  const client = useApolloClient()

  const [ updateModuleMarkup, updateModuleMarkupResult ] = useMutation(updateModuleMarkupMutation)
  const [ deleteModuleMarkup, deleteModuleMarkupResult ] = useMutation(deleteModuleMarkupMutation)

  const goUpdate = useGoUpdateTable({
    currentData: moduleMarkup || getDefaultModuleMarkup(),
    updateFunc: updateModuleMarkup,
    updateResult: updateModuleMarkupResult,
    deleteFunc: deleteModuleMarkup,
    deleteResult: deleteModuleMarkupResult,
    projectId,
    moduleId: moduleId || moduleMarkup.moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => {
      if(!moduleMarkup) {
        return [
          initData => {
            // Create a new moduleMarkup

            const newData = goUpdate[0]({
              ...initData,
              moduleId,
            })

            client.writeQuery({
              query: moduleMarkupQuery,
              data: {
                moduleMarkup: newData,
              },
              variables: {
                id: newData.id,
              },
            })

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ moduleMarkup, moduleId, goUpdate, client ],
  )

  return toReturn
}

export default useGoUpdateModuleMarkup