import { useCallback } from "react"

import useInstanceValue from "./useInstanceValue"
import useGoUpdateModulePassage from "./useGoUpdateModulePassage"
import { cloneObj } from "../utils/misc"

const useDuplicateModulePassages = ({
  moduleToCopyFrom,
  newModuleId,
  projectId,
}) => {

  const hookParams = { moduleId: newModuleId, projectId }
  const goCreateFunctions = [
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
    useGoUpdateModulePassage(hookParams)[0],
  ]

  const getGoCreateFunctions = useInstanceValue(goCreateFunctions)
  const getModuleToCopyFrom = useInstanceValue(moduleToCopyFrom)

  const goDuplicateModulePassages = useCallback(
    async () => {

      const goCreateFunctions = getGoCreateFunctions()
      const moduleToCopyFrom = getModuleToCopyFrom()

      moduleToCopyFrom.modulePassages.forEach((modulePassage, idx) => {
        const newModulePassageData = cloneObj(modulePassage)
        delete newModulePassageData.__typename
        delete newModulePassageData.id
        delete newModulePassageData.savedAt
        delete newModulePassageData.moduleId
        goCreateFunctions[idx](newModulePassageData)
      })

    },
    [ getGoCreateFunctions, getModuleToCopyFrom ],
  )

  return goDuplicateModulePassages
}

export default useDuplicateModulePassages
