import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { getBoxToBoxArrow } from 'curved-arrows'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import useRefState from '../../hooks/useRefState'
import useInstanceValue from '../../hooks/useInstanceValue'
import { equalObjs } from '../../utils/misc'

const ContextEl = styled.div`
  display: none;
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const SvgArrow = ({
  color=`black`,
  arrowHeadSize=9,
  ref1,
  ref2,
  padStart=0,
  padEnd=0,
  controlPointStretch=70,
  ...otherProps
}) => {

  const contextRef = useRef()
  const getRefs = useInstanceValue({ ref1, ref2, contextRef })
  const [ setCheckElsTimeout ] = useSetTimeout()
  const [ el1, setEl1, getEl1 ] = useRefState((ref1 || {}).current)
  const [ el2, setEl2, getEl2 ] = useRefState((ref2 || {}).current)
  const [ contextEl, setContextEl, getContextEl ] = useRefState((ref2 || {}).current)
  const [ arrowPositionDetails, setArrowPositionDetails, getArrowPositionDetails ] = useRefState()
  const [ sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae ] = arrowPositionDetails || []

  useEffect(
    () => {

      if(
        !(el1 instanceof Element)
        || !(el2 instanceof Element)
        || !(contextEl instanceof Element)
      ) {
        setArrowPositionDetails()
        return
      }

      const determineArrowDetails = () => {

        if(
          !document.body.contains(el1)
          || !document.body.contains(el2)
        ) {
          setArrowPositionDetails()
          return
        }

        const rect1 = el1.getBoundingClientRect()
        const rect2 = el2.getBoundingClientRect()

        let positionedParentEl = contextEl
        while(
          positionedParentEl.parentElement
          && window.getComputedStyle(positionedParentEl).getPropertyValue(`position`) === 'static'
        ) {
          positionedParentEl = positionedParentEl.parentElement
        }
        const containerRect = positionedParentEl.getBoundingClientRect()

        rect1.x -= containerRect.x
        rect1.y -= containerRect.y
        rect2.x -= containerRect.x
        rect2.y -= containerRect.y

        const options = {
          padStart,
          padEnd,
          controlPointStretch,
        }

        const newArrowPositionDetails = getBoxToBoxArrow(rect1.x, rect1.y, rect1.width, rect1.height, rect2.x, rect2.y, rect2.width, rect2.height, options)

        if(!equalObjs(newArrowPositionDetails, getArrowPositionDetails())) {
          setArrowPositionDetails(newArrowPositionDetails)
        }

      }

      const interval = setInterval(determineArrowDetails, 20)

      return () => {
        clearInterval(interval)
      }

    },
    [ el1, el2, contextEl, padStart, padEnd, controlPointStretch, getArrowPositionDetails, setArrowPositionDetails ],
  )

  useEffectAsync(
    () => {
      const checkEls = () => {
        const { ref1, ref2, contextRef } = getRefs()
        const el1 = (ref1 || {}).current
        if(el1 !== getEl1()) setEl1(el1)
        const el2 = (ref2 || {}).current
        if(el2 !== getEl2()) setEl2(el2)
        const contextEl = contextRef.current
        if(contextEl !== getContextEl()) setContextEl(contextEl)
        setCheckElsTimeout(checkEls, 50)
      }
      checkEls()
    },
    [],
  )

  return (
    <>
      <ContextEl ref={contextRef} />

      {sx !== undefined &&
        <Svg
          {...otherProps}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d={`M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`}
            stroke={color}
            strokeWidth={arrowHeadSize / 2}
            fill="none"
          />
          <polygon
            points={`0,${-arrowHeadSize} ${arrowHeadSize *
              2},0, 0,${arrowHeadSize}`}
            transform={`translate(${ex}, ${ey}) rotate(${ae})`}
            fill={color}
          />
        </Svg>
      }
    </>
  )

}

export default SvgArrow