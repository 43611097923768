import { useCallback, useContext, useMemo } from 'react'
import { useApolloClient } from '@apollo/client'
import { isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"

import { SelectedVersionsContext } from '../context/SelectedVersions'
import { OfflineSetupStatusContext } from '../context/LocalInfo'
import {
  getVersionInfoByIdAsync as getVersionInfoByIdAsyncFromUtil,
  getVersionInfoByIdSync as getVersionInfoByIdSyncFromUtil,
  getVersionInfoFromVersion,
  normalizeVersionId,
} from '../utils/misc'
import useObjFromArrayOfObjs from './useObjFromArrayOfObjs'
import useDataQuery from './useDataQuery'
import useEffectAsync from './useEffectAsync'

import versionsQuery from '../graphql/queries/versions'
import versionQuery from '../graphql/queries/version'

const useVersionInfos = ({
  versionIds,
  searchText,
  restrictToTestamentBookId,
  restrictToTestamentSearchText,
  index=0,
  limit,
  skip,
}={}) => {

  const client = useApolloClient()
  const offlineSetupStatus = useContext(OfflineSetupStatusContext)

  const restrictToTestament = useMemo(
    () => (
      restrictToTestamentBookId
        ? (
          restrictToTestamentBookId <= 39
            ? `ot`
            : `nt`
        )
        : (
          (
            isOriginalLanguageSearch(restrictToTestamentSearchText)
            && (
              (/#H/.test(restrictToTestamentSearchText) && `ot`)
              || (/#G/.test(restrictToTestamentSearchText) && `nt`)
            )
          )
          || null
        )
    ),
    [ restrictToTestamentBookId, restrictToTestamentSearchText ],
  )

  let { selectedVersionInfos, ...selectedVersionFuncs } = useContext(SelectedVersionsContext)

  selectedVersionInfos = useMemo(
    () => (
      restrictToTestament
        ? (
          selectedVersionInfos
            .filter(({ version: { partialScope }={} }) => (
              !partialScope
              || partialScope === restrictToTestament
            ))
        )
        : selectedVersionInfos
    ),
    [ selectedVersionInfos, restrictToTestament ]
  )

  const unhiddenSelectedVersionInfos = useMemo(
    () => (
      selectedVersionInfos
      && selectedVersionInfos.filter(({ hide }) => !hide)
    ),
    [ selectedVersionInfos ],
  )

  const nonOrigSelectedVersionInfos = useMemo(
    () => (
      selectedVersionInfos
      && selectedVersionInfos.filter(({ id }) => ![ 'original', 'lxx' ].includes(id))  // TODO: when bring in Tyndale, I need to make this unambiguous as to whether it means `original` or type === 'ORIGINAL'
    ),
    [ selectedVersionInfos ],
  )

  const defaultVersionId = ((unhiddenSelectedVersionInfos || [])[0] || {}).id || 'esv'

  const isSearch = searchText !== undefined

  // do search defaults
  if(isSearch) {
    if(limit === undefined) {
      limit = 10
    }
  }

  const normalizedVersionIds = useMemo(
    () => (
      versionIds
      && [ ...new Set(versionIds.map(normalizeVersionId)) ]
    ),
    [ versionIds ],
  )

  // check for versions already cached (and core versions)
  const cachedVersionInfos = useMemo(
    () => (
      (normalizedVersionIds || [])
        .map(versionId => (
          getVersionInfoByIdSyncFromUtil({
            versionId,
            client,
          })
        ))
        .filter(Boolean)
    ),
    [ normalizedVersionIds, client ],
  )
  const cachedVersionInfoById = useObjFromArrayOfObjs(cachedVersionInfos)

  // do search defaults
  const versionIdsToQuery = (normalizedVersionIds || []).filter(versionId => !cachedVersionInfoById[versionId])
  if(!isSearch && versionIdsToQuery.length === 0) {
    skip = true
  }

  // do a versions query for the rest (or for searchText)
  let { versions: { count, versions: versionsFromQuery }={} } = useDataQuery({
    versionsQuery,
    variables: {
      query: (
        isSearch
          ? searchText
          : `id:"${versionIdsToQuery.join(` `)}"`
      ),
      offset: isSearch ? parseInt(index / limit, 10) * limit : undefined,
      limit,
    },
    skip,
  })
  const versionsFromQueryById = useObjFromArrayOfObjs(versionsFromQuery)

  // put results into versionQuery
  useEffectAsync(
    () => {
      ;(versionsFromQuery || []).forEach(version => {
        client.writeQuery({
          query: versionQuery,
          data: {
            version,
          },
          variables: {
            id: version.id,
          },
        })
      })
    },
    [ versionsFromQuery, client ]
  )

  const versionInfos = useMemo(
    () => {
      if(
        versionsFromQueryById
        || Object.values(cachedVersionInfoById || {}).length === (normalizedVersionIds || []).length
      ) {
        return (
          (
            normalizedVersionIds
              ? (
                normalizedVersionIds.map(versionId => (
                  /^external_/.test(versionId)
                    ? ({
                      id: versionId,
                      safeVersionAbbr: versionId.replace(/^external_/, ``)
                    })
                    : (
                      cachedVersionInfoById[versionId]
                      || getVersionInfoFromVersion((versionsFromQueryById || {})[versionId])
                      // || getVersionInfoFromVersion({ id: versionId })  DO NOT use this line as it breaks things
                    )
                ))
              )
              : (versionsFromQuery || []).map(getVersionInfoFromVersion)
          ).filter(Boolean)
        )
      }
      return undefined
    },
    [ versionsFromQueryById, normalizedVersionIds, cachedVersionInfoById, versionsFromQuery ],
  )

  const versionInfo = useMemo(
    () => (versionInfos || [])[index % (limit || 10)],
    [ versionInfos, index, limit ],
  )

  const versions = useMemo(
    () => (
      versionInfos
      && versionInfos.map(({ version }) => version)
    ),
    [ versionInfos ],
  )

  const version = useMemo(
    () => (versions || [])[index % (limit || 10)],
    [ versions, index, limit ],
  )

  const versionInfoById = useObjFromArrayOfObjs(versionInfos || [])

  const getVersionInfoByIdSync = useCallback(
    versionId => (
      versionInfoById[versionId]
      || (
        getVersionInfoByIdSyncFromUtil({
          versionId,
          client,
        })
      )
    ),
    [ versionInfoById, client ],
  )

  const getVersionByIdSync = useCallback(versionId => (getVersionInfoByIdSync(versionId) || {}).version, [ getVersionInfoByIdSync ])

  const getVersionInfoByIdAsync = useCallback(
    async versionId => (
      getVersionInfoByIdAsyncFromUtil({
        versionId,
        client,
        offlineSetupStatus,
      })
    ),
    [ client, offlineSetupStatus ],
  )

  const getVersionByIdAsync = useCallback(
    async versionId => {
      const { version } = await getVersionInfoByIdAsync({
        versionId,
        client,
        offlineSetupStatus,
      }) || {}
      return version
    },
    [ getVersionInfoByIdAsync, client, offlineSetupStatus ],
  )

  return {
    count: isSearch ? count : null,
    versionInfo,
    version,
    versionInfos,
    versions,
    getVersionInfoByIdSync,
    getVersionByIdSync,
    getVersionInfoByIdAsync,
    getVersionByIdAsync,
    defaultVersionId,
    selectedVersionInfos,
    unhiddenSelectedVersionInfos,
    nonOrigSelectedVersionInfos,
    ...selectedVersionFuncs,
  }

}

export default useVersionInfos

/*

versionInfo = {
  id,
  ordering,
  setupStatus,
  hide,
  safeVersionAbbr,
  version: {
    id,
    doNotRemove,
    ...
  },
}

*/