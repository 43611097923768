import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

import useVersionInfo from '../../hooks/useVersionInfo'

import VersionChooser from './VersionChooser'
import ParallelAccordion from './ParallelAccordion'
import BookChooser from './BookChooser'
import ChapterChooser from './ChapterChooser'
import VersesChooser from './VersesChooser'
import PasteInExternalTextDialog from '../common/PasteInExternalTextDialog'

const Container = styled.div`
  width: 383px;
  max-width: min(100%, calc(100vw - 20px));
  user-select: none;
  height: calc(100vh - 110px);
  max-height: ${({ $maxHeight }) => $maxHeight || 600}px;
  display: flex;
  flex-direction: column;
`

const VersionMessage = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  padding: 10px 15px;
`

const BookAndChapter = styled.div`
  min-height: 0;
  flex: 1;
  display: flex;
`

const PassageChooser = ({
  type="chapter",
  showParallel=false,
  disableVersionChange=false,
  showBasedUponMessage=false,
  initialPassageState,
  updatePassage,
  showOther,
  maxChapterSpan,
  autoSelectEntireBook,
  maxHeight,
}) => {

  const [ selectedBookId, setSelectedBookId ] = useState(initialPassageState.bookId)
  const [ parallelExpanded, setParallelExpanded ] = useState(!!initialPassageState.parallelVersionId)
  const [ pasteInExternalTextDialogInfo, setPasteInExternalTextDialogInfo ] = useState({})

  const { safeVersionAbbr } = useVersionInfo(initialPassageState.versionId)

  const onChangeParallelExpanded = useCallback(
    () => {
      if(!!initialPassageState.parallelVersionId) {
        updatePassage({ parallelVersionId: null })
      }
      setParallelExpanded(!parallelExpanded)
    },
    [ parallelExpanded, updatePassage, setParallelExpanded, initialPassageState ],
  )

  const setSelectedVersionId = useCallback(versionId => updatePassage({ versionId }), [ updatePassage ])
  const setSelectedParallelVersionId = useCallback(parallelVersionId => updatePassage({ parallelVersionId }), [ updatePassage ])

  const setChapter = useCallback(
    chapter => {
      updatePassage({
        ref: {
          bookId: selectedBookId,
          chapter,
        }
      })
    },
    [ updatePassage, selectedBookId ],
  )

  const closePasteInExternalTextDialog = useCallback(
    () => {
      setPasteInExternalTextDialogInfo({
        ...pasteInExternalTextDialogInfo,
        versionId: null,
      })
    },
    [ pasteInExternalTextDialogInfo ],
  )

  return (
    <Container $maxHeight={maxHeight}>

      {showBasedUponMessage &&
        <VersionMessage>
          {i18n("Using {{version}} versification", {
            version: safeVersionAbbr || `KJV`,
          })}
        </VersionMessage>
      }

      {!disableVersionChange &&
        <VersionChooser
          selectedVersionId={initialPassageState.versionId}
          setSelectedVersionId={setSelectedVersionId}
          showOther={showOther}
        />
      }

      {showParallel &&
        <>

          <Divider />

          <ParallelAccordion
            expanded={parallelExpanded}
            onChange={onChangeParallelExpanded}
            summary={i18n("Parallel Version")}
            details={
              <VersionChooser
                selectedVersionId={initialPassageState.parallelVersionId}
                setSelectedVersionId={setSelectedParallelVersionId}
                isParallel={true}
              />
            }
          />

        </>
      }
 
      <Divider />

      <BookAndChapter>

        <BookChooser
          versionId={initialPassageState.versionId}
          selectedBookId={selectedBookId}
          setBookId={setSelectedBookId}
        />

        {type === 'verses' &&
          <VersesChooser
            versionId={initialPassageState.versionId}
            selectedBookId={selectedBookId}
            initialSelectionRefs={initialPassageState.refs}
            updatePassage={initialPassageState.versionId === `paste-in` ? setPasteInExternalTextDialogInfo : updatePassage}
            maxChapterSpan={maxChapterSpan}
            autoSelectEntireBook={autoSelectEntireBook}
          />
        }

        {type === 'chapter' &&
          <ChapterChooser
            versionId={initialPassageState.versionId}
            selectedBookId={selectedBookId}
            selectedChapter={selectedBookId === initialPassageState.bookId ? initialPassageState.chapter : null}
            setChapter={setChapter}
          />
        }

      </BookAndChapter>

      <PasteInExternalTextDialog
        open={!!pasteInExternalTextDialogInfo.versionId}
        onClose={closePasteInExternalTextDialog}
        onConfirm={updatePassage}
        {...pasteInExternalTextDialogInfo}
      />

    </Container>
  )
}

export default memo(PassageChooser)