import { useCallback, useContext, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { i18n } from "inline-i18n"
import Drawer from '@material-ui/core/Drawer'
import { useToggle } from 'react-use'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import useModalAnchor from './hooks/useModalAnchor'
import useAppSize from './hooks/useAppSize'
import { IS_EMBED } from './utils/constants'
import { isChrome, isFirefox, isIOS, isSafari } from './utils/misc'

import SyncManager from './components/common/SyncManager'
import MainMenu from './components/common/MainMenu'
import Routes from './components/common/Routes'
import { ChannelIdInPWAContext } from './context/ChannelIdInPWA'
import CustomSnackbar from './components/common/CustomSnackbar'
import Tour from './components/common/Tour'
import Loading from './components/common/Loading'
import OldAppMessage from './OldAppMessage'

const isOldIOSApp = isIOS && !isSafari && !isChrome && !isFirefox

const Main = ({
  goUpdate,
  updating,
  setResetApp,
}) => {

  const [ drawerOpen, toggleDrawerOpen ] = useToggle(false)
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)
  const { miniHeaderSize } = useAppSize()
  const location = useLocation()
  const history = useHistory()
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })
  const [ userSelectedTourType, setUserSelectedTourType ] = useState()

  const openHeaderItem = useCallback(
    ({ openModal, type }) => {

      if(!/^(?:\/|\/project\/.*|\/church\/[^/]+)$/.test(location.pathname)) {
        if(channelIdInPWA) {
          history.push(`/church/${channelIdInPWA}`)
        } else {
          history.push(`/`)
        }
      }

      if(miniHeaderSize && !drawerOpen) {
        toggleDrawerOpen()
      }

      setTimeout(() => {
        const currentTarget = document.querySelector(`.SecondaryHeaderItems-${type}Button`)
        openModal({ currentTarget })
      })

    },
    [ toggleDrawerOpen, drawerOpen, miniHeaderSize, location, history, channelIdInPWA ],
  )

  const { anchorEl: displaySettingsAnchorEl, openModal: openDisplaySettingsModal, closeModal: closeDisplaySettingsModal } = useModalAnchor()
  const openDisplaySettings = useCallback(
    () => openHeaderItem({ openModal: openDisplaySettingsModal, type: `displaySettings` }),
    [ openHeaderItem, openDisplaySettingsModal ],
  )
  const { anchorEl: alertsAnchorEl, openModal: openAlertsModal, closeModal: closeAlertsModal } = useModalAnchor()
  const openAlerts = useCallback(
    () => openHeaderItem({ openModal: openAlertsModal, type: `alerts` }),
    [ openHeaderItem, openAlertsModal ],
  )

  if(isOldIOSApp) return <OldAppMessage />

  return (
    <>

      <Drawer
        open={drawerOpen && !IS_EMBED}
        onClose={toggleDrawerOpen}
      >
        <MainMenu
          toggleDrawerOpen={toggleDrawerOpen}
          setResetApp={setResetApp}
          displaySettingsAnchorEl={displaySettingsAnchorEl}  
          openDisplaySettings={openDisplaySettings}
          closeDisplaySettings={closeDisplaySettingsModal}
          alertsAnchorEl={alertsAnchorEl}
          openAlerts={openAlerts}
          closeAlerts={closeAlertsModal}
          setSnackbarInfo={setSnackbarInfo}
          setUserSelectedTourType={setUserSelectedTourType}
        />
      </Drawer>

      <Routes
        toggleDrawerOpen={toggleDrawerOpen}
        displaySettingsAnchorEl={displaySettingsAnchorEl}  
        openDisplaySettings={openDisplaySettings}
        closeDisplaySettings={closeDisplaySettingsModal}
        alertsAnchorEl={alertsAnchorEl}
        openAlerts={openAlerts}
        closeAlerts={closeAlertsModal}
      />

      <CustomSnackbar {...snackbarInfo} />

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!goUpdate && !IS_EMBED}
        message={i18n("There is an update available to this app.")}
        action={
          <>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={goUpdate}
              disabled={updating}
            >
              {i18n("Refresh")}
              {updating && <Loading size={15} bgOpacity={0} />}
            </Button>
          </>
        }
      />

      <SyncManager />

      <Tour
        userSelectedTourType={userSelectedTourType}
        setUserSelectedTourType={setUserSelectedTourType}
      />

    </>
  )
}

export default Main