import { memo, useCallback } from 'react'
import styled from 'styled-components'
import StarIcon from '@material-ui/icons/Star'
import IconButton from '@material-ui/core/IconButton'

import useGoUpdateProject from "../../hooks/useGoUpdateProject"

const Container = styled.div`
  padding: 0 5px;
`

const StyledIconButton = styled(IconButton)`
  color: ${({ theme, $starred }) => theme.palette.grey[$starred ? 900 : 300]} !important;

  :hover {
    background-color: transparent;
    color: ${({ theme, $starred }) => theme.palette.grey[$starred ? 700 : 500]};
  }
`

const ProjectStar = ({
  project,
  disabled,
  ...otherProps
}) => {

  const [ goUpdateProject ] = useGoUpdateProject({ project })

  const { starred } = project

  const toggleStar = useCallback(
    () => {
      goUpdateProject({
        starred: !starred,
      })
    },
    [ goUpdateProject, starred ],
  )

  return (
    <Container {...otherProps} >

      <StyledIconButton
        onClick={toggleStar}
        $starred={starred}
        disabled={!!disabled}
      >
        <StarIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(ProjectStar)