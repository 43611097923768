import { memo, useCallback } from 'react'

import OptionsPopover from '../common/OptionsPopover'
import PassageChooser from '../chooser/PassageChooser'

const PassagePopover = ({
  initialPassageState,
  updatePassageBookmark,
  onClose,
  type,
  showParallel,
  disableVersionChange,
  showBasedUponMessage,
  versionsAlreadyInUse,
  maxChapterSpan,  // only relevant when type === `verses`
  autoSelectEntireBook,  // only relevant when type === `verses`
  ...otherProps
}) => {

  const updatePassage = useCallback(
    passage => {
      updatePassageBookmark({
        scrollYFraction: 0,
        ...passage,
      })
      passage.ref && onClose()
    },
    [ updatePassageBookmark, onClose ],
  )

  return (
    <OptionsPopover
      onClose={onClose}
      hideArrow
      {...otherProps}
    >

      <PassageChooser
        initialPassageState={initialPassageState}
        updatePassage={updatePassage}
        type={type}
        showParallel={showParallel}
        disableVersionChange={disableVersionChange}
        showBasedUponMessage={showBasedUponMessage}
        versionsAlreadyInUse={versionsAlreadyInUse}
        maxChapterSpan={maxChapterSpan}
        autoSelectEntireBook={autoSelectEntireBook}
      />

    </OptionsPopover>
  )
}

export default memo(PassagePopover)