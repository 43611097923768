import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

// import LinkIconButton from '../common/LinkIconButton'

const Container = styled.div`
  display: flex;
  padding: 10px;
  margin: 0 10px;

  ${({ onClick, theme }) => !onClick ? `` : `

    :hover {
      background: ${theme.palette.grey[100]};
      cursor: pointer;
    }

    :hover .MuiButtonBase-root {
      visibility: visible;
    }

  `}
`

const Image = styled.img`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  object-fit: cover;
`

const MainSection = styled.div`
  line-height: 1.2;
  flex: 1;
`

const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const ExtraInfo = styled.div`
  font-size: 11px;
  font-weight: 300;
`

const NumSermons = styled.div`
  font-size: 11px;
  font-weight: 500;
`

// const ButtonContainer = styled.div`
//   align-self: center;
//   margin: -5px -5px -5px -24px;
// `

// const StyledLinkIconButton = styled(LinkIconButton)`
//   padding: 10px;
//   background: white;
//   visibility: hidden;

//   :hover {
//     background: ${({ theme }) => theme.palette.grey[300]};
//   }

//   .MuiSvgIcon-root {
//     font-size: 14px;
//   }
// `

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin: -5px 10px;
  align-self: center;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const StudyBibleFollowAddBroadcaster = ({
  onSelect,
  onClose,
  broadcaster,
  mediaTypeAsNotNecessarilyAllSermons,
}) => {

  const {
    // aboutUs,
    broadcasterID,
    displayName,
    imageURL,
    location,
    minister,
    numberOfSermons,
  } = broadcaster

  const onClick = useCallback(
    () => {
      onSelect({
        ...broadcaster,
        channelUrl: `https://api.sermonaudio.com/v2/node/sermons?broadcasterID=${broadcasterID}`,
      })
    },
    [ onSelect, broadcaster, broadcasterID ],
  )

  return (
    <Container
      onClick={onSelect ? onClick : null}
    >

      <Image
        src={imageURL}
        className="dark-mode-exempt"
      />

      <MainSection>

        <Name>
          {displayName}
        </Name>

        <ExtraInfo>
          {location}
          {!!minister &&
            <>
              {` // `}
              <i>
                {minister}
              </i>
            </>
          }
        </ExtraInfo>

        {!!numberOfSermons &&
          <NumSermons>
            {mediaTypeAsNotNecessarilyAllSermons
              ? (
                i18n("{{num}} {{media_type}}", {
                  num: numberOfSermons,
                  media_type: mediaTypeAsNotNecessarilyAllSermons,
                })
              )
              : (
                i18n("{{num}} sermons", {
                  num: numberOfSermons,
                })
              )
            }
          </NumSermons>
        }

      </MainSection>

      {!!onClose &&
        <StyledIconButton
          onClick={onClose}
        >
          <CloseIcon />
        </StyledIconButton>
      }

      {/* <ButtonContainer>
        <StyledLinkIconButton
          to={`/channel/sermonaudio/broadcaster/${broadcasterID}`}
        >
          <LaunchIcon />
        </StyledLinkIconButton>
      </ButtonContainer> */}

    </Container>
  )
}

export default memo(StudyBibleFollowAddBroadcaster)