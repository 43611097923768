import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import BookIcon from '@material-ui/icons/Book'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
// import { useNetworkState } from 'react-use'

import { LoggedInUserContext } from '../../../context/LoggedInUser'

import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const Container = styled.div`
  width: 300px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    background-color: white;
    position: absolute;
    z-index: 3;
    top: 59px;
    bottom: 0;
    transition: left .2s ease-in-out;
    left: ${({ $expanded }) => $expanded ? 0 : `-300px`};
  }
`

const SectionNavLink = styled(NavLinkOrAWithDisable)`
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
  }

  &.active .MuiListItem-button {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    cursor: default;
  }
`

const styleIcon = () => `
  margin-right: 10px;
`

const StyledBookIcon = styled(BookIcon)(styleIcon)
const StyledFormatSizeIcon = styled(FormatSizeIcon)(styleIcon)
const StyledOndemandVideoIcon = styled(OndemandVideoIcon)(styleIcon)
const StyledAccessibilityIcon = styled(AccessibilityIcon)(styleIcon)

const SettingsLeftPanel = ({
  expanded,
  close,
  openDisplaySettings,
}) => {

  const { adminLevel, hasMyPlan } = useContext(LoggedInUserContext) || {}

  // const { online } = useNetworkState()

  return (
    <Container $expanded={expanded}>

      <List>

        <SectionNavLink to="/settings/versions">
          <ListItem
            button
            onClick={close}
          >
            <StyledBookIcon />
            <ListItemText primary={i18n("Bible Versions")} />
          </ListItem>
        </SectionNavLink>

        <ListItem
          button
          onClick={openDisplaySettings}
        >
          <StyledFormatSizeIcon />
          <ListItemText primary={i18n("Bible Display Settings")} />
        </ListItem>

        {hasMyPlan && (([ `ADMIN`, `EDITOR`, `CONTRIBUTOR` ].includes(adminLevel) || process.env.REACT_APP_STAGE === `development`)) &&
          <SectionNavLink to="/settings/channels">
            <ListItem
              button
              onClick={close}
            >
              <StyledOndemandVideoIcon />
              <ListItemText primary={i18n("Channels")} />
            </ListItem>
          </SectionNavLink>
        }

        <SectionNavLink to="/settings/accessibility">
          <ListItem
            button
            onClick={close}
          >
            <StyledAccessibilityIcon />
            <ListItemText primary={i18n("Accessibility")} />
          </ListItem>
        </SectionNavLink>

      </List>

    </Container>
  )
}

export default memo(SettingsLeftPanel)