import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'

const PassagePopperContainer = styled.div`
  padding: ${({ $hasHeader }) => $hasHeader ? `0 16px 16px` : `16px`};
  font-size: 16.4px;
  position: relative;
  flex: 1;
  overflow-y: auto;
  text-align: initial;

  .ViewChapterIconButton:hover {
     background-color: rgb(255 255 255/.1);
  }

  /* the [data-vsnum-id] ensures it overrides the style in ContainerWithPassagePopper */
  .TextContent-tag-v[data-vsnum-id],
  .TextContent-tag-vp[data-vsnum-id] {
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  /* the [data-dot-id] ensures it overrides the style in ContainerWithPassagePopper */
  .TextContent-tag-x[data-dot-id] {
    color: ${({ theme }) => theme.palette.grey[600]};
    opacity: 1;

    :hover {
      color: ${({ theme }) => theme.palette.grey[200]};
    }
  }

  /* the [data-dot-id] ensures it overrides the style in ContainerWithPassagePopper */
  .TextContent-tag-f[data-dot-id],
  .TextContent-tag-zApparatusJson[data-dot-id] {
    opacity: 1;

    :hover {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }

  .PassageContent-BibleTextContainer {
    color: white;
    padding: 0;
  }

  .TextContent-tag-mt,
  .TextContent-tag-mt1,
  .TextContent-tag-mt2,
  .TextContent-tag-mt3,
  .TextContent-tag-mt3,
  .TextContent-tag-mt4,
  .TextContent-tag-mt5,
  .TextContent-tag-mt6,
  .TextContent-tag-mt7,
  .TextContent-tag-mt8,
  .TextContent-tag-mt9,
  .TextContent-tag-mte,
  .TextContent-tag-ms,
  .TextContent-tag-s2 {
    color: ${({ theme }) => theme.palette.grey[400]};
  }

  .TextContent-tag-mr,
  .TextContent-tag-s,
  .TextContent-tag-s1,
  .TextContent-tag-sr,
  .TextContent-tag-r,
  .TextContent-tag-rq,
  .TextContent-tag-sp,
  .TextContent-tag-qa,
  .TextContent-tag-peh,
  .TextContent-tag-samech,
  .TextContent-tag-selah,
  .TextContent-tag-fk {
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  .TextContent-tag-s3 {
    color: ${({ theme }) => theme.palette.warning.light};
  }

  .PassageContent-StyledTextContentMarkupContainer {
    padding-bottom: 0;
  }

  .TextContent-highlighted-loc {
    background: ${({ theme }) => theme.palette.markupColors.SYSTEM.highlight};
  }

`

export default memo(PassagePopperContainer)