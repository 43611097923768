import { useCallback } from 'react'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from './useMutationContext'
import useInstanceValue from './useInstanceValue'

import deleteModulePiecesMutation from '../graphql/mutations/deleteModulePieces'
import { defaultExpectedUserUpdate, recordExpectedResponseData } from './useGoUpdateTable'
import { clearUndoItems } from './useGoUndo'

const useSetUpPiecesAfterPassageChangeOutlining = ({
  moduleId,
  goCreateModulePiece,
  modulePieces,
}) => {

  const context = useMutationContext()
  const getContext = useInstanceValue(context)
  const client = useApolloClient()

  const [ deleteModulePieces, deleteModulePiecesResult ] = useMutation(deleteModulePiecesMutation)

  const getModulePieces = useInstanceValue(modulePieces)

  const setUpPiecesAfterPassageChange = useCallback(
    ({ newModulePassage, oldModulePassage, updatedModulePassage, savedAt }) => {

      if(newModulePassage) {

        const { fromLoc } = newModulePassage
        goCreateModulePiece({
          position: `break`,
          ordering: parseInt(`${fromLoc}001`),
        })

      } else {

        const goDeleteModulePieces = (orderingFrom, orderingTo) => {

          const modulePieceDeletedIds = (
            getModulePieces()
              .filter(({ ordering }) => (
                ordering >= orderingFrom
                && ordering < orderingTo + 1
              ))
              .map(({ id }) => id)
          )

          const expectedResponseData = {
            ...defaultExpectedUserUpdate,
            modulePiece: {
              __typename: `ModulePieceUpdate`,
              deletedIds: modulePieceDeletedIds.sort(),  // since they come back from the server sorted
              rows: [],
            },
          }

          deleteModulePieces({
            variables: {
              moduleId,
              orderingFrom,
              orderingTo,
              savedAt,
            },
            context: {
              ...getContext(),
              // optimistically delete just the main record for now
              // TODO: the UserUpdate which is returned should take care of deleting the subtables (when relevant)
              expectedResponse: {
                deleteModulePieces: expectedResponseData,
              },
            },
          })

          recordExpectedResponseData({ client, expectedResponseData: expectedResponseData })

        }

        const { fromLoc, toLoc } = updatedModulePassage

        if(
          oldModulePassage.fromLoc < fromLoc
          || oldModulePassage.toLoc > toLoc
        ) {
          goDeleteModulePieces(0, parseInt(`${fromLoc}000`))
          goDeleteModulePieces(parseInt(`${toLoc}999`), 99999999999)
          clearUndoItems(moduleId)
        }

      }

    },
    [ goCreateModulePiece, moduleId, getModulePieces, deleteModulePieces, getContext, client ],
  )

  if(deleteModulePiecesResult.error) {
    // Nothing to do here since it has gone into queuedMutations and will try again when relevant
    console.error('deleteModulePiecesResult.error', deleteModulePiecesResult.error)
  }

  return setUpPiecesAfterPassageChange

}

export default useSetUpPiecesAfterPassageChangeOutlining
