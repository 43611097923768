import { useMemo, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from "react-router-dom"
import { useApolloClient } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'
import { LoggedInUserContext } from '../context/LoggedInUser'
import { IS_EMBED } from '../utils/constants'

import projectQuery from '../graphql/queries/project'
import updateProjectMutation from '../graphql/mutations/updateProject'
import deleteProjectMutation from '../graphql/mutations/deleteProject'

export const getDefaultProject = userId => ({
  __typename: `Project`,
  name: ``,
  starred: false,
  inDoubleStudyMode: false,
  studyModule1Id: null,
  studyModule2Id: null,
  notesModuleId: null,
  notesInDrawer: false,
  notesHeightPercentage: 30,
  notesWidthPercentage: 25,
  firstColumnWidthPercentage: 50,
  passageBookmarks: [],
  deletedAt: null,
  folderAncestry: null,
  userId,
})

const useGoUpdateProject = ({
  project,
  onDelete,
}={}) => {

  const history = useHistory()
  const user = useContext(LoggedInUserContext)
  const client = useApolloClient()

  const [ updateProject, updateProjectResult ] = useMutation(updateProjectMutation)
  const [ deleteProject, deleteProjectResult ] = useMutation(deleteProjectMutation)

  const goUpdate = useGoUpdateTable({
    currentData: project || getDefaultProject(IS_EMBED ? `embed` : (user || {}).id),
    updateFunc: updateProject,
    updateResult: updateProjectResult,
    deleteFunc: deleteProject,
    deleteResult: deleteProjectResult,
    onDelete,
  })

  const toReturn = useMemo(
    () => {
      if(!project) {
        return [
          (initialProjectData={}, options={}) => {
            // Create a new project

            const now = Date.now()

            const newData = goUpdate[0]({
              ...initialProjectData,
              createdAt: now,
              modifiedAt: now,
              openedOrModifiedAt: now,
            })

            client.writeQuery({
              query: projectQuery,
              data: {
                project: {
                  moduleByProjects: [],
                  ...newData,
                },
              },
              variables: {
                id: newData.id,
              },
            })

            if(!options.skipNav) {
              history.push(`/project/${newData.id}`)
            }

            return newData
          },
        ]
      }

      return goUpdate
    },
    [ project, goUpdate, client, history ],
  )

  return toReturn
}

export default useGoUpdateProject