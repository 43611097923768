import { memo } from 'react'
import styled from 'styled-components'

import useFormattedSearchValue from '../../hooks/useFormattedSearchValue'

const Container = styled.div`
  position: relative;
  background: ${({ $hasError, theme }) => $hasError ? theme.palette.tertiary.light : `white`};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  min-height: 59px;
  font-size: 16px;
`

const InputContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 50px;
  overflow: auto;
  font-size: 16px;
  display: flex;
`

const FormattedInputText = styled.div`
  color: black;
  position: relative;
  display: block;
  flex: 1;
  line-height: 52px;
  padding: 3px 14px;
  user-select: none;
  white-space: pre;
`

const InvisibleFormattedInputText = styled(FormattedInputText)`
  opacity: 0;
  padding-right: 64px;
`

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  color: transparent;
  caret-color: black;
  border: none;
  outline: none;
  padding: 3px 14px;
  font: inherit;

  ::selection {
    background: rgb(15 123 255 / .34);
  }
`

const SearchTextField = ({
  value,
  tabAddition,
  action,
  versionId,
  hasError,
  button,
  inputRef,
  inputContainerRef,
  ...otherProps
}) => {

  const formattedValue = useFormattedSearchValue({ value, action, versionId, tabAddition })

  return (
    <Container
      $hasError={hasError}
    >

      <InvisibleFormattedInputText>
        {/* This is to have SearchTextFieldContainer automatically widen properly */}
        {formattedValue}
      </InvisibleFormattedInputText>

      <InputContainer ref={inputContainerRef}>
        <FormattedInputText>

          {formattedValue}

          <StyledInput
            value={value}
            ref={inputRef}
            maxLength={1000}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            data-gramm="false"
            {...otherProps}
          />

        </FormattedInputText>
      </InputContainer>

      {button}

    </Container>
  )
}

export default memo(SearchTextField)