import { useContext, useMemo } from "react"
import { findAutoCompleteSuggestions, getRefsFromPassageStr, isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"
import { getLocFromRef } from "@bibletags/bibletags-versification"

import useDataQuery from './useDataQuery'
import useVersionInfos from './useVersionInfos'
import { GET_SETTINGS_FOR_SEARCH, GET_HELP_FOR_SEARCH } from '../utils/constants'
import useVersesPieces from "./useVersesPieces"
import useEqualObjsMemo from "./useEqualObjsMemo"
import useBibleSearchResults from "./useBibleSearchResults"
import useIsLoggedIn from "./useIsLoggedIn"
import useMenuSearchItems from "./useMenuSearchItems"
import { ChannelIdInPWAContext } from "../context/ChannelIdInPWA"

import projectsQuery from '../graphql/queries/projects'
// import highlightsQuery from '../graphql/queries/highlights'

const useSearchResults = ({ searchText }) => {

  let { unhiddenSelectedVersionInfos=[] } = useVersionInfos()

  const menuItemsForSearch = useMenuSearchItems()
  const settingsForSearch = useMemo(() => GET_SETTINGS_FOR_SEARCH(), [])
  const helpForSearch = useMemo(() => GET_HELP_FOR_SEARCH(), [])

  const isLoggedIn = useIsLoggedIn()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)

  //////////////////////////////
  // BEGIN COLLECTING RESULTS //
  //////////////////////////////

  // 1. Passage look-ups

  const { refs=[], versionId } = (!isOrigLanguageSearch && getRefsFromPassageStr(searchText)) || {}
  const foundPassageRef = refs.length !== 0
  const searchWasForEntireChapter = foundPassageRef && refs[0].verse === undefined

  const [ fromLoc, toLoc ] = (
    !foundPassageRef
      ? []
      : (
        searchWasForEntireChapter
          ? [
            getLocFromRef({ ...refs[0], verse: 1 }),
            getLocFromRef({ ...refs[0], verse: 3 }),
          ]
          : refs.map(getLocFromRef)
      )
  )

  if(foundPassageRef) {
    const restrictToTestament = refs[0].bookId <= 39 ? `ot` : `nt`
    unhiddenSelectedVersionInfos = (
      unhiddenSelectedVersionInfos
        .filter(({ version: { partialScope }={} }) => (
          !partialScope
          || partialScope === restrictToTestament
        ))
    )
  }

  const getExtraInfo = idx => (
    ((versionId && idx > 0) || !foundPassageRef)
      ? {}
      : {
        versionId: !versionId && (unhiddenSelectedVersionInfos[idx] || {}).id,
        bookId: refs[0].bookId,
        ...(
          searchWasForEntireChapter
            ? {
              fromLoc: fromLoc.slice(0, -3),
            }
            : {
              fromLoc,
              toLoc,
            }
        ),
        startChapter: refs[0].chapter,
      }
  )

  const getUseVersesPiecesParams = (idx, vId) => ({
    fromLoc,
    toLoc,
    versionId: vId || (unhiddenSelectedVersionInfos[idx] || {}).id,
    skip: !foundPassageRef || ((!!versionId || !unhiddenSelectedVersionInfos[idx] || (toLoc && idx > 3)) && !vId),
  })

  let passageInfoSets = [
    {
      ...getExtraInfo(0),
      versionId: foundPassageRef && (versionId || (unhiddenSelectedVersionInfos[0] || {}).id),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(0, versionId)),
    },
    {
      ...getExtraInfo(1),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(1)),
    },
    {
      ...getExtraInfo(2),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(2)),
    },
    {
      ...getExtraInfo(3),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(3)),
    },
    {
      ...getExtraInfo(4),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(4)),
    },
    {
      ...getExtraInfo(5),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(5)),
    },
    {
      ...getExtraInfo(6),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(6)),
    },
    {
      ...getExtraInfo(7),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(7)),
    },
    {
      ...getExtraInfo(8),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(8)),
    },
    {
      ...getExtraInfo(9),
      piecesAndLoading: useVersesPieces(getUseVersesPiecesParams(9)),
    },
  ]

  let passageInfoLoading = false
  passageInfoSets.forEach(set => {
    set.pieces = set.piecesAndLoading[0]
    passageInfoLoading = passageInfoLoading || set.piecesAndLoading[1]
    delete set.piecesAndLoading
  })

  // 2. Bible search

  const { bibleSearchResults, includeVersionIds, inVersionAbbrs, inVersionIds, bibleSearchError } = useBibleSearchResults({
    searchText,
    isInitialSearch: true,
    skip: foundPassageRef,
  })

  // 3. Projects

  const { projects: projectsAndCount } = useDataQuery({
    projectsQuery,
    variables: {
      query: searchText,
      limit: 3,
    },
    skip: isOrigLanguageSearch || !isLoggedIn || !!channelIdInPWA,
  })

  // 4. Highlights

  const highlightsAndCount = { highlights: [], count: 0 }
  // const { highlights: highlightsAndCount } = useDataQuery({
  //   highlightsQuery,
  //   variables: {
  //     query: fromLoc,
  //     limit: 3,
  //   },
  //   skip: isOrigLanguageSearch,
  // })
  // also look by content of the text highlighted?

  // 5. Alerts

    // use alertsItems query (or what is already stored locally?)

  // 6. Online Courses

  // 7. Bible versions

  const { versions } = useVersionInfos({
    searchText,
    limit: 2,
    skip: isOrigLanguageSearch,
  })

  // 8. App items (menu, settings)

  const appItems = findAutoCompleteSuggestions({
    str: searchText,
    suggestionOptions: (
      isOrigLanguageSearch
        ? []
        : [
          ...menuItemsForSearch,
          ...settingsForSearch,
        ]
    ),
    max: 3,
  })

  // 9. Help items

  const helpItems = findAutoCompleteSuggestions({
    str: searchText,
    suggestionOptions: (
      isOrigLanguageSearch
        ? []
        : helpForSearch
    ),
    max: 3,
  })

  // 10. Shared with me
  // 11. BSB
  // 12. EQUIP blog posts

    // use searchShared query

  passageInfoSets = useEqualObjsMemo(passageInfoSets.filter(({ versionId, pieces }) => versionId && (pieces || []).length > 0))

  return {
    foundPassageRef,
    passageInfoLoading,
    passageInfoSets,
    bibleSearchResults,
    bibleSearchError,
    includeVersionIds,
    inVersionAbbrs,
    inVersionIds,
    projectsAndCount,
    highlightsAndCount,
    versions,
    appItems,
    helpItems,
  }
}

export default useSearchResults