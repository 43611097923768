import { memo } from 'react'

import { LoggedInUserContextProvider } from '../../context/LoggedInUser'
import { LocalInfoContextProvider } from '../../context/LocalInfo'
import { SelectedVersionsContextProvider } from '../../context/SelectedVersions'
import { FormattingKeysContextProvider } from '../../context/FormattingKeys'
import { LastApolloResponseContextProvider } from '../../context/LastApolloResponse'
import { StudyBibleItemInfoContextProvider } from '../../context/StudyBibleItemInfo'
import { ChannelItemInfoContextProvider } from '../../context/ChannelItemInfo'
import { ChannelIdInPWAContextProvider  } from '../../context/ChannelIdInPWA'

export const GlobalContextProviders = ({
  children,
}) => (
  <LoggedInUserContextProvider>
    <LocalInfoContextProvider>
      <SelectedVersionsContextProvider>
        <FormattingKeysContextProvider>
          <LastApolloResponseContextProvider>
            <StudyBibleItemInfoContextProvider>
              <ChannelItemInfoContextProvider>
                <ChannelIdInPWAContextProvider>
                  {children}
                </ChannelIdInPWAContextProvider>
              </ChannelItemInfoContextProvider>
            </StudyBibleItemInfoContextProvider>
          </LastApolloResponseContextProvider>
        </FormattingKeysContextProvider>
      </SelectedVersionsContextProvider>
    </LocalInfoContextProvider>
  </LoggedInUserContextProvider>
)

export default memo(GlobalContextProviders)