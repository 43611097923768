import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const StyledMarkupLine = styled(MarkupLine)`
  background-image: linear-gradient(to bottom, transparent 66%, ${({ theme, $color }) => theme.palette.markupColors[$color].highlight} 66%);
  margin: 0 -.15em;
  padding: 0 .15em;
  z-index: -9;
`

const BaseHighlight = ({
  color,
  ...otherProps
}) => (
  <StyledMarkupLine
    {...otherProps}
    $color={color}
  />
)


export default memo(BaseHighlight)