import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import useGoUpdateTable from './useGoUpdateTable'

// import formattingKeysQuery from '../graphql/queries/formattingKeys'
import updateFormattingKeyMutation from '../graphql/mutations/updateFormattingKey'
import deleteFormattingKeyMutation from '../graphql/mutations/deleteFormattingKey'

export const getDefaultFormattingKey = () => ({
  __typename: `FormattingKey`,
  name: "",
  type: "MARKUP",
  info: {},
  public: false,
})

const useGoUpdateFormattingKey = ({
  formattingKey,
  undoRedoStack,
}) => {
  // const client = useApolloClient()

  const [ updateFormattingKey, updateFormattingKeyResult ] = useMutation(updateFormattingKeyMutation)
  const [ deleteFormattingKey, deleteFormattingKeyResult ] = useMutation(deleteFormattingKeyMutation)

  const onUpdate = useCallback(
    ({ newData }) => {
      // Update formattingKeys query
      // client.writeQuery({
      //   query: moduleDotQuery,
      //   data: {
      //     moduleDot: newData,
      //   },
      //   variables: {
      //     id: newData.id,
      //   },
      // })
    },
    [],
  )

  const goUpdate = useGoUpdateTable({
    currentData: formattingKey || getDefaultFormattingKey(),
    updateFunc: updateFormattingKey,
    updateResult: updateFormattingKeyResult,
    deleteFunc: deleteFormattingKey,
    deleteResult: deleteFormattingKeyResult,
    undoRedoStack,
    onUpdate,
  })

  return goUpdate
}

export default useGoUpdateFormattingKey