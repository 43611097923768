import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Tooltip from '@material-ui/core/Tooltip'

import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useThrottleCallback from '../../../hooks/useThrottleCallback'

import CustomSwitch from '../../common/CustomSwitch'
import TextAndShortcutCombo from '../../common/TextAndShortcutCombo'

const Container = styled.div`
  position: absolute;
  top: 11px;
  right: 7px;
  border-radius: 4px;
  padding-left: 7px;
  z-index: 5;
  user-select: none;

  .MuiFormControlLabel-root {
    margin-right: 10px;
  }
`

const EditingButton = ({
  module,
  modifierDisable=false,
}) => {

  const [ goUpdateModule ] = useGoUpdateModule({ module })

  const { inEditingMode } = module

  const toggleEditing = useThrottleCallback(
    ({ target }) => {
      goUpdateModule({
        inEditingMode: target.checked,
      })
    },
    500,
  )

  const disabled = inEditingMode === null

  return (
    <Tooltip
      title={
        disabled
          ? i18n("Subscribe to Biblearc TOOLS in order to edit")
          : (
            <TextAndShortcutCombo
              text={i18n("Toggle on/off")}
              shortcutCombo={i18n("spacebar")}
            />
          )
      }
    >
      <Container>
        <CustomSwitch
          checked={!!inEditingMode}
          disabled={disabled || modifierDisable}
          onChange={toggleEditing}
          label={i18n("Editing")}
          tabIndex={-1}
        />
      </Container>
    </Tooltip>
  )
}

export default memo(EditingButton)