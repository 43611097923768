import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import { i18n } from 'inline-i18n'
import copy from 'copy-to-clipboard'

import selectWholeWords from '../../../../utils/selectWholeWords'

import FormattingMenuList from '../../../markup/FormattingMenuList'
import PassageInNotesWordSelectionSearchMenuItem from '../PassageInNotesWordSelectionSearchMenuItem'

const selectText = async ({
  markup=[],
  updateMarkup,
  textSelectionInfo,
  formattingKeyId,
  mode,
  closeOptions,
  getOptionsPopperInfo,
  setOptionsPopperInfo,
  versionId,
}) => {

  const {
    selection,
    textRange,
    commonAncestorEl,
    knownToBeStillSelecting,
  } = textSelectionInfo

  if(!textRange || knownToBeStillSelecting || !commonAncestorEl.closest('.passage-in-notes-content-text-container')) {

    const { $type } = getOptionsPopperInfo()
    if($type === 'selection' || !window.getSelection().isCollapsed) {
      closeOptions()
    }

    return
  }

  const {
    selectionStart,
    selectionEnd,
    firstWordEl,
    lastWordEl,
    partialWordSelection,
    copyText,
    searchStr,
    words,
    showSearchSuggestions,
    bookId,
  } = selectWholeWords({ selection, textSelectionInfo, versionId })

  const existingMarkupDetailsByType = {}

  const markupRangeMatches = ({ start, end }) => (
    start.loc === selectionStart.loc
    && start.wordNumberInVerse === selectionStart.wordNumberInVerse
    && end.loc === selectionEnd.loc
    && end.wordNumberInVerse === selectionEnd.wordNumberInVerse
  )

  markup.forEach(({ start, end, type, ...details }) => {
    if(markupRangeMatches({ start, end })) {
      existingMarkupDetailsByType[type] = details
    }
  })

  const toggleMarkup = ({ type, color }) => event => {
    event.preventDefault()
    const doRemove = (existingMarkupDetailsByType[type] || {}).color === color
    const isArrowType = /ARROW/.test(type)
    updateMarkup([
      ...markup.filter(markupItem => !(
        (
          type === markupItem.type
          || (
            isArrowType
            && /ARROW/.test(markupItem.type)
          )
        )
        && markupRangeMatches(markupItem)
      )),
      ...(doRemove ? [] : [{
        type,
        start: selectionStart,
        end: selectionEnd,
        color,
      }]),
    ])
  }

  const boundingClientRect = textRange.getBoundingClientRect()
  const scrollContainerEl = commonAncestorEl.closest('.flipeditor-editor-container')
  const editorEl = commonAncestorEl.closest('.DraftEditor-root')
  if(!scrollContainerEl) return {}
  const containerBoundingClientRect = scrollContainerEl.getBoundingClientRect()

  const pseudoElStyle = {
    top: boundingClientRect.top - containerBoundingClientRect.top + scrollContainerEl.scrollTop,
    left: boundingClientRect.left - containerBoundingClientRect.left - parseInt(window.getComputedStyle(editorEl).getPropertyValue('padding-left'), 10),
    width: boundingClientRect.width,
    height: boundingClientRect.height,
  }

  if(boundingClientRect.width === undefined || !firstWordEl || !lastWordEl) {
    closeOptions()
    return
  }

  setOptionsPopperInfo({
    open: true,
    onClose: () => document.getSelection().empty(),
    $type: 'selection',
    isAnchoredBySelection: true,
    pseudoElStyle,
    tabs: [
      {
        icon: null,
        tooltipLabel: i18n("Info"),
        content: (
          null
        ),
      },
      {
        icon: null,
        tooltipLabel: i18n("Edit"),
        content: (
          null
        ),
      },
    ],
    children: (
      <div>

        <MenuList>

          <MenuItem
            onClick={() => copy(copyText)}
            tabIndex={-1}
          >
            {i18n("Copy")}
          </MenuItem>

          {showSearchSuggestions && 
            <PassageInNotesWordSelectionSearchMenuItem
              baseSearchStr={searchStr}
              words={words}
              bookId={bookId}
              selectionStart={selectionStart}
              selectionEnd={selectionEnd}
              versionId={versionId}
            />
          }

          {/* IMPORTANT: As I add things here, I need to update `verticalSpaceNeeded` in PassageInNotesContent */}

        </MenuList>

        {mode === `edit` &&
          <>

            <Divider />

            <FormattingMenuList
              toggleMarkup={toggleMarkup}
              existingMarkupDetailsByType={existingMarkupDetailsByType}
              partialWordSelection={partialWordSelection}
              formattingKeyId={formattingKeyId}
              inNotes
            />

          </>
        }

        {/* {!partialWordSelection &&
          <>

            <Divider />

            <MenuList>

              <MenuItem
                // onClick={}
                tabIndex={-1}
                disabled
              >
                {i18n("Other options")}
              </MenuItem>

            </MenuList>

          </>
        } */}

      </div>
    ),
  })
}

export default selectText

// Edit tab
//   Copy
//   Search
//   ------
//   [format]
//   ------
//   Other options
//     Cross out these words
//     Replace these words
//     Remove these words (if they were inserted)
//     Extract these words
// Info tab
//   highlight word(s) in all versions using alignment data