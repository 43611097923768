import { useCallback, useRef } from 'react'
// import { i18n } from 'inline-i18n'

import useInstanceValue from './useInstanceValue'
import useNextPrevChapters from './useNextPrevChapters'
import usePassageArrowShortcuts from './usePassageArrowShortcuts'
import useEffectAsync from './useEffectAsync'
import { equalObjs, preventDefaultEvent } from '../utils/misc'

export const usePassageNextPreviousInfo = ({
  bookId,
  chapter,
  versionId,
  updatePassageBookmark,
  columnIdx,
}) => {

  // This is used to maintain the scroll position if you flip next and then back.
  const prevNextScrollYFraction = useRef({})

  const { previousChapter, nextChapter } = useNextPrevChapters({
    bookId,
    chapter,
    versionId,
  })

  const getPreviousChapter = useInstanceValue(previousChapter)
  const getNextChapter = useInstanceValue(nextChapter)

  const gotoPreviousChapter = useCallback(
    () => {
      const ref = getPreviousChapter()
      if(!ref) return
      const { oldPassageBookmark } = updatePassageBookmark({
        ref,
        scrollYFraction: prevNextScrollYFraction.current.previous || 0,
      })
      prevNextScrollYFraction.current = {
        next: oldPassageBookmark.scrollYFraction,
        newCurrentRef: ref,
      }
    },
    [ updatePassageBookmark, getPreviousChapter ],
  )

  const gotoNextChapter = useCallback(
    () => {
      const ref = getNextChapter()
      if(!ref) return
      const { oldPassageBookmark } = updatePassageBookmark({
        ref,
        scrollYFraction: prevNextScrollYFraction.current.next || 0,
      })
      prevNextScrollYFraction.current = {
        previous: oldPassageBookmark.scrollYFraction,
        newCurrentRef: ref,
      }
    },
    [ updatePassageBookmark, getNextChapter ],
  )

  usePassageArrowShortcuts({
    gotoPreviousChapter,
    gotoNextChapter,
    columnIdx,
  })

  useEffectAsync(
    () => {
      if(!equalObjs(prevNextScrollYFraction.current.newCurrentRef, { bookId, chapter })) {
        prevNextScrollYFraction.current = {}
      }
    },
    [ bookId, chapter ],
  )

  return {
    previousProps: {
      disabled: !previousChapter,
      onClick: gotoPreviousChapter,
      onMouseDown: preventDefaultEvent,
      tabIndex: -1,
    },
    nextProps: {
      disabled: !nextChapter,
      onClick: gotoNextChapter,
      onMouseDown: preventDefaultEvent,
      tabIndex: -1,
    },
  }
}

export default usePassageNextPreviousInfo