import { gql } from '@apollo/client'
import userUpdate_fields from '../fragments/userUpdate_fields'

export default gql
`
  mutation deleteModulePieces($moduleId: ID!, $position: String, $orderingFrom: BigInt, $orderingTo: BigInt, $savedAt: Milliseconds!, $updatedSince: Milliseconds) {
    deleteModulePieces(moduleId: $moduleId, position: $position, orderingFrom: $orderingFrom, orderingTo: $orderingTo, savedAt: $savedAt, updatedSince: $updatedSince) {
      ${userUpdate_fields}
    }
  }
`