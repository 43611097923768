import { memo, useContext, useRef } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { Snackbar } from '@material-ui/core'

import { TransitionUp } from '../common/CustomSnackbar'

import useEffectAsync from '../../hooks/useEffectAsync'
import useVersionInfos from '../../hooks/useVersionInfos'
import useAccountSetting from '../../hooks/useAccountSetting'
import { ChannelItemInfoContext } from '../../context/ChannelItemInfo'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import { getLocalStorage, removeLocalStorage } from '../../utils/misc'

import ChannelItem from '../study-bible/ChannelItem'

const StyledSnackbar = styled(Snackbar)`
  left: 50%;
  right: auto;
  transform: translateX(-50%);

  @media (max-width: 599px) {
    bottom: 10px;
  }

  @media (max-width: 384px) {
    left: 10px;
    right: 10px;
    transform: none;
  }

  .MuiSnackbarContent-root {
    padding: 0;
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
  }
  .MuiSnackbarContent-message {
    padding: 0;
    transition: box-shadow .15s ease-in-out;
    :focus-within {
      box-shadow: 0px 3px 12px -1px rgba(0,0,0,0.5),0px 6px 20px 0px rgba(0,0,0,0.35),0px 1px 36px 0px rgba(0,0,0,0.32);
    }
  }
`

const ChannelItemSnackbar = () => {

  const { channelItemInfo, setChannelItemInfo } = useContext(ChannelItemInfoContext)

  const {
    anchorEl,
    studyBibleItem,  // warning: this will not be up-to-date if edited by StudyBibleItemEditor; but that should be okay, since from the editor the user can only close
    versionId,
    autoplay,
    onClose,
    ...otherProps
  } = channelItemInfo

  const [ listeningHistory, x1, x2, loading ] = useAccountSetting(`listening-history`, [])  // eslint-disable-line no-unused-vars
  const onInitialLoad = useRef(true)
  const { defaultVersionId } = useVersionInfos()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  useEffectAsync(
    () => {
      if(!loading && onInitialLoad.current) {
        onInitialLoad.current = false

        const { open, id, title, channelId } = listeningHistory[0] || {}

        if(
          open
          && (
            channelIdInPWA
            || !getLocalStorage(`message-snackbar-open`)
          )
        ) {
          const studyBibleFollow = {
            id: `none`,
            channelId,
          }
          setChannelItemInfo({
            studyBibleItem: {
              id,
              title,
              ...(channelId ? { studyBibleFollow } : {}),
              type: `CHANNEL`
            },
            versionId: defaultVersionId,
            anchorEl: true,
            autoplay: false,
          })
        } else {
          removeLocalStorage(`message-snackbar-open`)
        }
      }
    },
    [ loading ],
  )

  return (
    <StyledSnackbar
      className="ChannelItemSnackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      TransitionComponent={TransitionUp}
      open={!!anchorEl}
      elevation={24}
      transitionDuration={300}
      message={
        <ChannelItem
          studyBibleItem={studyBibleItem}
          onClose={onClose}
          versionId={versionId}
          autoplay={autoplay}
        />
      }
      {...otherProps}
    />
  )
}

export default memo(ChannelItemSnackbar)