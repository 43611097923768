import React, { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import IconButton from '@material-ui/core/IconButton'
import LoopIcon from '@material-ui/icons/Loop'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import { useCounter } from 'react-use'
import { useHistory } from 'react-router-dom'

import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import { getLocalStorage, preventDefaultEvent, setLocalStorage } from '../../utils/misc'

const Container = styled.div`
  flex: 1;
  background-color: rgb(116 116 116);
  padding: 16px;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[300]};
  position: relative;
  white-space: normal;

  &&& p {
    margin: 5px 0;
  }

  &&& ol, &&& ul {
    margin-block-start: .5em;
    margin-block-end: .5em;
    padding-inline-start: 20px;
  }

  &&& ol {
    list-style-type: upper-roman;
  }

  &&& ol ol {
    padding-inline-start: 10px;
    list-style-type: lower-alpha;
  }

  &&& ol ol ol {
    list-style-type: decimal;
  }

  &&& ol ol ol ol {
    list-style-type: lower-roman;
  }

  &&& li {
    margin: 5px 0;
  }

  &&& strong {
    font-weight: 400;
    color: white;
  }

  &&& a {
    font-weight: bold;
    transition: opacity .1s ease-in-out;
    color: white;
    text-decoration: none;
  }

  &&& a:hover {
    opacity: .35;
  }

`

const Title = styled.h1`
  &&& {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: white;
    margin: 0 25px 10px 0;
    border: none;
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
  color: white;

  :hover {
    background-color: rgb(255 255 255/.1);
  }

  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`

const OriginalLanguageWordAltLexicons = ({
  alts=[],
  disabled,
  ...otherProps
}) => {

  const [ pageIdx, { inc }] = useCounter(0)
  const flip = useCallback(() => inc(), [ inc ])
  const effectivePageIdx = pageIdx % alts.length

  // TODO: use useHasShown hook (but note the undefined issue below)
  const [ forceShowTooltip, setForceShowTooltip ] = useState(() => (getLocalStorage(`hasFlippedThroughLexicons`, false) && undefined))
  const [ setTooltipTimeout ] = useSetTimeout()

  const history = useHistory()

  const onClick = useCallback(
    event => {
      const { target } = event

      if(disabled) {
        preventDefaultEvent(event)
        return
      }

      if(/^A$/i.test(target.tagName)) {
        const href = target.getAttribute(`href`)

        if(/^#search=/.test(href)) {
          history.replace({
            hash: href,
            state: {
              executeSearch: true,
            },
          })
        } else if(href) {
          window.open(href, '_blank')
        }

        preventDefaultEvent(event)
      }
    },
    [ disabled, history ],
  )

  useEffectAsync(
    () => {
      if(forceShowTooltip === false) {
        setTooltipTimeout(() => {
          setForceShowTooltip(true)
        }, 500)
      }
    },
    [],
  )

  useEffectAsync(
    () => {
      if(forceShowTooltip) {
        setTooltipTimeout(() => {
          setForceShowTooltip(false)
        }, 2000)
      }
    },
    [ forceShowTooltip ],
  )

  useEffectAsync(
    () => {
      if(pageIdx > 0 && forceShowTooltip === true) {
        setForceShowTooltip(false)
        setLocalStorage(`hasFlippedThroughLexicons`, true)
      }
    },
    [ pageIdx ],
  )

  if(!alts[0]) return null

  const { md, title } = alts[effectivePageIdx]

  return (
    <Container
      {...otherProps}
      className="OriginalLanguageWordInfo-RelatedInfo"
      onClick={onClick}
    >

      <Title>
        {title}
      </Title>

      <ReactMarkdown>
        {md}
      </ReactMarkdown>

      {alts.length > 1 &&
        <Tooltip
          title={i18n("Flip through lexicons")}
          {...(forceShowTooltip === undefined ? {} : {
            placement: `left`,
            arrow: true,
            open: forceShowTooltip,
          })}
        >
          <StyledIconButton
            className="OriginalLanguageWordAltLexicons-IconButton"
            onClick={flip}
            variant="outlined"
          >
            <LoopIcon />
          </StyledIconButton>
        </Tooltip>
      }

    </Container>
  )
}

export default memo(OriginalLanguageWordAltLexicons)