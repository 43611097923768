import { memo } from 'react'
import styled from 'styled-components'

import useAppSize from '../../hooks/useAppSize'

const StyledDiv = styled.div`

  :focus-within::after, &.pseudo-focus::after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0;
    left: ${({ $focusIndicatorLeft }) => $focusIndicatorLeft || 0};
    right: ${({ $focusIndicatorRight }) => $focusIndicatorRight || 0};
    height: 2px;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

`

const DivWithFocusIndicator = props => {

  const { miniHeaderSize } = useAppSize()

  if(miniHeaderSize) return <div {...props} />

  return <StyledDiv {...props} />

}

export default memo(DivWithFocusIndicator)