import { memo, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { isOriginalLanguageSearch } from "@bibletags/bibletags-ui-helper"

import { IS_EMBED, MINIMUM_MEDIUM_WIDTH } from '../../utils/constants'
import useSearchResults from '../../hooks/useSearchResults'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useRecentSearchManager from '../../hooks/useRecentSearchManager'
import useAppSize from '../../hooks/useAppSize'
import useEmbedSettings from '../../hooks/useEmbedSettings'
import useEffectAsync from '../../hooks/useEffectAsync'
import { getOriginalWordsForSearch } from '../../utils/misc'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'

import PassageSearchResultsRow from './PassageSearchResultsRow'
import SearchResultsError from './SearchResultsError'
import BibleSearchResults from './BibleSearchResults'
import ProjectSearchResults from './ProjectSearchResults'
// import VersionSearchResults from './VersionSearchResults'
import AppItemSearchResults from './AppItemSearchResults'
import HelpItemSearchResults from './HelpItemSearchResults'
import OriginalLanguageWordInfo from '../common/OriginalLanguageWordInfo'
import Loading from '../common/Loading'

const Container = styled.div`
  background: white;
  width: 1100px;
  max-width: 100vw;
  min-height: min(${({ $minHeight }) => $minHeight}px, calc(100vh - 59px));
  max-height: min(800px, calc(100vh - 59px));
  position: relative;
  display: flex;
  align-items: stretch;

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH-1}px) {
    flex-direction: column;
    min-height: calc(100vh - 59px);
    max-height: none;
  }
`

const BibleSearchColumn = styled.div`
  flex: 4;
  /* flex: 3; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const StyledBibleSearchColumn = styled(BibleSearchColumn)`
  padding: 15px;
`

const WordInfoColumn = styled.div`
  flex: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.text.primary};  // so that the grey text that accompanies the PassagePopper doesn't affect this container

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH-1}px) {
    flex: none;
    order: -1;
  }
`

const OtherColumn = styled(WordInfoColumn)`
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 7px 15px;
  color: ${({ theme }) => theme.palette.text.primary};  // so that the grey text that accompanies the PassagePopper doesn't affect this container

  @media (max-width: ${MINIMUM_MEDIUM_WIDTH-1}px) {
    display: none;
  }
`

const StyledOriginalLanguageWordInfo = styled(OriginalLanguageWordInfo)`
  margin: 0;
  background-color: ${({ theme }) => theme.palette.grey[400]};

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  ${({ basicInfoOnly }) => !basicInfoOnly ? `` : `
    flex: 0;
  `}

  && .OriginalLanguageWordInfo-RelatedInfo {
    background-color: ${({ theme }) => theme.palette.grey[200]};
    color: ${({ theme }) => theme.palette.grey[700]};

    h1, strong, a {
      color: black;
    }
  }

  .OriginalLanguageWordInfo-Usage {
    background-color: rgb(220 220 220);
  }

  .OriginalLanguageWordInfo-Vocal {
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  .OriginalLanguageWordInfo-Hash {
    color: ${({ theme }) => theme.palette.grey[600]};
  }

  .OriginalLanguageWordInfo-Hits {
    color: ${({ theme }) => theme.palette.grey[600]};

    span {
      opacity: .8;
    }
  }

  .OriginalLanguageWordSearchMenu-IconButton,
  .OriginalLanguageWordAltLexicons-IconButton {
    color: black;

    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .OriginalLanguageWordInfo-PosGroup {
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  .OriginalLanguageWordInfo-Pos {
    color: ${({ theme }) => theme.palette.grey[700]};
  }

  .OriginalLanguageWordInfo-Lex > span {
    color: black;
  }

`

const SearchResults = ({
  searchText,
  closeAndClearSearch,
  doMenuSearchItemAction,
  goSetPopperInfo,
  isEmbedSearch,
  disabled,
}) => {

  const {
    foundPassageRef,
    passageInfoLoading,
    passageInfoSets,
    bibleSearchResults,
    bibleSearchError,
    includeVersionIds,
    inVersionAbbrs,
    inVersionIds,
    projectsAndCount,
    // highlightsAndCount,
    versions,
    appItems,
    helpItems,
  } = useSearchResults({
    searchText,
  })

  const isLoggedIn = useIsLoggedIn()
  const isOrigLanguageSearch = isOriginalLanguageSearch(searchText)
  const minHeight = ((bibleSearchResults || {}).results || []).length > 5 ? 800 : 500
  const { addToRecentSearches } = useRecentSearchManager()
  const { mobileSize } = useAppSize()
  const { disableOriginalLanguageHelps } = useEmbedSettings()
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const isLoading = (
    isOrigLanguageSearch
      ? bibleSearchResults === undefined
      : (
        passageInfoLoading
        || (!foundPassageRef && bibleSearchResults === undefined)
        || (isLoggedIn && !channelIdInPWA && projectsAndCount === undefined)
        || versions === undefined
      )
  )

  useEffectAsync(
    () => {
      if(isLoading || !searchText) return

      const { hitsByBookId, rowCountByBookId } = bibleSearchResults || {}
      const resultCount = (hitsByBookId || rowCountByBookId || []).reduce((total, count) => total + count, 0)

      const originalWords = {}
      const originalStrongs = (searchText.match(/#[GH][0-9]{5}/g) || []).map(strgs => strgs.slice(1))
      const allOriginalWords = getOriginalWordsForSearch()
      originalStrongs.forEach(strongs => {
        if(allOriginalWords[strongs]) {
          originalWords[strongs] = allOriginalWords[strongs]
        }
      })

      addToRecentSearches({
        resultCount,
        suggestedQuery: searchText,
        originalWords,
      })
    },
    [ isLoading, searchText ],
  )

  if(isLoading) {
    return (
      <Container $minHeight={minHeight}>
        <Loading />
      </Container>
    )
  }

  const strongsFromSearchText = [ ...new Set(searchText.match(/#(?:[HG][0-9]{5}|[blkm])(?=#| |$)/g) || []) ]
  const projectsCount = projectsAndCount ? projectsAndCount.count : 0
  const hideWordInfo = IS_EMBED && disableOriginalLanguageHelps

  return (
    <Container
      $minHeight={minHeight}
      className="options-popper-no-pointer-events-exempt"
    >

      {foundPassageRef &&
        <StyledBibleSearchColumn>

          {passageInfoSets.map((passageInfo, idx) => (
            <PassageSearchResultsRow
              key={idx}
              {...passageInfo}
              closeAndClearSearch={closeAndClearSearch}
              goSetPopperInfo={goSetPopperInfo}
            />
          ))}

          {passageInfoSets.length === 0 &&
            <SearchResultsError>
              {i18n("Invalid passage reference")}
            </SearchResultsError>
          }

        </StyledBibleSearchColumn>

      }

      {!foundPassageRef &&
        <BibleSearchColumn>
          <BibleSearchResults
            searchText={searchText}
            {...(bibleSearchResults || {})}
            error={bibleSearchError}
            includeVersionIds={includeVersionIds}
            inVersionAbbrs={inVersionAbbrs}
            inVersionIds={inVersionIds}
            goSetPopperInfo={goSetPopperInfo}
            closeAndClearSearch={closeAndClearSearch}
            disabled={disabled}
          />
        </BibleSearchColumn>
      }

      {isOrigLanguageSearch && strongsFromSearchText.length > 0 && !mobileSize && !hideWordInfo &&
        <WordInfoColumn
          className="options-popper-no-pointer-events"
        >
          {strongsFromSearchText.map(strong => (
            <StyledOriginalLanguageWordInfo
              key={strong}
              strong={strong}
              basicInfoOnly={strongsFromSearchText.length > 1}
              disabled={disabled}
            />
          ))}
        </WordInfoColumn>
      }

      {!isOrigLanguageSearch && !isEmbedSearch &&
        <OtherColumn
          className="options-popper-no-pointer-events"
        >

          {projectsCount > 0 &&
            <ProjectSearchResults
              {...projectsAndCount}
            />
          }

          {/*
          My Highlights (top 3)
          Alerts (top 2)
          Online Courses
          */}

          {/* {versions.length > 0 &&
            <VersionSearchResults
              versions={versions}
            />
          } */}

          {appItems.length > 0 &&
            <AppItemSearchResults
              suggestions={appItems}
              doMenuSearchItemAction={doMenuSearchItemAction}
            />
          }

          {helpItems.length > 0 &&
            <HelpItemSearchResults
              suggestions={helpItems}
            />
          }

          {/*
          Shared with me (top 3)
          BSB (top 5)
          */}

          {projectsCount + appItems.length + helpItems.length === 0 &&
            <SearchResultsError>
              {i18n("No other results")}
            </SearchResultsError>
          }

        </OtherColumn>
      }

    </Container>
  )
}

export default memo(SearchResults)