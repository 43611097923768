import { useMemo } from 'react'
import { getPassageStr } from "@bibletags/bibletags-ui-helper"
import { getRefFromLoc, getCorrespondingRefs } from '@bibletags/bibletags-versification'
import { i18n } from 'inline-i18n'

import useVersionInfo from './useVersionInfo'
import useEqualObjsMemo from './useEqualObjsMemo'
import useStartAndEndVersesByChapter from './useStartAndEndVersesByChapter'
import { cloneObj, getOrigVersionInfo } from '../utils/misc'
import { KJV_VERSION } from '../utils/constants'

const usePassageRef = ({
  twoLines,
  withoutBookName,
  noWithoutBookNameExceptions,
  fromLoc,
  toLoc,
  convertToVersionId,  // using this assumes the passed in value uses original versification
  versionId,  // either convertToVersionId or this is required
  ...otherProps
 }) => {

  const { version } = useVersionInfo(convertToVersionId)

  const memoedOtherProps = useEqualObjsMemo(otherProps)
  
  const { startAndEndVersesByChapter } = useStartAndEndVersesByChapter({
    versionId: convertToVersionId || versionId,
    bookId: (fromLoc ? getRefFromLoc(fromLoc) : ((otherProps.refs || {})[0] || {})).bookId,
  })

  const passageStr = useMemo(
    () => {
      if(parseInt(fromLoc, 10) > 67000000) {
        let extraBiblical = i18n("Extra-biblical")
        if(twoLines) {
          extraBiblical = extraBiblical.replace(/-/g, '\n')
        }
        return extraBiblical
      }

      let refs = cloneObj(memoedOtherProps.refs || null)

      if(!fromLoc && !refs) return i18n("Loading")

      const getConvertedRefs = ({ ref, idx }) => (
        getCorrespondingRefs({
          baseVersion: {
            ref,
            info: getOrigVersionInfo(),
          },
          lookupVersionInfo: version || KJV_VERSION,  // unknown versions use the KJV
          directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
        })
      )

      if(fromLoc) {
        refs = [ getRefFromLoc(fromLoc) ]
        if(toLoc && toLoc !== fromLoc) {
          refs.push(getRefFromLoc(toLoc))
        }
      }

      if(convertToVersionId) {
        refs = refs.map((ref, idx) => getConvertedRefs({ ref, idx }).at(idx === 0 ? 0 : -1)).filter(Boolean)
      }

      const bookStr = getPassageStr({ ...memoedOtherProps, refs: [{ bookId: refs[0].bookId }] })

      if(
        (convertToVersionId || versionId)
        && refs.length === 2
        && refs[0].chapter === 1
        && refs[0].verse === startAndEndVersesByChapter[0][0]
        && refs[1].chapter === startAndEndVersesByChapter.length
        && refs[1].verse === startAndEndVersesByChapter.at(-1)[1]
        && (!withoutBookName || noWithoutBookNameExceptions)
      ) {  // is entire book
        return bookStr
      }

      if(
        (convertToVersionId || versionId)
        && refs.length === 2
        && refs[0].verse === startAndEndVersesByChapter[refs[0].chapter - 1][0]
        && refs[1].verse === startAndEndVersesByChapter[refs[1].chapter - 1][1]
        && (!withoutBookName || noWithoutBookNameExceptions)
      ) {  // is entire chapters
        refs.forEach(ref => {
          delete ref.verse
        })
      }

      let passageStr = getPassageStr({ ...memoedOtherProps, refs })
      if(twoLines) {
        passageStr = `${passageStr.substr(0, bookStr.length).trim()}\n${passageStr.substr(bookStr.length).trim()}`
      } else if(withoutBookName) {
        passageStr = `${passageStr.substr(bookStr.length).trim()}`
      }

      return passageStr

    },
    [ fromLoc, toLoc, convertToVersionId, twoLines, withoutBookName, version, memoedOtherProps, startAndEndVersesByChapter, versionId, noWithoutBookNameExceptions ],
  )

  return passageStr
}

export default usePassageRef