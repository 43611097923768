import { memo, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'

import useRefState from '../../../hooks/useRefState'
import useFormattingKeys from '../../../hooks/useFormattingKeys'
import useGoUpdateFormattingKey, { getDefaultFormattingKey } from '../../../hooks/useGoUpdateFormattingKey'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import { cloneObj } from '../../../utils/misc'

import ConfirmDialog from '../../common/ConfirmDialog'
import CustomRadioGroup from '../../common/CustomRadioGroup'
import CustomCheckbox from '../../common/CustomCheckbox'
import MarkupTypeChooser from './MarkupTypeChooser'
import ColorChooser from '../../common/ColorChooser'

const StyledConfirmDialog = styled(ConfirmDialog)`
  .MuiDialog-paper {
    width: 400px;
    max-width: calc(100vw - 50px);
    margin: 25px;
  }
`

const Style = styled.div`
  display: flex;
  align-items: center;
`

const Color = styled.div`
  
`

const MarkupColorDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: ${({ theme, $color }) => theme.palette.markupColors[$color].arrow};
`

const ColorValue = styled.div`
  display: flex;
  align-items: center;
`

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`

const EditLensDialog = ({
  onCancel,
  formattingKeyInfos,
  goSetFormattingKeyInfos,
  formattingKeyId,
}) => {

  const open = !!formattingKeyId

  const { formattingKeysById } = useFormattingKeys()

  let formattingKey = formattingKeysById[formattingKeyId]
  // const creatingNewBlank = formattingKeyId === `new`
  const creatingNewFromSystemOrPublic = (
    formattingKey
    && (
      /^system:/.test(formattingKey.id || ``)
      || formattingKey.user
    )
  )

  const [ goUpdateFormattingKey ] = useGoUpdateFormattingKey({
  // const [ goUpdateFormattingKey, goDeleteFormattingKey ] = useGoUpdateFormattingKey({
    formattingKey: creatingNewFromSystemOrPublic ? null : formattingKey,
  })

  const [ editedFormattingKey, setEditedFormattingKey, getEditedFormattingKey ] = useRefState()

  const colorInfoTypeRadios = useMemo(
    () => (
      [
        {
          value: `example`,
          label: i18n("Example"),
        },
        {
          value: `value`,
          label: i18n("Fixed value"),
        },
      ]
    ),
    [],
  )

  const onConfirm = useCallback(
    () => {
      const updatedFormattingKey = getEditedFormattingKey()

      if(!creatingNewFromSystemOrPublic && formattingKey) {
        for(let key in updatedFormattingKey) {
          if(updatedFormattingKey[key] === formattingKey[key]) {
            delete updatedFormattingKey[key]
          }
        }
      }

      goUpdateFormattingKey(updatedFormattingKey)
      onCancel()
    },
    [ onCancel, goUpdateFormattingKey, getEditedFormattingKey, formattingKey, creatingNewFromSystemOrPublic ],
  )

  const onClickAddStyle = useCallback(
    () => {
      setEditedFormattingKey({
        ...getEditedFormattingKey(),
        info: {
          ...getEditedFormattingKey().info,
          styles: [
            ...(getEditedFormattingKey().info.styles || []),
            {
              markupType: "BASE-HIGHLIGHT",
              label: "",
            },
          ],
        },
      })
    },
    [ setEditedFormattingKey, getEditedFormattingKey ],
  )

  const onClickAddColor = useCallback(
    () => {
      setEditedFormattingKey({
        ...getEditedFormattingKey(),
        info: {
          ...getEditedFormattingKey().info,
          colors: [
            ...(getEditedFormattingKey().info.colors || []),
            {
              color: "RED",
              example: "",
            },
          ],
        },
      })
    },
    [ setEditedFormattingKey, getEditedFormattingKey ],
  )

  const onColorInfoRadioChange = useCallback(
    ({ target }) => {

      const colorsIdx = target.closest(`[data-colors-idx]`).getAttribute(`data-colors-idx`)
      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())

      if(target.value === `example`) {
        newEditedFormattingKey.info.colors[colorsIdx].example = newEditedFormattingKey.info.colors[colorsIdx].value
        delete newEditedFormattingKey.info.colors[colorsIdx].value
      } else {
        newEditedFormattingKey.info.colors[colorsIdx].value = newEditedFormattingKey.info.colors[colorsIdx].example
        delete newEditedFormattingKey.info.colors[colorsIdx].example
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  const hasColorDefault = !!(editedFormattingKey || {}).colorDefault
  const toggleColorDefault = useCallback(
    () => {

      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())

      delete newEditedFormattingKey.colorDefault
      if(!hasColorDefault) {
        newEditedFormattingKey.colorDefault = {
          example: "",
        }
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ hasColorDefault, getEditedFormattingKey, setEditedFormattingKey ],
  )

  const onChange = useCallback(
    ({ target, keys, newValue }) => {

      if(!keys && target instanceof Element) {
        keys = target.closest(`[data-keys]`).getAttribute(`data-keys`).split(` `)
      } else if(typeof keys === `string`) {
        keys = keys.split(` `)
      }

      const newEditedFormattingKey = cloneObj(getEditedFormattingKey())
      let obj = newEditedFormattingKey
      keys.slice(0,-1).forEach(key => {
        obj = obj[key]
      })
      if((target || {}).type === `checkbox`) {
        if(target.checked) {
          obj[keys.pop()] = ``
        } else {
          delete obj[keys.pop()]
        }
      } else {
        obj[keys.pop()] = newValue !== undefined ? newValue : target.value
      }

      setEditedFormattingKey(newEditedFormattingKey)

    },
    [ getEditedFormattingKey, setEditedFormattingKey ],
  )

  useLayoutEffectAsync(
    () => {
      if(formattingKey) {

        const newFormattingKey = {
          ...getDefaultFormattingKey(),
          ...cloneObj(formattingKey || {}),
        }

        delete newFormattingKey.__typename
        delete newFormattingKey.savedAt
        delete newFormattingKey.createdAt
        delete newFormattingKey.user

        if(creatingNewFromSystemOrPublic) {
          delete newFormattingKey.id
        }

        setEditedFormattingKey(newFormattingKey)

      }
    },
    [ formattingKeyId, formattingKey ],
  )

  if(!editedFormattingKey) return null

  const commonProps = {
    onChange,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  const skipTypes = (editedFormattingKey.info.styles || []).map(({ markupType }) => markupType)
  const skipColors = [ ...(editedFormattingKey.info.colors || []).map(({ color }) => color), `GREY` ]

  return (
    <StyledConfirmDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonLabel={i18n("Update")}
      // loading={loading}
      title={i18n("Edit", "", "markup")}
      explanation={
        <>

          <TextField
            value={editedFormattingKey.name}
            data-keys={`name`}
            label={i18n("Name")}
            {...commonProps}
          />

          {(editedFormattingKey.info.styles || []).map(({ markupType, label }, idx) => (
            <Style key={idx}>

              <TextField
                value={label}
                data-keys={`info styles ${idx} label`}
                label={i18n("Label")}
                {...commonProps}
              />

              <MarkupTypeChooser
                skipTypes={skipTypes}
                onChange={onChange}
                keys={`info styles ${idx} markupType`}
              />

              <div>{markupType}</div>

            </Style>
          ))}

          <Button
            onClick={onClickAddStyle}
          >
            {i18n("Add a style definition")}
          </Button>

          {(editedFormattingKey.info.colors || []).map(({ color, type, example, value }, idx) => (
            <Color key={idx}>

              <ColorValue>

                <MarkupColorDot $color={color} />

                {type !== undefined &&
                  <TextField
                    value={type}
                    data-keys={`info colors ${idx} type`}
                    label={i18n("Type")}
                    {...commonProps}
                  />
                }

                {example !== undefined &&
                  <TextField
                    value={example}
                    data-keys={`info colors ${idx} example`}
                    label={i18n("Example")}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{i18n("E.g.")}</InputAdornment>,
                    }}          
                    {...commonProps}
                  />
                }

                {value !== undefined &&
                  <TextField
                    value={value}
                    data-keys={`info colors ${idx} value`}
                    label={i18n("Value")}
                    {...commonProps}
                  />
                }

                <ColorChooser
                  onChange={onChange}
                  keys={`info colors ${idx} color`}
                  skipColors={skipColors}
                />

              </ColorValue>

              <CustomRadioGroup
                value={example !== undefined ? `example` : `value`}
                onChange={onColorInfoRadioChange}
                radios={colorInfoTypeRadios}
                data-colors-idx={idx}
              />

              <CustomCheckbox
                checked={type !== undefined}
                data-keys={`info colors ${idx} type`}
                onChange={onChange}
                label={i18n("Include prefix")}
              />

            </Color>
          ))}

          <Button
            onClick={onClickAddColor}
          >
            {i18n("Add a color definition")}
          </Button>

          <CustomCheckbox
            checked={!!editedFormattingKey.colorDefault}
            onChange={toggleColorDefault}
            label={i18n("Include default color definition")}
          />

          {hasColorDefault &&
            <Color>

              <ColorValue>

                {editedFormattingKey.colorDefault.type !== undefined &&
                  <TextField
                    value={editedFormattingKey.colorDefault.type}
                    data-keys={`colorDefault type`}
                    label={i18n("Default Type")}
                    {...commonProps}
                  />
                }

                <TextField
                  value={editedFormattingKey.colorDefault.example}
                  data-keys={`colorDefault example`}
                  label={i18n("Default Example")}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{i18n("E.g.")}</InputAdornment>,
                  }}
                  {...commonProps}
                />

              </ColorValue>

              <CustomCheckbox
                value={editedFormattingKey.colorDefault.type !== undefined}
                data-keys={`colorDefault type`}
                onChange={onChange}
                label={i18n("Include prefix")}
              />            

            </Color>
          }

          <StyledButton
            color="primary"
            onClick={() => alert('sure?')}
          >
            {i18n("Delete")}
          </StyledButton>

        </>
      }
    />
  )
}

export default memo(EditLensDialog)