import { memo, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useNetworkState } from 'react-use'

import {
  IS_EMBED,
  MODULE_TYPE_COLOR_MAP,
} from '../../../utils/constants'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useSimpleToggle from '../../../hooks/useSimpleToggle'

import ModuleSharingPopover from './ModuleSharingPopover'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: -5px 0 -5px 5px;
`

const SmallPeopleAltIcon = styled(PeopleAltIcon)`
  height: 16px;
  position: relative;
  left: 3px;
`

const SmallShareIcon = styled(ShareIcon)`
  height: 16px;
`

const ShareButton = styled(Button)`
  text-transform: none;
  border-color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main}80;
  color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main};
  height: 31px;

  :hover {
    border-color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main};
    background-color: ${({ theme, $moduleType }) => theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main}0a;
  }

  ${({ $shared, $moduleType, theme }) => !$shared ? `` : `
    font-size: 14px;
    line-height: 23px;
    padding-left: 8px;
    padding-right: 4px;
    background-color: ${theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].main};
    color: white;

    :hover {
      background-color: ${theme.palette[MODULE_TYPE_COLOR_MAP[$moduleType]].dark};
    }
  `}
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  height: 18px;
  margin-left: 3px;
`

const ModuleOptionsSharing = ({
  module,
}) => {

  const { online } = useNetworkState()

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getShared = useInstanceValue(module.shared)

  const [ showSharingOn, toggleShowSharingOn ] = useSimpleToggle()
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const toggleShared = useCallback(
    () => {
      goUpdateModule({
        shared: !getShared(),
      })
    },
    [ goUpdateModule, getShared ],
  )

  const goShare = useCallback(
    ({ currentTarget }) => {
      toggleShared()
      requestAnimationFrame(() => {
        // requestAnimationFrame needed so that the button first resizes
        toggleShowSharingOn({ force: true })
        openModal({
          currentTarget,
        })
      })
    },
    [ toggleShared, openModal, toggleShowSharingOn ],
  )

  if(IS_EMBED) return null

  return (

    <Container>

      <Tooltip title={online ? `` : i18n("You are offline and so cannot change share setting")}>
        <span>
          <ShareButton
            $shared={module.shared}
            variant={module.shared ? `contained` : `outlined`}
            size="small"
            startIcon={module.shared ? <SmallPeopleAltIcon /> : <SmallShareIcon />}
            disableElevation
            $moduleType={module.type}
            onClick={module.shared ? openModal : goShare}
            disabled={!online}
          >
            {
              module.shared
                ? i18n("Shared")
                : i18n("Share")
            }
            {module.shared && <StyledExpandMoreIcon />}
          </ShareButton>
        </span>
      </Tooltip>

      <ModuleSharingPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        moduleId={module.id}
        toggleShared={toggleShared}
        showSharingOn={showSharingOn}
        toggleShowSharingOn={toggleShowSharingOn}
      />

    </Container>

  )
}

export default memo(ModuleOptionsSharing)
