import { memo, useRef } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { getRefFromLoc } from '@bibletags/bibletags-versification'

import { IS_EMBED } from '../../../utils/constants'
import useGoSetModuleSetting from '../../../hooks/useGoSetModuleSetting'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useVersesPieces from '../../../hooks/useVersesPieces'
import { getEmbedMode } from '../../../graphql/links/embedLink'

import Loading from '../../common/Loading'
import TextContent from '../../common/TextContent'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import MarkupContentTextField from './MarkupContentTextField'

const Container = styled.div`
  flex: 1;
  max-width: 960px;
  overflow: auto;
  padding: 60px 0 calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const TextContentContainer = styled.div`
  /* position: relative; */

  font-size: ${({ $textFontSize }) => $textFontSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};

  ${({ $showVsNums }) => $showVsNums ? `` : `
    .TextContent-tag-v,
    .TextContent-tag-vp {
      display: none;
    }
  `}

  ${({ $inEditingMode }) => !$inEditingMode ? `` : `
    .TextContent-tag-w:hover {
      cursor: text;
      opacity: 1;
    }
  `}

`

const MarkupContent = ({
  module,
  projectId,
  isRTL,
  textFontSize,
  lineSpacing,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  showContextSetting,
  greekPunctuationSetting,
  paragraphFormattingSetting,
  lastScrollPositionSetting,
  proceedingContextSetting,
  followingContextSetting,
  inEditingMode,
  noneditableViewingMode,
  goSetPopperInfo,
  goUndo,
  goRedo,
  goPrintOrDownload,
  embedFullScreen,
}) => {

  const { modulePassages, id: moduleId } = module
  const { fromLoc, toLoc, info={} } = modulePassages[0] || {}
  const { versionId } = info
  const { bookId, chapter: startChapter } = getRefFromLoc(fromLoc || ``)
  const { chapter: endChapter } = getRefFromLoc(toLoc || fromLoc || ``)

  const [ pieces, loading ] = useVersesPieces({
    inlineMarkersOnly: paragraphFormattingSetting.value === `NONE`,
    hideHeadings: paragraphFormattingSetting.value === `PARAGRAPHS-ONLY`,
    ...modulePassages[0],
    ...modulePassages[0].info,
  })

  const containerRef = useRef(null)

  // const ready = chaptersReady && contentWidth > 0
  // loading

  // const [ goUpdateModule ] = useGoUpdateModule({ module })
  // const getModule = useInstanceValue(module)

  const [ goSetLastScrollPositionSetting ] = useGoSetModuleSetting({
    moduleSetting: lastScrollPositionSetting,
    projectId,
  })

  // useMarkupShortcuts({
  //   getModule,
  //   goUpdateModule,
  //   goUndo,
  //   goRedo,
  //   goPrintOrDownload,
  //   noneditableViewingMode,
  // })

  useEffectAsync(
    () => {
      // if(!ready) return
      if(noneditableViewingMode) return
      if(IS_EMBED && getEmbedMode() !== `prep`) return
      // TODO: scroll to right place
    },
    [ loading ],
  )

  // const currentLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const newLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const [ setUpdateLastScrollPositionTimeout ] = useSetTimeout()
  // const updateLastScrollPosition = () => {
  //   if(!equalObjs(currentLastScrollPositionSettingValue.current, newLastScrollPositionSettingValue.current)) {
  //     goSetLastScrollPositionSetting({
  //       value: newLastScrollPositionSettingValue.current,
  //     })
  //     currentLastScrollPositionSettingValue.current = newLastScrollPositionSettingValue.current
  //   }

  // }

  // const onScroll = useCallback(
  //   () => {
  //     // TODO: throttle
  //     if(!isScrolling && containerRef.current && !noneditableViewingMode) {
  //       const rowEls = containerRef.current.querySelectorAll(`[data-index]`)
  //       const containerTop = containerRef.current.getBoundingClientRect().top
  //       for(let idx=0; idx<rowEls.length; idx++) {
  //         const { top } = rowEls[idx].getBoundingClientRect()
  //         const offset = (top - containerTop) * -1
  //         if(offset < 0 || idx === rowEls.length - 1) {
  //           newLastScrollPositionSettingValue.current = {
  //             align: `start`,
  //             index: parseInt(rowEls[idx].getAttribute(`data-index`), 10),
  //             offset,
  //           }
  //           setUpdateLastScrollPositionTimeout(updateLastScrollPosition, 5000)
  //           break
  //         }
  //       }
  //     }
  //   },
  //   [],
  // )

  // useEffect(() => updateLastScrollPosition, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      // className="options-popper-no-pointer-events-exempt"
      ref={containerRef}
    >

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={proceedingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          placeholder={inEditingMode ? i18n("Summarize the proceeding context here.") : null}
        />
      }

      <TextContentMarkupContainer
        isRTL={isRTL}
        markup={[]}
      >

        <TextContentContainer
          // these are faster than going through TextContent
          $isRTL={isRTL}
          $textFontSize={textFontSize}
          $lineSpacing={lineSpacing}
          $showVsNums={showVsNumsSetting.value}
          $inEditingMode={inEditingMode}
          // onScroll={onScroll}
        >

          <TextContent
            showCfs={showCfsSetting.value}
            showNotes={showNotesSetting.value}
            showCantillation={showCantillationSetting.value}
            showHebrewVowels={showHebrewVowelsSetting.value}
            showGreekAccents={showGreekAccentsSetting.value}
            greekPunctuation={greekPunctuationSetting.value}
            pieces={pieces}
            versionId={versionId}
            bookId={bookId}
            startChapter={startChapter}
            // wordClassName="markup-text-word"
            showChapterNumbers={startChapter !== endChapter}
            goSetPopperInfo={goSetPopperInfo}
            ignoreClick={inEditingMode ? `word` : false}
          />

        </TextContentContainer>

      </TextContentMarkupContainer>

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={followingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          placeholder={inEditingMode ? i18n("Summarize the following context here.") : null}
        />
      }

      {loading && <Loading />}

    </Container>
  )
}

export default memo(MarkupContent)