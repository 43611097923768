import { memo, useCallback, useContext } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { IS_EMBED } from '../../../utils/constants'
import { cloneObj } from '../../../utils/misc'
import useGoUpdateModulePassage from '../../../hooks/useGoUpdateModulePassage'
import useVersionInfo from '../../../hooks/useVersionInfo'
import useInstanceValue from '../../../hooks/useInstanceValue'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  transition: background-color .15s ease-in-out;
  width: 75px;
  height: 75px;
  padding: 10px;
  position: relative;
  margin-right: 12px;
  display: inline-block;

  ${({ $visible, theme }) => !$visible ? `` : `
    outline: 2px solid ${theme.palette.grey[800]};
  `}

`

const Version = styled.div`
  font-size: 13px;
`

// const Passage = styled.div`
//   font-size: 13px;
//   line-height: 1.1;
//   margin-top: 4px;
//   white-space: pre;
// `

const VisibilityToggle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(0 0 0/.08);
  transition: background-color .15s ease-in-out, color .15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  color: ${({ theme }) => theme.palette.grey[500]};

  :hover {
    background-color: rgb(0 0 0/.15);
    color: black;
    cursor: pointer;
  }

  ${({ $visible, $disabled, theme }) => !$visible ? `` : `

    .not-visible {
      display: none;
    }

    ${!$disabled ? `` : `
      :hover {
        background-color: rgb(0 0 0/.08);
        color: ${theme.palette.grey[500]};
        cursor: default;
      }
    `}

    ${$disabled ? `` : `
      :hover {
        .not-visible {
          display: block;
        }
        .visible {
          display: none;
        }
      }
    `}

  `}

  ${({ $visible }) => $visible ? `` : `

    .visible {
      display: none;
    }

    :hover {
      .visible {
        display: block;
      }
      .not-visible {
        display: none;
      }
    }

  `}
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 8px;
  background: rgb(245 245 245/.8);

  .MuiSvgIcon-root {
    height: 15px;
    width: 15px;
  }
`

const LegacyDiscourseOptionsColumn = ({
  modulePassage,
  projectId,
  setUpPiecesAfterPassageChange,
  modulePassages,
  visibilityById,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasToolsPlan } = user || {}

  // const { id, fromLoc, toLoc, info } = modulePassage
  const { id, info } = modulePassage
  const { versionId } = info
  const getModulePassages = useInstanceValue(modulePassages)

  const { safeVersionAbbr } = useVersionInfo(versionId)

  const [ x, goDeleteModulePassage ] = useGoUpdateModulePassage({ modulePassage, projectId })  // eslint-disable-line no-unused-vars
  const [ goUpdateModulePassage ] = useGoUpdateModulePassage({ moduleId: modulePassage.moduleId, projectId, isUpdate: true })

  const toggleVisibility = useCallback(
    () => {
      const modulePassages = getModulePassages()

      const newVisibilityById = cloneObj(visibilityById)
      if(!newVisibilityById[id] && newVisibilityById.isMaxedOut) {
        modulePassages.map(({ id }) => id).reverse().some(mpId => {
          if(newVisibilityById[mpId]) {
            newVisibilityById[mpId] = false
            return true
          }
          return false
        })
      }
      newVisibilityById[id] = !newVisibilityById[id]

      modulePassages.forEach(modulePassage => {
        const info = cloneObj(modulePassage.info)
        if(info.visible !== newVisibilityById[modulePassage.id]) {
          info.visible = newVisibilityById[modulePassage.id]
          goUpdateModulePassage({ info }, modulePassage)
        }
      })
    },
    [ getModulePassages, goUpdateModulePassage, visibilityById, id ],
  )

  const deleteModulePassage = useCallback(
    () => {
      const savedAt = goDeleteModulePassage()
      setUpPiecesAfterPassageChange({
        oldModulePassages: modulePassages,
        updatedModulePassages: modulePassages.filter(modulePassage => modulePassage.id !== id),
        savedAt,
      })
    },
    [ setUpPiecesAfterPassageChange, goDeleteModulePassage, modulePassages, id ],
  )

  const numVisible = modulePassages.filter(({ id }) => visibilityById[id]).length
  const disableHideAndClose = !!(visibilityById[id] && numVisible === 1)
  const hasAMineCol = modulePassages.some(({ info: { versionId } }) => versionId === `mine`)
  const disabledClose = !!(hasAMineCol && versionId !== `mine` && modulePassages.length === 2)

// TODO: need a hook to get effectiveVisibility that takes width into account

  return (
    <Container
      $visible={visibilityById[id]}
      // onClick={}
    >

      <Version>
        {/* TODO: The MINE is temporary with legacy modules and until I set up versions of type PERSONAL */}
        {safeVersionAbbr || versionId.replace(/^external_/,'').toUpperCase()}
      </Version>

      <VisibilityToggle
        $visible={visibilityById[id]}
        onClick={disableHideAndClose ? null : toggleVisibility}
        $disabled={disableHideAndClose}
      >
        <VisibilityIcon className="visible" />
        <VisibilityOffIcon className="not-visible" />
      </VisibilityToggle>

      {(hasToolsPlan || IS_EMBED) && !disableHideAndClose && !disabledClose &&
        <CloseButton
          onClick={deleteModulePassage}
        >
          <CloseIcon />
        </CloseButton>
      }

    </Container>
  )
}

export default memo(LegacyDiscourseOptionsColumn)