// See this spreadsheet for data flow information: https://docs.google.com/spreadsheets/d/1HQTbo3YS0IShtKfuxyfFrklCnmju9V432iQmQH2wSzk/edit?usp=sharing

import { memo } from 'react'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { selectURI } from "@apollo/client/link/http"

import getAddContextLink from '../../graphql/links/getAddContextLink'
import embedLink, { shouldGetFromEmbed } from '../../graphql/links/embedLink'
import dexieQueryLink from '../../graphql/links/dexieQueryLink'
import dexieUpdatePreServerLink from '../../graphql/links/dexieUpdatePreServerLink'
import dexieUpdateResponseLink from '../../graphql/links/dexieUpdateResponseLink'
import returnNullLink from '../../graphql/links/returnNullLink'
import placeholderLink from '../../graphql/links/placeholderLink'

const unbatchableOperations = [
  // prevent these from delaying the display of the text or holding up each other
  `studyBibleItems`,
  `channelItems`,
  `sermonAudioChannels`,
  `streamingServiceChannel`,
]

const batchHttpLinkOptions = {
  batchInterval: 50,
  batchMax: 25,  // 10 is the default; I have 25 since there can be up to 20 settings
  // batchDebounce: true,
  batchKey: operation => {
    // This is just like the default except for the unbatchableOperations addition

    const context = operation.getContext()

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers,
    }

    return selectURI(operation, `/graphql`) + JSON.stringify(contextConfig) + (unbatchableOperations.includes(operation.operationName) ? Math.random() : ``)
  },
}

const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_BIBLEARC_DATA_URI,
  ...batchHttpLinkOptions,
})

const bibletagsBatchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_BIBLETAGS_DATA_URI,
  ...batchHttpLinkOptions,
})

const getClient = () => client

const merge = (existing={}, incoming, { fieldName, args: { offset=0 } }) => ({
  count: incoming.count,
  [fieldName]: (
    offset === 0
      ? incoming[fieldName]  // reset
      : [
        ...(existing[fieldName] || []),
        ...incoming[fieldName].filter(({ __ref }) => !(existing[fieldName] || []).some(item => item.__ref === __ref)),
      ]
  ),
})

// This merge function comes from the docs, but doesn't account for a change to backend data
// const merge = (existing={}, incoming, { fieldName, args: { offset = 0 }}) => {
//   // Slicing is necessary because the existing data is
//   // immutable, and frozen in development.
//   const merged = [ ...(existing[fieldName] || []) ]
//   for(let i = 0; i < incoming[fieldName].length; i++) {
//     merged[offset + i] = incoming[fieldName][i]
//   }

//   return {
//     count: incoming.count,
//     [fieldName]: merged,
//   }
// }

const client = new ApolloClient({
  link: (
    getAddContextLink(getClient)
      .split(
        shouldGetFromEmbed,
        embedLink,  // terminating link
        placeholderLink
          .split(
            operation => operation.getContext().doDexieQuery,
            dexieQueryLink,  // terminating link
            (
              dexieUpdatePreServerLink.concat(dexieUpdateResponseLink)  // do to async restictions, I need to separate these
                .split(
                  operation => !operation.getContext().runThroughServer,
                  returnNullLink,  // terminating link
                  placeholderLink
                    .split(
                      operation => operation.getContext().serverRequestUsesBibleTagsEndpoint,
                      bibletagsBatchHttpLink,  // terminating link
                      batchHttpLink,  // terminating link
                    )
                )
            ),
          )
      )
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          projects: {
            keyArgs: [ `query` ],  // cache separate results only when these params change
            merge,
          },
          studyBibleItems: {
            keyArgs: [ `query` ],  // cache separate results only when these params change
            merge,
          },
          alertsItems: {
            keyArgs: [ `query` ],  // cache separate results only when these params change
            merge,
          },
          myBookmarkedChannelItems: {
            keyArgs: [ `query` ],  // cache separate results only when these params change
            merge,
          },
        },
      },
    },
  }),
})

const ApolloSetup = ({
  children,
}) => {

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default memo(ApolloSetup)