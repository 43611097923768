import { memo, useState, useMemo, useCallback, useContext, useRef } from 'react'
import { useHistory } from "react-router-dom"
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'

import useInstanceValue from '../../../hooks/useInstanceValue'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useDataQuery from '../../../hooks/useDataQuery'
import useLogInCallback from '../../../hooks/useLogInCallback'
// import useModalAnchor from '../../../hooks/useModalAnchor'
// import useVersionInfo from '../../../hooks/useVersionInfo'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import CustomRadioGroup from '../../common/CustomRadioGroup'
import SermonAudioChannelSearch from './SermonAudioChannelSearch'
import StudyBibleFollowAddBroadcaster from '../../passage/StudyBibleFollowAddBroadcaster'
import CustomCheckbox from '../../common/CustomCheckbox'
import ImageUrlTextField from '../../common/ImageUrlTextField'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import FadedLoading from '../../common/FadedLoading'
// import VersionPopover from '../../common/VersionPopover'
import ChannelFinder from './ChannelFinder'
import AppIconCreator from './AppIconCreator'

import createChannelMutation from '../../../graphql/mutations/createChannel'
import channelsQuery from '../../../graphql/queries/channels'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px max(60vh, 300px);
  background-color: white;
  position: relative;
`

// const Step = styled.div`
//   margin: 50px 0 20px;
//   font-weight: 700;
//   font-size: 28px;
//   text-align: center;
// `

const FormContainer = styled.div`
  margin: 0 0 0 10px;
  display: flex;
  flex-direction: column;
`

const Heading = styled.div`
  margin: 60px 0 10px;
  font-size: 24px;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
`

const MustLogIn = styled.div`
  flex: 1;
  padding: 20px 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  gap: 10px;
  background-color: white;
`

const SelectionContainer = styled.div`
  margin: 0 0 20px;
  display: flex;
  /* justify-content: center; */
`

const Hint = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin: -5px 0 10px;
`

const StyledCustomRadioGroup = styled(CustomRadioGroup)`
  margin-top: 12px;

  .MuiFormControlLabel-label {
    display: flex;
    margin: 0;
  }

  .MuiRadio-root {
    padding: 12px 10px;
  }
`

const FormSubmissionContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const StyledImageUrlTextField = styled(ImageUrlTextField)`
  margin: 12px 0;

  .MuiInputAdornment-root {
    margin-right: 0;
  }

  @media (max-width: 450px) {
    .MuiInputAdornment-root {
      overflow: hidden;
      height: 1.2em;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }
`

const HostLogo = styled.img`
  height: 21px;
  align-self: flex-start;
  vertical-align: middle;
`

const HostLogo2 = styled(HostLogo)`
  height: 28px;
`

const HostLogo3 = styled(HostLogo)`
  height: 32px;
`

const HostLogo4 = styled(HostLogo)`
  margin-bottom: 8px;
`

const HostLogo5 = styled(HostLogo)`
  margin-bottom: 4px;
`

// const PreferredVersion = styled.div`
// `

const ErrorDetail1 = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: 13px;
  font-weight: bold;
  margin: -5px 0 5px;
  text-align: center;
`

const ErrorDetail2 = styled(ErrorDetail1)`
  text-align: right;
`

const ErrorAbove = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 35px 0 -10px;
`

const ComingSoon = styled.div`
  margin: 25px 0 0;
  font-weight: 300;
  font-style: italic;
`

export const getRandomColorHex = () => {
  const maxTotal = 180*3
  const r = parseInt(Math.random()*255, 0)
  const maxG = Math.min(maxTotal - r, 255)
  const g = parseInt(Math.random()*maxG, 0)
  const maxB = Math.min(maxTotal - r - g, 255)
  const b = parseInt(Math.random()*maxB, 0)
  const getHex = num => `0${num.toString(16)}`.slice(-2)
  return `#${getHex(r)}${getHex(g)}${getHex(b)}`
}

const fixId = (id, alternative) => (id.toLowerCase().replace(/[ –—]/g, `-`).replace(/[^-a-z]/g, ``) || alternative || ``).slice(0,50)
export const fixName = name => name.slice(0,255)
export const fixShortName = shortName => shortName.slice(0,15)
export const fixLocation = location => location.slice(0,255)

const CreateAChurchBible = ({ ...props }) => {

  const user = useContext(LoggedInUserContext)

  const containerRef = useRef()
  const connectAcctRef = useRef()
  const [ sermonHost, setSermonHost ] = useState(``)
  const [ selectedChannel, setSelectedChannel ] = useState()
  const [ iAmPastor, toggleIAmPastor ] = useSimpleToggle()
  const [ agreeToSOF, toggleAgreeToSOF ] = useSimpleToggle()
  const [ id, setId ] = useState(``)
  const [ name, setName ] = useState(``)
  const [ shortName, setShortName ] = useState(``)
  const [ location, setLocation ] = useState(``)
  // Redo preferredVersionId, creating a component similar to SettingsVersionsRightPanel
  const [ preferredVersionId, setPreferredVersionId ] = useState(`esv`)  // eslint-disable-line no-unused-vars
  const [ description, setDescription ] = useState(``)
  const [ imageEditInfo, setImageEditInfo ] = useState({ bgColor: getRandomColorHex() })
  const [ hidden, toggleHidden ] = useSimpleToggle()  // eslint-disable-line no-unused-vars
  const [ loading, setLoading ] = useState()
  const [ error, setError ] = useState()
  const { logIn, navigatingToLogin } = useLogInCallback()

  const channelUrls = [(selectedChannel || {}).channelUrl || ``]

  const channelUrlQuery = useDataQuery({
    channelsQuery,
    variables: {
      query: `channelUrl:"${channelUrls[0]}"`,
      limit: 1,
    },
    skip: !channelUrls[0],
  })
  const channelUrlCount = ((channelUrlQuery.channels || {}).channels || [0,0]).length  // gets value of 2 if still loading

  const channelIdQuery = useDataQuery({
    channelsQuery,
    variables: {
      query: `id:"${id}"`,
      limit: 1,
    },
    skip: !id,
  })
  const channelIdCount = ((channelIdQuery.channels || {}).channels || [0,0]).length  // gets value of 2 if still loading

  // const { safeVersionAbbr } = useVersionInfo(preferredVersionId)
  const history = useHistory()
  const [ createChannel ] = useMutation(createChannelMutation)

  const sermonHostRadios = useMemo(
    () => (
      [
        {
          value: `SERMON-AUDIO`,
          label: <HostLogo4 src="/sermonaudio.svg" />,
        },
        {
          value: `VIMEO`,
          label: <HostLogo5 src="/vimeo.svg" />,
          info: {
            channelUrlRegex: /^https:\/\/vimeo\.com\/[0-9]*[^/#?0-9]+[0-9]*$/,
            channelUrlLabel: i18n("Vimeo Profile URL"),
            channelUrlExample: `https://vimeo.com/bethlehembaptistchurch`,
            channelUrlRequirement: i18n("Note: Each sermon’s relevant passage(s) must be included in the video’s title or description on Vimeo, and must use full Bible book names."),
            mediaTypeAsNotNecessarilyAllSermons: i18n("videos"),
          },
        },
        {
          value: `YOUTUBE`,
          label: <HostLogo src="/youtube.png" />,
          info: {
            channelUrlRegex: /^https:\/\/www\.youtube\.com\/@[-a-zA-Z0-9_.]+$/,
            channelUrlStartAdornment: `@`,
            channelUrlLabel: i18n("YouTube Handle"),
            channelUrlExample: `@CitiesChurchMN`,
            channelUrlRequirement: i18n("Note: Each sermon’s relevant passage(s) must be included in the video’s title on YouTube (or the very start of the description), and must use full Bible book names."),
            channelUrlTransform: url => `https://www.youtube.com/@${url}`,
            mediaTypeAsNotNecessarilyAllSermons: i18n("videos"),
            channelUrlImmediateTransform: url => url.replace(/[^-a-zA-Z0-9_.]/g, ``),
          },
        },
        {
          value: `SERMONS-BY-LOGOS`,
          label: <HostLogo3 src="/sermons_by_logos.svg" />,
          info: {
            channelUrlRegex: /^https:\/\/sermons\.logos\.com\/profile\/[^/]+$/,
            channelUrlLabel: i18n("Sermons by Logos Profile URL"),
            channelUrlExample: `https://sermons.logos.com/profile/mc-xt`,
            channelUrlRequirement: i18n("This is the URL you are brought to when you click “My sermons” from the header while logged in to Sermons by Logos. (Note: The “Bible passages” field must be filled-in for sermons to be included in your app.)"),
          },
        },
        // {
        //   value: `SPOTIFY`,
        //   label: <HostLogo2 src="/spotify.svg" />,
        //   info: {
        //     soon: true,
        //     // channelUrlRegex: /^https:\/\/vimeo\.com\/[0-9]*[^/#?0-9]+[0-9]*$/,
        //     // channelUrlLabel: i18n("Vimeo profile URL"),
        //     // channelUrlExample: `https://vimeo.com/943398058`,
        //     // channelUrlRequirement: i18n("Each sermon’s relevant passage(s) must be included in the video’s title or description on Vimeo, and must use full Bible book names."),
        //     // mediaTypeAsNotNecessarilyAllSermons: ,
        //   },
        // },
        {
          value: `APPLE-PODCASTS`,
          label: <HostLogo2 src="/apple_podcasts.png" />,
          info: {
            channelUrlRegex: /^https:\/\/podcasts\.apple\.com\/us\/podcast\/[^/?#]+\/id[0-9]+$/,
            channelUrlLabel: i18n("Apple Podcasts URL"),
            channelUrlExample: `https://podcasts.apple.com/us/podcast/hickory-grove-baptist-church/id589559966`,
            channelUrlRequirement: i18n("Each sermon’s relevant passage(s) must be included in the video’s title or description on Apple Podcasts, and must use full Bible book names."),
            mediaTypeAsNotNecessarilyAllSermons: i18n("episodes"),
          },
        },
      ]
    ),
    [],
  )

  const sermonHostInfo = (sermonHostRadios.find(({ value }) => value === sermonHost) || {}).info

  // const { anchorEl, openModal, closeModal } = useModalAnchor()

  const onSermonHostChange = useCallback(({ target }) => setSermonHost(target.value), [])
  const clearSelectedChannel = useCallback(() => setSelectedChannel(), [])
  const onIdChange = useCallback(({ target }) => setId(fixId(target.value)), [])
  const onNameChange = useCallback(({ target }) => setName(fixName(target.value)), [])
  const onShortNameChange = useCallback(({ target }) => setShortName(fixShortName(target.value)), [])
  const onLocationChange = useCallback(({ target }) => setLocation(fixLocation(target.value)), [])
  // const onSelectVersion = useCallback(({ versionId }) => setPreferredVersionId(versionId), [])  // Redo this, creating a component similar to SettingsVersionsRightPanel
  const onDescriptionChange = useCallback(({ target }) => setDescription(target.value), [])

  const getInput = useInstanceValue({
    id,
    name: name.trim(),
    shortName: shortName.trim(),
    location: location.trim(),
    preferredVersionId,
    description: description.trim(),
    imageEditInfo,
    hidden,
    channelUrls,
  })

  const goCreate = useCallback(
    async () => {

      setLoading(true)
      setError()

      try {

        await createChannel({
          variables: {
            input: getInput(),
          },
        })

      } catch(err) {
        setError(err.message || `Unknown error`)
        setLoading(false)
        return
      }

      history.push(`/church/${getInput().id}`)

      setLoading(false)

    },
    [ createChannel, getInput, history ],
  )

  useLayoutEffectAsync(
    () => {
      setSelectedChannel()
      toggleIAmPastor({ force: false })
      toggleAgreeToSOF({ force: false })
      setId(``)
      setName(``)
      setShortName(``)
      setLocation(``)
      setDescription(``)
    },
    [ sermonHost ],
  )

  useLayoutEffectAsync(
    () => {
      if(selectedChannel) {
        toggleIAmPastor({ force: false })
        toggleAgreeToSOF({ force: false })
        setId(fixId(selectedChannel.broadcasterID || ``, fixId(selectedChannel.displayName || ``)))
        setName(fixName(selectedChannel.displayName || ``))
        let shortName = (selectedChannel.displayName || ``).replace(/ church$/i, ``).trim()
        while(shortName.length > 15) {
          shortName = shortName.replace(/[^ ]+$/, ``).trim()
        }
        setShortName(fixShortName(shortName))
        setLocation(fixLocation(selectedChannel.location || ``))
        setDescription(selectedChannel.aboutUs || [ selectedChannel.location, selectedChannel.minister ].filter(Boolean).join(` // `))

        if(containerRef.current && connectAcctRef.current) {
          const containerRect = containerRef.current.getBoundingClientRect()
          const connectAcctRect = connectAcctRef.current.getBoundingClientRect()
          const top = connectAcctRect.y + containerRef.current.scrollTop - containerRect.y
          if(top < containerRef.current.scrollTop) {
            containerRef.current.scrollTo({
              top,
              behavior: 'smooth',
            })
          }
        }
      }
    },
    [ selectedChannel ],
  )

  const commonProps = {
    // disabled: ,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  if(!user) {
    return (
      <>
  
        <Header {...props} />
  
        <MustLogIn>
          {i18n("To get started...")}
          <Button
            onClick={logIn}
            variant="contained"
            disableElevation
            color="primary"
          >
            {i18n("Sign in / create an account")}
          </Button>
        </MustLogIn>
  
        {navigatingToLogin && <FadedLoading />}
  
      </>
    )
  }

  const readyToCreate = (
    iAmPastor
    && agreeToSOF
    && id.length >= 2
    && name.trim()
    && shortName.trim()
    && location.trim()
    && preferredVersionId
    && description.trim()
    && imageEditInfo.bgColor
    && !channelUrlCount
    && !channelIdCount
  )

  return (
    <>

      <Header {...props} />

      <Container ref={containerRef}>
        <Content>

          {/* <Step>
            {i18n("Step 1 of 3")}
          </Step> */}

          <Heading>
            {i18n("Where do you host your sermons?")}
          </Heading>

          <StyledCustomRadioGroup
            value={sermonHost}
            onChange={onSermonHostChange}
            radios={sermonHostRadios}
          />

          {!!sermonHost &&
            <Heading ref={connectAcctRef}>
              {i18n("Connect your account...")}
            </Heading>
          }

          {!selectedChannel && sermonHost === `SERMON-AUDIO` &&
            <SermonAudioChannelSearch
              onSelect={setSelectedChannel}
            />
          }

          {!selectedChannel && !!sermonHostInfo && !sermonHostInfo.soon &&
            <ChannelFinder
              {...sermonHostInfo}
              onSelect={setSelectedChannel}
            />
          }

          {!selectedChannel && !!sermonHostInfo && !!sermonHostInfo.soon &&
            <ComingSoon>
              {i18n("Integration with this hosting service coming soon. (Check back in one week.)")}
            </ComingSoon>
          }

          {!!selectedChannel &&
            <>

              <SelectionContainer>
                <StudyBibleFollowAddBroadcaster
                  broadcaster={selectedChannel}
                  onClose={clearSelectedChannel}
                  mediaTypeAsNotNecessarilyAllSermons={(sermonHostInfo || {}).mediaTypeAsNotNecessarilyAllSermons}
                />
              </SelectionContainer>

              {channelUrlCount === 1 &&
                <ErrorDetail1>
                  {i18n("These sermons are already connected to an existing Church-Specific Study Bible.")}
                  {` `}
                  <NavLinkOrAWithDisable
                    to={`/church/${channelUrlQuery.channels.channels[0].id}`}
                  >
                    {i18n("View that Bible")}
                  </NavLinkOrAWithDisable>
                </ErrorDetail1>
              }

              {channelUrlCount !== 1 &&
                <>

                  <Heading>
                    {i18n("Church and App Details")}
                  </Heading>

                  <FormContainer>

                    <CustomCheckbox
                      checked={iAmPastor}
                      onChange={toggleIAmPastor}
                      label={i18n("I am a pastor/elder of this church (or acting at a pastor’s request)")}
                    />

                    {!iAmPastor &&
                      <ErrorDetail2>
                        {i18n("(Required to create a Church study bible.)")}
                      </ErrorDetail2>
                    }

                    <CustomCheckbox
                      checked={agreeToSOF}
                      onChange={toggleAgreeToSOF}
                      label={i18nReact("My church’s beliefs agree with {{statement_of_faith}}", {
                        statement_of_faith: (
                          <NavLinkOrAWithDisable
                            to={`${document.location.origin}/partnership-statement-of-faith`}
                          >
                            {i18n("Biblearc’s Partnership Statement of Faith")}
                          </NavLinkOrAWithDisable>
                        ),
                      })}
                    />

                    {!agreeToSOF &&
                      <ErrorDetail2>
                        {i18n("(Required to create a Church study bible.)")}
                      </ErrorDetail2>
                    }

                    <StyledImageUrlTextField
                      {...commonProps}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            https://app.biblearc.com/church/
                          </InputAdornment>
                        ),
                      }}
                      value={id}
                      data-keys={`id`}
                      label={i18n("URL")}
                      onChange={onIdChange}
                      error={id.length < 2 || channelIdCount === 1}
                    />
                    <Hint>{i18n("This CANNOT be changed later.")}</Hint>

                    {id.length < 2 &&
                      <ErrorDetail2>
                        {i18n("Enter at least two characters.")}
                      </ErrorDetail2>
                    }

                    {channelIdCount === 1 &&
                      <ErrorDetail2>
                        {i18n("This URL is already is use.")}
                      </ErrorDetail2>
                    }

                    <StyledImageUrlTextField
                      {...commonProps}
                      value={name}
                      data-keys={`name`}
                      label={i18n("Name")}
                      onChange={onNameChange}
                      error={!name.trim()}
                    />

                    <StyledImageUrlTextField
                      {...commonProps}
                      value={shortName}
                      data-keys={`shortName`}
                      label={i18n("Short Name")}
                      onChange={onShortNameChange}
                      error={!shortName.trim()}
                    />
                    <Hint>{i18n("Used as seen in the Preview below.")}</Hint>

                    <StyledImageUrlTextField
                      {...commonProps}
                      value={location}
                      data-keys={`location`}
                      label={i18n("Location")}
                      onChange={onLocationChange}
                      error={!location.trim()}
                    />
                    <Hint>{i18n("Typical usage: city and state, or city and country. (E.g. Minneapolis, MN)")}</Hint>

                    {/* Redo this, creating a component similar to SettingsVersionsRightPanel */}
                    {/* <PreferredVersion
                      onClick={openModal}
                    >
                      {safeVersionAbbr}
                    </PreferredVersion> */}
                    {/* <VersionPopover
                      anchorEl={anchorEl}
                      onClose={closeModal}
                      onSelectVersion={onSelectVersion}
                    /> */}

                    <StyledImageUrlTextField
                      {...commonProps}
                      value={description}
                      data-keys={`description`}
                      label={i18n("About")}
                      onChange={onDescriptionChange}
                      maxRows={15}
                      multiline
                      error={!description.trim()}
                    />

                    <AppIconCreator
                      shortName={shortName}
                      imageEditInfo={imageEditInfo}
                      setImageEditInfo={setImageEditInfo}
                    />

                    {/* <CustomCheckbox
                      checked={hidden}
                      onChange={toggleHidden}
                      label={i18n("Hide for security reasons")}
                    />
                    <div>
                      {i18n("Only choose this if...")}
                    </div> */}

                    {!!channelIdCount &&
                      <ErrorAbove>
                        {i18n("Please fix the error above.")}
                      </ErrorAbove>
                    }

                    {!channelIdCount && !readyToCreate &&
                      <ErrorAbove>
                        {i18n("All fields are required.")}
                      </ErrorAbove>
                    }

                    {!!error &&
                      <ErrorAbove>
                        {error}
                      </ErrorAbove>
                    }

                    <FormSubmissionContainer>
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={goCreate}
                        disabled={!readyToCreate}
                      >
                        {i18n("Create My Church Study Bible")}
                      </Button>
                    </FormSubmissionContainer>

                  </FormContainer>
                </>
              }

            </>
          }

        </Content>

      </Container>

      {loading && <FadedLoading />}

    </>
  )
}

export default memo(CreateAChurchBible)