import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { reorder } from '../../../utils/misc'
import useVersionInfos from '../../../hooks/useVersionInfos'

import MyVersionsListLine from "./MyVersionsListLine"

const Container = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`

const Contents = styled.div`
  min-height: 0;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overflow-anchor: none;
  padding-bottom: 80px;
`

const Lines = styled.div`
`

const MyVersionsList = () => {

  const { selectedVersionInfos, updateSelectedVersionIds } = useVersionInfos()

  const onDragEnd = useCallback(
    result => {
      if(!result.destination) return
      if(result.destination.index === result.source.index) return

      const newSelectedVersions = reorder({
        list: selectedVersionInfos,
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })

      updateSelectedVersionIds(newSelectedVersions.map(({ id }) => id))
    },
    [ selectedVersionInfos, updateSelectedVersionIds ],
  )

  return (
    <Container>

      <Contents>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Lines>
                    {selectedVersionInfos.map((versionInfo, index) => (
                      <Draggable
                        key={versionInfo.id}
                        draggableId={versionInfo.id}
                        index={index}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <MyVersionsListLine
                              {...versionInfo}
                              dragHandleProps={provided.dragHandleProps}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </Lines>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

      </Contents>

      {/* <CustomSnackbar {...snackbarInfo} /> */}

    </Container>
  )
}

export default memo(MyVersionsList)