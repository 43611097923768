import { memo } from 'react'
import styled from 'styled-components'

import MarkupLine from './MarkupLine'

const directions = [ 'top', 'right', 'bottom', 'left' ]

const StyledMarkupLine = styled(MarkupLine)`
  z-index: -2;

  ${({ $includesStart, $includesEnd, $isRTL, width, height }) => ($includesStart && $includesEnd) ? `` : `
    overflow: hidden;
    margin-top: -.5em;
    ${($isRTL && !$includesStart && $includesEnd) ? `
      margin-right: -.5em;
    ` : `
      margin-left: ${$includesStart ? -.5 : 0}em;
    `}
    width: calc(${width}px + ${(!$includesStart && !$includesEnd) ? `-.2em` : `.5em`});
    height: calc(${height}px + 1em);
  `}

  ::before{
    content: "";
    z-index: -1;
    left: ${({ $includesStart, $includesEnd }) => $includesStart ? ($includesEnd ? `-0.3em` : `0.2em`) : `-50%`};
    right: ${({ $includesStart, $includesEnd }) => $includesEnd ? ($includesStart ? `-0.3em` : `0.2em`) : `-50%`};
    top: ${({ $includesStart, $includesEnd }) => !($includesStart && $includesEnd) ? `0.38em` : `-0.12em`};
    bottom: ${({ $includesStart, $includesEnd }) => !($includesStart && $includesEnd) ? `0.38em` : `-0.12em`};
    ${() => {
      const startIdx = parseInt(Math.random() * 4, 10)
      return `
        border-${directions[startIdx % 4]}-width: .14em;
        border-${directions[(startIdx+1) % 4]}-width: .1em;
        border-${directions[(startIdx+2) % 4]}-width: .01px;
        border-${directions[(startIdx+3) % 4]}-width: .23em;
      `
    }}
    border-style: solid;
    border-color: ${({ theme, $color }) => theme.palette.markupColors[$color].line};
    background-color: ${({ theme, $color, $darkGreyInsteadOfGrey }) => $darkGreyInsteadOfGrey ? `transparent` : `${theme.palette.markupColors[$color].arrow}22`};
    position: absolute;
    ${({ width, height }) => width/height > 8 ? `` : `
      transform: rotate(1deg);
    `}
    border-radius: 50%;
    padding: 0.1em 0.25em;
  }
`

const Circle = ({
  color,
  includesStart,
  includesEnd,
  darkGreyInsteadOfGrey,
  isRTL=false,
  ...otherProps
}) => {

  if(isRTL) {
    [ includesStart, includesEnd ] = [ includesEnd, includesStart ]
  }

  return (
    <StyledMarkupLine
      {...otherProps}
      $color={color}
      $includesStart={includesStart !== false}
      $includesEnd={includesEnd !== false}
      $isRTL={isRTL}
      $darkGreyInsteadOfGrey={darkGreyInsteadOfGrey}
    />
  )
}

export default memo(Circle)