import { capitalize, cloneObj } from '../../utils/misc'
import { v4 as uuidv4 } from 'uuid'

export const addNewPassageToModulePiece = modulePiece => {
  try {
    const content = JSON.parse(modulePiece.content || `{"blocks":[],"entityMap":{}}`)
    let largestCurrentKeyInt = Object.keys(content.entityMap).map(key => parseInt(key, 10)).sort((a,b) => b-a)[0]
    if(largestCurrentKeyInt === undefined) {
      largestCurrentKeyInt = -1
    }
    const alreadyHasANewPassage = largestCurrentKeyInt >= 0 && !content.entityMap[`${largestCurrentKeyInt}`].data.refs
    if(!alreadyHasANewPassage) {  // do not add one on if the last block is already without passage
      const key = `${largestCurrentKeyInt + 1}`
      content.blocks.push({
        key: uuidv4().slice(0,5),
        text: " ",
        type: "atomic",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [{
          offset: 0,
          length: 1,
          key,
        }],
        data: {}
      })
      content.entityMap[key] = {
        type: "passage",
        mutability: "IMMUTABLE",
        data: {},
      }
      modulePiece.content = JSON.stringify(content)
    }
  } catch(err) {}
}

export const removeNewPassagesFromModulePiece = modulePiece => {
  try {
    const content = JSON.parse(modulePiece.content || `{"blocks":[],"entityMap":{}}`)
    const keysNeedingToBeRemoved = Object.keys(content.entityMap).filter(key => !content.entityMap[key].data.refs)
    const blockIndexesNeeingToBeRemoved = (
      keysNeedingToBeRemoved
        .map(key => content.blocks.findIndex(({ entityRanges }) => entityRanges[0].key === key))
        .filter(idx => idx !== -1)
        .sort((a,b) => b-a)
    )
    keysNeedingToBeRemoved.forEach(key => delete content.entityMap[key])
    blockIndexesNeeingToBeRemoved.forEach(idx => content.blocks.splice(idx, 1))
    modulePiece.content = JSON.stringify(content)
  } catch(err) {}
}

const embedLinkQueries = async ({
  operationName,
  variables,
  embedData,
  embedSettings,
  embedMode,
}) => {

  console.log('query from embed', operationName, variables)

  switch(operationName) {

    case `project`: {

      const { id } = variables

      const project = cloneObj(embedData[`Project:${id}`])

      project.moduleByProjects = (
        Object.values(embedData)
          .filter(({ projectId, __typename }) => (
            __typename === `ModuleByProject`
            && projectId === id
          ))
          .sort((a,b) => a.ordering - b.ordering)
          .map(moduleByProject => {
            const module = embedData[`Module:${moduleByProject.id.split(':')[0]}`]
            if(!module) return null

            module.modulePassages = (
              Object.values(embedData)
                .filter(({ moduleId, __typename }) => (
                  __typename === `ModulePassage`
                  && moduleId === module.id
                ))
                .sort((a,b) => a.ordering - b.ordering)
            )

            return {
              ...moduleByProject,
              module,
            }
          })
      )

      return project

    }

    case `accountSetting`:
    case `moduleSetting`:
    case `modulePiece`:
    case `moduleDot`:
    case `moduleMarkup`: {

      const { id, defaultValue } = variables

      return (
        embedData[`${capitalize(operationName)}:${id}`]
        || (
          defaultValue !== undefined
            ? {
              __typename: capitalize(operationName),
              id,
              value: defaultValue,
              savedAt: null,
            }
            : null
        )
      )

    }

    case `formattingKeys`: {

      return (
        embedData.formattingKeys
        || []
      )

    }

    case `modulePieces`: {

      const { moduleId, position, orderingFrom, orderingTo, offset } = variables

      const modulePieces = (
        Object.values(embedData)
          .filter(modulePiece => (
            modulePiece.__typename === `ModulePiece`
            && modulePiece.moduleId === moduleId
            && (
              !position
              || modulePiece.position === position
            )
            && (
              !orderingFrom
              || modulePiece.ordering >= orderingFrom
            )
            && (
              !orderingTo
              || modulePiece.ordering < orderingTo
            )
          ))
          .sort((a,b) => a.ordering - b.ordering)
      )

      if(offset) {
        return modulePieces.slice(offset, offset + 1)
      }

      if(
        operationName === `modulePieces`
        && embedSettings.embedType === `passages`
      ) {
        if(embedMode === `prep`) {
          // add on a new passage
          addNewPassageToModulePiece(modulePieces[0])
        } else {
          removeNewPassagesFromModulePiece(modulePieces[0])
        }
      }

      return modulePieces

    }

    case `moduleDots`: {

      const { moduleId, modulePieceId } = variables

      return (
        Object.values(embedData)
          .filter(moduleDot => (
            moduleDot.__typename === `ModuleDot`
            && moduleDot.moduleId === moduleId
            && (
              modulePieceId === undefined
              || moduleDot.modulePieceId === modulePieceId
            )
          ))
          .sort((a,b) => a.id < b.id ? -1 : 1)
      )

    }

    case `moduleMarkups`: {

      const { moduleId, container } = variables

      return (
        Object.values(embedData)
          .filter(moduleMarkup => (
            moduleMarkup.__typename === `ModuleMarkup`
            && moduleMarkup.moduleId === moduleId
            && (
              !container
              || moduleMarkup.container === container
            )
          ))
          .sort((a,b) => a.id < b.id ? -1 : 1)
      )

    }

    default: {
      return null
    }

  }

}
    
export default embedLinkQueries