import { memo, useCallback, useMemo } from 'react'
import { isRTL, getRTLAdjustedRight } from 'inline-i18n'
import styled from 'styled-components'
import { getTextLanguageId, isRTLText, stripHebrewVowelsEtc, adjustPiecesForSpecialHebrew } from "@bibletags/bibletags-ui-helper"

import { getGloss, getOrigVersionInfo, preventDefaultEvent } from '../../utils/misc'
import { GRAMMAR_COLOR_BRIGHTNESS } from './VerseNumberPopperContentInline'

import TextContent from '../common/TextContent'

const Container = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`

const OriginalSnippet = styled.div`
  margin: -64px -16px 5px;
  padding: 64px 16px 0;
  color: white;
  font-size: 22px;
  line-height: 1.5;

  ${({ $isRTL }) => `
    direction: ${$isRTL ? "rtl" : "ltr"};
  `}
`

const Empty = styled.div`
  margin-top: -16px;
`

const WordSetContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 -.15em 2px;
  padding: 4px .25em 5px;
  border-radius: 4px;

  .TextContent-tag-v, .TextContent-tag-vp {
    font-size: 17px;
  }

  ${({ onClick, theme, $selected }) => !(onClick && !$selected) ? `` : `
    :hover {
      background: ${theme.palette.grey[900]}44;
      cursor: pointer;
    }
  `}

  ${({ $selected, theme }) => !$selected ? `` : `
    background: ${theme.palette.grey[900]};
  `}
`

const OrigWordContainer = styled.div`
  [data-word-id], [data-word-part] {
    display: inline-block;
    position: relative;
  }

  ${({ $showGloss, $id, $children, $infoByWordIdAndPartNumber, $bookId }) => {
    if($showGloss) return ``

    return (
      $children
        .map((part, idx) => {
          const wordIdAndPartNumber = `${$id}|${idx+1}`
          const { comboColor } = (
            $infoByWordIdAndPartNumber[wordIdAndPartNumber]
            || $infoByWordIdAndPartNumber[$id]
            || {}
          )
          if(comboColor) {
            if($bookId >= 40) {
              return `
                [data-word-id="${$id}"] {
                  border-radius: 3px;
                  background: ${comboColor};
                }
              `
            } else {
              return `
                [data-word-part="${wordIdAndPartNumber}"]::before {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  background: ${comboColor};
                  content: '';
                  z-index: -1;
                }
                [data-word-part="${wordIdAndPartNumber}"][data-color]::before {
                  filter: brightness(${(100 / GRAMMAR_COLOR_BRIGHTNESS).toFixed(2)}%);
                }
              `
            }
          } else {
            return ``
          }
        })
        .join(`\n`)
    )
  }}
`

const TranslationWordsContainer = styled.div`
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  max-width: ${({ $approximatOrigWordLength }) => Math.max($approximatOrigWordLength, 75)}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TranslationWords = styled.div`
  font-size: 13.5px;
  font-weight: 300;
  line-height: 1;
  filter: brightness(160%);
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  text-align: ${({ $textAlign }) => $textAlign};
`

const OriginalWithInlineDefs = ({
  originalPieces,
  selectedOriginalWordId,
  contextRef,
  selectWord,
  showGloss,
  definitionsByStrong,
  infoByWordIdAndPartNumber,
}) => {

  // TODO: do tooltip for Hebrew suffixes that give an English word for it (e.g. 1cs = “my, me”)
  // TODO: do tooltip for all other Hebrew prefixes suffixes that shows OriginalLanguageWordInfo in a tooltip

  const { bookId=1 } = contextRef || {}
  const languageId = getTextLanguageId({ languageId: getOrigVersionInfo().languageId, bookId })
  const originalIsRTL = isRTLText({ bookId, languageId })
  const adjustedOriginalPieces = useMemo(
    () => (
      adjustPiecesForSpecialHebrew({
        isOriginal: true,
        languageId: `heb+grc`,
        pieces: (
          originalPieces
            .filter((piece, idx) => !(piece.tag === `v` && originalPieces[idx+1].tag === `vp`))
        ),
      })
    ),
    [ originalPieces ],
  )

  const getApproximatOrigWordLength = useCallback(
    ({ text, children, strong }) => {
      const approximateLetterWidth = /^G/.test(strong) ? 10 : 13
      if(text) {
        return stripHebrewVowelsEtc(text).length * approximateLetterWidth
      } else if(children) {
        return children.map(({ text }) => getApproximatOrigWordLength({ text, strong })).reduce((t, len) => t + len, 0)
      } else {
        return 0
      }
    },
    [],
  )

  if(!originalPieces) return <Empty />

  return (
    <Container>

      <OriginalSnippet
        $isRTL={originalIsRTL}
        onClick={selectWord}
      >
        
        {adjustedOriginalPieces.map((piece, idx) => {

          let { children, tag, text, type } = piece
          if(!children) {
            children = [{ text }]
          }

          if(!tag) return text || ``

          return (
            <WordSetContainer
              key={idx}
              $selected={selectedOriginalWordId && piece[`x-id`] === selectedOriginalWordId}
              onClick={
                type === `word`
                  ? (
                    event => {
                      preventDefaultEvent(event)
                      selectWord(piece[`x-id`])
                    }
                  )
                  : null
              }
            >
              <OrigWordContainer
                $showGloss={showGloss}
                $id={piece[`x-id`]}
                $children={children}
                $infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
                $bookId={bookId}
              >
                <TextContent
                  pieces={[piece]}
                  versionId="original"
                  bookId={contextRef.bookId}
                  startChapter={contextRef.chapter}
                />
              </OrigWordContainer>
              {type === `word` &&
                <TranslationWordsContainer $approximatOrigWordLength={getApproximatOrigWordLength(piece)}>

                  {showGloss && children.map((part, idx) => (
                    <TranslationWords
                      key={idx}
                      style={{ color: part.color }}
                      $textAlign={originalIsRTL ? getRTLAdjustedRight() : `initial`}
                      $isRTL={isRTL()}
                    >
                      {getGloss({ piece, idx, definitionsByStrong })}
                    </TranslationWords>
                  ))}

                  {!showGloss && children.map((part, idx) => {
                    const { translation } = (
                      infoByWordIdAndPartNumber[`${piece[`x-id`]}|${idx+1}`]
                      || infoByWordIdAndPartNumber[piece[`x-id`]]
                      || {}
                    )
                    if(!translation) return null
                    return (
                      <TranslationWords
                        key={idx}
                        style={{ color: part.color }}
                        $textAlign={originalIsRTL ? getRTLAdjustedRight() : `initial`}
                        $isRTL={isRTL()}
                      >
                        {translation}
                      </TranslationWords>
                    )
                  })}

                </TranslationWordsContainer>
              }
            </WordSetContainer>
          )
        })}

      </OriginalSnippet>

    </Container>
  )
}

export default memo(OriginalWithInlineDefs)