import { memo, useState, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import { useApolloClient } from '@apollo/client'

import useRefState from '../../../hooks/useRefState'

import FadedLoading from '../../common/FadedLoading'

import streamingServiceChannelQuery from '../../../graphql/queries/streamingServiceChannel'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledTextField = styled(TextField)`
  margin-top: 15px;

  .MuiInputBase-root {
    padding-right: 5px;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 2px;
  }
`

const Error = styled.div`
  color: ${({ theme }) => theme.palette.tertiary.main};
  text-align: center;
  padding: 30px;
`

const Example = styled.div`
  color: black;
  text-align: center;
  margin-top: 10px;
  font-weight: 300;
  word-wrap: break-word;
`

const Note = styled.div`
  color: black;
  text-align: center;
  margin-top: 10px;
  padding: 30px;
  font-weight: 300;
  word-wrap: break-word;
`

const defaultTransform = url => `https://${url}`
const defaultImmediateTransform = url => url.replace(/\s/g, ``).replace(/^https:\/\//, ``)

const ChannelFinder = ({
  onSelect,
  image,
  channelUrlRegex,
  channelUrlStartAdornment=`https://`,
  channelUrlLabel,
  channelUrlExample,
  channelUrlRequirement,
  channelUrlTransform=defaultTransform,
  channelUrlImmediateTransform=defaultImmediateTransform,
}) => {

  const [ channelUrl, setChannelUrl, getChannelUrl ] = useRefState(``)
  const [ finding, setFinding ] = useState(false)
  const [ error, setError ] = useState()

  const client = useApolloClient()

  const onChangeChannelUrl = useCallback(
    ({ target }) => {
      setChannelUrl(
        channelUrlImmediateTransform(
          target.value,
        )
      )
    },
    [ setChannelUrl, channelUrlImmediateTransform ],
  )

  const goFind = useCallback(
    async () => {

      setError()

      let id = channelUrlTransform ? channelUrlTransform(getChannelUrl()) : getChannelUrl()

      if(!channelUrlRegex.test(id)) {
        setError(
          id
          ? (
            i18n("Invalid {{invalid_item}}", {
              invalid_item: channelUrlLabel,
            })
          )
          : undefined
        )
        return
      }

      setFinding(true)

      try {

        const { data: { streamingServiceChannel } } = await client.query({
          query: streamingServiceChannelQuery,
          variables: {
            id,
          },
        })

        if(streamingServiceChannel) {
          onSelect({
            ...streamingServiceChannel,
            channelUrl: id,
          })    
        } else {
          setError(i18n("Not found"))
          setFinding(false)
        }

      } catch(err) {
        console.error(err)
        setError(`Unknown error`)
        setFinding(false)
      }

      setFinding(false)

    },
    [ getChannelUrl, client, channelUrlLabel, channelUrlRegex, onSelect, channelUrlTransform ],
  )

  const onKeyDownChannelUrl = useCallback(
    async event => {

      if(event.key === `Enter`) {
        goFind()
      }

    },
    [ goFind ],
  )

  return (
    <Container>

      {image}

      <StyledTextField
        label={channelUrlLabel}
        InputProps={{
          startAdornment: !channelUrlStartAdornment ? null : (
            <InputAdornment position="start">
              {channelUrlStartAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={goFind}
              >
                <KeyboardReturnIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        fullWidth
        variant="outlined"
        value={channelUrl}
        onChange={onChangeChannelUrl}
        onKeyDown={onKeyDownChannelUrl}
      />

      {!!error &&
        <Error>
          {error}
          <Example>
            {i18n("Valid example: {{example}}", {
              example: channelUrlExample || ``,
            })}
          </Example>
        </Error>
      }

      {!error &&
        <Note>
          {channelUrlRequirement}
        </Note>
      }

      {finding && <FadedLoading size={30} />}

    </Container>
  )
}

export default memo(ChannelFinder)