import { memo } from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  position: absolute;
  box-sizing: content-box;
  top: ${({ $top }) => $top}px;
  ${({ $left, $right }) => (
    $left
      ? `left: ${$left}px;`
      : `right: ${$right}px;`
  )}
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  z-index: -1;
`

const MarkupLine = ({
  top,
  left,
  right,
  width,
  height,
  ...otherProps
}) => (
  <StyledDiv
    {...otherProps}
    $top={top}
    $left={left}
    $right={right}
    $width={width}
    $height={height}
  />
)

export default memo(MarkupLine)