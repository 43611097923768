import { useContext, useMemo } from 'react'
import { i18n } from 'inline-i18n'
import { useLocation } from "react-router-dom"

import { LoggedInUserContext } from '../context/LoggedInUser'
import { ChannelIdInPWAContext } from '../context/ChannelIdInPWA'

const useMenuSearchItems = () => {

  const user = useContext(LoggedInUserContext)
  const { hasMyPlan, hasToolsPlan, hasEquipPlan } = user || {}
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)

  const { pathname } = useLocation()

  const menuItemsForSearch = useMemo(
    () => {

      const autologin = user ? `?autologin` : ``

      let menuItemsForSearch = [

        // {
        //   suggestedQuery: i18n("Bible Map + Timeline"),
        //   path: "/map",
        // },
        {
          suggestedQuery: i18n("Settings"),
          path: "/settings",
        },
        {
          suggestedQuery: i18n("Bible Versions"),
          path: "/settings/versions",
        },
        {
          suggestedQuery: i18n("Bible Display Settings"),
          action: "open-display-settings"
        },
        {
          suggestedQuery: i18n("Alerts"),
          action: "open-alerts"
        },

        ...(channelIdInPWA ? [] : [  // NOT church app
          {
            // suggestedQuery: i18n("My Study Bible"),
            suggestedQuery: i18n("Read the Bible"),
            path: "/",
          },
          {
            suggestedQuery: i18n("All Courses"),
            url: `https://equip.biblearc.com/all-courses${autologin}`,
          },
          {
            suggestedQuery: i18n("Learning Resources"),
            url: `https://equip.biblearc.com/learningresources${autologin}`,
          },
          // {
          //   suggestedQuery: i18n("FAQ"),
          //   path: "/faq",
          // },
          {
            suggestedQuery: i18n("Contact Us"),
            // path: "/contact",
            url: `https://equip.biblearc.com/contact${autologin}`,
          },
          {
            suggestedQuery: hasMyPlan ? i18n("Manage Your Subscription") : i18n("Subscribe"),
            path: "/subscribe",
          },
          {
            suggestedQuery: i18n("Join the Mailing List"),
            action: "join-mailing-list",
          },
          {
            suggestedQuery: i18n("Group Subscriptions"),
            path: "/group-subscriptions",
          },
          {
            suggestedQuery: i18n("Gift Certificates"),
            path: "/give-a-gift",
          },
          {
            suggestedQuery: i18n("About The Biblearc Bible"),
            path: "/about-the-biblearc-bible"
          },
          {
            suggestedQuery: i18n("About Biblearc TOOLS"),
            path: "/about-tools"
          },
          {
            suggestedQuery: i18n("About Biblearc EQUIP"),
            url: `https://equip.biblearc.com${autologin}`,
          },
          {
            suggestedQuery: i18n("FAQ (Biblearc EQUIP)"),
            url: `https://equip.biblearc.com/faq${autologin}`
          },
          {
            suggestedQuery: i18n("Announcements"),
            url: `https://equip.biblearc.com/blog?category=Announcements`  // autologin breaks the link since it assumes no query string
          },  
        ]),

        ...(!hasMyPlan ? [] : [  // has MY
          {
            suggestedQuery: i18n("Channels"),
            path: "/settings/channels",
          },
        ]),

        ...(!hasToolsPlan ? [] : [  // has TOOLS
          {
            suggestedQuery: i18n("All Projects"),
            path: "/projects",
          },
          {
            suggestedQuery: i18n("Your Biblearc TOOLS Subscription"),
            path: "/your-tools-subscription"
          },
        ]),

        ...(!hasEquipPlan ? [] : [  // has EQUIP
          {
            suggestedQuery: i18n("Your Biblearc EQUIP Subscription"),
            path: "/your-equip-subscription"
          },
        ]),

        {
          suggestedQuery: i18n("About Our Greek Text"),
          url: `https://equip.biblearc.com/blog-post/introducing-biblearcs-new-greek-text-of-choice${autologin}`
        },

        ...(user ? [] : [  // not logged in
          {
            suggestedQuery: i18n("Sign In"),
            action: "sign-in",
          },
          {
            suggestedQuery: i18n("Log In"),
            action: "sign-in",
          },
        ]),

        ...(!user ? [] : [  // logged in
          {
            suggestedQuery: i18n("Manage Your Account"),
            action: "manage-account",
          },
          {
            suggestedQuery: i18n("Sign Out"),
            action: "log-out",
          },
          {
            suggestedQuery: i18n("Log Out"),
            action: "log-out",
          },
        ]),

      ]

      menuItemsForSearch = menuItemsForSearch.filter(({ path }) => !path || path !== pathname)

      menuItemsForSearch.forEach(suggestion => {
        suggestion.from = "menu-item"
        suggestion.action = suggestion.action || (suggestion.url ? "open-new-tab" : "open")
      })

      return menuItemsForSearch
    },
    [ user, pathname, hasEquipPlan, hasMyPlan, hasToolsPlan, channelIdInPWA ],
  )

  return menuItemsForSearch

}

export default useMenuSearchItems