import { memo } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getTextLanguageId, isRTLText } from "@bibletags/bibletags-ui-helper"

import { getGloss, getOrigVersionInfo, preventDefaultEvent } from '../../utils/misc'

import TextContent from '../common/TextContent'

const Container = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`

const OriginalSnippet = styled.div`
  margin: -64px -16px 5px;
  padding: 64px 16px 0;
  color: white;
  font-size: 22px;
  line-height: 1.5;
`

const Empty = styled.div`
  margin-top: -16px;
`

const WordRow = styled.div`
  display: flex;
  gap: 10px;
`

const OrigWordContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  direction: ltr;
  justify-content: flex-end;

  ${({ $isRTL }) => !$isRTL ? `` : `
    direction: rtl;
    justify-content: flex-start;
  `}
`

const OrigWord = styled.div`
  padding: 4px 6px 5px;
  margin: 0 -6px;
  border-radius: 4px;

  ${({ onClick, theme, $selected }) => !(onClick && !$selected) ? `` : `
    :hover {
      background: ${theme.palette.grey[900]}44;
      cursor: pointer;
    }
  `}

  ${({ $selected, theme }) => !$selected ? `` : `
    background: ${theme.palette.grey[900]};
  `}
`

const OrigNonWord = styled.div`
  color: rgba(255 255 255/.4);
  white-space: pre;
  line-height: 1.9;
`

const TranslationWordsContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3px 0;
`

const TranslationWords = styled.div`
  font-size: 13.5px;
  font-weight: 300;
  line-height: 1;
  filter: brightness(160%);
`

const OriginalChartWithDefs = ({
  originalPieces,
  selectedOriginalWordId,
  contextRef,
  selectWord,
  showGloss,
  definitionsByStrong,
  phraseAndTranslationPairs,
}) => {

  const { bookId=1 } = contextRef || {}
  const languageId = getTextLanguageId({ languageId: getOrigVersionInfo().languageId, bookId })
  const isRTL = isRTLText({ bookId, languageId })

  if(!originalPieces) return <Empty />

  return (
    <Container>

      <OriginalSnippet
        onClick={selectWord}
      >

        {showGloss && originalPieces.map((piece, idx) => {
          const { children=[{}] } = piece
          return (
            <WordRow
              key={`gloss-${idx}`}
            >
              <OrigWordContainer>
                <OrigWord
                  $isRTL={isRTL}
                  $selected={selectedOriginalWordId && piece[`x-id`] === selectedOriginalWordId}
                  onClick={event => {
                    preventDefaultEvent(event)
                    selectWord(piece[`x-id`])
                  }}
                >
                  <TextContent
                    pieces={[piece]}
                    versionId="original"
                    bookId={contextRef.bookId}
                    startChapter={contextRef.chapter}
                  />
                </OrigWord>
              </OrigWordContainer>
              <TranslationWordsContainer>
                {children.map((part, idx) => (
                  <TranslationWords
                    key={idx}
                    style={{ color: part.color }}
                  >
                    {getGloss({ piece, idx, definitionsByStrong })}
                  </TranslationWords>
                ))}
              </TranslationWordsContainer>
            </WordRow>
          )
        })}

        {!showGloss && phraseAndTranslationPairs.map(({ phrase, translations }, idx) => (
          <WordRow
            key={`tags-${idx}`}
          >
            <OrigWordContainer
              $isRTL={isRTL}
            >
              {phrase.map((word, idx) => (
                word[`x-id`]
                  ? (
                    <OrigWord
                      key={`tags-word-${idx}`}
                      $selected={selectedOriginalWordId && word[`x-id`] === selectedOriginalWordId}
                      className="TextContent-tag-w"
                      onClick={event => {
                        preventDefaultEvent(event)
                        selectWord(word[`x-id`])
                      }}
                    >
                      <TextContent
                        pieces={[word]}
                        versionId="original"
                        bookId={contextRef.bookId}
                        startChapter={contextRef.chapter}
                      />
                    </OrigWord>
                  )
                  : (
                    <OrigNonWord
                      key={`tags-word-${idx}`}
                    >
                      <TextContent
                        pieces={[word]}
                        versionId="original"
                        bookId={contextRef.bookId}
                        startChapter={contextRef.chapter}
                      />
                    </OrigNonWord>
                  )
              ))}
            </OrigWordContainer>
            <TranslationWordsContainer>
              {translations.map(({ text, color }, idx) => (
                <TranslationWords
                  key={idx}
                  style={{ color }}
                >
                  {text}
                </TranslationWords>
              ))}
            </TranslationWordsContainer>
          </WordRow>
        ))}

      </OriginalSnippet>

    </Container>
  )
}

export default memo(OriginalChartWithDefs)